import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const VideoIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '15px'}
      height={props.size || '12px'}
      viewBox="0 0 15 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-150.000000, -796.000000)" fill={props.color || '#1F72F2'}>
          <g transform="translate(140.000000, 789.000000)">
            <g transform="translate(5.000000, 1.000000)">
              <g transform="translate(5.000000, 6.000000)">
                <path
                  d="M5.625,9.75 C5.568,9.75 5.51025,9.73725 5.457,9.71025 C5.33025,9.6465 5.25,9.51675 
                  5.25,9.375 L5.25,2.625 C5.25,2.48325 5.33025,2.35275 5.457,2.28975 C5.58375,2.22675 
                  5.736,2.24025 5.85,2.325 L10.35,5.7 C10.4445,5.7705 10.5,5.88225 10.5,6 C10.5,6.11775 
                  10.4445,6.2295 10.35,6.3 L5.85,9.675 C5.784,9.7245 5.7045,9.75 5.625,9.75 Z M6,3.375 
                  L6,8.625 L9.50025,6 L6,3.375 Z M14.625,0 C14.832,0 15,0.168 15,0.375 L15,11.625 C15,11.832 
                  14.832,12 14.625,12 L0.375,12 C0.168,12 0,11.832 0,11.625 L0,0.375 C0,0.168 0.168,0 0.375,0 
                  L14.625,0 Z M0.75,11.25 L14.25,11.25 L14.25,0.75 L0.75,0.75 L0.75,11.25 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
