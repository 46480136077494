import * as React from 'react';

import {ITemplateColors} from '../../../@types/templateColors';

interface ICustomRadioButtonProps {
  index?: string | number;
  id: string;
  value: string | number;
  selectedValue: string | number;
  text?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  templateColors: ITemplateColors;
  isUseDefaultColorForNotSelected?: boolean;
  reverse?: boolean;
  disabled?: boolean;
  className?: string;
}

export class CustomRadioButton extends React.PureComponent<ICustomRadioButtonProps> {
  onLabelClick = e => {
    e.stopPropagation();
  };

  render() {
    const {main, highlight} = this.props.templateColors;
    const className = this.props.className ? ` ${this.props.className}` : ``;
    const isSelected = this.props.selectedValue === this.props.value;
    const reverse = this.props.reverse ? ` reverse` : ``;
    const disabled = this.props.disabled ? ` disabled` : ``;

    return (
      <div className="radio-container" {...(this.props.index ? {key: this.props.index} : {})}>
        <label
          className={`radio-container_radio${reverse}${disabled}${className}`}
          htmlFor={this.props.id}
          onClick={this.onLabelClick}
        >
          <input
            disabled={this.props.disabled}
            id={this.props.id}
            type="checkbox"
            value={this.props.value}
            checked={this.props.selectedValue === this.props.value}
            style={{display: 'none'}}
            onChange={this.props.onChange}
          />
          <div className="radio-container_radio_custom" style={isSelected ? {borderColor: highlight} : null}>
            <div
              className="radio-container_radio_custom_inner_circle"
              style={{backgroundColor: highlight, display: isSelected ? '' : 'none'}}
            />
          </div>
          <div
            className="radio-container_radio_title"
            style={this.props.isUseDefaultColorForNotSelected && !isSelected ? null : {color: main}}
          >
            {this.props.text || 'Option'}
          </div>
        </label>
      </div>
    );
  }
}
