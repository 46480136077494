import {Action, handleActions} from 'redux-actions';
import {IVideoState} from 'state/IVideoState';
import LocalStorageService from 'services/LocalStorageService';
import {MediaTimeFormat} from '../state';
import {
  ADD_SUBTITLE_FILE,
  GET_EVENT_GROUPS,
  SET_CHANGED_EVENTS,
  GET_CATEGORIES,
  CHANGE_VISIBLE_SUBTITLE,
  FORWARD_AND_REWIND_RATE_CHANGE,
  METADATA_RECEIVED,
  MUTE,
  PLAYBACK_RATE_CHANGE,
  READY,
  STOP_FAST_FRAME,
  SWITCH_FAST_FRAME,
  SWITCH_LOOP,
  SWITCH_PLAYING,
  THIRD_INDICATOR,
  UPDATE_SHORTCUTS,
  VIDEO_STOP,
  VOLUME,
  CHANGE_MEDIA_TIME_FORMAT,
  SET_LOOP_TIME_IN,
  SET_LOOP_TIME_OUT,
  UPDATE_THUMBNAILS_VTT,
  SET_VIDEO_FRAGMENT_IN_TIME,
  SET_VIDEO_FRAGMENT_OUT_TIME,
  UPDATE_AUDIO_CONFIG,
  UPDATE_PLAYLIST_ID,
  UPDATE_SWITCHING_CURRENT_TIME,
  PLAYLIST_LOADING_STATE,
  PLAYLIST_LOADING_ERROR,
  GET_LANGUAGE_ENUMS,
  GET_BIT_RATE_MODE_ENUMS,
  GET_AUDIO_BIT_DEPTH_ENUMS,
  GET_AUDIO_SUB_TYPE_ENUMS,
  GET_AUDIO_CODEC_ENUMS,
  GET_CHANNEL_CONFIG_ENUMS,
  GET_CHANNEL_CONFIG_TYPE_ENUMS,
  GET_CHANNEL_MAP_ENUMS,
  GET_SUB_TYPE_ENUMS,
  GET_VIDEO_CODEC_ENUMS,
  GET_ENCODE_RATE_ENUMS,
  GET_PICTURE_FORMAT_ENUMS,
  GET_SUBTITLE_TYPE_ENUMS,
  GET_BIT_DEPTH_ENUMS,
  GET_PIXEL_ASPECT_ENUMS,
  GET_DISPLAY_ASPECT_RATIO_ENUMS,
  GET_SCAN_TYPE_ENUMS,
  GET_PICTURE_ASPECT_RATIO_ENUMS,
  GET_COLOR_SUB_SAMPLING_ENUMS,
  GET_COLOR_TYPE_ENUMS,
  GET_TIME_CODE_ENUMS,
  GET_COLOR_ENCODING_ENUMS,
  GET_TRANSFER_FUNCTION_ENUMS,
  GET_COLOR_DIFF_ENUMS,
  GET_FUNCTIONS_ENUMS,
  UPDATE_PLAYBACK_PROXY_STATE,
  UPDATE_STAGE_COUNT,
  UPDATE_PLAYLIST_STAGING_STATE,
  UPDATE_PLAYLIST_FORCE_STAGING,
  UPDATE_PLAYLIST_URL,
  UPDATE_PLAYLIST_PROXY,
  LOAD_MARKUPS_TYPES_ENUMS,
  UPDATE_SELECTED_EVENT_GROUP,
  SET_VIDEO_FRAGMENT,
  GET_ASSET_STATUS_ENUMS,
  GET_FILE_WRAPPER_ENUMS,
  GET_CONTENT_TYPE_ENUMS,
  GET_FORMAT_COMPLIANCE_ENUMS,
  GET_FRAME_RATE_ENUMS,
  GET_REFERENCES_NAME_ENUMS,
  GET_REFERENCES_TYPE_ENUMS,
  SUBMIT_METADATA,
  SET_MARKUPS_ERRORS,
  UPDATE_PLAYLIST_ASSETS,
  UPDATE_SELECTED_ASSET_ID,
  UPDATE_PLAYLIST_VIDEO,
  GET_COUNTRIES_ENUMS,
  GET_COLOR_MODEL_ENUMS,
  GET_SIGNAL_RANGE_ENUMS,
  GET_COLORIMETRY_ENUMS,
  GET_WHITE_POINT_ENUMS,
  GET_DYNAMIC_RANGE_TYPE_ENUMS,
  GET_DYNAMIC_RANGE_SYSTEM_ENUMS,
  GET_LANGUAGE_DIALECT,
  UPDATE_VIDEO_START_TIME,
  UPDATE_PLAYLIST_FRAME_RATE,
  GET_GRAPHICS_TYPE_ENUMS,
  GET_NON_MEDIA_SUB_TYPE_ENUMS,
  GET_NON_MEDIA_TYPE_ENUMS
} from 'actions/video';
import * as shortcuts from '../constants/shortcuts';
import {hasProp, defineShortcutType} from '../utils/utils';
import {deepCopy} from '../modules/Tabs/utils/helpers';
import {IAssetDetails} from '../../@types/assetDetails';

const localStorageService = new LocalStorageService();
const localStoragePaybackRate = +localStorageService.get('playbackRate');
const localStorageForwardAndRewindRate = +localStorageService.get('forwardAndRewindRate');
const shortcutType = defineShortcutType();

export const DEFAULT_ASSET_DETAILS: IAssetDetails = {
  id: '',
  hrId: '',
  linkedConformanceId: '',
  name: '',
  fileWrapper: '',
  fileExt: '',
  fileType: '',
  size: 0,
  vfsHashId: '',
  function: '',
  status: '',
  asOfDate: '',
  distributors: [],
  frameRate: {value: '', isDrop: false},
  private: [],
  contentProviderId: '',
  contentOwnerId: '',
  contentType: '',
  isQuarantined: false,
  quarantinedDate: '',
  references: [],
  formatCompliance: '',
  nonMedia: [],
  audio: [],
  closedCaptions: [],
  images: [],
  subtitles: [],
  videos: [],
  assetExtension: [],
  keywords: [],
  tags: [],
  sourceAssetIds: [],
  programTimings: [],
  chapter: [],
  programRuntimeInMin: 0,
  compliance: [],
  qualityControl: [],
  createdBy: '',
  createdDate: '',
  modifiedBy: '',
  modifiedDate: ''
};

export const DEFAULT_VIDEO_STATE: IVideoState = {
  metadata: null,
  isReady: false,
  playing: false,
  fastFrameInProgress: false,
  fastFrameType: null,
  eventGroups: [],
  selectedEventGroup: '',
  changedEvents: [],
  types: [],
  categories: {},
  watermarkPosition: 'diagonal',
  loop: {
    loop: 'off',
    timeIn: 0,
    timeOut: 0
  },
  fastFrameIntervalId: null,
  volume: 0.5,
  mute: false,
  isThirdIndicator: false,
  shortcuts: {
    type: shortcutType,
    shortcuts: shortcuts.getShortcutsByName(shortcutType)
  },
  playbackRate: localStoragePaybackRate || 1,
  forwardAndRewindRate: localStorageForwardAndRewindRate || 0.1,
  playlist: {
    title: '',
    atlas: [],
    assets: [],
    externalSubs: [],
    embeddedSubs: [],
    url: '',
    id: '',
    selectedSub: '',
    startTime: 0,
    frameRate: {
      frameRate: 24,
      dropFrame: false
    },
    loading: false,
    error: null,
    stageCount: 0,
    staging: false,
    forcingStaging: false,
    proxy: null,
    selectedAssetId: null,
    video: null
  },
  textTracks: [],
  settings: {
    mediaTimeFormat: MediaTimeFormat.NonDropFrameTimecode
  },
  displayMediaTimeFormat: 'FrameBasedTimecode',
  currentVideoFragment: {
    inTime: 0,
    outTime: 0,
    lastUpdated: null
  },
  audioConfiguration: {
    selectedConfig: null,
    checkedChannels: {},
    switchingCurrentTime: false,
    proxyState: null,
    componentIdentifier: null
  },
  submitMetadata: false,
  markupsErrors: [],
  enums: {
    language: [],
    bitRateMode: [],
    audioBitDepth: [],
    audioSubType: [],
    audioCodec: [],
    channelMap: [],
    channelConfig: [],
    channelConfigType: [],
    assetStatus: [],
    assetFunction: [],
    fileWrapper: [],
    contentType: [],
    formatCompliance: [],
    frameRate: [],
    referencesName: [],
    referencesType: [],
    subType: [],
    videoCodec: [],
    encodeRate: [],
    pictureFormat: [],
    subtitleType: [],
    bitDepth: [],
    pixelAspect: [],
    displayAspectRatio: [],
    scanType: [],
    pictureAspectRatio: [],
    colorSubSampling: [],
    colorType: [],
    timeCode: [],
    colorEncoding: [],
    transferFunction: [],
    colorDiff: [],
    countries: [],
    dynamicRangeSystem: [],
    signalRange: [],
    colorimetry: [],
    colorModel: [],
    whitePoint: [],
    dynamicRangeType: [],
    languageDialect: [],
    graphicsType: [],
    nonMediaType: [],
    nonMediaSubType: []
  },
  videoStartTime: 0
};

export const video = handleActions<IVideoState, any>(
  {
    [READY]: (state: IVideoState, action: Action<READY>): IVideoState => ({
      ...state,
      isReady: true
    }),

    [METADATA_RECEIVED]: (state: IVideoState, action: Action<METADATA_RECEIVED>): IVideoState => ({
      ...state,
      metadata: action.payload
    }),

    [SUBMIT_METADATA]: (state: IVideoState, action: Action<SUBMIT_METADATA>): IVideoState => ({
      ...state,
      submitMetadata: action.payload
    }),

    [UPDATE_SHORTCUTS]: (state: IVideoState, action: Action<UPDATE_SHORTCUTS>): IVideoState => ({
      ...state,
      shortcuts: {
        type: action.payload.type || state.shortcuts.type || 'default',
        shortcuts: deepCopy(action.payload.shortcuts)
      }
    }),

    [SWITCH_PLAYING]: (state: IVideoState, action: Action<SWITCH_PLAYING>): IVideoState => ({
      ...state,
      playing: action.payload
    }),

    [SWITCH_FAST_FRAME]: (state: IVideoState, action: Action<SWITCH_FAST_FRAME>): IVideoState => ({
      ...state,
      fastFrameInProgress: action.payload.fastFrameInProgress,
      fastFrameType: action.payload.fastFrameType,
      fastFrameIntervalId: action.payload.fastFrameIntervalId
    }),

    [SWITCH_LOOP]: (state: IVideoState, action: Action<SWITCH_LOOP>): IVideoState => ({
      ...state,
      loop: action.payload
    }),

    [SET_LOOP_TIME_IN]: (state: IVideoState, action: Action<SET_LOOP_TIME_IN>): IVideoState => ({
      ...state,
      loop: {
        ...state.loop,
        timeIn: action.payload
      }
    }),

    [SET_LOOP_TIME_OUT]: (state: IVideoState, action: Action<SET_LOOP_TIME_OUT>): IVideoState => ({
      ...state,
      loop: {
        ...state.loop,
        timeOut: action.payload
      }
    }),

    [VOLUME]: (state: IVideoState, action: Action<VOLUME>): IVideoState => ({
      ...state,
      volume: action.payload,
      mute: !action.payload
    }),

    [MUTE]: (state: IVideoState, action: Action<MUTE>): IVideoState => ({
      ...state,
      mute: action.payload,
      volume: !action.payload && !state.volume ? 0.5 : state.volume
    }),

    [THIRD_INDICATOR]: (state: IVideoState, action: Action<THIRD_INDICATOR>): IVideoState => ({
      ...state,
      isThirdIndicator: action.payload
    }),

    [PLAYBACK_RATE_CHANGE]: (state: IVideoState, action: Action<PLAYBACK_RATE_CHANGE>): IVideoState => ({
      ...state,
      playbackRate: action.payload
    }),

    [FORWARD_AND_REWIND_RATE_CHANGE]: (
      state: IVideoState,
      action: Action<FORWARD_AND_REWIND_RATE_CHANGE>
    ): IVideoState => ({
      ...state,
      forwardAndRewindRate: action.payload
    }),

    [VIDEO_STOP]: (state: IVideoState, action: Action<VIDEO_STOP>): IVideoState => ({
      ...state,
      isReady: false
    }),

    [UPDATE_PLAYLIST_FRAME_RATE]: (state: IVideoState, action: Action<UPDATE_PLAYLIST_FRAME_RATE>): IVideoState => ({
      ...state,
      playlist: {
        ...state.playlist,
        frameRate: action.payload
      }
    }),

    [CHANGE_VISIBLE_SUBTITLE]: (state: IVideoState, action: Action<CHANGE_VISIBLE_SUBTITLE>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          selectedSub: action.payload
        }
      };
    },

    [ADD_SUBTITLE_FILE]: (state: IVideoState, action: Action<ADD_SUBTITLE_FILE>): IVideoState => ({
      ...state,
      playlist: {
        ...state.playlist,
        externalSubs: [...state.playlist.externalSubs, action.payload]
      }
    }),

    [STOP_FAST_FRAME]: (state: IVideoState, action: Action<STOP_FAST_FRAME>): IVideoState => {
      return {
        ...state,
        fastFrameIntervalId: action.payload
      };
    },

    [GET_EVENT_GROUPS]: (state: IVideoState, action: Action<GET_EVENT_GROUPS>): IVideoState => {
      return {
        ...state,
        eventGroups: action.payload.eventGroups,
        selectedEventGroup: action.payload.selectedEventGroup
      };
    },

    [SET_CHANGED_EVENTS]: (state: IVideoState, action: Action<SET_CHANGED_EVENTS>): IVideoState => {
      return {
        ...state,
        changedEvents: action.payload
      };
    },

    [LOAD_MARKUPS_TYPES_ENUMS]: (state: IVideoState, action: Action<LOAD_MARKUPS_TYPES_ENUMS>): IVideoState => {
      return {
        ...state,
        types: action.payload
      };
    },

    [UPDATE_SELECTED_EVENT_GROUP]: (state: IVideoState, action: Action<UPDATE_SELECTED_EVENT_GROUP>): IVideoState => {
      return {
        ...state,
        selectedEventGroup: action.payload
      };
    },

    [GET_CATEGORIES]: (state: IVideoState, action: Action<GET_CATEGORIES>): IVideoState => {
      return {
        ...state,
        categories: action.payload
      };
    },

    [CHANGE_MEDIA_TIME_FORMAT]: (state: IVideoState, action: Action<CHANGE_MEDIA_TIME_FORMAT>): IVideoState => {
      return {
        ...state,
        displayMediaTimeFormat: action.payload
      };
    },

    [UPDATE_THUMBNAILS_VTT]: (state: IVideoState, action: Action<UPDATE_THUMBNAILS_VTT>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          thumbnailTrack: {
            ...state.playlist.thumbnailTrack,
            jsonVTT: [...action.payload]
          }
        }
      };
    },

    [SET_VIDEO_FRAGMENT_IN_TIME]: (state: IVideoState, action: Action<SET_VIDEO_FRAGMENT>): IVideoState => {
      return {
        ...state,
        currentVideoFragment: {
          ...state.currentVideoFragment,
          outTime: action.payload.outTime,
          inTime: action.payload.inTime,
          lastUpdated: action.payload.lastUpdated
        }
      };
    },

    [SET_VIDEO_FRAGMENT_OUT_TIME]: (state: IVideoState, action: Action<SET_VIDEO_FRAGMENT>): IVideoState => {
      return {
        ...state,
        currentVideoFragment: {
          ...state.currentVideoFragment,
          outTime: action.payload.outTime,
          inTime: action.payload.inTime,
          lastUpdated: action.payload.lastUpdated
        }
      };
    },

    [UPDATE_PLAYLIST_URL]: (state: IVideoState, action: Action<UPDATE_PLAYLIST_URL>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          url: action.payload
        }
      };
    },

    [UPDATE_AUDIO_CONFIG]: (state: IVideoState, action: Action<UPDATE_AUDIO_CONFIG>): IVideoState => {
      // In order to include null value as a possible update
      const selectedConfig = hasProp(action.payload, 'selectedConfig')
        ? action.payload.selectedConfig
        : state.audioConfiguration.selectedConfig;
      return {
        ...state,
        audioConfiguration: {
          ...state.audioConfiguration,
          selectedConfig,
          checkedChannels: action.payload.checkedChannels || state.audioConfiguration.checkedChannels,
          componentIdentifier: action.payload.componentIdentifier || null
        }
      };
    },

    [UPDATE_PLAYLIST_ID]: (state: IVideoState, action: Action<UPDATE_PLAYLIST_ID>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          id: action.payload
        }
      };
    },

    [UPDATE_SWITCHING_CURRENT_TIME]: (
      state: IVideoState,
      action: Action<UPDATE_SWITCHING_CURRENT_TIME>
    ): IVideoState => {
      return {
        ...state,
        audioConfiguration: {
          ...state.audioConfiguration,
          switchingCurrentTime: action.payload
        }
      };
    },

    [PLAYLIST_LOADING_STATE]: (state: IVideoState, action: Action<PLAYLIST_LOADING_STATE>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          error: action.payload ? null : state.playlist.error,
          loading: action.payload
        }
      };
    },

    [PLAYLIST_LOADING_ERROR]: (state: IVideoState, action: Action<PLAYLIST_LOADING_ERROR>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          error: action.payload
        }
      };
    },

    [GET_LANGUAGE_ENUMS]: (state: IVideoState, action: Action<GET_LANGUAGE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          language: action.payload
        }
      };
    },

    [GET_BIT_RATE_MODE_ENUMS]: (state: IVideoState, action: Action<GET_BIT_RATE_MODE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          bitRateMode: action.payload
        }
      };
    },

    [GET_AUDIO_BIT_DEPTH_ENUMS]: (state: IVideoState, action: Action<GET_AUDIO_BIT_DEPTH_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          audioBitDepth: action.payload
        }
      };
    },

    [GET_AUDIO_SUB_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_AUDIO_SUB_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          audioSubType: action.payload
        }
      };
    },

    [GET_AUDIO_CODEC_ENUMS]: (state: IVideoState, action: Action<GET_AUDIO_CODEC_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          audioCodec: action.payload
        }
      };
    },

    [GET_CHANNEL_CONFIG_ENUMS]: (state: IVideoState, action: Action<GET_CHANNEL_CONFIG_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          channelConfig: action.payload
        }
      };
    },

    [GET_CHANNEL_CONFIG_TYPE_ENUMS]: (
      state: IVideoState,
      action: Action<GET_CHANNEL_CONFIG_TYPE_ENUMS>
    ): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          channelConfigType: action.payload
        }
      };
    },

    [GET_ASSET_STATUS_ENUMS]: (state: IVideoState, action: Action<GET_ASSET_STATUS_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          assetStatus: action.payload
        }
      };
    },

    [GET_FILE_WRAPPER_ENUMS]: (state: IVideoState, action: Action<GET_FILE_WRAPPER_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          fileWrapper: action.payload
        }
      };
    },

    [GET_CONTENT_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_CONTENT_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          contentType: action.payload
        }
      };
    },

    [GET_FORMAT_COMPLIANCE_ENUMS]: (state: IVideoState, action: Action<GET_FORMAT_COMPLIANCE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          formatCompliance: action.payload
        }
      };
    },

    [GET_FRAME_RATE_ENUMS]: (state: IVideoState, action: Action<GET_FRAME_RATE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          frameRate: action.payload
        }
      };
    },

    [GET_REFERENCES_NAME_ENUMS]: (state: IVideoState, action: Action<GET_REFERENCES_NAME_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          referencesName: action.payload
        }
      };
    },

    [GET_REFERENCES_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_REFERENCES_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          referencesType: action.payload
        }
      };
    },

    [GET_CHANNEL_MAP_ENUMS]: (state: IVideoState, action: Action<GET_CHANNEL_MAP_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          channelMap: action.payload
        }
      };
    },

    [GET_SUB_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_SUB_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          subType: action.payload
        }
      };
    },

    [GET_VIDEO_CODEC_ENUMS]: (state: IVideoState, action: Action<GET_VIDEO_CODEC_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          videoCodec: action.payload
        }
      };
    },

    [GET_ENCODE_RATE_ENUMS]: (state: IVideoState, action: Action<GET_ENCODE_RATE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          encodeRate: action.payload
        }
      };
    },

    [GET_PICTURE_FORMAT_ENUMS]: (state: IVideoState, action: Action<GET_PICTURE_FORMAT_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          pictureFormat: action.payload
        }
      };
    },

    [GET_SUBTITLE_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_SUBTITLE_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          subtitleType: action.payload
        }
      };
    },

    [GET_BIT_DEPTH_ENUMS]: (state: IVideoState, action: Action<GET_BIT_DEPTH_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          bitDepth: action.payload
        }
      };
    },

    [GET_PIXEL_ASPECT_ENUMS]: (state: IVideoState, action: Action<GET_PIXEL_ASPECT_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          pixelAspect: action.payload
        }
      };
    },

    [GET_DISPLAY_ASPECT_RATIO_ENUMS]: (
      state: IVideoState,
      action: Action<GET_DISPLAY_ASPECT_RATIO_ENUMS>
    ): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          displayAspectRatio: action.payload
        }
      };
    },

    [GET_SCAN_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_SCAN_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          scanType: action.payload
        }
      };
    },

    [GET_PICTURE_ASPECT_RATIO_ENUMS]: (
      state: IVideoState,
      action: Action<GET_PICTURE_ASPECT_RATIO_ENUMS>
    ): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          pictureAspectRatio: action.payload
        }
      };
    },

    [GET_COLOR_SUB_SAMPLING_ENUMS]: (state: IVideoState, action: Action<GET_COLOR_SUB_SAMPLING_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          colorSubSampling: action.payload
        }
      };
    },

    [GET_COLOR_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_COLOR_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          colorType: action.payload
        }
      };
    },

    [GET_TIME_CODE_ENUMS]: (state: IVideoState, action: Action<GET_TIME_CODE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          timeCode: action.payload
        }
      };
    },

    [GET_COLOR_ENCODING_ENUMS]: (state: IVideoState, action: Action<GET_COLOR_ENCODING_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          colorEncoding: action.payload
        }
      };
    },

    [GET_TRANSFER_FUNCTION_ENUMS]: (state: IVideoState, action: Action<GET_TRANSFER_FUNCTION_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          transferFunction: action.payload
        }
      };
    },

    [GET_COLOR_DIFF_ENUMS]: (state: IVideoState, action: Action<GET_COLOR_DIFF_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          colorDiff: action.payload
        }
      };
    },

    [GET_COUNTRIES_ENUMS]: (state: IVideoState, action: Action<GET_COUNTRIES_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          countries: action.payload
        }
      };
    },

    [GET_LANGUAGE_DIALECT]: (state: IVideoState, action: Action<GET_LANGUAGE_DIALECT>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          languageDialect: action.payload
        }
      };
    },

    [GET_COLOR_MODEL_ENUMS]: (state: IVideoState, action: Action<GET_COLOR_MODEL_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          colorModel: action.payload
        }
      };
    },

    [GET_SIGNAL_RANGE_ENUMS]: (state: IVideoState, action: Action<GET_SIGNAL_RANGE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          signalRange: action.payload
        }
      };
    },

    [GET_COLORIMETRY_ENUMS]: (state: IVideoState, action: Action<GET_COLORIMETRY_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          colorimetry: action.payload
        }
      };
    },

    [GET_WHITE_POINT_ENUMS]: (state: IVideoState, action: Action<GET_WHITE_POINT_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          whitePoint: action.payload
        }
      };
    },

    [GET_DYNAMIC_RANGE_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_DYNAMIC_RANGE_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          dynamicRangeType: action.payload
        }
      };
    },

    [GET_DYNAMIC_RANGE_SYSTEM_ENUMS]: (
      state: IVideoState,
      action: Action<GET_DYNAMIC_RANGE_SYSTEM_ENUMS>
    ): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          dynamicRangeSystem: action.payload
        }
      };
    },

    [GET_FUNCTIONS_ENUMS]: (state: IVideoState, action: Action<GET_FUNCTIONS_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          assetFunction: action.payload
        }
      };
    },

    [GET_GRAPHICS_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_GRAPHICS_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          graphicsType: action.payload
        }
      };
    },

    [GET_NON_MEDIA_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_NON_MEDIA_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          nonMediaType: action.payload
        }
      };
    },

    [GET_NON_MEDIA_SUB_TYPE_ENUMS]: (state: IVideoState, action: Action<GET_NON_MEDIA_SUB_TYPE_ENUMS>): IVideoState => {
      return {
        ...state,
        enums: {
          ...state.enums,
          nonMediaSubType: action.payload
        }
      };
    },

    [UPDATE_PLAYBACK_PROXY_STATE]: (state: IVideoState, action: Action<UPDATE_PLAYBACK_PROXY_STATE>): IVideoState => {
      return {
        ...state,
        audioConfiguration: {
          ...state.audioConfiguration,
          proxyState: action.payload
        }
      };
    },

    [UPDATE_STAGE_COUNT]: (state: IVideoState, action: Action<UPDATE_STAGE_COUNT>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          stageCount: action.payload
        }
      };
    },

    [UPDATE_PLAYLIST_STAGING_STATE]: (
      state: IVideoState,
      action: Action<UPDATE_PLAYLIST_STAGING_STATE>
    ): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          staging: action.payload,
          stageCount: action.payload ? 1 : state.playlist.stageCount
        }
      };
    },

    [UPDATE_PLAYLIST_FORCE_STAGING]: (
      state: IVideoState,
      action: Action<UPDATE_PLAYLIST_FORCE_STAGING>
    ): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          forcingStaging: action.payload
        }
      };
    },

    [UPDATE_PLAYLIST_PROXY]: (state: IVideoState, action: Action<UPDATE_PLAYLIST_PROXY>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          proxy: action.payload
        }
      };
    },
    [UPDATE_SELECTED_ASSET_ID]: (state: IVideoState, action: Action<UPDATE_SELECTED_ASSET_ID>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          selectedAssetId: action.payload
        }
      };
    },
    [SET_MARKUPS_ERRORS]: (state: IVideoState, action: Action<SET_MARKUPS_ERRORS>): IVideoState => {
      return {
        ...state,
        markupsErrors: action.payload
      };
    },
    [UPDATE_PLAYLIST_ASSETS]: (state: IVideoState, action: Action<UPDATE_PLAYLIST_ASSETS>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          assets: action.payload
        }
      };
    },
    [UPDATE_PLAYLIST_VIDEO]: (state: IVideoState, action: Action<UPDATE_PLAYLIST_VIDEO>): IVideoState => {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          video: action.payload
        }
      };
    },
    [UPDATE_VIDEO_START_TIME]: (state: IVideoState, action: Action<UPDATE_VIDEO_START_TIME>): IVideoState => {
      return {
        ...state,
        videoStartTime: action.payload
      };
    }
  },
  DEFAULT_VIDEO_STATE
);
