import * as React from 'react';
import {defineThumbnailStyleFromCue} from '../../../../utils/utils';
import {IParsedVTT} from '../../../../../@types/parsedVtt';

export interface IThumbnailProps {
  time: number;
  jsonVTT: Array<IParsedVTT>;
}

export class Thumbnail extends React.Component<IThumbnailProps> {
  getThumbnailStyle() {
    const time = (this.props.time || 0) * 1000;

    const selectedCue =
      this.props.jsonVTT &&
      this.props.jsonVTT.find((record: IParsedVTT) => {
        return record.start <= time && record.end >= time && !!record.part;
      });

    if (!(selectedCue && selectedCue.part)) {
      return null;
    }

    return defineThumbnailStyleFromCue(selectedCue) as React.CSSProperties;
  }

  render() {
    const style = this.getThumbnailStyle();
    const thumbnailImage = [
      'thumbnail-tooltip-container_wrapper_image',
      !style && 'thumbnail-tooltip-container_wrapper_image--empty'
    ].filter(x => x);

    return (
      <div className="thumbnail-tooltip-container">
        <div className={thumbnailImage.join(' ')}>
          <div
            className="thumbnail-tooltip-container_wrapper_image_thumbnail"
            style={style || {width: 75, height: 1}}
          />
        </div>
      </div>
    );
  }
}
