import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const AudioIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '13px'}
      height={props.size || '15px'}
      viewBox="0 0 13 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-151.000000, -960.000000)" fill={props.color || '#FFFFFF'}>
          <g transform="translate(140.000000, 948.000000)">
            <g transform="translate(0.000000, 6.000000)">
              <g transform="translate(5.000000, 1.000000)">
                <g transform="translate(6.000000, 5.000000)">
                  <path
                    d="M11.762,9.552 C11.617,9.552 11.473,9.489 11.374,9.368 C11.2,9.154 11.232,8.839 
                    11.446,8.665 C11.798,8.378 12,7.954 12,7.501 C12,7.048 11.798,6.623 11.446,6.337 
                    C11.232,6.163 11.199,5.848 11.374,5.634 C11.549,5.42 11.863,5.387 12.077,5.562 C12.664,
                    6.039 13,6.746 13,7.502 C13,8.258 12.663,8.965 12.077,9.442 C11.984,9.517 11.873,
                    9.554 11.762,9.554 L11.762,9.552 Z M8.15,0.207 L8.15,0.206 C8.573,0.206 9,0.529 9,1.249 
                    L9,13.749 C9,14.021 8.939,14.25 8.82,14.429 C8.666,14.659 8.422,14.791 8.15,14.791 
                    C7.903,14.791 7.655,14.687 7.414,14.482 L3.316,10.999 L1.5,10.999 C0.673,10.999 0,10.326 0,
                    9.499 L0,5.499 C0,4.672 0.673,3.999 1.5,3.999 L3.316,3.999 L7.414,0.516 C7.656,0.311 7.903,
                    0.207 8.15,0.207 Z M1,9.5 C1,9.776 1.224,10 1.5,10 L3,10 L3,5 L1.5,5 C1.224,5 1,5.224 1,
                    5.5 L1,9.5 Z M8,13.669 L8,1.331 L4,4.731 L4,10.269 L8,13.669 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
