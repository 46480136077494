import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const GuideDefaultIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/guide_default</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/guide_default" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="idea" transform="translate(5.000000, 4.000000)" fillRule="nonzero">
          <path
            d="M5.74509804,18.1415094 L6.64705882,18.1415094 L6.64705882,
                    19.5 L8.35294118,19.5 L8.35294118,18.1415094 L9.25490196,18.1415094 L9.25490196,
                    13.8387674 L9.59804107,13.7253935 C12.3315267,12.8222439 14.2156863,10.2544136 14.2156863,
                    7.32075472 C14.2156863,3.59507121 11.2085515,0.575471698 7.5,0.575471698 C3.79144845,
                    0.575471698 0.784313725,3.59507121 0.784313725,7.32075472 C0.784313725,
                    10.2544136 2.66847328,12.8222439 5.40195893,13.7253935 L5.74509804,
                    13.8387674 L5.74509804,18.1415094 Z"
            id="Path"
            stroke="#FFFFFF"
          />
          <path
            d="M2.53921569,7.77358491 C2.28982353,7.77358491 2.08823529,
                    7.57116981 2.08823529,7.32075472 C2.08823529,4.32437736 4.51586275,
                    1.88679245 7.5,1.88679245 C7.74939216,1.88679245 7.95098039,2.08920755 7.95098039,
                    2.33962264 C7.95098039,2.59003774 7.74939216,2.79245283 7.5,2.79245283 C5.01329412,
                    2.79245283 2.99019608,4.82384906 2.99019608,7.32075472 C2.99019608,7.57116981 2.78860784,
                    7.77358491 2.53921569,7.77358491 Z"
            id="Path"
            fill="#FFFFFF"
          />
          <polygon
            id="Path"
            fill="#FFFFFF"
            points="5.24509804 15.4716981 5.24509804
                    18.6415094 6.14705882 18.6415094 6.14705882 20 8.85294118 20 8.85294118
                     18.6415094 9.75490196 18.6415094 9.75490196 15.4716981"
          />
        </g>
      </g>
    </svg>
  );
};
