import * as React from 'react';
import ReactDOM from 'react-dom';
import * as isEqual from 'deep-equal';
import {Subject as RxJsSubject, Subscription} from 'rxjs';
import {throttleTime as rxJsThrottleTime} from 'rxjs/operators';
import {IPlaylistAsset} from '../../state';
import {IAssetStatus} from '../../../@types/assetStatus';
import {IShortcutPush} from '../../../@types/shortcutPush';
import {sizeMe} from 'tt-components/src/sizeMe';
import {Icon} from 'tt-components/src/Icon';
import {ShortcutHandler} from 'tt-components/src/ShortcutHandler';
import {PlayerLayer} from 'tt-components/src/TTLayers/PlayerLayer';
import {IPlayerMetadata} from 'tt-components/src/VideoPlayer/playerProps';
import {IVideoPlayer} from 'tt-components/src/VideoPlayer/IVideoPlayer';
import {IPlayerObservable, Observable} from 'tt-components/src/VideoPlayer/Observable';
import {triggerNotification} from 'tt-components/src/Notifications/notifications';
import {SeekType} from '../OnePlayerControlBar/onePlayerControlBarProps';
import {Reactive} from 'tt-components/src/Reactive';
import {ThirdsIndicator} from 'tt-components/src/TTLayers/ThirdsIndicator';
import {OnePlayerControlBar} from '../OnePlayerControlBar';
import {
  IVideoPlayerProps,
  ShallowPlayer,
  YouTubePlayer,
  BitMovinPlayer,
  IBitMovinPlayerProps,
  IBitMovinPlayerUiTemplate,
  IBitMovinPlayerErrors,
  IErrorEvent
} from 'tt-components/src/VideoPlayer';
import {ICSSPropList} from 'tt-components/src/types/types';
import {ITemplateColors} from '../../../@types/templateColors';
import {DiagnosticBox} from '../Overlays/DiagnosticBox';
import {FastFrameType, BitMovinErrors} from '../../constants/constants';
import Loading from '../PlayerOverlays/Loading';
import NotFound from '../PlayerOverlays/NotFound';
import {
  fullScreenHelper,
  isEdge,
  isBlockingAssetState,
  parseQualityLevelMetrics,
  getPlayerType,
  getStartTimecodeTimestampFromEvents,
  getOnRange
} from '../../utils/utils';
import {serviceProvider} from '../../services/serviceProvider';
import {IThumbnailPreviewData, ThumbnailTooltip} from '../ThumbnailTooltip';
import Timeline from '../../modules/Timeline';
import Tabs from '../../modules/Tabs';
import {NotificationComponent} from '../Notification';
import {PlaybackSetup} from '../Overlays/PlaybackSetup';
import {ForceStaging} from '../Overlays/ForceStaging';
import {PlayerSetupError} from '../Overlays/PlayerSetupError';
import {PlaybackRestore} from '../Overlays/PlaybackRestore';
import {IHandleControlBar, holderHandleControlBar} from '../../utils/handleControlBarHelper';
import {StagingService} from '../../services/StagingService';
import {PlaybackCurrentTime} from '../../utils/storage/PlaybackCurrentTime';
import {Shortcuts} from '../Shortcuts';
import {GuideActiveIcon} from '../../assets/Icons/GuideActiveIcon';
import {GuideDefaultIcon} from '../../assets/Icons/GuideDefaultIcon';
import {LayoutGroup} from '../ButtonsGroup/components/LayoutGroup';
import {ILayoutType} from '../../../@types/layoutType';
import {Reparentable} from '../Reparentable/Reparentable';
import {TitleHeader} from '../TitleHeader';
import {OptionsGrid} from '../OptionsGrid';
import {AssetSelect} from '../AssetSelect';
import {ConformanceOverlay} from '../Overlays/ConformanceOverlay';
import {ISearchTitle} from '../../../@types/searchTitle';
import {IPlayerConnectedProps, IPlayerDispatch, IPlayerOwnProps} from '../../containers/VideoPlayer';
import Actions from '../../containers/Actions';
import {PlayerError} from '../../models/PlayerError/PlayerError';
import {Smpte} from '../../models/Smpte/Smpte';
const AdaptivePlayerLayer = sizeMe()(PlayerLayer);

interface IPlayerState {
  baseTime: number;
  scale: number;
  isFullScreen: boolean;
  isShowShortcuts: boolean;
  videoClock: IPlayerObservable;
  textCueClock$: IPlayerObservable;
  videoFirstInit: boolean;
  videoNotFound: boolean;
  waitingPlayback: Date;
  isBuffering: boolean;
  isLoadingSegment: boolean;
  thumbnailPreviewData: IThumbnailPreviewData;
  isControlBarShown: boolean;
  controlBarMouseOverTimerId: number;
  currentBitRate: number;
  timelineStyle: React.CSSProperties;
  switchAudioConfiguration: boolean;
  handleControlBar: IHandleControlBar;
  selectedLayout: ILayoutType;
  // NOTE: Use this flag to define if restore is initiated for selected asset
  // and we will need to restart it after every new asset is selected
  playbackRestored: boolean;
  // NOTE: This flag will be used to define when Video Player component is mounted
  // and the proxy is created properly, before allowing player rendering
  renderPlayer: boolean;
  // NOTE: This instance will keep the error object that will be thrown from the Player
  playerError: PlayerError;
  autoPlay: boolean;
}

type IPlayerProps = IPlayerConnectedProps & IPlayerDispatch & IPlayerOwnProps;

export class VideoPlayer extends React.PureComponent<IPlayerProps, IPlayerState> {
  player: IVideoPlayer;
  fastForwardInterval: number;
  videoPlayerContainer: HTMLDivElement;
  timelineWrapperRefElement: HTMLDivElement;
  onUserActionOnPlayer$: RxJsSubject<void>;
  onShortcutPush$: RxJsSubject<IShortcutPush>;
  stagingService: StagingService;
  videoClockSubscription: Subscription;
  readonly timelineContainer: HTMLElement = document.createElement('div');

  readonly handlePlayer = {
    onEnded: () => this.props.switchPlaying(false),
    onReady: player => {
      this.state.videoClock.dispose();
      this.unSubscribeVideoClock();

      this.setState(
        {
          videoClock: Observable.fromPlayer(player),
          textCueClock$: Observable.fromPlayer(player, 100),
          waitingPlayback: null,
          isBuffering: false,
          isLoadingSegment: false
          //videoPlayer: this.state.videoPlayer || player.player
        },
        () => {
          this.videoClockSubscription = this.state.videoClock.subscribe(this.onVideoCurrentTime);
        }
      );

      this.props.onReady(player);

      // TODO: Define better approach to start playlist at defined startTime when submitted
      if (this.state.videoFirstInit && this.props.playlist.startTime) {
        this.state.handleControlBar.seek(this.props.playlist.startTime, SeekType.toTime);

        this.setState({
          videoFirstInit: false,
          videoClock: Observable.fromPlayer(this.player)
        });
      }

      // Once the new source is loaded we need to play it
      // at the same timecode that the switch was performed
      if (this.props.audioConfiguration.switchingCurrentTime) {
        // NOTE: Enable in cases we will need the functionality, for now we can start video from the beginning
        // Retrieve the timecode that the Player had before the switch was performed
        // const currentTimeStorage = new PlaybackCurrentTime();
        // const timecode = currentTimeStorage.get();
        this.state.handleControlBar.seek(0, SeekType.toTime);
        this.props.updateSwitchingCurrentTime(false);
        triggerNotification(
          {
            type: 'success',
            title: 'Playback',
            message: 'Audio configuration updated!',
            delay: 2500
          },
          null
        );
      }
    },

    onMetadata: value => {
      // Once video metadata are loaded we can check for thumbnail tracks
      this.props.onMetadata(value);
      if (this.props.playlist.thumbnailTrack) {
        this.props.updateThumbnailsVtt();
      }
      if (this.state.autoPlay && getPlayerType(this.props.playlist.url || '') !== 'Shallow') {
        this.props.switchPlaying(true);
      }
      this.getVideoQualityLevels();
    },

    onPlaybackWaiting: () => {
      if (this.props.playing) {
        if (isEdge) {
          // TODO: Add a proper handler for the Edge browser to detect buffering state
          // and listen to the readyState update which for the moment is not supported
        } else {
          this.setState({isBuffering: true, waitingPlayback: new Date()});
        }
      }
    },

    onPlaybackStallEnded: () => {
      if (this.state.waitingPlayback) {
        // Find the interval in milliseconds for the buffering process
        const onBufferStartTime = this.state.waitingPlayback.getTime();
        const onBufferEndTime = new Date().getTime();
        serviceProvider.gtm.fireVideoBuffering(this.props.playlist.title, onBufferEndTime - onBufferStartTime);
      }
      this.setState({isBuffering: false, waitingPlayback: null});
    },

    onVideoQualityChanged: (newQualityId: string) => {
      if (!newQualityId) {
        return;
      }
      this.props.updateLoadedLevel(newQualityId);
    },

    onSegmentLoaded: (bitrate: number, failed?: boolean) => {
      const bitrateInMegaBytes = bitrate / 1000 / 1000;

      const updatedState: any = {
        // Convert bitrate to mb/s
        currentBitRate: isNaN(bitrateInMegaBytes) ? 0 : bitrateInMegaBytes,
        // For cases when we will lose fragments on request, player will try
        // to re-fetch them so we need to show proper overlay to the user
        isLoadingSegment: failed
      };

      // In case the segment loading has not failed, we can remove any playerError instance
      if (!failed) {
        updatedState.playerError = null;
      }

      this.setState({...updatedState});
      // Update values for current and total drop frames each passed half minute
      this.props.updateDroppedFramesMetrics();
    },

    onError: (errorEvent: IErrorEvent) => {
      console.log('VideoPlayer error: ', errorEvent);
      const error = new PlayerError(errorEvent);
      // Custom errors
      if (error.isCustomErrors()) {
        if (error.name === IBitMovinPlayerErrors.INTERNAL_ERROR) {
          this.setState({playerError: error}, () => this.props.updateSwitchingCurrentTime(false));
        }
      }
      // General errors
      if (error.isGeneralError()) {
        // Handle general errors
      }
      // Setup errors
      if (error.isSetupError()) {
        this.props.updateAudioConfig({selectedConfig: null});
        this.props.updateAudioConfig({checkedChannels: {}});
        this.props.updateSwitchingCurrentTime(false);
        this.props.updatePlaybackProxyState(null);
        this.setState({playerError: error});
      }
      // Network errors
      if (error.isNetworkError()) {
        triggerNotification(
          {
            type: 'error',
            title: 'Network Error',
            message: 'Player is facing some network connectivity problems',
            delay: 2500
          },
          null
        );
        this.setState({playerError: error});
      }
      // Source errors
      if (error.isSourceError()) {
        if (
          [BitMovinErrors.SourceManifestInvalid, BitMovinErrors.SourceCouldNotLoadManifest].indexOf(error.name) !== -1
        ) {
          // Invalid manifest
          this.props.updatePlaylistForceStaging(true);
          this.props.updateSwitchingCurrentTime(false);
        }
      }
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      scale: 1,
      baseTime: 0,
      isFullScreen: false,
      isShowShortcuts: false,
      videoFirstInit: true,
      videoClock: Observable.emptyPlayer(),
      textCueClock$: Observable.emptyPlayer(),
      videoNotFound: false,
      waitingPlayback: null,
      isBuffering: false,
      isLoadingSegment: false,
      thumbnailPreviewData: {
        timePosition: null,
        leftPosition: 0,
        mostLeftPosition: 0,
        bottomPosition: style.thumbnailPreview.bottom as number,
        jsonVTT: (props.playlist.thumbnailTrack && props.playlist.thumbnailTrack.jsonVTT) || [],
        seekBarWidth: 0
      },
      isControlBarShown: true,
      controlBarMouseOverTimerId: null,
      timelineStyle: style.timelineRootElement,
      switchAudioConfiguration: false,
      handleControlBar: holderHandleControlBar,
      selectedLayout: this.showTabs() ? 'smallPlayer' : 'bigPlayer',
      playbackRestored: false,
      currentBitRate: 0,
      renderPlayer: false,
      playerError: null,
      autoPlay: !props.autoSelectPlaybackConfig
    };
  }

  componentWillMount() {
    this.onUserActionOnPlayer$ = new RxJsSubject();
    this.onUserActionOnPlayer$.pipe(rxJsThrottleTime(300)).subscribe(this.onUserActionOnPlayer.bind(this));
    this.onShortcutPush$ = new RxJsSubject();
  }

  componentDidMount() {
    console.log('One Player Mounted!!!');
    window.addEventListener('resize', this.defineTimelineStyles);
    if (this.props.playlist.url.startsWith('s3://')) {
      this.props.getHybrikPlaylistUrl();
    }
    this.props.loadEnums();
    this.props.getAssetsData();
    this.videoPlayerContainer.addEventListener('fullscreenchange', this.manageFullScreenModeChange);
    this.onUserActionOnPlayer();
    this.defineTimelineStyles();
    // In case the Timeline component is configured to be rendered we need to show it automatically
    if (appConfig.view.showTimeline) {
      this.props.updateTimelineView(appConfig.view.showTimeline);
    }
    if (this.props.playlist.url) {
      this.props.updatePlaylistProxy(this.props.playlist.url);
      this.props.updatePlaylistUrl('');
    }
    this.setState({renderPlayer: true});
  }

  componentWillUnmount() {
    this.state.videoClock.dispose();
    this.videoPlayerContainer.removeEventListener('fullscreenchange', this.manageFullScreenModeChange);
    window.removeEventListener('resize', this.defineTimelineStyles);
    this.onUserActionOnPlayer$.unsubscribe();
    if (this.stagingService) {
      this.stagingService.clearTimeoutRef();
    }
    // @ts-ignore
    if (this.player && this.player.isBitmovinPlayer) {
      //@ts-ignore
      this.player.unloadSource();
    }
    this.unSubscribeVideoClock();
  }

  componentDidUpdate(prevProps: IPlayerConnectedProps, prevState: IPlayerState) {
    if (this.props.playlist && this.props.playlist.url) {
      this.setState({
        videoNotFound: false
      });
    }

    //reset player
    if (prevProps.isReady === false && this.props.isReady === true) {
      this.props.seek(0, SeekType.toTime);
      this.player.stop();
    }

    if (
      prevProps.videoOptions.widthOptions &&
      this.props.videoOptions.widthOptions &&
      prevProps.videoOptions.widthOptions.width !== this.props.videoOptions.widthOptions.width
    ) {
      this.defineTimelineStyles();
    }

    const loadingStateChanged = prevProps.playlist.loading !== this.props.playlist.loading;
    const proxyStateChanged = prevProps.audioConfiguration.proxyState !== this.props.audioConfiguration.proxyState;
    const forcingStagingStateChanged = prevProps.playlist.forcingStaging !== this.props.playlist.forcingStaging;
    const internalErrorFlagChanged = !isEqual(prevState.playerError, this.state.playerError);
    if (loadingStateChanged || proxyStateChanged || forcingStagingStateChanged || internalErrorFlagChanged) {
      this.props.showPlaybackSetup(this.isLoading());
      // If playlist loaded and all assets are staged we should enable all controls functionalities
      this.setState({handleControlBar: this.isLoading() ? holderHandleControlBar : this.getHandleControlBarConf()});
    }

    if (prevProps.playlist.staging !== this.props.playlist.staging && this.props.playlist.staging) {
      this.stagingService = new StagingService(this.props.playlist.id);
    } else if (prevProps.playlist.staging !== this.props.playlist.staging && !this.props.playlist.staging) {
      if (this.stagingService) {
        this.stagingService.clearTimeoutRef();
        this.stagingService = null;
      }
    }

    if (proxyStateChanged) {
      this.handleProxyState(this.props.audioConfiguration.proxyState, prevProps.audioConfiguration.proxyState);
    }

    if (this.stagingService && prevProps.playlist.stageCount !== this.props.playlist.stageCount) {
      this.stagingService.init((assets: Array<IPlaylistAsset>) => {
        this.props.updateAudiosStagingState(assets);
        // NOTE: Commented out for some performance issues related with subtitles implementation
        // this.props.onUpdateSubtitlesUrl();
        const count = this.props.playlist.stageCount + 1;
        this.props.updateStageCount(count);
      });
    }

    if (prevProps.audioConfiguration.switchingCurrentTime !== this.props.audioConfiguration.switchingCurrentTime) {
      this.setState({switchAudioConfiguration: this.props.audioConfiguration.switchingCurrentTime});
    }

    if (prevProps.audioConfiguration.selectedConfig !== this.props.audioConfiguration.selectedConfig) {
      this.setState({playbackRestored: false});
    }

    if (loadingStateChanged && !this.props.playlist.loading) {
      // Re-defined Timeline styles after playlist loading so we can avoid overlaping of layers
      this.defineTimelineStyles();
    }

    if (
      this.props.tabsDataInEditMode !== prevProps.tabsDataInEditMode &&
      this.props.tabsDataInEditMode &&
      this.state.selectedLayout === 'bigTimeLine'
    ) {
      // In case user is in 'Big Timeline' view and starts editing we can switch to 'Small Player' view
      this.onLayoutUpdated('smallPlayer');
    }

    if (!isEqual(prevProps.changedEvents, this.props.changedEvents)) {
      // Events from changedEvents prop have the latest upated copy of asset events so we can relay on this updates
      // to check if the value of 'Start Timecode' event has been changed or updated
      const videoStartTime = getStartTimecodeTimestampFromEvents(this.props.changedEvents, this.props.frameRate);
      // NOTE: In case the asset information is being updated and we have a valid videoStartTime value and the use of
      // 'Start Timecode' event is disabled, then we will set videoStartTime to 0
      const updatedVideoStartTime =
        this.props.tabsDataInEditMode && videoStartTime && !this.props.useStartTimecode ? 0 : videoStartTime;
      this.props.updateVideoStartTime(updatedVideoStartTime);
    }
  }

  unSubscribeVideoClock = () => {
    if (this.videoClockSubscription) {
      this.videoClockSubscription.unsubscribe();
    }
  };

  onVideoCurrentTime = (currentTime: number) => {
    const {loop, videoMetadata, playing} = this.props;
    const start = loop.loop === 'segmentVideo' ? loop.timeIn : 0;
    const end = loop.loop === 'segmentVideo' ? loop.timeOut : videoMetadata && videoMetadata.duration;
    const intervalCondition = currentTime < start || currentTime >= end;
    // Add exception for case when both timeIn and timeOut are at initial state
    const isEnd = Math.floor(currentTime) === Math.floor(end);
    if (loop.loop !== 'off' && intervalCondition && isEnd) {
      this.state.handleControlBar.seek(start, SeekType.toTime);
      if (!playing) {
        this.state.handleControlBar.switchPlaying();
      }
    }
  };

  defineTimelineStyles = () => {
    if (this.timelineWrapperRefElement) {
      this.setState({timelineStyle: {...this.state.timelineStyle, width: 0}}, () => {
        const {width} = this.timelineWrapperRefElement.getBoundingClientRect();
        this.setState({timelineStyle: {...this.state.timelineStyle, width: width > 0 ? width : null}});
      });
    }
  };

  emptyAudioConfigurations = (withProxyValidation: boolean = true): boolean => {
    return ![
      ...this.props.embeddedAudioChannelConfigs,
      ...this.props.externalAudioChannelConfigs,
      this.props.playlist.video,
      withProxyValidation ? this.props.playlist.proxy : null
    ].filter(config => config).length;
  };

  isLoading = () => {
    // NOTE: Loading state for the player should block all the functionalities for modules as well
    return this.props.playlist.loading || this.isBlocking();
  };

  isBlocking = () => {
    return (
      isBlockingAssetState(this.props.audioConfiguration, this.props.playlist) ||
      this.emptyAudioConfigurations() ||
      !!this.state.playerError
    );
  };

  getHandleControlBarConf = (): IHandleControlBar => {
    return {
      seek: (value: number, type: SeekType = SeekType.timeLeap, seekBarUpdated: boolean = false) => {
        if (seekBarUpdated) {
          this.props.updateScrubBar();
        }
        this.props.seek(value, type);
      },
      goto: (value: number) => {
        this.props.goToLocation(value);
      },
      switchPlaying: () => {
        const {playing, switchPlaying, stopFastFrame, fastFrameInProgress, switchFastFrame} = this.props;
        switchPlaying(!playing);
        stopFastFrame();
        if (fastFrameInProgress) {
          switchFastFrame(false);
        }
        clearInterval(this.fastForwardInterval);
      },
      switchLoop: loop => {
        const {switchLoop} = this.props;
        switchLoop(loop);
      },
      onPlaybackRateChange: playbackRate => {
        this.props.onPlaybackRateChange(playbackRate);
      },
      onForwardAndRewindRateChange: forwardAndRewindRate => {
        this.props.onForwardAndRewindRateChange(forwardAndRewindRate);
      },
      onFastBackward: () => {
        const {switchFastFrame, fastFrameInProgress} = this.props;

        switchFastFrame(!fastFrameInProgress, FastFrameType.fastRewind);
      },
      onFastForward: () => {
        const {fastFrameInProgress, switchFastFrame} = this.props;

        switchFastFrame(!fastFrameInProgress, FastFrameType.fastForward);
      },
      onStart: () => {
        const {playing, switchPlaying} = this.props;

        if (playing) {
          switchPlaying(false);
        }

        if (this.player.getCurrentTime()) {
          setTimeout(() => this.props.seek(0, SeekType.toTime), 100);
        }
      },
      onEnd: () => {
        const {playing, switchPlaying, videoMetadata} = this.props;
        const duration = videoMetadata && videoMetadata.duration;

        if (playing) {
          switchPlaying(false);
        }

        if (this.player.getCurrentTime()) {
          setTimeout(() => this.props.seek(duration, SeekType.toTime), 100);
        }
      },
      switchThirdIndicator: () => {
        const {isThirdIndicator, toggleThirdIndicator} = this.props;
        toggleThirdIndicator(!isThirdIndicator);
      },
      onFirstFrame: () => {
        this.props.seek(0, SeekType.toTime);
      }
    };
  };

  handleProxyState = (currentProxyState: IAssetStatus, previosProxyState: IAssetStatus) => {
    switch (currentProxyState) {
      case 'Staged':
        console.log('Proxy state: Staged');
        if (previosProxyState !== 'Error') {
          this.props.updatePlaybackAudio();
        }
        break;
      case 'Copying':
      case 'Restoring':
        console.log(`Proxy state: ${currentProxyState}`);
        break;
      case 'Checking':
        console.log('Proxy state: Checking');
        break;
      case 'Initial':
        console.log('Proxy state: Initial');
        break;
      case 'Error':
        console.log('Proxy state: Error');
        break;
      default:
        console.log('Proxy state', this.props.audioConfiguration.proxyState);
    }
  };

  manageFullScreenModeChange = event => {
    const isFullScreen = !!fullScreenHelper.getFullScreenElement() && event.target === this.videoPlayerContainer;
    if (isFullScreen) {
      serviceProvider.gtm.fireFullScreen(this.props.playlist.title);
    }
    this.setState({isFullScreen});
  };

  onFullScreen = doFullScreen => {
    const element = this.videoPlayerContainer;

    doFullScreen ? fullScreenHelper.requestFullscreen(element) : fullScreenHelper.exitFullscreen();
  };

  handleShortcutIcon = () => {
    const {isShowShortcuts} = this.state;
    this.setState({
      isShowShortcuts: !isShowShortcuts
    });
  };

  handleShortcutClose = e => {
    const {isShowShortcuts} = this.state;
    e.preventDefault();
    if (isShowShortcuts) {
      this.setState({
        isShowShortcuts: false
      });
    }
  };

  onVideoClick = e => {
    //ignore click on subtitle
    if (
      e.target.closest('.video-subtitle-grid td') //CCRenderer
    ) {
      return;
    }

    this.state.handleControlBar.switchPlaying();
  };

  getCases = definitions => {
    let shortcuts = {};
    definitions.forEach(x => (shortcuts[x.projectAction] = x.shortcutKey.toLowerCase()));
    return shortcuts;
  };

  handleShortcutPressed = (shortcut: string) => {
    const cases = this.getCases(this.props.keyboardShortcuts.shortcuts);
    const {loop} = this.props;
    const {isFullScreen} = this.state;
    const currentTime = this.player.getCurrentTime();
    serviceProvider.gtm.fireKeyboardShortcut(this.props.playlist.title, shortcut);

    switch (shortcut) {
      case cases['play_pause']:
        this.state.handleControlBar.switchPlaying();
        break;
      case cases['frameForward']:
        this.state.handleControlBar.seek(1, SeekType.frameLeap);
        this.changeControlBarVisibility(true);
        break;
      case cases['frameRewind']:
        this.state.handleControlBar.seek(-1, SeekType.frameLeap);
        this.changeControlBarVisibility(true);
        break;
      case cases['forward10s']:
        this.state.handleControlBar.seek(10, SeekType.timeLeap);
        break;
      case cases['backward10s']:
        this.state.handleControlBar.seek(-10, SeekType.timeLeap);
        break;
      case cases['fastForward']:
        this.state.handleControlBar.onFastForward();
        break;
      case cases['fastRewind']:
        this.state.handleControlBar.onFastBackward();
        break;
      case cases['toStart']:
        this.state.handleControlBar.onStart();
        break;
      case cases['toEnd']:
        this.state.handleControlBar.onEnd();
        break;
      case cases['toggleDiagnosticsBox']:
        this.props.onDiagnosticBoxChange(!this.props.showDiagnostic);
        break;
      case cases['settings']:
        this.props.onShowSettingsChange(!this.props.isSettingsShow);
        this.changeControlBarVisibility(true);
        break;
      case cases['audio&SubPanel']:
        this.props.onShowAudioSubsChange(!this.props.isAudioSubsShow);
        this.changeControlBarVisibility(true);
        break;
      case cases['playerSpeed']:
        this.props.onShowPlayerSpeedChange(!this.props.isPlayerSpeedShow);
        this.changeControlBarVisibility(true);
        break;
      case cases['toggleFullScreen']:
        this.onFullScreen(!isFullScreen);
        break;
      case cases['toggleLoop']:
        this.props.onShowLoopControlChange(true);
        if (loop.loop === 'off') {
          const newLoop = {loop: 'entireVideo', timeIn: loop.timeIn, timeOut: loop.timeOut};
          this.state.handleControlBar.switchLoop(newLoop);
        } else if (loop.loop === 'entireVideo') {
          const newLoop = {loop: 'segmentVideo', timeIn: loop.timeIn, timeOut: loop.timeOut};
          this.state.handleControlBar.switchLoop(newLoop);
        } else {
          const newLoop = {loop: 'off', timeIn: loop.timeIn, timeOut: loop.timeOut};
          this.state.handleControlBar.switchLoop(newLoop);
        }
        break;
      case cases['markInTime']:
        const {inTime} = this.props.currentVideoFragment;
        this.props.setVideoFragmentInTime(currentTime);
        if (currentTime === inTime) {
          this.onShortcutPush$.next({type: 'InTime'});
        }
        break;
      case cases['markOutTime']:
        const {outTime} = this.props.currentVideoFragment;
        this.props.setVideoFragmentOutTime(currentTime);
        if (currentTime === outTime) {
          this.onShortcutPush$.next({type: 'OutTime'});
        }
        break;
    }
  };

  onSeekBarMovement = (
    timePosition: number,
    leftPosition: number,
    seekBarWidth: number,
    seekBarBoundingLeft: number
  ) => {
    let offsetBetweenContainerAndScrubBar = 0;
    if (this.videoPlayerContainer) {
      const {left} = this.videoPlayerContainer.getBoundingClientRect();
      offsetBetweenContainerAndScrubBar = seekBarBoundingLeft - left;
    }
    this.setState({
      thumbnailPreviewData: {
        ...this.state.thumbnailPreviewData,
        timePosition,
        leftPosition: leftPosition + offsetBetweenContainerAndScrubBar,
        mostLeftPosition: offsetBetweenContainerAndScrubBar,
        seekBarWidth,
        jsonVTT: (this.props.playlist.thumbnailTrack && this.props.playlist.thumbnailTrack.jsonVTT) || []
      }
    });
  };

  changeControlBarVisibility(isControlBarShown) {
    this.setState({isControlBarShown});
  }

  onUserActionOnPlayer() {
    const HIDE_CONTROL_BAR_WAIT_TIME_MS = 5000;
    const {controlBarMouseOverTimerId} = this.state;
    clearTimeout(controlBarMouseOverTimerId);
    let newControlBarMouseOverTimerId = window.setTimeout(() => {
      this.changeControlBarVisibility(false);
    }, HIDE_CONTROL_BAR_WAIT_TIME_MS);
    this.setState({
      controlBarMouseOverTimerId: newControlBarMouseOverTimerId,
      isControlBarShown: true
    });
  }

  getVideoQualityLevels = () => {
    if (!(this.player as any).isBitmovinPlayer) {
      return;
    }
    this.props.updateQualityLevels((this.player as any).getVideoQualityLevels());
    if ((this.player as any).getVideoQuality()) {
      this.props.updateLoadedLevel((this.player as any).getVideoQuality().id);
    }
  };

  defineLoadingText() {
    const {isBuffering, isLoadingSegment, switchAudioConfiguration} = this.state;
    return switchAudioConfiguration
      ? 'Updating audio configuration...'
      : isBuffering
      ? 'Buffering...'
      : isLoadingSegment
      ? 'Loading segment...'
      : 'Loading...';
  }

  defineLoadingStyle() {
    const {isBuffering, isLoadingSegment, switchAudioConfiguration} = this.state;
    return isBuffering || switchAudioConfiguration || isLoadingSegment ? style.bufferingLoader : {};
  }

  onPlaybackRestore = () => {
    this.setState({playbackRestored: true}, () => this.props.getAssetsData(true, true));
  };

  showOverlays() {
    // NOTE: Overlays should have an order of appearing in order to not mesh with each other
    const {
      isThirdIndicator,
      showPlaybackSetupOverlay,
      templateColors,
      playlist,
      updatePlaylistForceStaging,
      getAssetsData,
      audioConfiguration
    } = this.props;
    const {
      videoNotFound,
      switchAudioConfiguration,
      isBuffering,
      isLoadingSegment,
      scale,
      playbackRestored,
      playerError
    } = this.state;
    const isProxy = this.props.playlist.proxy
      ? this.props.audioConfiguration.selectedConfig === this.props.playlist.proxy.id
      : false;
    const emptyAudioConfigurations = (proxyValidation: boolean = true) =>
      this.emptyAudioConfigurations(proxyValidation);

    let overlay: JSX.Element;
    if (playerError && playerError.isSetupError() && !playlist.loading && !playlist.error) {
      return <PlayerSetupError templateColors={templateColors} error={playerError} />;
    } else if (playlist.forcingStaging) {
      return (
        <ForceStaging
          templateColors={templateColors}
          onCancel={updatePlaylistForceStaging}
          onForce={() => getAssetsData(true)}
        />
      );
    } else if (audioConfiguration.proxyState === 'Restoring' && !playbackRestored) {
      return (
        <PlaybackRestore
          templateColors={templateColors}
          onRestore={this.onPlaybackRestore}
          onCancel={() => this.setState({playbackRestored: true})}
        />
      );
    } else if (showPlaybackSetupOverlay) {
      overlay = (
        <PlaybackSetup
          templateColors={templateColors}
          playlistLoading={playlist.loading}
          playlistError={playlist.error}
          assetStatus={audioConfiguration.proxyState}
          selectedConfiguration={audioConfiguration.selectedConfig}
          isProxy={isProxy}
          error={playerError}
          emptyAudioConfigurations={emptyAudioConfigurations}
        />
      );
    } else if (videoNotFound) {
      overlay = <NotFound templateColors={templateColors} />;
    } else {
      overlay = (
        <>
          {(switchAudioConfiguration || isBuffering || isLoadingSegment) && (
            <Loading
              templateColors={templateColors}
              text={this.defineLoadingText()}
              style={this.defineLoadingStyle()}
            />
          )}
        </>
      );
    }

    return (
      <>
        {isThirdIndicator && <ThirdsIndicator scale={scale} />}
        {overlay}
      </>
    );
  }

  handlePlaybackProxyCheck = async () => {
    await new Promise(resolve => this.setState({playerError: null}, resolve));
    this.props.updatePlaylistForceStaging(false);
    // NOTE: Added outside from the flow so we keep the Player with default size
    this.props.updatePlaybackProxyState('Checking');
    // When audio configuration switch will start we need to detach previously added media from the Player
    // so the new one can start playing correctly and we stop loading older chunks
    // @ts-ignore
    if (this.player && this.player.isBitmovinPlayer) {
      this.props.switchPlaying(false);
      // Save current playing time of the playback so it can be used once it will resume
      const currentTimeStorage = new PlaybackCurrentTime();
      currentTimeStorage.set(this.player.getCurrentTime());
      // @ts-ignore
      await this.player.unloadSource();
    }
    this.props.checkPlaybackProxy();
    // Reset currentBitRate after the source is unloaded
    this.setState({currentBitRate: 0});
    // Reset quality metrics once we need to perform an audio configuration change
    this.props.resetQualityMetrics();
  };

  onConformanceGroupAssign = (titles: Array<ISearchTitle>) => {
    this.props.updateUnregisteredConformanceGroup(titles);
    this.props.showConformanceOverlay(false);
  };

  showTabs = () => appConfig.view.tabs.length !== 0;

  onLayoutUpdated = (selectedLayout: ILayoutType) => {
    this.setState({selectedLayout}, this.defineTimelineStyles);
  };

  gridOptions = (templateColors: ITemplateColors, selectedLayout: ILayoutType, isShowShortcuts: boolean) => {
    return [
      <div
        className="layout-and-shortcuts-tooltip-controls_right_shortcut-icon"
        key={0}
        onClick={this.handleShortcutIcon}
      >
        {isShowShortcuts ? (
          <Icon icon={GuideActiveIcon} color={templateColors.main} size="17px" />
        ) : (
          <Icon icon={GuideDefaultIcon} color={templateColors.main} size="17px" />
        )}
      </div>,
      this.showTabs() ? (
        <LayoutGroup key={1} selectedConfig={selectedLayout} onOptionChanged={this.onLayoutUpdated} />
      ) : null
    ].filter(option => option);
  };

  transformCurrentTimeFunction = (timestamp: number) => {
    const {frameRate, dropFrame} = this.props.frameRate;
    const smpte = Smpte.fromTimeWithAdjustments(timestamp, {frameRate, dropFrame});
    return smpte.toAdjustedTime();
  };

  renderPlayer() {
    const shallowMetadata: IPlayerMetadata = {
      duration: 0,
      videoSize: {width: 480, height: 360}
    };

    const videoUrl = this.props.playlist.url || '';

    const ref = x => {
      this.player = x;
      serviceProvider.video.init(this.player);
    };
    const props: IVideoPlayerProps | IBitMovinPlayerProps = {
      url: videoUrl,
      autoPlay: this.state.autoPlay,
      playing: this.props.playing,
      style: {...style.player, maxHeight: '100%', maxWidth: 'unset'},
      volume: this.props.volume,
      mute: this.props.mute,
      playbackRate: this.props.playbackRate,
      scale: this.state.scale,
      ...this.handlePlayer
    };

    const playerType = getPlayerType(videoUrl);

    if (!this.state.renderPlayer || playerType === 'Shallow') {
      const shallowProps = {...props, autoPlay: false};
      // For ShallowPlayer the autoPlay will be always false to avoid running timecode with black screen
      return <ShallowPlayer ref={ref} metadata={shallowMetadata} {...shallowProps} />;
    }

    switch (playerType) {
      case 'YouTube':
        return <YouTubePlayer ref={ref} {...props} />;
      case 'Bitmovin':
        const subtitlesClassName = `op_player_wrapper_bitmovin-container_subtitles${
          this.state.isFullScreen ? `_full-screen` : ``
        }`;
        // Define the template object for the BitMovinPlayer
        const uiTemplate: IBitMovinPlayerUiTemplate = {
          template: 'One Player',
          subtitlesClassName,
          subtitlesBottomPosition: this.state.isControlBarShown ? 85 : 40
        };
        const bitmovinProps = {...props, volume: getOnRange(Math.round((props.volume || 0) * 100), 0, 100)};
        return (
          <BitMovinPlayer
            playerKey={this.props.bitmovinKey}
            onPlayerInit={ref}
            showLogs
            {...bitmovinProps}
            className="op_player_wrapper_bitmovin-container"
            uiTemplate={uiTemplate}
            transformCurrentTimeFunction={this.transformCurrentTimeFunction}
          />
        );
    }
  }

  render() {
    const {
      containerElement,
      selectedAsset,
      userIp,
      userEmail,
      appVersion,
      isReady,
      loop,
      getShortcuts,
      setDefaultShortcuts,
      changeShortcuts,
      keyboardShortcuts,
      playing,
      videoMetadata,
      qualityMetrics,
      mute,
      volume,
      playbackRate,
      forwardAndRewindRate,
      frameRate,
      templateColors,
      showDiagnostic,
      isDiagnosticsPanelEnabled,
      isSettingsShow,
      onShowSettingsChange,
      isAudioSubsShow,
      onShowAudioSubsChange,
      isPlayerSpeedShow,
      onShowPlayerSpeedChange,
      isLoopControlShow,
      onShowLoopControlChange,
      isGoToShow,
      onShowGoToChange,
      isVolumeControlShow,
      onShowVolumeControlChange,
      videoOptions,
      playlist,
      embeddedAudioChannelConfigs,
      externalAudioChannelConfigs,
      embeddedSubtitlesMetadata,
      externalSubtitlesMetadata,
      displayMediaTimeFormat,
      watermarkPosition,
      changeWatermarkPosition,
      changeWatermarkUIPosition,
      timeline,
      updateTimelineView,
      controlBarControls,
      showingDropdownTimeout,
      audioConfiguration,
      updateAudioConfig,
      enums,
      showPlaybackSetupOverlay,
      showConformanceOverlay,
      showConformanceOverlayState,
      curationModeEnabled,
      tabsInEditMode,
      tabsAreProcessing,
      videoStartTime
    } = this.props;

    const {
      scale,
      videoClock,
      isFullScreen,
      isShowShortcuts,
      isBuffering,
      isLoadingSegment,
      isControlBarShown,
      switchAudioConfiguration,
      selectedLayout
    } = this.state;

    const showTabs = this.showTabs();

    const duration = (videoMetadata && videoMetadata.duration) || 0;
    const aspectRatio =
      (videoMetadata && videoMetadata.videoSize && videoMetadata.videoSize.width / videoMetadata.videoSize.height) || 1;
    const {onVolume, onMute} = this.props;

    const passedStyle: {[x: string]: number | string} = {
      width: 0,
      height: 0
    };

    // In order to define width from the options we have three values to check: width, maxWidth, minWidth
    // The prop width will be the default value and will compared against maxWidth and minWidth in order to be defined
    const calculatedWidth = videoOptions.widthOptions
      ? videoOptions.widthOptions.width > videoOptions.widthOptions.maxWidth
        ? videoOptions.widthOptions.maxWidth
        : videoOptions.widthOptions.width < videoOptions.widthOptions.minWidth
        ? videoOptions.widthOptions.minWidth
        : videoOptions.widthOptions.width
      : '100%';

    passedStyle.width = calculatedWidth;

    // Keep ratio 16:9 for HD resolutions and calculate height
    passedStyle.height =
      typeof passedStyle.width !== 'string' ? Math.ceil((passedStyle.width as number) / (16 / 9)) : '100%';

    // Manage styles for case when Player displayed altogether with Tabs module
    let modulesWrapperStyle = {};
    if (showTabs) {
      modulesWrapperStyle = {
        ...style.playerColumn,
        display: 'flex',
        minWidth: 855,
        height: 'unset'
      };
    }

    // In case widthOptions are not defined or Tabs are shown we will need
    // to calculate player styles so it will have fixed dimensions until video is fully loaded
    if (!videoOptions.widthOptions || showTabs) {
      if (!playlist.url || this.isLoading() || switchAudioConfiguration) {
        // NOTE: For case when no URL is defined and we need to define a height
        // that keeps the player container with 16:9 dimensions
        const containerWidth = this.videoPlayerContainer
          ? this.videoPlayerContainer.getBoundingClientRect().width
          : null;
        passedStyle.height = containerWidth ? Math.ceil((containerWidth as number) / (16 / 9)) : '100%';
      } else {
        passedStyle.height = '100%';
      }
    }

    style.playerRoot = {
      ...style.playerRoot,
      height: isFullScreen ? '100%' : passedStyle.height,
      width: isFullScreen ? '100%' : passedStyle.width,
      ...{overflow: scale > 1 ? 'auto' : 'hidden'}
    };

    const playerOverlayStyle = {
      ...style.playerOverlay,
      ...(isBuffering || switchAudioConfiguration || showPlaybackSetupOverlay || isLoadingSegment ? {opacity: 1} : {})
    };

    const shortcuts = keyboardShortcuts.shortcuts.map(x => x.shortcutKey.toLowerCase());
    const preventEventShortcuts = keyboardShortcuts.shortcuts
      .filter(x => x.preventEvent)
      .map(x => x.shortcutKey.toLowerCase());
    const disabledInEditableAreaShortcuts = keyboardShortcuts.shortcuts
      .filter(x => x.disabledInEditableArea)
      .map(x => x.shortcutKey.toLowerCase());

    /** should be removed when we manage to set waterwark data properly on demo page of player on the internet */
    let stubUserIp = '127.0.0.1';
    let stubUserEmail = 'volodymyr.moskalyk@sferastudios.com';
    let stubAppVersion = '1.0.1';

    const bodyContainerElement = this.videoPlayerContainer && this.videoPlayerContainer.closest('body');
    const wrapperContainerElement = containerElement.closest('body > div');

    const showWatermark = true; // disabled due to request from Graig
    const changeWatermarkAction = showWatermark ? changeWatermarkUIPosition : changeWatermarkPosition;

    const loadingAsset = this.isBlocking();
    return (
      <div style={{position: 'relative'}}>
        <ShortcutHandler
          scope={'player'}
          shortcuts={shortcuts}
          preventEventShortcuts={preventEventShortcuts}
          onShortcutPressed={this.handleShortcutPressed}
          disabledInEditableAreaShortcuts={disabledInEditableAreaShortcuts}
          {...(bodyContainerElement ? {nodeToAttach: bodyContainerElement} : {})}
        />
        <div className="layout-and-shortcuts-tooltip-controls" onClick={this.handleShortcutClose}>
          <div className="layout-and-shortcuts-tooltip-controls_left">
            <TitleHeader selectedAsset={selectedAsset} />
          </div>
          <div className="layout-and-shortcuts-tooltip-controls_right">
            <OptionsGrid options={this.gridOptions(templateColors, selectedLayout, isShowShortcuts)} />
          </div>
        </div>
        <div
          style={style.playerRow}
          className={
            selectedLayout !== 'smallPlayer'
              ? ' op_modules-wrapper_big-player-layout'
              : ' op_modules-wrapper_small-player-layout'
          }
          onClick={this.handleShortcutClose}
        >
          <div className="op_modules-wrapper_left-part">
            <div
              style={modulesWrapperStyle}
              className={`op_player${isFullScreen ? ' full-screen' : ''}${showTabs ? ' with-tabs' : ''}${
                !isControlBarShown ? ' with-control-bar-hidden' : ''
              }`}
              ref={node => (this.videoPlayerContainer = node)}
              onMouseEnter={e => this.changeControlBarVisibility(true)}
              onMouseLeave={e => this.changeControlBarVisibility(false)}
              onMouseMove={e => this.onUserActionOnPlayer$.next()}
              onKeyDown={e => this.onUserActionOnPlayer$.next()}
              onClick={e => this.onUserActionOnPlayer$.next()}
            >
              <div className="op_player_wrapper" style={style.playerRoot} onClick={this.onVideoClick}>
                {this.renderPlayer()}
                {(userIp || userEmail || stubUserIp || stubUserEmail) && showWatermark && (
                  <AdaptivePlayerLayer
                    text={`<div class="op_watermark-label ${watermarkPosition}">
                      ${userIp || stubUserIp}:${appVersion || stubAppVersion} <br> ${userEmail || stubUserEmail}
                      </div>`}
                    aspectRatio={aspectRatio}
                  />
                )}

                <Reactive observable={this.state.textCueClock$}>
                  {currentTime => (
                    <div className="player-overlay" style={playerOverlayStyle}>
                      {this.showOverlays()}
                      {isDiagnosticsPanelEnabled && showDiagnostic && (
                        <DiagnosticBox
                          {...parseQualityLevelMetrics(qualityMetrics)}
                          currentBitRate={this.state.currentBitRate}
                          frameRate={frameRate.frameRate}
                          onClose={() => this.props.onDiagnosticBoxChange(!this.props.showDiagnostic)}
                          templateColors={templateColors}
                          dropFrame={playlist.frameRate.dropFrame}
                        />
                      )}
                    </div>
                  )}
                </Reactive>
              </div>
              <ThumbnailTooltip
                data={this.state.thumbnailPreviewData}
                frameRate={frameRate}
                duration={duration}
                isControlBarShown={isControlBarShown}
                showingDropdownTimeout={showingDropdownTimeout}
              />
              <OnePlayerControlBar
                videoClock={videoClock}
                enabled={isReady}
                playing={playing}
                isFullScreen={isFullScreen}
                duration={duration}
                baseTime={this.state.baseTime}
                frameRate={frameRate}
                getShortcuts={getShortcuts}
                setDefaultShortcuts={setDefaultShortcuts}
                changeShortcuts={changeShortcuts}
                keyboardShortcuts={keyboardShortcuts.shortcuts}
                keyboardShortcutsType={keyboardShortcuts.type}
                loop={loop}
                volume={volume}
                mute={mute}
                playbackRate={playbackRate}
                forwardAndRewindRate={forwardAndRewindRate}
                switchLoop={this.state.handleControlBar.switchLoop}
                onFullScreen={this.onFullScreen}
                onPlaybackRateChange={this.state.handleControlBar.onPlaybackRateChange}
                onForwardAndRewindRateChange={this.state.handleControlBar.onForwardAndRewindRateChange}
                onFastBackward={this.state.handleControlBar.onFastBackward}
                onFastForward={this.state.handleControlBar.onFastForward}
                onSwitchPlaying={this.state.handleControlBar.switchPlaying}
                onVolumeChanged={onVolume}
                onMuteChanged={onMute}
                onSeek={this.state.handleControlBar.seek}
                onGoTo={this.state.handleControlBar.goto}
                onStart={this.state.handleControlBar.onStart}
                onEnd={this.state.handleControlBar.onEnd}
                onThirdIndicator={this.state.handleControlBar.switchThirdIndicator}
                onFirstFrame={this.state.handleControlBar.onFirstFrame}
                showNextPrevSubtitleButtons={videoOptions.showNextPrevSubtitleButtons}
                showNextPrevSeekButtons={videoOptions.showNextPrevSeekButtons}
                showNextPrevFrameButtons={videoOptions.showNextPrevFrameButtons}
                onChangeVisibleSubtitle={this.props.onChangeVisibleSubtitle}
                templateColors={templateColors}
                onSeekBarMovement={this.onSeekBarMovement}
                displayMediaTimeFormat={displayMediaTimeFormat}
                changeMediaTimeFormat={this.props.changeMediaTimeFormat}
                watermarkPosition={watermarkPosition}
                changeWatermarkPosition={changeWatermarkAction}
                isControlBarShown={isControlBarShown}
                isSettingsShow={isSettingsShow}
                onShowSettingsChange={onShowSettingsChange}
                isAudioSubsShow={isAudioSubsShow}
                onShowAudioSubsChange={onShowAudioSubsChange}
                isPlayerSpeedShow={isPlayerSpeedShow}
                onShowLoopControlChange={onShowLoopControlChange}
                isLoopControlShow={isLoopControlShow}
                onShowPlayerSpeedChange={onShowPlayerSpeedChange}
                isVolumeControlShow={isVolumeControlShow}
                onShowVolumeControlChange={onShowVolumeControlChange}
                isGoToShow={isGoToShow}
                onShowGoToChange={onShowGoToChange}
                timelineVisibilitySettings={timeline.layersVisisbility}
                updateLayerVisibility={this.props.updateLayerVisibility}
                showTimeline={timeline.showTimeline}
                updateTimelineView={updateTimelineView}
                timelineEnabled={appConfig.view.showTimeline}
                controlBarControls={controlBarControls}
                showingDropdownTimeout={showingDropdownTimeout}
                videoPlayerContainer={this.videoPlayerContainer}
                closestBodyElement={bodyContainerElement}
                audioChannelConfigurations={embeddedAudioChannelConfigs}
                externalAudioChannelConfigurations={externalAudioChannelConfigs}
                selectedSub={playlist.selectedSub}
                embeddedSubtitlesMetadata={embeddedSubtitlesMetadata}
                externalSubtitlesMetadata={externalSubtitlesMetadata}
                audioLanguageEnums={enums.language}
                audioChannelMapEnums={enums.channelMap}
                audioChannelConfigTypeEnums={enums.channelConfigType}
                languageDialectEnums={enums.languageDialect}
                audioConfiguration={audioConfiguration}
                updateAudioConfig={updateAudioConfig}
                loadingPlaylist={playlist.loading}
                loadingPlaylistError={playlist.error}
                loadingAsset={loadingAsset}
                loadingAudioConfig={this.state.switchAudioConfiguration}
                checkPlaybackProxy={this.handlePlaybackProxyCheck}
                proxyConfiguration={playlist.proxy}
                videoConfiguration={playlist.video}
                userEmail={this.props.userEmail}
                userIp={this.props.userIp}
                curationModeEnabled={curationModeEnabled}
                videoStartTime={videoStartTime}
              />
              <NotificationComponent />
            </div>
            {appConfig.view.showTimeline &&
              ReactDOM.createPortal(
                <Timeline
                  rootElementStyles={this.state.timelineStyle}
                  secondUnits={5}
                  playerClock={this.state.videoClock}
                  onSeek={this.state.handleControlBar.seek}
                  switchLoop={this.state.handleControlBar.switchLoop}
                  loadingAudioConfig={this.state.switchAudioConfiguration}
                  checkPlaybackProxy={this.handlePlaybackProxyCheck}
                  loadingAsset={loadingAsset}
                />,
                this.timelineContainer
              )}
            {appConfig.view.showTimeline && selectedLayout === 'smallPlayer' && (
              <Reparentable
                el={this.timelineContainer}
                className="op_modules-wrapper_timeline-wrapper"
                ref={component => (this.timelineWrapperRefElement = component && component.container)}
              />
            )}
          </div>
          <div className="op_modules-wrapper_right-part">
            {appConfig.view.showTimeline && selectedLayout !== 'smallPlayer' && (
              <Reparentable
                el={this.timelineContainer}
                className={`op_modules-wrapper_timeline-wrapper${
                  selectedLayout === 'bigTimeLine' ? ' full_screen-timeline' : ''
                }`}
                ref={component => (this.timelineWrapperRefElement = component && component.container)}
              />
            )}
            {showTabs && selectedLayout !== 'bigTimeLine' && (
              <div className="op_modules-wrapper_right-part_tabs-column">
                <AssetSelect
                  tabsAreProcessing={tabsAreProcessing}
                  assets={playlist.assets}
                  curationModeEnabled={curationModeEnabled}
                  selectedAssetId={playlist.selectedAssetId}
                  loadingPlaylist={this.props.playlist.loading}
                  onAssetSelect={this.props.updateSelectedAssetId}
                  closestBodyElement={wrapperContainerElement as HTMLElement}
                  tabsInEditMode={tabsInEditMode}
                />
                <Tabs
                  closestBodyElement={wrapperContainerElement as HTMLElement}
                  shortcutSubject={this.onShortcutPush$}
                  loadingAsset={loadingAsset}
                  onSeek={this.state.handleControlBar.seek}
                />
              </div>
            )}
          </div>
          {bodyContainerElement &&
            ReactDOM.createPortal(
              <ConformanceOverlay
                assets={playlist.assets || []}
                isOpen={showConformanceOverlayState}
                closestBodyElement={bodyContainerElement as HTMLElement}
                onCancel={() => showConformanceOverlay(false)}
                onAssign={this.onConformanceGroupAssign}
              />,
              bodyContainerElement
            )}
          {showTabs && <Actions closestBodyElement={wrapperContainerElement as HTMLElement} />}
        </div>
        {isShowShortcuts && (
          <Shortcuts
            templateColors={templateColors}
            keyboardShortcuts={keyboardShortcuts.shortcuts}
            onClose={this.handleShortcutClose}
          />
        )}
      </div>
    );
  }
}

const style: ICSSPropList = {
  playerColumn: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  playerRoot: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  player: {
    width: '100%',
    height: '100%',
    maxHeight: 400,
    minHeight: 200,
    background: 'black'
  },
  tableThirdIndicator: {
    width: '100%',
    height: 'calc(100% - 2px)',
    borderCollapse: 'collapse',
    position: 'absolute',
    top: 0
  },
  tableCellThirdIndicator: {
    border: '1px solid #7F887F'
  },
  timelineRootElement: {
    paddingTop: 10,
    paddingBottom: 10
  },
  bufferingLoader: {
    background: 'rgba(0, 0, 0, 0.7)'
  },
  thumbnailPreview: {
    bottom: 65
  },
  playerOverlay: {
    top: 0
  },
  playerRow: {
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
};
