import * as React from 'react';

interface IOptionsGridProps {
  options: Array<JSX.Element>;
  height?: number;
}

export class OptionsGrid extends React.PureComponent<IOptionsGridProps> {
  static defaultProps = {
    height: 40
  };

  divider = (key: number) => {
    return <div key={key} className="options-grid-container_divider" />;
  };

  getOptions = () => {
    const options = [];
    this.props.options.forEach((option: JSX.Element, index: number) => {
      if (index !== 0) {
        options.push(this.divider(this.props.options.length + index));
      }
      options.push(option);
    });
    return options;
  };

  render() {
    return (
      <div className="options-grid-container" style={{height: this.props.height}}>
        {this.getOptions()}
      </div>
    );
  }
}
