import * as React from 'react';
import {Container, Row, Column} from '../../../Grid';
import {Dropdown, IDropdownOption} from '../../../../../../components/Dropdown';
import {Tt1, Tt4a} from '../../../../../../components/Typography';
import {Toggle} from '../../../../../../components/Toggle';
import {IEnums} from '../../../../../../state/IEnums';
import {
  IAssetVideo,
  IAssetDetails,
  IAssetVideoColorEncoding,
  IAssetVideoSubtitleLanguage,
  IErrorLog
} from '../../../../../../../@types/assetDetails';
import {deepCopy, getErrorLogByField} from '../../../../utils/helpers';
import {IEnum} from '../../../../../../../@types/enum';
import {TextField} from '../../../../../../components/TextField';
import {LanguageDropdown} from '../../../LanguageDropdown';

interface IVideoDetailsProps {
  updatedAssetDetails: Partial<IAssetDetails>;
  assetDetails: IAssetDetails;
  video: IAssetVideo;
  videoMetadataErrors: any;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

export class VideoDetails extends React.Component<IVideoDetailsProps> {
  handleClick = (name: string, value, isDetail: boolean = false, subtitleId?: string) => {
    const data = value !== '' ? value : null;
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const assetDetails = deepCopy({...this.props.assetDetails});
    // NOTE: Once one of the video configurations is updated we need to push to updated details all videos
    const videos = (assetDetails.videos || []).reduce((acc: Array<IAssetVideo>, video: IAssetVideo) => {
      if (video.id === this.props.video.id) {
        if (isDetail) {
          video.videoDetail[name] = data;
        } else if (subtitleId) {
          // NOTE: Update subtitle language with provided value and field
          video.subtitleLanguage = video.subtitleLanguage.reduce(
            (acc: Array<IAssetVideoSubtitleLanguage>, subLanguage: IAssetVideoSubtitleLanguage) => {
              if (subLanguage.id === subtitleId) {
                subLanguage[name] = data;
              }
              return [...acc, subLanguage];
            },
            []
          );
        } else {
          video[name] = data;
        }
      }
      return [...acc, video];
    }, []);

    this.props.updatePartialAssetDetails({...updatedAssetDetails, videos});
  };

  convertEnums = enums => {
    let options = [];
    enums
      .filter((item: IEnum) => item.isActive)
      .map((item: IEnum) => {
        options.push({label: item.name, value: item.value} as IDropdownOption);
      });
    return options;
  };

  getColorEncoding = (): IAssetVideoColorEncoding => {
    const colorEncoding =
      this.props.video.videoDetail && this.props.video.videoDetail.colorEncoding
        ? this.props.video.videoDetail.colorEncoding
        : null;
    return (
      colorEncoding || ({primaries: '', transferCharacteristics: '', colorDifferencing: ''} as IAssetVideoColorEncoding)
    );
  };

  onColorEncodingUpdate(name: string, value) {
    const colorEncoding = {...this.getColorEncoding(), [name]: value};
    this.handleClick('colorEncoding', colorEncoding, true);
  }

  getDynamicRange = () => {
    const dynamicRange =
      this.props.video.videoDetail && this.props.video.videoDetail.dynamicRange
        ? this.props.video.videoDetail.dynamicRange
        : null;
    return dynamicRange || {type: '', system: '', toneMapped: {}};
  };

  onDynamicRangeUpdate = (name: string, value, isToneMapped: boolean) => {
    let dynamicRange;
    if (isToneMapped) {
      let toneMapped = {...this.getDynamicRange().toneMapped, [name]: value};
      dynamicRange = {...this.getDynamicRange(), toneMapped};
    } else {
      dynamicRange = {...this.getDynamicRange(), [name]: value};
    }
    this.handleClick('dynamicRange', dynamicRange, true);
  };

  getSt2086 = () => {
    const st2086 =
      this.props.video.videoDetail && this.props.video.videoDetail.st2086 ? this.props.video.videoDetail.st2086 : null;
    return st2086 || {whitePoint: '', primaries: '', displayLuminanceMax: '', displayLuminanceMin: ''};
  };

  onSt2086Update = (name: string, value) => {
    const st2086 = {...this.getSt2086(), [name]: value};
    this.handleClick('st2086', st2086, true);
  };

  getMaxFall = () => {
    const maxFall =
      this.props.video.videoDetail && this.props.video.videoDetail.maxfall
        ? this.props.video.videoDetail.maxfall
        : null;
    return maxFall || {actual: '', client: ''};
  };

  onMaxFallUpdate = (name: string, value) => {
    const maxFall = {...this.getMaxFall(), [name]: value};
    this.handleClick('maxfall', maxFall, true);
  };

  getMaxCll = () => {
    const maxCll =
      this.props.video.videoDetail && this.props.video.videoDetail.maxcll ? this.props.video.videoDetail.maxcll : null;
    return maxCll || {actual: '', client: ''};
  };

  onMaxCllUpdate = (name: string, value) => {
    const maxCll = {...this.getMaxCll(), [name]: value};
    this.handleClick('maxcll', maxCll, true);
  };

  onLanguageSelected = (language: string, country: string) => {
    setTimeout(() => this.handleClick('language', language), 200);
    setTimeout(() => this.handleClick('country', country), 200);
  };

  onSubtitleLanguageSelected(subId: string, language: string, country: string) {
    setTimeout(() => this.handleClick('language', language, false, subId), 200);
    setTimeout(() => this.handleClick('country', country, false, subId), 200);
  }

  getSubtitleLanguage = (subtitleLanguages: Array<IAssetVideoSubtitleLanguage>) => {
    if (!subtitleLanguages.length) {
      return (
        <Row
          className={`video-details-container_row empty-subtitle-languages${
            !this.props.tabsDataInEditMode ? ' disabled' : ''
          }`}
        >
          <Column>
            <TextField label="" text={`Video doesn't have subtitle languages defined`} />
          </Column>
        </Row>
      );
    }
    return subtitleLanguages.map((subtitle: IAssetVideoSubtitleLanguage, index: number) => (
      <Container key={index}>
        <Row className="video-details-container_row">
          <Column
            className={`video-details-container_row_column two-columns${
              !this.props.tabsDataInEditMode ? ' disabled' : ''
            }`}
          >
            <LanguageDropdown
              search
              languageDialect={this.props.enums.languageDialect}
              onLanguageSelected={this.onSubtitleLanguageSelected.bind(this, subtitle.id)}
              language={subtitle.language}
              country={subtitle.country}
              disabled={!this.props.tabsDataInEditMode}
              label="Language"
              portalNode={this.props.closestBody}
              toLowerCaseCompare
            />
          </Column>
          <Column className={`video-details-container_row_column${!this.props.tabsDataInEditMode ? ' disabled' : ''}`}>
            <Toggle
              label="Closed"
              disabled={!this.props.tabsDataInEditMode}
              toggled={subtitle.closed}
              onToggle={value => this.handleClick('closed', value, false, subtitle.id)}
            />
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              width={206}
              options={this.convertEnums(this.props.enums.subtitleType)}
              onSelected={value => this.handleClick('type', value, false, subtitle.id)}
              selected={subtitle.type}
              disabled={!this.props.tabsDataInEditMode}
              label="Type"
              portalNode={this.props.closestBody}
            />
          </Column>
          <Column />
          <Column />
        </Row>
      </Container>
    ));
  };

  getContent = () => {
    const videos = this.props.video;
    const subtitles = videos.subtitleLanguage || [];
    const isNotEditable = !this.props.tabsDataInEditMode;
    return (
      <>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.subType)}
              error={this.props.videoMetadataErrors.subType}
              onSelected={value => this.handleClick('subType', value)}
              selected={videos.subType}
              disabled={isNotEditable}
              label="Sub Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.subType', this.props.errorLogs)}
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <Toggle
              label="Golden Master"
              disabled={isNotEditable}
              toggled={videos.isGoldenMaster}
              onToggle={value => this.handleClick('isGoldenMaster', value)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              error={this.props.videoMetadataErrors.videoCodec}
              options={this.convertEnums(this.props.enums.videoCodec)}
              onSelected={value => this.handleClick('videoCodec', value)}
              selected={videos.videoCodec}
              disabled={isNotEditable}
              label="Video Codec"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoCodec', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.encodeRate)}
              error={this.props.videoMetadataErrors.encodeRate}
              onSelected={value => this.handleClick('encodeRate', value)}
              selected={videos.encodeRate}
              disabled={isNotEditable}
              label="Encode Rate"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.encodeRate', this.props.errorLogs)}
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Frame Size Height"
              text={videos.frameSizeHeight}
              editMode={true}
              onChange={value => this.handleClick('frameSizeHeight', value)}
              onlyNumbers
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Frame Size Width"
              text={videos.frameSizeWidth}
              editMode={true}
              onChange={value => this.handleClick('frameSizeWidth', value)}
              onlyNumbers
            />
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.pictureFormat)}
              error={this.props.videoMetadataErrors.pictureFormat}
              onSelected={value => this.handleClick('pictureFormat', value)}
              selected={videos.pictureFormat}
              disabled={isNotEditable}
              label="Picture Format"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.pictureFormat', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <LanguageDropdown
              search
              languageDialect={this.props.enums.languageDialect}
              error={this.props.videoMetadataErrors.language}
              onLanguageSelected={this.onLanguageSelected}
              language={videos.language}
              country={videos.country}
              disabled={isNotEditable}
              label="Language"
              portalNode={this.props.closestBody}
              toLowerCaseCompare
              errorLogs={this.props.errorLogs}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt1 content="Subtitles" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        {this.getSubtitleLanguage(subtitles)}
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt1 content="Video Details" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Peak Bit Rate"
              text={
                videos.videoDetail && typeof videos.videoDetail.peakBitRate !== 'undefined'
                  ? videos.videoDetail.peakBitRate
                  : ''
              }
              editMode={true}
              onChange={value => this.handleClick('peakBitRate', value, true)}
              onlyNumbers
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Average Bit Rate"
              text={
                videos.videoDetail && typeof videos.videoDetail.averageBitRate !== 'undefined'
                  ? videos.videoDetail.averageBitRate
                  : ''
              }
              editMode={true}
              onChange={value => this.handleClick('averageBitRate', value, true)}
              onlyNumbers
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Actual Length"
              text={(videos.videoDetail && videos.videoDetail.actualLength) || ''}
              editMode={true}
              onChange={value => this.handleClick('actualLength', value, true)}
            />
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.pixelAspect)}
              error={this.props.videoMetadataErrors.pixelAspect}
              onSelected={value => this.handleClick('pixelAspect', value, true)}
              selected={videos.videoDetail && videos.videoDetail.pixelAspect}
              disabled={isNotEditable}
              label="Pixel Aspect"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.pixelAspect', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.displayAspectRatio)}
              error={this.props.videoMetadataErrors.displayAspectRatio}
              onSelected={value => this.handleClick('displayAspectRatio', value, true)}
              selected={videos.videoDetail && videos.videoDetail.displayAspectRatio}
              disabled={isNotEditable}
              label="Display Aspect Ratio"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.displayAspectRatio', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.pictureAspectRatio)}
              error={this.props.videoMetadataErrors.pictureAspectRatio}
              onSelected={value => this.handleClick('pictureAspectRatio', value, true)}
              selected={videos.videoDetail && videos.videoDetail.pictureAspectRatio}
              disabled={isNotEditable}
              label="Picture Aspect Ratio"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.pictureAspectRatio', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.scanType)}
              error={this.props.videoMetadataErrors.scanType}
              onSelected={value => this.handleClick('scanType', value, true)}
              selected={videos.videoDetail && videos.videoDetail.scanType}
              disabled={isNotEditable}
              label="Scan Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.scanType', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.colorType)}
              error={this.props.videoMetadataErrors.colorType}
              onSelected={value => this.handleClick('colorType', value)}
              selected={videos.colorType}
              disabled={isNotEditable}
              label="Color Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.colorType', this.props.errorLogs)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt1 content="Color Space" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt4a content="Color Encoding" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.colorEncoding)}
              selected={this.getColorEncoding().primaries}
              onSelected={this.onColorEncodingUpdate.bind(this, 'primaries')}
              disabled={isNotEditable}
              label="Primaries"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.colorEncoding.primaries', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.transferFunction)}
              selected={this.getColorEncoding().transferCharacteristics}
              onSelected={this.onColorEncodingUpdate.bind(this, 'transferCharacteristics')}
              disabled={isNotEditable}
              label="Transfer Characteristics"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField(
                'videos.videoDetail.colorEncoding.transferCharacteristics',
                this.props.errorLogs
              )}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.colorDiff)}
              selected={this.getColorEncoding().colorDifferencing}
              onSelected={this.onColorEncodingUpdate.bind(this, 'colorDifferencing')}
              disabled={isNotEditable}
              label="Color Differencing"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.colorEncoding.colorDifferencing', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.colorModel)}
              error={this.props.videoMetadataErrors.colorModel}
              onSelected={value => this.handleClick('colorModel', value, true)}
              selected={videos.videoDetail && videos.videoDetail.colorModel}
              disabled={isNotEditable}
              label="Color Model"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.colorModel', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.signalRange)}
              error={this.props.videoMetadataErrors.signalRange}
              onSelected={value => this.handleClick('signalRange', value, true)}
              selected={videos.videoDetail && videos.videoDetail.signalRange}
              disabled={isNotEditable}
              label="Signal Range"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.signalRange', this.props.errorLogs)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.colorSubSampling)}
              error={this.props.videoMetadataErrors.colorSubSampling}
              onSelected={value => this.handleClick('colorSubSampling', value, true)}
              selected={videos.videoDetail && videos.videoDetail.colorSubSampling}
              disabled={isNotEditable}
              label="Color Sub Sampling"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.colorSubSampling', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.bitDepth)}
              error={this.props.videoMetadataErrors.bitDepth}
              onSelected={value => this.handleClick('bitDepth', value, true)}
              selected={videos.videoDetail && videos.videoDetail.bitDepth}
              disabled={isNotEditable}
              label="Bit Depth"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.bitDepth', this.props.errorLogs)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.colorimetry)}
              error={this.props.videoMetadataErrors.colorimetry}
              onSelected={value => this.handleClick('colorimetry', value, true)}
              selected={videos.videoDetail && videos.videoDetail.colorimetry}
              disabled={isNotEditable}
              label="Colorimetry"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.colorimetry', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.whitePoint)}
              error={this.props.videoMetadataErrors.whitePoint}
              onSelected={value => this.handleClick('whitePoint', value, true)}
              selected={videos.videoDetail && videos.videoDetail.whitePoint}
              disabled={isNotEditable}
              label="White Point"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.whitePoint', this.props.errorLogs)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt1 content="Advanced Formats" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt4a content="Dynamic Range" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.dynamicRangeType)}
              error={this.props.videoMetadataErrors.dynamicRangeType}
              onSelected={value => this.onDynamicRangeUpdate('type', value, false)}
              selected={videos.videoDetail && videos.videoDetail.dynamicRange && videos.videoDetail.dynamicRange.type}
              disabled={isNotEditable}
              label="Dynamic Range Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.dynamicRange.type', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.dynamicRangeSystem)}
              error={this.props.videoMetadataErrors.dynamicRangeSystem}
              onSelected={value => this.onDynamicRangeUpdate('system', value, false)}
              selected={videos.videoDetail && videos.videoDetail.dynamicRange && videos.videoDetail.dynamicRange.system}
              disabled={isNotEditable}
              label="Dynamic Range System"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.dynamicRange.system', this.props.errorLogs)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Tone Mapping: Constrained Target Nits"
              text={
                videos.videoDetail &&
                videos.videoDetail.dynamicRange &&
                videos.videoDetail.dynamicRange.toneMapped &&
                typeof videos.videoDetail.dynamicRange.toneMapped.targetNits !== 'undefined'
                  ? videos.videoDetail.dynamicRange.toneMapped.targetNits
                  : ''
              }
              editMode={true}
              onChange={value => this.onDynamicRangeUpdate('targetNits', value, true)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={[{label: 'True', value: true}, {label: 'False', value: false}]}
              error={this.props.videoMetadataErrors.isConstrainedDerivative}
              onSelected={value => this.onDynamicRangeUpdate('isConstrainedDerivative', value, true)}
              selected={
                videos.videoDetail &&
                videos.videoDetail.dynamicRange &&
                videos.videoDetail.dynamicRange.toneMapped &&
                videos.videoDetail.dynamicRange.toneMapped.isConstrainedDerivative
                  ? 'True'
                  : 'False'
              }
              disabled={isNotEditable}
              label="Tone Mapping: Is Constrained Derivative"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField(
                'videos.videoDetail.dynamicRange.toneMapped.isConstrainedDerivative',
                this.props.errorLogs
              )}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={[{label: 'True', value: true}, {label: 'False', value: false}]}
              error={this.props.videoMetadataErrors.bitDepth}
              onSelected={value => this.onDynamicRangeUpdate('isSDRtoHDR', value, true)}
              selected={
                videos.videoDetail &&
                videos.videoDetail.dynamicRange &&
                videos.videoDetail.dynamicRange.toneMapped &&
                videos.videoDetail.dynamicRange.toneMapped.isSDRtoHDR
                  ? 'True'
                  : 'False'
              }
              disabled={isNotEditable}
              label="Tone Mapping: Is SDR to HDR Conversion"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField(
                'videos.videoDetail.dynamicRange.toneMapped.isSDRtoHDR',
                this.props.errorLogs
              )}
            />
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt4a content="HDR Metadata" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt4a
                content="Unless explicitly known that the MaxLL values are the true actual values without throwing away
                 any % of outliers, treat the values you receive as the 'Client' set."
                className="references-container_header_title"
              />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="MaxCLL Client"
              text={
                videos.videoDetail &&
                videos.videoDetail.maxcll &&
                typeof videos.videoDetail.maxcll.client !== 'undefined'
                  ? videos.videoDetail.maxcll.client
                  : ''
              }
              editMode={true}
              onChange={value => this.onMaxCllUpdate('client', value)}
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="MaxCLL Actual"
              text={
                videos.videoDetail &&
                videos.videoDetail.maxcll &&
                typeof videos.videoDetail.maxcll.actual !== 'undefined'
                  ? videos.videoDetail.maxcll.actual
                  : ''
              }
              editMode={true}
              onChange={value => this.onMaxCllUpdate('actual', value)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="MaxFALL Client"
              text={
                videos.videoDetail &&
                videos.videoDetail.maxfall &&
                typeof videos.videoDetail.maxfall.client !== 'undefined'
                  ? videos.videoDetail.maxfall.client
                  : ''
              }
              editMode={true}
              onChange={value => this.onMaxFallUpdate('client', value)}
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="MaxFALL Actual"
              text={
                videos.videoDetail &&
                videos.videoDetail.maxfall &&
                typeof videos.videoDetail.maxfall.actual !== 'undefined'
                  ? videos.videoDetail.maxfall.actual
                  : ''
              }
              editMode={true}
              onChange={value => this.onMaxFallUpdate('actual', value)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt4a content="ST2086 Mastering Display" className="references-container_header_title" />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.colorEncoding)}
              error={this.props.videoMetadataErrors.colorVolume}
              onSelected={value => this.onSt2086Update('primaries', value)}
              selected={videos.videoDetail && videos.videoDetail.st2086 && videos.videoDetail.st2086.primaries}
              disabled={isNotEditable}
              label="Color Volume"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.st2086.primaries', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.whitePoint)}
              error={this.props.videoMetadataErrors.whitePoint}
              onSelected={value => this.onSt2086Update('whitePoint', value)}
              selected={videos.videoDetail && videos.videoDetail.st2086 && videos.videoDetail.st2086.whitePoint}
              disabled={isNotEditable}
              label="White Point"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('videos.videoDetail.st2086.whitePoint', this.props.errorLogs)}
            />
          </Column>
          <Column />
        </Row>
        <Row className="video-details-container_row">
          <Column className="video-details-container_row_column">
            <div className="dropdown-ui-container">
              <Tt4a
                content="Decimal nit values need to be converted to the Integer form by multiplying by 10,000;
                so 0.005 should be stored as 50."
                className="references-container_header_title"
              />
            </div>
          </Column>
        </Row>
        <Row className="video-details-container_row">
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Max Luminance (as integer)"
              text={
                videos.videoDetail &&
                videos.videoDetail.st2086 &&
                typeof videos.videoDetail.st2086.displayLuminanceMax !== 'undefined'
                  ? videos.videoDetail.st2086.displayLuminanceMax
                  : ''
              }
              editMode={true}
              onChange={value => this.onSt2086Update('displayLuminanceMax', value)}
            />
          </Column>
          <Column className={`video-details-container_row_column${isNotEditable ? ' disabled' : ''}`}>
            <TextField
              label="Min Luminance (as integer)"
              text={
                videos.videoDetail &&
                videos.videoDetail.st2086 &&
                typeof videos.videoDetail.st2086.displayLuminanceMin !== 'undefined'
                  ? videos.videoDetail.st2086.displayLuminanceMin
                  : ''
              }
              editMode={true}
              onChange={value => this.onSt2086Update('displayLuminanceMin', value)}
            />
          </Column>
          <Column />
        </Row>
      </>
    );
  };
  render() {
    return <Container className="video-details-container">{this.getContent()}</Container>;
  }
}
