import * as React from 'react';
import {MdClose} from 'react-icons/md';
import {Icon} from 'tt-components/src/Icon/Icon';
import {ITemplateColors} from '../../../../@types/templateColors';

//we pass VERSION variable from webpack config via DefinePlugin
declare var VERSION;

interface IDiagnosticBoxProps {
  frameRate?: number;
  currentBitRate?: number; // in mb/s
  currentResolution?: string;
  currentDroppedFrames?: number;
  totalDroppedFrames?: number;
  onClose: () => void;
  templateColors: ITemplateColors;
  dropFrame: boolean;
}

export class DiagnosticBox extends React.PureComponent<IDiagnosticBoxProps, {}> {
  constructor(props: IDiagnosticBoxProps) {
    super(props);
  }

  displayDiagnosticRecord(text: string, value: number | string) {
    return <div className="diagnostic-box-container_content_record">{`${text}: ${value}`}</div>;
  }

  onBoxClicking = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onCloseIconClick = () => {
    this.props.onClose();
  };

  renderUnitValue(value: string | number, unit: string = '') {
    return `${value ? `${value}` : '0'}${unit}`;
  }

  renderDropFrame(dropFrame: boolean) {
    let dropFrameStr = '';
    if (dropFrame !== undefined && dropFrame !== null) {
      dropFrameStr = ` ${dropFrame ? 'DF' : 'NDF'}`;
    }
    return dropFrameStr;
  }

  public render() {
    const {tooltipBackground} = this.props.templateColors;

    return (
      <div
        className="diagnostic-box-container"
        style={{backgroundColor: tooltipBackground}}
        onClick={this.onBoxClicking}
      >
        <div className="diagnostic-box-container_header">
          <div className="diagnostic-box-container_header_title">Diagnostics</div>
          <div className="diagnostic-box-container_header_icon" onClick={this.onCloseIconClick}>
            <Icon icon={MdClose} size="1.6em" color="#8e8f8f" />
          </div>
        </div>
        <div className="diagnostic-box-container_content">
          {this.displayDiagnosticRecord('App Version', VERSION)}
          {this.displayDiagnosticRecord(
            'Current Bit Rate',
            this.renderUnitValue(this.props.currentBitRate.toFixed(1), 'mb')
          )}
          {this.displayDiagnosticRecord('Current Resolution', this.props.currentResolution || 'N/A')}
          {this.displayDiagnosticRecord(
            'Frame Rate',
            this.renderUnitValue(this.props.frameRate, 'fps') + this.renderDropFrame(this.props.dropFrame)
          )}
          {this.displayDiagnosticRecord('Current Dropped Frames', this.props.currentDroppedFrames || 0)}
          {this.displayDiagnosticRecord('Total Dropped Frames', this.props.totalDroppedFrames || 0)}
        </div>
      </div>
    );
  }
}
