import * as React from 'react';
import {Dropdown, IDropdownOption} from '../Dropdown';
import {PlaylistAsset} from '../../models/PlaylistAsset/PlaylistAsset';
import {displayAssetInfo} from '../../utils/utils';

interface IAssetSelectProps {
  assets: Array<PlaylistAsset>;
  curationModeEnabled?: boolean;
  tabsInEditMode?: boolean;
  selectedAssetId: string;
  loadingPlaylist: boolean;
  closestBodyElement?: HTMLElement;
  tabsAreProcessing?: boolean;
  onAssetSelect: (assetId: string) => void;
}

export class AssetSelect extends React.PureComponent<IAssetSelectProps> {
  getAssets = () => {
    return this.props.loadingPlaylist ? [] : this.props.assets.filter((asset: PlaylistAsset) => !asset.isHidden);
  };

  getOptions = () => {
    return this.getAssets().map(
      (record: PlaylistAsset): IDropdownOption => {
        const label = displayAssetInfo(record);
        const value = record.assetId;
        return {label, value, style: !record.isRegistered && this.props.curationModeEnabled ? {color: '#F57617'} : {}};
      }
    );
  };

  getPlaceholder = () => {
    return this.props.loadingPlaylist
      ? 'Loading assets...'
      : this.getAssets().length
      ? 'Select asset...'
      : 'No assets found';
  };

  isUnregisteredAsset = () => {
    if (!this.props.curationModeEnabled) {
      return false;
    }

    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(this.props.assets, this.props.selectedAssetId);
    if (selectedAsset && !selectedAsset.isRegistered) {
      return true;
    }
    return false;
  };

  render() {
    const unregistered = this.isUnregisteredAsset() ? ` unregistered` : ``;
    return (
      <div className="asset-select-container">
        <div className={`asset-select-container_picker${unregistered}`}>
          <Dropdown
            width={450}
            disabled={this.props.loadingPlaylist || this.props.tabsInEditMode || this.props.tabsAreProcessing}
            disableMatchWidth
            selectedPlaceholder={this.getPlaceholder()}
            selected={this.props.selectedAssetId}
            borderless
            onSelected={this.props.onAssetSelect}
            options={this.getOptions()}
            portalNode={this.props.closestBodyElement}
          />
          <div className="asset-select-container_picker_arrow" />
        </div>
      </div>
    );
  }
}
