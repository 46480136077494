import * as React from 'react';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Dropdown as TTDropdown} from 'tt-components/src/Dropdown';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Tt4a} from '../Typography/Tt4a';
import {FloatingWarning} from '../FloatingWarning';

import {TriangleIcon} from '../../assets/Icons/Triangle';
import {SearchIcon} from '../../assets/Icons/Search';
import {IErrorLog} from '../../../@types/assetDetails';

export interface IDropdownOption {
  label: string;
  value: any;
  error?: boolean; // TODO: Remove error field and include styling in style prop
  style?: React.CSSProperties;
}

export interface IDropdownProps {
  width?: number;
  options: Array<IDropdownOption>;
  optionsSortCallback?: (a: IDropdownOption, b: IDropdownOption) => number;
  error?: string | boolean;
  selected: string;
  selectedPlaceholder?: string;
  emptyPlaceholder?: string;
  buttonContentStyles?: React.CSSProperties;
  onSelected?: (value: string) => void;
  label?: string;
  search?: boolean;
  placeholder?: string;
  triggerAction?: 'click' | 'hover';
  portalNode?: HTMLElement;
  borderless?: boolean;
  paddingLeft?: number;
  borderWidth?: number;
  disableMatchWidth?: boolean;
  disabled?: boolean;
  fixedButtonWidth?: boolean;
  searchValue?: string;
  disableSearchInput?: boolean;
  onSearch?: (searchValue: string) => void;
  contentListLimit?: number;
  searchOnEnter?: boolean;
  allowOpenAbove?: boolean;
  relative?: boolean;
  toLowerCaseCompare?: boolean;
  errorLog?: IErrorLog;
}

interface IDropdownState {
  width: number;
  selected: string;
  searchValue: string;
  openAbove: boolean;
  disabled: boolean;
}

const DROPDOWN_CONTENT_BOTTOM_OFFSET = -10;

export class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  static defaultProps = {
    triggerAction: 'click',
    portalNode: null,
    paddingLeft: 15,
    borderWidth: 1,
    disabled: false,
    contentListLimit: 9,
    allowOpenAbove: true,
    optionsSortCallback: (a: IDropdownOption, b: IDropdownOption) => a.label.localeCompare(b.label)
  };

  static getDerivedStateFromProps(nextProps: IDropdownProps, prevState: IDropdownState) {
    if (nextProps.selected !== prevState.selected) {
      return {selected: nextProps.selected};
    }
    return null;
  }

  containerRef: HTMLDivElement;
  inputRef: HTMLInputElement;
  buttonRef: HTMLDivElement;
  contentRef: HTMLDivElement;
  onInputChange$: Subject<string>;
  ttDropdownRef: React.RefObject<TTDropdown>;
  $onInputChange: Subscription;

  constructor(props) {
    super(props);

    this.state = {
      width: null,
      selected: null,
      searchValue: '',
      openAbove: false,
      disabled: false
    };
    this.ttDropdownRef = React.createRef();
  }

  componentDidMount() {
    if (this.containerRef) {
      const {width} = this.containerRef.getBoundingClientRect();
      this.setState({width});
    }

    this.onInputChange$ = new Subject();
    this.$onInputChange = this.onInputChange$.pipe(debounceTime(400)).subscribe(this.updateSearch);
  }

  componentWillUnmount() {
    if (this.$onInputChange) {
      this.$onInputChange.unsubscribe();
    }
  }

  onItemSwitch = (value: string) => {
    if (this.props.onSelected) {
      this.buttonRef.click();
      this.props.onSelected(value);
    }
  };

  componentDidUpdate(prevProps: IDropdownProps) {
    if (prevProps.search !== this.props.search && this.props.search) {
      this.setState({searchValue: ''});
    }
    if (this.state.disabled !== this.props.disabled) {
      if (
        !this.state.disabled &&
        this.ttDropdownRef &&
        this.ttDropdownRef.current &&
        this.ttDropdownRef.current.state.isOpened
      ) {
        this.buttonRef.click();
      }
      this.setState({disabled: this.props.disabled});
    }
  }

  compareSelectedWithOption = (option: IDropdownOption) => {
    if (this.props.toLowerCaseCompare) {
      return (option.value || '').toLowerCase() === (this.state.selected || '').toLowerCase();
    }
    return option.value === this.state.selected;
  };

  getItem = (item: IDropdownOption, index: number) => {
    const selected = this.compareSelectedWithOption(item) ? ` selected` : ``;
    return (
      <div
        title={item.label}
        className={`dropdown-ui-container_dropdown_content_list_item${selected}${item.error ? ' error-msg' : ''}`}
        onClick={e => this.onItemSwitch(item.value)}
        style={item.style || {}}
        key={index}
      >
        {item.label}
      </div>
    );
  };

  updateSearch = (searchValue: string) => {
    if (this.props.searchOnEnter) {
      return;
    }
    this.updateSearchValue(searchValue);
  };

  updateSearchValue = (searchValue: string) => {
    this.setState({searchValue}, () => {
      if (this.props.onSearch) {
        this.props.onSearch(searchValue);
      }
    });
  };

  onInputkeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.props.searchOnEnter && e.keyCode === 13) {
      this.updateSearchValue((e.target as HTMLInputElement).value);
    }
  };

  getInputSearch = () => {
    return (
      <div className="dropdown-ui-container_dropdown_content_search-field">
        <div className="dropdown-ui-container_dropdown_content_search-field_wrapper">
          <Icon icon={SearchIcon} color="#fff" size="18px" />
          <input
            ref={node => (this.inputRef = node)}
            type="text"
            className={`${this.props.disableSearchInput ? `disabled` : ``}`}
            disabled={this.props.disableSearchInput}
            placeholder={this.props.placeholder || ''}
            onChange={e => this.onInputChange$.next(e.target.value)}
            onKeyDown={this.onInputkeyDown}
          />
        </div>
      </div>
    );
  };

  getPortalNode = () => {
    return this.props.triggerAction === 'click' ? this.props.portalNode : this.props.portalNode.closest('body');
  };

  getContent = () => {
    let items = this.props.options;
    // Sort options list based on provided sorting logic
    items.sort(this.props.optionsSortCallback);
    if (this.props.search && this.state.searchValue) {
      items = items.filter((item: IDropdownOption) =>
        item.label.toLowerCase().includes(this.state.searchValue.toLowerCase())
      );
    }
    const scroll = items.length > this.props.contentListLimit ? ` scroll` : ``;
    const empty = !items.length ? ` empty` : ``;
    const height = scroll ? this.props.contentListLimit * 40 : empty ? 40 : 'auto';
    return (
      <div className="dropdown-ui-container_dropdown_content" ref={node => (this.contentRef = node)}>
        {this.props.search && this.getInputSearch()}
        <div className={`dropdown-ui-container_dropdown_content_list${scroll}${empty}`} style={{height}}>
          {items.map((item: IDropdownOption, index: number) => this.getItem(item, index))}
          {!!empty && <Tt4a content={this.props.emptyPlaceholder || 'Empty'} style={{color: '#fff'}} />}
        </div>
      </div>
    );
  };

  getSelected = (): IDropdownOption => {
    const selectedRecord = this.props.options.find((item: IDropdownOption) => this.compareSelectedWithOption(item));
    return selectedRecord || null;
  };

  getButton = () => {
    const selectedRecord = this.getSelected();
    const selectedRecordLabel = selectedRecord ? selectedRecord.label : this.state.selected ? '...' : null;
    const selected = selectedRecordLabel || this.props.selectedPlaceholder || 'Select...';
    const style = {
      ...(this.props.buttonContentStyles || {}),
      ...(selectedRecord && selectedRecord.style ? selectedRecord.style : {})
    };
    const disableMatchWidth = this.props.disableMatchWidth ? ` disable-match-width` : ``;
    // Include in the width calculation the width of the absolute positioned Icon
    const calculatedWidth = disableMatchWidth ? `auto` : `calc(100% - ${this.props.paddingLeft + 30}px)`;
    return (
      <div className="dropdown-ui-container_dropdown_button" ref={node => (this.buttonRef = node)}>
        <div
          className={`dropdown-ui-container_dropdown_button_content${disableMatchWidth}`}
          title={selected}
          style={style}
        >
          <div
            className="dropdown-ui-container_dropdown_button_content_text"
            style={{width: calculatedWidth, paddingLeft: this.props.paddingLeft}}
          >
            {selected}
          </div>
        </div>
        <div className="dropdown-ui-container_dropdown_button_icon">
          <TriangleIcon color="#8194B5" />
        </div>
      </div>
    );
  };

  onMouseIn = () => {
    setTimeout(() => {
      if (this.inputRef && this.props.searchValue && this.props.search) {
        this.inputRef.value = this.props.searchValue;
      }
      if (this.contentRef && this.props.allowOpenAbove && this.props.triggerAction === 'click') {
        const {height, top} = this.contentRef.getBoundingClientRect();
        if (height + top + DROPDOWN_CONTENT_BOTTOM_OFFSET >= window.document.documentElement.offsetHeight) {
          this.setState({openAbove: true});
        }
      }
    }, 300);
  };

  onMouseOut = () => {
    this.setState({openAbove: false});
  };

  onOpen = (styles: React.CSSProperties) => {
    let computedStyles = {...styles};
    // Update top position based on scroll of the containing element
    if (this.props.triggerAction === 'click' && this.getPortalNode()) {
      const scrollTop = this.getPortalNode().scrollTop;
      let dimensions: any = {};

      // NOTE: We will use the 'relative' in cases we will need to define position of the Dropdown
      // relative to the provided portalNode so it can be included inside its borders
      if (this.props.relative) {
        const portalVPLeftOffset = this.getPortalNode().getBoundingClientRect().left;
        const portalVPTopOffset = this.getPortalNode().getBoundingClientRect().top;
        const conatinerVPLeftOffset = this.containerRef.getBoundingClientRect().left;
        const conatinerVPTopOffset = this.containerRef.getBoundingClientRect().top;
        const scrollLeft = this.getPortalNode().scrollLeft;
        dimensions = {
          top: conatinerVPTopOffset - portalVPTopOffset + this.containerRef.offsetHeight + scrollTop,
          left: conatinerVPLeftOffset - portalVPLeftOffset + scrollLeft
        };
      } else {
        dimensions = {
          top: typeof computedStyles.top !== 'undefined' ? (computedStyles.top as number) + scrollTop : null
        };
      }
      for (const prop in dimensions) {
        if (dimensions.hasOwnProperty(prop)) {
          if (!dimensions[prop]) {
            delete dimensions[prop];
          }
        }
      }
      computedStyles = {...computedStyles, ...dimensions};
    }
    // If openAbove criteria is met we need to update the top position
    if (this.state.openAbove && typeof computedStyles.top !== 'undefined') {
      const heightOffset = this.containerRef ? this.containerRef.getBoundingClientRect().height : 0;
      computedStyles = {...computedStyles, top: (computedStyles.top as number) - heightOffset};
    }
    return computedStyles;
  };

  computeStyles = () => {
    const styles = {...defaultStyle, marginLeft: -this.props.borderWidth};
    if (this.state.width) {
      return {...styles, width: this.props.disableMatchWidth ? this.props.width || 200 : this.state.width};
    }
    return styles;
  };

  getErrorMessage = () => {
    if (this.props.error) {
      return typeof this.props.error === 'string' ? (
        <div className="dropdown-ui-container_error-message">{this.props.error}</div>
      ) : null;
    }
    return null;
  };

  showErrorLog = () => {
    return !!this.props.errorLog && !this.state.selected;
  };

  getLabel = () => {
    const showLabel = !!this.props.label || this.showErrorLog();
    return (
      showLabel && (
        <div className="dropdown-ui-container_label-container">
          {this.props.label && <div className="dropdown-ui-container_label-container_label">{this.props.label}</div>}
          {this.showErrorLog() && (
            <div className="dropdown-ui-container_label-container_error-log">
              <FloatingWarning
                message={this.props.errorLog}
                closestBody={this.props.portalNode ? this.props.portalNode.closest('body') : null}
              />
            </div>
          )}
        </div>
      )
    );
  };

  render() {
    const style = this.computeStyles();
    const borderless = this.props.borderless ? ` borderless` : ``;
    const disableMatchWidth = this.props.disableMatchWidth ? ` disable-match-width` : ``;
    const fixedButtonWidth = this.props.fixedButtonWidth ? ` fixed-button-width` : ``;
    const disabled = this.state.disabled ? ` disabled` : ``;
    const error = !!this.props.error ? ' dropdown-error' : '';
    const width = this.props.disableMatchWidth
      ? 'unset'
      : this.props.width || (this.props.fixedButtonWidth ? this.state.width || '100%' : '100%');
    return (
      <div className={`dropdown-ui-container${disabled}`}>
        {this.getLabel()}
        <div
          className={`dropdown-ui-container_dropdown${borderless}${disableMatchWidth}${fixedButtonWidth}${error}`}
          ref={node => (this.containerRef = node)}
          style={{width, borderWidth: this.props.borderWidth}}
        >
          <TTDropdown
            ref={this.ttDropdownRef}
            disabled={this.state.disabled}
            triggerDropAction={this.props.triggerAction}
            content={this.getContent()}
            buttonComponent={() => this.getButton()}
            style={style}
            portalNode={this.getPortalNode()}
            onMouseIn={this.onMouseIn}
            onMouseOut={this.onMouseOut}
            onOpen={this.onOpen}
            openAbove={this.state.openAbove}
          />
        </div>
        {this.getErrorMessage()}
      </div>
    );
  }
}

const defaultStyle: React.CSSProperties = {
  // Add offset of the button element: paddingLeft + borderWidth
  // marginLeft: -16,
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
  paddingTop: 8,
  boxSizing: 'border-box'
};
