import * as React from 'react';
import {IFrameRate} from 'tt-components';
import {ITooltipSegmentData} from '../../../../interfaces/ITimelineTooltipData';
import {TimelineEvents} from '../../../TimelineEvents';
import {SegmentModel} from '../../../../utils/SegmentModel';

interface IProgramTimingsContentProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  events: Array<any>;
  frameRate: IFrameRate;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class ProgramTimingsContent extends React.PureComponent<IProgramTimingsContentProps> {
  render() {
    let events = this.props.events.find((record: any) => record.name === 'Program Timings');
    events = events ? (events.events || []).map((record: any) => new SegmentModel(record, this.props.frameRate)) : [];

    return (
      <TimelineEvents
        secondUnits={this.props.secondUnits}
        contentWidth={this.props.contentWidth}
        scrollLeft={this.props.scrollLeft}
        frameRate={this.props.frameRate}
        events={events}
        segmentClassName="programtimingslayer-content-container_segment"
        contentClassName="programtimingslayer-content-container"
        emptySegmentsMessage="No Program Timings are defined"
        onElementIn={this.props.onElementIn}
        onElementOut={this.props.onElementOut}
        onSegmentClicked={this.props.onSegmentClicked}
      />
    );
  }
}
