import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const PdfIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '14px'}
      height={props.size || '14px'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 42 56"
      enableBackground="new 0 0 1000 1000"
    >
      <g id="One-Player-in-Compliance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="One-Player"
          transform="translate(-4427.000000, -931.000000)"
          fill={props.color || '#1F72F2'}
          fillRule="nonzero"
        >
          <g id="Info-Panel" transform="translate(0.000000, 325.000000)">
            <g id="Markups-Tab-Selected" transform="translate(1689.000000, 0.000000)">
              <g id="Download-EDL" transform="translate(0.000000, 127.000000)">
                <g id="Download-EDL-Popover" transform="translate(2681.000000, 22.000000)">
                  <g id="file-pdf-solid" transform="translate(57.000000, 457.000000)">
                    <path
                      d="M23.5281912,31.28125 C18.4468537,28.4155784 18.5484805,22.88125 
                    21.4956562,22.88125 C30.0323032,22.88125 29.2192892,29.4902052 23.5281912,31.28125 
                    Z M20.3958118,33.1734375 C19.3675129,35.8796576 18.0854779,38.9743944 
                    16.603125,41.5734375 C19.0470042,40.6356385 21.8113921,39.2691313 
                    25.003125,38.6394662 C23.3070996,37.3533418 21.6778468,35.504538 
                    20.3958118,33.1734375 Z M9.4171875,50.8130032 C9.4171875,50.9798975 
                    12.5942649,49.6864664 17.8171875,42.4265625 C16.2045801,43.7408554 
                    10.813176,47.5377017 9.4171875,50.8130032 Z M27.125,17.5 L42,17.5 
                    L42,53.375 C42,54.8296875 40.8296875,56 39.375,56 L2.625,56 
                    C1.1703125,56 0,54.8296875 0,53.375 L0,2.625 C0,1.1703125 1.1703125,0 2.625,
                    0 L24.5,0 L24.5,14.875 C24.5,16.31875 25.68125,17.5 27.125,17.5 
                    Z M26.25,36.290625 C24.0625,34.95625 22.6078125,33.11875 
                    21.5796875,30.40625 C22.071875,28.3828125 22.8484375,25.309375 
                    22.2578125,23.384375 C21.74375,20.16875 17.6203125,20.4859375 
                    17.0296875,22.640625 C16.4828125,24.6421875 16.9859375,27.4640625 
                    17.915625,31.0625 C16.646875,34.08125 14.7765625,38.128125 13.453125,
                    40.446875 C13.4421875,40.446875 13.4421875,40.4578125 13.43125,
                    40.4578125 C10.4671875,41.978125 5.38125,45.325 7.4703125,47.8953125 
                    C8.0828125,48.65 9.2203125,48.9890625 9.821875,48.9890625 
                    C11.7796875,48.9890625 13.7265625,47.0203125 16.5046875,42.2296875 
                    C19.3265625,41.3 22.421875,40.140625 25.1453125,39.6921875 C27.51875,
                    40.9828125 30.296875,41.825 32.1453125,41.825 C35.3390625,41.825 35.5578125,
                    38.325 34.3,37.078125 C32.7796875,35.590625 28.3609375,36.0171875 26.25,
                    36.290625 Z M41.234375,11.484375 L30.515625,0.765625 C30.0234375,0.2734375 
                    29.35625,0 28.65625,0 L28,0 L28,14 L42,14 L42,13.3328125 C42,12.64375 
                    41.7265625,11.9765625 41.234375,11.484375 Z M36.6468433,45.6525559 
                    C37.4322046,43.5933313 36.1679645,36.576714 28.4484375,38.7884738 
                    C35.5549995,50.8387513 36.6468433,45.6525559 36.6468433,45.6525559 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
