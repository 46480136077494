import * as React from 'react';

interface ITimelineRowProps {
  labelElement: JSX.Element;
  contentElement: JSX.Element;
  className?: string;
  contentRef?: (node: HTMLDivElement) => void;
}

export class TimelineRow extends React.Component<ITimelineRowProps> {
  getRef = (node: HTMLDivElement) => {
    if (this.props.contentRef) {
      this.props.contentRef(node);
    }
  };

  render() {
    return (
      <div className={`timelinerow-container${this.props.className ? ` ${this.props.className}` : ``}`}>
        <div className="timelinerow-container_label">{this.props.labelElement}</div>
        <div className="timelinerow-container_content" ref={this.getRef}>
          {this.props.contentElement}
        </div>
      </div>
    );
  }
}
