import * as React from 'react';
import {Table} from 'tt-components/src/Table/Table';
import {Icon} from 'tt-components/src/Icon/Icon';
import {IVersion} from '../../../../../@types/version';
import {ITemplateColors} from '../../../../../@types/templateColors';
import {CustomRadioButton} from '../../../../components/CustomRadio';
import {AddIcon} from '../../assets/Icons/AddIcon';
import {DeleteIcon} from '../../assets/Icons/DeleteIcon';
import {CopyIcon} from '../../assets/Icons/CopyIcon';

export interface IVersionsProps {
  versions: IVersion[];
  selectedVersion: string;
  templateColors: ITemplateColors;
  onVersionChange: (versionId: string) => void;
}

export class Versions extends React.Component<IVersionsProps> {
  readonly columns = [
    {id: 'id', header: 'VERSION', render: this.renderRadio.bind(this)},
    {id: 'name', header: 'NAME'},
    {id: 'createdAt', header: 'CREATED'},
    {id: 'updatedAt', header: 'LAST UPDATED'},
    {id: 'options', header: 'OPTIONS', render: this.renderOptions.bind(this)}
  ];

  renderRadio(props) {
    return (
      <CustomRadioButton
        value={props.cellData}
        selectedValue={this.props.selectedVersion}
        id={`versionColumn-${props.cellData}`}
        index={`versionColumn-${props.cellData}`}
        text={props.cellData}
        templateColors={this.props.templateColors}
        onChange={e => this.props.onVersionChange(e.target.value)}
      />
    );
  }

  renderOptions(props) {
    return (
      <div className="column-options">
        <div className="column-options_icon">
          <CopyIcon color="#a1aab7" size="16px" />
        </div>
        <div className="column-options_icon">
          <Icon icon={DeleteIcon} color="#a1aab7" size="16px" />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="versions-container">
        <div className="versions-container_actions">
          <div className="versions-container_actions_icon">
            <AddIcon color="#a1aab7" />
          </div>
        </div>
        <div className="versions-container_table hide-last-header">
          <Table headerHeight={30} rowHeight={45} list={this.props.versions} columns={this.columns} />
        </div>
      </div>
    );
  }
}
