import * as React from 'react';
import {Table} from 'tt-components/src/Table/Table';
import {CustomSelectDropdown, IOption} from '../../../../components/CustomSelectDropdown';

const versions: IOption[] = [
  {value: 'version1', text: 'Version 1'},
  {value: 'version2', text: 'Version 2'},
  {value: 'version3', text: 'Version 3'},
  {value: 'version4', text: 'Version 4'},
  {value: 'version5', text: 'Version 5'}
];

export class History extends React.Component {
  renderHistoryTable() {
    const columns = [{id: 'date', header: 'DATE'}, {id: 'action', header: 'ACTION'}, {id: 'user', header: 'USER'}];
    const list = [
      {date: '2019-02-19 8:05 AM', action: 'Added Blur @ 00:57:37:00 - 00:59:46:23', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Added Cut @ 00:59:30:00 - 00:59:33:23', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Added Insert Black @ 04:55:27:00 - 07:23:18:02', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Added Beep @ 03:00:45:21 - 03:45:00:21', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Removed Note @ 02:57:37:00 - 00:59:46:23', user: 'Nazim Pethani'},
      {date: '2019-02-19 8:05 AM', action: 'Updated Blur @ 00:57:37:00 - 00:59:46:23', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Added Beep @ 03:00:45:21 - 03:45:00:21', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Added Fade to Black @ 00:57:37:00 - 00:59:46:23', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Removed Fade to Black @ 00:57:37:00 - 00:59:46:23', user: 'Andy Schenlker'},
      {date: '2019-02-19 8:05 AM', action: 'Added Replace Slate @ 00:57:37:00 - 00:59:46:23', user: 'Andy Schenlker'}
    ];
    return <Table headerHeight={30} rowHeight={45} list={list} columns={columns} />;
  }

  render() {
    return (
      <div className="metadata-history-container">
        <div className="metadata-history-container_version">
          <CustomSelectDropdown selected="version2" options={versions} onItemSelected={value => {}} />
        </div>
        <div className="metadata-history-containe_table">{this.renderHistoryTable()}</div>
      </div>
    );
  }
}
