import * as React from 'react';
import {Tt2b} from '../../../../../../components/Typography/Tt2b';

interface ICollapsibleProps {
  content?: {
    type: string;
    timeIn: string;
    timeOut: string;
  };
}

export class ProgramTimingsRow extends React.Component<ICollapsibleProps> {
  render() {
    return (
      <div className="row-container">
        <div className="row-container-header">
          <Tt2b content={this.props.content.type || ''} className="collapsible-container_header_text" />
          <Tt2b content={this.props.content.timeIn || ''} className="collapsible-container_header_text" />
          <Tt2b content={this.props.content.timeOut || ''} className="collapsible-container_header_text" />
        </div>
      </div>
    );
  }
}
