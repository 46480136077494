import * as React from 'react';
import {ITabs, ITabsDispatchProps, ITabsOwnProps, ITabsProps} from '../../interfaces';
import {Markups} from '../Markups';
import {Versions} from '../Versions';
import {Metadata} from '../Metadata';
import {VersionsProps} from './mocks/VersionsProps';
import {GuidelinesProps} from './mocks/GuidelinesProps';
import {TabsComponent, ITabElement} from '../TabsComponent';
import {History} from '../History';
import {Guidelines} from '../Guidelines';
import {Comments} from '../Comments';
import {formatting} from '../../../../utils/formatting';
import {IEventGroup} from '../../../../../@types/markupEvent';
import {getTypesByEventGroup, defineInitTab} from '../../utils/helpers';
import {TmpElement} from '../TmpElement';
import {IMetadataError} from '../../../../../@types/metadataErrors';

type ITabsComponentProps = ITabsProps & ITabsOwnProps & ITabsDispatchProps;

interface ITabsComponentState {
  types: Array<string>;
  markupsCount: number;
}

// It should be uncommented and used as stub data for not yet implemented tabs
//const TmpElement = props => <div className="tmp-container">{props.content || ''}</div>;

export class Tabs extends React.PureComponent<ITabsComponentProps, ITabsComponentState> {
  readonly formatting = {
    smpte: (currentTime: number) => {
      const displayMediaTimeFormat = this.props.displayMediaTimeFormat;
      const frameRate = this.props.playlist.frameRate;
      return formatting(currentTime, displayMediaTimeFormat, frameRate);
    }
  };

  tabsContainerRef: HTMLElement;

  constructor(props) {
    super(props);

    this.state = {
      types: [],
      markupsCount: null
    };

    this.tabsContainerRef = this.props.closestBodyElement;
  }

  getMetadataErrors = () => {
    const {metadataDetails, metadataVideo, metadataAudio} = this.props.metadataTab.metadataErrors;
    return [metadataDetails, metadataVideo, metadataAudio].reduce(
      (acc: number, errors: Array<IMetadataError>) => acc + (errors.length ? 1 : 0),
      0
    );
  };

  getTabs = (): Array<ITabElement> => [
    {
      type: ITabs.Markups,
      name: `Markups${this.getMarkupsCount()}`,
      element: Markups,
      visible: appConfig.view.tabs.includes(ITabs.Markups),
      errors: (this.props.markupsErrors || []).length
    },
    {type: ITabs.Versions, name: 'Versions', element: Versions, visible: appConfig.view.tabs.includes(ITabs.Versions)},
    {
      type: ITabs.Metadata,
      name: 'Metadata',
      element: Metadata,
      visible: appConfig.view.tabs.includes(ITabs.Metadata),
      errors: this.getMetadataErrors()
    },
    {
      type: ITabs.Comments,
      name: 'Comments',
      element: Comments,
      visible: appConfig.view.tabs.includes(ITabs.Comments)
    },
    {type: ITabs.History, name: 'History', element: History, visible: appConfig.view.tabs.includes(ITabs.History)},
    {
      type: ITabs.Guidelines,
      name: 'Guidelines',
      element: Guidelines,
      visible: appConfig.view.tabs.includes(ITabs.Guidelines)
    },
    {type: ITabs.Empty, name: '', element: TmpElement, visible: true}
  ];

  componentDidMount(): void {
    this.init();
    this.defineActiveTab();
  }

  async init() {
    this.updateTypes(this.props);
  }

  componentDidUpdate(prevProps: ITabsComponentProps) {
    if (prevProps.selectedEventGroup !== this.props.selectedEventGroup) {
      this.updateTypes(this.props);
    }
  }

  defineActiveTab = () => {
    this.props.selectTab(defineInitTab(appConfig));
  };

  getMarkupsCount = () => {
    return this.state.markupsCount !== null ? ` ${this.state.markupsCount}` : ``;
  };

  updateTypes = (props: ITabsComponentProps) => {
    const types = getTypesByEventGroup(props.selectedEventGroup, props.eventTypes);
    this.setState({types});
  };

  onEventGroupSelected = groupName => {
    this.props.updateSelectedEventGroup(groupName);
  };

  filterProgramTimings = (events: Array<any>): IEventGroup => {
    const programTimings = (Array.isArray(events) ? events : []).find(
      (eventGroup: any) => eventGroup.name === 'Program Timings'
    );
    return programTimings as IEventGroup;
  };

  renderContent = () => {
    const {
      deleteComment,
      addComment,
      editComment,
      addReply,
      setVideoFragmentInTimeByCurrentTime,
      setVideoFragmentOutTimeByCurrentTime,
      setVideoFragmentInTime,
      setVideoFragmentOutTime,
      goToLocation,
      showingDropdownTimeout
    } = this.props;
    const selectedTab = this.getTabs().find(({type}: ITabElement) => this.props.tabs.selectedTab === type);
    if (!selectedTab) {
      return;
    }
    const TabContent = selectedTab.element;
    let props = null;
    switch (this.props.tabs.selectedTab) {
      case ITabs.Markups:
        props = {
          selectedAsset: this.props.selectedAsset,
          playlist: this.props.playlist,
          selectTab: this.props.selectTab,
          duration: (this.props.videoMetadata && this.props.videoMetadata.duration) || 0,
          changedEvents: this.props.changedEvents,
          eventGroups: this.props.eventGroups,
          selectedEventGroup: this.props.selectedEventGroup,
          types: this.state.types,
          categories: this.props.eventCategories,
          framerate: this.props.playlist.frameRate,
          currentVideoFragment: this.props.currentVideoFragment,
          markupsErrors: this.props.markupsErrors,
          addNewEventsGroup: this.props.addNewEventsGroup,
          onEventGroupSelected: this.onEventGroupSelected,
          closestBody: this.props.closestBodyElement,
          setVideoFragmentInTime,
          setVideoFragmentOutTime,
          setVideoFragmentInTimeByCurrentTime,
          setVideoFragmentOutTimeByCurrentTime,
          showingDropdownTimeout,
          getVideoCurrentTime: this.props.getVideoCurrentTime,
          shortcutSubject: this.props.shortcutSubject,
          onCountChanged: (markupsCount: number) => this.setState({markupsCount}),
          tabsInEditMode: this.props.inEditMode,
          updateChangedEventGroup: this.props.updateChangedEventGroup,
          getPlayerCurrentTime: this.props.getPlayerCurrentTime,
          useStartTimecode: this.props.markupsTab.useStartTimecode,
          updateUseStartTimecodeFlag: this.props.updateUseStartTimecodeFlag,
          onSeek: this.props.onSeek
        };
        break;
      case ITabs.Versions:
        props = VersionsProps(
          this.props.tabs.selectedVersion,
          this.props.configuration.templateColors,
          this.onVersionChange
        );
        break;
      case ITabs.Metadata:
        props = {
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.tabs.updatedAssetDetails,
          selectedAsset: this.props.selectedAsset,
          playlist: this.props.playlist,
          selectedTab: this.props.metadataTab.selectedMetadataTab,
          selectMetadataTab: this.onMetadataTabChange,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabs.inEditMode,
          metadataErrors: this.props.metadataTab.metadataErrors,
          updateAudioConfigurations: this.props.updateAudioConfigurations,
          goToLocation: this.props.goToLocation,
          loading: this.props.loadingPlayer,
          programTimings: this.filterProgramTimings(
            this.props.selectedAsset ? this.props.selectedAsset.events || [] : []
          ),
          closestBody: this.props.closestBodyElement,
          tabsContainer: this.tabsContainerRef,
          curationModeEnabled: this.props.configuration.curationModeEnabled
        };
        break;
      case ITabs.Comments:
        props = {
          commentsTab: this.props.commentsTab,
          deleteComment,
          addComment,
          editComment,
          addReply,
          setVideoFragmentInTimeByCurrentTime,
          setVideoFragmentOutTimeByCurrentTime,
          setVideoFragmentInTime,
          setVideoFragmentOutTime,
          frameRate: this.props.playlist.frameRate,
          currentVideoFragment: this.props.currentVideoFragment,
          formattingSmpte: this.formatting.smpte,
          goToLocation,
          jsonVTT: this.props.playlist.thumbnailTrack.jsonVTT,
          showingDropdownTimeout,
          closestBody: this.props.closestBodyElement
        };
        break;
      case ITabs.History:
        props = {};
        break;
      case ITabs.Guidelines:
        props = GuidelinesProps;
        break;
      default:
        props = {content: ''};
    }

    return <TabContent {...props} />;
  };

  onVersionChange = (versionId: string) => {
    this.props.selectVersion(versionId);
  };

  onMetadataTabChange = (tab: ITabs) => {
    this.props.selectMetadataTab(tab);
  };

  onTabChanged = (tab: ITabs) => {
    this.props.selectTab(tab);
  };

  render() {
    return (
      <div className="tabs-container main-tabs-container" ref={node => (this.tabsContainerRef = node)}>
        <TabsComponent
          tabsList={this.getTabs()}
          selectedTab={this.props.tabs.selectedTab}
          onTabChanged={this.onTabChanged}
          tabContent={this.renderContent()}
        />
      </div>
    );
  }
}
