import * as React from 'react';

interface IGridProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
}

export const Container = (props: IGridProps) => (
  <div className={`grid-container${props.className ? ` ${props.className}` : ``}`}>{props.children}</div>
);

export const Row = (props: IGridProps) => (
  <div className={`grid-container_row${props.className ? ` ${props.className}` : ``}`}>{props.children}</div>
);

export const Column = (props: IGridProps) => (
  <div className={`grid-container_row_column${props.className ? ` ${props.className}` : ``}`}>{props.children}</div>
);
