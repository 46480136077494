import * as React from 'react';
import {VideoConfig} from './components/VideoConfigurations';
import {IEnums} from '../../../../state/IEnums';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails, IAssetVideo, IAssetVideoSubtitleLanguage, IErrorLog} from '../../../../../@types/assetDetails';
import {Tt2b} from '../../../../components/Typography/Tt2b';
import {deepCopy} from '../../utils/helpers';

interface IVideoProps {
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  updatedAssetDetails: Partial<IAssetDetails>;
  selectedAsset: PlaylistAsset;
  videoMetadataErrors: any;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
}

export class Video extends React.Component<IVideoProps> {
  getAssetData = () => {
    const assetDetails =
      this.props.selectedAsset && this.props.selectedAsset.assetDetails ? this.props.selectedAsset.assetDetails : {};
    const updatedAssetDetails = this.props.updatedAssetDetails || {};
    const mergedAssetDetails = deepCopy({...assetDetails, ...updatedAssetDetails});

    if (mergedAssetDetails.videos) {
      mergedAssetDetails.videos = mergedAssetDetails.videos.reduce(
        (acc: Array<IAssetVideo>, video: IAssetVideo, index: number) => {
          const id = `${index}-video`;
          if (video.subtitleLanguage && Array.isArray(video.subtitleLanguage) && video.subtitleLanguage.length) {
            video.subtitleLanguage = video.subtitleLanguage.reduce(
              (acc: Array<IAssetVideoSubtitleLanguage>, subLangauge: IAssetVideoSubtitleLanguage, subIndex: number) => {
                return [...acc, {...subLangauge, id: `${id}-${subIndex}-subtitle-language`}];
              },
              []
            );
          }
          return [...acc, {...video, id}];
        },
        []
      );
    }

    return mergedAssetDetails;
  };

  hasVideoConfigurations = () => {
    const assetDetails = this.getAssetData();
    return assetDetails.videos && Array.isArray(assetDetails.videos) ? !!assetDetails.videos.length : false;
  };

  render() {
    return (
      <div className="video-details-container">
        {this.hasVideoConfigurations() ? (
          <VideoConfig
            assetDetails={this.getAssetData()}
            updatedAssetDetails={this.props.updatedAssetDetails}
            updatePartialAssetDetails={this.props.updatePartialAssetDetails}
            enums={this.props.enums}
            videoMetadataErrors={this.props.videoMetadataErrors}
            tabsDataInEditMode={this.props.tabsDataInEditMode}
            closestBody={this.props.closestBody}
            errorLogs={this.props.errorLogs}
          />
        ) : (
          <Tt2b
            className="video-details-container_empty"
            content="No Video configurations are defined for this asset"
          />
        )}
      </div>
    );
  }
}
