import * as React from 'react';
import {ButtonsGroup, IButtonsGroupProps} from '../../';
import {Icon} from 'tt-components/src/Icon/Icon';
import {BigPlayerIcon} from '../../../../assets/Icons/BigPlayer';
import {SmallPlayerIcon} from '../../../../assets/Icons/SmallPlayer';
import {BigTimeLineIcon} from '../../../../assets/Icons/BigTimeLine';
import {ILayoutType} from '../../../../../@types/layoutType';

interface ILayoutGroupProps extends Partial<IButtonsGroupProps> {
  selectedConfig: ILayoutType;
}

const options = (selectedConfig: string) => [
  {
    value: 'smallPlayer',
    content: (
      <Icon icon={SmallPlayerIcon} size="20px" color={selectedConfig === `smallPlayer` ? `#1F72F2` : `#8D919A`} />
    )
  },
  {
    value: 'bigPlayer',
    content: <Icon icon={BigPlayerIcon} size="20px" color={selectedConfig === `bigPlayer` ? `#1F72F2` : `#8D919A`} />
  },
  {
    value: 'bigTimeLine',
    content: (
      <Icon icon={BigTimeLineIcon} size="20px" color={selectedConfig === `bigTimeLine` ? `#1F72F2` : `#8D919A`} />
    )
  }
];

export const LayoutGroup = (props: ILayoutGroupProps) => {
  return (
    <ButtonsGroup
      selectedConfig={props.selectedConfig}
      options={options(props.selectedConfig)}
      onOptionChanged={props.onOptionChanged}
    />
  );
};
