import * as React from 'react';
import {VideoItem} from '../VideoItem';
import {IEnums} from '../../../../../../state/IEnums';
import {IAssetDetails, IAssetVideo, IErrorLog} from '../../../../../../../@types/assetDetails';

interface IVideoConfigProps {
  updatedAssetDetails: Partial<IAssetDetails>;
  assetDetails: IAssetDetails;
  videoMetadataErrors: any;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

export class VideoConfig extends React.Component<IVideoConfigProps> {
  render() {
    return (
      <div className="details-container">
        {this.props.assetDetails.videos.map((video: IAssetVideo, index) => {
          return (
            <VideoItem
              key={index}
              enums={this.props.enums}
              video={video}
              assetDetails={this.props.assetDetails}
              updatedAssetDetails={this.props.updatedAssetDetails}
              updatePartialAssetDetails={this.props.updatePartialAssetDetails}
              videoMetadataErrors={this.props.videoMetadataErrors}
              tabsDataInEditMode={this.props.tabsDataInEditMode}
              closestBody={this.props.closestBody}
              errorLogs={this.props.errorLogs}
            />
          );
        })}
      </div>
    );
  }
}
