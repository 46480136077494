import {applyMiddleware, compose, createStore, Store} from 'redux';
import reducer from 'reducers/index';
import thunk from 'redux-thunk';
import RavenMiddleware from 'redux-raven-middleware';
import {IAppState} from 'state';
import {serviceProvider} from './services/serviceProvider';
import {exposedEventMiddlewareWrapper} from './EventBus';

declare var window: {
  __REDUX_DEVTOOLS_EXTENSION__: any;
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
};

const devTool = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export default function configureStore(initialState: Partial<IAppState>): Store<IAppState> {
  const middlewares = [thunk.withExtraArgument(serviceProvider), exposedEventMiddlewareWrapper];

  //@see https://github.com/zalmoxisus/redux-devtools-extension
  const composeEnhancers = devTool ? devTool(REDUX_DEV_TOOL_OPTIONS) : compose;
  let appConfig = initialState.configuration;
  if (appConfig.sentryLogEnabled) {
    middlewares.push(RavenMiddleware(appConfig.sentryDsn));
  }

  return createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
}

const REDUX_DEV_TOOL_OPTIONS = {
  serialize: {
    replacer: (k, v) => (isTypedArray(v) && v.length ? '<<typed array>>' : v)
  }
};

function isTypedArray(x): boolean {
  return x instanceof Uint8Array || x instanceof Uint16Array || x instanceof Float32Array || x instanceof Float64Array;
}
