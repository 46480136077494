import * as React from 'react';
import {Dropdown, IDropdownOption} from '../../../../../../components/Dropdown';

export type IProgramTimingsEventType = 'common' | 'starttimecode' | 'programstart' | 'programend' | string;

interface IDefaultEventDropdownProps {
  closestBody?: HTMLElement;
  disabled?: boolean;
  selected: IProgramTimingsEventType;
  onEventTypeSelected: (type: IProgramTimingsEventType) => void;
}

export const DefaultEventDropdown = (props: IDefaultEventDropdownProps) => {
  const options: Array<IDropdownOption> = [
    {label: 'Common', value: 'common'},
    {label: 'Start Timecode', value: 'starttimecode'},
    {label: 'Program Start', value: 'programstart'},
    {label: 'Program End', value: 'programend'}
  ];
  return (
    <Dropdown
      label="Control Type"
      disableMatchWidth
      borderless
      disabled={props.disabled}
      width={140}
      options={options}
      selected={props.selected}
      onSelected={props.onEventTypeSelected}
      paddingLeft={0}
      borderWidth={0}
      portalNode={props.closestBody}
    />
  );
};
