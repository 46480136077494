import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const AddCommentIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '14px'}
      height={props.size || '14px'}
      viewBox="0 0 32 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="One-Player-in-Compliance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="One-Player" transform="translate(-4786.000000, -1656.000000)" fill={props.color || '#ffffff'}>
          <g id="Info-Panel" transform="translate(0.000000, 325.000000)">
            <g id="Comments-Tab-Selected" transform="translate(3014.000000, 0.000000)">
              <g id="Comment-Input" transform="translate(0.000000, 1240.000000)">
                <g id="Timecode-In-Out" transform="translate(0.000000, 67.000000)">
                  <g id="Pgm-Timing-Button-Copy-29" transform="translate(673.000000, 17.000000)">
                    <g id="Send-Comment-Button" transform="translate(823.073149, 0.000000)">
                      <g id="ic-edit" transform="translate(276.452050, 7.207317)">
                        <g id="writing">
                          <path
                            d="M21.4374753,13.3333333 C21.4374753,12.7021333 20.8444554,12.1904762
                            20.1128833,12.1904762 L6.46958603,12.1904762 C5.73801389,12.1904762
                            5.14499406,12.7021333 5.14499406,13.3333333 C5.14499406,13.9645333
                            5.73801389,14.4761905 6.46958603,14.4761905 L20.1128833,14.4761905
                            C20.8444554,14.4761905 21.4374753,13.9645333 21.4374753,13.3333333 Z"
                            id="Shape"
                            fillRule="nonzero"
                          />
                          <path
                            d="M6.49529624,17.5238095 C5.74952435,17.5238095 5.14499406,18.0354667
                            5.14499406,18.6666667 C5.14499406,19.2978667 5.74952435,19.8095238
                            6.49529624,19.8095238 L14.942179,19.8095238 C15.6879509,19.8095238
                            16.2924812,19.2978667 16.2924812,18.6666667 C16.2924812,18.0354667
                            15.6879509,17.5238095 14.942179,17.5238095 L6.49529624,17.5238095 Z"
                            id="Shape"
                            fillRule="nonzero"
                          />
                          <path
                            d="M9.93037385,29.5 L5.40836896,29.5 C3.91728164,29.5 2.70418448,28.3785
                            2.70418448,27 L2.70418448,5 C2.70418448,3.6215 3.91728164,2.5 5.40836896,2.5
                            L22.0315994,2.5 C23.5226867,2.5 24.7357839,3.6215 24.7357839,5 L24.7357839,12.6875
                            C24.7357839,13.377875 25.3411156,13.9375 26.0878761,13.9375 C26.8346366,13.9375
                            27.4399683,13.377875 27.4399683,12.6875 L27.4399683,5
                            C27.4399683,2.243 25.013774,0 22.0315994,0 L5.40836896,0
                            C2.42619431,0 0,2.243 0,5 L0,27 C0,29.757 2.42619431,32
                            5.40836896,32 L9.93037385,32 C10.6771344,32 11.2824661,31.440375
                            11.2824661,30.75 C11.2824661,30.059625 10.6771344,29.5 9.93037385,29.5 Z"
                            id="Shape"
                            fillRule="nonzero"
                          />
                          <path
                            d="M29.6885368,17.8761676 C28.1133786,16.3906849 25.5504641,16.3906214
                            23.9763158,17.8751516 L16.5830912,24.8320414 C16.4260736,24.9798023
                            16.310263,25.1617888 16.2457592,25.3619359 L14.6356559,30.3608511
                            C14.4929126,30.8040703 14.6172743,31.285325 14.9602621,31.6168504
                            C15.2171999,31.8651928 15.5662475,32 15.9242503,32 C16.0440334,32
                            16.1649612,31.9848874 16.283667,31.9538366 L21.71902,30.5339479
                            C21.9426959,30.4755293 22.1465764,30.3635815 22.3108658,30.2088993
                            L29.6886041,23.2642648 C31.2637624,21.7787821 31.2637624,19.3617772
                            29.6885368,17.8761676 Z M20.6588147,28.1753616 L17.924339,28.8897191
                            L18.7245765,26.4050884 L23.7131161,21.7109658 L25.6179304,23.5073368
                            L20.6588147,28.1753616 Z M27.7851365,21.4675127 L27.5242261,21.7130612
                            L25.6197484,19.9170077 L25.8797835,19.6722846 C26.4048362,19.1771238
                            27.2591411,19.1771238 27.7841938,19.6722846 C28.3092466,20.1674455
                            28.3092466,20.9731138 27.7851365,21.4675127 Z"
                            id="Shape"
                            fillRule="nonzero"
                          />
                          <path
                            d="M20.1128833,6.85714286 L6.46958603,6.85714286 C5.73801389,6.85714286
                            5.14499406,7.3688 5.14499406,8 C5.14499406,8.6312 5.73801389,9.14285714
                            6.46958603,9.14285714 L20.1128833,9.14285714 C20.8444554,9.14285714
                            21.4374753,8.6312 21.4374753,8 C21.4374753,7.3688 20.8444554,6.85714286
                            20.1128833,6.85714286 Z"
                            id="Shape"
                            fillRule="nonzero"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
