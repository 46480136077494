import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const SubtitleIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '17px'}
      height={props.size || '15px'}
      viewBox="0 0 17 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-149.000000, -1100.000000)" fill={props.color || '#1F72F2'}>
          <g transform="translate(140.000000, 1088.000000)">
            <g transform="translate(0.000000, 6.000000)">
              <g transform="translate(5.000000, 1.000000)">
                <g transform="translate(4.000000, 5.000000)">
                  <path
                    d="M0.447521868,14.9991178 C0.246207945,14.9991178 0.0699464214,14.8667804 
                    0.0162627085,14.6753323 C-0.0374210043,14.4838842 0.044894022,14.2809669 
                    0.217576632,14.1795083 C1.73056261,13.2849077 2.35776732,11.9835902 2.58055473,
                    11.3748383 C0.936043656,10.1335137 0,8.41577461 0,6.61775085 C0,5.70991648 0.23099756,
                    4.83031408 0.687309119,4.00276438 C1.12214719,3.21403364 1.74308881,2.50735208 2.53134466,
                    1.90212916 C4.13022457,0.675802847 6.2498365,0 8.5000788,0 C10.7503211,0 12.869933,
                    0.675802847 14.4688129,1.90212916 C15.2570688,2.50646983 15.8780104,3.21315139 16.3128485,
                    4.00276438 C16.76916,4.82943183 17,5.70903423 17,6.61775085 C17,7.52646747 16.76916,
                    8.40518762 16.3128485,9.23273733 C15.8780104,10.0214681 15.2570688,10.7281496 14.4688129,
                    11.3333725 C12.869933,12.5596989 10.7503211,13.2355017 8.5000788,13.2355017 C7.57582421,
                    13.2355017 6.66856946,13.1216916 5.8015775,12.8958358 C5.41773895,13.148159 4.70016666,
                    13.5954594 3.84301671,14.0162922 C2.51166063,14.6691566 1.369987,15 0.448416597,15 L0.447521868,
                    14.9991178 Z M8.5000788,0.883131396 C4.30648609,0.883131396 0.894886142,3.45576991 0.894886142,
                    6.61775085 C0.894886142,8.21903305 1.79408833,9.76032232 3.36075802,10.8454888 C3.51465133,
                    10.9522409 3.58444016,11.1419245 3.53612481,11.3210211 C3.43144157,11.7056817 3.07533961,12.7617339 
                    2.12245371,13.7807317 C3.28739028,13.3784261 4.5417997,12.680567 5.46784375,12.0524056 
                    C5.57789536,11.9774144 5.71568356,11.9553582 5.84452447,11.9915304 C6.69272713,12.2306199 
                    7.58656095,12.3523703 8.5000788,12.3523703 C12.6936715,12.3523703 16.1052715,9.7797318 
                    16.1052715,6.61775085 C16.1052715,3.45576991 12.6936715,0.883131396 8.5000788,0.883131396 
                    Z M7.875,7 L4.125,7 C4.056,7 4,6.776 4,6.5 C4,6.224 4.056,6 4.125,6 L7.875,6 C7.944,6 8,6.224 8,
                    6.5 C8,6.776 7.944,7 7.875,7 Z M13.875,7 L10.125,7 C10.056,7 10,6.776 10,6.5 C10,6.224 10.056,
                    6 10.125,6 L13.875,6 C13.944,6 14,6.224 14,6.5 C14,6.776 13.944,7 13.875,7 Z M11.6666667,9 
                    L4.33333333,9 C4.14933333,9 4,8.776 4,8.5 C4,8.224 4.14933333,8 4.33333333,8 L11.6666667,8 
                    C11.8506667,8 12,8.224 12,8.5 C12,8.776 11.8506667,9 11.6666667,9 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
