import {connect} from 'react-redux';
import {VideoPlayer} from '../../components/VideoPlayer';
import {PlaylistAsset} from '../../models/PlaylistAsset/PlaylistAsset';
import {IVideoShortcuts, IVideoLoop, IVideoAudioConfiguration, IVideoFragment} from '../../state/IVideoState';
import {IAppPlaylist, IPlaylistAsset, IAppState} from '../../state';
import {IAudioChannelConfiguration} from '../../../@types/audioChannelConfiguration';
import {ISubtitlesMetadata} from '../../../@types/subtitlesMetadata';
import {IPlayerMetadata} from 'tt-components/src/VideoPlayer/playerProps';
import {IQualityMetrics, IVideoQuality} from '../../../@types/qualityMetrics';
import {IVideoOptions} from '../../../@types/videoOptions';
import {IFrameRate, IVideoPlayer, IBitMovinPlayer} from 'tt-components';
import {ITemplateColors} from '../../../@types/templateColors';
import {IDisplayMediaTimeFormat} from '../../../@types/displayMediaTimeFormat';
import {IWatermarkPosition} from '../../../@types/watermarkPosition';
import {ITimelineState, ITimelineLayers} from '../../modules/Timeline/interfaces';
import {IControlBarControls} from '../../../@types/controlBarControls';
import {IEnums} from '../../state/IEnums';
import {SeekType} from '../../components/OnePlayerControlBar/onePlayerControlBarProps';
import {FastFrameType} from '../../constants/constants';
import * as shortcuts from '../../constants/shortcuts';
import {IAssetDetails} from '../../../@types/assetDetails';
import {IAssetStatus} from '../../../@types/assetStatus';
import {ISearchTitle} from '../../../@types/searchTitle';
import {
  ready,
  metadata,
  getShortcuts,
  setDefaultShortcuts,
  changeShortcuts,
  seek,
  stopVideo,
  switchPlaying,
  switchFastFrame,
  switchLoop,
  setVideoFragmentInTime,
  setVideoFragmentOutTime,
  stopFastFrame,
  volume,
  mute,
  toggleThirdIndicator,
  playbackRateChange,
  forwardAndRewindRateChange,
  onChangeVisibleSubtitle,
  updateScrubBar,
  goToLocation,
  changeMediaTimeFormat,
  updateAssetDetails,
  updateThumbnailsVtt,
  getHybrikPlaylistUrl,
  updateAudioConfig,
  getAssetsData,
  updateSwitchingCurrentTime,
  loadEnums,
  checkPlaybackProxy,
  updatePlaybackAudio,
  updateStageCount,
  updateAudiosStagingState,
  updatePlaylistForceStaging,
  onUpdateSubtitlesUrl,
  updatePlaylistUrl,
  updatePlaylistProxy,
  updatePlaybackProxyState,
  updateSelectedAssetId,
  updateUnregisteredConformanceGroup,
  updateVideoStartTime
} from '../../actions/video';
import {
  showDiagnosticsBox,
  showSettings,
  showAudioSubs,
  showPlayerSpeed,
  showLoopControl,
  showVolumeControl,
  showGoTo,
  enableDiagnosticsPanel,
  changeWatermarkPosition,
  changeWatermarkUIPosition,
  showPlaybackSetupOverlay,
  showConformanceOverlay
} from '../../actions/overlays';
import {updateLayerVisibility, updateTimelineView} from '../../modules/Timeline/actions/timeline';
import {
  updateQualityLevels,
  updateLoadedLevel,
  updateDroppedFramesMetrics,
  resetQualityMetrics
} from '../../actions/qualityMetrcis';
import {setEditMode} from '../../modules/Tabs/actions/tabs';
import {IEventGroup} from '../../../@types/markupEvent';
import {
  getPlaylistSelectedAsset,
  getEmbeddedAudioConfigurations,
  getExternalAudioConfigurations,
  getEmbeddedSubtitles,
  getExternalSubtitles
} from '../../selectors';

export interface IPlayerConnectedProps {
  tabsAreProcessing: boolean;
  selectedAsset: PlaylistAsset;
  userIp: string;
  userEmail: string;
  appVersion: string;
  readyCallback: string;
  isReady: boolean;
  playing: boolean;
  fastFrameInProgress: boolean;
  keyboardShortcuts: IVideoShortcuts;
  loop: IVideoLoop;
  playlist: IAppPlaylist;
  embeddedAudioChannelConfigs: Array<IAudioChannelConfiguration>;
  externalAudioChannelConfigs: Array<IAudioChannelConfiguration>;
  embeddedSubtitlesMetadata: Array<ISubtitlesMetadata>;
  externalSubtitlesMetadata: Array<ISubtitlesMetadata>;
  videoMetadata: IPlayerMetadata;
  qualityMetrics: IQualityMetrics;
  videoOptions: IVideoOptions;
  volume: number;
  mute: boolean;
  playbackRate: number;
  forwardAndRewindRate: number;
  isThirdIndicator: boolean;
  frameRate: IFrameRate;
  templateColors: ITemplateColors;
  showDiagnostic: boolean;
  isSettingsShow: boolean;
  isAudioSubsShow: boolean;
  isPlayerSpeedShow: boolean;
  isLoopControlShow: boolean;
  isGoToShow: boolean;
  isVolumeControlShow: boolean;
  isDiagnosticsPanelEnabled: boolean;
  showPlaybackSetupOverlay: boolean;
  displayMediaTimeFormat: IDisplayMediaTimeFormat;
  watermarkPosition: IWatermarkPosition;
  timeline: ITimelineState;
  controlBarControls: IControlBarControls[];
  showingDropdownTimeout: number;
  audioConfiguration: IVideoAudioConfiguration;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  selectedTab: any;
  currentVideoFragment: IVideoFragment;
  showConformanceOverlayState: boolean;
  curationModeEnabled: boolean;
  tabsInEditMode: boolean;
  bitmovinKey: string;
  autoSelectPlaybackConfig: boolean;
  videoStartTime: number;
  changedEvents: Array<IEventGroup>;
  useStartTimecode: boolean;
}

export interface IPlayerOwnProps {
  containerElement: HTMLElement;
}

export interface IPlayerDispatch {
  onReady: (player: IVideoPlayer | IBitMovinPlayer) => void;
  seek: (value: number, type: SeekType) => void;
  switchPlaying: (value: boolean) => void;
  getShortcuts: (name: shortcuts.IShortcutType) => void;
  setDefaultShortcuts: () => void;
  changeShortcuts: (shortcuts: Array<shortcuts.IShortcutsDefinitions>) => void;
  switchFastFrame: (isStartFastFrame: boolean, type?: FastFrameType) => void;
  stopFastFrame: () => void;
  switchLoop: (value: object) => void;
  onMetadata: (value: IPlayerMetadata) => void;
  onVolume: (value: number) => void;
  onMute: (value: boolean) => void;
  toggleThirdIndicator: (value: boolean) => void;
  stopVideo?: () => void;
  onPlaybackRateChange: (playbackRate: number) => void;
  onForwardAndRewindRateChange: (forwardAndRewindRate: number) => void;
  onDiagnosticBoxChange: (value: boolean) => void;
  onShowSettingsChange: (isSettingsShow: boolean) => void;
  onShowAudioSubsChange: (isAudioSubsShow: boolean) => void;
  onShowPlayerSpeedChange: (isPlayerSpeedShow: boolean) => void;
  onShowLoopControlChange: (isLoopControlShow: boolean) => void;
  onShowVolumeControlChange: (isVolumeControlShow: boolean) => void;
  onShowGoToChange: (isGoToShow: boolean) => void;
  onDiagnosticsPanelExistenceChange: (value: boolean) => void;
  onChangeVisibleSubtitle: (subId: string) => void;
  updateScrubBar: () => void;
  goToLocation: (value: number) => void;
  changeMediaTimeFormat: (value: IDisplayMediaTimeFormat) => void;
  setVideoFragmentInTime: (value: number) => void;
  setVideoFragmentOutTime: (value: number) => void;
  changeWatermarkPosition: (value: IWatermarkPosition) => void;
  changeWatermarkUIPosition: (watermarkPosition: IWatermarkPosition) => void;
  updateAssetDetails: (data: IAssetDetails) => void;
  updateLayerVisibility: (layer: ITimelineLayers, visible: boolean) => void;
  updateTimelineView: (visible: boolean) => void;
  updateThumbnailsVtt: () => void;
  updateQualityLevels: (levels: Array<IVideoQuality>) => void;
  updateLoadedLevel: (level: string) => void;
  updateDroppedFramesMetrics: () => void;
  getHybrikPlaylistUrl: () => void;
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) => void;
  getAssetsData: (forceStaging?: boolean, restoreSelected?: boolean) => void;
  updateSwitchingCurrentTime: (state: boolean) => void;
  loadEnums: () => void;
  showPlaybackSetup: (showOverlay: boolean) => void;
  checkPlaybackProxy: () => void;
  updatePlaybackAudio: () => void;
  updateStageCount: (count: number) => void;
  updateAudiosStagingState: (assets: Array<IPlaylistAsset>) => void;
  updatePlaylistForceStaging: (forcingStaging: boolean) => void;
  onUpdateSubtitlesUrl: () => void;
  updatePlaylistUrl: (url: string) => void;
  updatePlaylistProxy: (proxyUrl: string) => void;
  updatePlaybackProxyState: (proxyState: IAssetStatus) => void;
  setTabsEditMode: (inEditMode: boolean) => void;
  updateSelectedAssetId: (assetId: string) => void;
  showConformanceOverlay: (show: boolean) => void;
  updateUnregisteredConformanceGroup: (titles: Array<ISearchTitle>) => void;
  resetQualityMetrics: () => void;
  updateVideoStartTime: (time: number) => void;
}

const mapStateToPropsFactory = (initialState: IAppState) => {
  return (state: IAppState): IPlayerConnectedProps => {
    return {
      selectedAsset: getPlaylistSelectedAsset(state),
      tabsAreProcessing: state.tabs.savingTabsContent,
      curationModeEnabled: state.configuration.curationModeEnabled,
      bitmovinKey: state.configuration.bitmovinKey,
      userIp: state.configuration.userIp,
      userEmail: state.configuration.userEmail,
      appVersion: state.configuration.version,
      keyboardShortcuts: state.video.shortcuts,
      readyCallback: state.configuration.readyCallback,
      templateColors: state.configuration.templateColors,
      playlist: state.video.playlist,
      currentVideoFragment: state.video.currentVideoFragment,
      embeddedAudioChannelConfigs: getEmbeddedAudioConfigurations(state),
      externalAudioChannelConfigs: getExternalAudioConfigurations(state),
      embeddedSubtitlesMetadata: getEmbeddedSubtitles(state),
      externalSubtitlesMetadata: getExternalSubtitles(state),
      isReady: state.video.isReady,
      playing: state.video.playing,
      fastFrameInProgress: state.video.fastFrameInProgress,
      loop: state.video.loop,
      videoMetadata: state.video.metadata,
      qualityMetrics: state.qualityMetrics,
      videoOptions: state.configuration.videoOptions,
      volume: state.video.volume,
      mute: state.video.mute,
      playbackRate: state.video.playbackRate,
      forwardAndRewindRate: state.video.forwardAndRewindRate,
      isThirdIndicator: state.video.isThirdIndicator,
      frameRate: state.video.playlist.frameRate,
      showDiagnostic: state.overlays.showDiagnostic,
      isSettingsShow: state.overlays.isSettingsShow,
      isAudioSubsShow: state.overlays.isAudioSubsShow,
      isPlayerSpeedShow: state.overlays.isPlayerSpeedShow,
      isLoopControlShow: state.overlays.isLoopControlShow,
      isGoToShow: state.overlays.isGoToShow,
      isVolumeControlShow: state.overlays.isVolumeControlShow,
      isDiagnosticsPanelEnabled: state.overlays.isDiagnosticsPanelEnabled,
      showPlaybackSetupOverlay: state.overlays.showPlaybackSetup,
      displayMediaTimeFormat: state.video.displayMediaTimeFormat,
      watermarkPosition: state.overlays.watermarkPosition,
      timeline: state.timeline,
      controlBarControls: state.configuration.controlBarControls,
      showingDropdownTimeout: state.configuration.showingDropdownTimeout,
      audioConfiguration: state.video.audioConfiguration,
      enums: state.video.enums,
      tabsDataInEditMode: state.tabs.inEditMode,
      selectedTab: state.tabs.metadataTab.selectedMetadataTab,
      showConformanceOverlayState: state.overlays.showConformanceOverlay,
      tabsInEditMode: state.tabs.inEditMode,
      autoSelectPlaybackConfig: state.configuration.autoSelectPlaybackConfig,
      videoStartTime: state.video.videoStartTime,
      changedEvents: state.video.changedEvents,
      useStartTimecode: state.tabs.markupsTab.useStartTimecode
    };
  };
};

const mapDispatchToProps = (dispatch): IPlayerDispatch => ({
  onReady: (player: IVideoPlayer) => dispatch(ready(player)),
  onMetadata: (value: IPlayerMetadata) => dispatch(metadata(value)),
  getShortcuts: (name: shortcuts.IShortcutType) => dispatch(getShortcuts(name)),
  setDefaultShortcuts: () => dispatch(setDefaultShortcuts()),
  changeShortcuts: (shortcuts: Array<shortcuts.IShortcutsDefinitions>) => dispatch(changeShortcuts(shortcuts)),
  seek: (value: number, type: SeekType) => dispatch(seek(value, type)),
  stopVideo: () => dispatch(stopVideo()),
  switchPlaying: (value: boolean) => dispatch(switchPlaying(value)),
  switchFastFrame: (value: boolean, type: FastFrameType) => dispatch(switchFastFrame(value, type)),
  switchLoop: (value: object) => dispatch(switchLoop(value)),
  setVideoFragmentInTime: (value: number) => dispatch(setVideoFragmentInTime(value)),
  setVideoFragmentOutTime: (value: number) => dispatch(setVideoFragmentOutTime(value)),
  stopFastFrame: () => dispatch(stopFastFrame()),
  onVolume: (value: number) => dispatch(volume(value)),
  onMute: (value: boolean) => dispatch(mute(value)),
  toggleThirdIndicator: (value: boolean) => dispatch(toggleThirdIndicator(value)),
  onPlaybackRateChange: playbackRate => dispatch(playbackRateChange(playbackRate)),
  onForwardAndRewindRateChange: forwardAndRewindRate => dispatch(forwardAndRewindRateChange(forwardAndRewindRate)),
  onDiagnosticBoxChange: (showDiagnosticBox: boolean) => dispatch(showDiagnosticsBox(showDiagnosticBox)),
  onShowSettingsChange: (isSettingsShow: boolean) => dispatch(showSettings(isSettingsShow)),
  onShowAudioSubsChange: (isAudioSubsShow: boolean) => dispatch(showAudioSubs(isAudioSubsShow)),
  onShowPlayerSpeedChange: (isPlayerSpeedShow: boolean) => dispatch(showPlayerSpeed(isPlayerSpeedShow)),
  onShowLoopControlChange: (isLoopControlShow: boolean) => dispatch(showLoopControl(isLoopControlShow)),
  onShowVolumeControlChange: (isVolumeControlShow: boolean) => dispatch(showVolumeControl(isVolumeControlShow)),
  onShowGoToChange: (isGoToShow: boolean) => dispatch(showGoTo(isGoToShow)),
  onDiagnosticsPanelExistenceChange: (isEnableDiagnosticsPanel: boolean) =>
    dispatch(enableDiagnosticsPanel(isEnableDiagnosticsPanel)),
  onChangeVisibleSubtitle: (subId: string) => dispatch(onChangeVisibleSubtitle(subId)),
  updateScrubBar: () => dispatch(updateScrubBar()),
  goToLocation: (value: number) => dispatch(goToLocation(value)),
  changeMediaTimeFormat: (value: IDisplayMediaTimeFormat) => dispatch(changeMediaTimeFormat(value)),
  changeWatermarkPosition: (value: IWatermarkPosition) => dispatch(changeWatermarkPosition(value)),
  changeWatermarkUIPosition: (watermarkPosition: IWatermarkPosition) =>
    dispatch(changeWatermarkUIPosition(watermarkPosition)),
  updateAssetDetails: (data: IAssetDetails) => dispatch(updateAssetDetails(data)),
  updateLayerVisibility: (layer: ITimelineLayers, visible: boolean) =>
    dispatch(updateLayerVisibility({layer, visible})),
  updateTimelineView: (visible: boolean) => dispatch(updateTimelineView(visible)),
  updateThumbnailsVtt: () => dispatch(updateThumbnailsVtt()),
  updateQualityLevels: (levels: Array<IVideoQuality>) => dispatch(updateQualityLevels(levels)),
  updateLoadedLevel: (level: string) => dispatch(updateLoadedLevel(level)),
  updateDroppedFramesMetrics: () => dispatch(updateDroppedFramesMetrics()),
  getHybrikPlaylistUrl: () => dispatch(getHybrikPlaylistUrl()),
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) =>
    dispatch(updateAudioConfig(data, enableChannelsOnConfiguration)),
  getAssetsData: (forceStaging?: boolean, restoreSelected?: boolean) =>
    dispatch(getAssetsData(forceStaging, restoreSelected)),
  updateSwitchingCurrentTime: (state: boolean) => dispatch(updateSwitchingCurrentTime(state)),
  loadEnums: () => dispatch(loadEnums()),
  showPlaybackSetup: (showOverlay: boolean) => dispatch(showPlaybackSetupOverlay(showOverlay)),
  checkPlaybackProxy: () => dispatch(checkPlaybackProxy()),
  updatePlaybackAudio: () => dispatch(updatePlaybackAudio()),
  updateStageCount: (count: number) => dispatch(updateStageCount(count)),
  updateAudiosStagingState: (assets: Array<IPlaylistAsset>) => dispatch(updateAudiosStagingState(assets)),
  updatePlaylistForceStaging: (forcingStaging: boolean) => dispatch(updatePlaylistForceStaging(forcingStaging)),
  onUpdateSubtitlesUrl: () => dispatch(onUpdateSubtitlesUrl()),
  updatePlaylistUrl: (url: string) => dispatch(updatePlaylistUrl(url)),
  updatePlaylistProxy: (proxyUrl: string) => dispatch(updatePlaylistProxy(proxyUrl)),
  updatePlaybackProxyState: (proxyState: IAssetStatus) => dispatch(updatePlaybackProxyState(proxyState)),
  setTabsEditMode: (inEditMode: boolean) => dispatch(setEditMode(inEditMode)),
  updateSelectedAssetId: (assetId: string) => dispatch(updateSelectedAssetId(assetId)),
  showConformanceOverlay: (show: boolean) => dispatch(showConformanceOverlay(show)),
  updateUnregisteredConformanceGroup: (titles: Array<ISearchTitle>) =>
    dispatch(updateUnregisteredConformanceGroup(titles)),
  resetQualityMetrics: () => dispatch(resetQualityMetrics()),
  updateVideoStartTime: (time: number) => dispatch(updateVideoStartTime(time))
});

export default connect<IPlayerConnectedProps, IPlayerDispatch>(
  mapStateToPropsFactory,
  mapDispatchToProps
)(VideoPlayer);
