import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Container, Row, Column} from '../../../../../../../Grid';
import {Tt1, Tt2b} from '../../../../../../../../../../components/Typography';
import {Dropdown} from '../../../../../../../../../../components/Dropdown';
import {Toggle} from '../../../../../../../../../../components/Toggle';
import {IAssetDetailsReference, IAssetDetails, IErrorLog} from '../../../../../../../../../../../@types/assetDetails';
import {IEnum} from '../../../../../../../../../../../@types/enum';
import {TextField} from '../../../../../../../../../../components/TextField';
import {deepCopy, getErrorLogByField} from '../../../../../../../../utils/helpers';
import {IMetadataError} from '../../../../../../../../../../../@types/metadataErrors';
import {CloseIcon} from '../../../../../../../../../../assets/Icons/Close';

interface IReferencesProps {
  references: Array<IAssetDetailsReference>;
  referencesNameEnums: Array<IEnum>;
  referencesTypeEnums: Array<IEnum>;
  closestBody?: HTMLElement;
  disabled: boolean;
  errors: Array<IMetadataError>;
  errorLogs: Array<IErrorLog>;
  onReferenceUpdates: (references: Array<IAssetDetailsReference>) => void;
}

const getDefaultReference = (): IAssetDetailsReference => ({
  id: `${new Date().getTime()}-new-reference`,
  name: '',
  type: '',
  value: '',
  isPrimary: false
});

export class References extends React.PureComponent<IReferencesProps> {
  getReferenceContainer = (
    reference: IAssetDetailsReference,
    referencesNameEnums: Array<IEnum>,
    referencesTypeEnums: Array<IEnum>,
    key: number
  ) => {
    const nameList = referencesNameEnums
      .filter((record: IEnum) => record.isActive)
      .map((record: IEnum) => ({label: record.name, value: record.value}));
    const typeList = referencesTypeEnums
      .filter((record: IEnum) => record.isActive)
      .map((record: IEnum) => ({label: record.name, value: record.value}));
    const newReferenceRecord = (reference.id || '').endsWith('-new-reference');
    return (
      <Container className="references-container_body_reference" key={key}>
        <Row>
          <div className="reference-divider" />
        </Row>
        <Row>
          <div className="close-icon" onClick={() => this.onRemoveReference(reference.id)}>
            <Icon icon={CloseIcon} size="12px" color="#FFFFFF" />
          </div>
        </Row>
        <Row>
          <Column>
            <Dropdown
              disabled={this.props.disabled}
              fixedButtonWidth
              search
              label="Name"
              options={nameList}
              selected={reference.name}
              onSelected={(name: string) => this.onReferenceUpdates(reference.id, 'name', name)}
              portalNode={this.props.closestBody}
              errorLog={!newReferenceRecord && getErrorLogByField('references.name', this.props.errorLogs)}
            />
          </Column>
          <Column>
            <Dropdown
              disabled={this.props.disabled}
              fixedButtonWidth
              search
              label="Type"
              options={typeList}
              selected={reference.type}
              onSelected={(type: string) => this.onReferenceUpdates(reference.id, 'type', type)}
              portalNode={this.props.closestBody}
              errorLog={!newReferenceRecord && getErrorLogByField('references.type', this.props.errorLogs)}
            />
          </Column>
          <Column>
            <TextField
              label="Value"
              text={reference.value}
              editMode={!this.props.disabled}
              onChange={(value: string) => this.onReferenceUpdates(reference.id, 'value', value)}
            />
          </Column>
        </Row>
        <Row>
          <Toggle
            disabled={this.props.disabled}
            label="Primary"
            toggled={reference.isPrimary}
            onToggle={(state: boolean) => this.onReferenceUpdates(reference.id, 'isPrimary', state)}
          />
        </Row>
      </Container>
    );
  };

  onReferenceUpdates = (referenceId: string, field: string, value: any) => {
    const references = deepCopy([...this.props.references]).map((reference: IAssetDetails) => {
      if (referenceId === reference.id) {
        reference[field] = value;
      }
      return reference;
    });
    this.props.onReferenceUpdates(references);
  };

  getReference = (reference: IAssetDetailsReference, index: number) => {
    return this.getReferenceContainer(reference, this.props.referencesNameEnums, this.props.referencesTypeEnums, index);
  };

  onAddReference = () => {
    if (this.props.disabled) {
      return;
    }
    const references = deepCopy([...this.props.references]);
    references.push(getDefaultReference());
    this.props.onReferenceUpdates(references);
  };

  onRemoveReference = (referenceId: string) => {
    if (this.props.disabled) {
      return;
    }
    const references = deepCopy([...this.props.references]).filter(
      (reference: IAssetDetailsReference) => reference.id !== referenceId
    );
    this.props.onReferenceUpdates(references);
  };

  getErrorRow = () => {
    const error = this.props.errors.find((error: IMetadataError) => error.fieldName === 'References');
    if (!error) {
      return null;
    }
    return <Row className="references-container_error">{`! ${error.message}`}</Row>;
  };

  render() {
    const disabled = this.props.disabled ? ` disabled` : ``;
    return (
      <Container className="references-container">
        <Row className="references-container_header">
          <Tt1 content="References" className="references-container_header_title" />
        </Row>
        <Row className="references-container_body">{this.props.references.map(this.getReference)}</Row>
        <Row className="references-container_footer">
          <Tt2b
            content="+ ADD REFERENCE"
            className={`references-container_footer_text${disabled}`}
            onClick={this.onAddReference}
          />
        </Row>
        {this.getErrorRow()}
      </Container>
    );
  }
}
