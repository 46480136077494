import * as React from 'react';
import {TimelineRow} from '../TimelineRow';
import {CommentsLabel} from './components/CommentsLabel';
import {CommentsContent} from './components/CommentsContent';
import {IComment} from '../../../Tabs/interfaces/ICommentsTab';
import {ITooltipSegmentData} from '../../interfaces/ITimelineTooltipData';
import {IFrameRate} from 'tt-components';

interface ICommentsLayerProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  comments: Array<IComment>;
  frameRate: IFrameRate;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class CommentsLayer extends React.PureComponent<ICommentsLayerProps> {
  render() {
    const label = <CommentsLabel />;
    const content = (
      <CommentsContent
        secondUnits={this.props.secondUnits}
        contentWidth={this.props.contentWidth}
        scrollLeft={this.props.scrollLeft}
        frameRate={this.props.frameRate}
        comments={this.props.comments}
        onElementIn={this.props.onElementIn}
        onElementOut={this.props.onElementOut}
        onSegmentClicked={this.props.onSegmentClicked}
      />
    );

    return <TimelineRow labelElement={label} contentElement={content} />;
  }
}
