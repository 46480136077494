import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const EmbeddedScreenIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 50 49" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>ic-media-embedded</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2779.000000, -1764.000000)" id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-media-embedded" transform="translate(2349.000000, 0.366216)">
                  <g id="ic-media-fullscreen" transform="translate(0.000000, 0.897861)">
                    <rect id="Rectangle-11" fill="#D8D8D8" opacity="0" x="0" y="0" width="66" height="65.0781318" />
                    <path
                      d="M24.5487797,24.2058897 L24.5487797,22.4202094 L24.5487797,8.13476648
                       L20.9268286,8.13476648 L20.9268286,20.634529 L8.25,20.634529 L8.25,24.2058897
                        L22.7378042,24.2058897 L24.5487797,24.2058897 L24.5487797,24.2058897 Z M57.75,24.2058897
                        L57.75,20.634529 L45.0731689,20.634529 L45.0731689,8.13476648 L41.4512179,8.13476648
                        L41.4512179,22.4202094 L41.4512179,24.2058897 L43.2621934,24.2058897 L57.75,24.2058897
                         L57.75,24.2058897 Z M24.5487797,56.9433808 L24.5487797,42.6579379 L24.5487797,40.8722576
                          L22.7378042,40.8722576 L8.25,40.8722576 L8.25,44.4436183 L20.9268286,44.4436183
                          L20.9268286,56.9433808 L24.5487797,56.9433808 Z M45.0731689,56.9433808 L45.0731689,44.4436183
                           L57.75,44.4436183 L57.75,40.8722576 L43.2621934,40.8722576 L41.4512179,40.8722576
                           L41.4512179,42.6579379 L41.4512179,56.9433808 L45.0731689,56.9433808 Z"
                      id="Shape"
                      fill={props.color}
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
