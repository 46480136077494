import * as React from 'react';
import {CustomCheckbox} from '../../../../components/CustomCheckbox';
import {ITemplateColors} from '../../../../../@types/templateColors';
import {ILayersVisisbility, ITimelineLayers} from '../../interfaces';

interface ITimelineSettingsProps {
  templateColors: ITemplateColors;
  layersVisibility: ILayersVisisbility;
  onUpdateLayerVisibility: (layer: ITimelineLayers, visible: boolean) => void;
  clickedOutside: () => void;
}

export class TimelineSettings extends React.PureComponent<ITimelineSettingsProps> {
  contentRefElement: HTMLDivElement;

  componentDidMount() {
    this.contentRefElement.addEventListener('blur', this.onBlur);
    this.contentRefElement.focus();
    this.checkSettingsPosition();
  }

  componentWillUnmount() {
    this.contentRefElement.removeEventListener('blur', this.onBlur);
  }

  onBlur = e => {
    this.props.clickedOutside();
  };

  checkSettingsPosition = () => {
    if (this.contentRefElement) {
      const {top, height} = this.contentRefElement.getBoundingClientRect();
      const diff = document.documentElement.clientHeight - (top + height);

      if (diff < 30) {
        this.contentRefElement.style.bottom = `-45px`;
        this.contentRefElement.style.top = `unset`;
      }
    }
  };

  render() {
    const {
      showImageThumbnailsLayer,
      showProgramTimingsLayer,
      showMarkupsLayer,
      showCommentsLayer,
      showAudioLayer,
      showSubtitlesLayer
    } = this.props.layersVisibility;

    return (
      <>
        <div className="timeline-settings-container_arrow" />
        <div className="timeline-settings-container_popup" tabIndex={-1} ref={node => (this.contentRefElement = node)}>
          <div className="timeline-settings-container_popup_content">
            <span>Timeline Settings</span>
            <div className="timeline-settings-container_popup_content_settings">
              <CustomCheckbox
                id="thumbnails"
                index="thumbnails"
                checked={showImageThumbnailsLayer}
                text="Thumbnails"
                templateColors={this.props.templateColors}
                onChange={e =>
                  this.props.onUpdateLayerVisibility(ITimelineLayers.Thumbnails, !showImageThumbnailsLayer)
                }
              />
              <CustomCheckbox
                id="programTimings"
                index="programTimings"
                checked={showProgramTimingsLayer}
                text="Program Timings"
                templateColors={this.props.templateColors}
                onChange={e =>
                  this.props.onUpdateLayerVisibility(ITimelineLayers.ProgramsTiming, !showProgramTimingsLayer)
                }
              />
              <CustomCheckbox
                id="markups"
                index="markups"
                checked={showMarkupsLayer}
                text="Markups"
                templateColors={this.props.templateColors}
                onChange={e => this.props.onUpdateLayerVisibility(ITimelineLayers.Markups, !showMarkupsLayer)}
              />
              <CustomCheckbox
                id="comments"
                index="comments"
                checked={showCommentsLayer}
                text="Comments"
                templateColors={this.props.templateColors}
                onChange={e => this.props.onUpdateLayerVisibility(ITimelineLayers.Comments, !showCommentsLayer)}
              />
              <CustomCheckbox
                id="audio"
                index="audio"
                checked={showAudioLayer}
                text="Audio"
                templateColors={this.props.templateColors}
                onChange={e => this.props.onUpdateLayerVisibility(ITimelineLayers.Audio, !showAudioLayer)}
              />
              <CustomCheckbox
                id="subtitles"
                index="subtitles"
                checked={showSubtitlesLayer}
                text="Subtitles"
                templateColors={this.props.templateColors}
                onChange={e => this.props.onUpdateLayerVisibility(ITimelineLayers.Subtitles, !showSubtitlesLayer)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
