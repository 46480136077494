import * as React from 'react';

interface IReparentableProps {
  el: HTMLElement;
  className?: string;
}

export class Reparentable extends React.Component<IReparentableProps> {
  container: HTMLDivElement = null;
  private readonly ref = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.ref.current!.appendChild(this.props.el);
    this.container = this.ref.current;
  }

  render() {
    const {className} = this.props;
    return <div ref={this.ref} className={className} />;
  }
}
