import * as React from 'react';

export const RegroupIcon = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/Regroup</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/Regroup" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" stroke="#1F72F2" x="5.5" y="5.5" width="6" height="6" />
        <rect id="Rectangle-Copy" stroke="#1F72F2" x="9.5" y="9.5" width="9" height="9" />
      </g>
    </svg>
  );
};
