import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const SubtitlesIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      viewBox="0 0 63 64"
      className="subtitles-icon"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2643.000000, -1761.000000)" fill={props.color} fillRule="nonzero" id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-subtitles" transform="translate(2221.000000, 7.000000)">
                  <path
                    d="M56.2500549,0 L6.25005493,0 C2.81250275,0 0,2.81250275 0,6.24990845 
                  L0,43.7499451 C0,47.1874973 2.81250275,50 6.25005493,50 C22.5040649,50 
                  34.6945724,50 42.8215774,50 C44.532855,50 56.2500549,65.2665228 56.2500549,62.7572616 
                  C56.2500549,60.2918643 56.2500549,56.0394437 56.2500549,50 C59.6876071,50 
                  62.5001099,47.1874973 62.5001099,43.7499451 L62.5001099,6.24990845 C62.5001099,2.81250275 
                  59.6876071,0 56.2500549,0 Z M6.25005493,24.9999268 L18.7500183,24.9999268 L18.7500183,31.2499817 
                  L6.25005493,31.2499817 L6.25005493,24.9999268 Z M37.5000366,43.7499451 L6.25005493,43.7499451 
                  L6.25005493,37.4998901 L37.5000366,37.4998901 L37.5000366,43.7499451 Z M56.2500549,43.7499451 
                  L43.7500916,43.7499451 L43.7500916,37.4998901 L56.2500549,37.4998901 L56.2500549,43.7499451 
                  Z M56.2500549,31.2499817 L25.0000732,31.2499817 L25.0000732,24.9999268 L56.2500549,24.9999268 
                  L56.2500549,31.2499817 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
