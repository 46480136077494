import * as ttmanAPI from './ttmanAPI';
import * as authAPI from './authAPI';
import * as atlasAPI from './atlasAPI';
import * as hybrikAPI from './hybrikAPI';
import * as oneUIAPI from './oneUIAPI';
import * as onePlayerService from './onePlayerService';
import * as customersAPI from './customerAPI';
import {ErrorPayload} from '../models/ErrorPayload/ErrorPayload';
import {parseTextResponse} from '../utils/utils';

export {ttmanAPI, authAPI, atlasAPI, hybrikAPI, oneUIAPI, onePlayerService, customersAPI};

interface IFetchResponse extends Response {
  timeoutError?: string;
}

const timeoutHandler = (ms: number, fetchPromise: Promise<any>): Promise<IFetchResponse> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve({timeoutError: `Request reached timeout limit ${ms}`} as IFetchResponse), ms);
    fetchPromise.then(resolve, reject).catch(reject);
  });
};

const parseErrorContent = async (fetchResult: IFetchResponse, getErrorPayload: boolean = false) => {
  const contentType = fetchResult.headers.get('content-type');
  let hasTextResponse = contentType && contentType.indexOf('text/plain') !== -1 ? true : false;
  let errorPayload = hasTextResponse ? await parseTextResponse(fetchResult.text()) : await fetchResult.json();
  // Update flag in case we got a valid parsed JSON object from the text response
  hasTextResponse = hasTextResponse ? typeof errorPayload === 'string' : false;
  console.log('Parsed error from response', errorPayload);

  let errorText;
  if (typeof errorPayload === 'string') {
    errorText = errorPayload;
  } else if (errorPayload.message) {
    // Note: Handles error object response from the One Player Service
    errorText = errorPayload.message;
  } else if (errorPayload.detail) {
    errorText = errorPayload.detail;
  } else if (errorPayload.response) {
    errorText = errorPayload.response;
  } else {
    errorText = `Bad response from server. ${fetchResult.statusText} (${fetchResult.status})`;
  }
  const error = getErrorPayload
    ? new ErrorPayload(hasTextResponse ? null : errorPayload, errorText)
    : new Error(errorText);
  throw error;
};

/**
 * Use this file to add ajax request
 * @param method
 * @param url
 * @param body
 */
export async function apiRequest(
  method: 'POST' | 'GET' | 'DELETE' | 'PATCH' | 'PUT',
  url: string,
  body?: FormData | string,
  includeRequestCredentials?: boolean,
  timeout?: number, // in milliseconds
  getErrorPayload?: boolean
) {
  let headers = {};
  let controller = null;

  if (appConfig.headers) {
    headers = {...appConfig.headers};
  }

  // appConfig.headers might already pass Authorization header, so no need to add it again
  if (!headers['Authorization']) {
    // got OneUI api token? pass it (used with passthrough routes)
    // OneUI will replace it with bearer token and forward to destination service
    if (appConfig.apiAccessToken) {
      headers['Authorization'] = `Token ${appConfig.apiAccessToken}`;
    } else {
      // fetch bearer token from Auth service
      let token: string = await authAPI.token();
      headers['Authorization'] = `Bearer ${token}`;
    }
  }

  if (!(body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  let requestData = {
    method,
    body,
    headers: new Headers(headers),
    mode: 'cors' as RequestMode
  };

  if (includeRequestCredentials) {
    requestData['credentials'] = 'include' as RequestCredentials;
  }

  // NOTE: Please when using timeout option take in consideration that it's an experimental
  // feature and not ready for production. Limit its use only for testing
  if (typeof (window as any).AbortController !== 'undefined' && timeout) {
    controller = new AbortController();
    requestData['signal'] = controller.signal;
  }

  const fetchResult: IFetchResponse = controller
    ? await timeoutHandler(timeout, fetch(url, requestData))
    : await fetch(url, requestData);

  if (fetchResult.timeoutError) {
    controller.abort();
    throw new Error(fetchResult.timeoutError);
  }

  const contentType = fetchResult.headers.get('content-type');

  if (!fetchResult.ok) {
    await parseErrorContent(fetchResult, getErrorPayload);
  }
  //if json in response
  if (contentType && contentType.includes('application/json')) {
    const json = await fetchResult.json();
    if (json.error) {
      let errorText = Array.isArray(json.error) ? json.error[0].message : json.error;
      errorText = errorText + '\n' + (json.log && json.log[0] ? json.log[0].message : '');
      console.log('apiRequest error', json);
      throw new Error(errorText);
    }

    if (json.httpStatus && json.httpStatus !== 200) {
      console.log('apiRequest error', json);
      throw new Error(json.message + (json.extendedMessage ? json.extendedMessage : ''));
    }

    return json;
  } else if (contentType && contentType.includes('application/xml')) {
    return fetchResult.text();
  } else {
    return fetchResult.blob();
  }
}
