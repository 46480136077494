import {Action, handleActions} from 'redux-actions';
import {IQualityMetrics} from '../../@types/qualityMetrics';
import {
  UPDATE_QUALITY_LEVELS,
  UPDATE_LOADED_LEVEL,
  UPDATE_CURRENT_DROP,
  UPDATE_TOTAL_DROP
} from '../actions/qualityMetrcis';

export const DEFAULT_QUALITY_METRICS: IQualityMetrics = {
  qualityLevels: [],
  loadedLevel: null,
  currentDropped: 0,
  totalDroppedFrames: 0
};

export const qualityMetrics = handleActions<IQualityMetrics, any>(
  {
    [UPDATE_QUALITY_LEVELS]: (state: IQualityMetrics, action: Action<UPDATE_QUALITY_LEVELS>): IQualityMetrics => {
      return {
        ...state,
        qualityLevels: action.payload
      };
    },
    [UPDATE_LOADED_LEVEL]: (state: IQualityMetrics, action: Action<UPDATE_QUALITY_LEVELS>): IQualityMetrics => {
      return {
        ...state,
        loadedLevel: action.payload
      };
    },
    [UPDATE_CURRENT_DROP]: (state: IQualityMetrics, action: Action<UPDATE_CURRENT_DROP>): IQualityMetrics => {
      return {
        ...state,
        currentDropped: action.payload
      };
    },
    [UPDATE_TOTAL_DROP]: (state: IQualityMetrics, action: Action<UPDATE_CURRENT_DROP>): IQualityMetrics => {
      return {
        ...state,
        totalDroppedFrames: action.payload
      };
    }
  },
  DEFAULT_QUALITY_METRICS
);
