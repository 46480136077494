import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Dropdown} from '../../../../Dropdown';
import {
  searchTitles,
  getVersionsByFeatureId,
  getConformanceGroupsByFeatureVersionId
} from '../../../../../data/atlasAPI';
import {ISearchTitleParams, ISearchTitle} from '../../../../../../@types/searchTitle';
import {PlaylistAsset} from '../../../../../models/PlaylistAsset/PlaylistAsset';
import {parseOptions, parseWithIdOptions} from '../../../../../utils/utils';
import {getUniqueArrayObjectsByField} from '../../../../../modules/Tabs/utils/helpers';
import {IFeatureCredentials} from '../../../../../state/IAppState';

interface IFeatureProps {
  onConformanceSelected: (titles: Array<ISearchTitle>) => void;
  closestBodyElement?: HTMLElement;
  data: IFeatureCredentials;
}

interface IFeatureFieldState {
  selectedFeature: string;
  featureOptions: Array<ISearchTitle>;
  filtering: boolean;
  loadingFeature: boolean;
  searchString: string;
}

interface IVersionFieldState {
  selectedVersion: string;
  versionOptions: Array<ISearchTitle>;
  loadingVersion: boolean;
}

interface IConformanceGroupFieldState {
  selectedConformanceGroup: string;
  conformanceGroupOptions: Array<ISearchTitle>;
  loadingConformanceGroup: boolean;
}

type IFeatureState = IFeatureFieldState & IVersionFieldState & IConformanceGroupFieldState;

export class Feature extends React.Component<IFeatureProps, IFeatureState> {
  static defaultProps = {
    closestBodyElement: null
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedFeature: null,
      featureOptions: [],
      filtering: false,
      loadingFeature: false,
      searchString: '',
      selectedVersion: null,
      versionOptions: [],
      loadingVersion: false,
      selectedConformanceGroup: null,
      conformanceGroupOptions: [],
      loadingConformanceGroup: false
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: IFeatureProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.init();
    }
  }

  init = async () => {
    const {featureId, featureVersionId} = this.props.data;
    if (!featureId) {
      return;
    }
    await new Promise(resolve => this.setState({loadingFeature: true}, resolve));
    // Load feature title, select provided title and load versions
    await this.queryTitle(featureId, 'atlasId');
    await new Promise(resolve => this.onTitleSelect(featureId, resolve));
    if (!featureVersionId) {
      return;
    }
    // Select provided version and load related conformance groups
    await new Promise(resolve => this.onVersionSelected(featureVersionId, resolve));
  };

  onSearch = async (featureName: string) => {
    this.setState({searchString: featureName, filtering: true});
    await this.queryTitle(featureName);
    this.setState({filtering: false});
  };

  queryTitle = async (value: string, prop: 'name' | 'atlasId' = 'name') => {
    const titleRecord = this.state.featureOptions.find(
      (title: ISearchTitle) => title.id === this.state.selectedFeature
    );
    const params: ISearchTitleParams = {
      [prop]: value,
      types: 'Feature',
      size: 100
    };
    const searchResponse = await searchTitles(params);
    const featureOptions = getUniqueArrayObjectsByField(
      [...searchResponse.data, titleRecord].filter(title => title),
      'id'
    );
    this.setState({featureOptions, loadingFeature: false});
  };

  onTitleSelect = (selectedFeature: string, callback?: () => void) => {
    this.setState(
      {
        selectedFeature,
        selectedVersion: null,
        selectedConformanceGroup: null,
        versionOptions: [],
        conformanceGroupOptions: []
      },
      async () => {
        this.props.onConformanceSelected([]);
        await this.queryVersion();
        if (callback) {
          callback();
        }
      }
    );
  };

  queryVersion = async () => {
    this.setState({loadingVersion: true});
    const versionsResponse = await getVersionsByFeatureId(this.state.selectedFeature);
    const versionOptions = versionsResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
    this.setState({loadingVersion: false, versionOptions});
  };

  onVersionSelected = (selectedVersion: string, callback?: () => void) => {
    this.setState(
      {
        loadingConformanceGroup: true,
        selectedVersion,
        selectedConformanceGroup: null,
        conformanceGroupOptions: []
      },
      async () => {
        this.props.onConformanceSelected([]);
        const conformanceResponse = await getConformanceGroupsByFeatureVersionId(
          this.state.selectedFeature,
          this.state.selectedVersion
        );
        const conformanceGroupOptions = conformanceResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
        this.setState({loadingConformanceGroup: false, conformanceGroupOptions});
        if (callback) {
          callback();
        }
      }
    );
  };

  onConformanceGroupSelected = (selectedConformanceGroup: string) => {
    this.setState({selectedConformanceGroup}, () => {
      const featureTitle = this.state.featureOptions.find(
        (title: ISearchTitle) => title.id === this.state.selectedFeature
      );
      const featureVersionTitle = this.state.versionOptions.find(
        (version: ISearchTitle) => version.id === this.state.selectedVersion
      );
      const conformanceGroupTitle = this.state.conformanceGroupOptions.find(
        (conformance: ISearchTitle) => conformance.id === this.state.selectedConformanceGroup
      );
      this.props.onConformanceSelected([
        {...featureTitle, type: 'Feature'},
        {...featureVersionTitle, type: 'FeatureVersion'},
        {...conformanceGroupTitle, type: 'FeatureConformance'}
      ]);
    });
  };

  render() {
    return (
      <div className="feature-search-container">
        <div className="feature-search-container_dropdown-container bottom-padding">
          <Dropdown
            emptyPlaceholder={
              this.state.loadingFeature ? 'Loading...' : this.state.filtering ? 'Filtering...' : 'Empty'
            }
            label="Title Name *"
            search
            searchOnEnter
            disabled={this.state.loadingFeature}
            options={this.state.featureOptions.map(parseOptions)}
            selectedPlaceholder={this.state.loadingFeature ? 'Loading...' : 'Select title...'}
            selected={this.state.selectedFeature}
            onSelected={this.onTitleSelect}
            portalNode={this.props.closestBodyElement}
            onSearch={this.onSearch}
            searchValue={this.state.searchString}
            disableSearchInput={this.state.filtering}
            contentListLimit={5}
          />
        </div>
        <div className="feature-search-container_dropdown-container bottom-padding">
          <Dropdown
            label="Version Name / ID *"
            options={this.state.versionOptions.map(parseWithIdOptions)}
            selectedPlaceholder={this.state.loadingVersion ? 'Loading...' : 'Select version...'}
            disabled={this.state.loadingVersion}
            search
            selected={this.state.selectedVersion}
            onSelected={this.onVersionSelected}
            portalNode={this.props.closestBodyElement}
            contentListLimit={5}
          />
        </div>
        <div className="feature-search-container_dropdown-container bottom-padding">
          <Dropdown
            label="Conformance Group Name / ID *"
            options={this.state.conformanceGroupOptions.map(parseWithIdOptions)}
            selectedPlaceholder={this.state.loadingConformanceGroup ? 'Loading...' : 'Select conformance group...'}
            disabled={this.state.loadingConformanceGroup}
            search
            selected={this.state.selectedConformanceGroup}
            onSelected={this.onConformanceGroupSelected}
            portalNode={this.props.closestBodyElement}
            contentListLimit={5}
          />
        </div>
      </div>
    );
  }
}
