import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const TimeOutIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '14px'}
      height={props.size || '14px'}
      viewBox="0 0 45 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="One-Player-in-Compliance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="One-Player"
          transform="translate(-3943.000000, -1656.000000)"
          fill={props.color || '#ffffff'}
          fillRule="nonzero"
        >
          <g id="Info-Panel" transform="translate(0.000000, 325.000000)">
            <g id="Comments-Tab-Selected" transform="translate(3014.000000, 0.000000)">
              <g id="Comment-Input" transform="translate(0.000000, 1240.000000)">
                <g id="Timecode-In-Out" transform="translate(0.000000, 67.000000)">
                  <g id="Pgm-Timing-Button-Copy-29" transform="translate(673.000000, 17.000000)">
                    <g id="sign-out-alt-solid" transform="translate(256.555880, 7.207317)">
                      <path
                        d="M43.3723121,17.4193031 L28.7112488,31.4199976 C27.4022253,32.6700596 
                        25.1332513,31.7950162 25.1332513,30.0032606 L25.1332513,22.0028638 
                        L13.2647715,22.0028638 C12.104104,22.0028638 11.1703339,21.1111529 
                        11.1703339,20.0027646 L11.1703339,12.0023677 C11.1703339,10.8939794 
                        12.104104,10.0022685 13.2647715,10.0022685 L25.1332513,10.0022685 
                        L25.1332513,2.00187169 C25.1332513,0.218449891 27.3934985,-0.664927261 
                        28.7112488,0.585134746 L43.3723121,14.5858292 C44.1839066,15.3692014 
                        44.1839066,16.6359309 43.3723121,17.4193031 Z M16.7555008,31.0033103 
                        L16.7555008,27.6698116 C16.7555008,27.1197843 16.2842524,26.669762 
                        15.708282,26.669762 L8.37775042,26.669762 C6.83310268,26.669762 
                        5.58516694,25.4780362 5.58516694,24.002963 L5.58516694,8.00216932 
                        C5.58516694,6.52709615 6.83310268,5.33537037 8.37775042,5.33537037 
                        L15.708282,5.33537037 C16.2842524,5.33537037 16.7555008,4.88534805 
                        16.7555008,4.33532077 L16.7555008,1.00182208 C16.7555008,0.451794799 
                        16.2842524,0.00177247619 15.708282,0.00177247619 L8.37775042,0.00177247619 
                        C3.75253404,0.00177247619 0,3.58528356 0,8.00216932 L0,24.002963 
                        C0,28.4198488 3.75253404,32.0033599 8.37775042,32.0033599 
                        L15.708282,32.0033599 C16.2842524,32.0033599 16.7555008,31.5533375 
                        16.7555008,31.0033103 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
