import * as React from 'react';
import {IAppPlaylist} from '../../../../state/IAppState';
import {ITabElement, TabsComponent} from '../TabsComponent';
import {Details} from './components/Details';
import {Video} from '../Video';
import {Audio} from '../Audio';
import {Image} from '../Image';
import {NonMedia} from '../NonMedia';
import {ProgramTimings} from '../PrgramTimings';
import {Subtitles} from '../Subtitles';
import {IAudioMetadata, ITabs} from '../../interfaces';
import {IAudioChannelConfiguration} from '../../../../../@types/audioChannelConfiguration';
import {IEnums} from '../../../../state/IEnums';
import {IResponse} from '../../../../../@types/response';
import {IEventGroup} from '../../../../../@types/markupEvent';
import {IAssetDetails} from '../../../../../@types/assetDetails';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {RegroupIcon} from '../../../../assets/Icons/RegroupIcon';
import {Icon} from 'tt-components/src/Icon';
import {IMetadataErrors} from '../../interfaces/IMetadataTab';

export interface IMetadataProps {
  updatedAssetDetails: Partial<IAssetDetails>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  selectedAsset: PlaylistAsset;
  selectedTab: ITabs;
  selectMetadataTab: (tab: ITabs) => void;
  updateAudioConfigurations: (confs: IAudioChannelConfiguration) => IResponse;
  metadataErrors: IMetadataErrors;
  audioMetadata: IAudioMetadata;
  enums: IEnums;
  data: IAssetDetails;
  playlist: IAppPlaylist;
  goToLocation: (time: string) => void;
  loading: boolean;
  tabsDataInEditMode: boolean;
  programTimings: IEventGroup;
  closestBody?: HTMLElement;
  setAudioMetaData: (data: Partial<IAssetDetails>, index: number) => void;
  tabsContainer: HTMLElement;
  curationModeEnabled: boolean;
}

interface IMetadataState {
  isAudioMapping: boolean;
}

export class Metadata extends React.PureComponent<IMetadataProps, IMetadataState> {
  constructor(props) {
    super(props);

    this.state = {
      isAudioMapping: false
    };
  }

  componentDidUpdate(prevProps: IMetadataProps) {
    if (
      prevProps.selectedTab !== this.props.selectedTab ||
      (prevProps.loading !== this.props.loading && this.props.loading)
    ) {
      this.disableMapping();
    }
  }

  getTabs = () => {
    const tabs: ITabElement[] = [
      {
        type: ITabs.DetailsMetadata,
        name: 'Details',
        element: Details,
        visible: true,
        errors: (this.props.metadataErrors.metadataDetails || []).length
      },
      {type: ITabs.VideoMetadata, name: 'Video', element: Video, visible: true},
      {type: ITabs.AudioMetadata, name: 'Audio', element: Audio, visible: true},
      {type: ITabs.SubtitlesMetadata, name: 'Subtitles', element: Subtitles, visible: true},
      {type: ITabs.ImageMetadata, name: 'Images', element: Image, visible: true},
      {type: ITabs.NonMediaMetadata, name: 'Non Media', element: NonMedia, visible: true},
      {
        type: ITabs.ProgramTimingsMetadata,
        name: `Program Timings${this.getProgramTimingsCount()}`,
        element: ProgramTimings,
        visible: true
      }
    ];
    return tabs;
  };

  renderContent = () => {
    const selectedTab = this.getTabs().find(({type}: ITabElement) => this.props.selectedTab === type);
    if (!selectedTab) {
      return;
    }
    const TabContent = selectedTab.element;
    // NOTE: The errorLogs will be available only in cases when the 'Edit Mode' is enabled for Tab module
    const errorLogs =
      this.props.selectedAsset && this.props.tabsDataInEditMode ? this.props.selectedAsset.assetDetails.errorLogs : [];
    let props = null;
    switch (this.props.selectedTab) {
      case ITabs.DetailsMetadata:
        props = {
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          errorLogs,
          updatedAssetDetails: this.props.updatedAssetDetails,
          selectedAsset: this.props.selectedAsset,
          assetStatusEnums: this.props.enums.assetStatus,
          fileWrapperEnums: this.props.enums.fileWrapper,
          functionEnums: this.props.enums.assetFunction,
          contentTypeEnums: this.props.enums.contentType,
          formatComplianceEnums: this.props.enums.formatCompliance,
          frameRateEnums: this.props.enums.frameRate,
          referencesNameEnums: this.props.enums.referencesName,
          referencesTypeEnums: this.props.enums.referencesType,
          closestBody: this.props.closestBody,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          tabsContainer: this.props.tabsContainer,
          curationModeEnabled: this.props.curationModeEnabled,
          detailsMetadataErrors: this.props.metadataErrors.metadataDetails
        };
        break;
      case ITabs.VideoMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          videoMetadataErrors: this.props.metadataErrors.metadataVideo ? this.props.metadataErrors.metadataVideo : {},
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          closestBody: this.props.closestBody
        };
        break;
      case ITabs.AudioMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          processingMetadata: this.props.audioMetadata ? this.props.audioMetadata.metadataProcessing : false,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          isMapping: this.state.isAudioMapping,
          onDisableMapping: this.disableMapping,
          updateAudioConfigurations: this.props.updateAudioConfigurations,
          loading: this.props.loading,
          error: this.props.playlist ? this.props.playlist.error : null,
          closestBody: this.props.closestBody
        };
        break;
      case ITabs.ImageMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          closestBody: this.props.closestBody
        };
        break;
      case ITabs.NonMediaMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          closestBody: this.props.closestBody
        };
        break;
      case ITabs.SubtitlesMetadata:
        props = {
          playlist: this.props.playlist,
          enums: this.props.enums
        };
        break;
      case ITabs.ProgramTimingsMetadata:
        props = {
          data: this.props.programTimings,
          playlist: this.props.playlist,
          goToLocation: this.props.goToLocation
        };
        break;
      default:
        props = {content: ''};
    }

    return <TabContent {...props} />;
  };

  getProgramTimingsCount = () => {
    return this.props.programTimings ? ` ${this.props.programTimings.events.length}` : ``;
  };

  enableMapping = () => {
    this.setState({isAudioMapping: true});
  };

  disableMapping = () => {
    this.setState({isAudioMapping: false});
  };

  toggleMapping = () => {
    this.setState({isAudioMapping: !this.state.isAudioMapping});
  };

  render() {
    const {selectedTab} = this.props;
    return (
      <div className="metadata-container">
        <TabsComponent
          gridView
          tabsList={this.getTabs()}
          selectedTab={this.props.selectedTab}
          onTabChanged={(tab: ITabs) => this.props.selectMetadataTab(tab)}
          tabContent={this.renderContent()}
        />
        {selectedTab === 'AudioMetadata' && (
          <div className="regroup-button" onClick={this.toggleMapping}>
            <Icon icon={RegroupIcon} size="17px" />
            <p>REGROUP</p>
          </div>
        )}
      </div>
    );
  }
}
