import * as React from 'react';
import {AudioTrackDetails} from './AudioTrackDetails';
import {Row, Column} from '../../../../../Grid';
import {Tt1} from '../../../../../../../../components/Typography';
import {IEnums} from '../../../../../../../../state/IEnums';
import {parseEnum} from '../../../../../../../../utils/utils';

interface IAudioConfigProps {
  data: any;
  enums: IEnums;
}

export class AudioConfig extends React.Component<IAudioConfigProps> {
  render() {
    return (
      <div className="audio-config-container">
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Type" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.type} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Audio Language" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1
              content={parseEnum(this.props.data.language, this.props.enums.language, 'Undefined')}
              className="audio-detail-row_label"
            />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Channel config" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.channelConfig} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Language" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1
              content={parseEnum(this.props.data.language, this.props.enums.language, 'Undefined')}
              className="audio-detail-row_label"
            />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Country" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1
              content={parseEnum(this.props.data.country, this.props.enums.countries, 'Undefined')}
              className="audio-detail-row_label"
            />
          </Column>
        </Row>
        <Row className="audio-config-container_row audio-section-header">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Tracks " className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value" />
        </Row>
        <div className="audio-config-container-tracks" />
        {this.props.data.trackDetail.map((item, index) => {
          return <AudioTrackDetails key={index} data={item} channelMapEnums={this.props.enums.channelMap} />;
        })}
      </div>
    );
  }
}
