import * as React from 'react';

type ICellContent = JSX.Element | string | number;

interface IConfigRowProps {
  fourCellConf?: boolean;
  className?: string;
  cellX1Content?: ICellContent;
  cellX2Content?: ICellContent;
  cellX3Content?: ICellContent;
  cellX4Content?: ICellContent;
  cellX5Content?: ICellContent;
  cellX6Content?: ICellContent;
}

export class ConfigRow extends React.PureComponent<IConfigRowProps> {
  render() {
    const {
      className,
      cellX1Content,
      cellX2Content,
      cellX3Content,
      cellX4Content,
      cellX5Content,
      cellX6Content
    } = this.props;

    return (
      <div className={`config-row-container${className ? ` ${className}` : ``}`}>
        <div className="config-row-container_x1 cell">{cellX1Content || null}</div>
        <div className="config-row-container_x2 cell">{cellX2Content || null}</div>
        <div className="config-row-container_x3 cell">{cellX3Content || null}</div>
        <div className="config-row-container_x4 cell">{cellX4Content || null}</div>
        <div className="config-row-container_x5 cell">{cellX5Content || null}</div>
        <div className="config-row-container_x6 cell">{cellX6Content || null}</div>
      </div>
    );
  }
}
