import {utils} from 'tt-components/src/Utils';
import {IFrameRate} from 'tt-components';

export const getTimeInSeconds = (time: number | string, frameRateObject: IFrameRate) => {
  const {frameRate, dropFrame} = frameRateObject;
  return typeof time === 'string' ? utils.formatting.smpteTimecodeToSeconds(time, frameRate, dropFrame) : time;
};

export const getPartialObjectByProps = (obj: {[x: string]: any}, props: Array<string>) => {
  return props.reduce((acc: Partial<{[x: string]: any}>, prop: string) => {
    return {...acc, [prop]: obj.hasOwnProperty(prop) ? obj[prop] : undefined};
  }, {});
};
