import * as React from 'react';
import {sizeMe, WaveformMosaic, IWaveformMosaicProps} from 'tt-components';
import {ScrollContainer} from 'modules/Timeline/components/ScrollContainer';
import {TmpElement} from 'modules/Timeline/components/TmpElement';

interface IAudioLayerContentProps {
  width: number;
  scrollLeft: number;
  url: string;
  duration: number;
}

interface IAudioLayerContentState {
  loading: boolean;
  containerHeight: number;
  waveformHeight: number;
  url: string;
  duration: number;
  sampleRate: number;
  start?: number;
  end?: number;
  sampling?: Uint8Array;
  zoom?: number;
}

const AdaptiveWaveform = sizeMe()(WaveformMosaic) as React.ComponentClass<Partial<IWaveformMosaicProps>>;

export class AudioLayerContent extends React.Component<IAudioLayerContentProps, IAudioLayerContentState> {
  containerRefElement: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      containerHeight: 25,
      waveformHeight: 25,
      url: this.props.url,
      duration: this.props.duration,
      sampleRate: 8000
    };
  }

  componentDidMount() {
    if (this.props.url) {
      this.load().catch(e => console.error(e));
    }
  }

  renderWaveformsUI = () => {
    const {start, end, sampling, sampleRate} = this.state;

    const Content = (
      <AdaptiveWaveform
        sampling={sampling}
        sampleRate={sampleRate}
        start={start}
        end={end}
        style={style.waveform}
        barColor={'#25579f'}
      />
    );

    return Content;
  };

  renderStateComponent = () => {
    const {loading, url} = this.state;
    if (loading) {
      return <TmpElement text={`Getting waveforms`} />;
    }

    return url.length ? null : <TmpElement text={`Waveforms content is empty`} />;
  };

  render() {
    const {loading} = this.state;
    const waveforms = this.renderWaveformsUI();
    const isLoading = loading;
    const contentStyles = isLoading ? {} : {width: this.props.width, height: this.state.containerHeight};

    return (
      <ScrollContainer
        contentClassName={`audiolayer-content-container_content${isLoading ? ` loading` : ``}`}
        contentStyle={contentStyles}
        scrollLeft={this.props.scrollLeft}
      >
        {!loading && waveforms}
        {this.renderStateComponent()}
      </ScrollContainer>
    );
  }

  private async load(): Promise<any> {
    const {url, sampleRate, duration} = this.state;
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    this.setState({
      sampling: new Uint8Array(buffer),
      sampleRate,
      duration,
      start: 0,
      end: duration,
      loading: false
    });
  }
}

const style = {
  waveform: {
    background: '#070e17'
  },
  btn: {
    marginRight: 10
  },
  zoom: {
    verticalAlign: 'middle',
    marginRight: 10
  }
};
