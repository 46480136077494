import * as React from 'react';
import {ITabs} from '../../interfaces';
import {ButtonsGroup, IButtonConfig} from '../../../../components/ButtonsGroup';
import {Tt2b} from '../../../../components/Typography/Tt2b';

export interface ITabElement {
  type: ITabs;
  name: string;
  element: React.ComponentType;
  visible?: boolean;
  disabled?: boolean;
  errors?: number;
}

interface ITabsComponentProps {
  tabsList: ITabElement[];
  selectedTab: ITabs;
  onTabChanged: (type: ITabs) => void;
  tabContent: JSX.Element;
  gridView?: boolean;
}

const active = (type: ITabs, selected: ITabs) => (selected === type ? ' active' : '');
const empty = (type: ITabs) => (type === ITabs.Empty ? ' empty' : '');
const disable = (disabled: boolean) => (disabled ? ' disabled' : '');
const error = (errors: number) => (errors ? ` error-msg` : ``);

export class TabsComponent extends React.Component<ITabsComponentProps> {
  onTabChanged = (type: ITabs) => {
    if (type !== ITabs.Empty) {
      this.props.onTabChanged(type);
    }
  };

  getUnderlinedTabs = () => {
    const selected = this.props.selectedTab;
    return this.props.tabsList
      .filter(t => t.visible)
      .map(({type, name, disabled, errors}: ITabElement, index) => (
        <div
          key={index}
          className={`tabs-component_tabs_tab-content${empty(type)}${active(type, selected)}${disable(disabled)}${error(
            errors
          )}`}
          onClick={e => this.onTabChanged(type)}
        >
          {name}
          {errors ? ` ( ${errors} )` : ``}
        </div>
      ));
  };

  getGridTabs = () => {
    const selected = this.props.selectedTab;
    const options: Array<IButtonConfig> = this.props.tabsList
      .filter((tab: ITabElement) => tab.visible)
      .map(
        ({type, name, errors}: ITabElement) =>
          ({
            value: type as string,
            content: (
              <Tt2b
                content={`${name}${errors ? ` ( ${errors} )` : ``}`}
                className={`tabs-component_tabs_grid_tab-content${active(type, selected)}${error(errors)}`}
              />
            ),
            hasError: !!errors
          } as IButtonConfig)
      );
    return (
      <ButtonsGroup
        options={options}
        selectedConfig={this.props.selectedTab as string}
        onOptionChanged={this.onTabChanged}
      />
    );
  };

  getTabs() {
    return this.props.gridView ? this.getGridTabs() : this.getUnderlinedTabs();
  }

  render() {
    return (
      <div className="tabs-component">
        <div className={`tabs-component_tabs${this.props.gridView ? `_grid` : ``}`}>{this.getTabs()}</div>
        <div className="tabs-component_content">{this.props.tabContent}</div>
      </div>
    );
  }
}
