import {PlaylistAsset} from '../PlaylistAsset';
import {IPlaylistAsset} from '../../../state/IAppState';
import {IAssetDetails} from '../../../../@types/assetDetails';
import {IAudioChannelConfiguration} from '../../../../@types/audioChannelConfiguration';
import {parsing} from './parsing';
import {IEventGroup} from '../../../../@types/markupEvent';
import {deepCopy} from 'modules/Tabs/utils/helpers';
import {ISearchTitle} from '../../../../@types/searchTitle';
import {IVideoSubs} from '../../../state/IVideoSubs';
import {ISubtitlesMetadata} from '../../../../@types/subtitlesMetadata';

const updateSubtitleTTManError = (
  assets: Array<PlaylistAsset>,
  subtitleId: string,
  isTTManError: boolean
): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (asset.isSubtitlesAsset()) {
      const subtitles = (asset.subtitles || []).reduce(
        (acc: Array<ISubtitlesMetadata>, subtitle: ISubtitlesMetadata) => {
          if (subtitleId === subtitle.id) {
            subtitle.isTTManError = isTTManError;
          }
          return [...acc, subtitle];
        },
        []
      );
      asset.updateAssetSubtitles(subtitles);
    }
    return [...acc, asset];
  }, []);
};

const updateSubtitleTrackUrl = (
  assets: Array<PlaylistAsset>,
  trackId: string,
  src: string,
  getSubtitleData: boolean = false
): Array<PlaylistAsset> | {updatedAssets: Array<PlaylistAsset>; subtitle: IVideoSubs} => {
  let updatedSubtitle: IVideoSubs;
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  const updatedAssets = clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (asset.isSubtitlesAsset()) {
      const subtitles = (asset.subtitles || []).reduce(
        (acc: Array<ISubtitlesMetadata>, subtitle: ISubtitlesMetadata) => {
          if (subtitle.videoSubFormat.id === trackId) {
            subtitle.videoSubFormat.url = src;
            updatedSubtitle = deepCopy({...subtitle.videoSubFormat});
          }
          return [...acc, subtitle];
        },
        []
      );
      asset.updateAssetSubtitles(subtitles);
    }
    return [...acc, asset];
  }, []);

  if (getSubtitleData) {
    return {updatedAssets, subtitle: updatedSubtitle};
  } else {
    return updatedAssets;
  }
};

const updateAssetsStatus = (assets: Array<PlaylistAsset>, assetsStatus: Array<IPlaylistAsset>) => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, record: PlaylistAsset) => {
    const match = assetsStatus.find((asset: IPlaylistAsset) => asset.assetId === record.assetId);
    if (match) {
      record.updateAssetStatus(match.assetStatus);
    }
    return [...acc, record];
  }, []);
};

const updateAssetDetails = (
  assets: Array<PlaylistAsset>,
  partial: Partial<IAssetDetails>,
  assetId: string,
  completeUpdate: boolean = false
): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (asset.assetId === assetId) {
      asset.updateAssetDetails(partial, completeUpdate);
    }
    return [...acc, asset];
  }, []);
};

const updateAssetAudioConfigurations = (
  assets: Array<PlaylistAsset>,
  audio: Array<IAudioChannelConfiguration>,
  assetId: string
): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (asset.assetId === assetId) {
      asset.updateAssetAudioConfiguration(audio);
    }
    return [...acc, asset];
  }, []);
};

const updateAssetRegisteredState = (
  assets: Array<PlaylistAsset>,
  isRegistered: boolean,
  assetId: string
): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (asset.assetId === assetId) {
      asset.isRegistered = isRegistered;
    }
    return [...acc, asset];
  }, []);
};

const updateAssetEvents = (
  assets: Array<PlaylistAsset>,
  events: Array<IEventGroup>,
  assetId: string
): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (asset.assetId === assetId) {
      asset.updateEventsProp(events);
    }
    return [...acc, asset];
  }, []);
};

const updateAssetHiddenState = (
  assets: Array<PlaylistAsset>,
  isHidden: boolean,
  assetId: string
): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (asset.assetId === assetId) {
      asset.isHidden = isHidden;
    }
    return [...acc, asset];
  }, []);
};

const updatePlaylistAssetsConformanceGroups = (assets: Array<PlaylistAsset>): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  const videoAssetWithConformanceGroup: PlaylistAsset = [...clonedAssets].find(
    // NOTE: For curation mode we will need to update all the unregistered assets with the conformance group of the
    // leading Video asset that has already been curated and has a valid conformance group assigned
    (asset: PlaylistAsset) =>
      asset.getAssetType() === 'Video' && !!asset.getTitleInfoForAsset().conformanceGroupId && asset.isRegistered
  );

  if (!videoAssetWithConformanceGroup) {
    return assets;
  }

  return [...clonedAssets].reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    const hasNoConformanceGroup = !asset.getTitleInfoForAsset().conformanceGroupId;
    if (
      !asset.isRegistered &&
      !asset.isHidden &&
      asset.assetId !== videoAssetWithConformanceGroup.assetId &&
      hasNoConformanceGroup
    ) {
      asset.updateAssetDetails({titles: deepCopy([...videoAssetWithConformanceGroup.assetDetails.titles])});
    }
    return [...acc, asset];
  }, []);
};

const updateUnregisteredAssetsTitles = (
  assets: Array<PlaylistAsset>,
  titles: Array<ISearchTitle>
): Array<PlaylistAsset> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.reduce((acc: Array<PlaylistAsset>, asset: PlaylistAsset) => {
    if (!asset.isRegistered && !asset.isHidden) {
      asset.updateAssetDetails({titles});
    }
    return [...acc, asset];
  }, []);
};

export const update = {
  updateSubtitleTTManError,
  updateSubtitleTrackUrl,
  updateAssetsStatus,
  updateAssetDetails,
  updateAssetAudioConfigurations,
  updateAssetRegisteredState,
  updateAssetEvents,
  updateAssetHiddenState,
  updatePlaylistAssetsConformanceGroups,
  updateUnregisteredAssetsTitles
};
