import {Action, handleActions} from 'redux-actions';
import {IOverlays} from '../../@types/overlays';
import {
  SHOW_DIAGNOSTICS_BOX,
  ENABLE_DIAGNOSTICS_PANEL,
  CHANGE_WATERMARK_POSITION,
  SHOW_PLAYBACK_SETUP_OVERLAY,
  SHOW_SETTINGS,
  SHOW_AUDIO_SUBS,
  SHOW_PLAYER_SPEED,
  SHOW_LOOP_CONTROL,
  SHOW_GO_TO,
  SHOW_VOLUME_CONTROL,
  SHOW_CONFORMANCE_OVERLAY
} from '../actions/overlays';
import {IWatermarkPosition} from '../../@types/watermarkPosition';

export const DEFAULT_OVERLAYS: IOverlays = {
  isDiagnosticsPanelEnabled: true,
  showDiagnostic: false,
  watermarkPosition: (localStorage.getItem('watermarkPosition') as IWatermarkPosition) || 'diagonal',
  showPlaybackSetup: false,
  isSettingsShow: false,
  isAudioSubsShow: false,
  isPlayerSpeedShow: false,
  isLoopControlShow: false,
  isGoToShow: false,
  isVolumeControlShow: false,
  showConformanceOverlay: false
};

export const overlays = handleActions<IOverlays, any>(
  {
    [SHOW_DIAGNOSTICS_BOX]: (state: IOverlays, action: Action<SHOW_DIAGNOSTICS_BOX>): IOverlays => ({
      ...state,
      showDiagnostic: action.payload
    }),
    [SHOW_SETTINGS]: (state: IOverlays, action: Action<SHOW_SETTINGS>): IOverlays => ({
      ...state,
      isSettingsShow: action.payload,
      isVolumeControlShow: false,
      isGoToShow: false,
      isAudioSubsShow: false,
      isPlayerSpeedShow: false,
      isLoopControlShow: false
    }),
    [SHOW_VOLUME_CONTROL]: (state: IOverlays, action: Action<SHOW_VOLUME_CONTROL>): IOverlays => ({
      ...state,
      isVolumeControlShow: action.payload,
      isSettingsShow: false,
      isGoToShow: false,
      isAudioSubsShow: false,
      isPlayerSpeedShow: false,
      isLoopControlShow: false
    }),
    [SHOW_GO_TO]: (state: IOverlays, action: Action<SHOW_GO_TO>): IOverlays => ({
      ...state,
      isGoToShow: action.payload,
      isVolumeControlShow: false,
      isSettingsShow: false,
      isAudioSubsShow: false,
      isPlayerSpeedShow: false,
      isLoopControlShow: false
    }),
    [SHOW_AUDIO_SUBS]: (state: IOverlays, action: Action<SHOW_AUDIO_SUBS>): IOverlays => ({
      ...state,
      isAudioSubsShow: action.payload,
      isGoToShow: false,
      isVolumeControlShow: false,
      isPlayerSpeedShow: false,
      isSettingsShow: false,
      isLoopControlShow: false
    }),
    [SHOW_PLAYER_SPEED]: (state: IOverlays, action: Action<SHOW_PLAYER_SPEED>): IOverlays => ({
      ...state,
      isPlayerSpeedShow: action.payload,
      isGoToShow: false,
      isVolumeControlShow: false,
      isAudioSubsShow: false,
      isSettingsShow: false,
      isLoopControlShow: false
    }),
    [SHOW_LOOP_CONTROL]: (state: IOverlays, action: Action<SHOW_LOOP_CONTROL>): IOverlays => ({
      ...state,
      isLoopControlShow: action.payload,
      isGoToShow: false,
      isVolumeControlShow: false,
      isPlayerSpeedShow: false,
      isAudioSubsShow: false,
      isSettingsShow: false
    }),
    [ENABLE_DIAGNOSTICS_PANEL]: (state: IOverlays, action: Action<ENABLE_DIAGNOSTICS_PANEL>): IOverlays => ({
      ...state,
      isDiagnosticsPanelEnabled: action.payload
    }),
    [CHANGE_WATERMARK_POSITION]: (state: IOverlays, action: Action<CHANGE_WATERMARK_POSITION>): IOverlays => ({
      ...state,
      watermarkPosition: action.payload
    }),
    [SHOW_PLAYBACK_SETUP_OVERLAY]: (state: IOverlays, action: Action<SHOW_PLAYBACK_SETUP_OVERLAY>): IOverlays => ({
      ...state,
      showPlaybackSetup: action.payload
    }),
    [SHOW_CONFORMANCE_OVERLAY]: (state: IOverlays, action: Action<SHOW_CONFORMANCE_OVERLAY>): IOverlays => ({
      ...state,
      showConformanceOverlay: action.payload
    })
  },
  DEFAULT_OVERLAYS
);
