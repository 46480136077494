import * as React from 'react';
import {IEnum} from '../../../../../@types/enum';
import {Dropdown, IDropdownProps, IDropdownOption} from '../../../../components/Dropdown';
import {IErrorLog} from '../../../../../@types/assetDetails';
import {getErrorLogByField} from '../../utils/helpers';

interface ILanguageDropdownProps extends Partial<IDropdownProps> {
  languageDialect: Array<IEnum>;
  language: string;
  country: string;
  errorLogs?: Array<IErrorLog>;
  onLanguageSelected: (language: string, country: string) => void;
}

export class LanguageDropdown extends React.PureComponent<ILanguageDropdownProps> {
  getOptions = () =>
    this.props.languageDialect.reduce(
      (acc: Array<IDropdownOption>, enumRecord: IEnum) => [...acc, {label: enumRecord.name, value: enumRecord.value}],
      []
    );

  onSelected = (value: string) => {
    const tokens = value.split('-');
    this.props.onLanguageSelected(tokens[0] || null, tokens[1] || null);
  };

  getSelected = (language: string, country: string) => {
    return [language, country].filter(opt => opt).join('-');
  };

  getErrorLog = () => {
    const country = getErrorLogByField('videos.country', this.props.errorLogs || []);
    const language = getErrorLogByField('videos.language', this.props.errorLogs || []);
    return country || language;
  };

  render() {
    const {languageDialect, options, selected, onSelected, language, country, ...props} = this.props;
    return (
      <Dropdown
        {...props}
        options={this.getOptions()}
        selected={this.getSelected(language, country)}
        onSelected={this.onSelected}
        errorLog={this.getErrorLog()}
      />
    );
  }
}
