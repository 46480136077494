import * as React from 'react';

export const AudioListening = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icons/listening</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/listening" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="volume-high" transform="translate(7.000000, 3.000000)" fill="#FFFFFF" fill-rule="nonzero">
          <path
            d="M3.1836,14.2572 C3.0096,14.2572 2.8368,14.1816 2.718,14.0364 C2.5092,13.7796 2.5476,13.4016 2.8044,
            13.1928 C4.0728,12.162 4.8,10.6332 4.8,9.0012 C4.8,7.3692 4.0728,5.8416 2.8056,4.8096 C2.5488,
            4.6008 2.5092,4.2228 2.7192,3.966 C2.9292,3.7092 3.306,3.6696 3.5628,3.8796 C5.112,5.1396 6,7.0068 6,
            9.0024 C6,10.998 5.112,12.864 3.5628,14.1252 C3.4512,14.2152 3.318,14.2596 3.1848,14.2596 L3.1836,14.2572 Z"
          />
          <path
            d="M5.454,17.0508 C5.28,17.0508 5.1072,16.9752 4.9884,16.83 C4.7796,16.5732 4.818,16.1952 5.0748,
            15.9864 C7.188,14.268 8.4,11.7216 8.4,9.0012 C8.4,6.2808 7.188,3.7344 5.0748,2.016 C4.818,1.8072 4.7784,
            1.4292 4.9884,1.1724 C5.1984,0.9156 5.5752,0.876 5.832,1.086 C8.226,3.0336 9.6,5.9196 9.6,9.0024 C9.6,
            12.0852 8.2272,14.9712 5.832,16.9188 C5.7204,17.0088 5.5872,17.0532 5.454,17.0532 L5.454,17.0508 Z"
          />
          <path
            d="M0.9144,11.4624 C0.7404,11.4624 0.5676,11.3868 0.4488,11.2416 C0.24,10.9848 0.2784,10.6068 0.5352,
            10.398 C0.9576,10.0536 1.2,9.5448 1.2,9.0012 C1.2,8.4576 0.9576,7.9476 0.5352,7.6044 C0.2784,7.3956 0.2388,
            7.0176 0.4488,6.7608 C0.6588,6.504 1.0356,6.4644 1.2924,6.6744 C1.9968,7.2468 2.4,8.0952 2.4,9.0024 C2.4,
            9.9096 1.9956,10.758 1.2924,11.3304 C1.1808,11.4204 1.0476,11.4648 0.9144,11.4648 L0.9144,11.4624 Z"
          />
        </g>
      </g>
    </svg>
  );
};
