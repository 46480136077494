import {IPlayerError} from '../../../@types/playerError';
import {IErrorEvent, IBitMovinPlayerErrors} from 'tt-components/src/VideoPlayer/BitMovinPlayer';

export class PlayerError implements IPlayerError {
  public code;
  public message;
  public name;

  constructor(errorEvent: IErrorEvent) {
    this.code = errorEvent.code || 0;
    this.name = errorEvent.name || 'unhandled_error';
    this.message = this.getMessage(errorEvent.data);
  }

  public genericMessageBasedOnCode = (): string => {
    if (this.isCustomErrors()) {
      const isCustomInternalError = this.name === IBitMovinPlayerErrors.INTERNAL_ERROR;
      return isCustomInternalError
        ? `Selected source seems having some issues on returning playable content to the player`
        : `Player faced an unhandled error`;
    } else if (this.isGeneralError()) {
      return `Player faced an unhandled General error.`;
    } else if (this.isSetupError()) {
      return `Something went wrong and player was not initialized properly.`;
    } else if (this.isSourceError()) {
      return `Player faced an unhandled Source error.`;
    } else if (this.isPlaybackError()) {
      return `Player faced an unhandled Playback error.`;
    } else if (this.isNetworkError()) {
      return `Player faced an unhandled Network error.`;
    } else if (this.isDRMError()) {
      return `Player faced an unhandled DRM error.`;
    } else if (this.isVRError()) {
      return `Player faced an unhandled VRM error.`;
    } else if (this.isModuleError()) {
      return `Player faced an unhandled Module error.`;
    } else if (this.isModuleAdsError()) {
      return `Player faced an unhandled Module Ads error.`;
    } else {
      return `Player faced an unknown error.`;
    }
  };

  public getMessage = (data: any): string => {
    if (this.isSetupError()) {
      return data && data['error-message'] ? data['error-message'] : this.genericMessageBasedOnCode();
    }
    if (this.isNetworkError()) {
      return data && data.message ? data.message : this.genericMessageBasedOnCode();
    }
    return this.genericMessageBasedOnCode();
  };

  public isCustomErrors = () => this.code >= 1 && this.code <= 999;
  public isGeneralError = () => this.code >= 1000 && this.code <= 1099;
  public isSetupError = () => this.code >= 1100 && this.code <= 1199;
  public isSourceError = () => this.code >= 1200 && this.code <= 1299;
  public isPlaybackError = () => this.code >= 1300 && this.code <= 1399;
  public isNetworkError = () => this.code >= 1400 && this.code <= 1499;
  public isDRMError = () => this.code >= 2000 && this.code <= 2099;
  public isVRError = () => this.code >= 2100 && this.code <= 2199;
  public isModuleError = () => this.code >= 3000 && this.code <= 3099;
  public isModuleAdsError = () => this.code >= 3100 && this.code <= 3199;
}
