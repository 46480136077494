import * as React from 'react';

interface IButtonProps {
  content: string | JSX.Element;
  secondary?: boolean;
  negative?: boolean;
  disabled?: boolean;
  small?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disablePressState?: boolean;
}

interface IButtonState {
  pressed: boolean;
}

export class Button extends React.Component<IButtonProps, IButtonState> {
  constructor(props) {
    super(props);

    this.state = {
      pressed: false
    };
  }

  onMouseDown = e => {
    if (this.props.disablePressState) {
      return;
    }
    this.setState({pressed: true});
  };

  onMouseUp = e => {
    this.setState({pressed: false});
  };

  onMouseLeave = e => {
    this.setState({pressed: false});
  };

  onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const buttonType = this.props.secondary ? ` secondary${this.props.negative ? ` negative` : ``}` : ' primary';
    const pressed = this.state.pressed ? ' pressed' : '';
    const smallSized = this.props.small ? ' small' : '';
    const className = this.props.className ? ` ${this.props.className}` : ``;
    return (
      <button
        disabled={this.props.disabled}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
        className={`typo2a button-ui-container${buttonType}${pressed}${smallSized}${className}`}
        onClick={this.onClick}
      >
        {this.props.content}
      </button>
    );
  }
}
