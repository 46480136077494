import * as React from 'react';
import {TimelineRow} from '../TimelineRow';
import {SubtitlesLabel} from './components/SubtitlesLabel';
import {SubtitlesContent} from './components/SubtitlesContent';
import {TmpElement} from '../TmpElement';
import {ITemplateColors} from '../../../../../@types/templateColors';
import {ITooltipSegmentData} from '../../interfaces';
import {Tt5} from '../../../../components/Typography/Tt5';
import {ISubtitlesMetadata} from '../../../../../@types/subtitlesMetadata';
import {IEnum} from '../../../../../@types/enum';

interface ISubtitlesProps {
  scrollLeft: number;
  secondUnits: number;
  contentWidth: number;
  subtitles: Array<ISubtitlesMetadata>;
  selectedSub: string;
  templateColors: ITemplateColors;
  languageDialect: Array<IEnum>;
  onSubtitleSelected: (subtitleId: string | number) => void;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class SubtitlesLayer extends React.Component<ISubtitlesProps> {
  listSubtitles = () => {
    return (this.props.subtitles || []).reduce(
      (acc: Array<JSX.Element>, subtitle: ISubtitlesMetadata, index: number) => {
        const sub = subtitle.videoSubFormat;
        const SubtitleLabel = (
          <SubtitlesLabel
            subtitle={subtitle}
            selectedSubtitleId={this.props.selectedSub}
            templateColors={this.props.templateColors}
            languageDialect={this.props.languageDialect}
            onSelected={this.props.onSubtitleSelected}
          />
        );
        const SubtitleContent = (
          <SubtitlesContent
            label={sub.label}
            webVTTUrl={sub.url}
            secondUnits={this.props.secondUnits}
            width={this.props.contentWidth}
            scrollLeft={this.props.scrollLeft}
            onElementIn={this.props.onElementIn}
            onElementOut={this.props.onElementOut}
            onSegmentClicked={this.props.onSegmentClicked}
          />
        );
        const row = <TimelineRow key={index} labelElement={SubtitleLabel} contentElement={SubtitleContent} />;
        return [...acc, row];
      },
      []
    );
  };

  placeholderField = (content: string = '') => {
    return (
      <TimelineRow
        labelElement={<Tt5 content="Subtitles" className="subtitleslayer-container_label aligned" />}
        contentElement={<TmpElement text={content} />}
      />
    );
  };

  getContent = () => {
    const isEmpty = !this.props.subtitles;
    if (isEmpty) {
      return this.placeholderField('No subtitles to list for this asset');
    } else {
      return (
        <>
          {this.placeholderField()}
          {this.listSubtitles()}
        </>
      );
    }
  };

  render() {
    return <div className="subtitleslayer-container">{this.getContent()}</div>;
  }
}
