import * as React from 'react';
import {Icon} from 'tt-components/src/Icon';
import {Dropdown} from 'tt-components/src/Dropdown';
import {TacometrIcon} from '../../assets/Icons/Tacometr';
import {CustomRadioButton} from '../CustomRadio';
import {defaultMenuControlBarStyles, DropdownContent} from '../DropdownContent';
import {calculateDropdownStyles} from '../../utils/utils';

import {ITemplateColors} from '../../../@types/templateColors';
import {PopoverTailIcon} from '../../assets/Icons/PopoverTail';

export const playerPlayBackRateOptions = [
  {id: 0.25, name: '1/4 x'},
  {id: 0.5, name: '1/2 x'},
  {id: 1, name: 'Normal'},
  {id: 1.5, name: '1.5 x'},
  {id: 2, name: '2 x'},
  {id: 4, name: '4 x'}
];

export const forwardAndRewindRateOptions = [
  {id: 0.1, name: '2 x'},
  {id: 0.125, name: '4 x'},
  {id: 0.16, name: '6 x'},
  {id: 0.33, name: '8 x'},
  {id: 0.66, name: '16 x'},
  {id: 1.33, name: '32 x'}
];

export interface ISpeedRateProps {
  playbackRate?: number;
  forwardAndRewindRate?: number;
  onPlaybackRateChange?: (rate: number) => void;
  onForwardAndRewindRateChange?: (rate: number) => void;
  templateColors: ITemplateColors;
  isControlBarShown: boolean;
  showingDropdownTimeout: number;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  disabled?: boolean;
  isOpen: boolean; // TODO: Implement approach to manually update popup visibility state
  onShowPlayerSpeedChange: (isOpen: boolean) => void;
}

export class SpeedRate extends React.PureComponent<ISpeedRateProps, {}> {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.playbackRate !== prevState.playbackRate ||
      nextProps.forwardAndRewindRate !== prevState.forwardAndRewindRate
    ) {
      return {
        playbackRate: nextProps.playbackRate,
        forwardAndRewindRate: nextProps.forwardAndRewindRate
      };
    }
    return null;
  }

  speedRateDropdownButton: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      playbackRate: '',
      forwardAndRewindRate: ''
    };
  }

  componentDidMount(): void {
    this.setState({
      playbackRate: this.props.playbackRate,
      forwardAndRewindSpeedRate: this.props.forwardAndRewindRate
    });
  }

  getSpeedRateMenuContent = () => {
    const {playbackRate, forwardAndRewindRate, onPlaybackRateChange, onForwardAndRewindRateChange} = this.props;
    const {main, tooltipBackground} = this.props.templateColors;
    const content = (
      <div className="op-speed-rate-dropdown">
        <div className="op_speed-rate-dropdown_title" style={{color: main}}>
          Speed
        </div>
        <div className="op_speed-rate-dropdown_speed-rate-options">
          <div className="op_speed-rate-dropdown_speed-rate-options_column">
            <div className="op_speed-rate-dropdown_speed-rate-options_column-title">Playback</div>
            <ul>
              {playerPlayBackRateOptions.map((item, index) => (
                <li key={index}>
                  <CustomRadioButton
                    key={index}
                    index={index}
                    id={`playbackRate${item.id}`}
                    value={item.id}
                    text={item.name}
                    selectedValue={playbackRate}
                    onChange={e => onPlaybackRateChange(item.id)}
                    templateColors={this.props.templateColors}
                    isUseDefaultColorForNotSelected={true}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="speed-rate-dropdown_delimiter" />
          <div className="op_speed-rate-dropdown_speed-rate-options_column">
            <div className="op_speed-rate-dropdown_speed-rate-options_column-title">Fast Fwd/Rew</div>
            <ul>
              {forwardAndRewindRateOptions.map((item, index) => (
                <li key={index}>
                  <CustomRadioButton
                    key={index}
                    index={index}
                    id={`forwardAndRewindRate${item.id}`}
                    value={item.id}
                    text={item.name}
                    selectedValue={forwardAndRewindRate}
                    onChange={e => onForwardAndRewindRateChange(item.id)}
                    templateColors={this.props.templateColors}
                    isUseDefaultColorForNotSelected={true}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );

    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent content={content} color={tooltipBackground} />
        <Icon icon={PopoverTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  onSpeedRateOpen = (styles: React.CSSProperties) => {
    return calculateDropdownStyles(styles, this.props.isFullScreen, this.speedRateDropdownButton);
  };

  render() {
    const {
      isControlBarShown,
      isOpen,
      showingDropdownTimeout,
      videoPlayerContainer,
      isFullScreen,
      onShowPlayerSpeedChange,
      closestBodyElement
    } = this.props;
    const {main} = this.props.templateColors;

    return (
      <Dropdown
        disabled={this.props.disabled}
        isOpen={isOpen}
        elemDiff={true}
        onCloseHotKey={onShowPlayerSpeedChange}
        title="Player playback rate"
        elemId="playbackId"
        buttonComponent={() => (
          <div className="speed-rate-dropdown-icon" id="playbackId" ref={node => (this.speedRateDropdownButton = node)}>
            <Icon icon={TacometrIcon} color={main} size="25px" />
          </div>
        )}
        content={this.getSpeedRateMenuContent()}
        className={
          'ttc-control-bar-dropdown speed-rate-dropdown ' + (isControlBarShown ? '' : 'op_controls-bar_hidden')
        }
        openAbove={true}
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={isFullScreen ? videoPlayerContainer : closestBodyElement}
        onOpen={this.onSpeedRateOpen}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  width: 225,
  padding: 5,
  borderWidth: 0,
  marginLeft: 0,
  boxSizing: 'content-box'
};
