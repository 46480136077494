import {Action, handleActions} from 'redux-actions';
import {ITimelineState} from '../interfaces/ITimelineState';
import {UPDATE_LAYER_VISIBILITY, UPDATE_TIMELINE_VIEW} from '../actions/timeline';

export const DEFAULT_TIMELINE_STATE: ITimelineState = {
  layersVisisbility: {
    showImageThumbnailsLayer: true,
    showProgramTimingsLayer: true,
    showMarkupsLayer: true,
    showCommentsLayer: false,
    showAudioLayer: true,
    showSubtitlesLayer: true
  },
  showTimeline: false
};

export const timeline = handleActions<ITimelineState, any>(
  {
    [UPDATE_LAYER_VISIBILITY]: (state: ITimelineState, action: Action<UPDATE_LAYER_VISIBILITY>): ITimelineState => {
      return {
        ...state,
        layersVisisbility: {
          ...state.layersVisisbility,
          ...action.payload
        }
      };
    },
    [UPDATE_TIMELINE_VIEW]: (state: ITimelineState, action: Action<UPDATE_TIMELINE_VIEW>): ITimelineState => {
      return {
        ...state,
        showTimeline: action.payload
      };
    }
  },
  DEFAULT_TIMELINE_STATE
);
