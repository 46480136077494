import * as React from 'react';
import {ConfigButton} from './components/ConfigButton';
import {ConfigRow} from './components/ConfigRow';
import {ChannelRow} from './components/ChannelRow';
import {ConfigGroup} from './components/ConfigGroup';
import {IAudioChannelConfiguration, IAudioChannel} from '../../../../../../../@types/audioChannelConfiguration';
import {
  genUniqueIdentifier,
  parseCustomAudioChannel,
  areChannelsConfigured,
  areConfigurationsProperlyConfigured,
  deepCopy,
  clearProps
} from '../../../../utils/helpers';
import CustomButton from '../../../../../../components/CustomButton';
import {IResponse} from '../../../../../../../@types/response';
import {IEnum} from '../../../../../../../@types/enum';

interface IAudioMappingProps {
  audioMetadata: Array<IAudioChannelConfiguration>;

  channelConfigEnums: Array<IEnum>;
  channelMapEnums: Array<IEnum>;
  languageEnums: Array<IEnum>;
  channelConfigTypeEnums: Array<IEnum>;
  processingMetadata: boolean;
  onDisableMapping: () => void;
  updateAudioConfigurations: (confs: Array<IAudioChannelConfiguration>) => IResponse;
}

interface IAudioMappingState {
  mappingAudioConfigurations: Array<IAudioChannelConfiguration>;
  mappingAudioChannels: Array<IAudioChannel>;
  isPlaying: boolean;
}

export class AudioMapping extends React.Component<IAudioMappingProps, IAudioMappingState> {
  constructor(props) {
    super(props);

    this.state = {
      mappingAudioConfigurations: [],
      mappingAudioChannels: [],
      isPlaying: false
    };
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const mappingAudioChannels = [];
    const audioMetadata = [...this.props.audioMetadata];
    audioMetadata.forEach((conf: IAudioChannelConfiguration, index) => {
      mappingAudioChannels.push(
        ...deepCopy(conf.trackDetail).map((audio: IAudioChannel) => parseCustomAudioChannel(audio, null))
      );
    });

    mappingAudioChannels.sort((channelA: IAudioChannel, channelB: IAudioChannel) => +channelA.track - +channelB.track);

    this.setState({mappingAudioChannels});
  }

  listConfigButtons() {
    const configs = this.props.channelConfigEnums.map((config: IEnum, index: number) => {
      return <ConfigButton key={index} text={config.name} value={config.value} onClick={this.onConfigurationClick} />;
    });
    return configs.length > 0 ? (
      configs
    ) : (
      <div className="metadata-audio-container_mapping_channel-configs_empty">
        No channel configurations are available
      </div>
    );
  }

  onConfigurationClick = (config: string) => {
    let iterations: number;
    switch (config) {
      case '1.0':
        iterations = 1;
        break;
      case '2.0':
        iterations = 2;
        break;
      case '5.1':
        iterations = 6;
        break;
      case '7.1':
        iterations = 8;
        break;
      default:
        iterations = 1;
    }

    this.mapChannels(config, iterations);
  };

  switchPlaying = () => {
    this.setState({isPlaying: !this.state.isPlaying});
  };

  mapChannels = (channelConfig: string, iterations: number) => {
    const newConf: IAudioChannelConfiguration = {
      description: null,
      type: null,
      audioLanguage: null,
      channelConfig,
      language: null,
      country: null,
      trackDetail: [],
      id: genUniqueIdentifier()
    };

    const areEnoughUngroupedChannels =
      this.state.mappingAudioChannels.filter((channel: IAudioChannel) => !channel.confId).length >= iterations;

    if (!areEnoughUngroupedChannels) {
      alert(`There are not enough channels for ${channelConfig} configuration.`);
      return;
    }

    const mappingAudioChannels = this.state.mappingAudioChannels.map((channel: IAudioChannel) => {
      if (!channel.confId && iterations) {
        channel.confId = newConf.id;
        iterations--;
      }
      return channel;
    });

    this.setState({
      // New created configuration should be added in the end of the stack
      mappingAudioConfigurations: [...this.state.mappingAudioConfigurations, newConf],
      mappingAudioChannels
    });
  };

  renderTitleCell = (content: string) => {
    return <span className="metadata-audio-container_mapping_channels_title-cell">{content}</span>;
  };

  renderGroupedChannels() {
    const groupedChannels = [];
    const {isPlaying} = this.state;
    this.state.mappingAudioConfigurations.forEach((config: IAudioChannelConfiguration, index) => {
      const confId = config.id;
      const channels = this.state.mappingAudioChannels.filter((channel: IAudioChannel) => channel.confId === confId);
      if (channels.length) {
        const channelsConfigured = areChannelsConfigured(channels);
        groupedChannels.push(
          <ConfigGroup
            key={index}
            audioConfig={config}
            onConfigRemove={this.removeConfig}
            languageEnums={this.props.languageEnums}
            channelConfigTypeEnums={this.props.channelConfigTypeEnums}
            onConfigUpdate={this.updateConfig}
            isPlaying={isPlaying}
            switchPlaying={this.switchPlaying}
            channelsConfigured={channelsConfigured}
          >
            {this.renderChannels(channels, true, isPlaying)}
          </ConfigGroup>
        );
      }
    });
    return groupedChannels;
  }

  updateConfig = (confId: string, updateObject: {[x: string]: string}) => {
    const mappingAudioConfigurations = this.state.mappingAudioConfigurations.map(
      (config: IAudioChannelConfiguration) => {
        if (config.id === confId) {
          config = {...config, ...updateObject};
        }
        return config;
      }
    );
    this.setState({mappingAudioConfigurations});
  };

  removeConfig = (configId: string) => {
    const index = this.state.mappingAudioConfigurations.findIndex(
      (conf: IAudioChannelConfiguration) => conf.id === configId
    );
    // Check if the requested config is the the last one added in the stack
    if (index === this.state.mappingAudioConfigurations.length - 1) {
      // Update stack with configuration records
      const mappingAudioConfigurations = this.state.mappingAudioConfigurations;
      mappingAudioConfigurations.pop();
      // Update stack with audio channels records
      const mappingAudioChannels = this.state.mappingAudioChannels.map((audio: IAudioChannel) => {
        if (audio.confId === configId) {
          audio.confId = null;
        }
        return audio;
      });
      this.setState({mappingAudioConfigurations, mappingAudioChannels});
      return;
    }
    alert('Last added configuration should be the first to be removed');
  };

  renderChannels(data: Array<IAudioChannel>, enableEdit: boolean = false, isPlaying: boolean) {
    const optionalProps = enableEdit ? {enableEdit, onChannelUpdate: this.onChannelUpdate} : {};
    return data.map((channel: IAudioChannel, index) => {
      return (
        <ChannelRow
          key={index}
          enableEdit={enableEdit}
          channel={channel}
          channelMapEnums={this.props.channelMapEnums}
          isPlaying={isPlaying}
          {...optionalProps}
        />
      );
    });
  }

  onChannelUpdate = (track: string, updateObject: {[x: string]: string}) => {
    const mappingAudioChannels = this.state.mappingAudioChannels.map((channel: IAudioChannel) => {
      if (channel.track === track) {
        channel = {...channel, ...updateObject};
      }
      return channel;
    });
    this.setState({mappingAudioChannels});
  };

  renderUngroupedChannels() {
    const ungroupedChannels = this.state.mappingAudioChannels.filter((channel: IAudioChannel) => !channel.confId);
    return this.renderChannels(ungroupedChannels, false, false);
  }

  areChannelAllConfigured() {
    // NOTE: channels will be considered configured properly if all required fields
    // are provided and all channels grouped
    const hasUngroupedChannels = this.state.mappingAudioChannels.some((audio: IAudioChannel) => !audio.confId);
    return !hasUngroupedChannels
      ? areConfigurationsProperlyConfigured(this.state.mappingAudioConfigurations, this.state.mappingAudioChannels)
      : false;
  }

  saveAudioMetadata = async () => {
    const finalConfigurations = deepCopy(this.state.mappingAudioConfigurations).map(
      (conf: IAudioChannelConfiguration) => {
        const channels = deepCopy(this.state.mappingAudioChannels)
          .filter((audio: IAudioChannel) => audio.confId === conf.id)
          .map((audio: IAudioChannel) => {
            const updatedAudio = {...audio};
            return {...clearProps(updatedAudio, ['confId', 'streamInFile'])} as IAudioChannel;
          });
        return {
          ...clearProps(conf, ['id', 'assetId', 'assetStatus']),
          trackDetail: [...channels]
        } as IAudioChannelConfiguration;
      }
    );
    const updateResponse = (await this.props.updateAudioConfigurations(finalConfigurations)) as IResponse;
    if (updateResponse.success) {
      this.props.onDisableMapping();
    }
  };

  render() {
    return (
      <div className="metadata-audio-container_mapping">
        {this.props.processingMetadata && (
          <div className="metadata-audio-container_mapping_processing-mask">Processing data...</div>
        )}
        <div className="metadata-audio-container_mapping_title">
          <div className="metadata-audio-container_mapping_title_text">Regroup Audio Channels</div>
          <div className="metadata-audio-container_mapping_title_icon">
            <span className="metadata-audio-container_mapping_title_icon_close" onClick={this.props.onDisableMapping}>
              &times;
            </span>
          </div>
        </div>
        <div className="metadata-audio-container_mapping_channels_list">{this.renderGroupedChannels()}</div>
        <div className="metadata-audio-container_mapping_channel-configs">{this.listConfigButtons()}</div>
        <div className="metadata-audio-container_mapping_channels">
          <div className="metadata-audio-container_mapping_channels_wrapper">
            <ConfigRow
              cellX2Content={this.renderTitleCell('Channel')}
              cellX4Content={this.renderTitleCell('Assignment')}
            />
            <div className="metadata-audio-container_mapping_channels_list">{this.renderUngroupedChannels()}</div>
            <div className="metadata-audio-container_mapping_channels_actions">
              <CustomButton className="cancel-button not-hover" label="CANCEL" onClick={this.props.onDisableMapping} />
              <CustomButton
                className="save-button not-hover"
                label="DONE"
                onClick={this.saveAudioMetadata}
                disabled={!this.areChannelAllConfigured()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
