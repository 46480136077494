import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const CbiIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      viewBox="0 0 56 57"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1417.000000, -1761.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-location" transform="translate(995.000000, 7.000000)">
                  <g id="Shape">
                    <path
                      d="M48.6188311,0.384846411 L3.14273639,21.3739815 C-2.1040005,23.8228962
                    -0.355088204,31.5185478 5.2416499,31.5185478 L24.4818727,31.5185478
                    L24.4818727,50.7587706 C24.4818727,56.3555087 32.1775243,58.1055147
                    34.626439,52.8576841 L55.6155741,7.38158935 C57.3644864,3.18266858
                    52.8166581,-1.36515964 48.6188311,0.384846411 Z"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
