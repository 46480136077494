export interface ISmpteMetadata {
  frameRate: number;
  dropFrame: boolean;
  // NOTE: For some case when we have recordings with 29.97/59.94 fps we will have slower
  // timecode compared with running playback. We can handle these scenarios by using
  // Drop Frame Timeocode which will drop 2/4 frame numbers (not actual frames) each
  // minute, but not the 10th minute
  framesDroppedEachMinute: number;
  adjustmentFactor: number;
}

export class Smpte {
  static parseMetadata = (metadata: Partial<ISmpteMetadata>) => {
    const frameRate = metadata.frameRate || 24;
    const dropFrame = metadata.dropFrame || false;
    const adjustmentFactor = metadata.adjustmentFactor || Math.ceil(frameRate) / frameRate;
    let framesDroppedEachMinute = 0;
    // NOTE: The possible list of non-integer frame rates provided from ATLAS is as below:
    // 1- 23.976 - 24000 / 1001
    // 2- 29.97 - 30000 / 1001 (Non-Drop / Drop) [2 dropped frames each minute]
    // 3- 59.94 - 60000 / 1001 (Non-Drop / Drop) [4 dropped frames each minute]
    if (dropFrame) {
      framesDroppedEachMinute = frameRate === 29.97 ? 2 : frameRate === 59.94 ? 4 : 0;
    }
    return {
      frameRate,
      dropFrame,
      framesDroppedEachMinute,
      adjustmentFactor
    };
  };

  static validateTimecode = (timecode: string, frameRate: number) => {
    // tslint:disable-next-line
    const timecodeRegExp = new RegExp(
      /(^(?:(?:[0-1][0-9]|[0-2][0-3])[:|;|,|.|\-|\+])(?:[0-5][0-9][:|;|,|.|\-|\+]){2}(?:[0-6][0-9])$)/
    );
    if (!timecodeRegExp.test(timecode)) {
      console.warn(`Time Code doesn't seem to match pattern HH:MM:SS:FF => ${timecode}`);
      return false;
    }
    if (Number(timecode.split(/[:|;|,|.|\-|\+]/)[3]) >= frameRate) {
      console.warn(`Frame Rate part is greater than the provided value of ${frameRate}`);
      return false;
    }
    return true;
  };

  static padNum = (numb: number, isMillisecond: boolean = false): string => {
    const paddedNum = [numb];
    if (numb < 100 && isMillisecond) {
      paddedNum.unshift(0);
    }
    if (numb < 10) {
      paddedNum.unshift(0);
    }
    return paddedNum.join('');
  };

  static fitIntoRange = (toFit: number, range: number) => {
    let overflow = 0;
    if (toFit < 0) {
      while (toFit < 0) {
        overflow--;
        toFit += range;
      }
    } else if (toFit >= range) {
      while (toFit >= range) {
        overflow++;
        toFit -= range;
      }
    }
    return [toFit, overflow];
  };

  static fromTime = (timestamp: number, metadata: Partial<ISmpteMetadata>): Smpte => {
    const parsedMetadata = Smpte.parseMetadata(metadata);
    let timeVal = timestamp;
    const smpte = new Smpte(null, parsedMetadata);
    smpte.hours = Math.floor(timeVal / 3600);
    timeVal -= smpte.hours * 3600;
    smpte.minutes = Math.floor(timeVal / 60);
    timeVal -= smpte.minutes * 60;
    smpte.seconds = Math.floor(timeVal);
    timeVal -= smpte.seconds;
    smpte.frames = Math.floor(timeVal * parsedMetadata.frameRate);
    return smpte;
  };

  static fromTimeToFrameNumber = (timestampMilliseconds: number, metadata: Partial<ISmpteMetadata>): Smpte => {
    const parsedMetadata = Smpte.parseMetadata(metadata);
    const frameNumber = timestampMilliseconds * parsedMetadata.frameRate;
    const smpte = new Smpte(null, parsedMetadata);
    smpte.hours = Math.trunc((frameNumber / (parsedMetadata.frameRate * 3600)) % 24);
    smpte.minutes = Math.trunc((frameNumber / (parsedMetadata.frameRate * 60)) % 60);
    smpte.seconds = Math.trunc((frameNumber / parsedMetadata.frameRate) % 60);
    smpte.frames = Math.trunc(frameNumber % parsedMetadata.frameRate);
    return smpte;
  };

  static fromTimeWithAdjustments = (
    timestamp: number,
    metadata: Partial<ISmpteMetadata> = {},
    doWithFrameCount: boolean = false
  ) => {
    const parsedMetadata = Smpte.parseMetadata(metadata);
    const time = timestamp / parsedMetadata.adjustmentFactor;
    let smpte = Smpte.fromTime(time, parsedMetadata);
    if (doWithFrameCount) {
      smpte = Smpte.fromTimeToFrameNumber(time, parsedMetadata);
    }
    if (parsedMetadata.framesDroppedEachMinute > 0) {
      let numMinutesWithDroppedFrames = smpte.minutes + smpte.hours * 60;
      // no frames dropped at every 10 minutes
      numMinutesWithDroppedFrames -= Math.floor(numMinutesWithDroppedFrames / 10);
      let framesToAdd = numMinutesWithDroppedFrames * parsedMetadata.framesDroppedEachMinute;
      let minutesBefore = smpte.minutes;
      smpte.addFrame(framesToAdd, false);
      if (smpte.minutes % 10 !== 0 && minutesBefore !== smpte.minutes) {
        smpte.addFrame(parsedMetadata.framesDroppedEachMinute, false);
      }
    }
    return smpte;
  };

  public hours: number;
  public minutes: number;
  public seconds: number;
  public frames: number;

  // tslint:disable-next-line
  private _metadata: ISmpteMetadata;

  get metadata() {
    return this._metadata;
  }

  constructor(timeVal: string | number, metadata: Partial<ISmpteMetadata> = {}) {
    this._metadata = Smpte.parseMetadata(metadata);
    this.parseTimeVal(timeVal);
  }

  public toString = (delimiter: string = ':') => {
    return `${Smpte.padNum(this.hours)}:${Smpte.padNum(this.minutes)}:${Smpte.padNum(
      this.seconds
    )}${delimiter}${Smpte.padNum(this.frames)}`;
  };

  public toFrames = (): number => {
    return Math.floor(this.toAdjustedTime() * this.metadata.frameRate);
  };

  public addFrame = (framesToAdd, fixFrameHoles) => {
    if (fixFrameHoles === void 0) {
      fixFrameHoles = true;
    }
    this.frames += framesToAdd;
    const range = Smpte.fitIntoRange(this.frames, Math.ceil(this.metadata.frameRate));
    this.frames = range[0];
    const overflow = range[1];
    if (overflow !== 0) {
      this.addSeconds(overflow);
    }
    // make sure we dont step into a frame hole
    if (fixFrameHoles && this.metadata.framesDroppedEachMinute > 0 && this.minutes % 10 !== 0) {
      if (framesToAdd > 0 && this.seconds === 0) {
        this.addFrame(this.metadata.framesDroppedEachMinute, false);
      }
    }
  };

  public addSeconds = (secondsToAdd: number) => {
    this.seconds += secondsToAdd;
    const range = Smpte.fitIntoRange(this.seconds, 60);
    this.seconds = range[0];
    const overflow = range[1];
    if (overflow !== 0) {
      this.addMinute(overflow);
    }
  };

  public addMinute = (minutesToAdd: number) => {
    this.minutes += minutesToAdd;
    const range = Smpte.fitIntoRange(this.minutes, 60);
    this.minutes = range[0];
    const overflow = range[1];
    if (overflow !== 0) {
      this.addHour(overflow);
    }
  };

  public addHour = (hoursToAdd: number) => {
    this.hours += hoursToAdd;
    if (this.hours < 0) {
      console.log('Cannot go further back');
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.frames = 0;
    }
  };

  public toTime = () => {
    let timeInSeconds = this.hours * 3600 + this.minutes * 60 + this.seconds;
    // convert frame number to time and add it
    timeInSeconds += this.frames / this.metadata.frameRate;
    return timeInSeconds;
  };

  public toAdjustedTime = () => {
    // take dropped frames around every full minute (except for every 10minutes) into account
    if (this.metadata.framesDroppedEachMinute > 0) {
      let totalMinutes = this.hours * 60 + this.minutes;
      let framesToAdd = totalMinutes - Math.floor(totalMinutes / 10);
      framesToAdd *= this.metadata.framesDroppedEachMinute;
      this.addFrame(-framesToAdd, false);
    }
    let targetTime = this.toTime() * this.metadata.adjustmentFactor;
    targetTime += 1 / this.metadata.frameRate / 2 / this.metadata.adjustmentFactor;
    targetTime = Math.floor(targetTime * 1000) / 1000;
    return targetTime;
  };

  private parseTimeVal = (timeVal: string | number) => {
    if (timeVal && isFinite(timeVal as number)) {
      // Parse time if provided as tmestamp
      let timeInSeconds = timeVal as number;
      this.hours = Math.floor(timeInSeconds / 3600);
      timeInSeconds -= this.hours * 3600;
      this.minutes = Math.floor(timeInSeconds / 60);
      timeInSeconds -= this.minutes * 60;
      this.seconds = Math.floor(timeInSeconds);
      timeInSeconds -= this.seconds;
      this.frames = Math.floor(timeInSeconds * this._metadata.frameRate);
    } else if (timeVal) {
      Smpte.validateTimecode(timeVal as string, this._metadata.frameRate);
      // Parse time if provided as SMPTE timecode
      const tokens = (timeVal as string).split(/[:|;|,|.|\-|\+]/);
      this.hours = typeof tokens[0] !== 'undefined' ? (isNaN(Number(tokens[0])) ? 0 : Number(tokens[0])) : 0;
      this.minutes = typeof tokens[1] !== 'undefined' ? (isNaN(Number(tokens[1])) ? 0 : Number(tokens[1])) : 0;
      this.seconds = typeof tokens[2] !== 'undefined' ? (isNaN(Number(tokens[2])) ? 0 : Number(tokens[2])) : 0;
      this.frames = typeof tokens[3] !== 'undefined' ? (isNaN(Number(tokens[3])) ? 0 : Number(tokens[3])) : 0;
    } else {
      // Default parsing for not valid provided time
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.frames = 0;
    }
  };
}
