import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';

import {SquarePlusIcon} from '../../assets/Icons/SquarePlus';
import {SquareMinusIcon} from '../../assets/Icons/SquareMinus';

interface ICollapseConfigProps {
  label: string;
  collapsed?: boolean;
}

interface ICollapseConfigState {
  collapsed: boolean;
}

export class CollapseConfigs extends React.Component<ICollapseConfigProps, ICollapseConfigState> {
  static defaultProps = {
    collapsed: false
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.collapsed
    };
  }

  componentDidUpdate(prevProps: ICollapseConfigProps) {
    if (prevProps.collapsed !== this.props.collapsed) {
      this.setState({collapsed: !!this.props.collapsed});
    }
  }

  onToggle = () => {
    this.setState({collapsed: !this.state.collapsed});
  };

  render() {
    return (
      <div className="collapse-config-container">
        <div className={`collapse-config-container_header${this.state.collapsed ? ` opened` : ``}`}>
          <div className="collapse-config-container_header_icon" onClick={this.onToggle}>
            <Icon icon={this.state.collapsed ? SquareMinusIcon : SquarePlusIcon} size="15px" color="#1F72F2" />
          </div>
          <div className="collapse-config-container_header_label">{this.props.label || '<MISSING_LABEL>'}</div>
        </div>
        {this.state.collapsed && <div className="collapse-config-container_body">{this.props.children}</div>}
      </div>
    );
  }
}
