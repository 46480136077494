import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const PlayheadIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      x="0px"
      y="0px"
      width="13px"
      height="17px"
      viewBox="0 0 31 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="One-Player-in-Compliance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="One-Player" transform="translate(-1746.000000, -1901.000000)" fill="#1F72F2" fillRule="nonzero">
          <g id="Extreme-Timeline" transform="translate(347.000000, 1740.000000)">
            <g id="Playhead" transform="translate(1399.000000, 161.000000)">
              <g id="ic-playhead">
                <path
                  d="M13.9070521,42.1324311 C2.17726563,24.4420507 0,22.6264769 0,16.1249961 C0,7.2193791 6.9395599,0 
                  15.5,0 C24.0604401,0 31,7.2193791 31,16.1249961 C31,22.6264769 28.8227344,24.4420507 
                  17.0929479,42.1324311 C16.3231953,43.2892316 14.676724,43.2891476 13.9070521,42.1324311 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
