import * as React from 'react';
import {Dropdown, IDropdownOption} from '../../../../../../components/Dropdown';

interface IOrderDropdownProps {
  selected: string;
  onOrderBySelected: (value: string) => void;
  closestBody?: HTMLElement;
}

export class OrderDropdown extends React.PureComponent<IOrderDropdownProps> {
  readonly options: Array<IDropdownOption> = [{label: 'Timecode', value: 'timecode'}];

  render() {
    return (
      <Dropdown
        label="Events Order By"
        disableMatchWidth
        borderless
        width={140}
        options={this.options}
        selected={this.props.selected}
        onSelected={this.props.onOrderBySelected}
        paddingLeft={0}
        borderWidth={0}
        portalNode={this.props.closestBody}
      />
    );
  }
}
