import {IStorageBase} from './IStorageBase';

export class BaseSS<T> implements IStorageBase {
  storageKey: string;

  constructor(storageKey) {
    this.storageKey = storageKey;
  }

  get() {
    const data = sessionStorage.getItem(this.storageKey);
    return data ? (JSON.parse(data) as T) : null;
  }

  set(data: T) {
    sessionStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  remove() {
    sessionStorage.removeItem(this.storageKey);
  }
}
