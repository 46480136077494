import * as React from 'react';
import {EditIcon} from '../../assets/Icons/EditIcon';
import {DeleteThinIcon} from '../../assets/Icons/DeleteThinIcon';
import {AddCommentIcon} from '../../assets/Icons/AddCommentIcon';
import {TimeInIcon} from '../../assets/Icons/TimeInIcon';
import {TimeOutIcon} from '../../assets/Icons/TimeOutIcon';
import {TimePicker} from 'tt-components';
import {IComment, ICommentsTab, ICreateComment} from '../../interfaces/ICommentsTab';
import {IFrameRate} from 'tt-components/src/frameRate';
import {IVideoFragment} from '../../../../state/IVideoState';
import {IDisplayMediaTimeFormat} from '../../../../../@types/displayMediaTimeFormat';
import {IParsedVTT} from '../../../../../@types/parsedVtt';
import {PlayIcon} from '../../../../assets/Icons/Play';
import {formatCommentTime} from '../../utils/helpers';

export interface ICommentsProps {
  commentsTab: ICommentsTab;
  deleteComment: (id: number) => void;
  addComment: (comment: ICreateComment) => void;
  editComment: (id: number, text: string) => void;
  addReply: (commentId: number, text: string) => void;
  setVideoFragmentInTimeByCurrentTime: () => void;
  setVideoFragmentOutTimeByCurrentTime: () => void;
  setVideoFragmentInTime: (timeIn) => void;
  setVideoFragmentOutTime: (timeOut) => void;
  frameRate: IFrameRate;
  currentVideoFragment: IVideoFragment;
  displayMediaTimeFormat: IDisplayMediaTimeFormat;
  formattingSmpte: (currentTime: number) => string;
  goToLocation: (time: number) => void;
  jsonVTT: IParsedVTT[];
  showingDropdownTimeout: number;
  closestBody: HTMLElement;
}

interface ICommentsState {
  commentIdEdit: number;
  commentIdCreateReply: number;
  currentComment: string;
  addCommentTextarea: string;
}

export class Comments extends React.Component<ICommentsProps, ICommentsState> {
  public addCommentEl: HTMLTextAreaElement;
  public currentEditingCommentEl: HTMLTextAreaElement;
  public currentAddReplyEl: HTMLTextAreaElement;
  public cancelEditing: (e) => void;
  public editingCommentRef: (x) => void;

  constructor(props) {
    super(props);

    this.state = {
      commentIdEdit: null,
      commentIdCreateReply: null,
      currentComment: '',
      addCommentTextarea: ''
    };
  }

  onEditIconClick(id: number, comment: string) {
    const {commentIdEdit, currentComment} = this.state;
    const {editComment} = this.props;
    let newCommentIdEdit = id !== commentIdEdit ? id : null;

    if (!newCommentIdEdit) {
      editComment(id, currentComment);
    } else {
      this.setState({
        currentComment: comment
      });
    }

    this.setState({
      commentIdEdit: newCommentIdEdit
    });
  }

  onCreateReplyBtnClick(id: number) {
    this.setState({
      commentIdCreateReply: id
    });
  }

  onCancelReplyBtnClick() {
    this.setState({
      commentIdCreateReply: null
    });
  }

  onSaveReplyBtnClick(commentId) {
    const {addReply} = this.props;
    let text = this.currentAddReplyEl.value;
    if (text && text.trim().length > 0) {
      addReply(commentId, text.trim());
      this.setState({
        commentIdCreateReply: null
      });
    }
  }

  handleCommentChange(event) {
    this.setState({currentComment: event.target.value});
  }

  renderReplies(replies: IComment[]) {
    const {deleteComment} = this.props;
    const {commentIdEdit, currentComment} = this.state;
    return replies.map((reply, index) => {
      return (
        <div className="replies-container_reply" key={index}>
          {commentIdEdit !== reply.id ? (
            <div className="replies-container_reply-regular">
              <div className="replies-container_reply_header">
                <div className="replies-container_reply_header_post-time">
                  {`${reply.isCurrentUser ? 'You' : reply.createdBy.displayName} replied on ${formatCommentTime(
                    reply.createdAt
                  )}`}
                </div>
                {reply.isCurrentUser && reply.id !== null ? (
                  <div className="replies-container_reply_header_action-icons">
                    <div
                      className="replies-container_reply_header_edit-icon"
                      onClick={() => {
                        this.onEditIconClick(reply.id, reply.comment);
                      }}
                    >
                      <EditIcon color="#5C5C5C" size="15px" />
                    </div>
                    <div
                      className="replies-container_reply_header_delete-icon"
                      onClick={() => {
                        deleteComment(reply.id);
                      }}
                    >
                      <DeleteThinIcon color="#5C5C5C" size="12px" />
                    </div>
                  </div>
                ) : null}
              </div>
              <pre className="replies-container_reply_body">{reply.comment}</pre>
            </div>
          ) : (
            <div className="replies-container_reply_editing">
              <textarea
                className="replies-container_reply_body_editing"
                onChange={this.handleCommentChange.bind(this)}
                ref={this.editingCommentRef}
                value={currentComment}
                onKeyDown={event => {
                  event.stopPropagation();
                }}
              />
              <div className="replies-container_reply_body_action-icons">
                <div
                  className="replies-container_reply_body_edit-icon"
                  onClick={() => {
                    this.onEditIconClick(reply.id, reply.comment);
                  }}
                >
                  <EditIcon color="#5C5C5C" size="15px" />
                </div>
                <div
                  className="replies-container_reply_body_delete-icon"
                  onClick={() => {
                    deleteComment(reply.id);
                  }}
                >
                  <DeleteThinIcon color="#5C5C5C" size="12px" />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  renderComments() {
    const {commentsTab, deleteComment, formattingSmpte} = this.props;
    const {commentIdEdit, currentComment, commentIdCreateReply} = this.state;
    const comments = commentsTab.comments;
    const addReplyRef = x => (this.currentAddReplyEl = x);

    return comments.map((comment: IComment, index) => {
      if (comment.parent === null) {
        let replies = comments.filter(reply => {
          return reply.parent === comment.id && comment.id !== null;
        });
        return (
          <div className="op_comments-container_comment" key={index}>
            <div className="comments-container_comment_header">
              <div className="comments-container_comment_header_info-container">
                <div
                  className="comments-container_comment_header_avatar"
                  style={{backgroundImage: `url(${comment.createdBy.avatar})`}}
                />
                <div className="comments-container_comment_header_name-and-post-time">
                  <div className="comments-container_comment_header_author">{comment.createdBy.displayName}</div>
                  <div className="comments-container_comment_header_post-time">
                    {formatCommentTime(comment.createdAt)}
                  </div>
                </div>
                {comment.inTime && comment.outTime ? (
                  <div className="comments-container_comment_header_in-time-and-out-time">
                    <div className="comments-container_comment_header_in-time">{formattingSmpte(comment.inTime)}</div>
                    {comment.inTime !== comment.outTime ? (
                      <>
                        <div className="comments-container_comment_header_time-delimiter">-</div>
                        <div className="comments-container_comment_header_out-time">
                          {formattingSmpte(comment.outTime)}
                        </div>
                      </>
                    ) : null}
                    <div
                      className="comments-container_comment_header_play-btn"
                      title="Jump to timecode"
                      onClick={() => {
                        this.onPlayBtnClick(comment.inTime);
                      }}
                    >
                      <PlayIcon style={{color: '#ffffff', width: '11px', height: '11px'}} />
                      {/*Commented out until Thumbnails API is implemented*/}
                      {/*<ThumbnailDropdown*/}
                      {/*showingDropdownTimeout={showingDropdownTimeout}*/}
                      {/*closestBodyElement={closestBody}*/}
                      {/*time={comment.inTime}*/}
                      {/*jsonVTT={jsonVTT}*/}
                      {/*/>*/}
                    </div>
                  </div>
                ) : null}
              </div>
              {comment.isCurrentUser && comment.id !== null ? (
                <div className="comments-container_comment_header_action-icons">
                  <div
                    className="comments-container_comment_header_edit-icon"
                    onClick={() => {
                      this.onEditIconClick(comment.id, comment.comment);
                    }}
                  >
                    <EditIcon color="#ffffff" size="18px" />
                  </div>
                  <div
                    className="comments-container_comment_header_delete-icon"
                    onClick={() => {
                      deleteComment(comment.id);
                      this.setState({commentIdEdit: null});
                    }}
                  >
                    <DeleteThinIcon color="#ffffff" size="15px" />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="comments-container_comment_body">
              {comment.id !== commentIdEdit || comment.id === null ? (
                <pre
                  className="comments-container_comment_text"
                  style={commentIdCreateReply === comment.id && replies.length === 0 ? {marginBottom: '7px'} : null}
                >
                  {comment.comment}
                </pre>
              ) : (
                <textarea
                  className="comments-container_comment_text_editing"
                  onChange={this.handleCommentChange.bind(this)}
                  ref={this.editingCommentRef}
                  value={currentComment}
                  onKeyDown={event => {
                    event.stopPropagation();
                  }}
                />
              )}
              {replies && replies.length > 0 ? (
                <div className="comments-container_comment_replies-container">{this.renderReplies(replies)}</div>
              ) : null}

              {comment.id === commentIdCreateReply && comment.id !== null ? (
                <textarea
                  className="replies-container_comment_add-reply"
                  ref={addReplyRef}
                  onKeyDown={event => {
                    event.stopPropagation();
                  }}
                />
              ) : null}

              {comment.id !== null ? (
                <div className="comments-container_comment_footer">
                  {comment.id === commentIdCreateReply ? (
                    <>
                      <button
                        className="comments-container_comment_footer_save-reply-btn"
                        onClick={() => {
                          this.onSaveReplyBtnClick(comment.id);
                        }}
                      >
                        Save
                      </button>
                      <button
                        className="comments-container_comment_footer_cancel-reply-btn"
                        onClick={() => {
                          this.onCancelReplyBtnClick();
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  ) : null}
                  {comment.id !== commentIdCreateReply ? (
                    <button
                      className="comments-container_comment_footer_add-reply-btn"
                      onClick={() => {
                        this.onCreateReplyBtnClick(comment.id);
                      }}
                    >
                      Reply
                    </button>
                  ) : null}
                </div>
              ) : null}
              {comment.isCurrentUser ? <div className="comments-container_comment_current-user-comment-tail" /> : null}
              {!comment.isCurrentUser ? <div className="comments-container_comment_another-user-comment-tail" /> : null}
            </div>
          </div>
        );
      }
    });
  }

  addComment() {
    const {addComment, currentVideoFragment, setVideoFragmentInTime, setVideoFragmentOutTime} = this.props;
    const {addCommentTextarea} = this.state;
    if (addCommentTextarea.trim().length > 0) {
      this.setState({addCommentTextarea: ''});
      setVideoFragmentInTime(0);
      setVideoFragmentOutTime(0);
      let commentData: ICreateComment = {
        comment: addCommentTextarea,
        inTime: currentVideoFragment.inTime,
        outTime: currentVideoFragment.outTime
      };
      addComment(commentData);
    }
  }

  onPlayBtnClick(time) {
    const {goToLocation} = this.props;
    goToLocation(time);
  }

  onTimepickerTimeInChanged(time) {
    const {setVideoFragmentInTime, setVideoFragmentOutTime, currentVideoFragment} = this.props;
    if (currentVideoFragment.outTime < time || !time) {
      setVideoFragmentOutTime(time);
    }
    setVideoFragmentInTime(time);
  }

  onTimepickerTimeOutChanged(time) {
    const {setVideoFragmentOutTime, setVideoFragmentInTime, currentVideoFragment} = this.props;
    if (currentVideoFragment.inTime > time) {
      setVideoFragmentInTime(time);
    }
    setVideoFragmentOutTime(time);
  }

  onAddCommentTextareaChange(event) {
    this.setState({addCommentTextarea: event.target.value});
  }

  componentDidMount() {
    const {closestBody} = this.props;
    this.cancelEditing = e => {
      let isEditButtonClicked =
        !!e.target.closest('.comments-container_comment_header_edit-icon') ||
        !!e.target.closest('.replies-container_reply_header_edit-icon') ||
        !!e.target.closest('.replies-container_reply_body_edit-icon');
      let isDeleteReplyButtonClicked = !!e.target.closest('.replies-container_reply_body_delete-icon');
      if (
        !isDeleteReplyButtonClicked &&
        !isEditButtonClicked &&
        this.currentEditingCommentEl &&
        this.currentEditingCommentEl !== e.target
      ) {
        this.setState({commentIdEdit: null});
      }

      let isReplyButtonClicked =
        [].includes.call(e.target.classList, 'comments-container_comment_footer_save-reply-btn') ||
        [].includes.call(e.target.classList, 'comments-container_comment_footer_cancel-reply-btn');

      if (!isReplyButtonClicked && this.currentAddReplyEl && this.currentAddReplyEl !== e.target) {
        this.setState({
          commentIdCreateReply: null
        });
      }
    };
    if (this.props.currentVideoFragment.inTime || this.props.currentVideoFragment.outTime) {
      this.addCommentEl.focus();
    }
    this.editingCommentRef = x => {
      this.currentEditingCommentEl = x;
    };

    if (closestBody) {
      closestBody.addEventListener('click', this.cancelEditing);
    }
  }

  componentWillUnmount() {
    const {closestBody, setVideoFragmentInTime, setVideoFragmentOutTime} = this.props;
    setVideoFragmentInTime(0);
    setVideoFragmentOutTime(0);
    closestBody.removeEventListener('click', this.cancelEditing);
  }

  render() {
    const addCommentRef = x => (this.addCommentEl = x);
    const {
      frameRate,
      currentVideoFragment,
      formattingSmpte,
      setVideoFragmentInTimeByCurrentTime,
      setVideoFragmentOutTimeByCurrentTime
    } = this.props;

    const {addCommentTextarea} = this.state;

    let addCommentBtnAttrs = {
      disabled: true
    };

    if (addCommentTextarea && addCommentTextarea.trim().length > 0) {
      addCommentBtnAttrs['disabled'] = false;
    }

    let timeInButtonAttrs = {
      disabled: true
    };

    if (currentVideoFragment.inTime) {
      timeInButtonAttrs['disabled'] = false;
    }

    return (
      <>
        <div className="op_comments-container">
          <div className="comments-container_top-gradient-bar" />
          <div className="op_comments-container_comments-wrapper">{this.renderComments()}</div>
          <div className="comments-container_bottom-gradient-bar" />
        </div>
        <div className="op_add-comment-container">
          <div className="add-comment-container_action-btn-wrapper">
            <div
              className="add-comment-container_action-btn time-in-btn"
              onClick={() => {
                setVideoFragmentInTimeByCurrentTime();
              }}
            >
              <div className="add-comment-container_action-btn_text">Timecode In</div> <TimeInIcon />
            </div>
            <TimePicker
              changeTime={this.onTimepickerTimeInChanged.bind(this)}
              userLastComma={false}
              time={formattingSmpte(currentVideoFragment.inTime)}
              disabled={false}
              minTime={formattingSmpte(0)}
              maxTime={null}
              frameRate={frameRate}
            />
            <div
              className="add-comment-container_action-btn time-out-btn"
              {...timeInButtonAttrs}
              onClick={() => {
                if (currentVideoFragment.inTime) {
                  setVideoFragmentOutTimeByCurrentTime();
                }
              }}
            >
              <div className="add-comment-container_action-btn_text">Timecode Out</div> <TimeOutIcon />
            </div>
            <TimePicker
              changeTime={this.onTimepickerTimeOutChanged.bind(this)}
              userLastComma={false}
              time={formattingSmpte(currentVideoFragment.outTime)}
              disabled={!currentVideoFragment.inTime}
              minTime={formattingSmpte(0)}
              maxTime={null}
              frameRate={frameRate}
            />
            <div
              className="add-comment-container_action-btn add-comment-btn"
              {...addCommentBtnAttrs}
              onClick={() => {
                this.addComment();
              }}
            >
              <div className="add-comment-container_action-btn_text">Add Comment</div> <AddCommentIcon />
            </div>
          </div>
          <textarea
            autoFocus={true}
            className="add-comment-container_comment-text"
            ref={addCommentRef}
            onKeyDown={event => {
              event.stopPropagation();
            }}
            value={addCommentTextarea}
            onChange={event => {
              this.onAddCommentTextareaChange(event);
            }}
          />
        </div>
      </>
    );
  }
}
