import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const VisibleIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '18px'}
      height={props.size || '18px'}
      viewBox="0 0 18 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-695.000000, -1103.000000)" fill={props.color || '#FFFFFF'}>
          <g transform="translate(140.000000, 1088.000000)">
            <g transform="translate(552.000000, 7.000000)">
              <g transform="translate(3.000000, 8.000000)">
                <path
                  d="M17.9794286,4.69636364 C17.9369048,4.64818182 16.91,3.51 15.3049524,2.35818182 
                  C14.3576667,1.67818182 13.4049524,1.13636364 12.4730476,0.746363636 C11.2923333,
                  0.251818182 10.1396667,0.000909090909 9.04761905,0.000909090909 C7.95557143,0.000909090909 
                  6.80290476,0.251818182 5.62219048,0.746363636 C4.69028571,1.13636364 3.73757143,1.67909091 
                  2.79028571,2.35818182 C1.1852381,3.51 0.158333333,4.64909091 0.115809524,4.69636364 
                  C-0.0389047619,4.86909091 -0.0389047619,5.13181818 0.115809524,5.30454545 C0.158333333,
                  5.35272727 1.1852381,6.49090909 2.79028571,7.64272727 C3.73757143,8.32272727 4.69028571,
                  8.86454545 5.62219048,9.25454545 C6.80290476,9.74909091 7.95557143,10 9.04761905,10 C10.1396667,
                  10 11.2923333,9.74909091 12.4730476,9.25454545 C13.4049524,8.86454545 14.3576667,8.32181818 
                  15.3049524,7.64272727 C16.91,6.49090909 17.9369048,5.35181818 17.9794286,5.30454545 C18.1341429,
                  5.13181818 18.1341429,4.86909091 17.9794286,4.69636364 Z M11.3764762,1.30727273 C12.1970952,
                  2.00090909 12.6666667,3.01 12.6666667,4.09090909 C12.6666667,6.09636364 11.0435238,7.72727273 
                  9.04761905,7.72727273 C7.05171429,7.72727273 5.42857143,6.09636364 5.42857143,4.09090909 C5.42857143,
                  3.01090909 5.89814286,2.00090909 6.7187619,1.30727273 C7.46247619,1.06363636 8.24871429,0.909090909 
                  9.04761905,0.909090909 C9.84652381,0.909090909 10.6327619,1.06363636 11.3764762,1.30727273 Z M14.763,
                  6.91454545 C13.376,7.90727273 11.2588571,9.09090909 9.04761905,9.09090909 C6.83638095,9.09090909 
                  4.7192381,7.90727273 3.3322381,6.91454545 C2.28542857,6.16545455 1.48471429,5.40727273 1.08028571,
                  5 C1.48471429,4.59272727 2.28542857,3.83545455 3.3322381,3.08545455 C3.8117619,2.74181818 4.37814286,
                  2.37636364 5.00604762,2.04454545 C4.69209524,2.67 4.52380952,3.36727273 4.52380952,4.09090909 
                  C4.52380952,6.59727273 6.55319048,8.63636364 9.04761905,8.63636364 C11.5420476,8.63636364 13.5714286,
                  6.59727273 13.5714286,4.09090909 C13.5714286,3.36727273 13.4031429,2.67 13.0891905,2.04454545 
                  C13.7170952,2.37636364 14.2834762,2.74272727 14.763,3.08545455 C15.8098095,3.83454545 16.6105238,
                  4.59272727 17.0149524,5 C16.6105238,5.40727273 15.8098095,6.16454545 14.763,6.91454545 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
