import * as React from 'react';
import {Container, Row, Column} from '../../../../../Grid';
import {
  IAssetDetails,
  IAssetDetailsReference,
  IAssetDistributor,
  IErrorLog
} from '../../../../../../../../../@types/assetDetails';
import {TextField} from '../../../../../../../../components/TextField';
import {Tt1} from '../../../../../../../../components/Typography';
import {Dropdown, IDropdownOption} from '../../../../../../../../components/Dropdown';
import {parseSize, getErrorLogByField} from '../../../../../../utils/helpers';
import {IEnum} from '../../../../../../../../../@types/enum';
import {References} from './components/References';
import {Toggle} from '../../../../../../../../components/Toggle';
import {Distributors} from './components/Distributors';
import {IOneCustomer} from '../../../../../../../../../@types/oneCustomer';
import {customersAPI} from '../../../../../../../../data';
import {IResponse} from '../../../../../../../../../@types/response';
import {IMetadataError} from '../../../../../../../../../@types/metadataErrors';

interface IAssetInfoProps {
  isAssetRegistered: boolean;
  assetDetails: IAssetDetails;
  assetStatusEnums: Array<IEnum>;
  fileWrapperEnums: Array<IEnum>;
  functionEnums: Array<IEnum>;
  contentTypeEnums: Array<IEnum>;
  formatComplianceEnums: Array<IEnum>;
  frameRateEnums: Array<IEnum>;
  referencesNameEnums: Array<IEnum>;
  referencesTypeEnums: Array<IEnum>;
  closestBody?: HTMLElement;
  disabled: boolean;
  onAssetUpdate: (field: string, value: any) => void;
  errors: Array<IMetadataError>;
  errorLogs: Array<IErrorLog>;
}

interface IAssetInfoState {
  contentOwners: Array<IDropdownOption>;
  contentProviders: Array<IDropdownOption>;
  distributors: Array<IDropdownOption>;
}

export class AssetInfo extends React.Component<IAssetInfoProps, IAssetInfoState> {
  isComponentMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      contentOwners: [],
      contentProviders: [],
      distributors: []
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  updateState = (stateObject, callback: () => void = null) => {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(stateObject, callback);
  };

  async init() {
    const contentOwnersResponse = (await customersAPI.getCustomersListAPI('content_owner')) as IResponse;
    const contentOwners = this.customersToDropdownOptions(contentOwnersResponse.data);
    this.updateState({contentOwners});

    const contentProvidersResponse = (await customersAPI.getCustomersListAPI('content_provider')) as IResponse;
    const contentProviders = this.customersToDropdownOptions(contentProvidersResponse.data);
    this.updateState({contentProviders});

    const distributorsResponse = (await customersAPI.getCustomersListAPI('distributor')) as IResponse;
    const distributors = this.customersToDropdownOptions(distributorsResponse.data, true);
    this.updateState({distributors});
  }

  enumsToDropdownOptions = (enums: Array<IEnum>) => {
    return enums
      .filter((record: IEnum) => record.isActive)
      .map((record: IEnum) => ({
        label: record.name,
        value: record.value
      }));
  };

  customersToDropdownOptions = (costumers: Array<IOneCustomer>, includeId: boolean = false) => {
    return (costumers || []).map((customer: IOneCustomer) => ({
      label: `${customer.name}${includeId ? ` (${customer.externalId})` : ``}`,
      value: customer.externalId
    }));
  };

  getAssetDetails = () => {
    return this.props.assetDetails || {};
  };

  getError = (field: string): IMetadataError => {
    const fieldNames = [];
    switch (field) {
      case 'Function':
        fieldNames.push('AssetRegistrationPatch.Function');
    }
    return this.props.errors.find((error: IMetadataError) => fieldNames.indexOf(error.fieldName) !== -1);
  };

  getFileNameRow = () => (
    <Row>
      <Column>
        <TextField label="File Name" text={this.getAssetDetails().name || 'Undefined'} />
      </Column>
    </Row>
  );

  getIdWrapperExtensionRow = () => {
    const asset = this.getAssetDetails();
    const fileWrapperList = this.enumsToDropdownOptions(this.props.fileWrapperEnums);
    return (
      <Row>
        <Column>
          <TextField label="ID" text={asset.hrId} />
        </Column>
        <Column>
          <Dropdown
            disabled={this.props.disabled}
            fixedButtonWidth
            search
            label="File Wrapper"
            options={fileWrapperList}
            selected={asset.fileWrapper}
            onSelected={(value: string) => this.props.onAssetUpdate('fileWrapper', value)}
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('fileWrapper', this.props.errorLogs)}
          />
        </Column>
        <Column>
          <div className="dropdown-ui-container">
            <TextField
              label="File Ext."
              text={`${asset.fileExt ? (asset.fileExt.startsWith('.') ? asset.fileExt : `.${asset.fileExt}`) : ''}`}
              editMode={!this.props.disabled}
              onChange={(value: string) => this.props.onAssetUpdate('fileExt', value)}
            />
          </div>
        </Column>
      </Row>
    );
  };

  getTypeSizeFunction = () => {
    const asset = this.getAssetDetails();
    const fileType = asset.videos && Array.isArray(asset.videos) && asset.videos.length ? 'Video' : 'Audio';
    const functionsList = this.enumsToDropdownOptions(this.props.functionEnums).filter((option: IDropdownOption) => {
      if (!this.props.isAssetRegistered) {
        // NOTE: In case the asset is not registered we need to allow only 'Source' and 'Proxy'
        // options to be selectable from the operator on asset curation process
        return ['Source', 'Proxy'].indexOf(option.value) !== -1;
      }
      return true;
    });
    const functionError = this.getError('Function');
    return (
      <Row>
        <Column>
          <TextField label="File Type" text={fileType} />
        </Column>
        <Column>
          <TextField label="File Size" text={parseSize(asset.size, 'g')} />
        </Column>
        <Column>
          <Dropdown
            disabled={functionError ? false : this.props.disabled || this.props.isAssetRegistered}
            label="Function"
            search
            options={functionsList}
            selected={asset.function}
            onSelected={(value: string) => this.props.onAssetUpdate('function', value)}
            portalNode={this.props.closestBody}
            error={functionError ? `! ${functionError.message}` : false}
            errorLog={getErrorLogByField('function', this.props.errorLogs)}
          />
        </Column>
      </Row>
    );
  };

  getStatusProviderOwner = () => {
    const asset = this.getAssetDetails();
    const statusList = this.enumsToDropdownOptions(this.props.assetStatusEnums);
    return (
      <Row>
        <Column>
          <Dropdown
            disabled={this.props.disabled}
            fixedButtonWidth
            search
            label="Status"
            options={statusList}
            selected={asset.status}
            onSelected={(value: string) => this.props.onAssetUpdate('status', value)}
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('status', this.props.errorLogs)}
          />
        </Column>
        <Column>
          <Dropdown
            disabled={this.props.disabled}
            fixedButtonWidth
            search
            label="Content Provider"
            options={this.state.contentProviders}
            selected={asset.contentProviderId}
            onSelected={(value: string) => this.props.onAssetUpdate('contentProviderId', value)}
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('contentProviderId', this.props.errorLogs)}
          />
        </Column>
        <Column>
          <Dropdown
            disabled={this.props.disabled}
            fixedButtonWidth
            search
            label="Content Owner"
            options={this.state.contentOwners}
            selected={asset.contentOwnerId}
            onSelected={(value: string) => this.props.onAssetUpdate('contentOwnerId', value)}
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('contentOwnerId', this.props.errorLogs)}
          />
        </Column>
      </Row>
    );
  };

  getTypeCompliance = () => {
    const asset = this.getAssetDetails();
    const contentTypeList = this.enumsToDropdownOptions(this.props.contentTypeEnums);
    const formatComplianceList = this.enumsToDropdownOptions(this.props.formatComplianceEnums);
    return (
      <Row>
        <Column>
          <Dropdown
            disabled={this.props.disabled}
            fixedButtonWidth
            search
            toLowerCaseCompare
            label="Content Type"
            options={contentTypeList}
            selected={asset.contentType}
            onSelected={(value: string) => this.props.onAssetUpdate('contentType', value)}
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('contentType', this.props.errorLogs)}
          />
        </Column>
        <Column>
          <Dropdown
            disabled={this.props.disabled}
            fixedButtonWidth
            search
            label="Format Compliance"
            options={formatComplianceList}
            selected={asset.formatCompliance}
            onSelected={(value: string) => this.props.onAssetUpdate('formatCompliance', value)}
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('formatCompliance', this.props.errorLogs)}
          />
        </Column>
        <Column />
      </Row>
    );
  };

  onReferenceUpdates = (references: Array<IAssetDetailsReference>) => {
    this.props.onAssetUpdate('references', references);
  };

  onDistributorUpdates = (distributors: Array<IAssetDistributor>) => {
    this.props.onAssetUpdate('distributors', distributors);
  };

  getReferences = () => {
    const asset = this.getAssetDetails();
    const references = asset.references || [];
    return (
      <References
        references={references}
        referencesNameEnums={this.props.referencesNameEnums}
        referencesTypeEnums={this.props.referencesTypeEnums}
        onReferenceUpdates={this.onReferenceUpdates}
        closestBody={this.props.closestBody}
        disabled={this.props.disabled}
        errors={this.props.errors}
        errorLogs={this.props.errorLogs}
      />
    );
  };

  getFrameRow = () => {
    const asset = this.getAssetDetails();
    const frameRate = asset.frameRate ? asset.frameRate.value : null;
    const isDrop = asset.frameRate ? asset.frameRate.isDrop : false;
    const frameRateList = this.enumsToDropdownOptions(this.props.frameRateEnums);
    return (
      <>
        <Row>
          <Tt1 content="Frame Rate" className="asset-info-container_frame-rate-title" />
        </Row>
        <Row>
          <Column>
            <Dropdown
              disabled={this.props.disabled}
              fixedButtonWidth
              search
              label="Value"
              options={frameRateList}
              selected={frameRate}
              onSelected={(value: string) => this.props.onAssetUpdate('frameRate', {value, isDrop})}
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('frameRate.value', this.props.errorLogs)}
            />
          </Column>
          <Column>
            <Toggle
              disabled={this.props.disabled}
              label="Drop"
              toggled={isDrop}
              onToggle={(isDrop: boolean) => this.props.onAssetUpdate('frameRate', {value: frameRate, isDrop})}
            />
          </Column>
          <Column />
        </Row>
      </>
    );
  };

  getDistributors = () => {
    const asset = this.getAssetDetails();
    return (
      <Distributors
        distributors={asset.distributors || []}
        distributorsOptions={this.state.distributors}
        onDistributorUpdates={this.onDistributorUpdates}
        closestBody={this.props.closestBody}
        disabled={this.props.disabled}
        errorLogs={this.props.errorLogs}
      />
    );
  };

  render() {
    return (
      <Container className="asset-info-container">
        {this.getFileNameRow()}
        {this.getIdWrapperExtensionRow()}
        {this.getTypeSizeFunction()}
        {this.getStatusProviderOwner()}
        {this.getTypeCompliance()}
        {this.getReferences()}
        {this.getFrameRow()}
        {this.getDistributors()}
      </Container>
    );
  }
}
