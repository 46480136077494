import * as React from 'react';
import {Icon} from 'tt-components';
import {InfoIcon} from '../../../assets/Icons/InfoIcon';
import {IShortcutsDefinitions, IShortcutType} from '../../../constants/shortcuts';
import {isMac} from '../../../utils/utils';
import {CustomRadioButton} from '../../CustomRadio';
import {ITemplateColors} from '../../../../@types/templateColors';
import {deepCopy} from '../../../modules/Tabs/utils/helpers';

export interface IKeyboardShortcutsProps {
  getShortcuts: (name: IShortcutType) => void;
  keyboardShortcutsType: IShortcutType;
  templateColors: ITemplateColors;
  setDefaultShortcuts: () => void;
  changeShortcuts: (shortcuts: Array<IShortcutsDefinitions>) => void;
  keyboardShortcuts: Array<IShortcutsDefinitions>;
}

export interface IKeyboardShortcutsState {
  keyboardShortcuts?: Array<IShortcutsDefinitions>;
  isCustomizeMode: boolean;
  isInfoOpen: boolean;
  keyIndex: number;
  keyLabel: string;
}

export class KeyboardShortcuts extends React.PureComponent<IKeyboardShortcutsProps, IKeyboardShortcutsState> {
  constructor(props) {
    super(props);

    this.state = {
      keyboardShortcuts: this.props.keyboardShortcuts,
      isCustomizeMode: false,
      isInfoOpen: false,
      keyIndex: 0,
      keyLabel: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.keyboardShortcuts !== this.props.keyboardShortcuts) {
      this.setState({
        keyboardShortcuts: this.props.keyboardShortcuts
      });
    }
    if (prevProps.keyboardShortcutsType !== this.props.keyboardShortcutsType) {
      this.setState({
        isCustomizeMode: false
      });
    }
  }

  onCustomizeChange = () => {
    this.setState({
      isCustomizeMode: !this.state.isCustomizeMode
    });
  };

  shortcutValidation = newShortcutKey => {
    const {keyboardShortcuts} = this.state;
    let isValid = true;
    keyboardShortcuts.map(el => {
      if (el.shortcutKey === newShortcutKey) {
        isValid = false;
      }
    });
    return isValid;
  };

  infoBtnToogle = () => {
    const {isInfoOpen} = this.state;
    this.setState({
      isInfoOpen: !isInfoOpen
    });
  };

  onKeyChange = (label, value, index) => {
    const {changeShortcuts} = this.props;
    const keyboardShortcuts = deepCopy(this.state.keyboardShortcuts);
    let shortcut = keyboardShortcuts.filter(el => el.label === label);
    let keyValue = '';
    let labelValue = '';
    let keysArray = shortcut && shortcut[0].keys;
    let labelArray = shortcut && shortcut[0].label.split('+');
    let shortcutKeyArray = shortcut && shortcut[0].shortcutKey.split('+');
    switch (value) {
      case 'ArrowRight':
        keyValue = '→';
        labelValue = 'right';
        break;
      case 'ArrowLeft':
        keyValue = '←';
        labelValue = 'left';
        break;
      default:
        keyValue = value;
        labelValue = value;
    }
    keysArray[index] = keyValue;
    labelArray[index] = labelValue.charAt(0).toUpperCase() + labelValue.substring(1);
    shortcutKeyArray[index] = labelValue;
    shortcut[0].label = labelArray.join(' + ');
    shortcut[0].shortcutKey = shortcutKeyArray.join('+');
    if (this.shortcutValidation(shortcut[0].shortcutKey)) {
      changeShortcuts(keyboardShortcuts);
    }
  };

  onKeyButton = (index, item) => {
    const {isCustomizeMode} = this.state;
    if (isCustomizeMode) {
      this.setState({
        keyIndex: index,
        keyLabel: item.label
      });
    }
  };

  onKeyPressed = e => {
    const {isCustomizeMode, keyIndex, keyLabel} = this.state;
    const key = e.keyCode !== 32 ? e.key : 'space';
    if (isCustomizeMode) {
      this.onKeyChange(keyLabel, key, keyIndex);
    }
  };

  onGetShortcuts = name => {
    const {getShortcuts} = this.props;
    getShortcuts(name);
  };

  getShortcutColumn(shortcutColumnItems, index, isLastColumn) {
    const {keyboardShortcutsType, templateColors} = this.props;
    const {isCustomizeMode, keyIndex, keyLabel, isInfoOpen} = this.state;

    let shortcutsColumnContent = (
      <ul>
        {shortcutColumnItems.map((item, index) => (
          <li className="keyboard-shortcuts-buttons-and-description" key={index}>
            <div className="keyboard-shortcuts-buttons">
              {item.keys.map((elem, index) => (
                <button
                  className={
                    isCustomizeMode
                      ? item.label === keyLabel && index === keyIndex
                        ? 'selected-customize-btn'
                        : 'customize-btn'
                      : ''
                  }
                  key={index}
                  onClick={() => this.onKeyButton(index, item)}
                >
                  {elem}
                </button>
              ))}
            </div>
            <div className="keyboard-shortcuts-description" style={{color: templateColors.main}}>
              {item.description}
            </div>
          </li>
        ))}
      </ul>
    );

    return isLastColumn ? (
      <div className="keyboard-shortcuts-column" key={index}>
        {shortcutsColumnContent}
        <div className="change-keyboard-shortcut-options">
          <CustomRadioButton
            index="shortcuts-default"
            id="shortcuts-default"
            value="default"
            text="Default"
            selectedValue={keyboardShortcutsType}
            templateColors={templateColors}
            onChange={e => this.onGetShortcuts(e.target.value)}
          />
          {isMac && (
            <CustomRadioButton
              index="shortcuts-fcp"
              id="shortcuts-fcp"
              value="fcp"
              text="Final Cut Pro Settings"
              selectedValue={keyboardShortcutsType}
              templateColors={templateColors}
              onChange={e => this.onGetShortcuts(e.target.value)}
            />
          )}
          <CustomRadioButton
            index="shortcuts-avid"
            id="shortcuts-avid"
            value="avid"
            text="Avid Media Composer"
            selectedValue={keyboardShortcutsType}
            templateColors={templateColors}
            onChange={e => this.onGetShortcuts(e.target.value)}
          />
          <CustomRadioButton
            index="shortcuts-premiere"
            id="shortcuts-premiere"
            value="premiere"
            text="Adode Premiere"
            selectedValue={keyboardShortcutsType}
            templateColors={templateColors}
            onChange={e => this.onGetShortcuts(e.target.value)}
          />
          {keyboardShortcutsType === 'default' && (
            <>
              <div className="change-keyboard-shortcut-options_customize">
                <div onClick={this.onCustomizeChange}>Customize</div>
                <div className="info-btn" onMouseOver={this.infoBtnToogle}>
                  <Icon icon={InfoIcon} size="18px" />
                </div>
                {isInfoOpen && (
                  <div className="shortcut-info">
                    Select Customize action link and then hightlight the action hotkey you would like to customize.
                  </div>
                )}
              </div>
              <div className="change-keyboard-shortcut-options_customize" onClick={this.props.setDefaultShortcuts}>
                <span>Restore Defaults</span>
              </div>
            </>
          )}
        </div>
      </div>
    ) : (
      <div className="keyboard-shortcuts-column" key={index}>
        {shortcutsColumnContent}
      </div>
    );
  }

  getAllShortcutColumns() {
    const {keyboardShortcuts} = this.state;
    const SHORTCUTS_PER_COLUMN = 10;
    let numberOfColumns = Math.ceil(keyboardShortcuts.length / SHORTCUTS_PER_COLUMN);
    let keyboardShortcutsColumns = [];
    for (let i = 0; i < numberOfColumns; i++) {
      let shortcutColumnItems = keyboardShortcuts.slice(i * SHORTCUTS_PER_COLUMN, (i + 1) * SHORTCUTS_PER_COLUMN);
      let isLastColumn = i === numberOfColumns - 1;
      keyboardShortcutsColumns.push(this.getShortcutColumn(shortcutColumnItems, i, isLastColumn));
    }
    return keyboardShortcutsColumns;
  }

  render() {
    const {templateColors} = this.props;

    return (
      <div className="op_control-bar-shortcuts" onKeyDown={this.onKeyPressed} tabIndex={0}>
        <div className="op_control-bar-shortcuts_title" style={{color: templateColors.main}}>
          Keyboard Shortcuts
        </div>
        <div className="op_control-bar-shortcuts_content">{this.getAllShortcutColumns()}</div>
      </div>
    );
  }
}
