import * as React from 'react';
import {TimelineEvents} from '../../../TimelineEvents';
import {IFrameRate} from 'tt-components';
import {IComment} from '../../../../../Tabs/interfaces/ICommentsTab';
import {ITooltipSegmentData} from '../../../../interfaces/ITimelineTooltipData';
import {SegmentModel, DataType} from '../../../../utils/SegmentModel';

interface ICommentsContentProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  frameRate: IFrameRate;
  comments: Array<IComment>;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class CommentsContent extends React.PureComponent<ICommentsContentProps> {
  render() {
    const comments = this.props.comments.map((comment: IComment) => {
      return new SegmentModel(comment, this.props.frameRate, DataType.Comments);
    });

    return (
      <TimelineEvents
        secondUnits={this.props.secondUnits}
        contentWidth={this.props.contentWidth}
        scrollLeft={this.props.scrollLeft}
        frameRate={this.props.frameRate}
        events={comments}
        segmentClassName="commentslayer-content-container_segment"
        contentClassName="commentslayer-content-container"
        emptySegmentsMessage="No Comments are defined"
        onElementIn={this.props.onElementIn}
        onElementOut={this.props.onElementOut}
        onSegmentClicked={this.props.onSegmentClicked}
      />
    );
  }
}
