import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {IShortcutsDefinitions} from '../../constants/shortcuts';
import {ITemplateColors} from '../../../@types/templateColors';
import {CloseIcon} from '../../assets/Icons/Close';
import ShortcutButton from './ShortcutButton';

export interface IShortcutsProps {
  templateColors: ITemplateColors;
  keyboardShortcuts: Array<IShortcutsDefinitions>;
  onClose: (e: any) => void;
}

export interface IShortcutsState {
  keyboardShortcuts?: Array<IShortcutsDefinitions>;
  shortcutTypes?: Array<string>;
}

export class Shortcuts extends React.PureComponent<IShortcutsProps, IShortcutsState> {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.keyboardShortcuts !== prevState.keyboardShortcuts) {
      return {
        keyboardShortcuts: nextProps.keyboardShortcuts
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      keyboardShortcuts: this.props.keyboardShortcuts,
      shortcutTypes: ['Markups', 'Display', 'Player']
    };
  }

  getContentByType = type => {
    const {keyboardShortcuts} = this.state;
    const data = keyboardShortcuts.filter(item => item.type === type);
    return (
      <div className="shortcut-column">
        <div className="shortcut-type-name">{type}</div>
        {data.map((elem, index) => {
          if (!elem.isGroup && !elem.block) {
            return (
              <div key={index} className="shortcut-item">
                <label className="column-header">{elem.description}</label>
                <div className="shortcut-buttons">
                  {elem.keys.map((el, index) => {
                    const textClass =
                      el.split(' ').length === 1 ? (el.length > 1 ? 'long-text' : 'short-text') : 'two-words';
                    return <ShortcutButton className={textClass} key={index} label={el} />;
                  })}
                </div>
              </div>
            );
          } else if (elem.isGroup) {
            return (
              <div key={index} className="shortcut-item">
                <label className="column-header">{elem.groupTitle}</label>
                <div className="shortcut-buttons-group">
                  <div className="shortcut-buttons">
                    {elem.keys.map((el, index) => {
                      const textClass =
                        el.split(' ').length === 1 ? (el.length > 1 ? 'long-text' : 'short-text') : 'two-words';
                      return <ShortcutButton className={textClass} key={index} label={el} />;
                    })}
                  </div>
                  <div className="shortcut-buttons">
                    {data[index + 1].keys.map((el, index) => {
                      const textClass =
                        el.split(' ').length === 1 ? (el.length > 1 ? 'long-text' : 'short-text') : 'two-words';
                      return <ShortcutButton className={textClass} key={index} label={el} />;
                    })}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  render() {
    const {shortcutTypes} = this.state;
    return (
      <div className="shortcuts">
        {shortcutTypes.map(item => this.getContentByType(item))}
        <div className="close-icon" onClick={this.props.onClose}>
          <Icon icon={CloseIcon} size="12px" color="#FFFFFF" />
        </div>
      </div>
    );
  }
}
