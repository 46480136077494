import {createSelector} from 'reselect';
import {IAppState} from '../state/IAppState';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {IAppConfig} from '../../@types/appConfig';

export const getPlaylistAssetsSelector = (state: IAppState): Array<PlaylistAsset> => state.video.playlist.assets;
export const getPlaylistSelectedAssetIdSelector = (state: IAppState): string => state.video.playlist.selectedAssetId;
export const getConfigurations = (state: IAppState) => state.configuration;

export const getPlaylistSelectedAsset = createSelector(
  [getPlaylistAssetsSelector, getPlaylistSelectedAssetIdSelector],
  (assets: Array<PlaylistAsset>, assetId: string) => PlaylistAsset.filter.getPlaylistAsset(assets, assetId)
);

export const getTabsConfigurations = createSelector(
  [getConfigurations],
  (configurations: IAppConfig) => ({
    templateColors: configurations.templateColors,
    containerId: configurations.externalControlsElementId,
    curationModeEnabled: configurations.curationModeEnabled
  })
);

export const getEmbeddedAudioConfigurations = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getEmbeddedAudioConfigurations(assets)
);

export const getExternalAudioConfigurations = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getExternalAudioConfigurations(assets)
);

export const getEmbeddedSubtitles = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getEmbeddedSubtitles(assets)
);

export const getExternalSubtitles = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getExternalSubtitles(assets)
);
