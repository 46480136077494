import {createAction} from 'redux-actions';
import {IVideoQuality} from 'tt-components/src/VideoPlayer/BitMovinPlayer';
import {IAppState} from '../state';
import {IServiceProvider} from '../services/interfaces';

export const UPDATE_CURRENT_DROP = 'Video/UPDATE_CURRENT_DROP';
export type UPDATE_CURRENT_DROP = number;
export const updateCurrentDrop = createAction<UPDATE_CURRENT_DROP, UPDATE_CURRENT_DROP>(
  UPDATE_CURRENT_DROP,
  (currentDrop: UPDATE_CURRENT_DROP) => currentDrop
);

export const UPDATE_TOTAL_DROP = 'Video/UPDATE_TOTAL_DROP';
export type UPDATE_TOTAL_DROP = number;
export const updateTotalDrop = createAction<UPDATE_TOTAL_DROP, UPDATE_TOTAL_DROP>(
  UPDATE_TOTAL_DROP,
  (totalDrop: UPDATE_TOTAL_DROP) => totalDrop
);

export const updateDroppedFramesMetrics = () => {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const qualityMetrics = getState().qualityMetrics;
    const prevTotalDroppedFrames = qualityMetrics.totalDroppedFrames || 0;
    const nextTotalDroppedFrames = services.video.getDroppedFrames() || 0;
    const nextCurrentDroppedFrames = nextTotalDroppedFrames - prevTotalDroppedFrames;
    console.log('Checking total dropped frames => ', nextTotalDroppedFrames);
    if (nextTotalDroppedFrames !== prevTotalDroppedFrames) {
      dispatch(updateCurrentDrop(nextCurrentDroppedFrames));
      dispatch(updateTotalDrop(nextTotalDroppedFrames));
    }
  };
};

export const resetQualityMetrics = () => {
  return dispatch => {
    dispatch(updateQualityLevels([]));
    dispatch({type: UPDATE_LOADED_LEVEL, payload: null});
    dispatch(updateCurrentDrop(0));
    dispatch(updateTotalDrop(0));
  };
};

export const UPDATE_QUALITY_LEVELS = 'Video/UPDATE_QUALITY_LEVELS';
export type UPDATE_QUALITY_LEVELS = Array<IVideoQuality>;
export const updateQualityLevels = createAction<UPDATE_QUALITY_LEVELS, UPDATE_QUALITY_LEVELS>(
  UPDATE_QUALITY_LEVELS,
  (levels: UPDATE_QUALITY_LEVELS) => levels
);

export const UPDATE_LOADED_LEVEL = 'Video/UPDATE_LOADED_LEVEL';
export type UPDATE_LOADED_LEVEL = IVideoQuality;
export const updateLoadedLevel = (qualityId: string) => {
  return (dispatch, getState: () => IAppState) => {
    const levels = getState().qualityMetrics.qualityLevels || [];
    const updatedLevel = levels.find((data: IVideoQuality) => data.id === qualityId);
    if (updatedLevel) {
      dispatch({type: UPDATE_LOADED_LEVEL, payload: updatedLevel});
    }
  };
};
