import * as React from 'react';

import './styles';

interface IShortcutButtonProps {
  className?: string;
  label?: any;
  onClick?: () => void;
  style?: React.CSSProperties;
}

function shortcutButton(props: IShortcutButtonProps) {
  const shortcutClass = props.className ? ` ${props.className}` : ``;
  const data = props.label;
  return (
    <div className={`shortcut-button ${shortcutClass}`} onClick={props.onClick || null} style={props.style || {}}>
      {typeof data === 'object' ? (
        <div className="shortcuts-label">
          {data.map((item, index) => {
            return <label key={index}>{item}</label>;
          })}
        </div>
      ) : (
        <label>{data}</label>
      )}
    </div>
  );
}

export default React.memo(shortcutButton);
