import {apiRequest} from './index';

export async function getPlaylist(sourceUrl: string) {
  const url = appConfig.apiHybrikHost + `playlist`;

  const param = {
    source: sourceUrl,
    video: {
      bitrate_kb: 1000
    },
    audio: {
      channels: 1,
      map: [
        {
          input: {
            track: 1,
            channel: 1
          },
          output: {
            track: 0,
            channel: 1
          }
        }
      ]
    }
  };

  return apiRequest('POST', url, JSON.stringify(param));
}
