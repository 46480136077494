import * as React from 'react';
import {ITemplateColors} from '../../../@types/templateColors';
import {IIconProps, Icon} from 'tt-components/src/Icon/Icon';

export interface ITickers {
  checked: React.ComponentType<IIconProps>;
  notChecked: React.ComponentType<IIconProps>;
}

export interface ICustomCheckboxProps {
  index: string | number;
  id: string;
  value?: string | number;
  selectedValue?: string | number;
  checked?: boolean;
  disabled?: boolean;
  text?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  templateColors: ITemplateColors;
  className?: string;
  customTickers?: ITickers;
}

export class CustomCheckbox extends React.PureComponent<ICustomCheckboxProps> {
  onLabelClick = e => {
    e.stopPropagation();
  };

  isChecked = () => {
    const selectedValue = this.props.selectedValue || '';
    const value = this.props.value || '';
    return this.props.checked || (selectedValue === value && value !== '');
  };

  getCheckboxTickers = () => {
    const isChecked = this.isChecked();
    const {highlight} = this.props.templateColors;
    if (this.props.customTickers) {
      return (
        <>
          {!isChecked && (
            <Icon icon={this.props.customTickers.notChecked as any} data-action-icon size="18px" color="#FFFFFF" />
          )}
          {isChecked && (
            <Icon icon={this.props.customTickers.checked as any} data-action-icon size="18px" color="#FFFFFF" />
          )}
        </>
      );
    }
    return (
      <div className="checkbox-container_checkbox_custom" style={{borderColor: highlight}}>
        <div
          className="checkbox-container_checkbox_custom_check_mark"
          style={{color: highlight, display: isChecked ? '' : 'none'}}
        >
          &#x2713;
        </div>
      </div>
    );
  };

  render() {
    const {main} = this.props.templateColors;
    const value = this.props.value || '';
    const isChecked = this.isChecked();
    const className = this.props.className ? ` ${this.props.className}` : ``;

    return (
      <div
        className={`checkbox-container${this.props.disabled ? ` disabled` : ``}`}
        {...(this.props.index ? {key: this.props.index} : {})}
      >
        <label
          className={`checkbox-container_checkbox${className}`}
          htmlFor={this.props.id}
          onClick={this.onLabelClick}
        >
          <input
            disabled={this.props.disabled}
            id={this.props.id}
            type="checkbox"
            value={value}
            checked={isChecked}
            style={{display: 'none'}}
            onChange={this.props.onChange}
          />
          {this.getCheckboxTickers()}
          <div className="radio-container_radio_title" title={this.props.text || ''} style={{color: main}}>
            {this.props.text || 'Option'}
          </div>
        </label>
      </div>
    );
  }
}
