import {IFrameRate} from 'tt-components/src/frameRate';
import {apiRequest} from './index';
import {ITTmanRequestBody} from '../../@types/ttmanRequestBody';
import {IResponse} from '../../@types/response';

export async function transformSubtitlesToVTT(
  fileUrl: string,
  frameRate: IFrameRate = {frameRate: 24, dropFrame: false},
  sourceFormat?: string
): Promise<IResponse> {
  const body: ITTmanRequestBody = {
    frameRate: frameRate ? frameRate.frameRate.toString() : '',
    dropFrame: frameRate ? Number(frameRate.dropFrame).toString() : '',
    destinationFormat: 'webvtt',
    file: fileUrl,
    forceReturnContent: '1',
    sourceExtension: sourceFormat
  };

  if (sourceFormat) {
    body.sourceFormat = sourceFormat;
  }

  const url = appConfig.apiTTmanHost + 'transform';
  try {
    const response = await apiRequest('POST', url, JSON.stringify(body), false, null, true);
    return {success: true, data: URL.createObjectURL(response)};
  } catch (error) {
    return {success: false, data: '', error};
  }
}
