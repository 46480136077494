import * as React from 'react';
import {IParsedVTT} from '../../../../../../../@types/parsedVtt';
import {convertVttToJson} from '../../../../../../utils/utils';
import {ScrollContainer} from '../../../ScrollContainer';
import {ITooltipSegmentData} from '../../../../interfaces';
import {TimelineSegment} from '../../../TimelineSegment';

interface ISubtitlesContentProps {
  label: string;
  webVTTUrl: string;
  secondUnits: number;
  width: number;
  scrollLeft: number;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

interface ISubtitlesContentState {
  loading: boolean;
  jsonVTT: IParsedVTT[];
  containerHeight: number;
  subtitleHeight: number;
}

// Const value found due some different testing, between known values
const fontSizeWidthRatio = 0.557;
const textNodeFontSize = 11;

export class SubtitlesContent extends React.Component<ISubtitlesContentProps, ISubtitlesContentState> {
  containerRefElement: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      jsonVTT: [],
      containerHeight: 24,
      subtitleHeight: 10
    };
  }

  componentDidMount() {
    this.prepareSubtitles();
  }

  componentDidUpdate(prevProps: ISubtitlesContentProps) {
    if (prevProps.webVTTUrl !== this.props.webVTTUrl) {
      this.setState({loading: true}, this.prepareSubtitles);
    }
  }

  prepareSubtitles = () => {
    convertVttToJson(this.props.webVTTUrl).then((jsonVTT: IParsedVTT[]) => {
      this.setState({jsonVTT, loading: false});
    });
  };

  renderStateComponent = () => {
    const {loading, jsonVTT} = this.state;
    if (loading) {
      return <span>Parsing WebVTT content...</span>;
    }

    return jsonVTT.length ? null : <span>Subtitle content is empty</span>;
  };

  renderSubtitlesUI = () => {
    return this.state.jsonVTT.map((record: IParsedVTT, index: number) => {
      // In case Start Time is the same as End Time we give a pre-defined duration of 1 second
      // just to be able to display the segment in the Timeline
      const intervalDuration = record.end - record.start === 0 ? 1000 : record.end - record.start;

      const style: React.CSSProperties = {
        left: (record.start * this.props.secondUnits) / 1000,
        width: (intervalDuration * this.props.secondUnits) / 1000,
        height: this.state.subtitleHeight,
        display: this.props.secondUnits ? 'flex' : 'none'
      };

      const orderNumber = `#${index}`;
      const showTextNode =
        (style.width as number) - textNodeFontSize * fontSizeWidthRatio * orderNumber.length > 1 ? '' : ' hidden';

      return (
        <TimelineSegment
          key={index}
          containerClassName="subtitleslayer-content-container_content_item"
          style={style}
          start={record.start / 1000}
          end={record.end / 1000}
          title={`${this.props.label} Subtitle ${orderNumber}`}
          content={record.part}
          onElementIn={this.props.onElementIn}
          onElementOut={this.props.onElementOut}
          onSegmentClicked={this.props.onSegmentClicked}
        >
          <span
            className={`subtitleslayer-content-container_content_item_text${showTextNode}`}
            style={{fontSize: textNodeFontSize}}
          >
            {orderNumber}
          </span>
        </TimelineSegment>
      );
    });
  };

  render() {
    const {loading, jsonVTT} = this.state;
    const subtitles = this.renderSubtitlesUI();
    const stateComponent = this.renderStateComponent();
    const isLoading = loading || !!stateComponent;
    const contentStyles = isLoading ? {} : {width: this.props.width, height: this.state.containerHeight};

    return (
      <ScrollContainer
        contentClassName={`subtitleslayer-content-container_content${isLoading ? ` loading` : ``}`}
        contentStyle={contentStyles}
        scrollLeft={this.props.scrollLeft}
      >
        {!loading && !!jsonVTT.length && subtitles}
        {stateComponent}
      </ScrollContainer>
    );
  }
}
