import {apiRequest} from './index';
import {IPlaybackBodyRequest} from '../state/IVideoState';
import {IResponse} from '../../@types/response';
import {parseAssetPlaylist, parseAssetStatusPlaylist} from '../utils/utils';
import {IPlaylistContentResponse, IPlaylistAtlas, IWaveformsUrlData, IThumbnailsUrlData} from '../state/IAppState';
import {IPlaylistRequestAsset} from '../../@types/playlistRequestAsset';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';

export const getPlaybackUrlAPI = async (body: IPlaybackBodyRequest, playlistId: string = 'xyz'): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}playback/${playlistId}`;
    const data = await apiRequest('POST', url, JSON.stringify(body));
    return {success: true, data: data.url};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getSubtitlesUrlsAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}playback/${playlistId}/subtitles`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: data && Array.isArray(data) ? data.map(PlaylistAsset.parsing.parseSubtitlesStageData) : []
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const getWaveformsUrlsAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}playback/${playlistId}/waveforms`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: (data as Array<IWaveformsUrlData>) || []
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const getThumbnailsUrlAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}playback/${playlistId}/thumbnails`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: (data as Array<IThumbnailsUrlData>) || []
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const getPlaylistContentAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}playlist/${playlistId}`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: {id: data.id, assets: (data.assets || []).map(parseAssetPlaylist)} as IPlaylistContentResponse
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const getConformanceAssetsAPI = async (assetId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}playlist/conformancegroup/${assetId}`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, error};
  }
};

export const getAssetsPlaylistStatusAPI = async (assets: Array<IPlaylistRequestAsset>): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}playlist/assetstatus`;
    const data = await apiRequest('POST', url, JSON.stringify(assets));
    return {success: true, data: (data.data || []).map(parseAssetStatusPlaylist)};
  } catch (error) {
    return {success: false, error};
  }
};

export const createPlaylistByAssetsAPI = async (
  assets: Array<IPlaylistRequestAsset>,
  force: boolean = false
): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}stage${force ? `?force=true` : ``}`;
    const data = await apiRequest('POST', url, JSON.stringify({assets}));
    return {
      success: true,
      data: {
        id: data.id,
        assets: (data.assets || []).map(parseAssetPlaylist),
        assetDetails: data.searchAssets || []
      } as IPlaylistContentResponse
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const createAssetsPlaylistAPI = async (assetId: string, force: boolean = false): Promise<IResponse> => {
  try {
    const url = `${appConfig.onePlayerServiceHost}stage/stageassetsfromconformance/${assetId}${
      force ? `?force=true` : ``
    }`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: {
        id: data.id,
        assets: (data.assets || []).map(parseAssetPlaylist),
        assetDetails: data.versionAssets || []
      } as IPlaylistContentResponse
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const initPlaylistCreation = async (
  atlasAssets: Array<IPlaylistAtlas>,
  force: boolean = false,
  curationModeEnabled: boolean = false
) => {
  // NOTE: We need filter out the Non Media assets as they usually don't have valid vfsId
  // and One Player service will through an error on it.
  // TODO: Investigate case to allow One Player Service to query assets without vfsId in the future
  let requestAssets: Array<IPlaylistRequestAsset> = [].concat(
    atlasAssets
      .filter((asset: IPlaylistAtlas) => !asset.isNonMedia)
      .reduce((acc: Array<IPlaylistRequestAsset>, asset: IPlaylistAtlas) => {
        const requestAsset = {
          assetId: asset.assetId,
          isRegistered: typeof asset.isRegistered !== 'undefined' ? asset.isRegistered : true
        };
        return [...acc, requestAsset];
      }, [])
  );
  const nonMediaAssets = atlasAssets
    .filter((asset: IPlaylistAtlas) => asset.isNonMedia)
    .map((asset: IPlaylistAtlas) => ({
      assetId: asset.assetId,
      assetType: 'Image',
      assetStatus: 'Staged',
      path: '',
      vfsName: ''
    }));
  // In case all the assets are 'Non Media' we avoid calling the One Player Service
  if (nonMediaAssets.length === atlasAssets.length) {
    const response = {
      success: true,
      data: {
        id: '',
        assets: nonMediaAssets,
        assetDetails: []
      } as IPlaylistContentResponse
    } as IResponse;
    return Promise.resolve(response);
  }

  // NOTE: Player should check the curationModeEnabled flag from the configuration props to define
  // the set of the assets that needs to be passed to the Player Service end-point
  if (!curationModeEnabled) {
    const promiseAssets = requestAssets.reduce(
      async (accPromise: Promise<Array<IPlaylistRequestAsset>>, asset: IPlaylistRequestAsset) => {
        const acc = await accPromise;
        const data = await getConformanceAssetsAPI(asset.assetId);
        const assets = data.success
          ? data.data.map(record => ({assetId: record.id || '', isRegistered: true} as IPlaylistRequestAsset))
          : [asset];
        return Promise.resolve([...acc, ...assets]);
      },
      Promise.resolve([])
    );
    requestAssets = await promiseAssets;
  }

  let result = await createPlaylistByAssetsAPI(requestAssets, force);
  // In case we have success response from One Player Service we can see if can merge some
  // 'Non Media' assets to the content returned
  if (result.success && nonMediaAssets.length) {
    result = {
      ...result,
      data: {
        ...result.data,
        assets: [...result.data.assets, ...nonMediaAssets]
      }
    };
  }
  return result;
};
