import * as React from 'react';
import {Dropdown, IDropdownOption} from '../../../../../../components/Dropdown';

interface IStartTimecodeDropdownProps {
  disabled?: boolean;
  enabled: boolean;
  closestBody?: HTMLElement;
  updateUseStartTimecodeFlag?: (flag: boolean) => void;
}

const onSelected = (props: IStartTimecodeDropdownProps, value: string) => {
  if (props.updateUseStartTimecodeFlag) {
    props.updateUseStartTimecodeFlag(value === 'enabled' ? true : false);
  }
};

export const StartTimecodeDropdown = (props: IStartTimecodeDropdownProps) => {
  const options: Array<IDropdownOption> = [
    {label: 'Enabled', value: 'enabled'},
    {label: 'Disabled', value: 'disabled'}
  ];
  const selected = props.enabled ? 'enabled' : 'disabled';
  return (
    <Dropdown
      label="Start Timecode"
      disableMatchWidth
      borderless
      width={140}
      disabled={props.disabled}
      options={options}
      selected={selected}
      onSelected={onSelected.bind(this, props)}
      paddingLeft={0}
      borderWidth={0}
      portalNode={props.closestBody}
    />
  );
};
