import * as React from 'react';

interface IConfigButtonProps {
  text: string;
  value: string;
  onClick: (value: string) => void;
}

export const ConfigButton = (props: IConfigButtonProps) => (
  <button className="config-button-container" onClick={e => props.onClick(props.value)}>
    <span className="config-button-container_text">{props.text}</span>
    <span className="config-button-container_plus">&#43;</span>
  </button>
);
