import {EventBusEventNames} from '../@types/eventBusEventNames';
import {EventBusComponentNames} from '../@types/eventBusModuleNames';
import {serviceProvider} from './services/serviceProvider';
import {Action} from 'redux-actions';
import {SWITCH_PLAYING} from './actions/video';
import {PlayerEventExposedNames, PlayerEventsExposed} from 'constants/constants';

export interface IEventBus {
  on(event: EventBusEventNames, componentName: EventBusComponentNames, callback: (data: any) => {});
  off(event: EventBusEventNames, componentName: EventBusComponentNames);
  trigger(event: EventBusEventNames, data: any);
  nextAction(action: Action<any>);
}

export class EventBus {
  eventsToCallbacks: object;
  constructor() {
    this.eventsToCallbacks = {};
  }
  on(event: EventBusEventNames, componentName: EventBusComponentNames, callback: (data: any) => {}) {
    if (!this.eventsToCallbacks[event]) {
      this.eventsToCallbacks[event] = {};
    }
    if (!componentName && !this.eventsToCallbacks[event]._default) {
      this.eventsToCallbacks[event]._default = [];
    }
    if (componentName && !this.eventsToCallbacks[event][componentName]) {
      this.eventsToCallbacks[event][componentName] = [];
    }
    if (event && callback) {
      if (!componentName) {
        this.eventsToCallbacks[event]._default.push(callback);
      } else {
        this.eventsToCallbacks[event][componentName].push(callback);
      }
    }
  }
  off(event: EventBusEventNames, componentName: EventBusComponentNames = null) {
    if (event && !componentName) {
      delete this.eventsToCallbacks[event];
    } else if (event && componentName) {
      delete this.eventsToCallbacks[event][componentName];
    } else if (!event && componentName) {
      for (let key in this.eventsToCallbacks[event]) {
        if (this.eventsToCallbacks[event].hasOwnProperty(key) && key === componentName) {
          delete this.eventsToCallbacks[event][key];
        }
      }
    }
  }

  nextAction(action: Action<any>) {
    let event;

    switch (action.type) {
      case SWITCH_PLAYING:
        if (!action.payload) {
          event = PlayerEventExposedNames[PlayerEventsExposed.Pause];
        } else {
          event = PlayerEventExposedNames[PlayerEventsExposed.Play];
        }
        break;
    }

    if (event) {
      this.trigger(event);
    }
  }

  trigger(event: EventBusEventNames, data: any = null) {
    if (this.eventsToCallbacks[event]) {
      for (let key in this.eventsToCallbacks[event]) {
        if (this.eventsToCallbacks[event].hasOwnProperty(key)) {
          let callbacks = this.eventsToCallbacks[event][key];
          for (let callback of callbacks) {
            callback(data);
          }
        }
      }
    }
  }
}

export const exposedEventMiddlewareWrapper = store => next => action => {
  const newState = next(action);
  serviceProvider.eventBus.nextAction(action);
  serviceProvider.gtm.nextAction(action, store);
  return newState;
};
