import {BaseLS} from './BaseLS';
import {IEnum} from '../../../@types/enum';

const STORAGE_KEY = 'bitRateMode';

export class BitRateMode extends BaseLS<IEnum> {
  constructor() {
    super(STORAGE_KEY);
  }
}
