import * as React from 'react';
import {TimelineRow} from '../TimelineRow';
import {MarkupsLabel} from './components/MarkupsLabel';
import {MarkupsContent} from './components/MarkupsContent';
import {IFrameRate} from 'tt-components';
import {ITooltipSegmentData} from '../../interfaces/ITimelineTooltipData';
import {IEventGroup} from '../../../../../@types/markupEvent';

interface IMarkupsLayerProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  frameRate: IFrameRate;
  events: Array<IEventGroup>;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class MarkupsLayer extends React.PureComponent<IMarkupsLayerProps> {
  render() {
    const label = <MarkupsLabel />;
    const content = (
      <MarkupsContent
        secondUnits={this.props.secondUnits}
        contentWidth={this.props.contentWidth}
        scrollLeft={this.props.scrollLeft}
        frameRate={this.props.frameRate}
        events={this.props.events}
        onElementIn={this.props.onElementIn}
        onElementOut={this.props.onElementOut}
        onSegmentClicked={this.props.onSegmentClicked}
      />
    );

    return <TimelineRow labelElement={label} contentElement={content} />;
  }
}
