import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const TriangleIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '5px'}
      height={props.size || '8px'}
      viewBox="0 0 5 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.25">
        <g id="Player-Markup_completed_s" transform="translate(-271.000000, -19.000000)" fill={props.color}>
          <g id="Group" transform="translate(189.000000, 11.000000)">
            <polygon
              id="Triangle"
              transform="translate(84.500000, 12.000000) scale(1, -1) 
              rotate(90.000000) translate(-84.500000, -12.000000) "
              points="84.5 9.5 88.5 14.5 80.5 14.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
