import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const EditTabDataIcon = (props: Partial<IIconProps>) => (
  <svg
    width={props.size || '18px'}
    height={props.size || '18px'}
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="ONEplayer_metadata-(Audio)---Structured"
        transform="translate(-1323.000000, -295.000000)"
        fill={props.color || '#1F72F2'}
      >
        <g id="TextLink-with-icons" transform="translate(1320.000000, 290.000000)">
          <g id="icons/add" transform="translate(0.000000, 2.000000)">
            <path
              d="M20.2082112,3.79178886 C20.466277,4.04985466 20.662756,4.34017434 
              20.797654,4.6627566 C20.9325521,4.98533886 21.0000001,5.32844393 
              21.0000001,5.69208211 C21.0000001,6.04399003 20.9325521,6.38416258 
              20.797654,6.71260997 C20.662756,7.04105736 20.466277,7.33430956 
              20.2082112,7.59237537 L8.71847515,19.0645161 C8.69501462,19.0879767 
              8.67155445,19.1085043 8.64809392,19.1260997 C8.62463339,19.1436951 
              8.59530817,19.1583577 8.56011738,19.170088 L3.59824055,20.9824047 
              C3.57478002,20.9824047 3.55131984,20.9853372 3.52785932,20.9912023 
              C3.50439879,20.9970675 3.48093861,21 3.45747808,21 C3.39882676,21 
              3.34017632,20.9882699 3.281525,20.9648094 C3.22287369,20.9413489 
              3.17008829,20.9120236 3.12316723,20.8768328 C3.06451591,20.8064513 
              3.02639313,20.7302057 3.00879773,20.6480938 C2.99120234,20.565982 
              2.9999999,20.4838714 3.03519069,20.4017595 L4.8299121,15.4398827 
              C4.84164237,15.4164222 4.85630498,15.3900295 4.87390037,15.3607038 
              C4.89149577,15.3313782 4.91202342,15.3049855 4.93548395,15.2815249 
              L16.42522,3.79178886 C16.6715556,3.54545331 16.9589427,3.35190686 
              17.2873901,3.2111437 C17.6158375,3.07038053 17.95601,3 18.307918,3 
              C18.6715561,3 19.0175937,3.07038053 19.3460411,3.2111437 
              C19.6744885,3.35190686 19.9618757,3.54545331 20.2082112,3.79178886 
              Z M8.1554253,18.3607038 L18.1319649,8.38416422 L15.6158359,5.86803519 
              L5.63929627,15.8445748 L4.19648102,19.8035191 L8.1554253,18.3607038 
              Z M19.5747801,6.94134897 C19.7390038,6.77712528 19.8680348,6.58651135 
              19.9618769,6.36950147 C20.055719,6.15249158 20.1026394,5.92668739 
              20.1026394,5.69208211 C20.1026394,5.45747683 20.055719,5.23167264 
              19.9618769,5.01466276 C19.8680348,4.79765287 19.7390038,4.6011739 
              19.5747801,4.42521994 C19.4105564,4.26099625 19.2199425,4.1348978 
              19.0029326,4.04692082 C18.7859227,3.95894384 18.5542535,3.91495601 
              18.307918,3.91495601 C18.0733127,3.91495601 17.8475085,3.95894384 
              17.6304986,4.04692082 C17.4134887,4.1348978 17.2228748,4.26099625 
              17.0586511,4.42521994 L16.2492669,5.23460411 L18.765396,7.75073314 
              L19.5747801,6.94134897 Z"
              id="edit"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
