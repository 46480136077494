import * as React from 'react';
import {ITimelineTooltipData} from '../../interfaces/ITimelineTooltipData';
import {IFrameRate} from 'tt-components';
import {utils} from 'tt-components/src/Utils';
import {Icon} from 'tt-components/src/Icon/Icon';
import {formatCommentTime} from '../../../Tabs/utils/helpers';

import {ReplayIcon} from '../../../../assets/Icons/Replay';

interface ITimelineTooltip {
  data: ITimelineTooltipData;
  timelineRefWidth: number;
  frameRate: IFrameRate;
  oneUiHost: string;
  onTooltipIn: () => void;
  onTooltipOut: () => void;
  onLoop: () => void;
}

export class TimelineTooltip extends React.PureComponent<ITimelineTooltip> {
  tooltipRefElement: HTMLDivElement;
  arrowRefElement: HTMLDivElement;

  componentDidMount() {
    const {width} = this.tooltipRefElement.getBoundingClientRect();
    const {leftPosition, bottomPosition} = this.props.data;
    const arrowHeight = this.arrowRefElement.getBoundingClientRect().height;
    const arrowWidth = this.arrowRefElement.getBoundingClientRect().width;

    const tooltipRelativeLeft = leftPosition - width / 2;
    const tooltipDiff = this.props.timelineRefWidth - (width + tooltipRelativeLeft);
    if (tooltipDiff < 0) {
      this.tooltipRefElement.style.left = `${tooltipRelativeLeft + tooltipDiff}px`;
    } else {
      this.tooltipRefElement.style.left = `${tooltipRelativeLeft}px`;
    }
    this.tooltipRefElement.style.bottom = `${bottomPosition + arrowHeight}px`;

    const arrowRelativeLeft = leftPosition - arrowWidth / 2;
    const arrowDiff = this.props.timelineRefWidth - (arrowWidth + arrowRelativeLeft);
    if (arrowDiff < 0) {
      const updatedWidth = arrowWidth + arrowDiff;
      this.arrowRefElement.style.borderLeftWidth = `${updatedWidth / 2}px`;
      this.arrowRefElement.style.borderRightWidth = `${updatedWidth / 2}px`;
      this.tooltipRefElement.style.borderBottomRightRadius = `0px`;
    }
    this.arrowRefElement.style.bottom = `${bottomPosition}px`;
    this.arrowRefElement.style.left = `${arrowRelativeLeft}px`;
  }

  toSmpteTimecode = (time: number) => {
    const {frameRate, dropFrame} = this.props.frameRate;
    return utils.formatting.getSmpteTimecode(time, frameRate, dropFrame);
  };

  render() {
    const {title, start, end, content, comment} = this.props.data;
    const showEndTime = (end || end === 0) && start !== end;
    const showStartTime = start || start === 0 ? true : false;
    const tooltipAvatar = comment && comment.avatar;
    const tooltipTitle = title || (comment && comment.displayName);
    const commentDate = comment ? comment.createdAt : null;
    return (
      <>
        <div
          className="timeline-tooltip-container_content"
          ref={node => (this.tooltipRefElement = node)}
          onMouseEnter={this.props.onTooltipIn}
          onMouseLeave={this.props.onTooltipOut}
        >
          <div className="timeline-tooltip-container_content_loop" onClick={this.props.onLoop}>
            <Icon icon={ReplayIcon} color="#000" size="12px" />
          </div>
          <div style={{display: 'flex'}}>
            {tooltipAvatar && (
              <div className="timeline-tooltip-container_content_avatar">
                <img src={`${this.props.oneUiHost}${tooltipAvatar}`} />
              </div>
            )}
            <div className="timeline-tooltip-container_content_info-panel">
              {tooltipTitle && (
                <div className="timeline-tooltip-container_content_info-panel_title" title={tooltipTitle}>
                  {tooltipTitle}
                </div>
              )}
              {(showStartTime || showEndTime) && (
                <div className="timeline-tooltip-container_content_info-panel_interval">
                  {showStartTime && (
                    <div className="timeline-tooltip-container_content_info-panel_interval_time">
                      {this.toSmpteTimecode(start)}
                    </div>
                  )}
                  {showStartTime && showEndTime && (
                    <div className="timeline-tooltip-container_content_info-panel_interval_time">&nbsp;-&nbsp;</div>
                  )}
                  {showEndTime && (
                    <div className="timeline-tooltip-container_content_info-panel_interval_time">
                      {this.toSmpteTimecode(end)}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {showStartTime && showEndTime && !comment && (
            <div className="timeline-tooltip-container_content_duration">
              Duration: {this.toSmpteTimecode(end - start)}
            </div>
          )}
          {commentDate && (
            <div className="timeline-tooltip-container_content_comment-date">{formatCommentTime(commentDate)}</div>
          )}
          {content && <div className="timeline-tooltip-container_content_content">{content}</div>}
        </div>
        <div className="timeline-tooltip-container_arrow" ref={node => (this.arrowRefElement = node)} />
      </>
    );
  }
}
