import * as React from 'react';
import {TimelineRow} from '../TimelineRow';
import {ScaleLabel} from './components/ScaleLabel';
import {ScaleContent} from './components/ScaleContent';
import {TmpElement} from '../TmpElement';
import {IFrameRate} from 'tt-components';
import {ITemplateColors} from '../../../../../@types/templateColors';
import {ILayersVisisbility, ITimelineLayers} from '../../interfaces';

interface ITimelineScaleProps {
  contentRef?: (node: HTMLDivElement) => void;
  onIncrease: () => void;
  onDecrease: () => void;
  disableDecreaseScale?: boolean;
  scale: number;
  scrollLeft: number;
  secondUnits: number;
  contentWidth: number;
  duration: number;
  frameRate: IFrameRate;
  templateColors: ITemplateColors;
  layersVisibility: ILayersVisisbility;
  onUpdateLayerVisibility: (layer: ITimelineLayers, visible: boolean) => void;
  disableTimeline?: boolean;
}

export class TimelineScale extends React.Component<ITimelineScaleProps> {
  getRef = (node: HTMLDivElement) => {
    if (this.props.contentRef) {
      this.props.contentRef(node);
    }
  };

  render() {
    const ScaleLabelElement = (
      <ScaleLabel
        onIncrease={this.props.onIncrease}
        onDecrease={this.props.onDecrease}
        disableDecreaseScale={this.props.disableDecreaseScale}
        disableTimeline={this.props.disableTimeline}
        scale={this.props.scale}
        templateColors={this.props.templateColors}
        layersVisibility={this.props.layersVisibility}
        onUpdateLayerVisibility={this.props.onUpdateLayerVisibility}
      />
    );
    const ScaleContentElement = this.props.duration ? (
      <ScaleContent
        secondUnits={this.props.secondUnits}
        scrollLeft={this.props.scrollLeft}
        width={this.props.contentWidth}
        duration={this.props.duration}
        frameRate={this.props.frameRate}
        scale={this.props.scale}
      />
    ) : (
      <TmpElement text="Calculating Timeline scale" />
    );

    return (
      <TimelineRow
        className="timelinescale-container"
        labelElement={ScaleLabelElement}
        contentElement={ScaleContentElement}
        contentRef={this.getRef}
      />
    );
  }
}
