import * as React from 'react';
import {Playback} from '../Playback';
import {ITemplateColors} from '../../../../@types/templateColors';
import Button from '../../CustomButton';

interface IForceStagingProps {
  templateColors: ITemplateColors;
  onCancel?: (forcingStaging: boolean) => void;
  onForce?: () => void;
}

const createContent = (props: IForceStagingProps) => {
  return (
    <>
      <span style={{color: props.templateColors.main, fontSize: 15}}>
        Playback failed playing. Do you want to force staging process again?
      </span>
      <div className="force-staging-overlay_buttons">
        <Button
          className="force-staging-overlay_buttons_force"
          label="Force"
          onClick={() => (props.onForce ? props.onForce() : null)}
        />
        <Button
          className="force-staging-overlay_buttons_cancel"
          label="Cancel"
          onClick={() => (props.onCancel ? props.onCancel(false) : null)}
        />
      </div>
    </>
  );
};

export const ForceStaging = (props: IForceStagingProps) => (
  <Playback
    hideIcon={true}
    className="force-staging-overlay"
    templateColors={props.templateColors}
    descriptionContent={createContent(props)}
  />
);
