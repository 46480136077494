import * as React from 'react';
import {Collapsible} from '../../../Collapsible';
import {TitleInfo} from './components/TitleInfo';
import {AssetInfo} from './components/AssetInfo';
import {IAssetDetails, IErrorLog} from '../../../../../../../@types/assetDetails';
import {IEnum} from '../../../../../../../@types/enum';
import {deepCopy, getTitleInfo} from '../../../../utils/helpers';
import {ISearchTitle} from '../../../../../../../@types/searchTitle';
import {PlaylistAsset} from '../../../../../../models/PlaylistAsset/PlaylistAsset';
import {IMetadataError} from '../../../../../../../@types/metadataErrors';

interface IDetailsProps {
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  updatedAssetDetails: Partial<IAssetDetails>;
  selectedAsset: PlaylistAsset;
  assetStatusEnums: Array<IEnum>;
  fileWrapperEnums: Array<IEnum>;
  functionEnums: Array<IEnum>;
  contentTypeEnums: Array<IEnum>;
  formatComplianceEnums: Array<IEnum>;
  frameRateEnums: Array<IEnum>;
  referencesNameEnums: Array<IEnum>;
  referencesTypeEnums: Array<IEnum>;
  closestBody?: HTMLElement;
  tabsContainer?: HTMLElement;
  tabsDataInEditMode: boolean;
  curationModeEnabled: boolean;
  detailsMetadataErrors: Array<IMetadataError>;
  errorLogs: Array<IErrorLog>;
}

interface IDetailsState {
  openTitleInfo: boolean;
  openAssetInfo: boolean;
}

const updateId = (suffix: 'reference' | 'distributor') => (data: {[x: string]: any}, index: number) => {
  return {id: `${index}-${suffix}`, ...data};
};

export class Details extends React.PureComponent<IDetailsProps, IDetailsState> {
  constructor(props) {
    super(props);

    this.state = {
      openTitleInfo: false,
      openAssetInfo: false
    };
  }

  componentDidUpdate(prevProps: IDetailsProps) {
    // Close collapsible components if selected asset is disabled
    if (!!prevProps.selectedAsset && !this.props.selectedAsset) {
      this.setState({openTitleInfo: false, openAssetInfo: false});
    }
  }

  getTitleData = () => {
    const assetDetails = deepCopy({...this.getAssetData()});
    const titles = (assetDetails.titles || []).map(PlaylistAsset.parsing.parseSearchTitle);
    console.log('Titles from assetDetails', assetDetails.titles);
    const credentials = PlaylistAsset.parsing.parseCredentialsFromTitles(titles);
    console.log('Credentials', credentials);
    return getTitleInfo(credentials);
  };

  isRegistered = () => {
    return this.props.selectedAsset ? this.props.selectedAsset.isRegistered : true;
  };

  getAssetData = () => {
    const updatedAssetDetails = this.props.updatedAssetDetails || {};
    const currentAssetDetails =
      this.props.selectedAsset && this.props.selectedAsset.assetDetails
        ? deepCopy({...this.props.selectedAsset.assetDetails, ...updatedAssetDetails})
        : {};
    if (currentAssetDetails.references) {
      currentAssetDetails.references = currentAssetDetails.references.map(updateId('reference'));
    }

    if (currentAssetDetails.distributors) {
      currentAssetDetails.distributors = currentAssetDetails.distributors.map(updateId('distributor'));
    }
    return currentAssetDetails;
  };

  onTitleUpdate = (updatedTitles: Array<ISearchTitle>) => {
    const assetDetails = deepCopy({...this.getAssetData()});
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const isTitleUpdate = Array.isArray(updatedTitles)
      ? updatedTitles.some((title: ISearchTitle) => ['Feature', 'Episode'].indexOf(title.type) !== -1)
      : false;
    console.log('Added titles', updatedTitles, assetDetails.titles);
    const titles = isTitleUpdate
      ? [...(updatedTitles as Array<ISearchTitle>)]
      : [...(assetDetails.titles || [])]
          .reduce((acc: Array<ISearchTitle>, title: ISearchTitle) => {
            const matchType = updatedTitles.find((record: ISearchTitle) => record.type === title.type);
            if (matchType) {
              return [...acc, matchType];
            }
            return [...acc, title];
          }, [])
          .filter((title: ISearchTitle) => title.id);
    if (!isTitleUpdate) {
      updatedTitles.forEach((title: ISearchTitle) => {
        const isDefined = titles.find((record: ISearchTitle) => record.type === title.type);
        if (!isDefined) {
          titles.push(title);
        }
      });
    }
    console.log('Updated titles', titles);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, titles});
  };

  onAssetUpdate = (field: string, value: any) => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    console.log(field, value);
    const updated = {...updatedAssetDetails, [field]: value};
    this.props.updatePartialAssetDetails(updated);

    console.log('Update assetDetails', updated);
  };

  getAssetInfoComponent = () => (
    <AssetInfo
      isAssetRegistered={this.isRegistered()}
      assetDetails={this.getAssetData()}
      assetStatusEnums={this.props.assetStatusEnums}
      fileWrapperEnums={this.props.fileWrapperEnums}
      functionEnums={this.props.functionEnums}
      contentTypeEnums={this.props.contentTypeEnums}
      formatComplianceEnums={this.props.formatComplianceEnums}
      frameRateEnums={this.props.frameRateEnums}
      referencesNameEnums={this.props.referencesNameEnums}
      referencesTypeEnums={this.props.referencesTypeEnums}
      onAssetUpdate={this.onAssetUpdate}
      closestBody={this.props.closestBody}
      disabled={!this.props.tabsDataInEditMode}
      errors={this.props.detailsMetadataErrors}
      errorLogs={this.props.errorLogs}
    />
  );

  getTitleInfoComponent = () => {
    const {versionId, conformanceGroupId, titleId} = this.getTitleData();
    const disabled = this.isRegistered() || !this.props.tabsDataInEditMode || !this.props.curationModeEnabled;
    console.log('Title Info data', versionId, conformanceGroupId, titleId);
    return (
      <TitleInfo
        assetId={this.props.selectedAsset ? this.props.selectedAsset.assetId : null}
        versionId={versionId}
        conformanceGroupId={conformanceGroupId}
        titleId={titleId}
        closestBody={this.props.closestBody}
        disabled={disabled}
        onTitleInfoUpated={this.onTitleUpdate}
        tabsContainer={this.props.tabsContainer}
        errors={this.props.detailsMetadataErrors}
      />
    );
  };

  onTitleToggle = (openTitleInfo: boolean) => {
    if (!this.props.selectedAsset) {
      return;
    }
    this.setState({openTitleInfo});
  };

  onAssetToggle = (openAssetInfo: boolean) => {
    if (!this.props.selectedAsset) {
      return;
    }
    this.setState({openAssetInfo});
  };

  getGeneralError = () => {
    const error = this.props.detailsMetadataErrors.find(
      (error: IMetadataError) => error.fieldName === 'AssetRegistrationPatch'
    );
    return error ? <div className="details-container_error">! {error.message}</div> : null;
  };

  render() {
    return (
      <div className="details-container">
        {this.getGeneralError()}
        <Collapsible
          open={this.state.openTitleInfo}
          title="Title Info"
          content={this.getTitleInfoComponent()}
          onToggle={this.onTitleToggle}
        />
        <Collapsible
          open={this.state.openAssetInfo}
          title="Asset Info"
          content={this.getAssetInfoComponent()}
          onToggle={this.onAssetToggle}
        />
      </div>
    );
  }
}
