import * as React from 'react';
import {SubtitleRadio} from '../../../../../../components/SubtitleRadio';
import {ITemplateColors} from '../../../../../../../@types/templateColors';
import {ISubtitlesMetadata} from '../../../../../../../@types/subtitlesMetadata';
import {IEnum} from '../../../../../../../@types/enum';

interface ISubtitlesLabelProps {
  subtitle: ISubtitlesMetadata;
  selectedSubtitleId: string;
  languageDialect: Array<IEnum>;
  onSelected?: (subtitleId: number | string) => void;
  templateColors: ITemplateColors;
}

export class SubtitlesLabel extends React.Component<ISubtitlesLabelProps> {
  onSubtitleSelected = (subtitleId: string) => {
    if (this.props.onSelected) {
      this.props.onSelected(subtitleId);
    }
  };

  render() {
    return (
      <div className="subtitleslayer-label">
        <div className="subtitleslayer-label_radio">
          <SubtitleRadio
            languageDialect={this.props.languageDialect}
            templateColors={this.props.templateColors}
            selectedSubId={this.props.selectedSubtitleId}
            subtitle={this.props.subtitle}
            updateSelectedSubtitle={this.onSubtitleSelected}
            identifier="timeline-instance"
            reverse
          />
        </div>
      </div>
    );
  }
}
