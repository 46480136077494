import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const FrameAdvanceIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      viewBox="0 0 48 41"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-946.000000, -1768.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-frame-advance" transform="translate(524.000000, 14.000000)">
                  <g>
                    <path
                      d="M44.4156379,17.7991472 L15.4650206,0.455091831 C14.4087791,-0.151697277 
                    13.3525377,-0.151697277 12.3923182,0.455091831 C11.4320988,1.06188094 11,2.17432764 
                    11,3.28677433 L11,38.0760165 C11,39.1884632 11.5761317,40.3009099 12.3923182,40.907699 
                    C12.872428,41.1605278 13.4485597,41.4133566 14.0246914,41.4133566 C14.600823,41.4133566 
                    15.0809328,41.1605278 15.4170096,40.907699 L44.3676269,23.5636437 C45.4238683,22.9568546 
                    46,21.8444079 46,20.7319612 C46,19.6195145 45.4718793,18.5576335 44.4156379,17.7991472 Z"
                      id="Shape"
                      fillRule="nonzero"
                    />
                    <rect id="Rectangle-5" x="0" y="0" width="7" height="41.4133566" rx="3" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
