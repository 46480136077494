export const subsLanguages = [
  {
    'ISO 639-2/5': 'aar',
    'Language name': 'Afar'
  },
  {
    'ISO 639-2/5': 'aav',
    'Language name': 'Austro-Asiatic languages'
  },
  {
    'ISO 639-2/5': 'abk',
    'Language name': 'Abkhazian'
  },
  {
    'ISO 639-2/5': 'ace',
    'Language name': 'Achinese'
  },
  {
    'ISO 639-2/5': 'ach',
    'Language name': 'Acoli'
  },
  {
    'ISO 639-2/5': 'ada',
    'Language name': 'Adangme'
  },
  {
    'ISO 639-2/5': 'ady',
    'Language name': 'Adyghe'
  },
  {
    'ISO 639-2/5': 'afa',
    'Language name': 'Afro-Asiatic languages'
  },
  {
    'ISO 639-2/5': 'afh',
    'Language name': 'Afrihili'
  },
  {
    'ISO 639-2/5': 'afr',
    'Language name': 'Afrikaans'
  },
  {
    'ISO 639-2/5': 'ain',
    'Language name': 'Ainu (Japan)'
  },
  {
    'ISO 639-2/5': 'aka',
    'Language name': 'Akan'
  },
  {
    'ISO 639-2/5': 'akk',
    'Language name': 'Akkadian'
  },
  {
    'ISO 639-2/5': 'alb* / sqi',
    'Language name': 'Albanian'
  },
  {
    'ISO 639-2/5': 'ale',
    'Language name': 'Aleut'
  },
  {
    'ISO 639-2/5': 'alg',
    'Language name': 'Algonquian languages'
  },
  {
    'ISO 639-2/5': 'alt',
    'Language name': 'Southern Altai'
  },
  {
    'ISO 639-2/5': 'alv',
    'Language name': 'Atlantic-Congo languages'
  },
  {
    'ISO 639-2/5': 'amh',
    'Language name': 'Amharic'
  },
  {
    'ISO 639-2/5': 'ang',
    'Language name': 'Old English (c. 450–1100)'
  },
  {
    'ISO 639-2/5': 'anp',
    'Language name': 'Angika'
  },
  {
    'ISO 639-2/5': 'apa',
    'Language name': 'Apache languages'
  },
  {
    'ISO 639-2/5': 'aqa',
    'Language name': 'Alacalufan languages'
  },
  {
    'ISO 639-2/5': 'aql',
    'Language name': 'Algic languages'
  },
  {
    'ISO 639-2/5': 'ara',
    'Language name': 'Arabic'
  },
  {
    'ISO 639-2/5': 'arc',
    'Language name': 'Official Aramaic (700–300 BCE)'
  },
  {
    'ISO 639-2/5': 'arg',
    'Language name': 'Aragonese'
  },
  {
    'ISO 639-2/5': 'arm* / hye',
    'Language name': 'Armenian'
  },
  {
    'ISO 639-2/5': 'arn',
    'Language name': 'Mapudungun'
  },
  {
    'ISO 639-2/5': 'arp',
    'Language name': 'Arapaho'
  },
  {
    'ISO 639-2/5': 'art',
    'Language name': 'Artificial languages'
  },
  {
    'ISO 639-2/5': 'arw',
    'Language name': 'Arawak'
  },
  {
    'ISO 639-2/5': 'asm',
    'Language name': 'Assamese'
  },
  {
    'ISO 639-2/5': 'ast',
    'Language name': 'Asturian'
  },
  {
    'ISO 639-2/5': 'ath',
    'Language name': 'Athapascan languages'
  },
  {
    'ISO 639-2/5': 'auf',
    'Language name': 'Arauan languages'
  },
  {
    'ISO 639-2/5': 'aus',
    'Language name': 'Australian languages'
  },
  {
    'ISO 639-2/5': 'ava',
    'Language name': 'Avaric'
  },
  {
    'ISO 639-2/5': 'ave',
    'Language name': 'Avestan'
  },
  {
    'ISO 639-2/5': 'awa',
    'Language name': 'Awadhi'
  },
  {
    'ISO 639-2/5': 'awd',
    'Language name': 'Arawakan languages'
  },
  {
    'ISO 639-2/5': 'aym',
    'Language name': 'Aymara'
  },
  {
    'ISO 639-2/5': 'azc',
    'Language name': 'Uto-Aztecan languages'
  },
  {
    'ISO 639-2/5': 'aze',
    'Language name': 'Azerbaijani'
  },
  {
    'ISO 639-2/5': 'bad',
    'Language name': 'Banda languages'
  },
  {
    'ISO 639-2/5': 'bai',
    'Language name': 'Bamileke languages'
  },
  {
    'ISO 639-2/5': 'bak',
    'Language name': 'Bashkir'
  },
  {
    'ISO 639-2/5': 'bal',
    'Language name': 'Baluchi'
  },
  {
    'ISO 639-2/5': 'bam',
    'Language name': 'Bambara'
  },
  {
    'ISO 639-2/5': 'ban',
    'Language name': 'Balinese'
  },
  {
    'ISO 639-2/5': 'baq* / eus',
    'Language name': 'Basque'
  },
  {
    'ISO 639-2/5': 'bas',
    'Language name': 'Basa (Cameroon)'
  },
  {
    'ISO 639-2/5': 'bat',
    'Language name': 'Baltic languages'
  },
  {
    'ISO 639-2/5': 'bej',
    'Language name': 'Beja'
  },
  {
    'ISO 639-2/5': 'bel',
    'Language name': 'Belarusian'
  },
  {
    'ISO 639-2/5': 'bem',
    'Language name': 'Bemba (Zambia)'
  },
  {
    'ISO 639-2/5': 'ben',
    'Language name': 'Bengali'
  },
  {
    'ISO 639-2/5': 'ber',
    'Language name': 'Berber languages'
  },
  {
    'ISO 639-2/5': 'bho',
    'Language name': 'Bhojpuri'
  },
  {
    'ISO 639-2/5': 'bih',
    'Language name': 'Bihari languages'
  },
  {
    'ISO 639-2/5': 'bik',
    'Language name': 'Bikol'
  },
  {
    'ISO 639-2/5': 'bin',
    'Language name': 'Bini'
  },
  {
    'ISO 639-2/5': 'bis',
    'Language name': 'Bislama'
  },
  {
    'ISO 639-2/5': 'bla',
    'Language name': 'Siksika'
  },
  {
    'ISO 639-2/5': 'bnt',
    'Language name': 'Bantu languages'
  },
  {
    'ISO 639-2/5': 'bod / tib*',
    'Language name': 'Tibetan'
  },
  {
    'ISO 639-2/5': 'bos',
    'Language name': 'Bosnian'
  },
  {
    'ISO 639-2/5': 'bra',
    'Language name': 'Braj'
  },
  {
    'ISO 639-2/5': 'bre',
    'Language name': 'Breton'
  },
  {
    'ISO 639-2/5': 'btk',
    'Language name': 'Batak languages'
  },
  {
    'ISO 639-2/5': 'bua',
    'Language name': 'Buriat'
  },
  {
    'ISO 639-2/5': 'bug',
    'Language name': 'Buginese'
  },
  {
    'ISO 639-2/5': 'bul',
    'Language name': 'Bulgarian'
  },
  {
    'ISO 639-2/5': 'bur* / mya',
    'Language name': 'Burmese'
  },
  {
    'ISO 639-2/5': 'byn',
    'Language name': 'Bilin'
  },
  {
    'ISO 639-2/5': 'cad',
    'Language name': 'Caddo'
  },
  {
    'ISO 639-2/5': 'cai',
    'Language name': 'Central American Indian languages'
  },
  {
    'ISO 639-2/5': 'car',
    'Language name': 'Galibi Carib'
  },
  {
    'ISO 639-2/5': 'cat',
    'Language name': 'Catalan'
  },
  {
    'ISO 639-2/5': 'cau',
    'Language name': 'Caucasian languages'
  },
  {
    'ISO 639-2/5': 'cba',
    'Language name': 'Chibchan languages'
  },
  {
    'ISO 639-2/5': 'ccn',
    'Language name': 'North Caucasian languages'
  },
  {
    'ISO 639-2/5': 'ccs',
    'Language name': 'South Caucasian languages'
  },
  {
    'ISO 639-2/5': 'cdc',
    'Language name': 'Chadic languages'
  },
  {
    'ISO 639-2/5': 'cdd',
    'Language name': 'Caddoan languages'
  },
  {
    'ISO 639-2/5': 'ceb',
    'Language name': 'Cebuano'
  },
  {
    'ISO 639-2/5': 'cel',
    'Language name': 'Celtic languages'
  },
  {
    'ISO 639-2/5': 'ces / cze*',
    'Language name': 'Czech'
  },
  {
    'ISO 639-2/5': 'cha',
    'Language name': 'Chamorro'
  },
  {
    'ISO 639-2/5': 'chb',
    'Language name': 'Chibcha'
  },
  {
    'ISO 639-2/5': 'che',
    'Language name': 'Chechen'
  },
  {
    'ISO 639-2/5': 'chg',
    'Language name': 'Chagatai'
  },
  {
    'ISO 639-2/5': 'chi* / zho',
    'Language name': 'Chinese'
  },
  {
    'ISO 639-2/5': 'chk',
    'Language name': 'Chuukese'
  },
  {
    'ISO 639-2/5': 'chm',
    'Language name': 'Mari (Russia)'
  },
  {
    'ISO 639-2/5': 'chn',
    'Language name': 'Chinook jargon'
  },
  {
    'ISO 639-2/5': 'cho',
    'Language name': 'Choctaw'
  },
  {
    'ISO 639-2/5': 'chp',
    'Language name': 'Chipewyan'
  },
  {
    'ISO 639-2/5': 'chr',
    'Language name': 'Cherokee'
  },
  {
    'ISO 639-2/5': 'chu',
    'Language name': 'Church Slavic'
  },
  {
    'ISO 639-2/5': 'chv',
    'Language name': 'Chuvash'
  },
  {
    'ISO 639-2/5': 'chy',
    'Language name': 'Cheyenne'
  },
  {
    'ISO 639-2/5': 'cmc',
    'Language name': 'Chamic languages'
  },
  {
    'ISO 639-2/5': 'cop',
    'Language name': 'Coptic'
  },
  {
    'ISO 639-2/5': 'cor',
    'Language name': 'Cornish'
  },
  {
    'ISO 639-2/5': 'cos',
    'Language name': 'Corsican'
  },
  {
    'ISO 639-2/5': 'cpe',
    'Language name': 'English based Creoles and pidgins'
  },
  {
    'ISO 639-2/5': 'cpf',
    'Language name': 'French-Based Creoles and pidgins'
  },
  {
    'ISO 639-2/5': 'cpp',
    'Language name': 'Portuguese-Based Creoles and pidgins'
  },
  {
    'ISO 639-2/5': 'cre',
    'Language name': 'Cree'
  },
  {
    'ISO 639-2/5': 'crh',
    'Language name': 'Crimean Tatar'
  },
  {
    'ISO 639-2/5': 'crp',
    'Language name': 'Creoles and pidgins'
  },
  {
    'ISO 639-2/5': 'csb',
    'Language name': 'Kashubian'
  },
  {
    'ISO 639-2/5': 'csu',
    'Language name': 'Central Sudanic languages'
  },
  {
    'ISO 639-2/5': 'cus',
    'Language name': 'Cushitic languages'
  },
  {
    'ISO 639-2/5': 'cym / wel*',
    'Language name': 'Welsh'
  },
  {
    'ISO 639-2/5': 'cze* / ces',
    'Language name': 'Czech'
  },
  {
    'ISO 639-2/5': 'dak',
    'Language name': 'Dakota'
  },
  {
    'ISO 639-2/5': 'dan',
    'Language name': 'Danish'
  },
  {
    'ISO 639-2/5': 'dar',
    'Language name': 'Dargwa'
  },
  {
    'ISO 639-2/5': 'day',
    'Language name': 'Land Dayak languages'
  },
  {
    'ISO 639-2/5': 'del',
    'Language name': 'Delaware'
  },
  {
    'ISO 639-2/5': 'den',
    'Language name': 'Slave (Athapascan)'
  },
  {
    'ISO 639-2/5': 'deu / ger*',
    'Language name': 'German'
  },
  {
    'ISO 639-2/5': 'dgr',
    'Language name': 'Dogrib'
  },
  {
    'ISO 639-2/5': 'din',
    'Language name': 'Dinka'
  },
  {
    'ISO 639-2/5': 'div',
    'Language name': 'Dhivehi'
  },
  {
    'ISO 639-2/5': 'dmn',
    'Language name': 'Mande languages'
  },
  {
    'ISO 639-2/5': 'doi',
    'Language name': 'Dogri (macrolanguage)'
  },
  {
    'ISO 639-2/5': 'dra',
    'Language name': 'Dravidian languages'
  },
  {
    'ISO 639-2/5': 'dsb',
    'Language name': 'Lower Sorbian'
  },
  {
    'ISO 639-2/5': 'dua',
    'Language name': 'Duala'
  },
  {
    'ISO 639-2/5': 'dum',
    'Language name': 'Middle Dutch (c. 1050–1350)'
  },
  {
    'ISO 639-2/5': 'dut* / nld',
    'Language name': 'Dutch'
  },
  {
    'ISO 639-2/5': 'dyu',
    'Language name': 'Dyula'
  },
  {
    'ISO 639-2/5': 'dzo',
    'Language name': 'Dzongkha'
  },
  {
    'ISO 639-2/5': 'efi',
    'Language name': 'Efik'
  },
  {
    'ISO 639-2/5': 'egx',
    'Language name': 'Egyptian languages'
  },
  {
    'ISO 639-2/5': 'egy',
    'Language name': 'Egyptian (Ancient)'
  },
  {
    'ISO 639-2/5': 'eka',
    'Language name': 'Ekajuk'
  },
  {
    'ISO 639-2/5': 'elx',
    'Language name': 'Elamite'
  },
  {
    'ISO 639-2/5': 'eng',
    'Language name': 'English'
  },
  {
    'ISO 639-2/5': 'enm',
    'Language name': 'Middle English (1100-1500)'
  },
  {
    'ISO 639-2/5': 'epo',
    'Language name': 'Esperanto'
  },
  {
    'ISO 639-2/5': 'est',
    'Language name': 'Estonian'
  },
  {
    'ISO 639-2/5': 'esx',
    'Language name': 'Eskimo-Aleut languages'
  },
  {
    'ISO 639-2/5': 'euq',
    'Language name': 'Basque (family)'
  },
  {
    'ISO 639-2/5': 'eus / baq*',
    'Language name': 'Basque'
  },
  {
    'ISO 639-2/5': 'ewe',
    'Language name': 'Ewe'
  },
  {
    'ISO 639-2/5': 'ewo',
    'Language name': 'Ewondo'
  },
  {
    'ISO 639-2/5': 'fan',
    'Language name': 'Fang (Equatorial Guinea)'
  },
  {
    'ISO 639-2/5': 'fao',
    'Language name': 'Faroese'
  },
  {
    'ISO 639-2/5': 'fas / per*',
    'Language name': 'Persian'
  },
  {
    'ISO 639-2/5': 'fat',
    'Language name': 'Fanti'
  },
  {
    'ISO 639-2/5': 'fij',
    'Language name': 'Fijian'
  },
  {
    'ISO 639-2/5': 'fil',
    'Language name': 'Filipino'
  },
  {
    'ISO 639-2/5': 'fin',
    'Language name': 'Finnish'
  },
  {
    'ISO 639-2/5': 'fiu',
    'Language name': 'Finno-Ugrian languages'
  },
  {
    'ISO 639-2/5': 'fon',
    'Language name': 'Fon'
  },
  {
    'ISO 639-2/5': 'fox',
    'Language name': 'Formosan languages'
  },
  {
    'ISO 639-2/5': 'fra / fre*',
    'Language name': 'French'
  },
  {
    'ISO 639-2/5': 'fre* / fra',
    'Language name': 'French'
  },
  {
    'ISO 639-2/5': 'frm',
    'Language name': 'Middle French (c. 1400–1600)'
  },
  {
    'ISO 639-2/5': 'fro',
    'Language name': 'Old French (842–c. 1400)'
  },
  {
    'ISO 639-2/5': 'frr',
    'Language name': 'Northern Frisian'
  },
  {
    'ISO 639-2/5': 'frs',
    'Language name': 'Eastern Frisian'
  },
  {
    'ISO 639-2/5': 'fry',
    'Language name': 'Western Frisian'
  },
  {
    'ISO 639-2/5': 'ful',
    'Language name': 'Fulah'
  },
  {
    'ISO 639-2/5': 'fur',
    'Language name': 'Friulian'
  },
  {
    'ISO 639-2/5': 'gaa',
    'Language name': 'Ga'
  },
  {
    'ISO 639-2/5': 'gay',
    'Language name': 'Gayo'
  },
  {
    'ISO 639-2/5': 'gba',
    'Language name': 'Gbaya (Central African Republic)'
  },
  {
    'ISO 639-2/5': 'gem',
    'Language name': 'Germanic languages'
  },
  {
    'ISO 639-2/5': 'geo* / kat',
    'Language name': 'Georgian'
  },
  {
    'ISO 639-2/5': 'ger* / deu',
    'Language name': 'German'
  },
  {
    'ISO 639-2/5': 'gez',
    'Language name': "Ge'ez"
  },
  {
    'ISO 639-2/5': 'gil',
    'Language name': 'Gilbertese'
  },
  {
    'ISO 639-2/5': 'gla',
    'Language name': 'Scottish Gaelic'
  },
  {
    'ISO 639-2/5': 'gle',
    'Language name': 'Irish'
  },
  {
    'ISO 639-2/5': 'glg',
    'Language name': 'Galician'
  },
  {
    'ISO 639-2/5': 'glv',
    'Language name': 'Manx'
  },
  {
    'ISO 639-2/5': 'gme',
    'Language name': 'East Germanic languages'
  },
  {
    'ISO 639-2/5': 'gmh',
    'Language name': 'Middle High German (c. 1050–1500)'
  },
  {
    'ISO 639-2/5': 'gmq',
    'Language name': 'North Germanic languages'
  },
  {
    'ISO 639-2/5': 'gmw',
    'Language name': 'West Germanic languages'
  },
  {
    'ISO 639-2/5': 'goh',
    'Language name': 'Old High German (c. 750–1050)'
  },
  {
    'ISO 639-2/5': 'gon',
    'Language name': 'Gondi'
  },
  {
    'ISO 639-2/5': 'gor',
    'Language name': 'Gorontalo'
  },
  {
    'ISO 639-2/5': 'got',
    'Language name': 'Gothic'
  },
  {
    'ISO 639-2/5': 'grb',
    'Language name': 'Grebo'
  },
  {
    'ISO 639-2/5': 'grc',
    'Language name': 'Ancient Greek (to 1453)'
  },
  {
    'ISO 639-2/5': 'gre* / ell',
    'Language name': 'Modern Greek (1453-)'
  },
  {
    'ISO 639-2/5': 'grk',
    'Language name': 'Greek languages'
  },
  {
    'ISO 639-2/5': 'grn',
    'Language name': 'Guarani'
  },
  {
    'ISO 639-2/5': 'gsw',
    'Language name': 'Swiss German'
  },
  {
    'ISO 639-2/5': 'guj',
    'Language name': 'Gujarati'
  },
  {
    'ISO 639-2/5': 'gwi',
    'Language name': 'Gwichʼin'
  },
  {
    'ISO 639-2/5': 'hai',
    'Language name': 'Haida'
  },
  {
    'ISO 639-2/5': 'hat',
    'Language name': 'Haitian'
  },
  {
    'ISO 639-2/5': 'hau',
    'Language name': 'Hausa'
  },
  {
    'ISO 639-2/5': 'haw',
    'Language name': 'Hawaiian'
  },
  {
    'ISO 639-2/5': 'heb',
    'Language name': 'Hebrew'
  },
  {
    'ISO 639-2/5': 'her',
    'Language name': 'Herero'
  },
  {
    'ISO 639-2/5': 'hil',
    'Language name': 'Hiligaynon'
  },
  {
    'ISO 639-2/5': 'him',
    'Language name': 'Himachali languages'
  },
  {
    'ISO 639-2/5': 'hin',
    'Language name': 'Hindi'
  },
  {
    'ISO 639-2/5': 'hit',
    'Language name': 'Hittite'
  },
  {
    'ISO 639-2/5': 'hmn',
    'Language name': 'Hmong'
  },
  {
    'ISO 639-2/5': 'hmo',
    'Language name': 'Hiri Motu'
  },
  {
    'ISO 639-2/5': 'hmx',
    'Language name': 'Hmong-Mien languages'
  },
  {
    'ISO 639-2/5': 'hok',
    'Language name': 'Hokan languages'
  },
  {
    'ISO 639-2/5': 'hrv',
    'Language name': 'Croatian'
  },
  {
    'ISO 639-2/5': 'hsb',
    'Language name': 'Upper Sorbian'
  },
  {
    'ISO 639-2/5': 'hun',
    'Language name': 'Hungarian'
  },
  {
    'ISO 639-2/5': 'hup',
    'Language name': 'Hupa'
  },
  {
    'ISO 639-2/5': 'hye / arm*',
    'Language name': 'Armenian'
  },
  {
    'ISO 639-2/5': 'hyx',
    'Language name': 'Armenian (family)'
  },
  {
    'ISO 639-2/5': 'iba',
    'Language name': 'Iban'
  },
  {
    'ISO 639-2/5': 'ibo',
    'Language name': 'Igbo'
  },
  {
    'ISO 639-2/5': 'ice* / isl',
    'Language name': 'Icelandic'
  },
  {
    'ISO 639-2/5': 'ido',
    'Language name': 'Ido'
  },
  {
    'ISO 639-2/5': 'iii',
    'Language name': 'Sichuan Yi'
  },
  {
    'ISO 639-2/5': 'iir',
    'Language name': 'Indo-Iranian languages'
  },
  {
    'ISO 639-2/5': 'ijo',
    'Language name': 'Ijo languages'
  },
  {
    'ISO 639-2/5': 'iku',
    'Language name': 'Inuktitut'
  },
  {
    'ISO 639-2/5': 'ile',
    'Language name': 'Interlingue'
  },
  {
    'ISO 639-2/5': 'ilo',
    'Language name': 'Iloko'
  },
  {
    'ISO 639-2/5': 'ina',
    'Language name': 'Interlingua (International Auxiliary Language Association)'
  },
  {
    'ISO 639-2/5': 'inc',
    'Language name': 'Indic languages'
  },
  {
    'ISO 639-2/5': 'ind',
    'Language name': 'Indonesian'
  },
  {
    'ISO 639-2/5': 'ine',
    'Language name': 'Indo-European languages'
  },
  {
    'ISO 639-2/5': 'inh',
    'Language name': 'Ingush'
  },
  {
    'ISO 639-2/5': 'ipk',
    'Language name': 'Inupiaq'
  },
  {
    'ISO 639-2/5': 'ira',
    'Language name': 'Iranian languages'
  },
  {
    'ISO 639-2/5': 'iro',
    'Language name': 'Iroquoian languages'
  },
  {
    'ISO 639-2/5': 'isl / ice*',
    'Language name': 'Icelandic'
  },
  {
    'ISO 639-2/5': 'ita',
    'Language name': 'Italian'
  },
  {
    'ISO 639-2/5': 'itc',
    'Language name': 'Italic languages'
  },
  {
    'ISO 639-2/5': 'jav',
    'Language name': 'Javanese'
  },
  {
    'ISO 639-2/5': 'jbo',
    'Language name': 'Lojban'
  },
  {
    'ISO 639-2/5': 'jpn',
    'Language name': 'Japanese'
  },
  {
    'ISO 639-2/5': 'jpr',
    'Language name': 'Judeo-Persian'
  },
  {
    'ISO 639-2/5': 'jpx',
    'Language name': 'Japanese (family)'
  },
  {
    'ISO 639-2/5': 'jrb',
    'Language name': 'Judeo-Arabic'
  },
  {
    'ISO 639-2/5': 'kaa',
    'Language name': 'Kara-Kalpak'
  },
  {
    'ISO 639-2/5': 'kab',
    'Language name': 'Kabyle'
  },
  {
    'ISO 639-2/5': 'kac',
    'Language name': 'Kachin'
  },
  {
    'ISO 639-2/5': 'kal',
    'Language name': 'Kalaallisut'
  },
  {
    'ISO 639-2/5': 'kam',
    'Language name': 'Kamba (Kenya)'
  },
  {
    'ISO 639-2/5': 'kan',
    'Language name': 'Kannada'
  },
  {
    'ISO 639-2/5': 'kar',
    'Language name': 'Karen languages'
  },
  {
    'ISO 639-2/5': 'kas',
    'Language name': 'Kashmiri'
  },
  {
    'ISO 639-2/5': 'kat / geo*',
    'Language name': 'Georgian'
  },
  {
    'ISO 639-2/5': 'kau',
    'Language name': 'Kanuri'
  },
  {
    'ISO 639-2/5': 'kaw',
    'Language name': 'Kawi'
  },
  {
    'ISO 639-2/5': 'kaz',
    'Language name': 'Kazakh'
  },
  {
    'ISO 639-2/5': 'kbd',
    'Language name': 'Kabardian'
  },
  {
    'ISO 639-2/5': 'kdo',
    'Language name': 'Kordofanian languages'
  },
  {
    'ISO 639-2/5': 'kha',
    'Language name': 'Khasi'
  },
  {
    'ISO 639-2/5': 'khi',
    'Language name': 'Khoisan languages'
  },
  {
    'ISO 639-2/5': 'khm',
    'Language name': 'Central Khmer'
  },
  {
    'ISO 639-2/5': 'kho',
    'Language name': 'Khotanese'
  },
  {
    'ISO 639-2/5': 'kik',
    'Language name': 'Kikuyu'
  },
  {
    'ISO 639-2/5': 'kin',
    'Language name': 'Kinyarwanda'
  },
  {
    'ISO 639-2/5': 'kir',
    'Language name': 'Kirghiz'
  },
  {
    'ISO 639-2/5': 'kmb',
    'Language name': 'Kimbundu'
  },
  {
    'ISO 639-2/5': 'kok',
    'Language name': 'Konkani (macrolanguage)'
  },
  {
    'ISO 639-2/5': 'kom',
    'Language name': 'Komi'
  },
  {
    'ISO 639-2/5': 'kon',
    'Language name': 'Kongo'
  },
  {
    'ISO 639-2/5': 'kor',
    'Language name': 'Korean'
  },
  {
    'ISO 639-2/5': 'kos',
    'Language name': 'Kosraean'
  },
  {
    'ISO 639-2/5': 'kpe',
    'Language name': 'Kpelle'
  },
  {
    'ISO 639-2/5': 'krc',
    'Language name': 'Karachay-Balkar'
  },
  {
    'ISO 639-2/5': 'krl',
    'Language name': 'Karelian'
  },
  {
    'ISO 639-2/5': 'kro',
    'Language name': 'Kru languages'
  },
  {
    'ISO 639-2/5': 'kru',
    'Language name': 'Kurukh'
  },
  {
    'ISO 639-2/5': 'kua',
    'Language name': 'Kuanyama'
  },
  {
    'ISO 639-2/5': 'kum',
    'Language name': 'Kumyk'
  },
  {
    'ISO 639-2/5': 'kur',
    'Language name': 'Kurdish'
  },
  {
    'ISO 639-2/5': 'kut',
    'Language name': 'Kutenai'
  },
  {
    'ISO 639-2/5': 'lad',
    'Language name': 'Ladino'
  },
  {
    'ISO 639-2/5': 'lah',
    'Language name': 'Lahnda'
  },
  {
    'ISO 639-2/5': 'lam',
    'Language name': 'Lamba'
  },
  {
    'ISO 639-2/5': 'lao',
    'Language name': 'Lao'
  },
  {
    'ISO 639-2/5': 'lat',
    'Language name': 'Latin'
  },
  {
    'ISO 639-2/5': 'lav',
    'Language name': 'Latvian'
  },
  {
    'ISO 639-2/5': 'lez',
    'Language name': 'Lezghian'
  },
  {
    'ISO 639-2/5': 'lim',
    'Language name': 'Limburgan'
  },
  {
    'ISO 639-2/5': 'lin',
    'Language name': 'Lingala'
  },
  {
    'ISO 639-2/5': 'lit',
    'Language name': 'Lithuanian'
  },
  {
    'ISO 639-2/5': 'lol',
    'Language name': 'Mongo'
  },
  {
    'ISO 639-2/5': 'loz',
    'Language name': 'Lozi'
  },
  {
    'ISO 639-2/5': 'ltz',
    'Language name': 'Luxembourgish'
  },
  {
    'ISO 639-2/5': 'lua',
    'Language name': 'Luba-Lulua'
  },
  {
    'ISO 639-2/5': 'lub',
    'Language name': 'Luba-Katanga'
  },
  {
    'ISO 639-2/5': 'lug',
    'Language name': 'Ganda'
  },
  {
    'ISO 639-2/5': 'lui',
    'Language name': 'Luiseno'
  },
  {
    'ISO 639-2/5': 'lun',
    'Language name': 'Lunda'
  },
  {
    'ISO 639-2/5': 'luo',
    'Language name': 'Luo (Kenya and Tanzania)'
  },
  {
    'ISO 639-2/5': 'lus',
    'Language name': 'Lushai'
  },
  {
    'ISO 639-2/5': 'mac* / mkd',
    'Language name': 'Macedonian'
  },
  {
    'ISO 639-2/5': 'mad',
    'Language name': 'Madurese'
  },
  {
    'ISO 639-2/5': 'mag',
    'Language name': 'Magahi'
  },
  {
    'ISO 639-2/5': 'mah',
    'Language name': 'Marshallese'
  },
  {
    'ISO 639-2/5': 'mai',
    'Language name': 'Maithili'
  },
  {
    'ISO 639-2/5': 'mak',
    'Language name': 'Makasar'
  },
  {
    'ISO 639-2/5': 'mal',
    'Language name': 'Malayalam'
  },
  {
    'ISO 639-2/5': 'man',
    'Language name': 'Mandingo'
  },
  {
    'ISO 639-2/5': 'mao* / mri',
    'Language name': 'Maori'
  },
  {
    'ISO 639-2/5': 'map',
    'Language name': 'Austronesian languages'
  },
  {
    'ISO 639-2/5': 'mar',
    'Language name': 'Marathi'
  },
  {
    'ISO 639-2/5': 'mas',
    'Language name': 'Masai'
  },
  {
    'ISO 639-2/5': 'may* / msa',
    'Language name': 'Malay (macrolanguage)'
  },
  {
    'ISO 639-2/5': 'mdf',
    'Language name': 'Moksha'
  },
  {
    'ISO 639-2/5': 'mdr',
    'Language name': 'Mandar'
  },
  {
    'ISO 639-2/5': 'men',
    'Language name': 'Mende (Sierra Leone)'
  },
  {
    'ISO 639-2/5': 'mga',
    'Language name': 'Middle Irish (900-1200)'
  },
  {
    'ISO 639-2/5': 'mic',
    'Language name': "Mi'kmaq"
  },
  {
    'ISO 639-2/5': 'min',
    'Language name': 'Minangkabau'
  },
  {
    'ISO 639-2/5': 'mis',
    'Language name': 'Uncoded languages'
  },
  {
    'ISO 639-2/5': 'mkd / mac*',
    'Language name': 'Macedonian'
  },
  {
    'ISO 639-2/5': 'mkh',
    'Language name': 'Mon-Khmer languages'
  },
  {
    'ISO 639-2/5': 'mlg',
    'Language name': 'Malagasy'
  },
  {
    'ISO 639-2/5': 'mlt',
    'Language name': 'Maltese'
  },
  {
    'ISO 639-2/5': 'mnc',
    'Language name': 'Manchu'
  },
  {
    'ISO 639-2/5': 'mni',
    'Language name': 'Manipuri'
  },
  {
    'ISO 639-2/5': 'mno',
    'Language name': 'Manobo languages'
  },
  {
    'ISO 639-2/5': 'moh',
    'Language name': 'Mohawk'
  },
  {
    'ISO 639-2/5': 'mon',
    'Language name': 'Mongolian'
  },
  {
    'ISO 639-2/5': 'mos',
    'Language name': 'Mossi'
  },
  {
    'ISO 639-2/5': 'mri / mao*',
    'Language name': 'Maori'
  },
  {
    'ISO 639-2/5': 'msa / may*',
    'Language name': 'Malay (macrolanguage)'
  },
  {
    'ISO 639-2/5': 'mul',
    'Language name': 'Multiple languages'
  },
  {
    'ISO 639-2/5': 'mun',
    'Language name': 'Munda languages'
  },
  {
    'ISO 639-2/5': 'mus',
    'Language name': 'Creek'
  },
  {
    'ISO 639-2/5': 'mwl',
    'Language name': 'Mirandese'
  },
  {
    'ISO 639-2/5': 'mwr',
    'Language name': 'Marwari'
  },
  {
    'ISO 639-2/5': 'mya / bur*',
    'Language name': 'Burmese'
  },
  {
    'ISO 639-2/5': 'myn',
    'Language name': 'Mayan languages'
  },
  {
    'ISO 639-2/5': 'myv',
    'Language name': 'Erzya'
  },
  {
    'ISO 639-2/5': 'nah',
    'Language name': 'Nahuatl languages'
  },
  {
    'ISO 639-2/5': 'nai',
    'Language name': 'North American Indian'
  },
  {
    'ISO 639-2/5': 'nap',
    'Language name': 'Neapolitan'
  },
  {
    'ISO 639-2/5': 'nau',
    'Language name': 'Nauru'
  },
  {
    'ISO 639-2/5': 'nav',
    'Language name': 'Navajo'
  },
  {
    'ISO 639-2/5': 'nbl',
    'Language name': 'South Ndebele'
  },
  {
    'ISO 639-2/5': 'nde',
    'Language name': 'North Ndebele'
  },
  {
    'ISO 639-2/5': 'ndo',
    'Language name': 'Ndonga'
  },
  {
    'ISO 639-2/5': 'nds',
    'Language name': 'Low German'
  },
  {
    'ISO 639-2/5': 'nep',
    'Language name': 'Nepali (macrolanguage)'
  },
  {
    'ISO 639-2/5': 'new',
    'Language name': 'Nepal Bhasa'
  },
  {
    'ISO 639-2/5': 'ngf',
    'Language name': 'Trans-New Guinea languages'
  },
  {
    'ISO 639-2/5': 'nia',
    'Language name': 'Nias'
  },
  {
    'ISO 639-2/5': 'nic',
    'Language name': 'Niger-Kordofanian languages'
  },
  {
    'ISO 639-2/5': 'niu',
    'Language name': 'Niuean'
  },
  {
    'ISO 639-2/5': 'nld / dut*',
    'Language name': 'Dutch'
  },
  {
    'ISO 639-2/5': 'nno',
    'Language name': 'Norwegian Nynorsk'
  },
  {
    'ISO 639-2/5': 'nob',
    'Language name': 'Norwegian Bokmål'
  },
  {
    'ISO 639-2/5': 'nog',
    'Language name': 'Nogai'
  },
  {
    'ISO 639-2/5': 'non',
    'Language name': 'Old Norse'
  },
  {
    'ISO 639-2/5': 'nor',
    'Language name': 'Norwegian'
  },
  {
    'ISO 639-2/5': 'nqo',
    'Language name': "N'Ko"
  },
  {
    'ISO 639-2/5': 'nso',
    'Language name': 'Pedi'
  },
  {
    'ISO 639-2/5': 'nub',
    'Language name': 'Nubian languages'
  },
  {
    'ISO 639-2/5': 'nwc',
    'Language name': 'Classical Newari'
  },
  {
    'ISO 639-2/5': 'nya',
    'Language name': 'Nyanja'
  },
  {
    'ISO 639-2/5': 'nym',
    'Language name': 'Nyamwezi'
  },
  {
    'ISO 639-2/5': 'nyn',
    'Language name': 'Nyankole'
  },
  {
    'ISO 639-2/5': 'nyo',
    'Language name': 'Nyoro'
  },
  {
    'ISO 639-2/5': 'nzi',
    'Language name': 'Nzima'
  },
  {
    'ISO 639-2/5': 'oci',
    'Language name': 'Occitan (post 1500)'
  },
  {
    'ISO 639-2/5': 'oji',
    'Language name': 'Ojibwa'
  },
  {
    'ISO 639-2/5': 'omq',
    'Language name': 'Oto-Manguean languages'
  },
  {
    'ISO 639-2/5': 'omv',
    'Language name': 'Omotic languages'
  },
  {
    'ISO 639-2/5': 'ori',
    'Language name': 'Odiya (macrolanguage)'
  },
  {
    'ISO 639-2/5': 'orm',
    'Language name': 'Oromo'
  },
  {
    'ISO 639-2/5': 'osa',
    'Language name': 'Osage'
  },
  {
    'ISO 639-2/5': 'oss',
    'Language name': 'Ossetian'
  },
  {
    'ISO 639-2/5': 'ota',
    'Language name': 'Ottoman Turkish (1500-1928)'
  },
  {
    'ISO 639-2/5': 'oto',
    'Language name': 'Otomian languages'
  },
  {
    'ISO 639-2/5': 'paa',
    'Language name': 'Papuan languages'
  },
  {
    'ISO 639-2/5': 'pag',
    'Language name': 'Pangasinan'
  },
  {
    'ISO 639-2/5': 'pal',
    'Language name': 'Pahlavi'
  },
  {
    'ISO 639-2/5': 'pam',
    'Language name': 'Pampanga'
  },
  {
    'ISO 639-2/5': 'pan',
    'Language name': 'Panjabi'
  },
  {
    'ISO 639-2/5': 'pap',
    'Language name': 'Papiamento'
  },
  {
    'ISO 639-2/5': 'pau',
    'Language name': 'Palauan'
  },
  {
    'ISO 639-2/5': 'peo',
    'Language name': 'Old Persian (c. 600–400 B.C.)'
  },
  {
    'ISO 639-2/5': 'per* / fas',
    'Language name': 'Persian'
  },
  {
    'ISO 639-2/5': 'phi',
    'Language name': 'Philippine languages'
  },
  {
    'ISO 639-2/5': 'phn',
    'Language name': 'Phoenician'
  },
  {
    'ISO 639-2/5': 'plf',
    'Language name': 'Central Malayo-Polynesian languages'
  },
  {
    'ISO 639-2/5': 'pli',
    'Language name': 'Pali'
  },
  {
    'ISO 639-2/5': 'pny',
    'Language name': 'Pinyin'
  },
  {
    'ISO 639-2/5': 'pol',
    'Language name': 'Polish'
  },
  {
    'ISO 639-2/5': 'pon',
    'Language name': 'Pohnpeian'
  },
  {
    'ISO 639-2/5': 'por',
    'Language name': 'Portuguese'
  },
  {
    'ISO 639-2/5': 'poz',
    'Language name': 'Malayo-Polynesian languages'
  },
  {
    'ISO 639-2/5': 'pqe',
    'Language name': 'Eastern Malayo-Polynesian languages'
  },
  {
    'ISO 639-2/5': 'pqw',
    'Language name': 'Western Malayo-Polynesian languages'
  },
  {
    'ISO 639-2/5': 'pra',
    'Language name': 'Prakrit languages'
  },
  {
    'ISO 639-2/5': 'pro',
    'Language name': 'Old Provençal (to 1500)'
  },
  {
    'ISO 639-2/5': 'pus',
    'Language name': 'Pushto'
  },
  {
    'ISO 639-2/5': 'qaa-qtz',
    'Language name': 'Reserved for local use'
  },
  {
    'ISO 639-2/5': 'que',
    'Language name': 'Quechua'
  },
  {
    'ISO 639-2/5': 'qwe',
    'Language name': 'Quechuan (family)'
  },
  {
    'ISO 639-2/5': 'raj',
    'Language name': 'Rajasthani'
  },
  {
    'ISO 639-2/5': 'rap',
    'Language name': 'Rapanui'
  },
  {
    'ISO 639-2/5': 'rar',
    'Language name': 'Rarotongan'
  },
  {
    'ISO 639-2/5': 'rcf',
    'Language name': 'Reunionese, Reunion Creole'
  },
  {
    'ISO 639-2/5': 'roa',
    'Language name': 'Romance languages'
  },
  {
    'ISO 639-2/5': 'roh',
    'Language name': 'Romansh'
  },
  {
    'ISO 639-2/5': 'rom',
    'Language name': 'Romany'
  },
  {
    'ISO 639-2/5': 'ron / rum*',
    'Language name': 'Romanian'
  },
  {
    'ISO 639-2/5': 'run',
    'Language name': 'Rundi'
  },
  {
    'ISO 639-2/5': 'rup',
    'Language name': 'Macedo-Romanian'
  },
  {
    'ISO 639-2/5': 'rus',
    'Language name': 'Russian'
  },
  {
    'ISO 639-2/5': 'sad',
    'Language name': 'Sandawe'
  },
  {
    'ISO 639-2/5': 'sag',
    'Language name': 'Sango'
  },
  {
    'ISO 639-2/5': 'sah',
    'Language name': 'Yakut'
  },
  {
    'ISO 639-2/5': 'sai',
    'Language name': 'South American Indian languages'
  },
  {
    'ISO 639-2/5': 'sal',
    'Language name': 'Salishan languages'
  },
  {
    'ISO 639-2/5': 'sam',
    'Language name': 'Samaritan Aramaic'
  },
  {
    'ISO 639-2/5': 'san',
    'Language name': 'Sanskrit'
  },
  {
    'ISO 639-2/5': 'sas',
    'Language name': 'Sasak'
  },
  {
    'ISO 639-2/5': 'sat',
    'Language name': 'Santali'
  },
  {
    'ISO 639-2/5': 'scn',
    'Language name': 'Sicilian'
  },
  {
    'ISO 639-2/5': 'sco',
    'Language name': 'Scots'
  },
  {
    'ISO 639-2/5': 'sdv',
    'Language name': 'Eastern Sudanic languages'
  },
  {
    'ISO 639-2/5': 'sel',
    'Language name': 'Selkup'
  },
  {
    'ISO 639-2/5': 'sem',
    'Language name': 'Semitic languages'
  },
  {
    'ISO 639-2/5': 'sga',
    'Language name': 'Old Irish (to 900)'
  },
  {
    'ISO 639-2/5': 'sgn',
    'Language name': 'Sign languages'
  },
  {
    'ISO 639-2/5': 'shn',
    'Language name': 'Shan'
  },
  {
    'ISO 639-2/5': 'sid',
    'Language name': 'Sidamo'
  },
  {
    'ISO 639-2/5': 'sin',
    'Language name': 'Sinhala',
    'Default script': 'Sinh'
  },
  {
    'ISO 639-2/5': 'sio',
    'Language name': 'Siouan languages'
  },
  {
    'ISO 639-2/5': 'sit',
    'Language name': 'Sino-Tibetan languages'
  },
  {
    'ISO 639-2/5': 'sla',
    'Language name': 'Slavic languages'
  },
  {
    'ISO 639-2/5': 'slk / slo*',
    'Language name': 'Slovak'
  },
  {
    'ISO 639-2/5': 'slv',
    'Language name': 'Slovenian'
  },
  {
    'ISO 639-2/5': 'sma',
    'Language name': 'Southern Sami'
  },
  {
    'ISO 639-2/5': 'sme',
    'Language name': 'Northern Sami'
  },
  {
    'ISO 639-2/5': 'smi',
    'Language name': 'Sami languages'
  },
  {
    'ISO 639-2/5': 'smj',
    'Language name': 'Lule Sami'
  },
  {
    'ISO 639-2/5': 'smn',
    'Language name': 'Inari Sami'
  },
  {
    'ISO 639-2/5': 'smo',
    'Language name': 'Samoan'
  },
  {
    'ISO 639-2/5': 'sms',
    'Language name': 'Skolt Sami'
  },
  {
    'ISO 639-2/5': 'sna',
    'Language name': 'Shona'
  },
  {
    'ISO 639-2/5': 'snd',
    'Language name': 'Sindhi'
  },
  {
    'ISO 639-2/5': 'snk',
    'Language name': 'Soninke'
  },
  {
    'ISO 639-2/5': 'sog',
    'Language name': 'Sogdian'
  },
  {
    'ISO 639-2/5': 'som',
    'Language name': 'Somali'
  },
  {
    'ISO 639-2/5': 'son',
    'Language name': 'Songhai languages'
  },
  {
    'ISO 639-2/5': 'sot',
    'Language name': 'Southern Sotho'
  },
  {
    'ISO 639-2/5': 'spa',
    'Language name': 'Spanish'
  },
  {
    'ISO 639-2/5': 'sqi / alb*',
    'Language name': 'Albanian'
  },
  {
    'ISO 639-2/5': 'sqi',
    'Language name': 'Albanian languages'
  },
  {
    'ISO 639-2/5': 'srd',
    'Language name': 'Sardinian'
  },
  {
    'ISO 639-2/5': 'srn',
    'Language name': 'Sranan Tongo'
  },
  {
    'ISO 639-2/5': 'srp / scc*',
    'Language name': 'Serbian'
  },
  {
    'ISO 639-2/5': 'srr',
    'Language name': 'Serer'
  },
  {
    'ISO 639-2/5': 'ssa',
    'Language name': 'Nilo-Saharan languages'
  },
  {
    'ISO 639-2/5': 'ssw',
    'Language name': 'Swati'
  },
  {
    'ISO 639-2/5': 'suk',
    'Language name': 'Sukuma'
  },
  {
    'ISO 639-2/5': 'sun',
    'Language name': 'Sundanese'
  },
  {
    'ISO 639-2/5': 'sus',
    'Language name': 'Susu'
  },
  {
    'ISO 639-2/5': 'sux',
    'Language name': 'Sumerian'
  },
  {
    'ISO 639-2/5': 'swa',
    'Language name': 'Swahili (macrolanguage)'
  },
  {
    'ISO 639-2/5': 'swe',
    'Language name': 'Swedish'
  },
  {
    'ISO 639-2/5': 'syc',
    'Language name': 'Classical Syriac'
  },
  {
    'ISO 639-2/5': 'syd',
    'Language name': 'Samoyedic languages'
  },
  {
    'ISO 639-2/5': 'syr',
    'Language name': 'Syriac'
  },
  {
    'ISO 639-2/5': 'tah',
    'Language name': 'Tahitian'
  },
  {
    'ISO 639-2/5': 'tai',
    'Language name': 'Tai languages'
  },
  {
    'ISO 639-2/5': 'tam',
    'Language name': 'Tamil'
  },
  {
    'ISO 639-2/5': 'tat',
    'Language name': 'Tatar'
  },
  {
    'ISO 639-2/5': 'tbq',
    'Language name': 'Tibeto-Burman languages'
  },
  {
    'ISO 639-2/5': 'tel',
    'Language name': 'Telugu'
  },
  {
    'ISO 639-2/5': 'tem',
    'Language name': 'Timne'
  },
  {
    'ISO 639-2/5': 'ter',
    'Language name': 'Tereno'
  },
  {
    'ISO 639-2/5': 'tet',
    'Language name': 'Tetum'
  },
  {
    'ISO 639-2/5': 'tgk',
    'Language name': 'Tajik'
  },
  {
    'ISO 639-2/5': 'tgl',
    'Language name': 'Tagalog'
  },
  {
    'ISO 639-2/5': 'tha',
    'Language name': 'Thai'
  },
  {
    'ISO 639-2/5': 'tib* / bod',
    'Language name': 'Tibetan'
  },
  {
    'ISO 639-2/5': 'tig',
    'Language name': 'Tigre'
  },
  {
    'ISO 639-2/5': 'tir',
    'Language name': 'Tigrinya'
  },
  {
    'ISO 639-2/5': 'tiv',
    'Language name': 'Tiv'
  },
  {
    'ISO 639-2/5': 'tkl',
    'Language name': 'Tokelau'
  },
  {
    'ISO 639-2/5': 'tlh',
    'Language name': 'Klingon'
  },
  {
    'ISO 639-2/5': 'tli',
    'Language name': 'Tlingit'
  },
  {
    'ISO 639-2/5': 'tmh',
    'Language name': 'Tamashek'
  },
  {
    'ISO 639-2/5': 'tog',
    'Language name': 'Tonga (Nyasa)'
  },
  {
    'ISO 639-2/5': 'ton',
    'Language name': 'Tonga (Tonga Islands)'
  },
  {
    'ISO 639-2/5': 'tpi',
    'Language name': 'Tok Pisin'
  },
  {
    'ISO 639-2/5': 'trk',
    'Language name': 'Turkic languages'
  },
  {
    'ISO 639-2/5': 'tsi',
    'Language name': 'Tsimshian'
  },
  {
    'ISO 639-2/5': 'tsn',
    'Language name': 'Tswana'
  },
  {
    'ISO 639-2/5': 'tso',
    'Language name': 'Tsonga'
  },
  {
    'ISO 639-2/5': 'tuk',
    'Language name': 'Turkmen'
  },
  {
    'ISO 639-2/5': 'tum',
    'Language name': 'Tumbuka'
  },
  {
    'ISO 639-2/5': 'tup',
    'Language name': 'Tupi languages'
  },
  {
    'ISO 639-2/5': 'tur',
    'Language name': 'Turkish'
  },
  {
    'ISO 639-2/5': 'tut',
    'Language name': 'Altaic languages'
  },
  {
    'ISO 639-2/5': 'tuw',
    'Language name': 'Tungus languages'
  },
  {
    'ISO 639-2/5': 'tvl',
    'Language name': 'Tuvaluan'
  },
  {
    'ISO 639-2/5': 'twi',
    'Language name': 'Twi'
  },
  {
    'ISO 639-2/5': 'tyv',
    'Language name': 'Tuvinian'
  },
  {
    'ISO 639-2/5': 'udm',
    'Language name': 'Udmurt'
  },
  {
    'ISO 639-2/5': 'uga',
    'Language name': 'Ugaritic'
  },
  {
    'ISO 639-2/5': 'uig',
    'Language name': 'Uighur'
  },
  {
    'ISO 639-2/5': 'ukr',
    'Language name': 'Ukrainian'
  },
  {
    'ISO 639-2/5': 'umb',
    'Language name': 'Umbundu'
  },
  {
    'ISO 639-2/5': 'und',
    'Language name': 'Undetermined'
  },
  {
    'ISO 639-2/5': 'urd',
    'Language name': 'Urdu'
  },
  {
    'ISO 639-2/5': 'urj',
    'Language name': 'Uralic languages'
  },
  {
    'ISO 639-2/5': 'uzb',
    'Language name': 'Uzbek'
  },
  {
    'ISO 639-2/5': 'vai',
    'Language name': 'Vai'
  },
  {
    'ISO 639-2/5': 'ven',
    'Language name': 'Venda'
  },
  {
    'ISO 639-2/5': 'vie',
    'Language name': 'Vietnamese'
  },
  {
    'ISO 639-2/5': 'vol',
    'Language name': 'Volapük'
  },
  {
    'ISO 639-2/5': 'vot',
    'Language name': 'Votic'
  },
  {
    'ISO 639-2/5': 'wak',
    'Language name': 'Wakashan languages'
  },
  {
    'ISO 639-2/5': 'wal',
    'Language name': 'Wolaytta'
  },
  {
    'ISO 639-2/5': 'war',
    'Language name': 'Waray (Philippines)'
  },
  {
    'ISO 639-2/5': 'was',
    'Language name': 'Washo'
  },
  {
    'ISO 639-2/5': 'wel* / cym',
    'Language name': 'Welsh'
  },
  {
    'ISO 639-2/5': 'wen',
    'Language name': 'Sorbian languages'
  },
  {
    'ISO 639-2/5': 'wln',
    'Language name': 'Walloon'
  },
  {
    'ISO 639-2/5': 'wol',
    'Language name': 'Wolof'
  },
  {
    'ISO 639-2/5': 'xal',
    'Language name': 'Kalmyk'
  },
  {
    'ISO 639-2/5': 'xgn',
    'Language name': 'Mongolian languages'
  },
  {
    'ISO 639-2/5': 'xho',
    'Language name': 'Xhosa'
  },
  {
    'ISO 639-2/5': 'xnd',
    'Language name': 'Na-Dene languages'
  },
  {
    'ISO 639-2/5': 'yao',
    'Language name': 'Yao'
  },
  {
    'ISO 639-2/5': 'yap',
    'Language name': 'Yapese'
  },
  {
    'ISO 639-2/5': 'yid',
    'Language name': 'Yiddish'
  },
  {
    'ISO 639-2/5': 'yor',
    'Language name': 'Yoruba'
  },
  {
    'ISO 639-2/5': 'ypk',
    'Language name': 'Yupik languages'
  },
  {
    'ISO 639-2/5': 'zap',
    'Language name': 'Zapotec'
  },
  {
    'ISO 639-2/5': 'zbl',
    'Language name': 'Blissymbols'
  },
  {
    'ISO 639-2/5': 'zen',
    'Language name': 'Zenaga'
  },
  {
    'ISO 639-2/5': 'zgh',
    'Language name': 'Standard Moroccan Tamazight'
  },
  {
    'ISO 639-2/5': 'zha',
    'Language name': 'Zhuang'
  },
  {
    'ISO 639-2/5': 'zho / chi*',
    'Language name': 'Chinese'
  },
  {
    'ISO 639-2/5': 'zhx',
    'Language name': 'Chinese (family)'
  },
  {
    'ISO 639-2/5': 'zle',
    'Language name': 'East Slavic languages'
  },
  {
    'ISO 639-2/5': 'zls',
    'Language name': 'South Slavic languages'
  },
  {
    'ISO 639-2/5': 'zlw',
    'Language name': 'West Slavic languages'
  },
  {
    'ISO 639-2/5': 'znd',
    'Language name': 'Zande languages'
  },
  {
    'ISO 639-2/5': 'zul',
    'Language name': 'Zulu'
  },
  {
    'ISO 639-2/5': 'zun',
    'Language name': 'Zuni'
  },
  {
    'ISO 639-2/5': 'zxx',
    'Language name': 'No linguistic content'
  },
  {
    'ISO 639-2/5': 'zza',
    'Language name': 'Zaza'
  }
];
