import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const PlayIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 41 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-847.000000, -1768.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 459.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 282.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1012.000000)">
                <g id="ic-play" transform="translate(425.000000, 14.000000)">
                  <g id="Shape">
                    <path
                      d="M39.1440329,17.7991472 L5.23045267,0.455091831 C3.99314129,-0.151697277
                      2.7558299,-0.151697277 1.63100137,0.455091831 C0.50617284,1.06188094
                      0,2.17432764 0,3.28677433 L0,38.0760165 C0,39.1884632 0.674897119,40.3009099
                      1.63100137,40.907699 C2.19341564,41.1605278 2.86831276,41.4133566
                      3.54320988,41.4133566 C4.218107,41.4133566 4.78052126,41.1605278 5.17421125,40.907699
                      L39.0877915,23.5636437 C40.3251029,22.9568546 41,21.8444079 41,20.7319612
                      C41,19.6195145 40.3813443,18.5576335 39.1440329,17.7991472 Z"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
