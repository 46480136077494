import * as React from 'react';
import {Icon} from 'tt-components/src/Icon';

interface IButtonComponentProps {
  label?: string;
  open: boolean;
  onClick: () => void;
}

import {ChevronDownIcon} from '../../../../assets/Icons/ChevronDownIcon';
import {ChevronUpIcon} from '../../../../assets/Icons/ChevronUpIcon';

export const ButtonComponent = (props: IButtonComponentProps) => {
  return (
    <div className="button-component-container" onClick={e => props.onClick()}>
      <div className="button-component-container_label">
        <span className="button-component-container_label_span">{props.label || 'Select options...'}</span>
      </div>
      <div className="button-component-container_icon">
        <Icon icon={props.open ? ChevronUpIcon : ChevronDownIcon} />
      </div>
    </div>
  );
};
