import {createAction} from 'redux-actions';
import {ITemplateColors} from '../../@types/templateColors';

export const SET_PLAYER_WIDTH = 'Configuration/SET_PLAYER_WIDTH';
export type SET_PLAYER_WIDTH = number;
export const setPlayerWidth = createAction<SET_PLAYER_WIDTH, SET_PLAYER_WIDTH>(
  SET_PLAYER_WIDTH,
  (playerWidth: SET_PLAYER_WIDTH) => playerWidth
);

export const SET_TEMPLATE_COLORS = 'Configuration/SET_TEMPLATE_COLORS';
export type SET_TEMPLATE_COLORS = ITemplateColors;
export const setTemplateColors = createAction<SET_TEMPLATE_COLORS, SET_TEMPLATE_COLORS>(
  SET_TEMPLATE_COLORS,
  (colors: SET_TEMPLATE_COLORS) => colors
);
