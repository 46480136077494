import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const CloseIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '8px'}
      height={props.size || '8px'}
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1327.000000, -1807.000000)" fill={props.color || '#FFFFFF'} fillRule="nonzero">
          <g transform="translate(1094.000000, 724.000000)">
            <g transform="translate(5.000000, 757.000000)">
              <g transform="translate(0.000000, 111.000000)">
                <g>
                  <g>
                    <g transform="translate(50.000000, 178.000000)">
                      <g transform="translate(113.000000, 29.000000)">
                        <g>
                          <g transform="translate(62.000000, 5.000000)">
                            <path
                              d="M11.0123317,10.6638973 L7.34590589,7.01233635 L10.9877248,3.36077543 C11.0861523,
                              3.26208459 11.0861523,3.11404834 10.9877248,3.0153575 C10.8892973,2.91666667 10.741656,
                              2.91666667 10.6432284,3.0153575 L7.0014095,6.66691843 L3.35959059,2.99068479 C3.26116305,
                              2.89199396 3.11352174,2.89199396 3.0150942,2.99068479 C2.91666667,3.08937563 2.91666667,
                              3.23741188 3.0150942,3.33610272 L6.65691312,7.01233635 L2.99048732,10.6638973 C2.89205978,
                              10.7625881 2.89205978,10.9106244 2.99048732,11.0093152 C3.08891486,11.108006 3.23655617,
                              11.108006 3.3349837,11.0093152 L7.0014095,7.35775428 L10.6432284,11.0093152 C10.741656,
                              11.108006 10.8892973,11.108006 10.9877248,11.0093152 C11.1107592,10.9106244 11.1107592,
                              10.7872608 11.0123317,10.6638973 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
