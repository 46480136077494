import {apiRequest} from './index';
import {IAudioChannelConfiguration} from '../../@types/audioChannelConfiguration';
import {IResponse} from '../../@types/response';
import {clearProps, parseMarkupsTypes} from '../modules/Tabs/utils/helpers';
import {IPlaylistAtlas, IFeatureCredentials, ISeriesCredentials} from '../state/IAppState';
import {generateAssetURL, generateTitleDataURLs, generateAssetCredentials, prepareQuery} from '../utils/utils';
import {IEnumType} from '../../@types/enumType';
import {IAssetDetails} from '../../@types/assetDetails';
import {ISearchTitleParams} from '../../@types/searchTitle';
import {PlaylistAsset} from '../models/PlaylistAsset/PlaylistAsset';
import {ISearchAssetBody} from '../../@types/searchAssetBody';

// NOTE: Update this constant value with a valid ID from ONE-COSTUMER service (if not working)
// and use in cases of dev testing when existing assets have unvalid contentProviderId
const DEV_CONTENT_PROVIDER_ID = null; // '3290e347'

export async function getAssetDetails(selectedAsset: PlaylistAsset): Promise<IResponse> {
  try {
    if (!selectedAsset) {
      throw new Error('Selected asset not defined');
    }
    const credentials = selectedAsset.getTitleInfoForAsset();
    const url = generateAssetURL(credentials, selectedAsset.assetId);
    if (!url) {
      throw new Error(`Couldn't find title type to generate URL`);
    }
    const response = selectedAsset.isRegistered
      ? await apiRequest('GET', url)
      : await getSearchAsset(selectedAsset.assetId, {showErrorLogs: true});
    return {success: true, data: selectedAsset.isRegistered ? response : response.data};
  } catch (error) {
    return {success: false, data: {}, error};
  }
}

export async function getEventGroups(assetId: string): Promise<IResponse> {
  try {
    const url = appConfig.apiAtlasHost + `asset/${assetId}/eventgroups`;
    const data = await apiRequest('GET', url);
    return {success: true, data};
  } catch (error) {
    return {success: false, data: [], error};
  }
}

/*export async function getTypesByName(name) {
  let url = '';
  switch (name) {
    case 'Program Timings':
      url = appConfig.apiAtlasHost + `enum?type=Lookup.Asset&name=Asset.ProgramTimings.Name`;
      break;
    case 'QC':
      url = '';
      break;
    default:
      url = '';
  }
  return apiRequest('GET', url);
}*/

export const getProgramTimingTypes = async (): Promise<IResponse> => {
  const url = appConfig.apiAtlasHost + `enum?type=Lookup.Asset&name=Asset.ProgramTimings.Name`;
  try {
    const response = await apiRequest('GET', url);
    return {success: true, data: Array.isArray(response) ? parseMarkupsTypes(response) : null};
  } catch (error) {
    return {success: false, data: null, error};
  }
};

export const getComplianceTypes = async (): Promise<IResponse> => {
  const url = appConfig.apiAtlasHost + `enum?type=Lookup.Asset&name=Asset.Compliance.EditTypes`;
  try {
    const response = await apiRequest('GET', url);
    return {success: true, data: Array.isArray(response) ? parseMarkupsTypes(response) : null};
  } catch (error) {
    return {success: false, data: null, error};
  }
};

export const getQualityControlTypes = async (): Promise<IResponse> => {
  const url = appConfig.apiAtlasHost + `enum?type=Lookup.Asset&name=Asset.QualityControl.Types`;
  try {
    const response = await apiRequest('GET', url);
    return {success: true, data: Array.isArray(response) ? parseMarkupsTypes(response) : null};
  } catch (error) {
    return {success: false, data: null, error};
  }
};

export const getChapterTypes = async (): Promise<IResponse> => {
  try {
    // TODO: Replace this Promise implementation with related API end-point once we will have one
    const data = (await new Promise((resolve, reject) => {
      resolve({success: true, data: {name: 'Chapter', list: ['Chapter']}});
    })) as IResponse;
    return data;
  } catch (error) {
    return {success: false, data: null, error};
  }
};

export async function getCategories() {
  const url = appConfig.apiAtlasHost + `enum?type=Lookup.Asset&name=Asset.Compliance.ReasonCodes`;

  return apiRequest('GET', url);
}

export async function getEvents(assetId: string): Promise<IResponse> {
  try {
    const url = appConfig.apiAtlasHost + `asset/${assetId}/events`;
    const response = await apiRequest('GET', url);
    return {success: true, data: response};
  } catch (error) {
    return {success: false, data: [], error};
  }
}

export async function addNewEventsGroup(assetId: string) {
  const url = appConfig.apiAtlasHost + `asset/${assetId}/eventgroup?username=xyz`;

  return apiRequest('POST', url);
}

export const addNewEvents = async (
  assetId: string,
  group: string,
  username: string,
  events: string
): Promise<IResponse> => {
  const url = `${appConfig.apiAtlasHost}asset/${assetId}/eventgroup/${group}/events?username=${username}`;
  try {
    // const error = true;
    // if (error) {
    //   throw new Error('New error!');
    // }
    await apiRequest('POST', url, events);
    return {success: true};
  } catch (error) {
    return {success: false, error};
  }
};

export const updateEvents = async (
  assetId: string,
  group: string,
  username: string,
  events: string
): Promise<IResponse> => {
  const url = `${appConfig.apiAtlasHost}asset/${assetId}/eventgroup/${group}/events?username=${username}`;
  try {
    // const error = true;
    // if (error) {
    //   throw new Error('Update error!');
    // }
    await apiRequest('PATCH', url, events);
    return {success: true};
  } catch (error) {
    return {success: false, error};
  }
};

export const removeEvent = async (assetEventId: string, username: string): Promise<IResponse> => {
  const url = `${appConfig.apiAtlasHost}asset/events/${assetEventId}?username=${username}`;
  try {
    // const error = true;
    // if (error) {
    //   throw new Error('Delete error!');
    // }
    await apiRequest('DELETE', url);
    return {success: true};
  } catch (error) {
    return {success: false, error};
  }
};

export async function removeEventGroup(assetId: string, name: string): Promise<IResponse> {
  try {
    const url = appConfig.apiAtlasHost + `asset/${assetId}/eventgroup/${name}`;
    await apiRequest('DELETE', url);
    return {success: true};
  } catch (error) {
    return {success: false, error};
  }
}

export async function updateAsset(
  selectedAsset: PlaylistAsset,
  data: string,
  doPut: boolean = false,
  timeout: number = null,
  getErrorPayload: boolean = false
): Promise<string> {
  const credentials = selectedAsset.getTitleInfoForAsset();
  const url = selectedAsset.isRegistered
    ? generateAssetURL(credentials, selectedAsset.assetId)
    : `${appConfig.apiAtlasHost}asset/${selectedAsset.assetId}`;
  const verb = selectedAsset.isRegistered ? (doPut ? 'PUT' : 'PATCH') : 'PUT';

  return apiRequest(verb, url, data, false, timeout, getErrorPayload);
}

export const getEnums = (enumType: IEnumType) => {
  return async (): Promise<IResponse> => {
    let queryString = '';
    switch (enumType) {
      case 'AssetAudioChannelMap':
        queryString = 'type=Lookup.Asset&name=Asset.Audio.TrackDetail.ChannelMap';
        break;
      case 'BitRateMode':
        queryString = 'type=Lookup.Asset&name=Asset.Audio.TrackDetail.BitRateMode';
        break;
      case 'AudioBitDepth':
        queryString = 'type=Lookup.Asset&name=Asset.Audio.TrackDetail.BitDepth';
        break;
      case 'AudioSubType':
        queryString = 'type=Lookup.Asset&name=Asset.Audio.TrackDetail.SubType';
        break;
      case 'AudioCodec':
        queryString = 'type=Lookup.Asset&name=Asset.Audio.TrackDetail.AudioCodec';
        break;
      case 'VideoSubType':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.Subtype';
        break;
      case 'VideoCodec':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.Videocodec';
        break;
      case 'EncodeRate':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.EncodeRate';
        break;
      case 'PictureFormat':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.PictureFormat';
        break;
      case 'SubtitleType':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.SubtitleLanguage.Type';
        break;
      case 'BitDepth':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.BitDepth';
        break;
      case 'PixelAspect':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.PixelAspect';
        break;
      case 'DisplayAspectRatio':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.DisplayAspectRatio';
        break;
      case 'PictureAspectRatio':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.PictureAspectRatio';
        break;
      case 'ScanType':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.ScanType';
        break;
      case 'ColorSubSampling':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.ColorSubSampling';
        break;
      case 'ColorType':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.ColorType';
        break;
      case 'TimeCode':
        queryString = 'type=Lookup.Asset&name=Asset.TimeCodeFormat';
        break;
      case 'ColorEncoding':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.ColorEncoding.Primaries';
        break;
      case 'TransferFunction':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.ColorEncoding.TransferCharacteristics';
        break;
      case 'ColorDiff':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.ColorEncoding.ColorDifferencing';
        break;
      case 'AssetAudioChannelConfig':
        queryString = 'type=Lookup.Asset&name=Asset.Audio.ChannelConfig';
        break;
      case 'AssetAudioType':
        queryString = 'type=Lookup.Asset&name=Asset.Audio.Type';
        break;
      case 'AssetStatus':
        queryString = 'type=Lookup.Asset&name=Asset.Status';
        break;
      case 'AssetFileWrapper':
        queryString = 'type=Lookup.Asset&name=Asset.FileWrapper';
        break;
      case 'AssetFunction':
        queryString = 'type=Lookup.Asset&name=Asset.Function';
        break;
      case 'AssetFormatCompliance':
        queryString = 'type=Lookup.Asset&name=Asset.FormatCompliance';
        break;
      case 'AssetContentType':
        queryString = 'type=Lookup.Asset&name=Asset.ContentType';
        break;
      case 'AssetFrameRate':
        queryString = 'type=Lookup.Asset&name=Asset.FrameRate';
        break;
      case 'CommonReferencesName':
        queryString = 'type=Lookup.Common&name=references.name';
        break;
      case 'CommonReferencesType':
        queryString = 'type=Lookup.Common&name=references.type';
        break;
      case 'CommonCountries':
        queryString = 'type=Lookup.Common&name=Country';
        break;
      case 'ColorModel':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.ColorModel';
        break;
      case 'SignalRange':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.SignalRange';
        break;
      case 'Colorimetry':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.Colorimetry';
        break;
      case 'WhitePoint':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.WhitePoint';
        break;
      case 'DynamicRangeType':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.DynamicRangeType';
        break;
      case 'DynamicRangeSystem':
        queryString = 'type=Lookup.Asset&name=Asset.Videos.VideoDetail.DynamicRangeSystem';
        break;
      case 'CommonLanguageDialect':
        queryString = 'type=Lookup.Common&name=LanguageDialect';
        break;
      case 'AssetImageGraphicsType':
        queryString = `type=Lookup.Asset&name=Asset.Images.GraphicsType`;
        break;
      case 'AssetNonMediaType':
        queryString = `type=Lookup.Asset&name=Asset.NonMedia.Type`;
        break;
      case 'AssetNonMediaSubType':
        queryString = `type=Lookup.Asset&name=Asset.NonMedia.SubType`;
        break;
      case 'CommonLanguage':
      default:
        queryString = 'type=Lookup.Common&name=Language';
    }
    try {
      const url = `${appConfig.apiAtlasHost}enum?${queryString}`;
      const data = await apiRequest('GET', url);
      return {success: true, data: (data[0] && data[0].enums) || []};
    } catch (error) {
      return {success: false, data: [], error};
    }
  };
};

export const updateAudioChannelAPI = async (
  confs: Array<IAudioChannelConfiguration>,
  selectedAsset: PlaylistAsset,
  username: string
): Promise<IResponse> => {
  try {
    if (!selectedAsset) {
      throw new Error('Selected asset not defined');
    }
    const asset = await getAssetDetails(selectedAsset);
    if (!asset.success) {
      throw new Error(asset.error);
    }
    const parsedAsset = {...asset, audio: [...confs], username};
    const cleanAsset = clearProps(parsedAsset, ['createdBy', 'createdDate', 'modifiedBy', 'modifiedDate']);

    // NOTE: Remove this IF statement once the issue with null "qcStatus" will be fixed in ATLAS
    if (cleanAsset.qualityControl && Array.isArray(cleanAsset.qualityControl) && cleanAsset.qualityControl.length) {
      cleanAsset.qualityControl.forEach((quality: any) => {
        if (!quality.qcStatus) {
          // Use values from http://{domain}/v1/enum/values?type=%25&name=Asset.QualityControl.Statuses
          quality.qcStatus = 'None';
        }
      });
    }

    // NOTE: Below line should be commented out in cases we have issues in dev environment for contentProviderId
    cleanAsset.contentProviderId = DEV_CONTENT_PROVIDER_ID;
    await updateAsset(selectedAsset, JSON.stringify(cleanAsset), true);
    return {success: true, data: cleanAsset};
  } catch (error) {
    return {success: false, error};
  }
};

export const getTitleDataAPI = async (
  selectedAsset: IPlaylistAtlas,
  dataType: 'Title' | 'Version' | 'Conformance'
): Promise<IResponse> => {
  try {
    if (!selectedAsset) {
      throw new Error('Selected asset not defined');
    }
    const credentials = generateAssetCredentials(selectedAsset);
    const urlObject = generateTitleDataURLs(credentials);
    if (!urlObject) {
      throw new Error('Asset is not part of Feature title');
    }

    const apiUrl = [];
    switch (dataType) {
      case 'Title':
        apiUrl.push(urlObject.title);
        break;
      case 'Version':
        apiUrl.push(urlObject.versions);
        break;
    }

    const data = await apiRequest('GET', apiUrl.join(''));
    return {success: true, data};
  } catch (error) {
    return {success: false, error};
  }
};

export const getTitleConformanceGroupsByVersion = async (
  selectedAsset: IPlaylistAtlas,
  versionId: string
): Promise<IResponse> => {
  try {
    const credentials = generateAssetCredentials(selectedAsset);
    const url = [`${appConfig.apiAtlasHost}`];

    switch (credentials.type) {
      case 'Feature':
        const featureData = credentials.data as IFeatureCredentials;
        url.push(`feature/${featureData.featureId}/version/${versionId}/conformanceGroups`);
        break;
      case 'Series':
        const seriesData = credentials.data as ISeriesCredentials;
        url.push(`
          series/${seriesData.seriesId}/season/
          ${seriesData.seasonId}/episode/${seriesData.episodeId}/version/${versionId}/conformanceGroups`);
        break;
    }

    if (url.length === 1) {
      throw new Error(`Couldn't find title type to generate request: ${credentials.type}`);
    }

    const data = await apiRequest('GET', url.join(''));
    return {success: true, data: ((Array.isArray(data) && data) || []).map(PlaylistAsset.parsing.parseSearchTitle)};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const updateCurationAsset = async (
  selectedAsset: PlaylistAsset,
  data: IAssetDetails,
  getErrorPayload: boolean = false
): Promise<IResponse> => {
  try {
    if (!selectedAsset) {
      throw new Error('Selected asset not defined');
    }
    const response = await updateAsset(selectedAsset, JSON.stringify(data), true, 60 * 1000, getErrorPayload);
    return {success: true, data: response};
  } catch (error) {
    return {success: false, error};
  }
};

export const searchTitles = async (params: ISearchTitleParams): Promise<IResponse> => {
  try {
    if (!Object.keys(params).length) {
      throw new Error('Not valid params provided');
    }
    // In case the client field is defined on the global appConfig object we need to pass it to the search params
    if (appConfig.client) {
      params = {...params, contentOwnerId: appConfig.client};
    }
    const query = prepareQuery(params);
    const url = `${appConfig.apiAtlasHost}search${query}`;
    const data = await apiRequest('GET', url);
    const results = data.statusCode !== 200 || !data.data ? [] : data.data;
    return {success: true, data: results.map(PlaylistAsset.parsing.parseSearchTitle)};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getSearchTitleById = async (atlasId: string): Promise<IResponse> => {
  const params: ISearchTitleParams = {
    atlasId,
    size: 1
  };
  const result = await searchTitles(params);
  return {...result, data: result.data.length ? result.data[0] : null};
};

export const getSearchAsset = async (assetId: string, requestBody: ISearchAssetBody = null): Promise<IResponse> => {
  try {
    const url = `${appConfig.apiAtlasHost}asset/unregistered/search?size=1`;
    const body = JSON.stringify({...(requestBody || {}), id: assetId});
    const result = await apiRequest('POST', url, body);
    if (result.statusCode !== 200 || !result.data) {
      throw new Error('Asset not found');
    }
    return {success: true, data: Array.isArray(result.data) ? result.data[0] || {} : {}};
  } catch (error) {
    return {success: false, error};
  }
};

export const isUnregisteredAsset = async (
  assetId: string,
  requestBody: ISearchAssetBody = null
): Promise<IResponse> => {
  const searchAsset = await getSearchAsset(assetId, requestBody);
  if (searchAsset.success) {
    const asset = searchAsset.data;
    return {success: !asset.meetsMinimumRequirements, data: Object.keys(asset).length ? asset : null};
  }
  return searchAsset;
};

export const getVersionsByFeatureId = async (featureId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.apiAtlasHost}feature/${featureId}/versions`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getConformanceGroupsByFeatureVersionId = async (
  featureId: string,
  versionId: string
): Promise<IResponse> => {
  try {
    const url = `${appConfig.apiAtlasHost}feature/${featureId}/version/${versionId}/conformanceGroups`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, data: []};
  }
};

export const getSeasonsBySeriesId = async (seriesId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.apiAtlasHost}series/${seriesId}/seasons`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getEpisodesBySeriesSeasonId = async (seriesId: string, seasonId: string): Promise<IResponse> => {
  try {
    const url = `${appConfig.apiAtlasHost}series/${seriesId}/season/${seasonId}/episodes`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getVersionsByEpisodeSeasonSeriesId = async (
  seriesId: string,
  seasonId: string,
  episodeId: string
): Promise<IResponse> => {
  try {
    const url = `${appConfig.apiAtlasHost}series/${seriesId}/season/${seasonId}/episode/${episodeId}/versions`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getConformanceGroupsByVersionEpisodeSeasonSeriesId = async (
  seriesId: string,
  seasonId: string,
  episodeId: string,
  versionId: string
): Promise<IResponse> => {
  try {
    const url = `${appConfig.apiAtlasHost}series/
    ${seriesId}/season/${seasonId}/episode/${episodeId}/version/${versionId}/conformanceGroups`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, data: [], error};
  }
};
