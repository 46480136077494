import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const HiddenIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '17px'}
      height={props.size || '12px'}
      viewBox="0 0 17 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-696.000000, -962.000000)" fill={props.color || '#FFFFFF'}>
          <g transform="translate(140.000000, 948.000000)">
            <g transform="translate(552.000000, 7.000000)">
              <g transform="translate(4.000000, 6.000000)">
                <path
                  d="M14.400807,3.09112912 L13.7781924,3.73124257 C13.4115139,3.48204154 13.0042016,
                  3.22882149 12.5656229,2.99343182 C12.8005304,3.46872573 12.9504895,3.98655625 
                  13.0052193,4.52594036 L12.1342765,5.4213614 C12.1512573,5.27951185 12.16,5.13509857 
                  12.16,4.98863636 C12.16,3.93475 11.7092114,2.95088636 10.9214171,2.27459091 C10.2074514,
                  2.03704545 9.45266286,1.88636364 8.68571429,1.88636364 C7.91876571,1.88636364 7.16397714,
                  2.03704545 6.45001143,2.27459091 C5.66221714,2.95088636 5.21142857,3.93563636 5.21142857,
                  4.98863636 C5.21142857,6.94395455 6.76964571,8.53409091 8.68571429,8.53409091 C8.83806502,
                  8.53409091 8.98815336,8.52403778 9.13537588,8.50454728 L8.26460781,9.39978865 C6.06679328,
                  9.18299266 4.34285714,7.28738707 4.34285714,4.98863636 C4.34285714,4.28309091 4.50441143,
                  3.60325 4.80580571,2.99343182 C4.20301714,3.31695455 3.65929143,3.67327273 3.19894857,
                  4.00831818 C2.19401143,4.73956818 1.42532571,5.47790909 1.03707429,5.875 C1.42532571,
                  6.27209091 2.19401143,7.01131818 3.19894857,7.74168182 C4.35807464,8.58427424 6.04838476,
                  9.56792448 7.86706008,9.80850961 L7.11919716,10.5773915 C6.55517209,10.4560288 5.97979282,
                  10.2709715 5.39730286,10.0231818 C4.50267429,9.64293182 3.58806857,9.11465909 2.67867429,
                  8.45165909 C1.13782857,7.32863636 0.152,6.21890909 0.111177143,6.17193182 C-0.0373485714,
                  6.00352273 -0.0373485714,5.74736364 0.111177143,5.57895455 C0.152,5.53286364 1.13782857,
                  4.42225 2.67867429,3.29922727 C3.58806857,2.63711364 4.50267429,2.10795455 5.39730286,
                  1.72770455 C6.53078857,1.24552273 7.63734857,1.00088636 8.68571429,1.00088636 C9.73408,
                  1.00088636 10.84064,1.24552273 11.9741257,1.72770455 C12.7730672,2.067284 13.5879409,
                  2.52491531 14.400807,3.09112912 Z"
                />
                <path
                  d="M16.5347943,1.63607955 L5.98479429,12.5457386 M16.1753659,1.28850044 L5.6253659,
                  12.1981595 L6.34422268,12.8933177 L16.8942227,1.98365865 L16.1753659,1.28850044 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
