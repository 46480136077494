import * as React from 'react';
import {SubtitleRadio} from '../../SubtitleRadio';
import {ITemplateColors} from '../../../../@types/templateColors';
import {ISubtitlesMetadata} from '../../../../@types/subtitlesMetadata';
import {CustomRadioButton} from '../../CustomRadio';
import {IEnum} from '../../../../@types/enum';
import {CollapseConfigs} from '../../CollapseConfigs';

interface IAssetsListProps {
  selectedSub?: string;
  title?: string;
  onSubSelected?: (trackId: string) => void;
  templateColors: ITemplateColors;
  embeddedSubtitlesMetadata: ISubtitlesMetadata[];
  externalSubtitlesMetadata: ISubtitlesMetadata[];
  languageDialectEnums: Array<IEnum>;
}

export class SubsAssetsList extends React.PureComponent<IAssetsListProps> {
  constructor(props: IAssetsListProps) {
    super(props);
  }

  updateValue = (value: string) => {
    if (this.props.onSubSelected) {
      this.props.onSubSelected(value);
    }
  };

  renderSubs(subs: ISubtitlesMetadata[], emptyMessage: string = 'Empty subtitles', doRegroup: boolean = false) {
    if (!subs.length) {
      return <span>{emptyMessage}</span>;
    }
    let count = 0;
    const noneOption = {
      hrId: null,
      element: (
        <CustomRadioButton
          key="0"
          id="none-subtitle"
          value={''}
          text="None"
          selectedValue={this.props.selectedSub || ''}
          onChange={e => this.updateValue(e.target.value)}
          templateColors={this.props.templateColors}
        />
      )
    };
    const controls = subs.reduce(
      (acc: Array<{hrId: string; element: JSX.Element}>, sub: ISubtitlesMetadata, index: number) => {
        const subtitle = {
          hrId: sub.hrId,
          element: (
            <SubtitleRadio
              languageDialect={this.props.languageDialectEnums}
              key={index + 1}
              templateColors={this.props.templateColors}
              selectedSubId={this.props.selectedSub}
              subtitle={sub}
              updateSelectedSubtitle={this.updateValue}
              identifier="video-popup"
            />
          )
        };
        count++;
        return [...acc, subtitle];
      },
      [noneOption]
    );
    if (!doRegroup) {
      return controls.reduce(
        (acc: Array<JSX.Element>, element: {hrId: string; element: JSX.Element}) => [...acc, element.element],
        []
      );
    }
    const hrIds = controls
      .reduce((acc: Array<string>, element: {hrId: string; element: JSX.Element}) => [...acc, element.hrId], [])
      .filter(hrId => hrId)
      .filter((hrId: string, index: number, list: Array<string>) => list.indexOf(hrId) === index);
    const noValidHrIds = controls
      .filter((element: {hrId: string; element: JSX.Element}) => hrIds.indexOf(element.hrId) === -1)
      .map(element => element.hrId)
      .filter((hrId: string, index: number, list: Array<string>) => list.indexOf(hrId) === index);
    const elements = [];
    noValidHrIds.forEach((hrId: string) => {
      const hrIdAssets = controls
        .filter((element: {hrId: string; element: JSX.Element}) => element.hrId === hrId)
        .map(element => element.element);
      elements.push(...hrIdAssets);
    });
    hrIds.forEach((hrId: string) => {
      const hrIdAssets = controls
        .filter((element: {hrId: string; element: JSX.Element}) => element.hrId === hrId)
        .map(element => element.element);
      const collapsed = (
        <CollapseConfigs label={hrId} collapsed key={count}>
          {hrIdAssets}
        </CollapseConfigs>
      );
      count++;
      elements.push(collapsed);
    });
    return elements;
  }

  render() {
    const mainColor = this.props.templateColors.main;
    return (
      <div className="assets-list subs-assets-container">
        <div className="assets-list_title" style={{color: mainColor}}>
          {this.props.title || 'Subtitles'}
        </div>
        <div className="assets-list_content">
          <div className="assets-list_content_title">Embedded</div>
          <div
            className={`assets-list_content_checkboxes${!this.props.embeddedSubtitlesMetadata.length ? ` empty` : ``}`}
          >
            {this.renderSubs(this.props.embeddedSubtitlesMetadata, 'Empty embedded subtitles')}
          </div>
        </div>
        <div className="assets-list_content">
          <div className="assets-list_content_title">External</div>
          <div
            className={`assets-list_content_checkboxes${!this.props.externalSubtitlesMetadata.length ? ' empty' : ''}`}
          >
            {this.renderSubs(this.props.externalSubtitlesMetadata, 'Empty external subtitles', true)}
          </div>
        </div>
      </div>
    );
  }
}
