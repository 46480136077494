import * as React from 'react';
import {createPortal} from 'react-dom';
import {Button} from '../../../../components/Button';
import {Icon} from 'tt-components/src/Icon';

import {EditTabDataIcon} from '../../../../assets/Icons/EditTabData';
import {HourGlassIcon} from '../../../../assets/Icons/HourGlass';
import {IActionsStateProps, IActionsDispatchProps, IActionOwnProps} from '../../../../containers/Actions';

type IActionsProps = IActionsStateProps & IActionsDispatchProps & IActionOwnProps;

export class Actions extends React.PureComponent<IActionsProps> {
  onCancel = e => {
    e.stopPropagation();
    if (this.props.cancelTabsContent) {
      this.props.cancelTabsContent();
    }
  };

  onSave = e => {
    e.stopPropagation();
    if (this.props.saveTabsContent) {
      this.props.saveTabsContent();
    }
  };

  onSubmit = e => {
    e.stopPropagation();
    if (this.props.saveTabsContent) {
      this.props.saveTabsContent(true);
    }
  };

  getEditActions = () => {
    return (
      <div className="actions-container_buttons-container">
        {this.props.tabsDataInEditMode ? (
          <>
            <Button
              secondary
              disablePressState
              content="CANCEL"
              className="actions-container_buttons-container_cancel"
              onClick={this.onCancel}
            />
            <Button
              secondary
              disablePressState
              content="SAVE"
              className="actions-container_buttons-container_save"
              onClick={this.onSave}
            />
            <Button
              disablePressState
              content="SUBMIT"
              className="actions-container_buttons-container_submit"
              onClick={this.onSubmit}
            />{' '}
          </>
        ) : (
          <div
            className="actions-container_edit-btn"
            onClick={this.props.isProcessing ? null : () => this.props.startAssetEdit()}
          >
            <Icon icon={this.props.isProcessing ? HourGlassIcon : EditTabDataIcon} color="#ffffff" size="18px" />
          </div>
        )}
      </div>
    );
  };

  getActionsContent = () => {
    return <div className="actions-container">{!this.props.disableEdit && this.getEditActions()}</div>;
  };

  render() {
    const actionsContainerId = this.props.containerId;
    const closestBody = this.props.closestBodyElement && this.props.closestBodyElement.closest('body');
    const actionsContainerDOM = !!closestBody && closestBody.querySelector(`#${actionsContainerId}` || '');

    return actionsContainerDOM ? createPortal(this.getActionsContent(), actionsContainerDOM) : null;
  }
}
