import * as React from 'react';
import {Container, Row, Column} from '../../../../../Grid';
import {Dropdown} from '../../../../../../../../components/Dropdown';
import {Tt1} from '../../../../../../../../components/Typography';
import {deepCopy, getErrorLogByField} from '../../../../../../utils/helpers';
import {
  IAssetDetails,
  IAudioDataTrackDetail,
  IAudioData,
  IErrorLog
} from '../../../../../../../../../@types/assetDetails';
import {TextField} from '../../../../../../../../components/TextField';

interface ITrackDetailsEditableProps {
  track: IAudioDataTrackDetail;
  audioConfigId: string;
  assetDetails: IAssetDetails;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: any;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: IAssetDetails) => void;
}

export class TrackDetails extends React.Component<ITrackDetailsEditableProps> {
  handleClick = (name, value) => {
    const data = value !== '' ? value : null;
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const audio = (this.props.assetDetails.audio || []).reduce((acc: Array<IAudioData>, audio: IAudioData) => {
      if (audio.id === this.props.audioConfigId) {
        const trackDetail = (audio.trackDetail || []).map((track: IAudioDataTrackDetail) => {
          if (track.id === this.props.track.id) {
            track = {...track, [name]: name === 'channelMap' ? [data] : data};
          }
          return track;
        });
        audio = {...audio, trackDetail};
      }
      return [...acc, audio];
    }, []);

    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  convertEnums = enums => {
    let options = [];
    enums.map(item => {
      options.push({label: item.name, value: item.value});
    });
    return options;
  };

  getChannelEnumsData = () => {
    const channelMap =
      this.props.track.channelMap && Array.isArray(this.props.track.channelMap) ? this.props.track.channelMap : [];
    const channelMapEnums = [
      ...this.convertEnums(this.props.enums.channelMap),
      channelMap.length > 1 ? {value: 'Multiple Mapping', label: 'Multiple Mapping'} : null
    ].filter(enums => enums);
    return {channelMap, channelMapEnums};
  };

  getContent = () => {
    const trackDetailEntry = this.props.track;
    const {channelMap, channelMapEnums} = this.getChannelEnumsData();

    return (
      <div className="audio-config-container">
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Tt1 content={`Tracks ${trackDetailEntry.track}`} className="audio-details-container_row_label" />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              disabled={channelMap.length > 1}
              options={channelMapEnums}
              onSelected={value => this.handleClick('channelMap', value)}
              selected={channelMap.length > 1 ? 'Multiple Mapping' : channelMap[0] || 'No Channel Map'}
              label="Channel Map"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.channelMap', this.props.errorLogs)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <TextField
              label="Average Bit Rate"
              text={trackDetailEntry.averageBitRate}
              editMode
              onChange={value => this.handleClick('averageBitRate', value)}
              onlyNumbers
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.audioBitDepth)}
              onSelected={value => this.handleClick('bitDepth', value)}
              selected={trackDetailEntry.bitDepth}
              label="Bit Depth"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.bitDepth', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.bitRateMode)}
              onSelected={value => this.handleClick('bitRateMode', value)}
              selected={trackDetailEntry.bitRateMode}
              label="Bit Rate Mode"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.bitRateMode', this.props.errorLogs)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <TextField
              label="db Level"
              text={trackDetailEntry.dbLevel}
              editMode
              onChange={value => this.handleClick('dbLevel', value)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <TextField
              label="Element Type"
              text={trackDetailEntry.elementType}
              editMode
              onChange={value => this.handleClick('elementType', value)}
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.audioCodec)}
              onSelected={name => this.handleClick('audioCodec', name)}
              selected={trackDetailEntry.audioCodec}
              label="Audio Codec"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.audioCodec', this.props.errorLogs)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <TextField
              label="Peak Bit Rate"
              text={trackDetailEntry.peakBitRate}
              editMode
              onChange={value => this.handleClick('peakBitRate', value)}
              onlyNumbers
            />
          </Column>
          <Column className={`audio-details-container_row_column `}>
            <TextField
              label="Sample Rate"
              text={trackDetailEntry.sampleRate}
              editMode
              onChange={value => this.handleClick('sampleRate', value)}
              onlyNumbers
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.audioSubType)}
              onSelected={value => this.handleClick('subType', value)}
              selected={trackDetailEntry.subType}
              label="Sub Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.subType', this.props.errorLogs)}
            />
          </Column>
          <Column className={`audio-details-container_row_column `}>
            <TextField
              label="Actual Length"
              text={trackDetailEntry.actualLength}
              editMode
              onChange={value => this.handleClick('actualLength', value)}
            />
          </Column>
          <Column className="audio-details-container_row_column" />
        </Row>
      </div>
    );
  };

  render() {
    return <Container className="audio-details-container">{this.getContent()}</Container>;
  }
}
