import {IVideoState} from './IVideoState';
import {IAppConfig} from '../../@types/appConfig';
import {IOverlays} from '../../@types/overlays';
import {IBaseTextTrack} from 'tt-components';
import {IAudioChannelConfiguration} from '../../@types/audioChannelConfiguration';
import {IVideoSubs} from './IVideoSubs';
import {ITimelineState} from '../modules/Timeline/interfaces/ITimelineState';
import {IThumbnailTrack} from './IThumbnailTrack';
import {ITabsState} from '../modules/Tabs/interfaces/ITabsState';
import {IFrameRate} from 'tt-components/src/frameRate';
import {IAssetDetails} from '../../@types/assetDetails';
import {IAssetType} from '../../@types/assetType';
import {IAssetStatus} from '../../@types/assetStatus';
import {IAssetTitleType} from '../../@types/assetTitleType';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {IQualityMetrics} from '../../@types/qualityMetrics';

export interface IAppState {
  /**
   * The state of video stream
   */
  video: IVideoState;
  /**
   * The state of quality metrics for the loaded video
   */
  qualityMetrics: IQualityMetrics;
  /**
   * The state of visibility for overlays
   */
  overlays: IOverlays;
  /**
   * Project-level error
   */
  error?: IError;
  /**
   * Set of configurations for the player
   */
  configuration: IAppConfig;
  /**
   * The state of the Timeline component
   */
  timeline: ITimelineState;
  /**
   * The state of the Tabs component
   */
  tabs: ITabsState;
}

export interface IError {
  name: string;
  message: string;
  stack?: string;
}

export interface ITextTrack extends IBaseTextTrack {
  url?: string;
  showOnPlayer: boolean;
  columnIndex: number;
}

export interface IFeatureCredentials {
  featureId: string;
  featureVersionId: string;
}

export interface ISeriesCredentials {
  seriesId: string;
  seasonId: string;
  episodeId: string;
  episodeVersionId: string;
}

export interface IAssetCredentials {
  data: IFeatureCredentials | ISeriesCredentials;
  type: IAssetTitleType;
  isRegistered: boolean;
  assetId?: string;
  conformanceGroupId?: string;
}

export type IPlaylistAtlas = Partial<IFeatureCredentials> &
  Partial<ISeriesCredentials> & {
    assetId: string;
    isRegistered?: boolean;
    isNonMedia?: boolean;
    assetDetails?: IAssetDetails;
    titleRecord?: IPlaylistTitle;
  };

export interface IPlaylistTitleRecord {
  id: string;
  hrId: string;
  name: string;
  type: string;
  isActive: boolean;
}

export interface IPlaylistTitle {
  id: string;
  hrId: string;
  name: string;
  type: IAssetTitleType;
  releaseYear: string;
  versions: Array<IPlaylistTitleRecord>;
}

//@todo create full app playlist type
export interface IAppPlaylist {
  title: string;
  atlas: Array<IPlaylistAtlas>;
  url: string;
  externalSubs: Array<IVideoSubs>;
  embeddedSubs: Array<IVideoSubs>;
  assets: Array<PlaylistAsset>;
  thumbnailTrack?: IThumbnailTrack;
  startTime?: number;
  selectedSub?: string;
  id: string;
  /**
   * Indicated frame rate of current media and if current media represented using drop frame
   */
  frameRate: IFrameRate;
  loading: boolean;
  error: string;
  /**
   * Store counter for the checking calls to playlist end-point for asset status
   */
  stageCount: number;
  /**
   * Staging flag will be used to mark staging state of the playlist until all assets will be staged
   */
  staging: boolean;
  /**
   * This flag will be used as indicator for request to force staging process of playlist assets
   */
  forcingStaging: boolean;
  /**
   * Will store the proxy audio configuration
   */
  proxy: IAudioChannelConfiguration;
  /**
   * Store the asset ID for the selected asset that will display data in the Tabs module
   */
  selectedAssetId: string;
  /**
   * Store audio configuration data for the video asset of the Playlist
   */
  video: IAudioChannelConfiguration;
}
export enum MediaTimeFormat {
  NonDropFrameTimecode,
  DropFrameTimecode,
  MillisecondBasedTimecode,
  FramesOnlyTimecode
}
export interface ISettings {
  mediaTimeFormat: MediaTimeFormat;
}

export interface IComplianceData {
  id: string;
  assetId: string;
  distributorId: string;
  versionId: string;
  editType: string;
  reasons: Array<string>;
  notes: string;
  risk: string;
  timeIn: string;
  timeOut: string;
}

export interface IPlaylistAsset {
  assetId: string;
  assetType: IAssetType;
  assetStatus: IAssetStatus;
  path: string;
  vfsName: string;
}

export interface IPlaylistContentResponse {
  id: string;
  assets: Array<IPlaylistAsset>;
  assetDetails?: Array<any>;
}

export interface ISubtitlesUrlData {
  assetId: string;
  url: string;
  parentAssetId?: string;
  filename?: string;
}

export interface IWaveformsUrlData {
  assetId: string;
  parentAssetId: string;
  type: string;
  status: string;
  filename: string;
  url: string;
  channel: string;
  track: string;
}

export interface IThumbnailsUrlData {
  assetId: string;
  parentAssetId: string;
  type: string;
  status: string;
  filename: string;
  url: string;
}
