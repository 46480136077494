import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const CsvIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '14px'}
      height={props.size || '14px'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
    >
      <g>
        <path
          fill={props.color || '#1F72F2'}
          d="M62.4,504.5v283.2h626.3V504.5H62.4z 
        M265.3,717.2c-11.2,8.5-25.4,12.7-42.6,12.7c-21.3,0-38.7-7.3-52.4-21.8c-13.7-14.5-20.5-34.4-20.5-59.6c0-26.7,
        6.9-47.4,20.6-62.1c13.8-14.8,31.9-22.1,54.3-22.1c19.6,0,35.5,5.8,47.7,17.4c7.3,6.8,12.7,16.7,16.4,29.5l-32,
        7.6c-1.9-8.3-5.8-14.9-11.9-19.7c-6-4.8-13.3-7.2-21.9-7.2c-11.9,0-21.5,4.3-28.9,12.8c-7.4,8.5-11.1,22.3-11.1,
        41.4c0,20.2,3.6,34.7,10.9,43.3c7.3,8.6,16.8,12.9,28.4,12.9c8.6,0,16-2.7,22.2-8.2c6.2-5.5,10.6-14.1,
        13.3-25.8l31.4,9.9C284.5,695.7,276.5,708.7,265.3,717.2z M431.5,706.3c-5.1,8-12.3,14-21.6,17.9c-9.3,
        3.9-20.9,5.8-34.9,5.8c-20.2,0-35.8-4.7-46.7-14c-10.9-9.4-17.3-23-19.4-40.9l31.5-3.1c1.9,10.6,5.7,18.3,
        11.5,23.3c5.8,5,13.6,7.4,23.4,7.4c10.4,0,18.3-2.2,23.5-6.6c5.3-4.4,7.9-9.6,
        7.9-15.5c0-3.8-1.1-7-3.3-9.7c-2.2-2.7-6.1-5-11.6-6.9c-3.8-1.3-12.4-3.6-25.9-7
        c-17.3-4.3-29.5-9.6-36.5-15.8c-9.8-8.8-14.7-19.6-14.7-32.2c0-8.2,
        2.3-15.8,6.9-22.9c4.6-7.1,11.3-12.5,20-16.2c8.7-3.7,
        19.2-5.6,31.5-5.6c20.1,0,35.2,4.4,45.4,13.2c10.2,8.8,15.5,20.6,16,35.3l-32.3,
        1.4c-1.4-8.2-4.4-14.1-8.9-17.8c-4.6-3.6-11.4-5.4-20.5-5.4c-9.4,0-16.8,1.9-22.1,5.8c-3.4,
        2.5-5.1,5.8-5.1,9.9c0,3.8,1.6,7,4.8,9.7c4.1,3.4,14,7,29.7,10.7c15.7,
        3.7,27.4,7.6,34.9,11.5c7.5,4,13.4,9.4,17.7,16.3c4.3,6.9,6.4,15.4,6.4,25.5C439.1,689.7,436.6,698.3,431.5,
        706.3z M541.7,727.2h-34.5L449.9,567H485l40.5,118.5L564.7,567h34.3L541.7,727.2z"
        />
        <path
          fill={props.color || '#1F72F2'}
          d="M663.2,10.4H258c-34.3-3.8-59.3,22.4-55.6,54.4L202,
        392.9h0.6v112.7h76.6V392.9V86.6h367.4v229.7H861v597.3H279.2V785.4h-77l0.2,146.4c-3.7,
        32,21.2,58.2,51.8,58.2H882c30.6,0,55.6-26.2,55.6-58.2V297.8L663.2,10.4z"
        />
      </g>
    </svg>
  );
};
