import * as React from 'react';
import {Dropdown} from 'tt-components/src/Dropdown';
import {defaultMenuControlBarStyles} from '../../../../../../components/DropdownContent';
import {file} from 'tt-components/src/Utils/file';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'xlsx';
import {Icon} from 'tt-components/src/Icon';

import {ExportIcon} from '../../../../../../assets/Icons/Export';
import {ExcelIcon} from '../../../../assets/Icons/ExcelIcon';
import {CsvIcon} from '../../../../assets/Icons/CsvIcon';
import {PdfIcon} from '../../../../assets/Icons/PdfIcon';

export interface IGoToLocationProps {
  edl: string[][];
  closestBody: HTMLElement;
  showingDropdownTimeout: number;
  headRow: Array<string>;
  selectedEventGroup: string;
}

const headRow = ['Type', 'Timecode In', 'Timecode Out', 'Notes'];

export class DownloadEdl extends React.PureComponent<IGoToLocationProps> {
  readonly alphabetXlsSheet: Array<string> = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

  getHeadRow = () => {
    return this.props.headRow && this.props.headRow.length ? this.props.headRow : headRow;
  };

  saveEdlToCsv() {
    const {edl, selectedEventGroup} = this.props;
    let fileContent;
    fileContent = file.exportToCsv([this.getHeadRow(), ...edl], ',', false);
    file.download(fileContent, `${selectedEventGroup}.csv`, 'application/octet-stream');
  }

  saveEdlToPdf() {
    const {edl, selectedEventGroup} = this.props;
    let doc = new jsPDF();

    doc.autoTable({
      head: [this.getHeadRow()],
      body: edl
    });

    doc.save(`${selectedEventGroup}.pdf`);
  }

  saveToExcel() {
    const {edl, selectedEventGroup} = this.props;
    let sheetHeadData = {};
    this.getHeadRow().forEach((dataEl: string, index: number) => {
      let xlsSheetTopLetter = this.alphabetXlsSheet[index];
      sheetHeadData[xlsSheetTopLetter] = dataEl;
    });

    let sheetContentData = [];
    edl.forEach((edlRow: Array<string>, index: number) => {
      let sheetRow = {};
      edlRow.forEach((dataEl: string, index: number) => {
        let xlsSheetTopLetter = this.alphabetXlsSheet[index];
        sheetRow[xlsSheetTopLetter] = dataEl;
      });
      sheetContentData.push(sheetRow);
    });

    let workSheetCols = [{wch: 10}, {wch: 11}, {wch: 11}, {wch: 15}, {wch: 15}];

    let workBook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.json_to_sheet([sheetHeadData, ...sheetContentData], {skipHeader: true});
    workSheet['!cols'] = workSheetCols;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Edl');
    XLSX.writeFile(workBook, `${selectedEventGroup}.xlsx`);
  }

  render() {
    const {closestBody, showingDropdownTimeout} = this.props;
    return (
      <Dropdown
        title="Download EDL"
        buttonComponent={() => <Icon icon={ExportIcon} color="#fff" size="20px" />}
        content={
          <>
            <div className="markup-container_download-edl-dropdown_option-items-wrapper">
              <div className="markup-container_download-edl-dropdown_header">Download EDL</div>
              <div
                className="markup-container_download-edl-dropdown_option-item"
                onClick={e => {
                  this.saveEdlToCsv();
                }}
              >
                <Icon icon={CsvIcon} color="#1F72F2" size="20px" /> CSV
              </div>
              <div
                className="markup-container_download-edl-dropdown_option-item"
                onClick={e => {
                  this.saveToExcel();
                }}
              >
                <Icon icon={ExcelIcon} color="#1F72F2" size="20px" /> Excel
              </div>
              <div
                className="markup-container_download-edl-dropdown_option-item"
                onClick={e => {
                  this.saveEdlToPdf();
                }}
              >
                <Icon icon={PdfIcon} color="#1F72F2" size="20px" /> PDF
              </div>
            </div>
            <div className="markup-container_download-edl-dropdown_tail-top" />
            <div className="markup-container_download-edl-dropdown_tail-bottom" />
          </>
        }
        className="markup-container_download-edl-dropdown"
        openAbove={true}
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={closestBody}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles
};
