import {SeekType} from '../components/OnePlayerControlBar/onePlayerControlBarProps';

export interface IHandleControlBar {
  seek: (value: number, type: SeekType, seekBarUpdated?: boolean) => void;
  goto: (time: number) => void;
  switchPlaying: () => void;
  switchLoop: (loop) => void;
  onPlaybackRateChange: (playbackRate: number) => void;
  onForwardAndRewindRateChange: (forwardAndRewindRate: number) => void;
  onFastBackward: () => void;
  onFastForward: () => void;
  onStart: () => void;
  onEnd: () => void;
  switchThirdIndicator: () => void;
  onFirstFrame: () => void;
}

export const holderHandleControlBar: IHandleControlBar = {
  seek: (value: number, type: SeekType, seekBarUpdated: boolean) => {},
  goto: (time: number) => {},
  switchPlaying: () => {},
  switchLoop: loop => {},
  onPlaybackRateChange: (playbackRate: number) => {},
  onForwardAndRewindRateChange: (forwardAndRewindRate: number) => {},
  onFastBackward: () => {},
  onFastForward: () => {},
  onStart: () => {},
  onEnd: () => {},
  switchThirdIndicator: () => {},
  onFirstFrame: () => {}
};
