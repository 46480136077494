import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Dropdown} from '../../../../Dropdown';
import {
  searchTitles,
  getSeasonsBySeriesId,
  getEpisodesBySeriesSeasonId,
  getVersionsByEpisodeSeasonSeriesId,
  getConformanceGroupsByVersionEpisodeSeasonSeriesId
} from '../../../../../data/atlasAPI';
import {ISearchTitleParams, ISearchTitle} from '../../../../../../@types/searchTitle';
import {parseOptions, parseWithIdOptions, parseSeasonOptions, parseEpisodeOptions} from '../../../../../utils/utils';
import {PlaylistAsset} from '../../../../../models/PlaylistAsset/PlaylistAsset';
import {getUniqueArrayObjectsByField} from '../../../../../modules/Tabs/utils/helpers';
import {ISeriesCredentials} from '../../../../../state/IAppState';

interface ISeriesProps {
  onConformanceSelected: (titles: Array<ISearchTitle>) => void;
  closestBodyElement?: HTMLElement;
  data: ISeriesCredentials;
}

interface ISeriesFieldState {
  selectedSeries: string;
  seriesOptions: Array<ISearchTitle>;
  searchString: string;
  filtering: boolean;
  loadingSeries: boolean;
}

interface ISeasonFieldState {
  selectedSeason: string;
  seasonOptions: Array<ISearchTitle>;
  loadingSeason: boolean;
}

interface IEpisodeFieldState {
  selectedEpisode: string;
  episodeOptions: Array<ISearchTitle>;
  loadingEpisode: boolean;
}

interface IVersionFieldState {
  selectedVersion: string;
  versionOptions: Array<ISearchTitle>;
  loadingVersion: boolean;
}

interface IConfromanceGroupState {
  selectedConformanceGroup: string;
  conformanceGroupOptions: Array<ISearchTitle>;
  loadingConformanceGroup: boolean;
}

type ISeriesState = ISeriesFieldState &
  ISeasonFieldState &
  IEpisodeFieldState &
  IVersionFieldState &
  IConfromanceGroupState;

export class Series extends React.Component<ISeriesProps, ISeriesState> {
  static defaultProps = {
    closestBodyElement: null
  };

  constructor(props: ISeriesProps) {
    super(props);

    this.state = {
      selectedSeries: null,
      seriesOptions: [],
      searchString: '',
      filtering: false,
      loadingSeries: false,
      selectedSeason: null,
      seasonOptions: [],
      loadingSeason: false,
      selectedEpisode: null,
      episodeOptions: [],
      loadingEpisode: false,
      selectedVersion: null,
      versionOptions: [],
      loadingVersion: false,
      selectedConformanceGroup: null,
      conformanceGroupOptions: [],
      loadingConformanceGroup: false
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: ISeriesProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.init();
    }
  }

  init = async () => {
    const {seriesId, seasonId, episodeId, episodeVersionId} = this.props.data;
    if (!seriesId) {
      return;
    }
    await new Promise(resolve => this.setState({loadingSeries: true}, resolve));
    // Load series, select provided serie and load related seasons
    await this.querySeries(seriesId, 'atlasId');
    await new Promise(resolve => this.onSeriesSelected(seriesId, resolve));
    if (!seasonId) {
      return;
    }
    // Select provided season and load related episodes
    await new Promise(resolve => this.onSeasonSelected(seasonId, resolve));
    if (!episodeId) {
      return;
    }
    // Select provided episode and load related versions
    await new Promise(resolve => this.onEpisodeSelected(episodeId, resolve));
    if (!episodeVersionId) {
      return;
    }
    // Select provided version and load related conformance groups
    await new Promise(resolve => this.onVersionSelected(episodeVersionId, resolve));
  };

  onSeriesSearch = async (searchString: string) => {
    this.setState({searchString, filtering: true});
    await this.querySeries(searchString);
    this.setState({filtering: false});
  };

  querySeries = async (value: string, prop: 'name' | 'atlasId' = 'name') => {
    const seriesRecord = this.state.seriesOptions.find((title: ISearchTitle) => title.id === this.state.selectedSeries);
    const params: ISearchTitleParams = {
      [prop]: value,
      size: 100,
      types: 'Series'
    };
    const seriesResponse = await searchTitles(params);
    const seriesOptions = getUniqueArrayObjectsByField(
      [...seriesResponse.data, seriesRecord].filter(title => title),
      'id'
    );
    this.setState({seriesOptions, loadingSeries: false});
  };

  onSeriesSelected = (selectedSeries: string, callback?: () => void) => {
    this.setState(
      {
        selectedSeries,
        selectedSeason: null,
        seasonOptions: [],
        selectedEpisode: null,
        episodeOptions: [],
        selectedVersion: null,
        versionOptions: [],
        selectedConformanceGroup: null,
        conformanceGroupOptions: []
      },
      async () => {
        this.props.onConformanceSelected([]);
        await this.querySeasons();
        if (callback) {
          callback();
        }
      }
    );
  };

  querySeasons = async () => {
    this.setState({loadingSeason: true});
    const seasonsResponse = await getSeasonsBySeriesId(this.state.selectedSeries);
    const seasonOptions = seasonsResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
    this.setState({loadingSeason: false, seasonOptions});
  };

  onSeasonSelected = (selectedSeason: string, callback?: () => void) => {
    this.setState(
      {
        selectedSeason,
        selectedEpisode: null,
        episodeOptions: [],
        selectedVersion: null,
        versionOptions: [],
        selectedConformanceGroup: null,
        conformanceGroupOptions: []
      },
      async () => {
        this.props.onConformanceSelected([]);
        await this.queryEpisodes();
        if (callback) {
          callback();
        }
      }
    );
  };

  queryEpisodes = async () => {
    this.setState({loadingEpisode: true});
    const episodesResponse = await getEpisodesBySeriesSeasonId(this.state.selectedSeries, this.state.selectedSeason);
    const episodeOptions = episodesResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
    this.setState({loadingEpisode: false, episodeOptions});
  };

  onEpisodeSelected = (selectedEpisode: string, callback?: () => void) => {
    this.setState(
      {
        selectedEpisode,
        selectedVersion: null,
        versionOptions: [],
        selectedConformanceGroup: null,
        conformanceGroupOptions: []
      },
      async () => {
        this.props.onConformanceSelected([]);
        await this.queryVersions();
        if (callback) {
          callback();
        }
      }
    );
  };

  queryVersions = async () => {
    this.setState({loadingVersion: true});
    const versionsResponse = await getVersionsByEpisodeSeasonSeriesId(
      this.state.selectedSeries,
      this.state.selectedSeason,
      this.state.selectedEpisode
    );
    const versionOptions = versionsResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
    this.setState({loadingVersion: false, versionOptions});
  };

  onVersionSelected = (selectedVersion: string, callback?: () => void) => {
    this.setState(
      {
        selectedVersion,
        selectedConformanceGroup: null,
        conformanceGroupOptions: []
      },
      async () => {
        this.props.onConformanceSelected([]);
        await this.queryConformanceGroups();
        if (callback) {
          callback();
        }
      }
    );
  };

  queryConformanceGroups = async () => {
    this.setState({loadingConformanceGroup: true});
    const confromanceGroupsResponse = await getConformanceGroupsByVersionEpisodeSeasonSeriesId(
      this.state.selectedSeries,
      this.state.selectedSeason,
      this.state.selectedEpisode,
      this.state.selectedVersion
    );
    const conformanceGroupOptions = confromanceGroupsResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
    this.setState({loadingConformanceGroup: false, conformanceGroupOptions});
  };

  onConfromanceGroupSelected = (selectedConformanceGroup: string) => {
    this.setState({selectedConformanceGroup}, () => {
      const seriesTitle = this.state.seriesOptions.find(
        (series: ISearchTitle) => series.id === this.state.selectedSeries
      );
      const seasonTitle = this.state.seasonOptions.find(
        (season: ISearchTitle) => season.id === this.state.selectedSeason
      );
      const episodeTitle = this.state.episodeOptions.find(
        (episode: ISearchTitle) => episode.id === this.state.selectedEpisode
      );
      const episodeVersionTitle = this.state.versionOptions.find(
        (version: ISearchTitle) => version.id === this.state.selectedVersion
      );
      const episodeConformanceGroupTitle = this.state.conformanceGroupOptions.find(
        (conformance: ISearchTitle) => conformance.id === this.state.selectedConformanceGroup
      );
      this.props.onConformanceSelected([
        {...seriesTitle, type: 'Series'},
        {...seasonTitle, type: 'Season'},
        {...episodeTitle, type: 'Episode'},
        {...episodeVersionTitle, type: 'EpisodeVersion'},
        {...episodeConformanceGroupTitle, type: 'EpisodeConformance'}
      ]);
    });
  };

  render() {
    return (
      <div className="series-search-container">
        <div className="series-search-container_dropdown-container bottom-padding">
          <Dropdown
            label="Series Name *"
            selectedPlaceholder={this.state.loadingSeries ? 'Loading...' : 'Select series...'}
            emptyPlaceholder={this.state.loadingSeries ? 'Loading...' : this.state.filtering ? 'Filtering...' : 'Empty'}
            options={this.state.seriesOptions.map(parseOptions)}
            selected={this.state.selectedSeries}
            disabled={this.state.loadingSeries}
            search
            searchOnEnter
            onSelected={this.onSeriesSelected}
            searchValue={this.state.searchString}
            onSearch={this.onSeriesSearch}
            disableSearchInput={this.state.filtering}
            portalNode={this.props.closestBodyElement}
            contentListLimit={5}
          />
        </div>
        <div className="series-search-container_dropdown-container bottom-padding">
          <Dropdown
            label="Season # / Name *"
            selectedPlaceholder={this.state.loadingSeason ? 'Loading...' : 'Select season...'}
            disabled={this.state.loadingSeason}
            search
            options={this.state.seasonOptions.map(parseSeasonOptions)}
            selected={this.state.selectedSeason}
            onSelected={this.onSeasonSelected}
            portalNode={this.props.closestBodyElement}
            contentListLimit={5}
          />
        </div>
        <div className="series-search-container_dropdown-container bottom-padding">
          <Dropdown
            label="Episode # / Name *"
            selectedPlaceholder={this.state.loadingEpisode ? 'Loading...' : 'Select episode...'}
            disabled={this.state.loadingEpisode}
            search
            options={this.state.episodeOptions.map(parseEpisodeOptions)}
            selected={this.state.selectedEpisode}
            onSelected={this.onEpisodeSelected}
            portalNode={this.props.closestBodyElement}
            contentListLimit={5}
          />
        </div>
        <div className="series-search-container_dropdown-container bottom-padding">
          <Dropdown
            label="Version Name / ID *"
            selectedPlaceholder={this.state.loadingVersion ? 'Loading...' : 'Select version...'}
            disabled={this.state.loadingVersion}
            search
            options={this.state.versionOptions.map(parseWithIdOptions)}
            selected={this.state.selectedVersion}
            onSelected={this.onVersionSelected}
            portalNode={this.props.closestBodyElement}
            contentListLimit={5}
          />
        </div>
        <div className="series-search-container_dropdown-container bottom-padding">
          <Dropdown
            label="Confromance Group Name / ID *"
            selectedPlaceholder={this.state.loadingConformanceGroup ? 'Loading...' : 'Select conformance group...'}
            disabled={this.state.loadingConformanceGroup}
            search
            options={this.state.conformanceGroupOptions.map(parseWithIdOptions)}
            selected={this.state.selectedConformanceGroup}
            onSelected={this.onConfromanceGroupSelected}
            portalNode={this.props.closestBodyElement}
            contentListLimit={5}
          />
        </div>
      </div>
    );
  }
}
