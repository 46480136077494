import * as React from 'react';

export interface IButtonConfig {
  value: string;
  content: string | JSX.Element;
  hasError?: boolean;
}

export interface IButtonsGroupProps {
  options: Array<IButtonConfig>;
  selectedConfig: string;
  onOptionChanged: (value: string) => void;
}

interface IButtonsGroupState {
  selectedConfig: string;
}

export class ButtonsGroup extends React.Component<IButtonsGroupProps, IButtonsGroupState> {
  static getDerivedStateFromProps(nextProps: IButtonsGroupProps, prevState: IButtonsGroupState) {
    if (nextProps.selectedConfig !== prevState.selectedConfig) {
      return {selectedConfig: nextProps.selectedConfig};
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedConfig: null
    };
  }

  createOption = (config: IButtonConfig, index: number) => {
    const selected = this.state.selectedConfig === config.value;
    const selectedClass = selected ? ` selected` : ``;
    const errorClass = config.hasError ? ' error' : '';
    return (
      <div
        key={index}
        className={`buttons-group-container_option${selectedClass}${errorClass}`}
        onClick={e => this.props.onOptionChanged(config.value)}
      >
        {config.content}
      </div>
    );
  };

  render() {
    return (
      <div className="buttons-group-container">
        {this.props.options.map((option: IButtonConfig, index: number) => this.createOption(option, index))}
      </div>
    );
  }
}
