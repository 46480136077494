import {createAction} from 'redux-actions';
import {ITimelineLayers} from '../interfaces';
import {IAppState} from '../../../state';

export const UPDATE_LAYER_VISIBILITY = 'Timline/UPDATE_LAYER_VISIBILITY';
export type UPDATE_LAYER_VISIBILITY = {[x: string]: boolean};
export const updateLayerVisibility = (data: {layer: ITimelineLayers; visible: boolean}) => {
  return (dispatch, getState: () => IAppState) => {
    let updateLayerVisibilityObject: any = {};

    switch (data.layer) {
      case ITimelineLayers.Thumbnails:
        updateLayerVisibilityObject.showImageThumbnailsLayer = data.visible;
        break;
      case ITimelineLayers.ProgramsTiming:
        updateLayerVisibilityObject.showProgramTimingsLayer = data.visible;
        break;
      case ITimelineLayers.Markups:
        updateLayerVisibilityObject.showMarkupsLayer = data.visible;
        break;
      case ITimelineLayers.Comments:
        updateLayerVisibilityObject.showCommentsLayer = data.visible;
        break;
      case ITimelineLayers.Audio:
        updateLayerVisibilityObject.showAudioLayer = data.visible;
        break;
      case ITimelineLayers.Subtitles:
        updateLayerVisibilityObject.showSubtitlesLayer = data.visible;
        break;
      default:
        updateLayerVisibilityObject = {};
    }

    dispatch({
      type: UPDATE_LAYER_VISIBILITY,
      payload: updateLayerVisibilityObject
    });
  };
};

export const UPDATE_TIMELINE_VIEW = 'Timeline/UPDATE_TIMELINE_VIEW';
export type UPDATE_TIMELINE_VIEW = boolean;
export const updateTimelineView = createAction<UPDATE_TIMELINE_VIEW, UPDATE_TIMELINE_VIEW>(
  UPDATE_TIMELINE_VIEW,
  (visible: UPDATE_TIMELINE_VIEW) => visible
);
