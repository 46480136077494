import * as React from 'react';

interface IAudioLayerLabelProps {
  audioAndSubsElement: JSX.Element;
}

export class AudioLayerLabel extends React.PureComponent<IAudioLayerLabelProps> {
  render() {
    return <div className="audiolayer-label-container aligned">{this.props.audioAndSubsElement}</div>;
  }
}
