import {Action, handleActions} from 'redux-actions';
import {IAppConfig} from '../../@types/appConfig';
import {IVideoOptions} from '../../@types/videoOptions';
import {IGTMOptions} from '../../@types/gtm';
import {SET_PLAYER_WIDTH, SET_TEMPLATE_COLORS} from '../actions/configuration';
import {IControlBarControls} from '../../@types/controlBarControls';

export const DEFAULT_WIDTH_OPTIONS = {
  maxWidth: 5120,
  width: 1200,
  minWidth: 1200
};

export const DEFAULT_VIDEO_OPTIONS: IVideoOptions = {
  showControls: true,
  showNavigationBar: true,
  showSpeedSetting: true,
  showVolume: true,
  showNextPrevSubtitleButtons: false,
  showNextPrevSeekButtons: true,
  showNextPrevFrameButtons: false,
  showFramesTime: true,
  showMillisecondsTime: true,
  widthOptions: null
};

const DEFAULT_GTM_OPTIONS: IGTMOptions = {
  gtmId: '',
  auth: '',
  preview: ''
};

export const DEFAULT_CONTROL_BAR_CONTROLS: IControlBarControls[] = [
  'GoToBeginning',
  'FastRewind',
  'QuickBack',
  'FrameRewind',
  'Play',
  'FrameAdvance',
  'QuickFwd',
  'FastFwd',
  'GoToEnd',
  'GoToLocation',
  'Loop',
  'Speed',
  'Volume',
  'GeneralSettings',
  'AudioAndSubs',
  'FullScreenMode'
];

const oneUiHost = `${location.protocol}//${location.host}/`;
export const DEFAULT_CONFIGURATION_STATE: IAppConfig = {
  version: '0.1',
  userIp: '127.0.0.1',
  userEmail: 'test@user.com',
  apiTTmanHost: 'https://ttman.dmlib.de/v1/',
  apiAtlasHost: 'https://atlas-api.dmlib.de/v1/',
  apiHybrikHost: 'https://api-dev.deluxe.hybrik.com:443/v1/',
  apiOneUiHost: 'https://one-ui.dmlib.de/api/deluxe/',
  onePlayerServiceHost: 'https://one-player-service-api.dmlib.de/v1/',
  oneCustomerHost: 'https://one-ui.dmlib.de/api/deluxe/passthrough/customers/v1/',
  oneUiHost,
  apiAccessToken: '',
  templateColors: {
    main: '#ffffff',
    highlight: '#378fef',
    tooltipBackground: 'rgba(27, 30, 36, 0.89)'
  },
  showingDropdownTimeout: 250,
  videoOptions: DEFAULT_VIDEO_OPTIONS,
  gtmOptions: DEFAULT_GTM_OPTIONS,
  controlBarControls: DEFAULT_CONTROL_BAR_CONTROLS,
  curationModeEnabled: false,
  bitmovinKey: 'da8ff527-3b1b-401e-a0b7-0918fb477477',
  autoSelectPlaybackConfig: false,
  doAssetContentTypeCheck: false
};

export default handleActions<IAppConfig, any>(
  {
    [SET_PLAYER_WIDTH]: (state: IAppConfig, action: Action<SET_PLAYER_WIDTH>): IAppConfig => ({
      ...state,
      videoOptions: {
        ...state.videoOptions,
        widthOptions: {
          ...state.videoOptions.widthOptions,
          width: action.payload
        }
      }
    }),
    [SET_TEMPLATE_COLORS]: (state: IAppConfig, action: Action<SET_TEMPLATE_COLORS>): IAppConfig => ({
      ...state,
      templateColors: {
        ...state.templateColors,
        ...action.payload
      }
    })
  },
  DEFAULT_CONFIGURATION_STATE
);
