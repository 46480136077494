export enum PlayerEventsExposed {
  Pause = 1,
  Play,
  MediaTimeChangedSecBased,
  MediaTimeChangedFrameBased,
  VideoFragmentInTimeChanged,
  VideoFragmentOutTimeChanged
}

export const PlayerEventExposedNames: {[k: number]: string} = {
  [PlayerEventsExposed.Pause]: 'pause',
  [PlayerEventsExposed.Play]: 'play',
  [PlayerEventsExposed.MediaTimeChangedSecBased]: 'mediaTimeChangedSecBased',
  [PlayerEventsExposed.MediaTimeChangedFrameBased]: 'mediaTimeChangedFrameBased',
  [PlayerEventsExposed.VideoFragmentInTimeChanged]: 'inTimeChanged',
  [PlayerEventsExposed.VideoFragmentOutTimeChanged]: 'outTimeChanged'
};

export enum PlayerEventsApi {
  FrameForward = 1,
  FrameBackward,
  SkipForward10Sec,
  SkipBackward10sec,
  SkipToBeginning,
  StartFastRewind,
  StopFastRewind,
  StartFastForward,
  StopFastForward,
  GoToTimecode
}

export const PlayerEventApiNames: {[k: number]: string} = {
  [PlayerEventsApi.FrameForward]: 'Api/frameForward',
  [PlayerEventsApi.FrameBackward]: 'Api/frameBackward',
  [PlayerEventsApi.SkipForward10Sec]: 'Api/frameForward10Sec',
  [PlayerEventsApi.SkipBackward10sec]: 'Api/frameBackward10Sec',
  [PlayerEventsApi.SkipToBeginning]: 'Api/skipToBeginning',
  [PlayerEventsApi.StartFastRewind]: 'Api/startFastRewind',
  [PlayerEventsApi.StopFastRewind]: 'Api/stopFastRewind',
  [PlayerEventsApi.StartFastForward]: 'Api/startFastForward',
  [PlayerEventsApi.StopFastForward]: 'Api/stopFastForward',
  [PlayerEventsApi.GoToTimecode]: 'Api/goToTimecode'
};

export enum FastFrameType {
  fastForward = 1,
  fastRewind
}

export const BitMovinErrors = {
  SourceCouldNotLoadManifest: 'SOURCE_COULD_NOT_LOAD_MANIFEST',
  SourceManifestInvalid: 'SOURCE_MANIFEST_INVALID'
};

export const channelsMapping = Object.freeze({
  bl: 'left',
  br: 'right',
  c: 'left', // Update when optional mapping is available
  l: 'left',
  r: 'right',
  ls: 'left',
  lt: 'left',
  lfe: 'left', // Update when optional mapping is available
  m: 'left', // Update when optional mapping is available
  rs: 'right',
  rt: 'right'
});
