import * as React from 'react';
import * as isEqual from 'deep-equal';
import {IParsedVTT} from '../../../../../../../@types/parsedVtt';
import {defineThumbnailStyleFromCue, getRemoteImageWidth} from '../../../../../../utils/utils';
import {ScrollContainer} from '../../../ScrollContainer';

interface IThumbnailsContentProps {
  secondUnits: number;
  jsonVTT: IParsedVTT[];
  scrollLeft: number;
  contentWidth: number;
  scale: number;
}

interface IThumbnailsContentState {
  spriteFileWidth: number;
}

const thumbnailInterval = 10; // in seconds
const thumbnailReduceFactor = 3; // times

export class ThumbnailsContent extends React.Component<IThumbnailsContentProps, IThumbnailsContentState> {
  containerRefElement: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      spriteFileWidth: 0
    };
  }

  componentDidUpdate(prevProps: IThumbnailsContentProps) {
    if (!isEqual(prevProps.jsonVTT, this.props.jsonVTT)) {
      this.updateSpriteFileWidth(this.props.jsonVTT);
    }
  }

  updateSpriteFileWidth = async (data: IParsedVTT[]) => {
    let spriteFileWidth = 0;
    if (data.length) {
      const width = await getRemoteImageWidth(data[0].part.split('?xywh')[0]);
      spriteFileWidth = width;
    }
    this.setState({spriteFileWidth});
  };

  getThumbnailsData = () => {
    let containerWidth = 0;
    let containerHeight = 0;
    const thumbnailsList = [];

    this.props.jsonVTT.forEach((data: IParsedVTT, index: number) => {
      const style = defineThumbnailStyleFromCue(
        data,
        thumbnailReduceFactor,
        this.state.spriteFileWidth
      ) as React.CSSProperties;
      const baseSecondUnits = this.props.secondUnits / this.props.scale;
      const isSecondUnitsFactorOfThumbnailsWidth =
        baseSecondUnits % ((style.width as number) / thumbnailInterval) === 0;

      if (isSecondUnitsFactorOfThumbnailsWidth) {
        let iterate = (thumbnailInterval * this.props.secondUnits) / (style.width as number);
        while (iterate !== 0) {
          containerWidth += style.width as number;
          thumbnailsList.push(<div key={`${index}_${iterate}`} className={`thumbnail-container`} style={style} />);
          iterate--;
        }
      } else {
        containerWidth += style.width as number;
        thumbnailsList.push(<div key={index} className={`thumbnail-container`} style={style} />);
      }
    });

    return {thumbnailsList, containerWidth, containerHeight};
  };

  defineContainerStyles(containerWidth, containerHeight) {
    const style: React.CSSProperties = {};

    if (containerWidth) {
      style.width = containerWidth;
    }

    if (containerHeight) {
      style.height = containerHeight;
    }

    return style;
  }

  render() {
    const {thumbnailsList, containerWidth, containerHeight} = this.getThumbnailsData();
    const contentStyles = this.defineContainerStyles(containerWidth, containerHeight);

    return (
      <ScrollContainer
        contentClassName={`thumbnailslayer-content-container_scroll-area${this.props.jsonVTT.length ? `` : ` loading`}`}
        contentStyle={contentStyles}
        scrollLeft={this.props.scrollLeft}
      >
        {!!this.props.jsonVTT.length && thumbnailsList}
        {!this.props.jsonVTT.length && <span>Checking for Thumbnails data or missing</span>}
      </ScrollContainer>
    );
  }
}
