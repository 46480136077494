import {IVideoPlayer, Observable} from 'tt-components';
import {IBitMovinPlayer, IPlayerSubtitlesAPI} from 'tt-components/src/VideoPlayer/BitMovinPlayer';
import {IEventBus} from '../EventBus';
import {GoogleTagService} from './GoogleTagService';

export interface IServiceProvider {
  video: IVideo;
  eventBus: IEventBus;
  gtm: GoogleTagService;
}

export interface IVideo {
  init(player: IVideoPlayer | IBitMovinPlayer);
  clear();
  getVideoClock(purpose?: ClockPurpose): Observable<number>;
  getCurrentTime(): number;
  seekTo(second: number): number;
  pause(): void;
  isReady(): boolean;
  subtitles(): IPlayerSubtitlesAPI;
  getDroppedFrames(): number;
}

export enum ClockPurpose {
  animationFrame,
  playerCue,
  ttGrid,
  playScheduler,
  playerLoop,
  playerSync,
  playerExposedEvent
}
