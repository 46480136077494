import {getPlaylistContentAPI} from '../data/onePlayerService';
import {IResponse} from '../../@types/response';
import {IPlaylistAsset} from '../state/IAppState';

const INTERVAL_DURATION = 5 * 1000; // 5 SECONDS

export class StagingService {
  public playlist: string;
  public timeoutRef: number;
  public constructor(playlist: string) {
    this.playlist = playlist;
  }

  public init(timeoutCall: (assets: Array<IPlaylistAsset>) => void) {
    this.clearTimeoutRef();
    this.timeoutRef = window.setTimeout(async () => {
      const playlistContent = (await getPlaylistContentAPI(this.playlist)) as IResponse;
      const assets = playlistContent.success ? playlistContent.data.assets : [];
      timeoutCall(assets);
    }, INTERVAL_DURATION);
  }

  public clearTimeoutRef() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }
}
