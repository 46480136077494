import * as React from 'react';
import {TimelineEvents} from '../../../TimelineEvents';
import {IFrameRate} from 'tt-components';
import {ITooltipSegmentData} from '../../../../interfaces';
import {SegmentModel} from '../../../../utils/SegmentModel';
import {IEventGroup} from '../../../../../../../@types/markupEvent';

interface IMarkupsContentProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  frameRate: IFrameRate;
  events: Array<IEventGroup>;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class MarkupsContent extends React.PureComponent<IMarkupsContentProps> {
  render() {
    const events = this.props.events
      .reduce((acc, record) => {
        if (record.events) {
          acc.push(...record.events);
        }
        return acc;
      }, [])
      .map((record: any) => new SegmentModel(record, this.props.frameRate));

    return (
      <TimelineEvents
        secondUnits={this.props.secondUnits}
        contentWidth={this.props.contentWidth}
        scrollLeft={this.props.scrollLeft}
        frameRate={this.props.frameRate}
        events={events}
        emptySegmentsMessage="No Markups are defined"
        segmentClassName="markupslayer-content-container_segment"
        contentClassName="markupslayer-content-container"
        onElementIn={this.props.onElementIn}
        onElementOut={this.props.onElementOut}
        onSegmentClicked={this.props.onSegmentClicked}
      />
    );
  }
}
