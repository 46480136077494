import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const SettingsIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      viewBox="0 0 65 66"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2514.000000, -1758.000000)" fill={props.color} fillRule="nonzero" id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-settings" transform="translate(2092.000000, 4.000000)">
                  <path
                    d="M64.7966085,28.8919661 C64.693821,27.9782451 63.628458,27.2916241 
                  62.7072396,27.2916241 C59.7288186,27.2916241 57.0858479,25.5427843 55.9774351,22.8381411 
                  C54.8450789,20.0679556 55.5752334,16.8372819 57.7949612,14.8011206 C58.4936748,14.1623865 
                  58.5785652,13.093154 57.9925552,12.3504231 C56.4681551,10.4146309 54.7355194,8.66603292 
                  52.8437445,7.15058138 C52.1029484,6.55610645 51.0160606,6.63882021 50.3736987,7.35011019 
                  C48.4364554,9.49558907 44.9566729,10.2929787 42.2677501,9.1710222 C39.4695097,7.99392315 
                  37.7049495,5.15843737 37.8776326,2.11471606 C37.9344681,1.15867075 37.2357544,0.32742163 
                  36.2826114,0.216411056 C33.8548899,-0.064380397 31.4058854,-0.0730871087 28.9709083,0.197062808 
                  C28.0286487,0.301059642 27.329935,1.11271866 27.3616178,2.05667132 C27.4675495,5.07040284 
                  25.6817061,7.85630873 22.9112788,8.99108349 C20.2545225,10.0762784 16.7991672,9.28566057 
                  14.8657935,7.15928809 C14.2268176,6.4588815 13.157585,6.3727818 12.4131612,6.95298739 
                  C10.4652763,8.48125715 8.69370232,10.2313062 7.15527473,12.1511361 C6.55523718,12.8974948 
                  6.64351356,13.9793037 7.34924091,14.6214237 C9.61129299,16.6699195 10.3416894,19.9286482 
                  9.16652513,22.7307583 C8.04456859,25.4022677 5.27051349,27.1240199 2.09474039,27.1240199 
                  C1.06420432,27.090886 0.330180152,27.7825859 0.217476606,28.7195248 C-0.0683937624,31.1612738 
                  -0.0717797058,33.6492166 0.203449126,36.1107975 C0.305511135,37.0283882 1.40328237,37.7089628 
                  2.33465867,37.7089628 C5.16482369,37.6366488 7.8820433,39.3888745 9.02189698,42.1626877 
                  C10.1581229,44.9328732 9.42772648,48.1616121 7.20461276,50.1994663 C6.50928509,50.8382004 
                  6.4210087,51.90574 7.00701877,52.6484709 C8.51714955,54.5719286 10.250027,56.3222195 
                  12.1485739,57.8502474 C12.8932396,58.450285 13.9767415,58.3656364 14.6222474,57.6543464 
                  C16.5667464,55.5035468 20.046287,54.7076082 22.7245682,55.8317414 C25.5298224,57.0052127 
                  27.2943826,59.8404566 27.1216995,62.8858709 C27.0653477,63.8423999 27.7674473,64.6751001 
                  28.7167207,64.7844177 C29.9586364,64.9292877 31.2080496,65.0016018 32.4608486,65.0016018 
                  C33.6500403,65.0016018 34.8394739,64.9363015 36.0286656,64.8040078 C36.9711671,64.700011 
                  37.6693971,63.888352 37.6377143,62.9427064 C37.5286386,59.9306678 39.317626,57.1447619 
                  42.0844255,56.011922 C44.7590789,54.9194715 48.199923,55.7190379 50.1335386,57.8432337 
                  C50.7761423,58.5421891 51.8378774,58.6265959 52.5864128,58.0483251 C54.5306699,56.5236831 
                  56.298616,54.7750852 57.8442992,52.8499345 C58.4440949,52.1052688 58.3594463,51.0217669 
                  57.6500912,50.3798888 C55.3880391,48.331393 54.6540149,45.0721806 55.8291792,42.2720053 
                  C56.9339641,39.6360484 59.6049898,37.8661673 62.4779628,37.8661673 L62.8799227,37.876567 
                  C63.8117827,37.952267 64.6691519,37.234447 64.7820973,36.2832388 C65.0684514,33.8393132 
                  65.0718374,31.353547 64.7966085,28.8919661 Z M32.5515435,43.4104079 C26.5734185,43.4104079 
                  21.71072,38.5477094 21.71072,32.5695844 C21.71072,26.5917012 26.5734185,21.7287609 
                  32.5515435,21.7287609 C38.5294267,21.7287609 43.3921252,26.5917012 43.3921252,32.5695844 
                  C43.3921252,38.5477094 38.5294267,43.4104079 32.5515435,43.4104079 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
