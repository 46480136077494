import * as React from 'react';

interface IDropdownContentProps {
  content?: JSX.Element | string;
  color?: string;
}

export const defaultMenuControlBarStyles: React.CSSProperties = {
  backgroundColor: 'transparent',
  border: 'none',
  zIndex: 39000
};

export class DropdownContent extends React.PureComponent<IDropdownContentProps, any> {
  render() {
    const color = this.props.color;
    return (
      <div className="dropdown-content-container" style={{backgroundColor: color}}>
        {this.props.content || ''}
      </div>
    );
  }
}
