import {createAction} from 'redux-actions';
import {ITabs} from '../interfaces/ITabsState';
import {IComment} from '../interfaces/ICommentsTab';
import {IAppState} from '../../../state/IAppState';
import {
  getCommentsByComplianceJobIdAPI,
  deleteCommentByIdAPI,
  addCommentAPI,
  editCommentByIdAPI,
  addReplyAPI
} from '../../../data/oneUIAPI';
import {addNewEvents, updateEvents, removeEvent} from '../../../data/atlasAPI';
import {
  getEvents,
  setMarkupsErrors,
  updateAssetPartially,
  setChangedEvents,
  setDefaultDataForChangedEvents,
  updateChangedEventGroup
} from '../../../actions/video';
import {IMetadataErrors} from '../interfaces/IMetadataTab';
import {
  clearProps,
  deepCopy,
  getTitleInfo,
  filterDefaultTypes,
  updateMarkupsErrors,
  mergeChangedEvents,
  updateEventsGroup,
  getTypesByEventGroup,
  updateTimeOffset
} from '../utils/helpers';
import {IEventGroup, IMarkupEvent} from '../../../../@types/markupEvent';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails} from '../../../../@types/assetDetails';
import {IUpdateEventsFormat} from '../../../../@types/updateEventsFormat';
import {IMarkupsError} from '../../../../@types/markupsError';
import {IResponse} from '../../../../@types/response';
import {triggerNotification} from 'tt-components/src/Notifications/notifications';
import {utils} from 'tt-components/src/Utils';
import {IServiceProvider} from '../../../services/interfaces';
import {IProgramTimingsAssetPut} from '../../../../@types/programTimingsAssetPut';
import {IChapterAssetPut} from '../../../../@types/chapterAssetPut';
import {IComplianceAssetPut} from '../../../../@types/complianceAssetPut';
import {IQualityControlLogAssetPut} from '../../../../@types/qualityControlLogAssetPut';
import {IQualityControl} from '../../../../@types/qualityControl';
import {ITextlessAssetPut} from '../../../../@types/textlessAssetPut';
import {IMetadataError} from '../../../../@types/metadataErrors';
import {ErrorPayload} from '../../../models/ErrorPayload/ErrorPayload';
import {IAssetPutError, IAssetPutFieldError} from '../../../../@types/assetPutError';
import {has} from '../../../utils/utils';
import {IAssetUnregisteredPatchError} from '../../../../@types/assetUnregisteredPatchError';

export const SELECT_TAB = 'Tabs/SELECT_TAB';
export type SELECT_TAB = ITabs;
export const selectTab = createAction<SELECT_TAB, SELECT_TAB>(SELECT_TAB, (tab: ITabs) => tab);

export const SELECT_VERSION = 'Tabs/SELECT_VERSION';
export type SELECT_VERSION = string;
export const selectVersion = createAction<SELECT_VERSION, SELECT_VERSION>(
  SELECT_VERSION,
  (versionId: string) => versionId
);

export const SELECT_METADATA_TAB = 'Tabs/SELECT_METADATA_TAB';
export type SELECT_METADATA_TAB = ITabs;
export const selectMetadataTab = createAction<SELECT_METADATA_TAB, SELECT_METADATA_TAB>(
  SELECT_METADATA_TAB,
  (tab: ITabs) => tab
);

export const SET_COMMENTS = 'Video/SET_COMMENTS';
export type SET_COMMENTS = IComment[];
export const setComments = createAction<SET_COMMENTS, SET_COMMENTS>(SET_COMMENTS, (comments: SET_COMMENTS) => comments);

export const ADD_COMMENT = 'Video/ADD_COMMENT';
export type ADD_COMMENT = IComment;
export const addCommentToSrore = createAction<ADD_COMMENT, ADD_COMMENT>(ADD_COMMENT, (comment: ADD_COMMENT) => comment);

export const ADD_REPLY = 'Video/ADD_REPLY';
export type ADD_REPLY = IComment;
export const addReplyToStore = createAction<ADD_REPLY, ADD_REPLY>(ADD_REPLY, (reply: ADD_REPLY) => reply);

export const getComments = () => {
  return async (dispatch, getState: () => IAppState) => {
    const response = await getCommentsByComplianceJobIdAPI();

    if (response && response.success) {
      let comments = response.comments || [];

      // validate the result
      if (!Array.isArray(comments)) {
        console.warn('Comments: array is expected, got this instead:', comments);
        comments = [];
      }

      comments = comments.map(comment => {
        comment.inTime = parseFloat(comment.inTime) || 0;
        comment.outTime = parseFloat(comment.outTime) || 0;
        comment.createdAt = new Date(comment.createdAt + ' GMT+0000');
        return comment;
      });

      comments.sort((prevComment, nextComment) => {
        return prevComment.createdAt.getTime() - nextComment.createdAt.getTime();
      });

      dispatch({
        type: SET_COMMENTS,
        payload: comments
      });
    }
  };
};

export const deleteComment = (id: number) => {
  return async (dispatch, getState: () => IAppState) => {
    deleteCommentByIdAPI(id);

    const prevComments = getState().tabs.commentsTab.comments;
    let comments = prevComments.filter(comment => {
      return comment.id !== id;
    });

    dispatch({
      type: SET_COMMENTS,
      payload: comments
    });
  };
};

export const editComment = (id: number, text: string) => {
  return async (dispatch, getState: () => IAppState) => {
    const prevComments = getState().tabs.commentsTab.comments;
    let currComment = prevComments.find(comment => {
      return comment.id === id;
    });
    editCommentByIdAPI(id, {...currComment, comment: text});

    let comments = prevComments.map(comment => {
      if (id === comment.id) {
        comment.comment = text;
      }
      return comment;
    });

    dispatch({
      type: SET_COMMENTS,
      payload: comments
    });
  };
};

export const addCommentAndUpdateData = data => {
  return async (dispatch, getState: () => IAppState) => {
    const addCommentResponse = await addCommentAPI(data);

    let newComment: IComment = {
      id: null,
      comment: data.comment,
      createdBy: {
        displayName: 'Retrieving user name...',
        avatar: '/static/img/default-avatar.png'
      },
      createdAt: new Date(),
      parent: null,
      inTime: data.inTime,
      outTime: data.outTime,
      externalId: appConfig.externalID,
      isCurrentUser: true
    };

    dispatch({
      type: ADD_COMMENT,
      payload: newComment
    });

    if (addCommentResponse && addCommentResponse.success) {
      const response = await getCommentsByComplianceJobIdAPI();

      if (response && response.success) {
        let comments = response.comments || [];

        comments = comments.map(comment => {
          comment.inTime = parseFloat(comment.inTime) || 0;
          comment.outTime = parseFloat(comment.outTime) || 0;
          comment.createdAt = new Date(comment.createdAt + ' GMT+0000');
          return comment;
        });

        comments.sort((prevComment, nextComment) => {
          return prevComment.createdAt.getTime() - nextComment.createdAt.getTime();
        });

        dispatch({
          type: SET_COMMENTS,
          payload: comments
        });
      }
    }
  };
};

export const addReplyAndUpdateData = (commentId: number, text: string) => {
  return async (dispatch, getState: () => IAppState) => {
    const addReplyResponse = await addReplyAPI({commentId, text});

    let newComment: IComment = {
      id: null,
      comment: text,
      createdBy: {
        displayName: 'Retrieving user name...',
        avatar: '/static/img/default-avatar.png'
      },
      createdAt: new Date(),
      parent: commentId,
      inTime: null,
      outTime: null,
      externalId: appConfig.externalID,
      isCurrentUser: true
    };

    dispatch({
      type: ADD_COMMENT,
      payload: newComment
    });

    if (addReplyResponse && addReplyResponse.success !== false) {
      addReplyResponse.inTime = parseFloat(addReplyResponse.inTime) || 0;
      addReplyResponse.outTime = parseFloat(addReplyResponse.outTime) || 0;
      addReplyResponse.createdAt = new Date(addReplyResponse.createdAt + ' GMT+0000');

      dispatch({
        type: ADD_REPLY,
        payload: addReplyResponse
      });
    }
  };
};

export const PROCESSING_AUDIO_METADATA = 'Tabs/PROCESSING_AUDIO_METADATA';
export type PROCESSING_AUDIO_METADATA = boolean;
export const processingAudioMetadata = createAction<PROCESSING_AUDIO_METADATA, PROCESSING_AUDIO_METADATA>(
  PROCESSING_AUDIO_METADATA,
  (process: PROCESSING_AUDIO_METADATA) => process
);

export const getPlayerCurrentTime = () => {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    return services.video.getCurrentTime();
  };
};

export const prepareEventsContentForAssetPut = () => {
  return (dispatch, getState: () => IAppState) => {
    const {
      changedEvents,
      types,
      categories,
      playlist: {frameRate}
    } = getState().video;
    const {useStartTimecode} = getState().tabs.markupsTab;

    const startTimecodeEventTimeIn = [
      deepCopy([...changedEvents]).find((group: IEventGroup) => group.name === 'Program Timings')
    ]
      .filter(group => group)
      .reduce((timeIn: number, group: IEventGroup) => {
        const event = group.events.find(
          (event: IMarkupEvent) => event.type === 'Start Timecode' && !PlaylistAsset.parsing.isDefaultEvent(event)
        );
        if (event) {
          return utils.formatting.smpteTimecodeToSeconds(event.timeIn, frameRate.frameRate, frameRate.dropFrame);
        }
        return null;
      }, null);

    const startTimecodeUpdates = deepCopy([...changedEvents]).map((group: IEventGroup) => {
      // NOTE: In case useStartTimecode is set to false we will need to update all events to
      // have the defined offset from the Start Timecode (if provided) so we know that every
      // event on markups load will include the Start Timecode offset
      if (!useStartTimecode && startTimecodeEventTimeIn) {
        group.events = group.events.map((event: IMarkupEvent) => {
          // Default types of Program Timings will not be included in the offset logic
          if (PlaylistAsset.parsing.isDefaultType(event)) {
            return {...event};
          }
          const timeIn = startTimecodeEventTimeIn
            ? updateTimeOffset(event.timeIn, startTimecodeEventTimeIn, frameRate)
            : event.timeIn;
          const timeOut = startTimecodeEventTimeIn
            ? updateTimeOffset(event.timeOut, startTimecodeEventTimeIn, frameRate)
            : event.timeOut;
          return {...event, timeIn, timeOut};
        });
        dispatch(updateChangedEventGroup(group));
      }
      return group;
    });

    dispatch(updateUseStartTimecodeFlag(true));

    const copyChangedEvents = deepCopy([...startTimecodeUpdates]).map((group: IEventGroup) => {
      if (group.name === 'Program Timings') {
        group.events = group.events.filter(filterDefaultTypes);
      }
      return group;
    });

    let markupsErrors: Array<IMarkupsError> = [];
    copyChangedEvents.forEach((group: IEventGroup) => {
      (group.events || []).forEach((event: IMarkupEvent) => {
        const groupTypes = getTypesByEventGroup(group.name, types);
        let error = '';
        if (!event.type && group.name !== 'Textless') {
          error = 'Type is missing';
        } else if (event.type && groupTypes.length && groupTypes.indexOf(event.type) === -1) {
          error = 'Type is invalid';
        } else if (!event.timeIn) {
          error = 'Time In is missing';
        } else if (!event.timeOut) {
          error = 'Time Out is missing';
        }
        if (group.name === 'Compliance Edits' && !error) {
          const groupCategories = categories['Asset.Compliance.ReasonCodes'] || [];
          error = !event.category
            ? 'Category is missing'
            : groupCategories.indexOf(event.category) === -1
            ? 'Category is invalid'
            : '';
        }
        if (error) {
          markupsErrors = updateMarkupsErrors(markupsErrors, group.name, [event.id], error);
        }
      });
    });

    if (markupsErrors.length) {
      dispatch(setMarkupsErrors(markupsErrors));
      throw new Error('Markups events have missing data');
    }

    const programTimings = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Program Timings')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IProgramTimingsAssetPut>, event: IMarkupEvent) => {
        return [...acc, {name: event.type, timeIn: event.timeIn, timeOut: event.timeOut} as IProgramTimingsAssetPut];
      }, []);

    const chapter = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Chapter')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IChapterAssetPut>, event: IMarkupEvent) => {
        const chapter: IChapterAssetPut = {timeIn: event.timeIn, timeOut: event.timeOut};
        if (event.remoteassettimein) {
          chapter.remoteAssetTimeIn = event.remoteassettimein;
        }
        if (event.remoteassettimeout) {
          chapter.remoteAssetTimeOut = event.remoteassettimeout;
        }
        return [...acc, chapter];
      }, []);

    const compliance = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Compliance Edits')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IComplianceAssetPut>, event: IMarkupEvent) => {
        return [
          ...acc,
          {
            editType: event.type,
            timeIn: event.timeIn,
            timeOut: event.timeOut,
            reason: event.category || '',
            notes: event.notes || ''
          } as IComplianceAssetPut
        ];
      }, []);

    const qualityControlLogs = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Quality Control')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IQualityControlLogAssetPut>, event: IMarkupEvent) => {
        return [
          ...acc,
          {
            qcType: event.type,
            timeIn: event.timeIn,
            timeOut: event.timeOut,
            notes: event.notes || ''
          } as IQualityControlLogAssetPut
        ];
      }, []);
    const qualityControl: Array<IQualityControl> = [];
    if (qualityControlLogs.length) {
      qualityControl.push({qcStatus: 'None', qcLog: qualityControlLogs});
    }

    const textless = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Textless')]
      .filter(group => group)
      .reduce((acc: Array<ITextlessAssetPut>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<ITextlessAssetPut>, event: IMarkupEvent) => {
        const textless: ITextlessAssetPut = {timeIn: event.timeIn, timeOut: event.timeOut};
        if (event.remoteassettimein) {
          textless.remoteAssetTimeIn = event.remoteassettimein;
        }
        if (event.remoteassettimeout) {
          textless.remoteAssetTimeOut = event.remoteassettimeout;
        }
        return [...acc, textless];
      }, []);

    return {programTimings, chapter, compliance, qualityControl, textless};
  };
};

export const MARKUPS_SAVE_EVENT_CHANGES = 'Tabs/MARKUPS_SAVE_EVENT_CHANGES';
export const markupsSaveEventChanges = () => {
  return async (dispatch, getState: () => IAppState): Promise<IResponse> => {
    const {
      changedEvents,
      playlist: {assets, selectedAssetId}
    } = getState().video;
    const username = getState().configuration.userEmail;
    if (!username) {
      throw new Error('Username is missing from Player configuration');
    }
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    // Reset markups error after each new save request for the events
    dispatch(setMarkupsErrors([]));

    dispatch({type: MARKUPS_SAVE_EVENT_CHANGES});

    const selectedAssetGroupEvents = selectedAsset ? selectedAsset.events : [];
    const copyChangedEvents = deepCopy([...changedEvents]).map((group: IEventGroup) => {
      if (group.name === 'Program Timings') {
        group.events = group.events.filter(filterDefaultTypes);
      }
      return group;
    });
    let markupsErrors: Array<IMarkupsError> = [];
    const eventsReadyToProcess = copyChangedEvents.reduce((acc: Array<IUpdateEventsFormat>, group: IEventGroup) => {
      // Prepare current events that are provided from the operator
      const currentEvents = (group.events ? (Array.isArray(group.events) ? group.events : []) : []).map(
        (event: IMarkupEvent) => clearProps(event, ['newRecord', 'error', 'hidden'])
      );
      // Retrieve existing events related with selected asset data
      const existingEvents = [
        selectedAssetGroupEvents.find((groupEvent: IEventGroup) => groupEvent.name === group.name)
      ]
        .filter(groupEvent => groupEvent)
        .reduce((acc: Array<IMarkupEvent>, groupEvent: IEventGroup) => {
          return [...acc, ...(groupEvent.events || [])];
        }, [])
        .filter(group.name === 'Program Timings' ? filterDefaultTypes : () => true);
      // Filter out the new events
      const newEvents = currentEvents.filter(
        (event: IMarkupEvent) => existingEvents.map((exEvent: IMarkupEvent) => exEvent.id).indexOf(event.id) === -1
      );
      // Filter out removed events
      const removedEvents = existingEvents.filter(
        (event: IMarkupEvent) => currentEvents.map((curEvent: IMarkupEvent) => curEvent.id).indexOf(event.id) === -1
      );
      // Filter out updated events
      const updatedEvents = currentEvents.filter(
        (curEvent: IMarkupEvent) => newEvents.map((newEvent: IMarkupEvent) => newEvent.id).indexOf(curEvent.id) === -1
      );
      return [...acc, {eventGroup: group.name, updatedEvents, newEvents, removedEvents} as IUpdateEventsFormat];
    }, []);
    let notAddedEvents: Array<IEventGroup> = [];
    const resolvePromise = eventsReadyToProcess.reduce(async (acc: Promise<any>, groupEvent: IUpdateEventsFormat) => {
      await acc;
      // Handle events update functionalities
      const updatedResponse = groupEvent.updatedEvents.length
        ? await updateEvents(selectedAssetId, groupEvent.eventGroup, username, JSON.stringify(groupEvent.updatedEvents))
        : {success: true};
      const updatedIds = groupEvent.updatedEvents.map((event: IMarkupEvent) => event.id);
      if (!updatedResponse.success) {
        markupsErrors = updateMarkupsErrors(
          markupsErrors,
          groupEvent.eventGroup,
          updatedIds,
          updatedResponse.error.message
        );
        notAddedEvents = updateEventsGroup(notAddedEvents, groupEvent.eventGroup, groupEvent.updatedEvents);
      }
      // Create a copy reference for the new events without the id field as it's needed from the API side
      const newEventsWithoutField = deepCopy([...groupEvent.newEvents]).map((event: IMarkupEvent) => {
        if (event.id) {
          delete event.id;
        }
        return event;
      });
      // Handle events creation functionalities
      const newResponse = groupEvent.newEvents.length
        ? await addNewEvents(selectedAssetId, groupEvent.eventGroup, username, JSON.stringify(newEventsWithoutField))
        : {success: true};
      const newIds = groupEvent.newEvents.map((event: IMarkupEvent) => event.id);
      if (!newResponse.success) {
        markupsErrors = updateMarkupsErrors(markupsErrors, groupEvent.eventGroup, newIds, newResponse.error.message);
        notAddedEvents = updateEventsGroup(notAddedEvents, groupEvent.eventGroup, groupEvent.newEvents);
      }
      // Handle events remove functionalities
      for (const removedEvent of groupEvent.removedEvents) {
        const removedResponse = await removeEvent(removedEvent.id, username);
        if (!removedResponse.success) {
          markupsErrors = updateMarkupsErrors(
            markupsErrors,
            groupEvent.eventGroup,
            [removedEvent.id],
            removedResponse.error.message
          );
        }
      }
      return Promise.resolve('');
    }, Promise.resolve(''));

    // Stop execution until all the group events have been processed and we are ready to fetch data from API
    await resolvePromise;

    await dispatch(getEvents());

    if (markupsErrors.length) {
      console.log('Errors in Markups updated', markupsErrors);
      dispatch(setMarkupsErrors(markupsErrors));
      const mergedChangedEvents = mergeChangedEvents(deepCopy([...getState().video.changedEvents]), notAddedEvents);
      dispatch(setChangedEvents(mergedChangedEvents));
      return Promise.resolve({success: false, error: 'Please check Markups tab for errors'});
    }
    return Promise.resolve({success: true});
  };
};

export const UPDATE_USE_START_TIMECODE_FLAG = 'Tabs/UPDATE_USE_START_TIMECODE_FLAG';
export type UPDATE_USE_START_TIMECODE_FLAG = boolean;
export const updateUseStartTimecodeFlag = createAction<UPDATE_USE_START_TIMECODE_FLAG, UPDATE_USE_START_TIMECODE_FLAG>(
  UPDATE_USE_START_TIMECODE_FLAG,
  (useStartTimecode: UPDATE_USE_START_TIMECODE_FLAG) => useStartTimecode
);

export const SAVE_TABS_CONTENT = 'Tabs/SAVE_TABS_CONTENT';
export type SAVE_TABS_CONTENT = boolean;
export const saveTabsContent = (registerAsset: boolean = false) => {
  return async (dispatch, getState: () => IAppState) => {
    const {
      playlist: {selectedAssetId}
    } = getState().video;

    if (!selectedAssetId) {
      triggerNotification(
        {
          type: 'warning',
          title: 'Tabs',
          message: `Cannot proceed with saving content as no assets is selected!`,
          delay: 2500
        },
        null
      );
      return;
    }

    dispatch(setEditMode(false));
    dispatch({type: SAVE_TABS_CONTENT, payload: true});

    try {
      const responses = (await Promise.all([
        // await dispatch(markupsSaveEventChanges()),
        await dispatch(updateAssetDetailsData(registerAsset))
      ])) as Array<IResponse>;
      let hasError = false;
      responses.forEach((response: IResponse, index: number) => {
        if (!response.success) {
          hasError = true;
          triggerNotification(
            {
              type: 'error',
              title: `Tabs ${index === 0 ? `Events` : `Metadata`}`,
              message: response.error.message,
              delay: 2500
            },
            null
          );
        }
      });
      if (hasError) {
        dispatch(setEditMode(true));
      } else {
        // After success update we need to call events end-point to populate UI with latest data
        await dispatch(getEvents());
        triggerNotification(
          {
            type: 'success',
            title: 'Asset Update',
            message: `Asset saved successfully`,
            delay: 2500
          },
          null
        );
        dispatch(updatePartialAssetDetails({}));
      }
    } catch (error) {
      console.log('Error', error.message, error.stack);
      triggerNotification(
        {
          type: 'error',
          title: 'Tabs',
          message: error.message,
          delay: 2500
        },
        null
      );
      dispatch({type: SAVE_TABS_CONTENT, payload: false});
      dispatch(setEditMode(true));
    } finally {
      dispatch({type: SAVE_TABS_CONTENT, payload: false});
    }
  };
};

export const CANCEL_ASSET_EDIT = 'Tabs/CANCEL_ASSET_EDIT';
export const cancelTabsContent = () => {
  return (dispatch, getState: () => IAppState) => {
    dispatch(setDefaultDataForChangedEvents());
    dispatch(cancelSavingAssetDetailsData());
    dispatch(setEditMode(false));
    dispatch(updateUseStartTimecodeFlag(true));
    dispatch(resetMetadataErros());
    dispatch({type: CANCEL_ASSET_EDIT});
  };
};

export const SET_EDIT_MODE = 'Tabs/SET_EDIT_MODE';
export type SET_EDIT_MODE = boolean;
export const setEditMode = createAction<SET_EDIT_MODE, SET_EDIT_MODE>(
  SET_EDIT_MODE,
  (inEditMode: SET_EDIT_MODE) => inEditMode
);

export const START_ASSET_EDIT = 'Tabs/START_ASSET_EDIT';
export const startAssetEdit = () => {
  return (dispatch, getState: () => IAppState) => {
    const {selectedAssetId, assets} = getState().video.playlist;
    const {updatedAssetDetails} = getState().tabs;
    if (!selectedAssetId) {
      return;
    }
    const asset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    if (!asset) {
      return;
    }
    // In case the asset is unregistered and it has not provided function we need to default it to 'Source'
    if (!asset.isRegistered && !asset.assetDetails.function) {
      dispatch(updatePartialAssetDetails({...updatedAssetDetails, function: 'Source'}));
    }
    dispatch(setEditMode(true));
    dispatch({type: START_ASSET_EDIT});
  };
};

export const checkPreSaveMetadataErrors = () => {
  return (dispatch, getState: () => IAppState) => {
    const error = 'Some of the provided fields have wrong input';
    const errorDetails: Array<IMetadataError> = [];

    const {updatedAssetDetails} = getState().tabs;
    const {selectedAssetId, assets} = getState().video.playlist;
    const asset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);

    if (!asset) {
      console.log(`Couldn't find selected asset for provided ID`, selectedAssetId);
      return;
    }

    // NOTE: In case the asset is not registered and the function is defined
    // we need to make sure that the submitted value is constrained
    const functionValue = updatedAssetDetails.function || asset.assetDetails.function;
    if (!asset.isRegistered && functionValue) {
      const isFunctionValueAllowed = ['Source', 'Proxy'].indexOf(functionValue) !== -1;
      if (!isFunctionValueAllowed) {
        errorDetails.push({
          fieldName: 'AssetRegistrationPatch.Function',
          message: 'Allowed values are Source or Proxy'
        });
      }
    }

    if (errorDetails.length) {
      throw new ErrorPayload({error, errorDetails}, 'Something went wrong on pre-save validation for metadata');
    }
  };
};

export const parseAssetPutErrors = (error: Error | ErrorPayload) => {
  return (dispatch, getState: () => IAppState) => {
    const putPayload: IAssetPutError =
      error.name === 'PayloadError' ? (error as ErrorPayload).getAssetPutErrorPayload() : null;
    const patchPayload: IAssetUnregisteredPatchError =
      error.name === 'PayloadError' ? (error as ErrorPayload).getAssetUnregisteredPatchErrorPayload() : null;
    let detailsErrors = [];

    if (putPayload || patchPayload) {
      const errors = [
        ...(putPayload && putPayload.errorDetails ? putPayload.errorDetails : []),
        ...(patchPayload && patchPayload.reasons ? patchPayload.reasons : [])
      ];
      // TODO: Define better approach to handle error parsing for the left tabs as Video and Audio
      detailsErrors = errors.reduce((acc: Array<IMetadataError>, putFieldError: IAssetPutFieldError) => {
        return [
          ...acc,
          {fieldName: putFieldError.fieldName || '', message: putFieldError.message || ''} as IMetadataError
        ];
      }, []);
    }

    dispatch(updateMetadataErrors('metadataDetails', detailsErrors));
  };
};

export const UPDATE_ASSET_DETAILS_DATA = 'Tabs/UPDATE_ASSET_DETAILS_DATA';
export type UPDATE_ASSET_DETAILS_DATA = void;
export const updateAssetDetailsData = (minimumRequirementsMet: boolean) => {
  return async (dispatch, getState: () => IAppState): Promise<IResponse> => {
    dispatch({type: UPDATE_ASSET_DETAILS_DATA});

    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(
      getState().video.playlist.assets,
      getState().video.playlist.selectedAssetId
    );

    const isRegistered = () => {
      return selectedAsset
        ? typeof selectedAsset.isRegistered !== 'undefined'
          ? selectedAsset.isRegistered
          : true
        : true;
    };

    // Reset Metadata tabs errors before the update call is performed
    dispatch(resetMetadataErros());
    // Reset markups error after each new save request for the events
    dispatch(setMarkupsErrors([]));

    let updatedAssetDetailsGeneralData: Partial<IAssetDetails> = getState().tabs.updatedAssetDetails;
    let assetDetails: IAssetDetails = selectedAsset.assetDetails;
    const events = dispatch(prepareEventsContentForAssetPut());
    console.log('Updated events', events);
    const updatedFields = deepCopy({...updatedAssetDetailsGeneralData, ...events});

    if (!isRegistered()) {
      const titles = (updatedAssetDetailsGeneralData.titles || assetDetails.titles || []).map(
        PlaylistAsset.parsing.parseSearchTitle
      );
      const credentials = PlaylistAsset.parsing.parseCredentialsFromTitles(titles);
      const {versionId, conformanceGroupId} = getTitleInfo(credentials);
      updatedFields.versionId = versionId;
      updatedFields.conformanceId = conformanceGroupId;
    }

    try {
      // Check if we have some unwanted data before pushing to ATLAS
      dispatch(checkPreSaveMetadataErrors());
      await dispatch(updateAssetPartially(updatedFields, minimumRequirementsMet));
      return Promise.resolve({success: true});
    } catch (error) {
      dispatch(parseAssetPutErrors(error));
      return Promise.resolve({success: false, error});
    }
  };
};

export const CANCEL_SAVING_ASSET_DETAILS_DATA = 'Tabs/CANCEL_SAVING_ASSET_DETAILS_DATA';
export type CANCEL_SAVING_ASSET_DETAILS_DATA = void;
export const cancelSavingAssetDetailsData = () => {
  return async (dispatch, getState: () => IAppState) => {
    dispatch(updatePartialAssetDetails({}));
  };
};

export const UPDATE_PARTIAL_ASSET_DETAILS = 'Tabs/UPDATE_PARTIAL_ASSET_DETAILS';
export type UPDATE_PARTIAL_ASSET_DETAILS = Partial<IAssetDetails>;
export const updatePartialAssetDetails = createAction<UPDATE_PARTIAL_ASSET_DETAILS, UPDATE_PARTIAL_ASSET_DETAILS>(
  UPDATE_PARTIAL_ASSET_DETAILS,
  (details: UPDATE_PARTIAL_ASSET_DETAILS) => details
);

export const UPDATE_METADATA_ERRORS = 'Tabs/UPDATE_METADATA_ERRORS';
export type UPDATE_METADATA_ERRORS = Partial<IMetadataErrors>;
export const updateMetadataErrors = (
  metadataErrorProps: 'metadataDetails' | 'metadataVideo' | 'metadataAudio',
  content: Array<IMetadataError>
) => {
  return (dispatch, getState: () => IAppState) => {
    const errorsCopy = deepCopy({...getState().tabs.metadataTab.metadataErrors});
    if (!has(errorsCopy, metadataErrorProps)) {
      console.log('Metadata error type not found', metadataErrorProps);
      return;
    }
    dispatch({type: UPDATE_METADATA_ERRORS, payload: {[metadataErrorProps]: content}});
  };
};

export const resetMetadataErros = () => {
  return (dispatch, getState: () => IAppState) => {
    dispatch(updateMetadataErrors('metadataDetails', []));
    dispatch(updateMetadataErrors('metadataVideo', []));
    dispatch(updateMetadataErrors('metadataAudio', []));
  };
};
