import * as React from 'react';

interface IScrollContainerProps {
  containerClassName?: string;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
  scrollLeft: number;
}

interface IScrollContainerState {
  scrollLeft: number;
}

export class ScrollContainer extends React.Component<IScrollContainerProps, IScrollContainerState> {
  static getDerivedStateFromProps(nextProps: IScrollContainerProps, prevState: IScrollContainerState) {
    if (nextProps.scrollLeft !== prevState.scrollLeft) {
      return {scrollLeft: nextProps.scrollLeft};
    } else {
      return null;
    }
  }

  containerRefElement: HTMLDivElement;

  constructor(props: IScrollContainerProps) {
    super(props);

    this.state = {
      scrollLeft: props.scrollLeft
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: IScrollContainerProps, prevState: IScrollContainerState) {
    if (prevProps.scrollLeft !== this.state.scrollLeft) {
      this.init();
    }
  }

  init() {
    if (this.containerRefElement) {
      this.containerRefElement.scrollLeft = this.state.scrollLeft;
    }
  }

  render() {
    return (
      <div
        className={`scroll-container${this.props.containerClassName ? ` ${this.props.containerClassName}` : ``}`}
        ref={node => (this.containerRefElement = node)}
      >
        <div className={`${this.props.contentClassName || ''}`} style={this.props.contentStyle || {}}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
