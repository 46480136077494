import {combineReducers} from 'redux';

import {video} from './video';
import {overlays} from './overlays';
import appConfig from './appConfig';
import {IAppState} from '../state';
import {timeline} from '../modules/Timeline/reducers/timeline';
import {tabs} from '../modules/Tabs/reducers/tabs';
import {qualityMetrics} from './qualityMetrics';

export default combineReducers<IAppState>({
  video,
  overlays,
  qualityMetrics,
  configuration: appConfig,
  timeline,
  tabs
});
