import {Action, createAction} from 'redux-actions';
import {IAppState, IPlaylistAsset, IWaveformsUrlData} from 'state/IAppState';
import {IAssetDetails} from '../../@types/assetDetails';
import LocalStorageService from '../services/LocalStorageService';
import {IVideoPlayer} from 'tt-components/src/VideoPlayer/IVideoPlayer';
import {IPlayerMetadata} from 'tt-components/src/VideoPlayer/playerProps';
import {LoopState} from '../components/OnePlayerControlBar/LoopControlBar';
import {ClockPurpose, IServiceProvider} from '../services/interfaces';
import {SeekType} from '../components/OnePlayerControlBar/onePlayerControlBarProps';
import {FastFrameType, PlayerEventExposedNames, PlayerEventsExposed} from '../constants/constants';
import * as shortcuts from '../constants/shortcuts';
import {IVideoSubs} from '../state/IVideoSubs';
import {utils, IFrameRate} from 'tt-components';
import {atlasAPI, hybrikAPI, ttmanAPI, onePlayerService} from '../data';
import {IDisplayMediaTimeFormat} from '../../@types/displayMediaTimeFormat';
import {IAssetStatus} from '../../@types/assetStatus';
import {
  IVideoShortcuts,
  IVideoAudioConfiguration,
  IMarkupsTypes,
  IMarkupsEventGroup,
  IVideoFragmentLastUpdated
} from '../state/IVideoState';
import {
  updateEnums,
  preparePrePlaybackLoad,
  checkAssetProxyState,
  generatePlaybackRequestBody,
  generateTextOverlay,
  areAllStaged,
  wait,
  remoteFileExists,
  generateParsedVTT,
  has,
  getMarkupsTypes,
  getStartTimecodeTimestampFromEvents
} from '../utils/utils';
import {IParsedVTT} from '../../@types/parsedVtt';
import {IAudioChannelConfiguration, IAudioChannel} from '../../@types/audioChannelConfiguration';
import {processingAudioMetadata, setEditMode, getComments} from '../modules/Tabs/actions/tabs';
import {IResponse} from '../../@types/response';
import {ISubtitlesMetadata, ISubtitlesMetadataItem} from '../../@types/subtitlesMetadata';
import {IPlaylistContentResponse} from '../state/IAppState';
import {triggerNotification} from 'tt-components/src/Notifications/notifications';
import {IEnum} from '../../@types/enum';
import {
  BitRateMode,
  AudioBitDepth,
  AudioSubType,
  AudioCodec,
  AssetFunction,
  BitDepth,
  ChannelMap,
  Language,
  ChannelConfigType,
  ChannelConfig,
  ColorDiff,
  ColorEncoding,
  ColorSubSampling,
  ColorType,
  ColorModel,
  Colorimetry,
  DynamicRangeSystem,
  DynamicRangeType,
  ExpandedAudioConf,
  EncodeRate,
  AssetStatus,
  FileWrapper,
  ContentType,
  FrameRate,
  DisplayAspectRatio,
  PictureAspectRatio,
  PictureFormat,
  PixelAspect,
  ReferencesName,
  ReferencesType,
  ScanType,
  SignalRange,
  SubtitleType,
  SubType,
  TimeCode,
  TransferFunction,
  VideoCodec,
  WhitePoint,
  FormatCompliance,
  LanguageDialect,
  GraphicsType,
  NonMediaType,
  NonMediaSubType
} from '../utils/storage';
import {clearProps, deepCopy} from '../modules/Tabs/utils/helpers';
import {DEFAULT_ASSET_DETAILS} from '../reducers/video';
import {PlaylistAsset} from '../models/PlaylistAsset/PlaylistAsset';
import {IEventGroup} from '../../@types/markupEvent';
import {ISearchTitle} from '../../@types/searchTitle';
import {IMarkupsError} from '../../@types/markupsError';
import {supportedEvents} from '../constants/supportedEvents';
import {ErrorPayload} from '../models/ErrorPayload/ErrorPayload';
import {Countries} from '../utils/storage/Countries';
import {Smpte} from 'models/Smpte/Smpte';

const localStorageService = new LocalStorageService();
export const READY = 'Video/READY';
export type READY = void;
export function ready(player: IVideoPlayer) {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    services.video.init(player);
    services.video.getVideoClock(ClockPurpose.playerExposedEvent).subscribe(time => {
      services.eventBus.trigger(PlayerEventExposedNames[PlayerEventsExposed.MediaTimeChangedSecBased], time);
    });
    services.video.getVideoClock(ClockPurpose.playerExposedEvent).subscribe(time => {
      const state = getState();
      const startTime = state.video.playlist.startTime;
      const {frameRate, dropFrame} = state.video.playlist.frameRate;
      const currentFrame = utils.formatting.timeToFrame((time - startTime) * 1000, frameRate, dropFrame);
      const timeData = {
        startTime,
        currentFrame,
        frameRate,
        dropFrame
      };
      services.eventBus.trigger(PlayerEventExposedNames[PlayerEventsExposed.MediaTimeChangedFrameBased], timeData);
    });
    // In case the videoStartTime is defined on ready event we need to seek at that playback position
    if (getState().video.videoStartTime) {
      services.video.seekTo(getState().video.videoStartTime);
    }
    // NOTE: Bitmovin Player will be restarted every audio configuration switch
    // so we need to provide subtitles rreferences to each new created instance
    dispatch(updatePlayerSubtitlesOnReady());
    dispatch({type: READY} as Action<READY>);
  };
}

export const SWITCH_PLAYING = 'Video/PLAYING';
export type SWITCH_PLAYING = boolean;
export function switchPlaying(isPlaying: boolean) {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    if (!isPlaying) {
      services.video.pause();
    }
    dispatch({
      type: SWITCH_PLAYING,
      payload: isPlaying
    } as Action<SWITCH_PLAYING>);
  };
}

export const FAST_FORWARD = 'Video/FAST_FORWARD';
export function fastForward() {
  return (dispatch, getState: () => IAppState) => {
    dispatch({
      type: FAST_FORWARD,
      payload: {}
    });
  };
}

export const FAST_REWIND = 'Video/FAST_REWIND';
export function fastRewind() {
  return (dispatch, getState: () => IAppState) => {
    dispatch({
      type: FAST_REWIND,
      payload: {}
    });
  };
}

export const SWITCH_FAST_FRAME = 'Video/FASTFRAME';
export type SWITCH_FAST_FRAME = {
  fastFrameInProgress: boolean;
  fastFrameType: FastFrameType;
  fastFrameIntervalId: number;
};
//@todo refactor this action
export function switchFastFrame(isStartFastFrame: boolean, fastFrameType?: FastFrameType) {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const fastFrameInProgress = getState().video.fastFrameInProgress;
    const fastFrameIntervalId = getState().video.fastFrameIntervalId;
    const forwardAndRewindRate = getState().video.forwardAndRewindRate;
    const prevFastFrameType = getState().video.fastFrameType;

    let newFastFrameIntervalId = null;

    let sign: number;
    if (fastFrameType === FastFrameType.fastRewind) {
      sign = -1;
    } else if (fastFrameType === FastFrameType.fastForward) {
      sign = 1;
    }

    if (services.video.isReady()) {
      const isNeedRunningFastFrame =
        isStartFastFrame ||
        (fastFrameInProgress &&
          (fastFrameType !== null && fastFrameType !== undefined && fastFrameType !== prevFastFrameType));
      if (isNeedRunningFastFrame) {
        clearInterval(fastFrameIntervalId);
        newFastFrameIntervalId = setInterval(() => {
          const currentTime = services.video.getCurrentTime();
          const FAST_FRAME_MULTIPLIER = 10;
          if (currentTime === 0 && fastFrameType === FastFrameType.fastRewind) {
            clearInterval(newFastFrameIntervalId);
            dispatch({
              type: SWITCH_FAST_FRAME,
              payload: {
                fastFrameInProgress: false,
                fastFrameType,
                fastFrameIntervalId: null
              }
            } as Action<SWITCH_FAST_FRAME>);
            setTimeout(() => {
              dispatch({
                type: SWITCH_PLAYING,
                payload: false
              });
            }, 500);
          } else {
            dispatch(seek(sign * forwardAndRewindRate * FAST_FRAME_MULTIPLIER, SeekType.timeLeap));
          }
        }, 300);
        // Dispatch event that notifies Fast Rewind action
        if (sign === -1) {
          dispatch(fastRewind());
        }
        // Dispatch event that notifies Fast Forward action
        if (sign === 1) {
          dispatch(fastForward());
        }
      } else if (fastFrameInProgress && !isStartFastFrame && fastFrameIntervalId) {
        clearInterval(fastFrameIntervalId);
        setTimeout(() => {
          dispatch({
            type: SWITCH_PLAYING,
            payload: false
          });
        }, 500);
      }

      dispatch({
        type: SWITCH_FAST_FRAME,
        payload: {
          fastFrameInProgress: isNeedRunningFastFrame,
          fastFrameType,
          fastFrameIntervalId: newFastFrameIntervalId
        }
      } as Action<SWITCH_FAST_FRAME>);
    }
  };
}

export const STOP_FAST_FRAME = 'Video/STOP_FAST_FRAME';
export type STOP_FAST_FRAME = number | null;
export function stopFastFrame() {
  return (dispatch, getState: () => IAppState) => {
    clearInterval(getState().video.fastFrameIntervalId);

    dispatch({
      type: STOP_FAST_FRAME,
      payload: null
    } as Action<STOP_FAST_FRAME>);
  };
}

export const SWITCH_LOOP = 'Video/SWITCHLOOP';
export type SWITCH_LOOP = {
  loop: LoopState;
  timeIn: number;
  timeOut: number;
};
export function switchLoop(loop) {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    dispatch({
      type: SWITCH_LOOP,
      payload: loop
    } as Action<SWITCH_LOOP>);
  };
}

export const SET_LOOP_TIME_IN = 'Video/SET_LOOP_TIME_IN';
export type SET_LOOP_TIME_IN = number;
export function setLoopTimeIn(timeIn) {
  return dispatch => {
    dispatch({
      type: SET_LOOP_TIME_IN,
      payload: timeIn
    } as Action<SET_LOOP_TIME_IN>);
  };
}

export const SET_LOOP_TIME_OUT = 'Video/SET_LOOP_TIME_OUT';
export type SET_LOOP_TIME_OUT = number;
export function setLoopTimeOut(timeOut) {
  return dispatch => {
    dispatch({
      type: SET_LOOP_TIME_OUT,
      payload: timeOut
    } as Action<SET_LOOP_TIME_OUT>);
  };
}

export const UPDATE_SHORTCUTS = 'Video/UPDATESHORTCUTS';
export type UPDATE_SHORTCUTS = IVideoShortcuts;
export function getShortcuts(name) {
  localStorageService.set('shortcuts', name);
  const keyboardShortcuts = shortcuts.getShortcutsByName(name);
  return {
    type: UPDATE_SHORTCUTS,
    payload: {type: name, shortcuts: keyboardShortcuts}
  } as Action<UPDATE_SHORTCUTS>;
}

export function setDefaultShortcuts() {
  const defaultShortcuts = shortcuts.setDefaultDefenitions();
  return {
    type: UPDATE_SHORTCUTS,
    payload: {type: 'default', shortcuts: defaultShortcuts}
  } as Action<UPDATE_SHORTCUTS>;
}

export function changeShortcuts(shortcuts) {
  localStorageService.set('defaultShortcuts', JSON.stringify(shortcuts));
  return {
    type: UPDATE_SHORTCUTS,
    payload: {type: 'default', shortcuts}
  } as Action<UPDATE_SHORTCUTS>;
}

export const PROGRESS = 'Video/PROGRESS';
export type PROGRESS = {
  time: number;
  duration: number;
};
export function progress(time: number) {
  return (dispatch, getState: () => IAppState) => {
    dispatch({
      type: PROGRESS,
      payload: {
        time,
        duration: getState().video.metadata.duration
      }
    } as Action<PROGRESS>);
  };
}

export const SUBMIT_METADATA = 'Video/SUBMIT_METADATA';
export type SUBMIT_METADATA = boolean;
export function submitMetadata() {
  return {
    type: SUBMIT_METADATA,
    payload: true
  } as Action<SUBMIT_METADATA>;
}

export const METADATA_RECEIVED = 'Video/METADATA_RECEIVED';
export type METADATA_RECEIVED = IPlayerMetadata;

export function metadata(value: IPlayerMetadata) {
  return {
    type: METADATA_RECEIVED,
    payload: value
  } as Action<METADATA_RECEIVED>;
}

export const VOLUME = 'Video/VOLUME';
export type VOLUME = number;
export const volume = createAction<VOLUME, VOLUME>(VOLUME, (value: VOLUME) => value);

export const MUTE = 'Video/MUTE';
export type MUTE = boolean;
export const mute = createAction<MUTE, MUTE>(MUTE, (isMute: MUTE) => isMute);

export const FRAME_ADVANCE = 'Video/FRAME_ADVANCE';
export const frameAdvance = createAction(FRAME_ADVANCE);

export const FRAME_BACKWARD = 'Video/FRAME_BACKWARD';
export const frameBackward = createAction(FRAME_BACKWARD);

export const SKIP_FORWARD_10 = 'Video/SKIP_FORWARD_10';
export const skipForward10 = createAction(SKIP_FORWARD_10);

export const SKIP_BACKWARD_10 = 'Video/SKIP_BACKWARD_10';
export const skipBackward10 = createAction(SKIP_BACKWARD_10);

export const SKIP_TO_BEGINNING = 'Video/SKIP_TO_BEGINNING';
export const skipToBeginning = createAction(SKIP_TO_BEGINNING);

export const SEEK = 'Video/SEEK';
export type SEEK = {
  time: number;
  duration: number;
};
export function seek(timeVal: number, type: SeekType) {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const state = getState();
    const videoStartTime = state.video.videoStartTime;

    const frameRate = state.video.playlist.frameRate;
    const videoPlayer = services.video;
    const currentTime =
      !!videoStartTime && videoStartTime > services.video.getCurrentTime()
        ? videoStartTime
        : services.video.getCurrentTime();

    let nextTime;
    switch (type) {
      case SeekType.timeLeap:
        nextTime = videoPlayer.seekTo(currentTime + timeVal);
        if (timeVal === 10) {
          dispatch(skipForward10());
        }
        if (timeVal === -10) {
          dispatch(skipBackward10());
        }
        break;

      case SeekType.toTime:
        const toTime = !!videoStartTime && videoStartTime > timeVal ? videoStartTime : timeVal;
        nextTime = videoPlayer.seekTo(toTime);
        if (toTime === 0) {
          dispatch(skipToBeginning());
        }
        break;

      case SeekType.frameLeap:
        const smpte = Smpte.fromTimeWithAdjustments(currentTime, {
          frameRate: frameRate.frameRate,
          dropFrame: frameRate.dropFrame
        });
        // Check if we need to enable fix for the 'frame holes' related with possible non-drop(s)
        const fixFrameHole = !(smpte.minutes % 10 !== 0 && smpte.seconds === 0);
        smpte.addFrame(timeVal, fixFrameHole);
        nextTime = videoPlayer.seekTo(smpte.toAdjustedTime());
        // Check if playback is moving 1 frame in advance
        if (timeVal === 1) {
          dispatch(frameAdvance());
        }
        // Check if video player is moving 1 frame in backward
        if (timeVal === -1) {
          dispatch(frameBackward());
        }
        break;
    }

    dispatch({
      type: SEEK,
      payload: {
        time: nextTime,
        duration: state.video.metadata && state.video.metadata.duration
      }
    } as Action<SEEK>);
  };
}

export const THIRD_INDICATOR = 'Video/THIRD_INDICATOR';
export type THIRD_INDICATOR = boolean;
export const toggleThirdIndicator = createAction<THIRD_INDICATOR, THIRD_INDICATOR>(
  THIRD_INDICATOR,
  (isThirdIndicator: THIRD_INDICATOR) => isThirdIndicator
);

export const PLAYBACK_RATE_CHANGE = 'Video/PLAYBACK_RATE_CHANGE';
export type PLAYBACK_RATE_CHANGE = number;
export function playbackRateChange(playbackRate: number) {
  localStorageService.set('playbackRate', playbackRate.toString());
  return dispatch => {
    dispatch({
      type: PLAYBACK_RATE_CHANGE,
      payload: playbackRate
    } as Action<PLAYBACK_RATE_CHANGE>);
  };
}

export const FORWARD_AND_REWIND_RATE_CHANGE = 'Video/FORWARD_AND_REWIND_RATE_CHANGE';
export type FORWARD_AND_REWIND_RATE_CHANGE = number;
export function forwardAndRewindRateChange(forwardAndRewindRate: number) {
  localStorageService.set('forwardAndRewindRate', forwardAndRewindRate.toString());
  return dispatch => {
    dispatch({
      type: FORWARD_AND_REWIND_RATE_CHANGE,
      payload: forwardAndRewindRate
    } as Action<FORWARD_AND_REWIND_RATE_CHANGE>);
  };
}

export const VIDEO_STOP = 'Video/STOP';
export type VIDEO_STOP = void;
export function stopVideo() {
  return {type: VIDEO_STOP} as Action<VIDEO_STOP>;
}

export const UPDATE_PLAYLIST_FRAME_RATE = 'Video/UPDATE_PLAYLIST_FRAME_RATE';
export type UPDATE_PLAYLIST_FRAME_RATE = IFrameRate;
export const updatePlaylistFrameRate = (frameRate: UPDATE_PLAYLIST_FRAME_RATE) => {
  return (dispatch, getState: () => IAppState) => {
    let currentFrameRate = deepCopy({...getState().video.playlist.frameRate});
    if (frameRate.frameRate) {
      currentFrameRate = {...currentFrameRate, frameRate: frameRate.frameRate};
    }
    if (frameRate.dropFrame !== null) {
      currentFrameRate = {...currentFrameRate, dropFrame: !!frameRate.dropFrame};
    }
    dispatch({type: UPDATE_PLAYLIST_FRAME_RATE, payload: currentFrameRate});
  };
};

export const updateSubtitleTTManError = (subsMetadataId: string, isError: boolean) => {
  return (dispatch, getState: () => IAppState) => {
    const {assets} = getState().video.playlist;
    const updatedAssets = PlaylistAsset.update.updateSubtitleTTManError(assets, subsMetadataId, isError);
    dispatch(updatePlaylistAssets(updatedAssets));
  };
};

export const updateSubtitleTrackUrl = (subsElId: string, ttManResponse: string) => {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const {assets} = getState().video.playlist;
    const {updatedAssets, subtitle} = PlaylistAsset.update.updateSubtitleTrackUrl(
      assets,
      subsElId,
      ttManResponse,
      true
    ) as {updatedAssets: Array<PlaylistAsset>; subtitle: IVideoSubs};
    dispatch(updatePlaylistAssets(updatedAssets));
    // Check if subtitle record is returned and the subtitle feature is supported because despite
    // BitMovinPlayer component the application supports YouTube and Shallow players as well
    if (subtitle && services.video.subtitles()) {
      const isExistingRecord = services.video
        .subtitles()
        .list()
        .find((track: IVideoSubs) => track.id === subtitle.id);
      if (isExistingRecord) {
        services.video.subtitles().remove(subtitle.id);
        console.log('Subtitle record is already added, will replace', isExistingRecord, subtitle);
      }
      services.video.subtitles().add(subtitle);
    }
  };
};

export const updatePlayerSubtitlesOnReady = () => {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const {assets, selectedSub} = getState().video.playlist;
    const allSubs = PlaylistAsset.filter.getAllSubs(assets);
    if (services.video.subtitles() && allSubs.length) {
      // Load previous subtitles data to the new player instance
      allSubs
        .filter((track: IVideoSubs) => track.url)
        .forEach((track: IVideoSubs) => {
          const isSelectedSubtitle = track.id === selectedSub;
          services.video.subtitles().add({...track, enabled: isSelectedSubtitle});
        });
    }
  };
};

export const updateAssetDetailsByAssetId = (
  data: Partial<IAssetDetails>,
  assetId: string,
  completeUpdate: boolean = false
) => {
  return (dispatch, getState: () => IAppState) => {
    const {assets} = getState().video.playlist;
    const updatedAssets = PlaylistAsset.update.updateAssetDetails(assets, data, assetId, completeUpdate);
    dispatch(updatePlaylistAssets(updatedAssets));
  };
};

export const updateAssetEvents = (events: Array<IEventGroup>, assetId: string) => {
  return (dispatch, getState: () => IAppState) => {
    const {assets} = getState().video.playlist;
    const updatedAssets = PlaylistAsset.update.updateAssetEvents(assets, events, assetId);
    dispatch(updatePlaylistAssets(updatedAssets));
  };
};

export const updateAssetHiddenState = (assetId: string, isHidden: boolean = false) => {
  return (dispatch, getState: () => IAppState) => {
    const {assets, selectedAssetId} = getState().video.playlist;
    if (selectedAssetId === assetId) {
      triggerNotification(
        {
          type: 'warning',
          title: 'Assets List',
          message: `The removed asset was selected one the Tabs module. Please select a new one to populate tabs.`,
          delay: 2500
        },
        null
      );
      dispatch(updateSelectedAssetId(null));
    }
    const updatedAssets = PlaylistAsset.update.updateAssetHiddenState(assets, isHidden, assetId);
    dispatch(updatePlaylistAssets(updatedAssets));
  };
};

export const CHANGE_VISIBLE_SUBTITLE = 'TextTrack/CHANGE_VISIBLE_SUBTITLE';
export type CHANGE_VISIBLE_SUBTITLE = string;
export function onChangeVisibleSubtitle(subId) {
  return async (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    // Handle 'None' case when we need to disable subtitles
    if (subId === '') {
      const selectedSub = getState().video.playlist.selectedSub;
      // Disable any previous selected subtitle if Bitmovin player Subtitles API is available
      if (selectedSub && services.video.subtitles()) {
        services.video.subtitles().disable(selectedSub);
      }
      dispatch({
        type: CHANGE_VISIBLE_SUBTITLE,
        payload: subId
      } as Action<CHANGE_VISIBLE_SUBTITLE>);
      return;
    }

    const {assets} = getState().video.playlist;
    const embeddedSubtitlesMetadata = PlaylistAsset.filter.getEmbeddedSubtitles(assets);
    const externalSubtitlesMetadata = PlaylistAsset.filter.getExternalSubtitles(assets);
    const allSubs = PlaylistAsset.filter.getAllSubs(assets);
    const subsEl: IVideoSubs = allSubs.find((s: IVideoSubs) => s.id === subId);
    const subsMetadataEl: ISubtitlesMetadata =
      externalSubtitlesMetadata.find(s => s.id === subId) || embeddedSubtitlesMetadata.find(s => s.id === subId);

    await dispatch(updateSubsUrl(subsMetadataEl, subsEl));

    const isAddedSubtitle = services.video.subtitles()
      ? services.video
          .subtitles()
          .list()
          .find((track: IVideoSubs) => track.id === subId)
      : false;
    if (isAddedSubtitle) {
      services.video
        .subtitles()
        .list()
        .forEach((track: IVideoSubs) => {
          // NOTE: This workaround is added in order to allow proper subtitles support for cases
          // when added subtitles track have the same file URL/URI
          if (track.id !== isAddedSubtitle.id && track.url === isAddedSubtitle.url) {
            services.video.subtitles().remove(track.id);
          }
          // Enable the selected subtitle
          if (track.id === isAddedSubtitle.id) {
            services.video.subtitles().enable(isAddedSubtitle.id);
          }
        });
    } else if (services.video.subtitles()) {
      triggerNotification(
        {
          type: `warning`,
          title: `Player Subtitles`,
          message: `Could not enable subtitle at the moment`,
          delay: 2500
        },
        null
      );
      console.log(`Couldn't enable subtitle as doesn't exists`);
    }
    dispatch({
      type: CHANGE_VISIBLE_SUBTITLE,
      payload: subId
    } as Action<CHANGE_VISIBLE_SUBTITLE>);
  };
}

export const updateSubsUrl = (subsMetadataEl: ISubtitlesMetadata, subsEl: IVideoSubs) => {
  return async (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const isExistingRecord = services.video.subtitles()
      ? services.video
          .subtitles()
          .list()
          .find((track: IVideoSubs) => track.id === subsEl.id)
      : false;
    if (
      subsMetadataEl &&
      subsMetadataEl.assetStatus === 'Staged' &&
      !subsMetadataEl.isTTManError &&
      subsEl &&
      (!subsEl.url || !isExistingRecord)
    ) {
      // Will need to handle the case when subtitle has WebVTT url updated, but somehow is not
      // added into the Bitmovin subtitles list that will allow us to enable/disable
      if (subsEl.url && !isExistingRecord) {
        return dispatch(updateSubtitleTrackUrl(subsMetadataEl.id, subsEl.url));
      }
      // NOTE: Uncomment for local tests using mock WebVTT files served from development server
      // dispatch(updateSubtitleTrackUrl(subsEl.id, 'http://localhost:8085/subtitles/skyfall-serbian-subs.vtt'));
      let subsMetadataItem: ISubtitlesMetadataItem = subsMetadataEl.metadata;
      const ttManResponse = await ttmanAPI.transformSubtitlesToVTT(
        subsMetadataEl.url,
        subsMetadataEl.frameRate,
        subsMetadataItem.coding
      );
      if (ttManResponse.success) {
        dispatch(updateSubtitleTrackUrl(subsEl.id, ttManResponse.data));
      } else {
        dispatch(updateSubtitleTTManError(subsMetadataEl.id, true));
        console.log('TTman API error', ttManResponse.error);
      }
    }
  };
};

export const UPDATE_SUBTITLES_URL = 'TextTrack/UPDATE_SUBTITLES_URL';
export type UPDATE_SUBTITLES_URL = void;
export function onUpdateSubtitlesUrl() {
  return async (dispatch, getState: () => IAppState) => {
    const {assets} = getState().video.playlist;
    const embeddedSubtitlesMetadata = PlaylistAsset.filter.getEmbeddedSubtitles(assets);
    const externalSubtitlesMetadata = PlaylistAsset.filter.getExternalSubtitles(assets);
    const allSubs = PlaylistAsset.filter.getAllSubs(assets);

    allSubs.forEach((subsEl: IVideoSubs) => {
      embeddedSubtitlesMetadata.forEach((subsMetadataEl: ISubtitlesMetadata) => {
        if (subsEl.id === subsMetadataEl.id) {
          dispatch(updateSubsUrl(subsMetadataEl, subsEl));
        }
      });
    });

    allSubs.forEach((subsEl: IVideoSubs) => {
      externalSubtitlesMetadata.forEach((subsMetadataEl: ISubtitlesMetadata) => {
        if (subsEl.id === subsMetadataEl.id) {
          dispatch(updateSubsUrl(subsMetadataEl, subsEl));
        }
      });
    });
  };
}

export const ADD_SUBTITLE_FILE = 'TextTrack/ADD_SUBTITLE_FILE';
export type ADD_SUBTITLE_FILE = IVideoSubs;
export function addSubtitleFile(file: File, lang: string = 'en') {
  return async (dispatch, getState: () => IAppState) => {
    // @ts-ignore
    const subContentResponse = await ttmanAPI.transformSubtitlesToVTT(file, getState().video.frameRate);
    if (!subContentResponse.success) {
      return;
    }

    const newSub: IVideoSubs = {
      id: utils.random.string(10, 'external-file'),
      label: file.name,
      kind: 'subtitles',
      url: subContentResponse.data,
      lang
    };

    dispatch({
      type: ADD_SUBTITLE_FILE,
      payload: newSub
    } as Action<ADD_SUBTITLE_FILE>);

    dispatch(onChangeVisibleSubtitle(newSub.id));
  };
}

export const SKIP_TO_END = 'Video/SKIP_TO_END';
export type SKIP_TO_END = void;
export function skipToEnd() {
  return (dispatch, getState: () => IAppState) => {
    const playing = getState().video.playing;
    const videoMetadata = getState().video.metadata;
    const duration = videoMetadata && videoMetadata.duration;

    if (playing) {
      dispatch({
        type: SWITCH_PLAYING,
        payload: false
      } as Action<SWITCH_PLAYING>);
    }

    dispatch(seek(duration, SeekType.toTime));
  };
}

export const UPDATE_SCRUB_BAR = 'Video/UPDATE_SCRUB_BAR';
export const updateScrubBar = createAction(UPDATE_SCRUB_BAR);

export const GO_TO_LOCATION = 'Video/GO_TO_LOCATION';
export function goToLocation(timeVal: number) {
  return (dispatch, getState: () => IAppState) => {
    dispatch(seek(timeVal, SeekType.toTime));
    dispatch({type: GO_TO_LOCATION});
  };
}

export const CHANGE_MEDIA_TIME_FORMAT = 'Video/CHANGE_MEDIA_TIME_FORMAT';
export type CHANGE_MEDIA_TIME_FORMAT = IDisplayMediaTimeFormat;
export function changeMediaTimeFormat(value: IDisplayMediaTimeFormat) {
  return {
    type: CHANGE_MEDIA_TIME_FORMAT,
    payload: value
  } as Action<CHANGE_MEDIA_TIME_FORMAT>;
}

export const GET_EVENT_GROUPS = 'Video/GET_EVENT_GROUPS';
export type GET_EVENT_GROUPS = any;
export function getEventGroups(assetId?: string) {
  return async (dispatch, getState: () => IAppState) => {
    const {assets, selectedAssetId} = getState().video.playlist;
    const data = {
      eventGroups: [],
      selectedEventGroup: ''
    };
    const providedAssetId = typeof assetId !== 'undefined' ? assetId : selectedAssetId;
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, providedAssetId);
    if (selectedAsset && selectedAsset.assetId) {
      const eventGroups = await atlasAPI.getEventGroups(selectedAsset.assetId);
      // NOTE: For the moment show only the supported types of event groups
      const supported = eventGroups.data.filter(
        (eventGroup: string) => supportedEvents.map(event => event.label).indexOf(eventGroup) !== -1
      );
      data.eventGroups = supported;
      data.selectedEventGroup = data.eventGroups.reduce(
        (acc: string, group: string) => (acc === 'Program Timings' ? acc : group),
        ''
      );
    }
    /* data.eventGroups.push('Other');*/
    dispatch({
      type: GET_EVENT_GROUPS,
      payload: data
    } as Action<GET_EVENT_GROUPS>);
  };
}

export const LOAD_MARKUPS_TYPES_ENUMS = 'Video/LOAD_MARKUPS_TYPES_ENUMS';
export type LOAD_MARKUPS_TYPES_ENUMS = Array<IMarkupsTypes>;
export const loadMarkupsTypesEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    const payload = (await getMarkupsTypes()) as Array<IMarkupsTypes>;
    dispatch({
      type: LOAD_MARKUPS_TYPES_ENUMS,
      payload
    });
    return payload;
  };
};

export const UPDATE_SELECTED_EVENT_GROUP = 'Video/UPDATE_SELECTED_EVENT_GROUP';
export type UPDATE_SELECTED_EVENT_GROUP = IMarkupsEventGroup;
export const updateSelectedEventGroup = createAction<UPDATE_SELECTED_EVENT_GROUP, UPDATE_SELECTED_EVENT_GROUP>(
  UPDATE_SELECTED_EVENT_GROUP,
  (selectedEventGroup: UPDATE_SELECTED_EVENT_GROUP) => selectedEventGroup
);

export const GET_CATEGORIES = 'Video/GET_CATEGORIES';
export type GET_CATEGORIES = {programTimingCategories: Array<string>};
export function getCategories() {
  return async dispatch => {
    let categories = {};
    const categoriesData = await atlasAPI.getCategories();
    categoriesData.forEach(elem => {
      let data = [];
      elem.enums.forEach(item => data.push(item.name));
      categories[elem.name] = data;
    });
    dispatch({
      type: GET_CATEGORIES,
      payload: categories
    } as Action<GET_CATEGORIES>);
  };
}

export const SET_CHANGED_EVENTS = 'Video/SET_CHANGED_EVENTS';
export type SET_CHANGED_EVENTS = Array<IEventGroup>;
export const setChangedEvents = createAction<SET_CHANGED_EVENTS, SET_CHANGED_EVENTS>(
  SET_CHANGED_EVENTS,
  (events: SET_CHANGED_EVENTS) => events
);

export const GET_EVENTS = 'Video/GET_EVENTS';
export function getEvents(assetId?: string) {
  return async (dispatch, getState: () => IAppState) => {
    const {
      playlist: {assets, selectedAssetId},
      types
    } = getState().video;
    let events = [];
    const providedAssetId = typeof assetId !== 'undefined' ? assetId : selectedAssetId;
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, providedAssetId);
    if (selectedAsset && selectedAsset.assetId) {
      const responseAssets = await atlasAPI.getEvents(selectedAsset.assetId);
      events.push(...responseAssets.data);
      events = PlaylistAsset.parsing.parseEventsAfterAPIRequest(events, types);
      dispatch(updateAssetEvents(events, selectedAsset.assetId));
      dispatch({type: GET_EVENTS});
    }
    dispatch(setChangedEvents(deepCopy(events)));
    return deepCopy(events);
  };
}

export const SET_DEFAULT_DATA_FOR_CHANGED_EVENTS = 'Video/SET_DEFAULT_DATA_FOR_CHANGED_EVENTS';
export type SET_DEFAULT_DATA_FOR_CHANGED_EVENTS = void;
export function setDefaultDataForChangedEvents() {
  return async (dispatch, getState: () => IAppState) => {
    const {selectedAssetId, assets} = getState().video.playlist;
    let events = [];
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    if (selectedAsset) {
      events = selectedAsset.events;
    }
    dispatch(setChangedEvents(deepCopy(events)));
    dispatch(setMarkupsErrors([]));
  };
}

export const updateChangedEventGroup = (updatedGroup: IEventGroup) => {
  return (dispatch, getState: () => IAppState) => {
    const changedEvents = deepCopy([...getState().video.changedEvents])
      .filter((group: IEventGroup) => group.name !== updatedGroup.name)
      .reduce((acc: Array<IEventGroup>, eventGroup: IEventGroup) => {
        return [...acc, eventGroup];
      }, [])
      .concat([updatedGroup]);
    dispatch({type: SET_CHANGED_EVENTS, payload: changedEvents});
  };
};

export const REMOVE_EVENT_GROUP = 'Video/REMOVE_EVENT_GROUP';
export type REMOVE_EVENT_GROUP = string;
export function removeEventGroup(name) {
  return async (dispatch, getState: () => IAppState) => {
    const {selectedAssetId} = getState().video.playlist;
    if (selectedAssetId) {
      await atlasAPI.removeEventGroup(selectedAssetId, name);
    } else {
      console.log(`Selected asset not defined ... couldn't remove event`);
    }
  };
}

export function updateAssetDetails(data: IAssetDetails) {
  return async (dispatch, getState: () => IAppState) => {
    const {selectedAssetId, assets} = getState().video.playlist;
    const username = getState().configuration.userEmail;
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    const updatedAssetDetail = {
      ...data,
      username
    };
    const response = selectedAsset
      ? await atlasAPI.updateCurationAsset(selectedAsset, updatedAssetDetail)
      : {success: false, error: 'Asset not found'};
    if (response.success) {
      dispatch(updateAssetDetailsByAssetId(data, selectedAssetId));
    } else {
      triggerNotification(
        {
          type: 'error',
          title: 'Asset Details',
          message: response.error,
          delay: 2500
        },
        null
      );
    }
  };
}

export const UPDATE_ASSET_PARTIALLY = 'Video/UPDATE_ASSET_PARTIALLY';
export type UPDATE_ASSET_PARTIALLY = IAssetDetails;
export const updateAssetPartially = (updatedAssetDetails: Partial<IAssetDetails>, minimumRequirementsMet?: boolean) => {
  return async (dispatch, getState: () => IAppState) => {
    const {
      playlist: {selectedAssetId}
    } = getState().video;
    const username = getState().configuration.userEmail;
    if (!username) {
      throw new Error('Username is missing from Player configuration');
    }
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(getState().video.playlist.assets, selectedAssetId);
    if (!selectedAsset) {
      throw new Error(`Could't get asset information from local store`);
    }

    const partialUpdatedObject = deepCopy({...updatedAssetDetails});

    // Update state with the new updated asset details
    dispatch(updateAssetDetailsByAssetId(partialUpdatedObject, selectedAssetId));

    const apiAssetDetails = await atlasAPI.getAssetDetails(selectedAsset);
    if (!apiAssetDetails.success) {
      dispatch(updateAssetDetailsByAssetId(selectedAsset.assetDetails, selectedAssetId));
      dispatch(setEditMode(true));
      throw new Error(`Could't get asset information from ATLAS`);
    }

    // NOTE: Handle differences between quality controls created and the ones in asset side
    if (partialUpdatedObject.qualityControl.length && apiAssetDetails.data.qualityControl.length) {
      partialUpdatedObject.qualityControl[0].qcStatus = apiAssetDetails.data.qualityControl[0].qcStatus || 'None';
      if (apiAssetDetails.data.qualityControl[0].qcTiers) {
        partialUpdatedObject.qualityControl[0].qcTiers = apiAssetDetails.data.qualityControl[0].qcTiers;
      }
    }

    const updatedAsset = clearProps(
      {
        ...apiAssetDetails.data,
        ...partialUpdatedObject,
        ...(minimumRequirementsMet ? {minimumRequirementsMet} : {}),
        username
      },
      ['createdBy', 'createdDate', 'modifiedBy', 'modifiedDate', 'titles', 'errorLogs']
    );
    const response = await atlasAPI.updateCurationAsset(selectedAsset, updatedAsset, true);
    if (!response.success) {
      dispatch(updateAssetDetailsByAssetId(selectedAsset.assetDetails, selectedAssetId));
      dispatch(setEditMode(true));
      throw response.error;
    } else {
      // NOTE: ATLAS will send 200 status code even in the case some data are missing for curation mode
      // as versionId, conformanceGroupId and we need to take care of this case as well
      const errors = response.data.reasons && Array.isArray(response.data.reasons) ? response.data.reasons : [];
      // Once we want to publish an unregistered asset we need to define their related information as a registered one
      if (!selectedAsset.isRegistered && minimumRequirementsMet && !errors.length) {
        const upatedRegisterState = PlaylistAsset.update.updateAssetRegisteredState(
          getState().video.playlist.assets,
          true,
          selectedAsset.assetId
        );
        dispatch(updatePlaylistAssets(upatedRegisterState));
      }

      // Get the latest updated version of the selected asset
      const updatedSelectedAsset = PlaylistAsset.filter.getPlaylistAsset(
        getState().video.playlist.assets,
        selectedAssetId
      );
      // Retrieve latest updated copy of asset from ATLAS
      const apiUpdatedAsset = await atlasAPI.getAssetDetails(updatedSelectedAsset);
      if (apiUpdatedAsset.success) {
        // In case we have a successful save and we don't have any error list we can check
        // and update the errorLogs field
        // NOTE: This asset details update action will happen only for UNREGISTERED assets
        const data = errors.length ? apiUpdatedAsset.data : {errorLogs: apiUpdatedAsset.data.errorLogs || []};
        dispatch(updateAssetDetailsByAssetId(data, selectedAssetId, !!errors.length));
      }
      if (errors.length) {
        // Enable Edit mode to allow user select missing data
        dispatch(setEditMode(true));
        throw new ErrorPayload(
          {reasons: errors},
          `Asset is missing fields in order to meet minimum requirements for processing`
        );
      }
    }
  };
};

export const setVideoMetaData = (data: Partial<IAssetDetails>) => {
  return async (dispatch, getState: () => IAppState) => {
    const {
      playlist: {selectedAssetId}
    } = getState().video;
    dispatch(updateAssetDetailsByAssetId(data, selectedAssetId));
  };
};

export const SET_MARKUPS_ERRORS = 'Video/SET_MARKUPS_ERRORS';
export type SET_MARKUPS_ERRORS = Array<IMarkupsError>;
export function setMarkupsErrors(data: Array<IMarkupsError> = []) {
  return (dispatch, getState: () => IAppState) => {
    dispatch({
      type: SET_MARKUPS_ERRORS,
      payload: data
    });
  };
}

export const UPDATE_THUMBNAILS_VTT = 'Video/UPDATE_THUMBNAILS_VTT';
export type UPDATE_THUMBNAILS_VTT = IParsedVTT[];
export const updateThumbnailsVtt = () => {
  return async (dispatch, getState: () => IAppState) => {
    const {video} = getState();
    const thumbnails = await onePlayerService.getThumbnailsUrlAPI(video.playlist.id);
    let jsonVTT: IParsedVTT[] = [];
    if (thumbnails.data && thumbnails.data.length > 0) {
      let thumbs = thumbnails.data;
      let thumbnail = thumbs.find(tmb => tmb.parentAssetId === video.playlist.selectedAssetId);
      if (thumbnail) {
        jsonVTT = generateParsedVTT(thumbnail.url, video.metadata.duration);
      }
    }
    dispatch({
      type: UPDATE_THUMBNAILS_VTT,
      payload: jsonVTT
    });
  };
};

export const SET_VIDEO_FRAGMENT_IN_TIME = 'Video/SET_VIDEO_FRAGMENT_IN_TIME';
export type SET_VIDEO_FRAGMENT = {inTime: number; outTime: number; lastUpdated: IVideoFragmentLastUpdated};
export function setVideoFragmentInTime(inTime: number) {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const outTime = getState().video.currentVideoFragment.outTime;
    services.eventBus.trigger(PlayerEventExposedNames[PlayerEventsExposed.VideoFragmentInTimeChanged], inTime);
    if (outTime < inTime) {
      services.eventBus.trigger(PlayerEventExposedNames[PlayerEventsExposed.VideoFragmentOutTimeChanged], inTime);
    }

    dispatch({
      type: SET_VIDEO_FRAGMENT_IN_TIME,
      payload: {inTime, outTime: outTime < inTime ? inTime : outTime, lastUpdated: 'InTime'}
    } as Action<SET_VIDEO_FRAGMENT>);
  };
}

export const SET_VIDEO_FRAGMENT_OUT_TIME = 'Video/SET_VIDEO_FRAGMENT_OUT_TIME';
export function setVideoFragmentOutTime(outTime: number, isSetOutTimeIfInTimeNull: boolean = false) {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const inTime = getState().video.currentVideoFragment.inTime;

    if (isSetOutTimeIfInTimeNull || inTime) {
      services.eventBus.trigger(PlayerEventExposedNames[PlayerEventsExposed.VideoFragmentOutTimeChanged], outTime);
      if (outTime < inTime) {
        services.eventBus.trigger(PlayerEventExposedNames[PlayerEventsExposed.VideoFragmentInTimeChanged], outTime);
      }

      dispatch({
        type: SET_VIDEO_FRAGMENT_OUT_TIME,
        payload: {inTime: outTime < inTime ? outTime : inTime, outTime, lastUpdated: 'OutTime'}
      } as Action<SET_VIDEO_FRAGMENT>);
    }
  };
}

export const SET_VIDEO_FRAGMENT_IN_TIME_BY_CURRENT_TIME = 'Video/SET_VIDEO_FRAGMENT_IN_TIME_BY_CURRENT_TIME';
export type SET_VIDEO_FRAGMENT_IN_TIME_BY_CURRENT_TIME = void;
export function setVideoFragmentInTimeByCurrentTime() {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const inTime = services.video.getCurrentTime();
    dispatch(setVideoFragmentInTime(inTime));
  };
}

export const SET_VIDEO_FRAGMENT_OUT_TIME_BY_CURRENT_TIME = 'Video/SET_VIDEO_FRAGMENT_OUT_TIME_BY_CURRENT_TIME';
export type SET_VIDEO_FRAGMENT_OUT_TIME_BY_CURRENT_TIME = void;
export function setVideoFragmentOutTimeByCurrentTime() {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const outTime = services.video.getCurrentTime();
    dispatch(setVideoFragmentOutTime(outTime));
  };
}

export const GET_VIDEO_CURRENT_TIME = 'Video/GET_VIDEO_CURRENT_TIME';
export const getVideoCurrentTime = () => {
  return (dispatch, getState: () => IAppState, services: IServiceProvider): number => {
    const currentTime = services.video.getCurrentTime();
    dispatch({type: GET_VIDEO_CURRENT_TIME});
    return currentTime;
  };
};

export function getHybrikPlaylistUrl() {
  return async (dispatch, getState: () => IAppState) => {
    const {url} = getState().video.playlist;
    const playlist = await hybrikAPI.getPlaylist(url);
    console.log('getHybrikPlaylistUrl playlist', playlist);
  };
}

export const UPDATE_AUDIO_CONFIG = 'Video/UPDATE_AUDIO_CONFIG';
export type UPDATE_AUDIO_CONFIG = Partial<IVideoAudioConfiguration>;
export const updateAudioConfig = (
  updatedConfigs: UPDATE_AUDIO_CONFIG,
  enableChannelsOnConfiguration: boolean = false
) => {
  return (dispatch, getState: () => IAppState) => {
    const {assets} = getState().video.playlist;
    const checkedChannels = [];
    // Added logic to allow selection of all configuration channels if 'enableChannelsOnConfiguration' flag is provided
    if (enableChannelsOnConfiguration && has(updatedConfigs, 'selectedConfig')) {
      const embeddedAudioChannelConfigs = PlaylistAsset.filter.getEmbeddedAudioConfigurations(assets);
      const externalAudioChannelConfigs = PlaylistAsset.filter.getExternalAudioConfigurations(assets);
      const config = [...embeddedAudioChannelConfigs, ...externalAudioChannelConfigs].find(
        (audioConfig: IAudioChannelConfiguration) => audioConfig.id === updatedConfigs.selectedConfig
      );
      const channels = (config && config.trackDetail ? config.trackDetail : [])
        .filter((track: IAudioChannel) => track.channelMap.length)
        .map((track: IAudioChannel) => track.id);
      checkedChannels.push(...channels);
    }
    dispatch({
      type: UPDATE_AUDIO_CONFIG,
      payload: checkedChannels.length
        ? {
            ...updatedConfigs,
            checkedChannels: {
              ...(updatedConfigs.checkedChannels || {}),
              [updatedConfigs.selectedConfig]: checkedChannels
            }
          }
        : updatedConfigs
    });
  };
};

export const UPDATE_SWITCHING_CURRENT_TIME = 'Video/UPDATE_SWITCHING_CURRENT_TIME';
export type UPDATE_SWITCHING_CURRENT_TIME = boolean;
export const updateSwitchingCurrentTime = createAction<UPDATE_SWITCHING_CURRENT_TIME, UPDATE_SWITCHING_CURRENT_TIME>(
  UPDATE_SWITCHING_CURRENT_TIME,
  (currentTime: UPDATE_SWITCHING_CURRENT_TIME) => currentTime
);

export const UPDATE_PLAYBACK_AUDIO = 'Video/UPDATE_PLAYBACK_AUDIO';
export const updatePlaybackAudio = () => {
  return async (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    dispatch({type: UPDATE_PLAYBACK_AUDIO});
    const {id, url, assets, proxy, video} = getState().video.playlist;
    const {selectedConfig, checkedChannels} = getState().video.audioConfiguration;
    if (selectedConfig === null) {
      console.log('Selected configuration set to NULL');
      return;
    }
    const asset = PlaylistAsset.filter.findConfiguration(assets, selectedConfig, proxy, video);
    if (!asset) {
      triggerNotification(
        {
          type: 'warning',
          title: 'Playback Warning',
          message: `Sorry for the inconvenience, but couldn't found configuration data!`,
          delay: 2500
        },
        null
      );
      return;
    }

    let response: IResponse;
    dispatch(updateSwitchingCurrentTime(true));

    if (!asset.proxyUrl) {
      const isVideoAsset = asset.isVideoAudioConfiguration;
      const channels = checkedChannels[selectedConfig] || [];
      const audioChannels = asset.trackDetail.filter((audio: IAudioChannel) => channels.includes(audio.id));
      const bodyRequest = generatePlaybackRequestBody(
        audioChannels,
        isVideoAsset ? null : asset.assetId,
        generateTextOverlay(`${getState().configuration.userEmail}`, localStorageService.get('watermarkPosition')),
        isVideoAsset ? asset.assetId : null
      );
      console.log('Generated request body for playback', JSON.stringify(bodyRequest));
      response = await onePlayerService.getPlaybackUrlAPI(bodyRequest, id);
    } else {
      response = {success: true, data: asset.proxyUrl};
    }
    if (response.success) {
      // Case we when we have configurations with already configured URL
      if (url === response.data) {
        triggerNotification(
          {
            type: 'info',
            title: 'Playback',
            message: `Audio Configuration already defined!`,
            delay: 2500
          },
          null
        );
        dispatch(updateSwitchingCurrentTime(false));
        return;
      }
      dispatch(updatePlaylistUrl(response.data));
      dispatch(switchPlaying(false));
    } else {
      console.log(response.error);
      triggerNotification(
        {
          type: 'error',
          title: 'Playback Error',
          message: `Couldn't retrieve valid audio configuration!`,
          delay: 2500
        },
        null
      );
      dispatch(updateSwitchingCurrentTime(false));
      dispatch(updatePlaybackProxyState('Error'));
    }
  };
};

export const UPDATE_PLAYBACK_PROXY_STATE = 'Video/UPDATE_PLAYBACK_PROXY_STATE';
export type UPDATE_PLAYBACK_PROXY_STATE = IAssetStatus;
export const updatePlaybackProxyState = createAction<UPDATE_PLAYBACK_PROXY_STATE, UPDATE_PLAYBACK_PROXY_STATE>(
  UPDATE_PLAYBACK_PROXY_STATE,
  (proxyState: UPDATE_PLAYBACK_PROXY_STATE) => proxyState
);

export const CHECK_PLAYBACK_PROXY = 'Video/CHECK_PLAYBACK_PROXY';
export const checkPlaybackProxy = () => {
  return async (dispatch, getState: () => IAppState) => {
    // Put checking state for asset proxy state
    await wait(800);
    dispatch(updatePlaybackProxyState('Checking'));
    dispatch(updatePlaylistUrl(''));
    const {assets, proxy, video} = getState().video.playlist;
    const {selectedConfig} = getState().video.audioConfiguration;
    const asset = PlaylistAsset.filter.findConfiguration(assets, selectedConfig, proxy, video);
    if (!asset) {
      triggerNotification(
        {
          type: 'warning',
          title: 'Playback',
          message: `Sorry for the inconvenience, but couldn't found configuration data!`,
          delay: 2500
        },
        null
      );
      return;
    }
    const response = checkAssetProxyState(asset.id, getState().video.playlist) as IAssetStatus;
    // Update proxy state of asset once the service will return the response
    dispatch(updatePlaybackProxyState(response));
  };
};

export const getAssetsData = (forceStaging: boolean = false, restoreSelected: boolean = false) => {
  return async (dispatch, getState: () => IAppState) => {
    const {selectedConfig, checkedChannels} = getState().video.audioConfiguration;
    const video = getState().video.playlist.video;
    // Reset Player loading state
    dispatch(updatePlaylistStagingState(false));
    dispatch(updateAudioConfig({selectedConfig: restoreSelected ? selectedConfig : null}));
    dispatch(updateAudioConfig({checkedChannels: restoreSelected ? checkedChannels : {}}));
    dispatch(updatePlaybackProxyState(null));
    dispatch(updateSwitchingCurrentTime(false));
    dispatch(updatePlaylistUrl(''));
    dispatch({type: UPDATE_PLAYLIST_VIDEO, payload: restoreSelected ? video : null});
    // Create playlist with staged assets and return content of the playlist
    const playlistContent = (await dispatch(updatePlaylist(forceStaging, restoreSelected))) as IPlaylistContentResponse;
    if (!playlistContent) {
      const proxy = getState().video.playlist.proxy;
      if (proxy) {
        dispatch(initAudioConfiguration());
      }
    }
  };
};

export const initAudioConfiguration = () => {
  return (dispatch, getState: () => IAppState) => {
    const assets = getState().video.playlist.assets;
    const autoSelectPlaybackConfig = getState().configuration.autoSelectPlaybackConfig;
    const embeddedAudioChannelConfigs = PlaylistAsset.filter.getEmbeddedAudioConfigurations(assets);
    const externalAudioChannelConfigs = PlaylistAsset.filter.getExternalAudioConfigurations(assets);
    const {proxy, video, error} = getState().video.playlist;
    // In cases we have Playlist error or we have empty list of audio configurations for a given proxy,
    // we should define logic accordingly in order to be able to enable it even if not staged properly
    const allowProxySetup = error
      ? false
      : ![...embeddedAudioChannelConfigs, ...externalAudioChannelConfigs, ...(video ? [video] : [])].length;
    const audioConfiguration = !!(proxy && (allowProxySetup || proxy.assetStatus === 'Staged'))
      ? proxy
      : embeddedAudioChannelConfigs.length
      ? embeddedAudioChannelConfigs[0]
      : externalAudioChannelConfigs.length
      ? externalAudioChannelConfigs[0]
      : video
      ? video
      : null;
    // On Playlist Init we need to enable all possible channels by default for all possible configurations
    const checkedChannels = [...embeddedAudioChannelConfigs, ...externalAudioChannelConfigs].reduce(
      (acc: {[x: string]: Array<string>}, config: IAudioChannelConfiguration) => {
        return {...acc, ...preparePrePlaybackLoad(config).checkedChannel};
      },
      {}
    );
    dispatch(updateAudioConfig({checkedChannels}));
    const {selectedConfig} = preparePrePlaybackLoad(audioConfiguration);
    // NOTE: Auto selection of audio configurations after Playlist loading is made optional per ONEPL-409
    const allowAutoSelection = autoSelectPlaybackConfig;
    if (selectedConfig && allowAutoSelection) {
      dispatch(updateAudioConfig({selectedConfig}));
      dispatch(checkPlaybackProxy());
    }
  };
};

export const UPDATE_AUDIO_CONFIGURATIONS = 'Video/UPDATE_AUDIO_CONFIGURATIONS';
export const updateAudioConfigurations = (confs: Array<IAudioChannelConfiguration>) => {
  return async (dispatch, getState: () => IAppState): Promise<IResponse> => {
    dispatch(processingAudioMetadata(true));
    const {selectedAssetId, assets} = getState().video.playlist;
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    const {selectedConfig} = getState().video.audioConfiguration;
    const username = getState().configuration.userEmail;
    const response = await atlasAPI.updateAudioChannelAPI(confs, selectedAsset, username);
    if (response.success) {
      const isAssetAudioConfSelected = !!selectedAsset.audio.find(
        (conf: IAudioChannelConfiguration) => conf.id === selectedConfig
      );
      const updatedConfs = confs.reduce(
        (acc: Array<IAudioChannelConfiguration>, conf: IAudioChannelConfiguration, index: number) => {
          return [
            ...acc,
            {
              ...conf,
              id: `${selectedAsset.assetId}-audio-${index}`,
              assetId: selectedAsset.assetId,
              assetStatus: selectedAsset.assetStatus
            }
          ];
        },
        []
      );
      // Once the data is saved successfully we can update store with the new data
      const updateAssets = PlaylistAsset.update.updateAssetAudioConfigurations(
        assets,
        updatedConfs,
        selectedAsset.assetId
      );
      dispatch(updatePlaylistAssets(updateAssets));
      dispatch(processingAudioMetadata(false));
      if (isAssetAudioConfSelected) {
        const selectedConf = preparePrePlaybackLoad(updatedConfs[0]).selectedConfig;
        const checkedChannelsConf = preparePrePlaybackLoad(updatedConfs[0]).checkedChannel;
        dispatch(updateAudioConfig({checkedChannels: {...checkedChannelsConf}}));
        dispatch(updateAudioConfig({selectedConfig: selectedConf}));
        dispatch(checkPlaybackProxy());
      }
      return {success: true};
    } else {
      dispatch(processingAudioMetadata(false));
      alert((response.error && response.error.message) || `Something went wrong and data couldn't be save`);
      return {success: false, error: response.error};
    }
  };
};

export const UPDATE_PLAYLIST_ASSETS = 'Video/UPDATE_PLAYLIST_ASSETS';
export type UPDATE_PLAYLIST_ASSETS = Array<PlaylistAsset>;
export const updatePlaylistAssets = createAction<UPDATE_PLAYLIST_ASSETS, UPDATE_PLAYLIST_ASSETS>(
  UPDATE_PLAYLIST_ASSETS,
  (assets: UPDATE_PLAYLIST_ASSETS) => assets
);

const handlePlaylistUpdate = (content: IPlaylistContentResponse) => {
  return async (dispatch, getState: () => IAppState) => {
    const {doAssetContentTypeCheck, curationModeEnabled} = getState().configuration;
    const atlas = getState().video.playlist.atlas;
    let assets = [];
    let playlistAssets = [];
    // Get all assets in related conformance group
    assets.push(...(content.assetDetails || []));
    playlistAssets.push(...content.assets);

    // NOTE: In case the doAssetContentTypeCheck flag is defined then we need to process only those assets
    // that have the same content type as the assets that were supplied to the One Player when initiated
    // TODO: Probably we will need to have this filtering done directly to One Player Service
    // stage end-point that returns the list of the assets related to the created playlist
    if (doAssetContentTypeCheck) {
      const atlasIds = atlas.map((atlasAsset: IPlaylistAsset) => atlasAsset.assetId);
      const contentTypes = assets
        .filter(asset => atlasIds.indexOf(asset.id || '') !== -1)
        .map(asset => (asset.contentType || '').toLowerCase())
        .filter(contentType => contentType);
      const assetsOfSameContentTypeIds = assets
        .filter(asset => contentTypes.indexOf((asset.contentType || '').toLowerCase()) !== -1)
        .map(asset => asset.id)
        .filter(asset => asset);
      playlistAssets = assetsOfSameContentTypeIds.length
        ? playlistAssets.filter((asset: IPlaylistAsset) => assetsOfSameContentTypeIds.indexOf(asset.assetId) !== -1)
        : playlistAssets;
    }

    const subtitlesUrlResponse = await onePlayerService.getSubtitlesUrlsAPI(content.id);
    let waveformsUrlResponse = await onePlayerService.getWaveformsUrlsAPI(content.id);
    let waveformUrlData: IWaveformsUrlData[] = [];
    if (waveformsUrlResponse.success) {
      waveformUrlData = waveformsUrlResponse.data.map((waveform: IWaveformsUrlData) => {
        const waveformData = waveform.filename.match(/(.*)_(.*)_(.*)/);
        return {
          ...waveform,
          url: `${getState().configuration.cacheLocationUrl}transcode-hybrik/scratch2/${waveformData[1]}/${
            waveform.filename
          }`
        } as IWaveformsUrlData;
      });
    }

    // Get markups types from the enums in order to normalize event types that doesn't match the case
    const types = await dispatch(loadMarkupsTypesEnums());

    const parsedAssetsPromise = playlistAssets.reduce(
      async (objects: Promise<Array<PlaylistAsset>>, asset: IPlaylistAsset) => {
        const result = await objects;
        // Some assets as Waveforms and Thumbnails will have null IDs so we can omit events call
        const events = asset.assetId ? await atlasAPI.getEvents(asset.assetId) : {success: false};
        // Check if asset is unregistered by checking through related API call
        // Some assets as Waveforms and Thumbnails will have null IDs so we can pre-mark them as registered
        const isUnRegistered = asset.assetId
          ? await atlasAPI.isUnregisteredAsset(asset.assetId, {showErrorLogs: true})
          : {success: false, data: null};
        // NOTE: Get asset details as first choice from the search end-point as
        // the playlist returns an outdated version at some cases
        const assetDetails =
          isUnRegistered.data || assets.find((record: any) => record.id === asset.assetId) || DEFAULT_ASSET_DETAILS;

        // Prepare initial data for the asset to provide for the constructor
        const assetStatus = asset.assetStatus;
        const playlistAsset = new PlaylistAsset(
          assetStatus,
          assetDetails,
          true,
          PlaylistAsset.parsing.parseEventsAfterAPIRequest(events.success ? events.data : [], types),
          asset.assetType
        );

        // Update subtitles URL to retrieve the related file
        playlistAsset.updateSubtitlesUrl(subtitlesUrlResponse.success ? subtitlesUrlResponse.data : []);

        // Update waveforms URL to retrieve the related file
        const waveformsUrlData = waveformUrlData.filter(
          (record: IWaveformsUrlData) => record.parentAssetId === asset.assetId
        );
        playlistAsset.updateWaveformsUrl(waveformsUrlData);

        playlistAsset.isRegistered = !isUnRegistered.success;

        // NOTE: Waveform and Thumbnail assets come without valid assetId and they are related with
        // other Audio assets so on load we will define their status and handle as hidden
        if (['Waveform', 'Thumbnails'].indexOf(asset.assetType) !== -1) {
          playlistAsset.isHidden = true;
        }

        return Promise.resolve([...result, playlistAsset]);
      },
      Promise.resolve([])
    );

    let parsedAssets = await parsedAssetsPromise;

    // NOTE: Check if curation mode is enabled and update conformance group for the selected assets
    if (curationModeEnabled) {
      parsedAssets = PlaylistAsset.update.updatePlaylistAssetsConformanceGroups(parsedAssets);
    }

    // dispatch({
    //   type: UPDATE_THUMBNAILS_VTT,
    //   payload: [] //vtt || []
    // });
    dispatch(updatePlaylistAssets(parsedAssets));
    const videoAsset = PlaylistAsset.filter.getVideoPlaylistAsset(parsedAssets, getState().video.playlist.atlas);
    dispatch(parseVideoAudioConfiguration(videoAsset));
    dispatch(updateAudiosStagingState(playlistAssets));
    dispatch(initAudioConfiguration());
    if (parsedAssets.length) {
      dispatch(updateSelectedAssetId(parsedAssets[0].assetId));
    }
  };
};

export const UPDATE_PLAYLIST_VIDEO = 'Video/UPDATE_PLAYLIST_VIDEO';
export type UPDATE_PLAYLIST_VIDEO = IAudioChannelConfiguration;
export const parseVideoAudioConfiguration = (asset: PlaylistAsset) => {
  return (dispatch, getState: () => IAppState) => {
    const configuration = asset
      ? PlaylistAsset.parsing.createAudioChannelConfiguration(
          {},
          'video-config',
          asset.assetId,
          asset.assetStatus,
          asset.isRegistered,
          asset.assetDetails.hrId
        )
      : null;
    if (configuration) {
      // Mark the audio configuration as video configuration
      configuration.isVideoAudioConfiguration = true;
      const frameRate: IFrameRate = asset.assetDetails.frameRate
        ? {
            frameRate: asset.assetDetails.frameRate.value ? +asset.assetDetails.frameRate.value : null,
            dropFrame:
              typeof asset.assetDetails.frameRate.isDrop !== 'undefined' ? asset.assetDetails.frameRate.isDrop : null
          }
        : null;
      // Frame Rate of the player will be defined accordingly the metadata of the video
      // that it's passed when the One Player is initiated, to avoid cases when One UI
      // doesn't pass the correct frameRate
      if (frameRate) {
        // NOTE: Disabled for the moment as we should retrieve this correct value from One UI
        // dispatch(updatePlaylistFrameRate(frameRate));
      }
    }
    console.log('Video Audio Configuration', configuration);
    dispatch({type: UPDATE_PLAYLIST_VIDEO, payload: configuration});
  };
};

export const UPDATE_PLAYLIST_ID = 'Video/UPDATE_PLAYLIST_ID';
export type UPDATE_PLAYLIST_ID = string;
export const updatePlaylist = (forceStaging: boolean = false, restoreSelected: boolean = false) => {
  return async (dispatch, getState: () => IAppState): Promise<IPlaylistContentResponse> => {
    // Clear storage for the previous expanded audio configuration
    const expandedAudioConfStorage = new ExpandedAudioConf();
    expandedAudioConfStorage.remove();
    const atlas = getState().video.playlist.atlas;
    const curationModeEnabled = getState().configuration.curationModeEnabled;
    // Update store state for the forcing staging
    dispatch(updatePlaylistForceStaging(false));
    // Enable Playlist loading indicators
    dispatch(updatePlaylistLoadingState(true));
    triggerNotification(
      {
        type: 'info',
        title: 'Playlist',
        message: 'Loading Playlist...',
        delay: 2500
      },
      null
    );
    if (!atlas.length) {
      dispatch(updatePlaylistLoadingError('No Assets data provided to the player'));
      dispatch(updatePlaylistLoadingState(false));
      return null;
    }
    const response = await onePlayerService.initPlaylistCreation(atlas, forceStaging, curationModeEnabled);
    if (response.success) {
      dispatch({
        type: UPDATE_PLAYLIST_ID,
        payload: (response.data as IPlaylistContentResponse).id
      });
      // Don't update playlist content when restore is required
      if (!restoreSelected) {
        await dispatch(handlePlaylistUpdate(response.data));
      } else {
        // Once we need to restore a selected configuration we just need to request API and update only the status
        dispatch(updateAudiosStagingState(response.data.assets));
      }
      triggerNotification(
        {
          type: 'success',
          title: 'Playlist',
          message: 'Playlist loaded successfully!',
          delay: 2500
        },
        null
      );
    } else {
      console.log(response.error);
      triggerNotification(
        {
          type: 'warning',
          title: 'Playlist Warning',
          message: `Playlist was not generated!`,
          delay: 2500
        },
        null
      );
      dispatch(updatePlaylistLoadingError(response.error.message));
    }
    // Disable Playlist loading indicators
    dispatch(updatePlaylistLoadingState(false));
    return response.success ? response.data : null;
  };
};

export const PLAYLIST_LOADING_STATE = 'Video/PLAYLIST_LOADING_STATE';
export type PLAYLIST_LOADING_STATE = boolean;
export const updatePlaylistLoadingState = createAction<PLAYLIST_LOADING_STATE, PLAYLIST_LOADING_STATE>(
  PLAYLIST_LOADING_STATE,
  (loading: PLAYLIST_LOADING_STATE) => loading
);

export const PLAYLIST_LOADING_ERROR = 'Video/PLAYLIST_LOADING_ERROR';
export type PLAYLIST_LOADING_ERROR = string;
export const updatePlaylistLoadingError = createAction<PLAYLIST_LOADING_ERROR, PLAYLIST_LOADING_ERROR>(
  PLAYLIST_LOADING_ERROR,
  (error: PLAYLIST_LOADING_ERROR) => error
);

export const GET_LANGUAGE_ENUMS = 'Video/GET_LANGUAGE_ENUMS';
export type GET_LANGUAGE_ENUMS = Array<IEnum>;
export const getLanguageEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('CommonLanguage'), new Language(), GET_LANGUAGE_ENUMS);
  };
};

export const GET_SUB_TYPE_ENUMS = 'Video/GET_SUB_TYPE_ENUMS';
export type GET_SUB_TYPE_ENUMS = Array<IEnum>;
export const getSubTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('VideoSubType'), new SubType(), GET_SUB_TYPE_ENUMS);
  };
};

export const GET_VIDEO_CODEC_ENUMS = 'Video/GET_VIDEO_CODEC_ENUMS';
export type GET_VIDEO_CODEC_ENUMS = Array<IEnum>;
export const getVideoCodecEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('VideoCodec'), new VideoCodec(), GET_VIDEO_CODEC_ENUMS);
  };
};

export const GET_ENCODE_RATE_ENUMS = 'Video/GET_ENCODE_RATE_ENUMS';
export type GET_ENCODE_RATE_ENUMS = Array<IEnum>;
export const getEncodeRateEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('EncodeRate'), new EncodeRate(), GET_ENCODE_RATE_ENUMS);
  };
};

export const GET_PICTURE_FORMAT_ENUMS = 'Video/GET_PICTURE_FORMAT_ENUMS';
export type GET_PICTURE_FORMAT_ENUMS = Array<IEnum>;
export const getPictureFormatEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('PictureFormat'), new PictureFormat(), GET_PICTURE_FORMAT_ENUMS);
  };
};

export const GET_SUBTITLE_TYPE_ENUMS = 'Video/GET_SUBTITLE_TYPE_ENUMS';
export type GET_SUBTITLE_TYPE_ENUMS = Array<IEnum>;
export const getSubtitleLanguageEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('SubtitleType'), new SubtitleType(), GET_SUBTITLE_TYPE_ENUMS);
  };
};

export const GET_BIT_DEPTH_ENUMS = 'Video/GET_BIT_DEPTH_ENUMS';
export type GET_BIT_DEPTH_ENUMS = Array<IEnum>;
export const getBitDepthEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('BitDepth'), new BitDepth(), GET_BIT_DEPTH_ENUMS);
  };
};

export const GET_PIXEL_ASPECT_ENUMS = 'Video/GET_PIXEL_ASPECT_ENUMS';
export type GET_PIXEL_ASPECT_ENUMS = Array<IEnum>;
export const getPixelAspectEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('PixelAspect'), new PixelAspect(), GET_PIXEL_ASPECT_ENUMS);
  };
};

export const GET_DISPLAY_ASPECT_RATIO_ENUMS = 'Video/GET_DISPLAY_ASPECT_RATIO_ENUMS';
export type GET_DISPLAY_ASPECT_RATIO_ENUMS = Array<IEnum>;
export const getDisplayAspectRatioEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('DisplayAspectRatio'),
      new DisplayAspectRatio(),
      GET_DISPLAY_ASPECT_RATIO_ENUMS
    );
  };
};

export const GET_SCAN_TYPE_ENUMS = 'Video/GET_SCAN_TYPE_ENUMS';
export type GET_SCAN_TYPE_ENUMS = Array<IEnum>;
export const getScanTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('ScanType'), new ScanType(), GET_SCAN_TYPE_ENUMS);
  };
};

export const GET_PICTURE_ASPECT_RATIO_ENUMS = 'Video/GET_PICTURE_ASPECT_RATIO_ENUMS';
export type GET_PICTURE_ASPECT_RATIO_ENUMS = Array<IEnum>;
export const getPictureAspectRatioEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('PictureAspectRatio'),
      new PictureAspectRatio(),
      GET_PICTURE_ASPECT_RATIO_ENUMS
    );
  };
};

export const GET_COLOR_SUB_SAMPLING_ENUMS = 'Video/GET_COLOR_SUB_SAMPLING_ENUMS';
export type GET_COLOR_SUB_SAMPLING_ENUMS = Array<IEnum>;
export const getColorSubSamplingEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('ColorSubSampling'),
      new ColorSubSampling(),
      GET_COLOR_SUB_SAMPLING_ENUMS
    );
  };
};

export const GET_COLOR_TYPE_ENUMS = 'Video/GET_COLOR_TYPE_ENUMS';
export type GET_COLOR_TYPE_ENUMS = Array<IEnum>;
export const getColorTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('ColorType'), new ColorType(), GET_COLOR_TYPE_ENUMS);
  };
};

export const GET_TIME_CODE_ENUMS = 'Video/GET_TIME_CODE_ENUMS';
export type GET_TIME_CODE_ENUMS = Array<IEnum>;
export const getTimeCodeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('TimeCode'), new TimeCode(), GET_TIME_CODE_ENUMS);
  };
};

export const GET_BIT_RATE_MODE_ENUMS = 'Video/GET_BIT_RATE_MODE_ENUMS';
export type GET_BIT_RATE_MODE_ENUMS = Array<IEnum>;
export const getBitRateModeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('BitRateMode'), new BitRateMode(), GET_BIT_RATE_MODE_ENUMS);
  };
};

export const GET_AUDIO_BIT_DEPTH_ENUMS = 'Video/GET_AUDIO_BIT_DEPTH_ENUMS';
export type GET_AUDIO_BIT_DEPTH_ENUMS = Array<IEnum>;
export const getAudioBitDepthEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AudioBitDepth'), new AudioBitDepth(), GET_AUDIO_BIT_DEPTH_ENUMS);
  };
};

export const GET_AUDIO_SUB_TYPE_ENUMS = 'Video/GET_AUDIO_SUB_TYPE_ENUMS';
export type GET_AUDIO_SUB_TYPE_ENUMS = Array<IEnum>;
export const getAudioSubTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AudioSubType'), new AudioSubType(), GET_AUDIO_SUB_TYPE_ENUMS);
  };
};

export const GET_AUDIO_CODEC_ENUMS = 'Video/GET_AUDIO_CODEC_ENUMS';
export type GET_AUDIO_CODEC_ENUMS = Array<IEnum>;
export const getAudioCodecEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AudioCodec'), new AudioCodec(), GET_AUDIO_CODEC_ENUMS);
  };
};

export const GET_COLOR_ENCODING_ENUMS = 'Video/GET_COLOR_ENCODING_ENUMS';
export type GET_COLOR_ENCODING_ENUMS = Array<IEnum>;
export const getColorEncodingEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('ColorEncoding'), new ColorEncoding(), GET_COLOR_ENCODING_ENUMS);
  };
};

export const GET_TRANSFER_FUNCTION_ENUMS = 'Video/GET_TRANSFER_FUNCTION_ENUMS';
export type GET_TRANSFER_FUNCTION_ENUMS = Array<IEnum>;
export const getTransferFunctionEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('TransferFunction'),
      new TransferFunction(),
      GET_TRANSFER_FUNCTION_ENUMS
    );
  };
};

export const GET_FUNCTIONS_ENUMS = 'Video/GET_FUNCTIONS_ENUMS';
export type GET_FUNCTIONS_ENUMS = Array<IEnum>;
export const getAssetFunctionEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AssetFunction'), new AssetFunction(), GET_FUNCTIONS_ENUMS);
  };
};

export const GET_COLOR_DIFF_ENUMS = 'Video/GET_COLOR_DIFF_ENUMS';
export type GET_COLOR_DIFF_ENUMS = Array<IEnum>;
export const getColorDiffEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('ColorDiff'), new ColorDiff(), GET_COLOR_DIFF_ENUMS);
  };
};

export const GET_CHANNEL_MAP_ENUMS = 'Video/GET_CHANNEL_MAP_ENUMS';
export type GET_CHANNEL_MAP_ENUMS = Array<IEnum>;
export const getChannelMapEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AssetAudioChannelMap'), new ChannelMap(), GET_CHANNEL_MAP_ENUMS);
  };
};

export const GET_CHANNEL_CONFIG_ENUMS = 'Video/GET_CHANNEL_CONFIG_ENUMS';
export type GET_CHANNEL_CONFIG_ENUMS = Array<IEnum>;
export const getChannelConfigEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('AssetAudioChannelConfig'),
      new ChannelConfig(),
      GET_CHANNEL_CONFIG_ENUMS
    );
  };
};

export const GET_CHANNEL_CONFIG_TYPE_ENUMS = 'Video/GET_CHANNEL_CONFIG_TYPE_ENUMS';
export type GET_CHANNEL_CONFIG_TYPE_ENUMS = Array<IEnum>;
export const getChannelConfigTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('AssetAudioType'),
      new ChannelConfigType(),
      GET_CHANNEL_CONFIG_TYPE_ENUMS
    );
  };
};

export const GET_ASSET_STATUS_ENUMS = 'Video/GET_ASSET_STATUS_ENUMS';
export type GET_ASSET_STATUS_ENUMS = Array<IEnum>;
export const getAssetStatusEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AssetStatus'), new AssetStatus(), GET_ASSET_STATUS_ENUMS);
  };
};

export const GET_FILE_WRAPPER_ENUMS = 'Video/GET_FILE_WRAPPER_ENUMS';
export type GET_FILE_WRAPPER_ENUMS = Array<IEnum>;
export const getFileWrapperEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AssetFileWrapper'), new FileWrapper(), GET_FILE_WRAPPER_ENUMS);
  };
};

export const GET_CONTENT_TYPE_ENUMS = 'Video/GET_CONTENT_TYPE_ENUMS';
export type GET_CONTENT_TYPE_ENUMS = Array<IEnum>;
export const getContentTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AssetContentType'), new ContentType(), GET_CONTENT_TYPE_ENUMS);
  };
};

export const GET_FORMAT_COMPLIANCE_ENUMS = 'Video/GET_FORMAT_COMPLIANCE_ENUMS';
export type GET_FORMAT_COMPLIANCE_ENUMS = Array<IEnum>;
export const getFormatComplianceEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('AssetFormatCompliance'),
      new FormatCompliance(),
      GET_FORMAT_COMPLIANCE_ENUMS
    );
  };
};

export const GET_FRAME_RATE_ENUMS = 'Video/GET_FRAME_RATE_ENUMS';
export type GET_FRAME_RATE_ENUMS = Array<IEnum>;
export const getFrameRateEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AssetFrameRate'), new FrameRate(), GET_FRAME_RATE_ENUMS);
  };
};

export const GET_REFERENCES_NAME_ENUMS = 'Video/GET_REFERENCES_NAME_ENUMS';
export type GET_REFERENCES_NAME_ENUMS = Array<IEnum>;
export const getReferencesNameEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('CommonReferencesName'),
      new ReferencesName(),
      GET_REFERENCES_NAME_ENUMS
    );
  };
};

export const GET_REFERENCES_TYPE_ENUMS = 'Video/GET_REFERENCES_TYPE_ENUMS';
export type GET_REFERENCES_TYPE_ENUMS = Array<IEnum>;
export const getReferencesTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('CommonReferencesType'),
      new ReferencesType(),
      GET_REFERENCES_TYPE_ENUMS
    );
  };
};

export const GET_COUNTRIES_ENUMS = 'Video/GET_COUNTRY_ENUMS';
export type GET_COUNTRIES_ENUMS = Array<IEnum>;
export const getCountriesEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('CommonCountries'), new Countries(), GET_COUNTRIES_ENUMS);
  };
};

export const GET_LANGUAGE_DIALECT = 'Video/GET_LANGUAGE_DIALECT';
export type GET_LANGUAGE_DIALECT = Array<IEnum>;
export const getLanguageDialect = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('CommonLanguageDialect'),
      new LanguageDialect(),
      GET_LANGUAGE_DIALECT
    );
  };
};

export const GET_COLOR_MODEL_ENUMS = 'Video/GET_COLOR_MODEL_ENUMS';
export type GET_COLOR_MODEL_ENUMS = Array<IEnum>;
export const getColorModelEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('ColorModel'), new ColorModel(), GET_COLOR_MODEL_ENUMS);
  };
};

export const GET_SIGNAL_RANGE_ENUMS = 'Video/GET_SIGNAL_RANGE_ENUMS';
export type GET_SIGNAL_RANGE_ENUMS = Array<IEnum>;
export const getSignalRangeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('SignalRange'), new SignalRange(), GET_SIGNAL_RANGE_ENUMS);
  };
};

export const GET_COLORIMETRY_ENUMS = 'Video/GET_COLORIMETRY_ENUMS';
export type GET_COLORIMETRY_ENUMS = Array<IEnum>;
export const getColorimetryEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('Colorimetry'), new Colorimetry(), GET_COLORIMETRY_ENUMS);
  };
};

export const GET_WHITE_POINT_ENUMS = 'Video/GET_WHITE_POINT_ENUMS';
export type GET_WHITE_POINT_ENUMS = Array<IEnum>;
export const getWhitePointEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('WhitePoint'), new WhitePoint(), GET_WHITE_POINT_ENUMS);
  };
};

export const GET_DYNAMIC_RANGE_TYPE_ENUMS = 'Video/GET_DYNAMIC_RANGE_TYPE_ENUMS';
export type GET_DYNAMIC_RANGE_TYPE_ENUMS = Array<IEnum>;
export const getDynamicRangeTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('DynamicRangeType'),
      new DynamicRangeType(),
      GET_DYNAMIC_RANGE_TYPE_ENUMS
    );
  };
};

export const GET_DYNAMIC_RANGE_SYSTEM_ENUMS = 'Video/GET_DYNAMIC_RANGE_SYSTEM_ENUMS';
export type GET_DYNAMIC_RANGE_SYSTEM_ENUMS = Array<IEnum>;
export const getDynamicRangeSystemEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('DynamicRangeSystem'),
      new DynamicRangeSystem(),
      GET_DYNAMIC_RANGE_SYSTEM_ENUMS
    );
  };
};

export const GET_GRAPHICS_TYPE_ENUMS = 'Video/GET_GRAPHICS_TYPE_ENUMS';
export type GET_GRAPHICS_TYPE_ENUMS = Array<IEnum>;
export const getGetGraphicsTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('AssetImageGraphicsType'),
      new GraphicsType(),
      GET_GRAPHICS_TYPE_ENUMS
    );
  };
};

export const GET_NON_MEDIA_TYPE_ENUMS = 'Video/GET_NON_MEDIA_TYPE_ENUMS';
export type GET_NON_MEDIA_TYPE_ENUMS = Array<IEnum>;
export const getNonMediaTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(dispatch, atlasAPI.getEnums('AssetNonMediaType'), new NonMediaType(), GET_NON_MEDIA_TYPE_ENUMS);
  };
};

export const GET_NON_MEDIA_SUB_TYPE_ENUMS = 'Video/GET_NON_MEDIA_SUB_TYPE_ENUMS';
export type GET_NON_MEDIA_SUB_TYPE_ENUMS = Array<IEnum>;
export const getNonMediaSubTypeEnums = () => {
  return async (dispatch, getState: () => IAppState) => {
    await updateEnums(
      dispatch,
      atlasAPI.getEnums('AssetNonMediaSubType'),
      new NonMediaSubType(),
      GET_NON_MEDIA_SUB_TYPE_ENUMS
    );
  };
};

export const LOAD_ENUMS = 'Video/LOAD_ENUMS';
export const loadEnums = () => {
  return (dispatch, getState: () => IAppState) => {
    dispatch(getLanguageEnums());
    dispatch(getBitRateModeEnums());
    dispatch(getAudioBitDepthEnums());
    dispatch(getAudioSubTypeEnums());
    dispatch(getAudioCodecEnums());
    dispatch(getSubTypeEnums());
    dispatch(getVideoCodecEnums());
    dispatch(getEncodeRateEnums());
    dispatch(getSubtitleLanguageEnums());
    dispatch(getBitDepthEnums());
    dispatch(getPixelAspectEnums());
    dispatch(getDisplayAspectRatioEnums());
    dispatch(getScanTypeEnums());
    dispatch(getPictureAspectRatioEnums());
    dispatch(getColorSubSamplingEnums());
    dispatch(getColorTypeEnums());
    dispatch(getTimeCodeEnums());
    dispatch(getColorEncodingEnums());
    dispatch(getTransferFunctionEnums());
    dispatch(getColorDiffEnums());
    dispatch(getAssetFunctionEnums());
    dispatch(getPictureFormatEnums());
    dispatch(getChannelMapEnums());
    dispatch(getChannelConfigEnums());
    dispatch(getChannelConfigTypeEnums());
    dispatch(getAssetStatusEnums());
    dispatch(getFileWrapperEnums());
    dispatch(getContentTypeEnums());
    dispatch(getFormatComplianceEnums());
    dispatch(getFrameRateEnums());
    dispatch(getReferencesNameEnums());
    dispatch(getReferencesTypeEnums());
    dispatch(getCountriesEnums());
    dispatch(getColorModelEnums());
    dispatch(getSignalRangeEnums());
    dispatch(getColorimetryEnums());
    dispatch(getWhitePointEnums());
    dispatch(getDynamicRangeTypeEnums());
    dispatch(getDynamicRangeSystemEnums());
    dispatch(getLanguageDialect());
    dispatch(getGetGraphicsTypeEnums());
    dispatch(getNonMediaTypeEnums());
    dispatch(getNonMediaSubTypeEnums());
  };
};

export const UPDATE_STAGE_COUNT = 'Video/UPDATE_STAGE_COUNT';
export type UPDATE_STAGE_COUNT = number;
export const updateStageCount = createAction<UPDATE_STAGE_COUNT, UPDATE_STAGE_COUNT>(
  UPDATE_STAGE_COUNT,
  (count: number) => count
);

export const UPDATE_PLAYLIST_STAGING_STATE = 'Video/UPDATE_PLAYLIST_STAGING_STATE';
export type UPDATE_PLAYLIST_STAGING_STATE = boolean;
export const updatePlaylistStagingState = createAction<UPDATE_PLAYLIST_STAGING_STATE, UPDATE_PLAYLIST_STAGING_STATE>(
  UPDATE_PLAYLIST_STAGING_STATE,
  (isStaging: UPDATE_PLAYLIST_STAGING_STATE) => isStaging
);

export const UPDATE_AUDIOS_STAGING_STATE = 'Video/UPDATE_AUDIOS_STAGING_STATE';
export const updateAudiosStagingState = (assets: Array<IPlaylistAsset>) => {
  return (dispatch, getState: () => IAppState) => {
    const storeAssets = getState().video.playlist.assets;
    let video = getState().video.playlist.video ? deepCopy({...getState().video.playlist.video}) : null;
    const {proxy} = getState().video.playlist;
    const {selectedConfig} = getState().video.audioConfiguration;
    const playlistAssets = PlaylistAsset.update.updateAssetsStatus(storeAssets, assets);
    const selectedAsset = PlaylistAsset.filter.findConfiguration(playlistAssets, selectedConfig, proxy, video);
    // Update playlist assets with the new 'staging' status so it can reflect to the UI
    dispatch(updatePlaylistAssets(playlistAssets));
    // Update video audio configuration status as well so it can show correct staging state if no audio configurations
    if (video) {
      const updatedAsset = assets.find((asset: IPlaylistAsset) => asset.assetId === video.assetId);
      video = updatedAsset ? {...video, assetStatus: updatedAsset.assetStatus} : video;
      dispatch({type: UPDATE_PLAYLIST_VIDEO, payload: video});
    }
    // Check if we have selected audio configuration and update related status
    if (selectedAsset) {
      dispatch({
        type: UPDATE_PLAYBACK_PROXY_STATE,
        payload: selectedAsset.assetStatus
      });
    }
    const audioSubs = playlistAssets
      .reduce(
        (acc: Array<IAudioChannelConfiguration | ISubtitlesMetadata>, asset: PlaylistAsset) => {
          return [...acc, ...asset.audio, ...asset.subtitles];
        },
        [video]
      )
      .filter(config => config);
    const allStaged = areAllStaged(audioSubs);
    console.log('Staged all assets:', allStaged);
    dispatch(updatePlaylistStagingState(!allStaged));
  };
};

export const UPDATE_PLAYLIST_FORCE_STAGING = 'Video/UPDATE_PLAYLIST_FORCE_STAGING';
export type UPDATE_PLAYLIST_FORCE_STAGING = boolean;
export const updatePlaylistForceStaging = createAction<UPDATE_PLAYLIST_FORCE_STAGING, UPDATE_PLAYLIST_FORCE_STAGING>(
  UPDATE_PLAYLIST_FORCE_STAGING,
  (force: UPDATE_PLAYLIST_FORCE_STAGING) => force
);

export const UPDATE_PLAYLIST_URL = 'Video/UPDATE_PLAYLIST_URL';
export type UPDATE_PLAYLIST_URL = string;
export const updatePlaylistUrl = createAction<UPDATE_PLAYLIST_URL, UPDATE_PLAYLIST_URL>(
  UPDATE_PLAYLIST_URL,
  (url: UPDATE_PLAYLIST_URL) => url
);

export const UPDATE_PLAYLIST_PROXY = 'Video/UPDATE_PLAYLIST_PROXY';
export type UPDATE_PLAYLIST_PROXY = IAudioChannelConfiguration;
export const updatePlaylistProxy = (url: string) => {
  return async (dispatch, getState: () => IAppState) => {
    // Check if remote file exists and proxy can be played within player
    const fileExists = await remoteFileExists(url);
    const proxyAudioConfiguration = PlaylistAsset.parsing.createAudioChannelConfiguration(
      {},
      `proxy`,
      null,
      fileExists ? 'Staged' : 'Error',
      true,
      null
    );
    proxyAudioConfiguration.proxyUrl = url;
    dispatch({
      type: UPDATE_PLAYLIST_PROXY,
      payload: proxyAudioConfiguration
    });
  };
};

export const UPDATE_SELECTED_ASSET_ID = 'Video/UPDATE_SELECTED_ASSET_ID';
export type UPDATE_SELECTED_ASSET_ID = string;
export const updateSelectedAssetId = (assetId: string) => {
  return async (dispatch, getState: () => IAppState) => {
    dispatch({type: UPDATE_SELECTED_ASSET_ID, payload: assetId});
    const tabs = (appConfig.view && appConfig.view.tabs) || [];
    await dispatch(getEventGroups(assetId));
    const events = await dispatch(getEvents(assetId));
    // Will retrieve comments only if tabs view is enabled and Comments is included on configs
    if (tabs.indexOf('Comments') !== -1) {
      await dispatch(getComments());
    }
    await dispatch(getCategories());
    const videoStartTime = getStartTimecodeTimestampFromEvents(events, getState().video.playlist.frameRate);
    dispatch(updateVideoStartTime(videoStartTime));
    // When asset is switched we can seek to the defined video start time
    dispatch(seek(videoStartTime, SeekType.toTime));
  };
};

export const UPDATE_UNREGISTERED_CONFORMANCE_GROUP = 'Video/UPDATE_UNREGISTERED_CONFORMANCE_GROUP';
export const updateUnregisteredConformanceGroup = (titles: Array<ISearchTitle>) => {
  return (dispatch, getState: () => IAppState) => {
    const assets = getState().video.playlist.assets;
    const updatedAssets = PlaylistAsset.update.updateUnregisteredAssetsTitles(assets, titles);
    dispatch(updatePlaylistAssets(updatedAssets));
  };
};

export const UPDATE_VIDEO_START_TIME = 'Video/UPDATE_VIDEO_START_TIME';
export type UPDATE_VIDEO_START_TIME = number;
export const updateVideoStartTime = (time: UPDATE_VIDEO_START_TIME) => {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    const tabsView = (appConfig.view && appConfig.view.tabs) || [];
    if (tabsView.indexOf('Markups') === -1) {
      // NOTE: For the moment the video start time feature will be enabled only for cases
      // when the tabs module will be enabled, including Markups so the user can have the
      // possibility to override any behavior that doesn't want
      return;
    }
    // In case the current time of the video player is less than the provided
    // video start time then we will need to seek to this new position
    if (services.video.getCurrentTime() < time) {
      services.video.seekTo(time);
    }
    dispatch({type: UPDATE_VIDEO_START_TIME, payload: time});
  };
};
