import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Subscription} from 'rxjs';
import {Dropdown} from 'tt-components/src/Dropdown';
import {DropdownContent, defaultMenuControlBarStyles} from '../../DropdownContent';
import {Observable} from 'tt-components/src/VideoPlayer/Observable';
import {ITemplateColors} from '../../../../@types/templateColors';
import {title} from '../../../utils/utils';
import {IDisplayMediaTimeFormat} from '../../../../@types/displayMediaTimeFormat';
import {MediaTimeFormatSettings} from '../GeneralSettings/MediaTimeFormatSettings';
import {IFrameRate} from 'tt-components';
import {formatting} from '../../../utils/formatting';

interface IMediaDisplayPopupProps {
  videoClock: Observable<number>;
  templateColors: ITemplateColors;
  enabled: boolean;
  disabled?: boolean;
  isControlBarShown: boolean;
  showingDropdownTimeout: number;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  isFullScreen: boolean;
  displayMediaTimeFormat: IDisplayMediaTimeFormat;
  frameRate: IFrameRate;
  changeMediaTimeFormat: (value: IDisplayMediaTimeFormat) => void;
}

interface IMediaDisplayPopupState {
  time: number;
}

export class MediaDisplayPopup extends React.Component<IMediaDisplayPopupProps, IMediaDisplayPopupState> {
  $onPlayerClockTick: Subscription;

  constructor(props) {
    super(props);

    this.state = {
      time: 0
    };
  }

  componentWillMount() {
    this.initSubscriber();
  }

  componentDidUpdate(prevProps: IMediaDisplayPopupProps) {
    if (!isEqual(prevProps.videoClock, this.props.videoClock)) {
      this.initSubscriber();
    }
  }

  initSubscriber() {
    if (this.$onPlayerClockTick) {
      this.$onPlayerClockTick.unsubscribe();
    }
    this.$onPlayerClockTick = this.props.videoClock.subscribe((time: number) => {
      this.setState({time});
    });
  }

  getClockComponent = () => (
    <div className="media-display-popup-container_button-component">
      {formatting(this.state.time, this.props.displayMediaTimeFormat, this.props.frameRate)}
    </div>
  );

  getContent = () => {
    const content = (
      <div className="media-display-popup-container_clock-dropdown_content">
        <MediaTimeFormatSettings
          hideTitle
          templateColors={this.props.templateColors}
          displayMediaTimeFormat={this.props.displayMediaTimeFormat}
          changeMediaTimeFormat={this.props.changeMediaTimeFormat}
        />
      </div>
    );
    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent content={content} color={this.props.templateColors.tooltipBackground} />
      </div>
    );
  };

  render() {
    const style = {...menuStyles, ...(this.props.isFullScreen ? {paddingBottom: 5} : {paddingTop: 5})};
    return (
      <div className="media-display-popup-container">
        <Dropdown
          {...title('smpte (hh:mm:ss:ff)', this.props.enabled)}
          buttonComponent={() => this.getClockComponent()}
          disabled={this.props.disabled}
          content={this.getContent()}
          className={`ttc-control-bar-dropdown media-display-popup-container_clock-dropdown${
            this.props.isControlBarShown ? `` : ` op_controls-bar_hidden`
          }`}
          openAbove={true}
          triggerDropAction="click"
          showingDropdownTimeout={this.props.showingDropdownTimeout}
          portalNode={this.props.isFullScreen ? this.props.videoPlayerContainer : this.props.closestBodyElement}
          style={style}
        />
      </div>
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  width: 140,
  padding: 0,
  borderWidth: 0,
  marginLeft: 0,
  boxSizing: 'content-box'
};
