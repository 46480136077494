import * as React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import configureStore from 'configureStore';
import {DEFAULT_VIDEO_STATE} from './reducers/video';
import {DEFAULT_CONTROL_BAR_CONTROLS, DEFAULT_VIDEO_OPTIONS, DEFAULT_CONFIGURATION_STATE} from './reducers/appConfig';
import {IAppConfig} from '../@types/appConfig';

import {IAppPlaylist, IAppState} from './state';
import VideoPlayer from './containers/VideoPlayer';
import {VideoPlayerApi} from './VideoPlayerApi';
import {serviceProvider} from './services/serviceProvider';
import {defaultFrameRate} from './utils/utils';
import {Store} from 'redux';

import './styles/main';

export function create(containerSelector: string | HTMLElement, appConfig: IAppConfig, playlist: IAppPlaylist) {
  const configuration: IAppConfig = {
    ...appConfig,
    videoOptions: {...DEFAULT_VIDEO_OPTIONS, ...(appConfig.videoOptions || {})},
    controlBarControls: !!appConfig.controlBarControls
      ? [...appConfig.controlBarControls]
      : [...DEFAULT_CONTROL_BAR_CONTROLS, ...appConfig.controlBarControls],
    oneUiHost: appConfig.oneUiHost || DEFAULT_CONFIGURATION_STATE.oneUiHost,
    onePlayerServiceHost: appConfig.onePlayerServiceHost || DEFAULT_CONFIGURATION_STATE.onePlayerServiceHost,
    oneCustomerHost: appConfig.oneCustomerHost || DEFAULT_CONFIGURATION_STATE.oneCustomerHost,
    curationModeEnabled: appConfig.curationModeEnabled || DEFAULT_CONFIGURATION_STATE.curationModeEnabled,
    bitmovinKey: appConfig.bitmovinKey || DEFAULT_CONFIGURATION_STATE.bitmovinKey,
    autoSelectPlaybackConfig: appConfig.autoSelectPlaybackConfig || DEFAULT_CONFIGURATION_STATE.autoSelectPlaybackConfig
  };

  let store: Store<IAppState> = configureStore({
    video: {
      ...DEFAULT_VIDEO_STATE,
      playlist: {
        ...DEFAULT_VIDEO_STATE.playlist,
        ...playlist,
        atlas: !Array.isArray(playlist.atlas) ? [playlist.atlas] : playlist.atlas,
        url: playlist.url || '',
        frameRate: defaultFrameRate(playlist.frameRate)
      }
    },
    configuration
  });

  const container =
    typeof containerSelector === 'string' ? document.querySelector(containerSelector) : containerSelector;
  const enableLocalGtm = appConfig.gtmOptions && appConfig.gtmOptions && appConfig.gtmOptions.enableLocalGtm;

  render(
    <Provider store={store}>
      <VideoPlayer containerElement={container as HTMLElement} />
    </Provider>,
    container
  );

  if (enableLocalGtm) {
    serviceProvider.gtm.init(configuration, playlist);
  }

  return new VideoPlayerApi(store);
}
