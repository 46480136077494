import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Tt2b} from '../../../../components/Typography/Tt2b';

import {ChevronDownIcon} from '../../../../assets/Icons/ChevronDownIcon';
import {ChevronRightIcon} from '../../../../assets/Icons/ChevronRight';
import {wait} from '../../../../utils/utils';

interface ICollapsibleProps {
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  open?: boolean;
  onToggle?: (state: boolean) => void;
}

interface ICollapsibleState {
  open: boolean;
  transitClass: string;
  isVisible: boolean;
}

export class Collapsible extends React.Component<ICollapsibleProps, ICollapsibleState> {
  static getDerivedStateFromProps(nextProps: ICollapsibleProps, prevState: ICollapsibleState) {
    if (nextProps.open !== prevState.open && typeof nextProps.open !== 'undefined') {
      return {open: nextProps.open};
    }
    return null;
  }

  constructor(props: ICollapsibleProps) {
    super(props);

    this.state = {
      open: false,
      transitClass: '',
      isVisible: false
    };
  }

  componentDidMount() {
    this.setState({transitClass: this.state.open ? 'hidden' : ''}, this.transitOnInit);
  }

  transitOnInit = async () => {
    await wait(50);
    this.setState({transitClass: 'hidden', isVisible: true});
  };

  onToggle = () => {
    if (typeof this.props.open !== 'undefined' && this.props.onToggle) {
      this.props.onToggle(!this.state.open);
    } else {
      this.setState({open: !this.state.open});
    }
  };

  getContent = () => (
    <div className={`collapsible-container_content${this.state.open ? `` : ` ${this.state.transitClass}`}`}>
      {this.props.content || 'Some content'}
    </div>
  );

  render() {
    return (
      <div className={`collapsible-container${this.state.isVisible ? ` visible` : ``}`}>
        <div className={`collapsible-container_header${this.state.open ? ` open` : ``}`} onClick={this.onToggle}>
          <Tt2b content={this.props.title || ''} className="collapsible-container_header_text" />
          <div className="collapsible-container_header_icon">
            <Icon icon={this.state.open ? ChevronDownIcon : ChevronRightIcon} color="#8194B5" size="14px" />
          </div>
        </div>
        {this.getContent()}
      </div>
    );
  }
}
