import * as React from 'react';
import {AudioConfigurations} from './components/AudioConfigurations';
import {AudioMapping} from './components/AudioMapping';
import {IAudioChannelConfiguration} from '../../../../../@types/audioChannelConfiguration';
import {IEnums} from '../../../../state/IEnums';
import {IResponse} from '../../../../../@types/response';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails, IErrorLog} from '../../../../../@types/assetDetails';
import {deepCopy} from '../../utils/helpers';

export interface IAudioProps {
  selectedAsset: PlaylistAsset;
  updatedAssetDetails: Partial<IAssetDetails>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  processingMetadata: boolean;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  isMapping: boolean;
  onDisableMapping: () => void;
  updateAudioConfigurations: (confs: Array<IAudioChannelConfiguration>) => IResponse;
  loading: boolean;
  error: string;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
}

export class Audio extends React.Component<IAudioProps> {
  getAssetData = () => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const clonePlaylistAsset: PlaylistAsset = this.props.selectedAsset
      ? PlaylistAsset.parsing.parseSinglePlainObjectToInstance(deepCopy({...this.props.selectedAsset}))
      : null;
    if (clonePlaylistAsset) {
      clonePlaylistAsset.updateAssetDetails(updatedAssetDetails);
    }

    return clonePlaylistAsset
      ? {assetDetails: clonePlaylistAsset.assetDetails, audioMetadata: clonePlaylistAsset.audio}
      : {assetDetails: {}, audioMetadata: []};
  };

  render() {
    const {assetDetails, audioMetadata} = this.getAssetData();

    return (
      <div className="metadata-audio-container">
        {this.props.isMapping ? (
          <AudioMapping
            audioMetadata={audioMetadata}
            channelConfigEnums={this.props.enums.channelConfig}
            channelMapEnums={this.props.enums.channelMap}
            languageEnums={this.props.enums.language}
            channelConfigTypeEnums={this.props.enums.channelConfigType}
            processingMetadata={this.props.processingMetadata}
            onDisableMapping={this.props.onDisableMapping}
            updateAudioConfigurations={this.props.updateAudioConfigurations}
          />
        ) : (
          <AudioConfigurations
            loading={this.props.loading}
            error={this.props.error}
            tabsDataInEditMode={this.props.tabsDataInEditMode}
            enums={this.props.enums}
            updatedAssetDetails={this.props.updatedAssetDetails}
            assetDetails={assetDetails}
            audioMetadata={audioMetadata}
            updatePartialAssetDetails={this.props.updatePartialAssetDetails}
            closestBody={this.props.closestBody}
            errorLogs={this.props.errorLogs}
          />
        )}
      </div>
    );
  }
}
