import * as React from 'react';
import {ConfigRow} from '../ConfigRow';
import {IAudioChannel} from '../../../../../../../../../@types/audioChannelConfiguration';
import {defineChannelCheckboxLabel} from '../../../../../../../../utils/utils';
import {deepCopy} from '../../../../../../utils/helpers';
import {IEnum} from '../../../../../../../../../@types/enum';
import {Dropdown, IDropdownOption} from '../../../../../../../../components/Dropdown';
import {AudioPlay} from '../../../../../../../../assets/Icons/AudioPlayIcon';
import {AudioListening} from '../../../../../../../../assets/Icons/AudioListeningIcon';
import {Icon} from 'tt-components/src/Icon';

interface IChannelRowProps {
  enableEdit?: boolean;
  channel: IAudioChannel;
  channelMapEnums: Array<IEnum>;
  onChannelUpdate?: (track: string, updateObject: {[x: string]: string | Array<string>}) => void;
  isPlaying?: boolean;
}

interface IChannelRowState {
  editAssignment: boolean;
  isPlaying: boolean;
}

export class ChannelRow extends React.Component<IChannelRowProps, IChannelRowState> {
  constructor(props) {
    super(props);

    this.state = {
      editAssignment: true,
      isPlaying: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying) {
      this.setState({
        isPlaying: this.props.isPlaying
      });
    }
  }

  disableEdit = () => {
    this.setState({editAssignment: false});
  };

  switchPlaying = () => {
    this.setState({isPlaying: !this.state.isPlaying});
  };

  renderControler(options: Array<IDropdownOption>, index: number) {
    const {channel, onChannelUpdate} = this.props;
    const newProps = () => {
      return {buttonContentStyles: {color: '#1F72F2'}};
    };
    return (
      <Dropdown
        key={index}
        borderless
        search
        disableMatchWidth
        options={options}
        selected={channel.channelMap[index]}
        paddingLeft={0}
        borderWidth={0}
        onSelected={(value: string) => {
          if (onChannelUpdate) {
            const channelMap = deepCopy(channel.channelMap) as Array<string>;
            channelMap[index] = value;
            onChannelUpdate(channel.track, {channelMap});
          }
        }}
        {...newProps()}
      />
    );
  }

  renderControlerPerMapping() {
    const {channelMapEnums, channel} = this.props;
    const options: Array<any> = channelMapEnums
      .filter((enumMap: IEnum) => enumMap.isActive)
      .map((enumMap: IEnum) => ({value: enumMap.value, label: enumMap.name}));
    return channel.channelMap.map((mapping: string, index) => {
      return this.renderControler(options, index);
    });
  }

  renderAssignmentControl() {
    if (this.state.editAssignment && this.props.enableEdit) {
      return (
        <div className="channel-row-container_assignment_multiple-channels">{this.renderControlerPerMapping()}</div>
      );
    } else {
      const {channel, channelMapEnums, enableEdit} = this.props;
      const assignment = defineChannelCheckboxLabel(channel.channelMap, channelMapEnums);
      return (
        <span
          className={`channel-row-container_assignment${enableEdit ? ` editable` : ``}`}
          onClick={e => this.setState({editAssignment: true})}
        >
          {assignment}
        </span>
      );
    }
  }

  render() {
    const {channel, enableEdit} = this.props;
    const isMultipleMapping = this.state.editAssignment && enableEdit && channel.channelMap.length > 1;
    const {isPlaying} = this.state;

    return (
      <ConfigRow
        className={`channel-row-container${isMultipleMapping ? ` auto-height` : ``}`}
        cellX1Content={
          <div onClick={this.switchPlaying}>
            {isPlaying ? <Icon icon={AudioListening} /> : <Icon icon={AudioPlay} />}
          </div>
        }
        cellX2Content={<span className="channel-row-container_channel">{channel.track}</span>}
        cellX4Content={this.renderAssignmentControl()}
      />
    );
  }
}
