import * as React from 'react';
import {Playback} from '../Playback';
import {ITemplateColors} from '../../../../@types/templateColors';
import {Button} from '../../Button';

interface IPlaybackRestoreProps {
  templateColors: ITemplateColors;
  onRestore?: () => void;
  onCancel?: () => void;
}

const mainTitle = `Video Not Ready for Playback`;

const descriptionContent = (props: IPlaybackRestoreProps) => (
  <div className="restoring-overlay_description">
    <div className="restoring-overlay_description_text">Your asset needs to be restored in order to playback.</div>
    <div className="restoring-overlay_description_text">Would you like to restore now?</div>
    <div className="restoring-overlay_description_buttons-container">
      <Button content="RESTORE" onClick={() => (props.onRestore ? props.onRestore() : null)} />
      <Button secondary content="CANCEL" onClick={() => (props.onCancel ? props.onCancel() : null)} />
    </div>
  </div>
);

export const PlaybackRestore = (props: IPlaybackRestoreProps) => (
  <Playback
    className="restoring-overlay"
    mainTitle={mainTitle}
    descriptionContent={descriptionContent(props)}
    templateColors={props.templateColors}
  />
);
