import {getTimeInSeconds} from './helpers';
import {IComment} from '../../Tabs/interfaces/ICommentsTab';
import {IFrameRate} from 'tt-components';
import {ITooltipComment} from '../interfaces/ITimelineTooltipData';
import {isNaNHandler} from '../../../utils/utils';

export interface ISegmentModel {
  timeIn: number; // in seconds
  timeOut: number; // in seconds
  title: string;
  content: string;
  comment: ITooltipComment;
}

export enum DataType {
  Events = 'Events',
  Comments = 'Comments'
}

export class SegmentModel implements ISegmentModel {
  public timeIn;
  public timeOut;
  public title = null;
  public content;
  public comment = null;

  constructor(data: IComment | any, frameRate: IFrameRate, dataType: DataType = DataType.Events) {
    switch (dataType) {
      case DataType.Comments:
        this.timeIn = data.inTime ? getTimeInSeconds(data.inTime, frameRate) : 0;
        this.timeOut = data.outTime ? getTimeInSeconds(data.outTime, frameRate) : 0;
        this.content = data.comment;
        this.comment = {
          displayName: data.createdBy ? data.createdBy.displayName : 'Undefined',
          createdAt: data.createdAt,
          avatar: data.createdBy ? data.createdBy.avatar : ''
        };
        break;
      case DataType.Events:
      default:
        this.timeIn = data.timeIn ? isNaNHandler(getTimeInSeconds(data.timeIn, frameRate)) : 0;
        this.timeOut = data.timeOut ? isNaNHandler(getTimeInSeconds(data.timeOut, frameRate)) : 0;
        this.title = data.type;
        this.content = data.notes;
    }
  }
}
