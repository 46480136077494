import * as React from 'react';
import {Dropdown, IDropdownOption} from '../../../../../../components/Dropdown';
import {IMarkupsError} from '../../../../../../../@types/markupsError';
import {deepCopy} from '../../../../utils/helpers';
import {supportedEvents} from '../../../../../../constants/supportedEvents';

export interface IEventGroupsProps {
  groups: Array<string>;
  selectedGroup: string;
  errors: Array<IMarkupsError>;
  onEventGroupSelected: (name: string) => void;
  addNewGroup: (type: string) => void;
  closestBody: HTMLElement;
}

export interface IEventGroupsState {
  groups: Array<string>;
  selectedGroup: string;
}

export class EventGroups extends React.PureComponent<IEventGroupsProps, IEventGroupsState> {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.groups !== prevState.groups || nextProps.selectedGroup !== prevState.selectedGroup) {
      return {
        groups: nextProps.groups,
        selectedGroup: nextProps.selectedGroup
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      selectedGroup: ''
    };
  }

  onGroupChange = changedGroup => {
    if (changedGroup === 'Other') {
      this.props.addNewGroup(changedGroup);
    } else {
      this.props.onEventGroupSelected(changedGroup);
    }
  };

  createOptions = (): Array<IDropdownOption> => {
    const groups = deepCopy([...this.state.groups]);
    const options = groups.map((group: string) => {
      const error = (this.props.errors || []).find((error: IMarkupsError) => error.group === group);
      return {
        label: `${group}${error && (error.eventsErrors || []).length ? ` ( ${error.eventsErrors.length} )` : ``}`,
        value: group,
        style: error ? {color: `rgba(255, 178, 9, 0.7)`} : null
      };
    });
    return options;
  };

  sortEventGroups = (a: IDropdownOption, b: IDropdownOption) => {
    const refA = supportedEvents.find(event => event.label === a.value) || ({} as any);
    const refB = supportedEvents.find(event => event.label === b.value) || ({} as any);
    return (refA.order || 0) - (refB.order || 0);
  };

  render() {
    const {selectedGroup} = this.state;
    return (
      <Dropdown
        width={200}
        options={this.createOptions()}
        optionsSortCallback={this.sortEventGroups}
        selected={selectedGroup}
        onSelected={this.onGroupChange}
        portalNode={this.props.closestBody}
      />
    );
  }
}
