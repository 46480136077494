import * as React from 'react';
import {Collapsible} from '../../../Collapsible';
import {VideoDetails} from '../VideoDetails';
import {IEnums} from '../../../../../../state/IEnums';
import {IAssetDetails, IAssetVideo, IErrorLog} from '../../../../../../../@types/assetDetails';

interface IVideoItemProps {
  updatedAssetDetails: Partial<IAssetDetails>;
  assetDetails: IAssetDetails;
  video: IAssetVideo;
  videoMetadataErrors: any;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

interface IVideoItemState {
  openVideoDetails: boolean;
}

export class VideoItem extends React.Component<IVideoItemProps, IVideoItemState> {
  constructor(props) {
    super(props);

    this.state = {
      openVideoDetails: false
    };
  }

  render() {
    return (
      <div className="details-container">
        <Collapsible
          open={this.state.openVideoDetails}
          title="Video"
          content={
            <VideoDetails
              updatedAssetDetails={this.props.updatedAssetDetails}
              updatePartialAssetDetails={this.props.updatePartialAssetDetails}
              assetDetails={this.props.assetDetails}
              video={this.props.video}
              videoMetadataErrors={this.props.videoMetadataErrors}
              enums={this.props.enums}
              tabsDataInEditMode={this.props.tabsDataInEditMode}
              closestBody={this.props.closestBody}
              errorLogs={this.props.errorLogs}
            />
          }
          onToggle={openVideoDetails => this.setState({openVideoDetails})}
        />
      </div>
    );
  }
}
