import * as React from 'react';

export type ButtonType = 'blue' | 'green';

interface ICustomButtonProps {
  className?: string;
  label?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  type?: ButtonType;
}

function customButton(props: ICustomButtonProps) {
  const buttonType: ButtonType = props.type || 'blue';
  const customClass = props.className ? ` ${props.className}` : ``;
  const disabledClass = props.disabled ? ` disabled` : ``;

  return (
    <button
      className={`custom-button ${buttonType}Button${customClass}${disabledClass}`}
      disabled={props.disabled}
      onClick={props.onClick || null}
      style={props.style || {}}
    >
      {props.label && <span className="custom-button_label">{props.label}</span>}
      {props.icon && <span className={`custom-button_icon${props.label ? ` left-margin` : ``}`}>{props.icon}</span>}
    </button>
  );
}

export default React.memo(customButton);
