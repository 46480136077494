import * as React from 'react';
import {CustomRadioButton} from '../../CustomRadio';
import {ITemplateColors} from '../../../../@types/templateColors';
import {IDisplayMediaTimeFormat} from '../../../../@types/displayMediaTimeFormat';

export interface IMediaTimeFormatSettingsProps {
  templateColors: ITemplateColors;
  changeMediaTimeFormat: (value: IDisplayMediaTimeFormat) => void;
  displayMediaTimeFormat: IDisplayMediaTimeFormat;
  hideTitle?: boolean;
}

export class MediaTimeFormatSettings extends React.PureComponent<IMediaTimeFormatSettingsProps> {
  changeMediaTimeFormatById(radioButtonId: string) {
    const {changeMediaTimeFormat} = this.props;

    switch (radioButtonId) {
      case 'timecode-id':
        changeMediaTimeFormat('FrameBasedTimecode');
        break;
      case 'time-id':
        changeMediaTimeFormat('MillisecondBasedTimecode');
        break;
      case 'standard-id':
        changeMediaTimeFormat('Standard');
        break;
      case 'video-frame-id':
        changeMediaTimeFormat('FrameOnly');
        break;
    }
  }

  getRadioButtonIdByMediaTimeFormat(mediaTimeFormat: IDisplayMediaTimeFormat) {
    let id: string = '';
    switch (mediaTimeFormat) {
      case 'FrameBasedTimecode':
        id = 'timecode-id';
        break;
      case 'MillisecondBasedTimecode':
        id = 'time-id';
        break;
      case 'Standard':
        id = 'standard-id';
        break;
      case 'FrameOnly':
        id = 'video-frame-id';
        break;
    }
    return id;
  }

  render() {
    const {templateColors, displayMediaTimeFormat} = this.props;
    const {main} = templateColors;
    const mediaTimeFormatSettings = [
      {
        id: 'timecode-id',
        label: 'Timecode'
      },
      {
        id: 'time-id',
        label: 'Time'
      },
      {
        id: 'standard-id',
        label: 'Standard'
      },
      {
        id: 'video-frame-id',
        label: 'Video Frames'
      }
    ];

    return (
      <div className="op_media-time-format-settings">
        {!this.props.hideTitle && (
          <div className="op_media-time-format-settings_title" style={{color: main}}>
            Media Time Format
          </div>
        )}
        {mediaTimeFormatSettings.map((settingsOption, index) => (
          <CustomRadioButton
            key={index}
            index={index}
            id={settingsOption.id}
            value={settingsOption.id}
            text={settingsOption.label || 'Media Time Format Option'}
            selectedValue={this.getRadioButtonIdByMediaTimeFormat(displayMediaTimeFormat)}
            onChange={e => this.changeMediaTimeFormatById(e.target.value)}
            isUseDefaultColorForNotSelected={true}
            templateColors={this.props.templateColors}
          />
        ))}
      </div>
    );
  }
}
