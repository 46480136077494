import * as React from 'react';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ITemplateColors} from '../../../../@types/templateColors';
import {IAudioChannelConfiguration, IAudioChannel} from '../../../../@types/audioChannelConfiguration';
import {areEqualObjectsWithPrimitiveKeys} from '../../../utils/utils';
import {IVideoAudioConfiguration} from '../../../state/IVideoState';
import {IEnum} from '../../../../@types/enum';
import {ExpandedAudioConf} from '../../../utils/storage/ExpandedAudioConf';
import {deepCopy} from '../../../modules/Tabs/utils/helpers';
import {ConfigurationRadio} from '../../ConfigurationRadio';
import {CollapseConfigs} from '../../CollapseConfigs';

interface IAudioAssetsListProps {
  title?: string;
  audioLanguageEnums: Array<IEnum>;
  audioChannelMapEnums: Array<IEnum>;
  audioChannelConfigTypeEnums: Array<IEnum>;
  embeddedAudioChannels: Array<IAudioChannelConfiguration>;
  externalAudioChannels: Array<IAudioChannelConfiguration>;
  mergeLists?: boolean;
  componentIdentifier?: string;
  templateColors: ITemplateColors;
  audioConfiguration: IVideoAudioConfiguration;
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) => void;
  loadingPlaylist: boolean;
  loadingPlaylistError: string;
  loadingAudioConfig: boolean;
  checkPlaybackProxy: () => void;
  proxyConfiguration?: IAudioChannelConfiguration;
  videoConfiguration?: IAudioChannelConfiguration;
  userEmail: string;
  userIp: string;
  onOpenedConfiguration?: (conf: string) => void;
  useVisibilityCheckbox?: boolean;
  curationModeEnabled?: boolean;
  showConfigsAssetHrId?: boolean;
}

interface IAudioAssetsListState {
  loading: boolean;
  latestChannelConfig: string;
  // store ID of opened configuration
  openedConfiguration: string;
}

export class AudioAssetsList extends React.Component<IAudioAssetsListProps, IAudioAssetsListState> {
  static defaultProps = {
    componentIdentifier: 'video'
  };

  onChannelCheck$: Subject<string>;
  $onChannelCheck: Subscription;

  constructor(props) {
    super(props);

    this.state = {
      loading: props.loadingAudioConfig,
      latestChannelConfig: '',
      openedConfiguration: ''
    };
  }

  componentDidMount() {
    this.onChannelCheck$ = new Subject();
    this.$onChannelCheck = this.onChannelCheck$.pipe(debounceTime(1250)).subscribe(this.makeConfigSwitch);
    this.setOpenedConfig();
  }

  componentDidUpdate(prevProps: IAudioAssetsListProps) {
    if (prevProps.loadingAudioConfig !== this.props.loadingAudioConfig) {
      this.setState({loading: this.props.loadingAudioConfig});
    }

    if (prevProps.audioConfiguration.selectedConfig !== this.props.audioConfiguration.selectedConfig) {
      this.makeConfigSwitch(this.props.audioConfiguration.selectedConfig);
    }

    if (
      !areEqualObjectsWithPrimitiveKeys(
        prevProps.audioConfiguration.checkedChannels,
        this.props.audioConfiguration.checkedChannels
      )
    ) {
      this.onChannelCheck$.next(this.state.latestChannelConfig);
    }
  }

  componentWillUnmount() {
    if (this.$onChannelCheck) {
      this.$onChannelCheck.unsubscribe();
    }
  }

  setOpenedConfig = () => {
    const expandedAudioConfStorage = new ExpandedAudioConf();
    const openedConfiguration = expandedAudioConfStorage.get();
    if (openedConfiguration) {
      this.setState({openedConfiguration});
    }
  };

  makeConfigSwitch = (confId: string) => {
    // NOTE: AudioAssetsList component is used in different implementations within the player
    // so we need to identify the right component that it's triggering the action so we don't have
    // duplicate API calls for the same user interaction
    if (this.props.audioConfiguration.componentIdentifier !== this.props.componentIdentifier) {
      return;
    }

    if (this.state.loading) {
      return;
    }

    if (confId !== this.props.audioConfiguration.selectedConfig) {
      return;
    }

    const {selectedConf, channels} = this.getAudioConfiguration(confId);
    const isStaged = selectedConf
      ? selectedConf.proxyUrl || selectedConf.isVideoAudioConfiguration
        ? selectedConf.assetStatus.toLowerCase() === 'staged'
        : selectedConf.assetStatus.toLowerCase() === 'staged' && !!channels.length
      : false;
    this.setState({loading: isStaged}, () => this.onConfigSwitch(this.props.audioConfiguration.selectedConfig));
  };

  getAudioConfiguration = (selectedConfig: string) => {
    const {embeddedAudioChannels, externalAudioChannels, proxyConfiguration, videoConfiguration} = this.props;
    const selectedConf = [
      ...embeddedAudioChannels,
      ...externalAudioChannels,
      proxyConfiguration || null,
      videoConfiguration || null
    ]
      .filter(conf => conf)
      .find((conf: IAudioChannelConfiguration) => conf.id === selectedConfig) as IAudioChannelConfiguration;

    const channels = (selectedConf ? selectedConf.trackDetail : []).filter((audio: IAudioChannel) =>
      (this.props.audioConfiguration.checkedChannels[selectedConfig] || []).includes(audio.id)
    );

    return {selectedConf, channels};
  };

  onConfigSwitch = (selectedConfig: string) => {
    const {selectedConf} = this.getAudioConfiguration(selectedConfig);

    if (!selectedConf) {
      console.log(`Configuration with id ${selectedConfig} not found`);
      return;
    }
    this.props.checkPlaybackProxy();
  };

  onChannelChecked = (audioChannelId: string, confId: string) => {
    const isTrackDefined = this.props.audioConfiguration.checkedChannels[confId]
      ? this.props.audioConfiguration.checkedChannels[confId].includes(audioChannelId)
      : false;

    const updatedState = {
      checkedChannels: isTrackDefined
        ? {
            ...this.props.audioConfiguration.checkedChannels,
            [confId]: this.props.audioConfiguration.checkedChannels[confId].filter(
              (value: string) => value !== audioChannelId
            )
          }
        : {
            ...this.props.audioConfiguration.checkedChannels,
            [confId]: [...(this.props.audioConfiguration.checkedChannels[confId] || []), audioChannelId]
          }
    };

    this.setState(
      {
        latestChannelConfig: confId
      },
      () => this.props.updateAudioConfig({...updatedState, componentIdentifier: this.props.componentIdentifier})
    );
  };

  expandAudioConf = (confId: string) => {
    this.setState({openedConfiguration: this.state.openedConfiguration === confId ? '' : confId}, () => {
      const expandedAudioConfStorage = new ExpandedAudioConf();
      const expandedAudioConfFromStore = expandedAudioConfStorage.get();
      expandedAudioConfStorage.set(expandedAudioConfFromStore === confId ? '' : confId);
    });
    if (this.props.onOpenedConfiguration) {
      this.props.onOpenedConfiguration(confId);
    }
  };

  changeSelectedConfig = (configId: string) => {
    const {selectedConf} = this.getAudioConfiguration(configId);

    if (!selectedConf) {
      console.log(`Configuration with id ${configId} not found`);
      return;
    }
    this.props.updateAudioConfig({...{selectedConfig: configId, componentIdentifier: this.props.componentIdentifier}});
  };

  renderAudioConfigurations = (data: Array<IAudioChannelConfiguration>, doRegroup: boolean = false) => {
    const copy = deepCopy([...data]);
    copy.sort((aConfig: IAudioChannelConfiguration, bConfig: IAudioChannelConfiguration) => {
      return (aConfig.channelConfig || '').localeCompare(bConfig.channelConfig || '');
    });
    let count = 0;
    const controls = copy.map((config: IAudioChannelConfiguration, index: number) => {
      count = index;
      return {
        hrId: config.hrId,
        element: (
          <ConfigurationRadio
            key={index}
            config={config}
            audioLanguageEnums={this.props.audioLanguageEnums}
            audioChannelConfigTypeEnums={this.props.audioChannelConfigTypeEnums}
            audioChannelMapEnums={this.props.audioChannelMapEnums}
            componentIdentifier={this.props.componentIdentifier}
            selectedConfig={this.props.audioConfiguration.selectedConfig}
            checkedChannels={this.props.audioConfiguration.checkedChannels}
            templateColors={this.props.templateColors}
            curationModeEnabled={this.props.curationModeEnabled}
            disabled={this.state.loading}
            collapsedConfiguration={this.state.openedConfiguration}
            onConfigChange={this.changeSelectedConfig}
            onChannelChecked={this.onChannelChecked}
            collapseConfig={this.expandAudioConf}
            useVisibilityCheckbox={this.props.useVisibilityCheckbox}
            showConfigsAssetHrId={this.props.showConfigsAssetHrId && !doRegroup}
          />
        )
      };
    });
    if (!doRegroup) {
      return controls.reduce((acc: Array<JSX.Element>, element) => [...acc, element.element], []);
    }
    const hrIds = copy
      .reduce((ids: Array<string>, config: IAudioChannelConfiguration) => [...ids, config.hrId], [])
      .filter(id => id)
      .filter((id: string, index: number, list: Array<string>) => list.indexOf(id) === index);
    const noValidHrIds = controls
      .filter((element: {hrId: string; element: JSX.Element}) => hrIds.indexOf(element.hrId) === -1)
      .map(element => element.hrId)
      .filter((hrId: string, index: number, list: Array<string>) => list.indexOf(hrId) === index);
    const elements = [];
    noValidHrIds.forEach((hrId: string) => {
      const hrIdAssets = controls
        .filter((element: {hrId: string; element: JSX.Element}) => element.hrId === hrId)
        .map(element => element.element);
      elements.push(...hrIdAssets);
    });
    hrIds.forEach((hrId: string, index: number) => {
      const assetControls = controls.filter(element => element.hrId === hrId).map(element => element.element);
      const collapse = (
        <CollapseConfigs label={hrId} collapsed key={count}>
          {assetControls}
        </CollapseConfigs>
      );
      count++;
      elements.push(collapse);
    });
    return elements;
  };

  renderAudioConfigurationContent = (data: Array<IAudioChannelConfiguration>, doRegroup: boolean = false) => {
    const showGenericMessage = !data.length || this.props.loadingPlaylist || this.props.loadingPlaylistError;
    return (
      <div className={`assets-list_content_checkboxes${showGenericMessage ? ` empty` : ``}`}>
        {data.length && !this.props.loadingPlaylist && !this.props.loadingPlaylistError ? (
          this.renderAudioConfigurations(data, doRegroup)
        ) : (
          <span>
            {this.props.loadingPlaylist
              ? `Loading playlist...`
              : this.props.loadingPlaylistError
              ? this.props.loadingPlaylistError
              : `No configurations are defined`}
          </span>
        )}
      </div>
    );
  };

  renderConfigurationsLists = () => {
    if (!this.props.mergeLists) {
      return (
        <>
          <div className="assets-list_content">
            <div className="assets-list_content_title">Embedded</div>
            {this.renderAudioConfigurationContent(this.props.embeddedAudioChannels)}
          </div>
          <div className="assets-list_content">
            <div className="assets-list_content_title">External</div>
            {this.renderAudioConfigurationContent(this.props.externalAudioChannels, !this.props.mergeLists)}
          </div>
        </>
      );
    } else {
      return (
        <div className="assets-list_content">
          {this.renderAudioConfigurationContent([
            ...this.props.embeddedAudioChannels,
            ...this.props.externalAudioChannels
          ])}
        </div>
      );
    }
  };

  render() {
    const mainColor = this.props.templateColors.main;
    const proxyConfiguration = this.props.proxyConfiguration;
    const videoConfiguration = this.props.videoConfiguration;
    const showVideoConfiguration =
      !this.props.embeddedAudioChannels.length && !this.props.externalAudioChannels.length && !!videoConfiguration;

    return (
      <div className="assets-list audio-assets-container">
        {this.props.title && (
          <div className="assets-list_title" style={{color: mainColor}}>
            {this.props.title}
          </div>
        )}
        {proxyConfiguration && (
          <div className="assets-list_content">
            <ConfigurationRadio
              title="Proxy Configuration"
              config={proxyConfiguration}
              componentIdentifier={this.props.componentIdentifier}
              selectedConfig={this.props.audioConfiguration.selectedConfig}
              templateColors={this.props.templateColors}
              curationModeEnabled={this.props.curationModeEnabled}
              disabled={this.state.loading}
              onConfigChange={this.changeSelectedConfig}
            />
          </div>
        )}
        {showVideoConfiguration && (
          <div className="assets-list_content">
            <ConfigurationRadio
              title="Video Configuration"
              config={videoConfiguration}
              componentIdentifier={this.props.componentIdentifier}
              selectedConfig={this.props.audioConfiguration.selectedConfig}
              templateColors={this.props.templateColors}
              curationModeEnabled={this.props.curationModeEnabled}
              disabled={this.state.loading}
              onConfigChange={this.changeSelectedConfig}
              showConfigsAssetHrId={this.props.showConfigsAssetHrId}
            />
          </div>
        )}
        {this.renderConfigurationsLists()}
      </div>
    );
  }
}
