import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {
  ITimelineDispatchProps,
  ITimelineOwnProps,
  ITimelineProps,
  ITimelineTooltipData,
  ITooltipSegmentData
} from '../../interfaces';
import {TimelineScale} from '../TimelineScale';
import {ThumbnailsLayer} from '../ThumbnailsLayer';
import {CommentsLayer} from '../CommentsLayer';
import {Playhead} from '../Playhead';
import {TimelineTooltip} from '../TimelineTooltip';
import {SeekType} from '../../../../components/OnePlayerControlBar/onePlayerControlBarProps';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {TimelineInstance} from '../TimelineInstance';
import {Button} from '../../../../components/Button';
import {getPartialObjectByProps} from '../../utils/helpers';
import {clearProps} from '../../../Tabs/utils/helpers';

type ITimelineComponentProps = ITimelineProps & ITimelineOwnProps & ITimelineDispatchProps;

interface ITimelineState {
  // Defines mapping between SECOND unit and the pixels
  // that are necessary to set the position in the DOM
  // of related element for that unit
  secondUnits: number;
  // Duration of playing video
  duration: number;
  scrollLeft: number;
  scale: number;
  disableDecreaseScale: boolean;
  currentTime: number;
  playHeadPosition: number;
  extraPlayHeadPosition: number;
  updatedTime: number;
  movingPosition: number;
  tooltipData: ITimelineTooltipData;
  // Reference value to be used for tooltip position calculation
  timelineRefWidth: number;
  overTooltip: boolean;
  width: number;
  conformanceOverlayOpened: boolean;
}

const leftSectionWidth = 160;
const tooltipTopOffset = 5;

export class Timeline extends React.Component<ITimelineComponentProps, ITimelineState> {
  static getDerivedStateFromProps(nextProps: ITimelineComponentProps, prevState: ITimelineState) {
    if ((nextProps.video.metadata && nextProps.video.metadata.duration) !== prevState.duration) {
      return {duration: nextProps.video.metadata.duration};
    } else {
      return null;
    }
  }

  timelineRefElement: HTMLDivElement = null;
  contentRefElement: HTMLDivElement;
  subscriptionPlayerClock: Subscription;
  onSeek$: Subject<number>;
  $onSeek: Subscription;
  tooltipCheckTimeout: number = null;

  constructor(props: ITimelineComponentProps) {
    super(props);

    this.state = {
      secondUnits: 0,
      duration: (props.video.metadata && props.video.metadata.duration) || null,
      scrollLeft: 0,
      scale: 1,
      disableDecreaseScale: true,
      currentTime: 0,
      playHeadPosition: this.getPlayheadInitPosition(),
      extraPlayHeadPosition: 0,
      updatedTime: 0,
      movingPosition: null,
      tooltipData: null,
      timelineRefWidth: null,
      overTooltip: false,
      width: 0,
      conformanceOverlayOpened: false
    };
  }

  componentDidMount() {
    this.subscriptionPlayerClock = this.props.playerClock.subscribe(this.updateCurrentTime);
    this.onSeek$ = new Subject();
    this.$onSeek = this.onSeek$.pipe(debounceTime(700)).subscribe(this.updateTimeOnSeek);
  }

  componentDidUpdate(prevProps: ITimelineComponentProps, prevState: ITimelineState) {
    // Condition for duration update
    const isDurationUpdated = (prevProps.video.metadata && prevProps.video.metadata.duration) !== this.state.duration;
    // Condition for case when the Timeline module is displayed
    const isTimelineDisplayed =
      prevProps.timeline.showTimeline !== this.props.timeline.showTimeline && this.props.timeline.showTimeline;
    // Condition for case when the width CSS property is defined from parent component
    const prevStylesWidth = (getPartialObjectByProps(prevProps.rootElementStyles, ['width']) as any).width;
    const currentStylesWidth = (getPartialObjectByProps(this.props.rootElementStyles, ['width']) as any).width;
    const isTimelineWidthUpdated = prevStylesWidth !== currentStylesWidth && currentStylesWidth !== undefined;

    if (isDurationUpdated || isTimelineDisplayed || isTimelineWidthUpdated) {
      this.defineSecondUnits();
    }

    if (prevProps.playerClock !== this.props.playerClock) {
      this.subscriptionPlayerClock.unsubscribe();
      this.subscriptionPlayerClock = this.props.playerClock.subscribe(this.updateCurrentTime);
    }
  }

  componentWillUnmount() {
    if (this.subscriptionPlayerClock) {
      this.subscriptionPlayerClock.unsubscribe();
    }
    if (this.$onSeek) {
      this.$onSeek.unsubscribe();
    }
    if (this.tooltipCheckTimeout) {
      clearTimeout(this.tooltipCheckTimeout);
    }
  }

  getPlayheadInitPosition = () => {
    return leftSectionWidth - ((playHeadStyle.paddingLeft as number) || 0);
  };

  updateCurrentTime = (currentTime: number, pauseUpdate: boolean = false) => {
    const {scrollLeft, secondUnits, scale, duration} = this.state;
    let {extraPlayHeadPosition} = this.state;
    const {width} = this.contentRefElement.getBoundingClientRect();
    const scaledUnits = secondUnits * scale;
    const currentTimePosition = currentTime * scaledUnits;
    const contentWidth = scaledUnits * (duration !== null ? duration : 0);

    const playHeadInitPosition = this.getPlayheadInitPosition();
    const playHeadRelativePosition = currentTimePosition - scrollLeft + extraPlayHeadPosition;
    const playHeadPosition = playHeadInitPosition + playHeadRelativePosition;

    const calcScrollLeft = Math.floor(currentTimePosition / width) * width;
    // Do not update scrollLeft value if we are overcoming the content width limit
    const updatedScrollLeft = calcScrollLeft >= contentWidth ? scrollLeft : calcScrollLeft;
    if (scrollLeft !== updatedScrollLeft) {
      const scrollDiff = contentWidth - (scrollLeft + width);
      // Workaround to manage cases when the left Timeline scroll content is less than
      // the width of the container so we need to provide an extra position offset for the head
      if (scrollDiff < width && updatedScrollLeft > scrollLeft) {
        extraPlayHeadPosition = width - scrollDiff;
      } else {
        extraPlayHeadPosition = 0;
      }

      this.setState({
        currentTime,
        width,
        scrollLeft: updatedScrollLeft,
        extraPlayHeadPosition,
        playHeadPosition: pauseUpdate
          ? currentTimePosition - updatedScrollLeft + extraPlayHeadPosition + playHeadInitPosition
          : this.state.playHeadPosition
      });
    } else {
      this.setState({currentTime, playHeadPosition});
    }
  };

  updateCurrentTimeOnPause = () => {
    if (!this.props.video.playing) {
      this.updateCurrentTime(this.state.currentTime, true);
    }
  };

  allLayersHidden() {
    const {showImageThumbnailsLayer, showCommentsLayer} = this.props.timeline.layersVisisbility;
    const thumbnailsCommentsVisibility = [showImageThumbnailsLayer, showCommentsLayer].every(
      (option: boolean) => !option
    );
    const emptyAssets = !this.props.video.playlist.assets.filter((asset: PlaylistAsset) => !asset.isHidden).length;

    return thumbnailsCommentsVisibility && emptyAssets;
  }

  defineSecondUnits = () => {
    const duration = this.state.duration;
    let secondUnits: number;

    if (!duration || !this.contentRefElement) {
      return;
    }
    const {width} = this.contentRefElement.getBoundingClientRect();
    if (this.props.secondUnits && this.props.secondUnits * duration >= width) {
      secondUnits = this.props.secondUnits;
    } else {
      // Define secondUnits based on the container width
      secondUnits = width / duration;
    }

    this.setState({secondUnits, width});
  };

  getContentRef = (node: HTMLDivElement) => {
    this.contentRefElement = node;
  };

  onScaleIncrease = () => {
    const scale = this.state.scale + 1;
    this.setState({scale, disableDecreaseScale: false}, this.updateCurrentTimeOnPause);
  };

  onScaleDecrease = () => {
    const scale = this.state.scale === 1 ? this.state.scale : this.state.scale - 1;
    this.setState({scale, disableDecreaseScale: scale === 1}, this.updateCurrentTimeOnPause);
  };

  onPlayheadMoved = (position: number) => {
    const {scrollLeft, extraPlayHeadPosition, secondUnits, scale} = this.state;
    const {left, width} = this.contentRefElement.getBoundingClientRect();
    const scaledUnits = secondUnits * scale;
    const diff = position - left;
    const playHeadRelativePosition = diff < 0 ? 0 : diff > width ? width : diff;
    const playHeadPosition = playHeadRelativePosition + this.getPlayheadInitPosition();

    const updatedTime = (playHeadRelativePosition - extraPlayHeadPosition + scrollLeft) / scaledUnits;

    this.setState({movingPosition: playHeadPosition, width, updatedTime}, () => {
      this.onSeek$.next();
    });
  };

  updateTimeOnSeek = () => {
    this.props.onSeek(this.state.updatedTime, SeekType.toTime);
    this.setState({movingPosition: null});
  };

  onElementIn = (data: ITooltipSegmentData) => {
    const {left, bottom, width} = this.timelineRefElement.getBoundingClientRect();
    const contentWidth = this.contentRefElement.getBoundingClientRect().width;

    clearTimeout(this.tooltipCheckTimeout);

    let segmentWidthOffset;
    let mostLeftPosition = data.leftPosition - left;
    if (mostLeftPosition >= 0) {
      segmentWidthOffset =
        mostLeftPosition + data.width > width ? Math.abs(width - mostLeftPosition) / 2 : data.width / 2;
    } else {
      const hiddenWidth = Math.abs(mostLeftPosition);
      const isStillOverlap = data.width - hiddenWidth > contentWidth;
      segmentWidthOffset = isStillOverlap ? contentWidth / 2 : (data.width - hiddenWidth) / 2;
      mostLeftPosition = 150;
    }

    const leftPosition = mostLeftPosition + segmentWidthOffset;

    this.hideTooltip(function() {
      const tooltipData = {
        leftPosition,
        bottomPosition: bottom - data.bottomPosition + data.height + tooltipTopOffset,
        start: data.start,
        end: data.end,
        content: data.content,
        title: data.title,
        comment: data.comment
      };
      this.setState({tooltipData, timelineRefWidth: width});
    });
  };

  onElementOut = () => {
    this.tooltipCheckTimeout = window.setTimeout(() => {
      if (!this.state.overTooltip) {
        this.hideTooltip();
      }
    }, 500);
  };

  hideTooltip = (callback?: () => void) => {
    this.setState({tooltipData: null, timelineRefWidth: null, overTooltip: false}, callback);
  };

  onTooltipOut = () => {
    this.hideTooltip();
  };

  onTooltipIn = () => {
    this.setState({overTooltip: true});
  };

  onLoop = () => {
    const {start, end} = this.state.tooltipData;
    this.props.switchLoop({loop: 'segmentVideo', timeIn: start, timeOut: end});
  };

  onSegmentClicked = (time: number) => {
    this.props.onSeek(time, SeekType.toTime);
  };

  getSeconUnits = () => {
    if (this.state.scale === 1 && this.state.duration) {
      return this.state.width / this.state.duration;
    }
    return this.state.secondUnits * this.state.scale;
  };

  getContentWidth = () => {
    const secondUnits = this.getSeconUnits();
    return secondUnits * (this.state.duration !== null ? this.state.duration : 0);
  };

  getConformanceButtonContainer = () => {
    return (
      <div className="timeline-container_conformance-button">
        <Button
          small
          content="Assign Conformance Group"
          className="timeline-container_conformance-button_button"
          onClick={this.onConformanceOverlayOpen}
        />
      </div>
    );
  };

  onConformanceOverlayOpen = () => {
    this.props.showConformanceOverlay(true);
  };

  disableTimeline = () => {
    return this.props.loadingPlayer || this.props.loadingAsset;
  };

  showConformanceModal = () => {
    if (!this.props.curationModeEnabled) {
      return false;
    }
    const availableAssets = this.props.video.playlist.assets.filter((asset: PlaylistAsset) => !asset.isHidden);
    if (!availableAssets.length) {
      return false;
    }
    return availableAssets.every((asset: PlaylistAsset, index: number, list: Array<PlaylistAsset>) => {
      // Use the first asset as a reference for the info that the other assets should be compared
      // NOTE: Field conformanceGroupId should not be included in compare logic as it's the value
      // that we need to update if the other titles info match
      const refTitlesInfo = clearProps({...list[0].getTitleInfoForAsset()}, ['conformanceGroupId']);
      const currentTitleInfo = clearProps({...asset.getTitleInfoForAsset()}, ['conformanceGroupId']);
      return isEqual(refTitlesInfo, currentTitleInfo);
    });
  };

  mapTimelineInstances = (asset: PlaylistAsset, index: number) => (
    <TimelineInstance
      key={index}
      asset={asset}
      programTimingsInput={this.props.configuration.programTimingsInput}
      showMarkupsLayer={this.props.timeline.layersVisisbility.showMarkupsLayer}
      showProgramTimingsLayer={this.props.timeline.layersVisisbility.showProgramTimingsLayer}
      showSubtitlesLayer={this.props.timeline.layersVisisbility.showSubtitlesLayer}
      showAudioLayer={this.props.timeline.layersVisisbility.showAudioLayer}
      curationModeEnabled={this.props.curationModeEnabled}
      secondUnits={this.getSeconUnits()}
      contentWidth={this.getContentWidth()}
      scrollLeft={this.state.scrollLeft}
      frameRate={this.props.video.playlist.frameRate}
      onElementIn={this.onElementIn}
      onElementOut={this.onElementOut}
      onSegmentClicked={this.disableTimeline() ? time => {} : this.onSegmentClicked}
      selectedSub={this.props.video.playlist.selectedSub}
      templateColors={this.props.configuration.templateColors}
      onChangeVisibleSubtitle={this.props.onChangeVisibleSubtitle}
      audioLanguageEnums={this.props.video.enums.language}
      audioChannelMapEnums={this.props.video.enums.channelMap}
      audioChannelConfigTypeEnums={this.props.video.enums.channelConfigType}
      languageDialectEnums={this.props.video.enums.languageDialect}
      audioConfiguration={this.props.video.audioConfiguration}
      updateAudioConfig={this.props.updateAudioConfig}
      loadingPlaylist={this.props.loadingPlayer}
      loadingPlaylistError={this.props.loadingPlayerError}
      loadingAudioConfig={this.props.loadingAudioConfig}
      checkPlaybackProxy={this.props.checkPlaybackProxy}
      userEmail={this.props.configuration.userEmail}
      userIp={this.props.configuration.userIp}
      onAssetHidden={this.props.updateAssetHiddenState}
      duration={this.state.duration}
    />
  );

  render() {
    const {timeline, video, configuration, onUpdateLayerVisibility, tabs} = this.props;
    const isTimelineEmpty = this.allLayersHidden();
    const secondUnits = this.getSeconUnits();
    const contentWidth = this.getContentWidth();
    const disableTimeline = this.disableTimeline();
    const assets = this.props.video.playlist.assets;
    // NOTE: In case ONE UI is passing multiple atlas assets, then we will need to show possibility for Modal display
    const showConformanceModal = this.showConformanceModal();
    const playheadComputedStyle = {...playHeadStyle, height: `calc(100% - ${showConformanceModal ? `113` : `55`}px)`};

    return this.props.timeline.showTimeline ? (
      <div
        className={`timeline-container${showConformanceModal ? ` modal-button` : ``}`}
        ref={node => (this.timelineRefElement = node)}
        style={this.props.rootElementStyles || {}}
      >
        {this.state.tooltipData && (
          <TimelineTooltip
            data={this.state.tooltipData}
            timelineRefWidth={this.state.timelineRefWidth}
            frameRate={video.playlist.frameRate}
            onTooltipIn={this.onTooltipIn}
            onTooltipOut={this.onTooltipOut}
            onLoop={disableTimeline ? () => {} : this.onLoop}
            oneUiHost={configuration.oneUiHost}
          />
        )}
        <Playhead
          style={playheadComputedStyle}
          leftOffset={this.state.movingPosition || this.state.playHeadPosition}
          onPositionChange={disableTimeline ? position => {} : this.onPlayheadMoved}
          timelineRefElement={this.timelineRefElement}
        />
        <TimelineScale
          disableTimeline={disableTimeline}
          contentRef={this.getContentRef}
          onIncrease={this.onScaleIncrease}
          onDecrease={this.onScaleDecrease}
          disableDecreaseScale={this.state.disableDecreaseScale}
          scale={this.state.scale}
          secondUnits={secondUnits}
          contentWidth={contentWidth}
          scrollLeft={this.state.scrollLeft}
          duration={this.state.duration}
          frameRate={video.playlist.frameRate}
          templateColors={configuration.templateColors}
          layersVisibility={timeline.layersVisisbility}
          onUpdateLayerVisibility={onUpdateLayerVisibility}
        />
        {!this.props.loadingPlayer ? (
          <>
            {timeline.layersVisisbility.showImageThumbnailsLayer && (
              <ThumbnailsLayer
                jsonVTT={video.playlist.thumbnailTrack.jsonVTT || []}
                secondUnits={secondUnits}
                contentWidth={contentWidth}
                scrollLeft={this.state.scrollLeft}
                scale={this.state.scale}
              />
            )}
            <div className="timeline-container-instances">
              {assets.filter((asset: PlaylistAsset) => !asset.isHidden).map(this.mapTimelineInstances)}
            </div>
            {timeline.layersVisisbility.showCommentsLayer && (
              <CommentsLayer
                secondUnits={secondUnits}
                contentWidth={contentWidth}
                scrollLeft={this.state.scrollLeft}
                comments={tabs.commentsTab.comments}
                frameRate={video.playlist.frameRate}
                onElementIn={this.onElementIn}
                onElementOut={this.onElementOut}
                onSegmentClicked={disableTimeline ? time => {} : this.onSegmentClicked}
              />
            )}
            {isTimelineEmpty && <div className="timeline-container_layer">All layers have been disabled</div>}
            {showConformanceModal && this.getConformanceButtonContainer()}
          </>
        ) : (
          <div className="timeline-container_layer">Loading Assets data...</div>
        )}
      </div>
    ) : null;
  }
}

const playHeadStyle: React.CSSProperties = {
  paddingLeft: 4,
  paddingRight: 4
};
