import * as React from 'react';
import {Overlay} from 'tt-components/src/Overlays/Overlay';
import {IOverlay} from 'tt-components/src/Overlays/IOverlay';
import {Dropdown, IDropdownOption} from '../../Dropdown';
import {Button} from '../../Button';
import {Feature} from './components/Feature';
import {Series} from './components/Series';
import {ISearchTitle} from '../../../../@types/searchTitle';
import {PlaylistAsset} from '../../../models/PlaylistAsset/PlaylistAsset';
import {IFeatureCredentials, ISeriesCredentials, IAssetCredentials} from '../../../state/IAppState';

interface IConformanceOverlayProps extends Partial<IOverlay> {
  assets: Array<PlaylistAsset>;
  onCancel: () => void;
  closestBodyElement?: HTMLElement;
  onAssign: (titles: Array<ISearchTitle>) => void;
}

interface IConformanceOverlayState {
  isOpen: boolean;
  selectedType: string;
  titles: Array<ISearchTitle>;
  data: IFeatureCredentials | ISeriesCredentials;
}

export class ConformanceOverlay extends React.Component<IConformanceOverlayProps, IConformanceOverlayState> {
  static defaultProps = {
    type: 'default',
    closestBodyElement: null
  };

  static getDerivedStateFromProps(nextProps: IConformanceOverlayProps, prevState: IConformanceOverlayState) {
    if (nextProps.isOpen !== prevState.isOpen) {
      return {isOpen: nextProps.isOpen};
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedType: null,
      titles: [],
      data: {} as any
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: IConformanceOverlayProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.init();
    }
  }

  getTitleInfo = () => {
    const assets = this.props.assets.filter((asset: PlaylistAsset) => !asset.isHidden);
    const data = (assets.length ? assets[0].getTitleInfoForAsset() : {}) as IAssetCredentials;
    const selectedType = data.type ? data.type : 'Feature';
    return {data: data.data, selectedType};
  };

  init = () => {
    const {selectedType, data} = this.getTitleInfo();
    this.setState({selectedType, titles: [], data});
  };

  onTypeSelected = (type: string) => {
    const {selectedType, data} = this.getTitleInfo();
    this.setState({selectedType: type, titles: [], data: type === selectedType ? data : ({} as any)});
  };

  getTypeDropdownContainer = () => {
    const options: Array<IDropdownOption> = [{label: 'Feature', value: 'Feature'}, {label: 'Series', value: 'Series'}];
    return (
      <div className="overlay-body_type-dropdown bottom-padding">
        <Dropdown
          label="Type *"
          width={154}
          selectedPlaceholder="Select type..."
          selected={this.state.selectedType}
          options={options}
          onSelected={this.onTypeSelected}
          portalNode={this.props.closestBodyElement}
        />
      </div>
    );
  };

  isConformanceGroupSelected = () => {
    return this.state.titles.some(
      (title: ISearchTitle) => ['FeatureConformance', 'EpisodeConformance'].indexOf(title.type) !== -1
    );
  };

  onAssign = () => {
    this.props.onAssign(this.state.titles);
  };

  getActionsContainer = () => {
    return (
      <div className="overlay-body_actions">
        <Button secondary content="CANCEL" onClick={this.props.onCancel} />
        <Button content="ASSIGN" disabled={!this.isConformanceGroupSelected()} onClick={this.onAssign} />
      </div>
    );
  };

  onConformanceGroupSelected = (titles: Array<ISearchTitle>) => {
    console.log('Selected title', titles);
    this.setState({titles});
  };

  getContent = () => {
    let filterSection: JSX.Element;
    switch (this.state.selectedType) {
      case 'Feature':
        filterSection = (
          <Feature
            data={(this.state.data || {}) as IFeatureCredentials}
            closestBodyElement={this.props.closestBodyElement}
            onConformanceSelected={this.onConformanceGroupSelected}
          />
        );
        break;
      case 'Series':
        filterSection = (
          <Series
            data={(this.state.data || {}) as ISeriesCredentials}
            closestBodyElement={this.props.closestBodyElement}
            onConformanceSelected={this.onConformanceGroupSelected}
          />
        );
        break;
      default:
        filterSection = null;
    }
    return (
      <>
        {this.getTypeDropdownContainer()}
        {filterSection}
        {this.getActionsContainer()}
      </>
    );
  };

  render() {
    return (
      <Overlay
        className="conformance-overlay-container"
        isOpen={this.state.isOpen}
        onCancel={this.props.onCancel}
        title="Assign Conformance Group"
        width="600px"
        centerY={false}
        y={0}
      >
        {this.getContent()}
      </Overlay>
    );
  }
}
