import LocalStorageService from '../services/LocalStorageService';

export const altOrCmd = key => (navigator.appVersion.indexOf('Mac') !== -1 ? `Cmd+${key}` : `alt+${key}`);
export const altOrCmdLabel = key => (navigator.appVersion.indexOf('Mac') !== -1 ? `⌘ + ${key}` : `Alt + ${key}`);
export const altOrCmdKey = () => (navigator.appVersion.indexOf('Mac') !== -1 ? `⌘` : `alt`);
export const altLabel = key => (navigator.appVersion.indexOf('Mac') !== -1 ? `⌥ + ${key}` : `Alt + ${key}`);

const service = new LocalStorageService();

export interface IShortcutsDefinitions {
  shortcutKey: string;
  label: string;
  keys: any;
  projectAction: PlayerActions | any;
  type: ShortcutType;
  description: string;
  preventEvent?: boolean;
  allowInPopup?: boolean;
  disabledInEditableArea?: boolean;
  attachEvent?: boolean;
  isGroup?: boolean;
  groupTitle?: string;
  block?: boolean;
}

export type IShortcutType = 'avid' | 'premiere' | 'fcp' | 'default' | string;

export type ShortcutType = 'Player' | any;

export type PlayerActions =
  | 'play_pause'
  | 'frameForward'
  | 'frameRewind'
  | 'forward10s'
  | 'backward10s'
  | 'fastForward'
  | 'fastRewind'
  | 'toStart'
  | 'toEnd'
  | 'markInTime'
  | 'markOutTime'
  | 'audio&SubPanel'
  | 'settings'
  | 'playerSpeed'
  | 'toggleFullScreen'
  | 'toggleLoop';

export const defaultDefinitions: Array<IShortcutsDefinitions> = [
  {
    shortcutKey: 'space',
    label: 'Space',
    keys: ['Space'],
    projectAction: 'play_pause',
    type: 'Player',
    description: 'Play/Pause',
    disabledInEditableArea: true
  },
  {
    shortcutKey: 'right',
    label: 'Right',
    keys: ['→'],
    projectAction: 'frameForward',
    type: 'Player',
    description: 'Frame Forward'
  },
  {
    shortcutKey: 'left',
    label: 'Left',
    keys: ['←'],
    projectAction: 'frameRewind',
    type: 'Player',
    description: 'Frame Rewind'
  },
  {
    shortcutKey: altOrCmd('right'),
    label: altOrCmdLabel('Right'),
    keys: [altOrCmdKey(), '→'],
    projectAction: 'forward10s',
    type: 'Player',
    description: 'Skip Fwd 10s',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('left'),
    label: altOrCmdLabel('Left'),
    keys: [altOrCmdKey(), '←'],
    projectAction: 'backward10s',
    type: 'Player',
    description: 'Skip Back 10s',
    preventEvent: true
  },
  {
    shortcutKey: 'shift+right',
    label: 'Shift + Right',
    keys: ['shift', '→'],
    projectAction: 'fastForward',
    type: 'Player',
    description: 'Fast Forward',
    preventEvent: true
  },
  {
    shortcutKey: 'shift+left',
    label: 'Shift + Left',
    keys: ['shift', '←'],
    projectAction: 'fastRewind',
    type: 'Player',
    description: 'Fast Rewind',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('shift+left'),
    label: altOrCmdLabel('Shift+Left '),
    keys: [altOrCmdKey(), 'shift', '←'],
    projectAction: 'toStart',
    type: 'Player',
    description: 'Skip to Start',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('shift+right'),
    label: altOrCmdLabel('Shift+Right'),
    keys: [altOrCmdKey(), 'shift', '→'],
    projectAction: 'toEnd',
    type: 'Player',
    description: 'Skip to End',
    preventEvent: true
  },
  {
    shortcutKey: 'alt+i',
    label: altLabel('I'),
    keys: ['alt', 'i'],
    projectAction: 'markInTime',
    type: 'Markups',
    description: 'Mark Start Time',
    preventEvent: true
  },
  {
    shortcutKey: 'alt+o',
    label: altLabel('O'),
    keys: ['alt', 'o'],
    projectAction: 'markOutTime',
    type: 'Markups',
    description: 'Mark End Time',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('a'),
    label: altOrCmdLabel('A'),
    keys: [altOrCmdKey(), 'a'],
    projectAction: 'audio&SubPanel',
    type: 'Display',
    description: 'Audio & Subs',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('s'),
    label: altOrCmdLabel('S'),
    keys: [altOrCmdKey(), 's'],
    projectAction: 'settings',
    type: 'Display',
    description: 'Settings',
    preventEvent: true
  },

  {
    shortcutKey: altOrCmd('shift+s'),
    label: altOrCmdLabel('Shift+S'),
    keys: [altOrCmdKey(), 'shift', 's'],
    projectAction: 'playerSpeed',
    type: 'Display',
    description: 'Player Speed',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('shift+d'),
    label: altOrCmdLabel('Shift+D'),
    keys: [altOrCmdKey(), 'shift', 'd'],
    projectAction: 'toggleDiagnosticsBox',
    type: 'Display',
    description: 'Open/Close Playback Diagnostics',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('f'),
    label: altOrCmdLabel('F'),
    keys: [altOrCmdKey(), 'f'],
    projectAction: 'toggleFullScreen',
    type: 'Display',
    description: 'Full Screen',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('l'),
    label: altOrCmdLabel('L'),
    keys: [altOrCmdKey(), 'l'],
    projectAction: 'toggleLoop',
    type: 'Player',
    description: 'Loop',
    preventEvent: true
  }
];

const FcpDefinitions: Array<IShortcutsDefinitions> = [
  {
    shortcutKey: 'space',
    label: 'Space',
    keys: ['Space'],
    projectAction: 'play_pause',
    type: 'Player',
    description: 'Play/Pause',
    disabledInEditableArea: true
  },
  {
    shortcutKey: 'right',
    label: 'Right',
    keys: ['→'],
    projectAction: 'frameForward',
    type: 'Player',
    description: 'Frame Forward'
  },
  {
    shortcutKey: 'left',
    label: 'Left',
    keys: ['←'],
    projectAction: 'frameRewind',
    type: 'Player',
    description: 'Frame Rewind'
  },
  {
    shortcutKey: 'shift+right',
    label: 'Shift + Right',
    keys: ['shift', '→'],
    projectAction: 'forward10s',
    type: 'Player',
    description: 'Skip Fwd 10s',
    preventEvent: true
  },
  {
    shortcutKey: 'shift+left',
    label: 'Shift + Left',
    keys: ['shift', '←'],
    projectAction: 'backward10s',
    type: 'Player',
    description: 'Skip Back 10s',
    preventEvent: true
  },
  {
    shortcutKey: 'cmd+right',
    label: '⌘ + Right',
    keys: ['⌘', '→'],
    projectAction: 'fastForward',
    type: 'Player',
    description: 'Fast Forward',
    preventEvent: true
  },
  {
    shortcutKey: 'cmd+left',
    label: '⌘ + Left',
    keys: ['⌘', '←'],
    projectAction: 'fastRewind',
    type: 'Player',
    description: 'Fast Rewind',
    preventEvent: true
  },
  {
    shortcutKey: 'home',
    label: 'Home',
    keys: ['home'],
    projectAction: 'toStart',
    type: 'Player',
    description: 'Skip to Start',
    preventEvent: true
  },
  {
    shortcutKey: 'end',
    label: 'End',
    keys: ['end'],
    projectAction: 'toEnd',
    type: 'Player',
    description: 'Skip to End',
    preventEvent: true
  },
  {
    shortcutKey: 'alt+i',
    label: altLabel('I'),
    keys: ['alt', 'i'],
    projectAction: 'markInTime',
    type: 'Markups',
    description: 'Mark Start Time',
    preventEvent: true
  },
  {
    shortcutKey: 'alt+o',
    label: altLabel('O'),
    keys: ['alt', 'o'],
    projectAction: 'markOutTime',
    type: 'Markups',
    description: 'Mark End Time',
    preventEvent: true
  },
  {
    shortcutKey: 'cmd+a',
    label: '⌘ + A',
    keys: ['⌘', 'a'],
    projectAction: 'audio&SubPanel',
    type: 'Display',
    description: 'Audio & Subs',
    preventEvent: true
  },
  {
    shortcutKey: 'cmd+s',
    label: '⌘ + S',
    keys: ['⌘', 's'],
    projectAction: 'settings',
    type: 'Display',
    description: 'Settings',
    preventEvent: true
  },

  {
    shortcutKey: 'cmd+shift+s',
    label: '⌘ + Shift + S',
    keys: ['⌘', 'shift', 's'],
    projectAction: 'playerSpeed',
    type: 'Display',
    description: 'Player Speed',
    preventEvent: true
  },
  {
    shortcutKey: 'cmd+shift+d',
    label: '⌘ + Shift + D',
    keys: ['⌘', 'shift', 'd'],
    projectAction: 'toggleDiagnosticsBox',
    type: 'Display',
    description: 'Open/Close Playback Diagnostics',
    preventEvent: true
  },
  {
    shortcutKey: 'cmd+f',
    label: '⌘ + F',
    keys: ['⌘', 'f'],
    projectAction: 'toggleFullScreen',
    type: 'Display',
    description: 'Full Screen',
    preventEvent: true
  },
  {
    shortcutKey: 'cmd+l',
    label: '⌘ + L',
    keys: ['⌘', 'l'],
    projectAction: 'toggleLoop',
    type: 'Player',
    description: 'Loop',
    preventEvent: true
  }
];

const AvidDefinitions: Array<IShortcutsDefinitions> = [
  {
    shortcutKey: 'space',
    label: 'Space',
    keys: ['Space'],
    projectAction: 'play_pause',
    type: 'Player',
    description: 'Play/Pause',
    block: false,
    disabledInEditableArea: true
  },
  {
    shortcutKey: 'right',
    label: 'Right',
    keys: ['→'],
    projectAction: 'frameForward',
    type: 'Player',
    description: 'Frame Forward',
    isGroup: true,
    groupTitle: 'Frame Forward/Rewind'
  },
  {
    shortcutKey: 'left',
    label: 'Left',
    keys: ['←'],
    projectAction: 'frameRewind',
    type: 'Player',
    description: 'Frame Rewind',
    block: true
  },
  {
    shortcutKey: 'alt+right',
    label: altLabel('Right'),
    keys: ['alt', '→'],
    projectAction: 'forward10s',
    type: 'Player',
    description: 'Skip Fwd 10s',
    preventEvent: true,
    isGroup: true,
    groupTitle: 'Skip Forward/Backward 10s'
  },
  {
    shortcutKey: 'alt+left',
    label: altLabel('Left'),
    keys: ['alt', '←'],
    projectAction: 'backward10s',
    type: 'Player',
    description: 'Skip Back 10s',
    preventEvent: true,
    block: true
  },
  {
    shortcutKey: 'shift+right',
    label: 'Shift + Right',
    keys: ['shift', '→'],
    projectAction: 'fastForward',
    type: 'Player',
    description: 'Fast Forward',
    preventEvent: true,
    isGroup: true,
    groupTitle: 'Fast Forward/Rewind'
  },
  {
    shortcutKey: 'shift+left',
    label: 'Shift + Left',
    keys: ['shift', '←'],
    projectAction: 'fastRewind',
    type: 'Player',
    description: 'Fast Rewind',
    preventEvent: true,
    block: true
  },
  {
    shortcutKey: altOrCmd('shift+left'),
    label: altOrCmdLabel('Shift+Left'),
    keys: [altOrCmdKey(), 'shift', '←'],
    projectAction: 'toStart',
    type: 'Player',
    description: 'Skip to Start',
    preventEvent: true,
    isGroup: true,
    groupTitle: 'Skip to Start/End'
  },
  {
    shortcutKey: altOrCmd('shift+right'),
    label: altOrCmdLabel('Shift+Right'),
    keys: [altOrCmdKey(), 'shift', '→'],
    projectAction: 'toEnd',
    type: 'Player',
    description: 'Skip to End',
    preventEvent: true,
    block: true
  },
  {
    shortcutKey: 'alt+i',
    label: altLabel('I'),
    keys: ['alt', 'i'],
    projectAction: 'markInTime',
    type: 'Markups',
    description: 'Mark Start Time',
    preventEvent: true,
    block: false
  },
  {
    shortcutKey: 'alt+o',
    label: altLabel('O'),
    keys: ['alt', 'o'],
    projectAction: 'markOutTime',
    type: 'Markups',
    description: 'Mark End Time',
    preventEvent: true,
    block: false
  },
  {
    shortcutKey: altOrCmd('a'),
    label: altOrCmdLabel('A'),
    keys: [altOrCmdKey(), 'a'],
    projectAction: 'audio&SubPanel',
    type: 'Display',
    description: 'Audio & Subs',
    preventEvent: true,
    block: false
  },
  {
    shortcutKey: altOrCmd('s'),
    label: altOrCmdLabel('S'),
    keys: [altOrCmdKey(), 's'],
    projectAction: 'settings',
    type: 'Display',
    description: 'Settings',
    preventEvent: true,
    block: false
  },

  {
    shortcutKey: altOrCmd('shift+s'),
    label: altOrCmdLabel('Shift+S'),
    keys: [altOrCmdKey(), 'shift', 's'],
    projectAction: 'playerSpeed',
    type: 'Display',
    description: 'Player Speed',
    preventEvent: true,
    block: false
  },
  {
    shortcutKey: altOrCmd('shift+d'),
    label: altOrCmdLabel('Shift+D'),
    keys: [altOrCmdKey(), 'shift', 'd'],
    projectAction: 'toggleDiagnosticsBox',
    type: 'Display',
    description: 'Open/Close Playback Diagnostics',
    preventEvent: true,
    block: false
  },
  {
    shortcutKey: altOrCmd('f'),
    label: altOrCmdLabel('F'),
    keys: [altOrCmdKey(), 'f'],
    projectAction: 'toggleFullScreen',
    type: 'Display',
    description: 'Full Screen',
    preventEvent: true,
    block: false
  },
  {
    shortcutKey: altOrCmd('l'),
    label: altOrCmdLabel('L'),
    keys: [altOrCmdKey(), 'l'],
    projectAction: 'toggleLoop',
    type: 'Player',
    description: 'Loop',
    preventEvent: true,
    block: false
  }
];

const PremiereDefinitions: Array<IShortcutsDefinitions> = [
  {
    shortcutKey: 'space',
    label: 'Space',
    keys: ['Space'],
    projectAction: 'play_pause',
    type: 'Player',
    description: 'Play/Pause',
    disabledInEditableArea: true
  },
  {
    shortcutKey: 'right',
    label: 'Right',
    keys: ['→'],
    projectAction: 'frameForward',
    type: 'Player',
    description: 'Frame Forward'
  },
  {
    shortcutKey: 'left',
    label: 'Left',
    keys: ['←'],
    projectAction: 'frameRewind',
    type: 'Player',
    description: 'Frame Rewind'
  },
  {
    shortcutKey: 'shift+right',
    label: 'Shift + Right',
    keys: ['shift', '→'],
    projectAction: 'forward10s',
    type: 'Player',
    description: 'Skip Fwd 10s',
    preventEvent: true
  },
  {
    shortcutKey: 'shift+left',
    label: 'Shift + Left',
    keys: ['shift', '→'],
    projectAction: 'backward10s',
    type: 'Player',
    description: 'Skip Back 10s',
    preventEvent: true
  },
  {
    shortcutKey: 'j',
    label: 'J',
    keys: ['J'],
    projectAction: 'fastForward',
    type: 'Player',
    description: 'Fast Forward',
    preventEvent: true,
    disabledInEditableArea: true
  },
  {
    shortcutKey: 'l',
    label: 'L',
    keys: ['L'],
    projectAction: 'fastRewind',
    type: 'Player',
    description: 'Fast Rewind',
    preventEvent: true,
    disabledInEditableArea: true
  },
  {
    shortcutKey: 'home',
    label: 'Home',
    keys: ['Home'],
    projectAction: 'toStart',
    type: 'Player',
    description: 'Skip to Start',
    preventEvent: true
  },
  {
    shortcutKey: 'end',
    label: 'End',
    keys: ['End'],
    projectAction: 'toEnd',
    type: 'Player',
    description: 'Skip to End',
    preventEvent: true
  },
  {
    shortcutKey: 'alt+i',
    label: altLabel('I'),
    keys: ['alt', 'i'],
    projectAction: 'markInTime',
    type: 'Markups',
    description: 'Mark Start Time',
    preventEvent: true
  },
  {
    shortcutKey: 'alt+o',
    label: altLabel('O'),
    keys: ['alt', 'o'],
    projectAction: 'markOutTime',
    type: 'Markups',
    description: 'Mark End Time',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('a'),
    label: altOrCmdLabel('A'),
    keys: [altOrCmdKey(), 'a'],
    projectAction: 'audio&SubPanel',
    type: 'Display',
    description: 'Audio & Subs',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('s'),
    label: altOrCmdLabel('S'),
    keys: [altOrCmdKey(), 's'],
    projectAction: 'settings',
    type: 'Display',
    description: 'Settings',
    disabledInEditableArea: true,
    preventEvent: true
  },

  {
    shortcutKey: altOrCmd('shift+s'),
    label: altOrCmdLabel('Shift+S'),
    keys: [altOrCmdKey(), 'shift', 's'],
    projectAction: 'playerSpeed',
    type: 'Display',
    description: 'Player Speed',
    preventEvent: true
  },
  {
    shortcutKey: altOrCmd('shift+d'),
    label: altOrCmdLabel('Shift+D'),
    keys: [altOrCmdKey(), 'shift', 'd'],
    projectAction: 'toggleDiagnosticsBox',
    type: 'Display',
    description: 'Open/Close Playback Diagnostics',
    preventEvent: true
  },
  {
    shortcutKey: 'ctrl+`',
    label: 'Ctrl + `',
    keys: ['Ctrl', '`'],
    projectAction: 'toggleFullScreen',
    type: 'Display',
    description: 'Full Screen',
    preventEvent: true
  },
  {
    shortcutKey: 'ctrl+l',
    label: 'Ctrl + L',
    keys: ['Ctrl', 'l'],
    projectAction: 'toggleLoop',
    type: 'Player',
    description: 'Loop',
    preventEvent: true
  }
];

//For getting changed shortcuts from localstorage
const getLocalStorageDefenitions = () => {
  return service.get('defaultShortcuts') !== null ? JSON.parse(service.get('defaultShortcuts')) : defaultDefinitions;
};

export const setDefaultDefenitions = () => {
  service.remove('defaultShortcuts');
  return defaultDefinitions;
};

export const getShortcutsByName = (name: IShortcutType) => {
  switch (name) {
    case 'avid':
      return AvidDefinitions;
      break;
    case 'premiere':
      return PremiereDefinitions;
      break;
    case 'fcp':
      return FcpDefinitions;
      break;
    default:
      return getLocalStorageDefenitions();
  }
};
