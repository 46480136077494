import {Store} from 'redux';
import {IAppState} from './state';
import {
  addSubtitleFile,
  CHANGE_VISIBLE_SUBTITLE,
  forwardAndRewindRateChange,
  goToLocation,
  MUTE,
  PLAYBACK_RATE_CHANGE,
  seek,
  skipToEnd,
  SWITCH_PLAYING,
  switchFastFrame,
  VOLUME,
  CHANGE_MEDIA_TIME_FORMAT
} from './actions/video';
import {SET_PLAYER_WIDTH, SET_TEMPLATE_COLORS} from './actions/configuration';
import {FastFrameType} from './constants/constants';
import {EventBusEventNames} from '../@types/eventBusEventNames';
import {SeekType} from './components/OnePlayerControlBar/onePlayerControlBarProps';
import {utils} from 'tt-components/src/Utils/index';
import {IEventBus} from './EventBus';
import {serviceProvider} from './services/serviceProvider';
import {ITemplateColors} from '../@types/templateColors';
import {IDisplayMediaTimeFormat} from '../@types/displayMediaTimeFormat';
import {ENABLE_DIAGNOSTICS_PANEL, CHANGE_WATERMARK_POSITION} from './actions/overlays';
import {IWatermarkPosition} from '../@types/watermarkPosition';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';

export class VideoPlayerApi {
  store: Store<IAppState>;
  eventBus: IEventBus;

  constructor(store: Store<IAppState>) {
    this.store = store;
    this.eventBus = serviceProvider.eventBus;
  }

  pause() {
    this.store.dispatch({type: SWITCH_PLAYING, payload: false});
  }

  play() {
    this.store.dispatch({type: SWITCH_PLAYING, payload: true});
  }

  setVolume(volume: number) {
    this.store.dispatch({type: VOLUME, payload: volume});
  }

  mute() {
    this.store.dispatch({type: MUTE, payload: true});
  }

  frameForward() {
    this.store.dispatch(seek(1, SeekType.frameLeap) as any);
  }

  frameBackward() {
    this.store.dispatch(seek(-1, SeekType.frameLeap) as any);
  }

  skipForward10Sec() {
    this.store.dispatch(seek(10, SeekType.timeLeap) as any);
  }

  skipBackward10Sec() {
    this.store.dispatch(seek(-10, SeekType.timeLeap) as any);
  }

  skipToBeginning() {
    this.store.dispatch(seek(0, SeekType.toTime) as any);
  }

  skipToEnd() {
    this.store.dispatch(skipToEnd() as any);
  }

  startFastRewind(rate: number) {
    this.store.dispatch(forwardAndRewindRateChange(rate) as any);
    this.store.dispatch(switchFastFrame(false, FastFrameType.fastRewind) as any);
  }

  stopFastRewind() {
    this.store.dispatch(switchFastFrame(true, FastFrameType.fastRewind) as any);
  }

  startFastForward(rate: number) {
    this.store.dispatch(forwardAndRewindRateChange(rate) as any);
    this.store.dispatch(switchFastFrame(false, FastFrameType.fastForward) as any);
  }

  stopFastForward() {
    this.store.dispatch(switchFastFrame(true, FastFrameType.fastForward) as any);
  }

  goToTimecode(data) {
    let toTime = utils.formatting.smpteTimecodeToSeconds(
      data.timecode,
      data.frameRate,
      data.dropFrame,
      data.timeCodeFormat
    );
    this.store.dispatch(goToLocation(toTime) as any);
  }

  setPlaybackSpeed(rate) {
    this.store.dispatch({
      type: PLAYBACK_RATE_CHANGE,
      payload: rate
    });
  }

  setPlayerWidth(data) {
    this.store.dispatch({
      type: SET_PLAYER_WIDTH,
      payload: parseInt(data)
    });
  }

  setSubtitleTrack(trackId: string) {
    this.store.dispatch({
      type: CHANGE_VISIBLE_SUBTITLE,
      payload: trackId
    });
  }

  setSubtitleFile(file: File) {
    this.store.dispatch(addSubtitleFile(file) as any);
  }

  setPlayerTemplateColors(colors: ITemplateColors) {
    this.store.dispatch({
      type: SET_TEMPLATE_COLORS,
      payload: colors
    });
  }

  changeMediaTimeFormat(format: IDisplayMediaTimeFormat) {
    this.store.dispatch({
      type: CHANGE_MEDIA_TIME_FORMAT,
      payload: format
    });
  }

  enableDiagnosticsPanel(isDiagnosticsPanelEnabled: boolean) {
    this.store.dispatch({
      type: ENABLE_DIAGNOSTICS_PANEL,
      payload: isDiagnosticsPanelEnabled
    });
  }

  changeWatermarkPosition(watermarkPosition: IWatermarkPosition) {
    this.store.dispatch({
      type: CHANGE_WATERMARK_POSITION,
      payload: watermarkPosition
    });
  }

  getExternalSubtitlesList() {
    return this.store.getState().video.playlist.externalSubs;
  }

  getEmbeddedSubtitlesList() {
    return this.store.getState().video.playlist.embeddedSubs;
  }

  getExternalAudioChannelConfigurationsList() {
    const assets = this.store.getState().video.playlist.assets;
    const externalAudioChannelConfigs = PlaylistAsset.filter.getExternalAudioConfigurations(assets);
    return externalAudioChannelConfigs;
  }

  on(event: EventBusEventNames, callback: () => {}) {
    this.eventBus.on(event, null, callback);
  }

  off(event: EventBusEventNames) {
    this.eventBus.off(event, null);
  }
}
