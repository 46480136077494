import * as React from 'react';
import {IAppPlaylist} from '../../../../state';
import {Thumbnail} from '../Thumbnail';
import {utils} from 'tt-components/src/Utils';
import {PlayIcon} from '../../assets/Icons/PlayIcon';
import {IEventGroup} from '../../../../../@types/markupEvent';
import {Dropdown, Icon} from 'tt-components';
import {ProgramTimingsRow} from './components/ProgramTimingsRow';
import {Tt4a} from '../../../../components/Typography';

export interface IProgramTimingsProps {
  playlist: IAppPlaylist;
  data: IEventGroup;
}

export interface IProgramTimingDispatch {
  goToLocation: (value: number) => void;
}

export class ProgramTimings extends React.Component<IProgramTimingsProps & IProgramTimingDispatch> {
  renderProgramTimingsTable() {
    const list = [];
    const data = this.props.data ? this.props.data.events || [] : [];
    /*const columns = [
      {id: 'type', header: 'TIMING TYPE'},
      {id: 'timeIn', header: 'TIME IN', render: this.renderTime.bind(this)},
      {id: 'timeOut', header: 'TIME OUT', render: this.renderTime.bind(this)}
    ];*/
    data.forEach(item => {
      list.push({
        type: item.type,
        timeIn: item.timeIn,
        timeOut: item.timeOut
      });
    });

    return list.map((elem, index) => <ProgramTimingsRow key={index} content={elem} />);
  }

  handlePlayButton(time) {
    this.props.goToLocation(time);
  }

  convertToSecond(time: string) {
    return utils.formatting.smpteTimecodeToSeconds(time || '00:00:00:00');
  }

  renderTime(props) {
    return (
      <div className="markup-timecode program-timing-play">
        <span className="markup-timecode_timecode">{props.cellData}</span>
        <div onClick={() => this.handlePlayButton(this.convertToSecond(props.cellData))}>
          <Dropdown
            className={'program-timing-thumbnail'}
            buttonComponent={() => <Icon icon={PlayIcon} size="21px" />}
            content={
              <Thumbnail
                time={this.convertToSecond(props.cellData)}
                jsonVTT={this.props.playlist.thumbnailTrack.jsonVTT}
              />
            }
            openAbove={true}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="metadata-program-timings-container">
        <div className="metadata-program-timings-container-header">
          <Tt4a content="Type" />
          <Tt4a content="Time In" />
          <Tt4a content="Time Out" />
        </div>
        {this.renderProgramTimingsTable()}
      </div>
    );
  }
}
