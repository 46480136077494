import * as React from 'react';
import {TimelineRow} from '../TimelineRow';
import {ProgramTimingsLabel} from './components/ProgramTimingsLabel';
import {ProgramTimingsContent} from './components/ProgramTimingsContent';
import {IFrameRate} from 'tt-components';
import {ITooltipSegmentData} from '../../interfaces/ITimelineTooltipData';

interface IProgramTimingsLayerProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  // NOTE: Events type not defined explicitly; update when properly defined
  events: Array<any>;
  frameRate: IFrameRate;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class ProgramTimingsLayer extends React.PureComponent<IProgramTimingsLayerProps> {
  render() {
    const label = <ProgramTimingsLabel />;
    const content = (
      <ProgramTimingsContent
        secondUnits={this.props.secondUnits}
        contentWidth={this.props.contentWidth}
        scrollLeft={this.props.scrollLeft}
        events={this.props.events}
        frameRate={this.props.frameRate}
        onElementIn={this.props.onElementIn}
        onElementOut={this.props.onElementOut}
        onSegmentClicked={this.props.onSegmentClicked}
      />
    );

    return <TimelineRow labelElement={label} contentElement={content} />;
  }
}
