import * as React from 'react';
import {Table} from 'tt-components/src/Table/Table';
import {IAppPlaylist} from '../../../../state/IAppState';
import {ISubtitlesMetadataItem} from '../../../../../@types/subtitlesMetadata';
import {mapSubtitleLanguageCountry} from '../../../../utils/utils';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IEnums} from '../../../../state/IEnums';
import {Tt2b} from '../../../../components/Typography';

interface ISubtitlesProps {
  playlist: IAppPlaylist;
  enums: IEnums;
}

export class Subtitles extends React.Component<ISubtitlesProps> {
  renderSubtitlesTable() {
    const {playlist} = this.props;

    const columns = [
      {id: 'language', header: 'LANGUAGE', render: this.renderTruncatedColumn},
      {id: 'format', header: 'FORMAT'},
      {id: 'coding', header: 'CODING'},
      {id: 'embedType', header: 'EMBED TYPE'}
    ];

    const groupSubsData = (subsData: ISubtitlesMetadataItem) => {
      return {
        language: mapSubtitleLanguageCountry(subsData.language, subsData.country, this.props.enums.languageDialect),
        format: subsData.format,
        coding: subsData.coding,
        embedType: subsData.embedType
      };
    };

    const asset = PlaylistAsset.filter.getPlaylistAsset(playlist.assets, playlist.selectedAssetId || '');
    const list = (asset ? PlaylistAsset.filter.getAssetSubtitlesMetadata(asset) : []).reduce(
      (acc: Array<any>, item: ISubtitlesMetadataItem) => {
        const subsData = groupSubsData(item);
        return [...acc, subsData];
      },
      []
    );

    if (!list.length) {
      return (
        <Tt2b
          className="metadata-subtitles-container_empty"
          content="No Subtitle configurations are defined for this asset"
        />
      );
    }

    return <Table headerHeight={30} rowHeight={45} list={list} columns={columns} />;
  }

  renderTruncatedColumn = props => (
    <span style={truncateColumnText} title={props.cellData || ''}>
      {props.cellData || ''}
    </span>
  );

  render() {
    return <div className="metadata-subtitles-container">{this.renderSubtitlesTable()}</div>;
  }
}

const truncateColumnText: React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  textOverflow: 'ellipsis'
};
