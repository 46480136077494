import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Container, Row, Column} from '../../../../../../../Grid';
import {Tt1, Tt2b} from '../../../../../../../../../../components/Typography';
import {Dropdown, IDropdownOption} from '../../../../../../../../../../components/Dropdown';
import {Toggle} from '../../../../../../../../../../components/Toggle';
import {IAssetDistributor, IErrorLog} from '../../../../../../../../../../../@types/assetDetails';
import {deepCopy, getErrorLogByField} from '../../../../../../../../utils/helpers';
import {CloseIcon} from '../../../../../../../../../../assets/Icons/Close';

interface IDistributorsProps {
  distributors: Array<IAssetDistributor>;
  distributorsOptions: Array<IDropdownOption>;
  disabled: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  onDistributorUpdates: (distributors: Array<IAssetDistributor>) => void;
}

const getDefaultDistributor = (): IAssetDistributor => ({
  id: `${new Date().getTime()}-new-distributor`,
  distributorId: '',
  isExclusive: false
});

export class Distributors extends React.PureComponent<IDistributorsProps> {
  getDistributor = (distributor: IAssetDistributor, key: number) => {
    const newDistributorRecord = (distributor.id || '').endsWith('-new-distributor');
    return (
      <Container className="distributors-container_body_reference" key={key}>
        <Row>
          <div className="distributor-divider" />
        </Row>
        <Row>
          <div className="close-icon" onClick={() => this.onRemoveDistributor(distributor.id)}>
            <Icon icon={CloseIcon} size="12px" color="#FFFFFF" />
          </div>
        </Row>
        <Row>
          <Column className="distributors-container_body_reference_distributor">
            <Dropdown
              disabled={this.props.disabled}
              fixedButtonWidth
              search
              label="Distributor Name / ID"
              options={this.props.distributorsOptions}
              selected={distributor.distributorId}
              onSelected={(distributorId: string) => this.onDistributorUpdates(distributor.id, distributorId)}
              portalNode={this.props.closestBody}
              errorLog={!newDistributorRecord && getErrorLogByField('distributors.distributorId', this.props.errorLogs)}
            />
          </Column>
          <Column>
            <Toggle
              disabled={this.props.disabled}
              label="Exclusive"
              toggled={distributor.isExclusive}
              onToggle={(state: boolean) => this.onDistributorExlusiveUpdates(distributor.id, state)}
            />
          </Column>
        </Row>
      </Container>
    );
  };

  onDistributorUpdates = (distributorId: string, value: any) => {
    const distributors = deepCopy([...this.props.distributors]).map((distributor: IAssetDistributor) => {
      if (distributorId === distributor.id) {
        distributor.distributorId = value;
      }
      return distributor;
    });
    this.props.onDistributorUpdates(distributors);
  };

  onDistributorExlusiveUpdates = (distributorId: string, value: any) => {
    const distributors = deepCopy([...this.props.distributors]).map((distributor: IAssetDistributor) => {
      if (distributorId === distributor.id) {
        distributor.isExclusive = value;
      }
      return distributor;
    });
    this.props.onDistributorUpdates(distributors);
  };

  onAddDistributor = () => {
    if (this.props.disabled) {
      return;
    }
    const distributors = deepCopy([...this.props.distributors]);
    distributors.push(getDefaultDistributor());
    this.props.onDistributorUpdates(distributors);
  };

  onRemoveDistributor = (distributorId: string) => {
    if (this.props.disabled) {
      return;
    }
    const distributors = deepCopy([...this.props.distributors]).filter(
      (distributor: IAssetDistributor) => distributor.id !== distributorId
    );
    this.props.onDistributorUpdates(distributors);
  };

  render() {
    const disabled = this.props.disabled ? ` disabled` : ``;
    return (
      <Container className="distributors-container">
        <Row className="distributors-container_header">
          <Tt1 content="Distributors" className="distributors-container_header_title" />
        </Row>
        <Row className="distributors-container_body">{this.props.distributors.map(this.getDistributor)}</Row>
        <Row className="distributors-container_footer">
          <Tt2b
            content="+ ADD DISTRIBUTOR"
            className={`distributors-container_footer_text${disabled}`}
            onClick={this.onAddDistributor}
          />
        </Row>
      </Container>
    );
  }
}
