import * as React from 'react';
import {ITextProps} from './ITextProps';

interface IBaseProps extends ITextProps {
  typoClass?: string;
}

export const Base = (props: IBaseProps) => {
  const {content, className, typoClass, ...instrictProps} = props;
  return (
    <div className={`${typoClass}${className ? ` ${className}` : ``}`} {...instrictProps}>
      {content}
    </div>
  );
};
