import * as React from 'react';
import {ConfigRow} from '../ConfigRow';
import {IAudioChannelConfiguration} from '../../../../../../../../../@types/audioChannelConfiguration';
import {CustomSelectDropdown, IOption} from '../../../../../../../../components/CustomSelectDropdown';
import {isConfigurationProperlyConfigured} from '../../../../../../utils/helpers';
import {parseEnum} from '../../../../../../../../utils/utils';
import {IEnum} from '../../../../../../../../../@types/enum';
import {Collapsible} from '../../../../../Collapsible';
import {Dropdown, IDropdownOption} from '../../../../../../../../components/Dropdown';
import {AudioPlay} from '../../../../../../../../assets/Icons/AudioPlayIcon';
import {AudioListening} from '../../../../../../../../assets/Icons/AudioListeningIcon';
import {Icon} from 'tt-components/src/Icon';

interface IConfigGroupProps {
  audioConfig: IAudioChannelConfiguration;
  languageEnums: Array<IEnum>;
  channelConfigTypeEnums: Array<IEnum>;
  channelsConfigured: boolean;
  isPlaying: boolean;
  switchPlaying: () => void;
  onConfigRemove: (confId: string) => void;
  onConfigUpdate: (confId: string, updateObject: {[x: string]: string}) => void;
}

interface IConfigGroupState {
  editLanguage: boolean;
  editType: boolean;
  isPlaying: boolean;
}

export class ConfigGroup extends React.Component<IConfigGroupProps, IConfigGroupState> {
  constructor(props) {
    super(props);

    this.state = {
      editLanguage: false,
      editType: false,
      isPlaying: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying) {
      this.setState({
        isPlaying: this.props.isPlaying
      });
    }
  }

  renderChannelConfControl = () => {
    return <span className="channel-conf">{this.props.audioConfig.channelConfig}</span>;
  };

  switchPlaying = e => {
    e.stopPropagation();
    this.props.switchPlaying();
  };

  renderChannelTypeControl = () => {
    if (this.state.editType) {
      const {id} = this.props.audioConfig;
      const options: Array<IOption> = this.props.channelConfigTypeEnums
        .filter((type: IEnum) => type.isActive)
        .map((type: IEnum) => ({value: type.value, text: type.name}));
      return (
        <CustomSelectDropdown
          open={true}
          selected={this.props.audioConfig.type}
          options={options}
          onItemSelected={(value: string) => this.props.onConfigUpdate(id, {type: value})}
          placeholder="Select type..."
          onClosed={() => this.setState({editType: false})}
        />
      );
    } else {
      return (
        <span className="channel-type" onClick={e => this.setState({editType: true})}>
          {parseEnum(this.props.audioConfig.type || 'Not defined', this.props.channelConfigTypeEnums)}
        </span>
      );
    }
  };

  renderChannelLanguageControl = () => {
    const {id} = this.props.audioConfig;
    const newProps = (selectedPlaceholder: string) => {
      return {selectedPlaceholder, buttonContentStyles: {color: '#1F72F2'}};
    };
    const options: Array<IDropdownOption> = this.props.languageEnums
      .filter((language: IEnum) => language.isActive)
      .map((language: IEnum) => ({value: language.value, label: language.name}));
    return (
      <Dropdown
        borderless
        search
        disableMatchWidth
        options={options}
        selected={this.props.audioConfig.language}
        paddingLeft={0}
        borderWidth={0}
        onSelected={(value: string) => this.props.onConfigUpdate(id, {language: value})}
        {...newProps('Language')}
      />
    );
  };

  isProperConfiguration = () => {
    const {audioConfig, channelsConfigured} = this.props;
    return isConfigurationProperlyConfigured(audioConfig) && channelsConfigured;
  };

  renderRowTitle = () => {
    const {isPlaying} = this.state;
    return (
      <div className="config-group-container_channel-configs">
        <ConfigRow
          className="config-group-container_channel-configs_row"
          cellX1Content={
            <div onClick={e => this.switchPlaying(e)}>
              {isPlaying ? <Icon icon={AudioListening} /> : <Icon icon={AudioPlay} />}
            </div>
          }
          cellX2Content={this.renderChannelConfControl()}
          cellX4Content={this.renderChannelLanguageControl()}
        />
        <div
          className="config-group-container_close-icon"
          onClick={e => this.props.onConfigRemove(this.props.audioConfig.id)}
        >
          &times;
        </div>
      </div>
    );
  };

  renderRowContent = () => {
    return <div className="config-group-container_channels">{this.props.children}</div>;
  };

  render() {
    return (
      <div className="grouped-item">
        <Collapsible title={this.renderRowTitle()} content={this.renderRowContent()} />
      </div>
    );
  }
}
