import * as React from 'react';
import {Tt2b, Tt4a} from '../Typography';

export type IToggleLabels = {
  enabled: string;
  disabled: string;
};

interface IToggleProps {
  label?: string;
  toggled?: boolean;
  disabled?: boolean;
  labels?: IToggleLabels;
  onToggle?: (state: boolean) => void;
}

interface IToggleState {
  toggled: boolean;
}

export class Toggle extends React.Component<IToggleProps, IToggleState> {
  static defaultProps = {
    labels: {enabled: 'True', disabled: 'False'}
  };

  static getDerivedStateFromProps(nextProps: IToggleProps, prevState: IToggleState) {
    if (nextProps.toggled !== prevState.toggled && typeof nextProps.toggled !== 'undefined') {
      return {toggled: nextProps.toggled};
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      toggled: false
    };
  }

  onToggle = () => {
    if (this.props.disabled) {
      return;
    }
    if (typeof this.props.toggled !== 'undefined' && this.props.onToggle) {
      this.props.onToggle(!this.state.toggled);
    } else {
      this.setState({toggled: !this.state.toggled});
    }
  };

  render() {
    const disabled = this.props.disabled ? ` disabled` : ``;
    return (
      <div className={`toggle-ui-container${disabled}`}>
        {this.props.label && <Tt4a content={this.props.label} className="toggle-ui-container_label" />}
        <div className="toggle-ui-container_content" onClick={this.onToggle}>
          <div className="toggle-ui-container_content_slider">
            <div className={`toggle-ui-container_content_slider_circle${this.state.toggled ? ` checked` : ``}`} />
            <div className={`toggle-ui-container_content_slider_bar${this.state.toggled ? ` checked` : ``}`} />
          </div>
          <Tt2b
            content={this.state.toggled ? this.props.labels.enabled : this.props.labels.disabled}
            className="toggle-ui-container_content_text"
          />
        </div>
      </div>
    );
  }
}
