import * as React from 'react';
import {HeaderRow} from './components/HeaderRow/HeaderRow';
import {RemoteHeaderRow} from './components/HeaderRow/RemoteHeaderRow';
import {IMarkupEvent} from '../../../../../../../@types/markupEvent';
import {IFrameRate} from 'tt-components';
import {EventRow} from './components/EventRow';
import {Tt2b} from '../../../../../../components/Typography/Tt2b';
import {IParsedVTT} from '../../../../../../../@types/parsedVtt';
import {IMarkupsEventError} from '../../../../../../../@types/markupsError';

interface IEventsTableProps {
  events: Array<IMarkupEvent>;
  duration: number;
  selectedGroup: string;
  enableCategory?: boolean;
  enableNotes?: boolean;
  types: Array<string>;
  categories: Array<string>;
  frameRate: IFrameRate;
  jsonVTT: Array<IParsedVTT>;
  closestBody?: HTMLElement;
  onTime: (time: number) => void;
  removeMarkup: (eventId: string) => void;
  selectTab: (timeIn: string, timeOut: string) => void;
  updateEvent: (event: IMarkupEvent) => void;
  addEvent: (id: string, name: string, events: string) => void;
  tabsInEditMode: boolean;
  eventsErrors: Array<IMarkupsEventError>;
  getPlayerCurrentTime: () => number;
}

export class EventsTable extends React.PureComponent<IEventsTableProps> {
  getContent() {
    if (!this.props.events.length) {
      return <Tt2b content="There are no events to show" style={{color: '#fff'}} />;
    }

    return this.props.events.map((event: IMarkupEvent, index: number) => {
      const error = (this.props.eventsErrors || []).find((error: IMarkupsEventError) => error.id === event.id);
      return (
        <EventRow
          getPlayerCurrentTime={this.props.getPlayerCurrentTime}
          error={error}
          key={index}
          event={event}
          duration={this.props.duration}
          selectedGroup={this.props.selectedGroup}
          enableCategory={this.props.enableCategory}
          enableNotes={this.props.enableNotes}
          types={this.props.types}
          categories={this.props.categories}
          frameRate={this.props.frameRate}
          selectTab={this.props.selectTab}
          removeMarkup={this.props.removeMarkup}
          updateEvent={this.props.updateEvent}
          jsonVTT={this.props.jsonVTT}
          onTime={this.props.onTime}
          addEvent={this.props.addEvent}
          closestBody={this.props.closestBody}
          tabsInEditMode={this.props.tabsInEditMode}
        />
      );
    });
  }

  render() {
    const empty = !this.props.events.length ? ` empty` : ``;
    const isRemoteMarkup = ['Chapter', 'Textless'].indexOf(this.props.selectedGroup) !== -1;
    return (
      <div className={`events-table-container${isRemoteMarkup ? ` remote-content` : ``}`}>
        {isRemoteMarkup ? <RemoteHeaderRow /> : <HeaderRow />}
        <div className={`events-table-container_content${empty}`}>{this.getContent()}</div>
      </div>
    );
  }
}
