import * as React from 'react';
import {Container, Row, Column} from '../../../../../Grid';
import {Dropdown} from '../../../../../../../../components/Dropdown';
import {IPlaylistAtlas, IPlaylistTitleRecord} from '../../../../../../../../state/IAppState';
import {searchTitles, getTitleDataAPI, getTitleConformanceGroupsByVersion} from '../../../../../../../../data/atlasAPI';
import {ISearchTitleParams, ISearchTitle} from '../../../../../../../../../@types/searchTitle';
import {deepCopy, parseSeasonNumber, getUniqueArrayObjectsByField} from '../../../../../../utils/helpers';
import {PlaylistAsset} from '../../../../../../../../models/PlaylistAsset/PlaylistAsset';
import {IMetadataError} from '../../../../../../../../../@types/metadataErrors';

interface ITitleInfoProps {
  assetId: string;
  versionId: string;
  conformanceGroupId: string;
  titleId: string;
  closestBody?: HTMLElement;
  disabled: boolean;
  tabsContainer: HTMLElement;
  onTitleInfoUpated: (updatedTitles: Array<ISearchTitle>) => void;
  errors: Array<IMetadataError>;
}

interface ITitleInfoAsset extends IPlaylistAtlas {
  type?: string;
}

interface ITitleInfoState {
  conformanceGroups: Array<ISearchTitle>;
  versions: Array<ISearchTitle>;
  titleSearch: Array<ISearchTitle>;
  asset: ITitleInfoAsset;
  loading: boolean;
  loadingVersions: boolean;
  loadingConformanceGroups: boolean;
  searchString: string;
  filtering: boolean;
  seriesAsset: ISearchTitle;
  seasonAsset: ISearchTitle;
}

const mapToDropdownOptions = (record: ISearchTitle | IPlaylistTitleRecord) => ({
  label: `${record.name} (${record.hrId})`,
  value: record.id
});

const tmpSearchTitle = (id: string = '', type: string = ''): ISearchTitle =>
  ({
    id,
    hrId: '',
    name: '',
    type
  } as ISearchTitle);

export class TitleInfo extends React.Component<ITitleInfoProps, ITitleInfoState> {
  isComponentMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      conformanceGroups: [],
      versions: [],
      titleSearch: [],
      asset: null,
      loading: true,
      loadingVersions: true,
      loadingConformanceGroups: true,
      searchString: '',
      filtering: false,
      seriesAsset: tmpSearchTitle(),
      seasonAsset: tmpSearchTitle()
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  updateState = (stateObject, callBack: () => any = null) => {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(stateObject, callBack);
  };

  async componentDidUpdate(prevProps: ITitleInfoProps) {
    if (prevProps.titleId !== this.props.titleId) {
      await this.fetchTitleInit();
    } else if (prevProps.versionId !== this.props.versionId) {
      this.updateState({loadingConformanceGroups: true}, () => this.fetchConformanceGroups());
    }
  }

  init = () => {
    if (!this.props.titleId) {
      this.updateState({
        loading: false,
        loadingVersions: false,
        loadingConformanceGroups: false
      });
      return;
    }
    this.fetchTitleInit();
  };

  fetchTitleInit = () => {
    if (!this.props.titleId) {
      this.updateState({
        conformanceGroups: [],
        versions: [],
        titleSearch: [],
        asset: null,
        searchString: ''
      });
      return;
    }
    const params: ISearchTitleParams = {
      atlasId: this.props.titleId,
      types: 'Feature,Episode',
      size: 1
    };
    this.updateState({loading: true}, () => this.fetchTitle(params));
  };

  fetchTitle = async (params: ISearchTitleParams) => {
    const response = await searchTitles(params);
    const title = response.data.length ? response.data[0] : null;
    const titleSearch = getUniqueArrayObjectsByField([...this.state.titleSearch, title].filter(record => record), 'id');

    this.onTitleUpdate(title, titleSearch);
  };

  onTitleUpdate = (title: ISearchTitle, titleSearch: Array<ISearchTitle> = null, assetChanged: boolean = false) => {
    let asset: ITitleInfoAsset = null;
    let seasonAsset = tmpSearchTitle();
    let seriesAsset = tmpSearchTitle();

    if (title) {
      seasonAsset = title.ancestors
        ? title.ancestors.find((record: ISearchTitle) => record.type === 'Season')
        : seasonAsset;
      seriesAsset = title.ancestors
        ? title.ancestors.find((record: ISearchTitle) => record.type === 'Series')
        : seriesAsset;
      const assetData =
        title.type === 'Feature'
          ? {featureId: title.id, featureVersionId: ''}
          : {episodeId: title.id, seasonId: seasonAsset.id || '', seriesId: seriesAsset.id || ''};
      asset = {
        ...assetData,
        type: title.type,
        assetId: this.props.assetId
      };
    }

    const stateObject: any = {
      titleSearch: titleSearch || this.state.titleSearch,
      asset,
      loading: false,
      loadingVersions: !!asset,
      seriesAsset,
      seasonAsset
    };

    this.updateState(stateObject, () => {
      if (asset) {
        this.fetchVersions();
      }
    });
  };

  isEpisode = (asset: IPlaylistAtlas) => {
    return asset ? typeof asset.episodeId !== 'undefined' : false;
  };

  fetchVersions = async () => {
    const versionsAPI = await getTitleDataAPI(this.state.asset, 'Version');
    const versions = (versionsAPI.success ? (Array.isArray(versionsAPI.data) && versionsAPI.data) || [] : []).map(
      PlaylistAsset.parsing.parseSearchTitle
    );
    this.updateState({versions, loadingVersions: false, loadingConformanceGroups: true}, () =>
      this.fetchConformanceGroups()
    );
  };

  fetchConformanceGroups = async () => {
    const conformanceAPI = await getTitleConformanceGroupsByVersion(this.state.asset, this.props.versionId);
    const conformanceGroups = conformanceAPI.data;
    this.updateState({conformanceGroups, loadingConformanceGroups: false});
  };

  onSearch = async (searchString: string) => {
    this.updateState({searchString, filtering: true});
    const paramsHrId: ISearchTitleParams = {
      hrId: searchString,
      types: 'Feature,Episode',
      size: 50
    };
    const hrResults = await searchTitles(paramsHrId);
    const paramsName: ISearchTitleParams = {
      name: searchString,
      types: 'Feature,Episode',
      size: 50
    };
    const nameResults = await searchTitles(paramsName);

    const nameResultsId = deepCopy([...nameResults.data]).map((record: ISearchTitle) => record.id);
    const hrTitles = hrResults.data.filter((record: ISearchTitle) => {
      return nameResultsId.indexOf(record.id) === -1;
    });

    const selectedTitle = this.state.titleSearch.find((title: ISearchTitle) => title.id === this.props.titleId);
    const titleSearch = getUniqueArrayObjectsByField(
      [...hrTitles, ...nameResults.data, selectedTitle].filter(title => title),
      'id'
    );

    this.updateState({titleSearch, filtering: false});
  };

  updateTitle = (titleId: string) => {
    const title = this.state.titleSearch.find((record: ISearchTitle) => record.id === titleId);
    if (!title) {
      return;
    }
    const titles =
      title.type === 'Feature'
        ? [title]
        : [
            title,
            {...(title.ancestors.find((ancestor: ISearchTitle) => ancestor.type === 'Series') || tmpSearchTitle(''))},
            {...(title.ancestors.find((ancestor: ISearchTitle) => ancestor.type === 'Season') || tmpSearchTitle(''))}
          ];
    this.props.onTitleInfoUpated(titles.filter(record => record).filter((record: ISearchTitle) => record.id));
  };

  updateVersion = (versionId: string) => {
    const version = this.state.versions.find((record: ISearchTitle) => record.id === versionId);
    if (!version) {
      return;
    }
    const type = this.state.asset.type === 'Feature' ? 'FeatureVersion' : 'EpisodeVersion';
    const conformanceType = this.state.asset.type === 'Feature' ? 'FeatureConformance' : 'EpisodeConformance';
    this.props.onTitleInfoUpated([{...version, type}, tmpSearchTitle('', conformanceType)]);
  };

  updateConformanceGroup = (conformanceGroupId: string) => {
    const conformanceGroup = this.state.conformanceGroups.find(
      (record: ISearchTitle) => record.id === conformanceGroupId
    );
    if (!conformanceGroup) {
      return;
    }
    const type = this.state.asset.type === 'Feature' ? 'FeatureConformance' : 'EpisodeConformance';
    this.props.onTitleInfoUpated([{...conformanceGroup, type}]);
  };

  getVersionFieldError = () =>
    this.props.errors.find(
      (error: IMetadataError) =>
        ['UnregisteredAssetPatch.VersionId', 'AssetRegistrationPatch.VersionId'].indexOf(error.fieldName) !== -1
    );

  getEpisodeInfoRow = () => {
    const optionsSeries = this.state.seriesAsset.id
      ? [this.state.seriesAsset].map((record: ISearchTitle) => ({label: `${record.name}`, value: record.id}))
      : [];
    const placeholderSeries = !this.state.seriesAsset.id ? 'Serie not found' : 'Select...';

    const seasonNumber = parseSeasonNumber(this.state.seasonAsset.name);
    const optionsSeason = this.state.seasonAsset.id
      ? [this.state.seasonAsset].map((record: ISearchTitle) => ({
          label: `S${seasonNumber} - ${record.name}`,
          value: record.id
        }))
      : [];
    const placeholderSeason = !this.state.seasonAsset.id ? 'Season not found' : 'Select...';

    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={placeholderSeries}
            disabled
            search
            fixedButtonWidth
            label="Series Name"
            portalNode={this.props.closestBody}
            selected={this.state.seriesAsset.id}
            options={optionsSeries}
            onSelected={() => {}}
          />
        </Column>
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={placeholderSeason}
            disabled
            search
            fixedButtonWidth
            label="Season # / Name"
            portalNode={this.props.closestBody}
            selected={this.state.seasonAsset.id}
            options={optionsSeason}
            onSelected={() => {}}
          />
        </Column>
      </Row>
    );
  };

  getTitelRow = () => {
    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={this.state.loading ? 'Loading...' : 'Select...'}
            emptyPlaceholder={this.state.filtering ? 'Filtering...' : 'Empty'}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loading}
            search
            searchOnEnter
            allowOpenAbove={false}
            contentListLimit={5}
            options={this.state.titleSearch.map(mapToDropdownOptions)}
            selected={this.props.titleId}
            label="Title Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateTitle}
            onSearch={this.onSearch}
            searchValue={this.state.searchString}
            disableSearchInput={this.state.filtering}
          />
        </Column>
      </Row>
    );
  };

  getVersionConformanceRow = () => {
    const versionError = this.getVersionFieldError();
    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={this.state.loadingVersions ? 'Loading...' : 'Select...'}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loadingVersions}
            search
            contentListLimit={5}
            allowOpenAbove={false}
            options={this.state.versions.map(mapToDropdownOptions)}
            selected={this.props.versionId}
            label="Version Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateVersion}
            error={versionError ? `! ${versionError.message}` : false}
          />
        </Column>
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={this.state.loadingConformanceGroups ? 'Loading...' : 'Select...'}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loadingConformanceGroups}
            search
            contentListLimit={5}
            allowOpenAbove={false}
            options={this.state.conformanceGroups.map(mapToDropdownOptions)}
            selected={this.props.conformanceGroupId}
            label="Conformance Group Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateConformanceGroup}
          />
        </Column>
      </Row>
    );
  };

  render() {
    return (
      <Container className="title-info-container">
        {this.isEpisode(this.state.asset) && this.getEpisodeInfoRow()}
        {this.getTitelRow()}
        {this.getVersionConformanceRow()}
      </Container>
    );
  }
}
