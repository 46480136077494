import * as React from 'react';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails, IErrorLog, IAssetNonMedia} from '../../../../../@types/assetDetails';
import {IEnums} from '../../../../state/IEnums';
import {deepCopy} from '../../utils/helpers';
import {Tt2b} from '../../../../components/Typography';
import {NonMediaRecord} from './components/NonMediaRecord';

interface INonMediaProps {
  selectedAsset: PlaylistAsset;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

export class NonMedia extends React.PureComponent<INonMediaProps> {
  getAssetData = () => {
    const currentAssetDetails =
      this.props.selectedAsset && this.props.selectedAsset.assetDetails ? this.props.selectedAsset.assetDetails : {};
    const updatedAssetDetails = this.props.updatedAssetDetails || [];
    return deepCopy({...currentAssetDetails, ...updatedAssetDetails});
  };

  handlePropUpdate = (nonMediaId: string, prop: string, value: string | number) => {
    const currentAssetDetails = this.getAssetData();
    const nonMedia = (currentAssetDetails.nonMedia || []).reduce(
      (acc: Array<IAssetDetails>, nonMedia: IAssetNonMedia) => {
        if (nonMedia.id === nonMediaId) {
          nonMedia = {...nonMedia, [prop]: value};
        }
        return [...acc, nonMedia];
      },
      []
    );
    this.props.updatePartialAssetDetails({...this.props.updatedAssetDetails, nonMedia});
  };

  getContent = () => {
    const nonMedia = this.getAssetData().nonMedia || [];
    if (!nonMedia.length) {
      return (
        <Tt2b
          className="nonmedia-details-container_empty"
          content="No Non Media configurations are defined for this asset"
        />
      );
    }
    return nonMedia.reduce((acc: Array<JSX.Element>, nonMedia: IAssetNonMedia, index: number) => {
      const nonMediaElement = (
        <NonMediaRecord
          key={index}
          nonMedia={nonMedia}
          enums={this.props.enums}
          closestBody={this.props.closestBody}
          tabsDataInEditMode={this.props.tabsDataInEditMode}
          errorLogs={this.props.errorLogs}
          updateProp={this.handlePropUpdate}
        />
      );
      return [...acc, nonMediaElement];
    }, []);
  };

  render() {
    return <div className="nonmedia-details-container">{this.getContent()}</div>;
  }
}
