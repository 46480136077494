import * as React from 'react';
import {Dropdown} from 'tt-components/src/Dropdown';
import {Icon} from 'tt-components/src/Icon/Icon';

import {ImportantIcon} from '../../assets/Icons/Imporant';
import {IErrorLog} from '../../../@types/assetDetails';

interface IFloatingWarning {
  message: IErrorLog;
  closestBody?: HTMLElement;
}

export class FloatingWarning extends React.PureComponent<IFloatingWarning> {
  buttonRef: HTMLDivElement;

  getButtonComponent = () => (
    <div className="floating-warning-container_button" ref={node => (this.buttonRef = node)}>
      <Icon icon={ImportantIcon} size="15px" color="#F57617" />
    </div>
  );

  getContent = () => {
    const message = [this.props.message.message || 'Wrong enum value.'].map((token: string) => {
      if (!token.endsWith('.')) {
        return `${token}.`;
      }
      return token;
    });
    const attempted = this.props.message.attemptedValue ? `Attempted ${this.props.message.attemptedValue}.` : '';
    return (
      <div className="floating-warning-container_content">
        <p>{message}</p>
        {attempted && <p>{attempted}</p>}
      </div>
    );
  };

  onFloatingWarning = (style: React.CSSProperties) => {
    const buttonWidth = this.buttonRef ? this.buttonRef.getBoundingClientRect().width : 0;
    const marginLeft = -(+(style.width as number) / 2) + buttonWidth / 2;
    return {...style, marginLeft};
  };

  render() {
    return (
      <div className="floating-warning-container">
        <Dropdown
          style={contentStyle}
          portalNode={this.props.closestBody}
          buttonComponent={this.getButtonComponent}
          content={this.getContent()}
          onOpen={this.onFloatingWarning}
        />
      </div>
    );
  }
}

const contentStyle: React.CSSProperties = {
  padding: 0,
  marginLeft: 0,
  width: 130,
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  border: 'none'
};
