import * as React from 'react';
import {TimelineRow} from '../TimelineRow';
import {ThumbnailsLabel} from './components/ThumbnailsLabel';
import {ThumbnailsContent} from './components/ThumbnailsContent';
import {IParsedVTT} from '../../../../../@types/parsedVtt';

interface IThumbnailsLayerProps {
  secondUnits?: number;
  contentWidth: number;
  jsonVTT: IParsedVTT[];
  scrollLeft: number;
  scale: number;
}

export class ThumbnailsLayer extends React.Component<IThumbnailsLayerProps> {
  render() {
    const Label = <ThumbnailsLabel />;
    const Content = (
      <ThumbnailsContent
        jsonVTT={this.props.jsonVTT}
        secondUnits={this.props.secondUnits}
        scrollLeft={this.props.scrollLeft}
        contentWidth={this.props.contentWidth}
        scale={this.props.scale}
      />
    );

    return <TimelineRow labelElement={Label} contentElement={Content} />;
  }
}
