import {connect} from 'react-redux';
import {Timeline} from './components/Timeline';
import {IAppState} from '../../state/IAppState';
import {ITimelineOwnProps, ITimelineProps, ITimelineDispatchProps, ITimelineLayers} from './interfaces';
import {updateLayerVisibility} from './actions/timeline';
import {onChangeVisibleSubtitle, updateAudioConfig, updateAssetHiddenState} from '../../actions/video';
import {showConformanceOverlay} from '../../actions/overlays';
import {IVideoAudioConfiguration} from '../../state/IVideoState';

const mapStateToProps = (state: IAppState, ownProps: ITimelineOwnProps): ITimelineProps & ITimelineOwnProps => ({
  video: state.video,
  tabs: state.tabs,
  loadingPlayer: state.video.playlist.loading,
  loadingPlayerError: state.video.playlist.error,
  loadingAudioConfig: ownProps.loadingAudioConfig,
  checkPlaybackProxy: ownProps.checkPlaybackProxy,
  loadingAsset: ownProps.loadingAsset,
  curationModeEnabled: state.configuration.curationModeEnabled,
  configuration: {
    templateColors: state.configuration.templateColors,
    videoOptions: state.configuration.videoOptions,
    programTimingsInput: state.configuration.programTimingsInput,
    oneUiHost: state.configuration.oneUiHost,
    userEmail: state.configuration.userEmail,
    userIp: state.configuration.userIp
  },
  rootElementStyles: ownProps.rootElementStyles,
  timeline: state.timeline,
  secondUnits: ownProps.secondUnits || null,
  playerClock: ownProps.playerClock,
  onSeek: ownProps.onSeek,
  switchLoop: ownProps.switchLoop
});

const mapDispatchToProps = (dispatch): ITimelineDispatchProps => ({
  onChangeVisibleSubtitle: (subtitleId: string | number) => dispatch(onChangeVisibleSubtitle(subtitleId)),
  onUpdateLayerVisibility: (layer: ITimelineLayers, visible: boolean) =>
    dispatch(updateLayerVisibility({layer, visible})),
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) =>
    dispatch(updateAudioConfig(data, enableChannelsOnConfiguration)),
  updateAssetHiddenState: (assetId: string, isHidden: boolean) => dispatch(updateAssetHiddenState(assetId, isHidden)),
  showConformanceOverlay: (show: boolean) => dispatch(showConformanceOverlay(show))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timeline);
