import {BaseGAEvent} from './BaseGAEvent';
import {capitalizeFirstLetter} from '../../../utils/utils';

const gaTriggerEvent = 'playbackEvent';
const eventActions = {
  play: 'Play',
  pause: 'Pause',
  playbackSpeed: 'Playback Speed',
  frameForward: 'Frame Forward',
  frameBackward: 'Frame Backward',
  skipForward10: 'Skip Forward 10s',
  skipBackward10: 'Skip Backward 10s',
  fastForward: 'Fast Forward',
  fastRewind: 'Fast Rewind',
  scrubBar: 'Scrub Bar Update',
  goToLocation: 'Go To Location',
  loop: 'Loop',
  skipToBeginning: 'Skip To Beginning',
  fullScreen: 'Full Screen',
  externalSubtitle: 'External Subtitle',
  diagnosticPopup: 'Diagnostic Popup',
  keyboardShortcut: 'Keyboard Shortcut',
  keyboardShortcutsType: 'Keyboard Shortcuts Type',
  videoBuffering: 'Video Buffering',
  watermark: 'Watermark',
  timecodeDisplay: 'Timecode Display'
};

export type LoopMode = 'off' | 'entireVideo' | 'segmentVideo';

export class PlaybackEvents extends BaseGAEvent {
  constructor() {
    super(gaTriggerEvent, eventActions);
  }

  playEventDatalayer(eventLabel = '') {
    return this.createDatalayer({eventAction: this.eventActions.play, eventLabel});
  }

  pauseEventDatalayer(eventLabel = '') {
    return this.createDatalayer({eventAction: this.eventActions.pause, eventLabel});
  }

  playbackRateSpeedDatalayer(eventLabel = '', playbackSpeed) {
    return this.createDatalayer({
      eventAction: this.eventActions.playbackSpeed,
      eventLabel,
      eventValue: playbackSpeed
    });
  }

  frameForwardDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.frameForward,
      eventLabel
    });
  }

  frameBackwardDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.frameBackward,
      eventLabel
    });
  }

  skipForward10Datalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.skipForward10,
      eventLabel
    });
  }

  skipBackward10Datalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.skipBackward10,
      eventLabel
    });
  }

  fastForwardDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.fastForward,
      eventLabel
    });
  }

  fastRewindDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.fastRewind,
      eventLabel
    });
  }

  scrubBarUpdateDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.scrubBar,
      eventLabel
    });
  }

  goToLocationDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.goToLocation,
      eventLabel
    });
  }

  loopDatalayer(eventLabel = '', mode: LoopMode) {
    return this.createDatalayer({
      eventAction: `${this.eventActions.loop}:${capitalizeFirstLetter(mode)}`,
      eventLabel
    });
  }

  skipToBeginningDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.skipToBeginning,
      eventLabel
    });
  }

  fullScreenDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.fullScreen,
      eventLabel
    });
  }

  externalSubtitle(eventLabel = '', externalSubtitleLabel) {
    return this.createDatalayer({
      eventAction: `${this.eventActions.externalSubtitle}:${externalSubtitleLabel}`,
      eventLabel
    });
  }

  diagnosticPopupDatalayer(eventLabel = '') {
    return this.createDatalayer({
      eventAction: this.eventActions.diagnosticPopup,
      eventLabel
    });
  }

  keyboardShortcutDatalayer(eventLabel = '', shortcut) {
    return this.createDatalayer({
      eventAction: `${this.eventActions.keyboardShortcut}:${shortcut}`,
      eventLabel
    });
  }

  videoBufferingDatalayer(eventLabel = '', bufferTime: number) {
    return this.createDatalayer({
      eventAction: this.eventActions.videoBuffering,
      eventLabel,
      eventValue: bufferTime
    });
  }

  watermarkLocationDatalayer(eventLabel = '', location) {
    return this.createDatalayer({
      eventAction: `${this.eventActions.watermark}:${location}`,
      eventLabel
    });
  }

  keyboardShortcutsTypeDatalayer(eventLabel = '', shortcutType) {
    return this.createDatalayer({
      eventAction: `${this.eventActions.keyboardShortcutsType}:${shortcutType}`,
      eventLabel
    });
  }

  timecodeDisplayDatalayer(eventLabel = '', timecodeDisplay) {
    return this.createDatalayer({
      eventAction: `${this.eventActions.timecodeDisplay}:${timecodeDisplay}`,
      eventLabel
    });
  }
}
