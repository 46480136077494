import * as React from 'react';
import {TableRow, TableCell} from '../TableRow';
import {Tt4a} from '../../../../../../../../components/Typography/Tt4a';

const cells = [
  <TableCell key={1} content={<Tt4a style={{color: '#8D919A'}} content="Type / Category / Notes" />} />,
  <TableCell key={2} content={<Tt4a style={{color: '#8D919A'}} content="Timecode In" />} />,
  <TableCell key={3} content={<Tt4a style={{color: '#8D919A'}} content="Timecode Out" />} />,
  <TableCell className="header-row_action-cell" key={4} content={<Tt4a style={{color: '#8D919A'}} content="" />} />
];

export const HeaderRow = () => <TableRow className="header-row" cells={cells} />;
