import * as React from 'react';

interface ITableCellProps {
  content: string | JSX.Element;
  className?: string;
}

export const TableCell = (props: ITableCellProps) => (
  <div className={`table-row-container_cell${props.className ? ` ${props.className}` : ``}`}>{props.content}</div>
);

interface ITableRowProps {
  cells: Array<JSX.Element>;
  className?: string;
}

export const TableRow = (props: ITableRowProps) => (
  <div className={`table-row-container${props.className ? ` ${props.className}` : ``}`}>{props.cells}</div>
);
