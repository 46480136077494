import * as React from 'react';
import {Dropdown} from 'tt-components/src/Dropdown';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Tt2b, Tt1} from '../../../../../../components/Typography';

import {GuideDefaultIcon} from '../../../../../../assets/Icons/GuideDefault';
import {GuideActiveIcon} from '../../../../../../assets/Icons/GuideActive';

interface IGuideProps {
  closestBody?: HTMLElement;
}

interface IGuideState {
  opened: boolean;
}

export class Guide extends React.Component<IGuideProps, IGuideState> {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }
  getContent = () => {
    return (
      <div className="instructions-container_payload">
        <div className="instructions-container_payload_arrow" />
        <div className="instructions-container_payload_content">
          <Tt2b style={{color: '#FFF'}} content="Enter Timecode with a keyboard shortcut" />
          <div className="instructions-container_payload_content_buttons">
            <div className="instructions-container_payload_content_buttons_time">
              <Tt2b style={{color: '#FFB209'}} content="Time In" />
              {/* Replace button UI with the ones provided for the shortcuts view when ready */}
              <Tt1 className="instructions-container_payload_content_buttons_time_button" content="Alt + i" />
            </div>
            <div className="instructions-container_payload_content_buttons_time">
              <Tt2b style={{color: '#FFB209'}} content="Time Out" />
              {/* Replace button UI with the ones provided for the shortcuts view when ready */}
              <Tt1 className="instructions-container_payload_content_buttons_time_button" content="Alt + o" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  getIcon = () => (this.state.opened ? GuideActiveIcon : GuideDefaultIcon);

  onMouseIn = () => {
    this.setState({opened: true});
  };

  onMouseOut = () => {
    this.setState({opened: false});
  };

  render() {
    return (
      <div className="instructions-container">
        <Dropdown
          triggerDropAction="click"
          buttonComponent={() => <Icon icon={this.getIcon()} size="24px" />}
          style={contentStyle}
          content={this.getContent()}
          onMouseIn={this.onMouseIn}
          onMouseOut={this.onMouseOut}
          portalNode={this.props.closestBody}
        />
      </div>
    );
  }
}

const contentStyle: React.CSSProperties = {
  marginLeft: -29,
  padding: 0,
  width: 290,
  height: 184,
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  border: 'none'
};
