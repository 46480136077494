import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const QuickBackIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      viewBox="0 0 67 65"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-622.000000, -1756.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-quick-back" transform="translate(200.000000, 2.000000)">
                  <g>
                    <path
                      d="M33.1414286,-0.000306250032 C22.2215714,0.000459375048 11.5358571,5.27714743
                    5.25014286,14.9255547 C4.7358886,15.7150679 0.785714286,12.1146254 0.785714286,13.0336967
                    C0.785714286,15.2783011 0.785714286,22.970311 0.785714286,25.6779933 L13.5064286,22.1032554
                    C11.4318128,20.3771205 8.80707143,20.8943678 10.5568571,18.208555 C15.4864286,10.642648
                    23.9289286,6.12546002 33.1414286,6.12469439 C38.1959286,6.12469439 43.1357143,7.52885079
                    47.4280714,10.1840386 C59.8643571,17.8770394 63.558,33.9949786 55.6631429,46.1125267
                    C50.7335714,53.6784338 42.2910714,58.1956217 33.0785714,58.1956217 C28.0240714,58.1956217
                    23.0842857,56.792231 18.7919286,54.1370432 C16.7954286,52.9020899 15.0393571,51.4405116
                    13.5064286,49.8227458 L8.756,53.839215 C10.6817857,55.8918559 12.8975,57.7454342
                    15.4227857,59.308075 C20.9078571,62.7013253 27.0293571,64.3206224 33.0785714,64.3206224
                    C43.9984286,64.3206224 54.6841429,59.0431687 60.9698571,49.3955271 C70.7378571,34.4022911
                    66.1838571,14.5320234 50.7972143,5.01300678 C45.3121429,1.61975642 39.1906429,-0.000306250032
                    33.1414286,-0.000306250032 Z"
                      id="Fill-1"
                    />
                    <text id="10" fontFamily="Helvetica" fontSize="24" fontWeight="normal">
                      <tspan x="20" y="39.5600175">
                        10
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
