import {apiRequest} from './';
import {IResponse} from '../../@types/response';
import {parsOneCustomer} from '../utils/utils';

export const getCustomersListAPI = async (
  typeExternalId: 'content_owner' | 'content_provider' | 'distributor' = null,
  groupExternalId: string = null
): Promise<IResponse> => {
  try {
    const params = [
      `${groupExternalId ? `customer_group_external_id=${groupExternalId}` : ``}`,
      `${typeExternalId ? `customer_type_external_id=${typeExternalId}` : ``}`
    ].filter((param: string) => param);
    const query = params.length ? `?${params.join('&')}` : '';
    const url = `${appConfig.oneCustomerHost}customers${query}`;
    const response = await apiRequest('GET', url);
    return {success: true, data: response.map(parsOneCustomer)};
  } catch (error) {
    return {success: false, data: [], error};
  }
};
