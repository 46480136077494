import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const ExcelIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '14px'}
      height={props.size || '14px'}
      viewBox="0 0 41 41"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-4399.000000, -910.000000)" fill={props.color || '#1F72F2'} id="Info-Panel">
          <g transform="translate(0.000000, 367.000000)">
            <g id="Markup-Tab-Selected" transform="translate(2982.000000, 0.000000)">
              <g id="Markup-Set" transform="translate(0.000000, 82.000000)">
                <g id="Download-EDL" transform="translate(1362.000000, 74.000000)">
                  <g id="ic-microsoftexcel" transform="translate(55.000000, 387.000000)">
                    <g id="microsoftexcel">
                      <path
                        d="M40.236375,5.29925 L26.501375,5.29925 L26.501375,7.84125 L30.550125,7.84125
                        L30.550125,11.8626667 L26.501375,11.8626667 L26.501375,13.1439167
                        L30.550125,13.1439167 L30.550125,17.1704583 L26.501375,17.1704583
                        L26.501375,18.4875833 L30.550125,18.4875833 L30.550125,22.2954583
                        L26.501375,22.2954583 L26.501375,23.821 L30.550125,23.821 L30.550125,27.6374167
                        L26.501375,27.6374167 L26.501375,29.1629583 L30.550125,29.1629583
                        L30.550125,33.0015833 L26.501375,33.0015833 L26.501375,35.8015417
                        L40.236375,35.8015417 C40.4533333,35.736625 40.6344167,35.480375
                        40.779625,35.0362083 C40.9248333,34.588625 41,34.22475 41,33.9497083
                        L41,5.76391667 C41,5.54525 40.9248333,5.41370833 40.779625,5.36758333
                        C40.6344167,5.32316667 40.4533333,5.29925 40.236375,5.29925
                        Z M38.4545833,32.9981667 L31.8655417,32.9981667 L31.8655417,29.1629583
                        L38.4545833,29.1629583 L38.4545833,33.0015833 L38.4545833,32.9981667
                        Z M38.4545833,27.6374167 L31.8655417,27.6374167 L31.8655417,23.8192917
                        L38.4545833,23.8192917 L38.4545833,27.6374167 Z M38.4545833,22.29375
                        L31.8655417,22.29375 L31.8655417,18.5029583 L38.4545833,18.5029583
                        L38.4545833,22.2971667 L38.4545833,22.29375 Z M38.4545833,17.16875
                        L31.8655417,17.16875 L31.8655417,13.1473333 L38.4545833,13.1473333
                        L38.4545833,17.1704583 L38.4545833,17.16875 Z M38.4545833,11.8404583
                        L31.8655417,11.8404583 L31.8655417,7.84295833 L38.4545833,7.84295833
                        L38.4545833,11.8660833 L38.4545833,11.8404583 Z M0,4.66545833 L0,36.4421667
                        L24.19,40.625875 L24.19,0.374125 L0,4.679125 L0,4.66545833
                        Z M14.3380417,28.7034167 C14.2457917,28.454 13.811875,27.3948333
                        13.043125,25.5207917 C12.2760833,23.6484583 11.813125,22.5585417
                        11.6781667,22.247625 L11.6354583,22.247625 L9.0405,28.42325
                        L5.57258333,28.1892083 L9.68625,20.5017083 L5.919375,12.8142083
                        L9.455625,12.628 L11.792625,18.6430417 L11.83875,18.6430417
                        L14.478125,12.3546667 L18.13225,12.1240417 L13.781125,20.443625
                        L18.2655,28.930625 L14.3380417,28.7 L14.3380417,28.7034167 Z"
                        id="Shape"
                        fillRule="nonzero"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
