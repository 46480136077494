import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const HourGlassIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '18px'}
      height={props.size || '18px'}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill={props.color || '#FFFFFF'}
        d="M368 48h4c6.627 0 12-5.373 12-12V12c0-6.627-5.373-12-12-12H12C5.373 0 0 5.373 0 12v24c0 6.627 
        5.373 12 12 12h4c0 80.564 32.188 165.807 97.18 208C47.899 298.381 16 383.9 16 464h-4c-6.627 
        0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h360c6.627 0 
        12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-4c0-80.564-32.188-165.807-97.18-208C336.102 213.619 
        368 128.1 368 48zM64 48h256c0 101.62-57.307 184-128 184S64 149.621 64 48zm256 416H64c0-101.62 
        57.308-184 128-184s128 82.38 128 184z"
      />
    </svg>
  );
};
