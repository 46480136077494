import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const TimeInIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '14px'}
      height={props.size || '14px'}
      viewBox="0 0 44 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="One-Player-in-Compliance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="One-Player"
          transform="translate(-3236.000000, -1657.000000)"
          fill={props.color || '#ffffff'}
          fillRule="nonzero"
        >
          <g id="Info-Panel" transform="translate(0.000000, 325.000000)">
            <g id="Comments-Tab-Selected" transform="translate(3014.000000, 0.000000)">
              <g id="Comment-Input" transform="translate(0.000000, 1240.000000)">
                <g id="Timecode-In-Out" transform="translate(0.000000, 67.000000)">
                  <g id="sign-in-alt-solid" transform="translate(222.000000, 25.000000)">
                    <path
                      d="M35.75,31.5 L28.53125,31.5 C27.9640625,31.5 27.5,31.0570313 
                      27.5,30.515625 L27.5,27.234375 C27.5,26.6929688 27.9640625,26.25 
                      28.53125,26.25 L35.75,26.25 C37.2710937,26.25 38.5,25.0769531 
                      38.5,23.625 L38.5,7.875 C38.5,6.42304688 37.2710937,5.25 
                      35.75,5.25 L28.53125,5.25 C27.9640625,5.25 27.5,4.80703125 
                      27.5,4.265625 L27.5,0.984375 C27.5,0.44296875 27.9640625,0 
                      28.53125,0 L35.75,0 C40.3046875,0 44,3.52734375 44,7.875 
                      L44,23.625 C44,27.9726563 40.3046875,31.5 35.75,31.5 Z 
                      M31.7109375,15.0117188 L17.2734375,1.23046875 
                      C15.984375,6.66133815e-16 13.75,0.861328125 13.75,2.625 
                      L13.75,10.5 L2.0625,10.5 C0.91953125,10.5 0,11.3777344 0,12.46875 
                      L0,20.34375 C0,21.4347656 0.91953125,22.3125 2.0625,22.3125 
                      L13.75,22.3125 L13.75,30.1875 C13.75,31.9511719 15.984375,32.8125 
                      17.2734375,31.5820312 L31.7109375,17.8007812 C32.5101563,17.0296875 
                      32.5101563,15.7828125 31.7109375,15.0117188 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
