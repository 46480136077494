import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';

import {PlayheadIcon} from '../../../../assets/Icons/Playhead';

interface IPlayheadProps {
  leftOffset?: number;
  style?: React.CSSProperties;
  timelineRefElement: HTMLDivElement;
  onPositionChange: (position: number) => void;
}

interface IPlayheadState {
  drag: boolean;
}

export class Playhead extends React.PureComponent<IPlayheadProps, IPlayheadState> {
  playheadRefElement: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      drag: false
    };
  }

  componentDidMount() {
    this.initTimelineRefListeners(this.props.timelineRefElement);
    this.playheadRefElement.addEventListener('mousedown', this.onMouseDown);
    this.playheadRefElement.addEventListener('dragstart', this.onDragStart);
  }

  componentDidUpdate(prevProps: IPlayheadProps) {
    if (prevProps.timelineRefElement !== this.props.timelineRefElement && prevProps.timelineRefElement === null) {
      this.initTimelineRefListeners(this.props.timelineRefElement);
    }
  }

  componentWillUnmount() {
    this.removeTimelineRefListeners();
    this.playheadRefElement.removeEventListener('mousedown', this.onMouseDown);
    this.playheadRefElement.removeEventListener('dragstart', this.onDragStart);
  }

  initTimelineRefListeners = (timelineRefElement: HTMLDivElement) => {
    if (timelineRefElement) {
      timelineRefElement.addEventListener('mousemove', this.onMouseMove);
      timelineRefElement.addEventListener('mouseup', this.onMouseUp);
      timelineRefElement.addEventListener('dragend', this.onMouseUp);
    }
  };

  removeTimelineRefListeners = () => {
    if (this.props.timelineRefElement) {
      this.props.timelineRefElement.removeEventListener('mousemove', this.onMouseMove);
      this.props.timelineRefElement.removeEventListener('mouseup', this.onMouseUp);
      this.props.timelineRefElement.removeEventListener('dragend', this.onMouseUp);
    }
  };

  onMouseDown = e => {
    e.preventDefault();
    if (!this.state.drag) {
      this.setState({drag: true});
    }
  };

  onMouseMove = e => {
    e.preventDefault();
    if (this.state.drag) {
      this.props.onPositionChange(e.clientX);
    }
  };

  onMouseUp = e => {
    this.setState({drag: false});
  };

  onDragStart = e => {
    e.preventDefault();
  };

  definePlayheadStyle() {
    return {
      ...(this.props.style || {}),
      left: this.props.leftOffset || 0
    } as React.CSSProperties;
  }

  render() {
    const style = this.definePlayheadStyle();

    return (
      <div className="playhead-container" style={style} ref={node => (this.playheadRefElement = node)}>
        <div className="playhead-container_icon">
          <Icon icon={PlayheadIcon} />
        </div>
        <div className="playhead-container_marker" />
      </div>
    );
  }
}
