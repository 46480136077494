import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const GuideActiveIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(1.000000, 0.000000)" fillRule="nonzero">
          <path
            d="M11.5,4.0754717 C7.51468627,4.0754717 4.28431373,7.31954717 4.28431373,11.3207547 
            C4.28431373,14.5308679 6.36558824,17.2487547 9.24509804,18.2001509 L9.24509804,22.6415094 
            L10.1470588,22.6415094 L10.1470588,24 L12.8529412,24 L12.8529412,22.6415094 L13.754902,22.6415094 
            L13.754902,18.2001509 C16.6344118,17.2487547 18.7156863,14.5308679 18.7156863,11.3207547 
            C18.7156863,7.31954717 15.4853137,4.0754717 11.5,4.0754717 Z"
            fill="#EFCE4A"
          />
          <g fill="#EFCE4A">
            <path
              d="M11.5,0 C11.2506078,0 11.0490196,0.202415094 11.0490196,0.452830189 L11.0490196,2.26415094 
              C11.0490196,2.51456604 11.2506078,2.71698113 11.5,2.71698113 C11.7493922,2.71698113 11.9509804,
              2.51456604 11.9509804,2.26415094 L11.9509804,0.452830189 C11.9509804,0.202415094 11.7493922,0 11.5,0 Z"
            />
            <path
              d="M22.3235294,10.8679245 L20.5196078,10.8679245 C20.2702157,10.8679245 20.0686275,11.0703396 
              20.0686275,11.3207547 C20.0686275,11.5711698 20.2702157,11.7735849 20.5196078,11.7735849 L22.3235294,
              11.7735849 C22.5729216,11.7735849 22.7745098,11.5711698 22.7745098,11.3207547 C22.7745098,11.0703396 
              22.5729216,10.8679245 22.3235294,10.8679245 Z"
            />
            <path
              d="M2.48039216,10.8679245 L0.676470588,10.8679245 C0.427078431,10.8679245 0.225490196,11.0703396 
              0.225490196,11.3207547 C0.225490196,11.5711698 0.427078431,11.7735849 0.676470588,11.7735849 
              L2.48039216,11.7735849 C2.72978431,11.7735849 2.93137255,11.5711698 2.93137255,11.3207547 C2.93137255,
              11.0703396 2.72978431,10.8679245 2.48039216,10.8679245 Z"
            />
            <path
              d="M18.8347451,3.31562264 L17.5593725,4.59622642 C17.3830392,4.77328302 17.3830392,5.0594717 17.5593725,
              5.2365283 C17.6473137,5.32483019 17.7627647,5.36920755 17.8782157,5.36920755 C17.9936667,5.36920755 
              18.1091176,5.32483019 18.1970588,5.2365283 L19.4724314,3.95592453 C19.6487647,3.77886792 19.6487647,
              3.49267925 19.4724314,3.31562264 C19.296098,3.13856604 19.0106275,3.13901887 18.8347451,3.31562264 Z"
            />
            <path
              d="M4.80294118,17.4049811 L3.52756863,18.6855849 C3.35123529,18.8626415 3.35123529,19.1488302 
              3.52756863,19.3258868 C3.6155098,19.4141887 3.73096078,19.458566 3.84641176,19.458566 C3.96186275,
              19.458566 4.07731373,19.4141887 4.1652549,19.3258868 L5.44062745,18.045283 C5.61696078,17.8682264 
              5.61696078,17.5820377 5.44062745,17.4049811 C5.26429412,17.2279245 4.97927451,17.2279245 4.80294118,
              17.4049811 Z"
            />
            <path
              d="M18.1970588,17.4049811 C18.0207255,17.2279245 17.7357059,17.2279245 17.5593725,17.4049811 C17.3830392,
              17.5820377 17.3830392,17.8682264 17.5593725,18.045283 L18.8347451,19.3258868 C18.9226863,19.4141887 
              19.0381373,19.458566 19.1535882,19.458566 C19.2690392,19.458566 19.3844902,19.4141887 19.4724314,
              19.3258868 C19.6487647,19.1488302 19.6487647,18.8626415 19.4724314,18.6855849 L18.1970588,17.4049811 Z"
            />
            <path
              d="M4.1652549,3.31562264 C3.98892157,3.13856604 3.70390196,3.13856604 3.52756863,3.31562264 C3.35123529,
              3.49267925 3.35123529,3.77886792 3.52756863,3.95592453 L4.80294118,5.2365283 C4.89088235,5.32483019 
              5.00633333,5.36920755 5.12178431,5.36920755 C5.23723529,5.36920755 5.35268627,5.32483019 5.44062745,
              5.2365283 C5.61696078,5.0594717 5.61696078,4.77328302 5.44062745,4.59622642 L4.1652549,3.31562264 Z"
            />
          </g>
          <path
            d="M6.53921569,11.7735849 C6.28982353,11.7735849 6.08823529,11.5711698 6.08823529,11.3207547 
            C6.08823529,8.32437736 8.51586275,5.88679245 11.5,5.88679245 C11.7493922,5.88679245 11.9509804,6.08920755 
            11.9509804,6.33962264 C11.9509804,6.59003774 11.7493922,6.79245283 11.5,6.79245283 C9.01329412,6.79245283 
            6.99019608,8.82384906 6.99019608,11.3207547 C6.99019608,11.5711698 6.78860784,11.7735849 6.53921569,
            11.7735849 Z"
            fill="#F7E6A1"
          />
          <polygon
            fill="#556080"
            points="9.24509804 19.4716981 9.24509804 22.6415094 10.1470588 22.6415094 10.1470588 24 12.8529412 
            24 12.8529412 22.6415094 13.754902 22.6415094 13.754902 19.4716981"
          />
        </g>
      </g>
    </svg>
  );
};
