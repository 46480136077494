import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const TacometrIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      viewBox="0 0 70 60"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1647.000000, -1759.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-tacometer" transform="translate(1225.000000, 4.000000)">
                  <g transform="translate(0.000000, 0.366216)">
                    <path
                      d="M29.5852865,29.4918185 L21.2670034,21.1735354 C20.5380247,20.4445567 20.5380247,19.2626487
                      21.2670034,18.53367 C21.9959821,17.8046914 23.17789,17.8046914 23.9068687,18.53367 L32.2251518,
                      26.8519531 C33.1494656,26.3510872 34.2081845,26.0666667 35.3333333,26.0666667 C38.9415937,
                      26.0666667 41.8666667,28.9917396 41.8666667,32.6 C41.8666667,36.2082604 38.9415937,
                      39.1333333 35.3333333,39.1333333 C31.725073,39.1333333 28.8,36.2082604 28.8,32.6 C28.8,
                      31.4748512 29.0844205,30.4161323 29.5852865,29.4918185 Z"
                      id="Dial"
                    />
                    <path
                      d="M16.6298553,59.7333333 C13.6847143,59.7333333 10.9631127,58.7590823 8.62593626,
                      56.964752 C6.6029117,55.411607 4.92302131,53.2915821 3.58563937,
                      50.7423504 C1.25567764,46.3011268 0,40.5770426 0,34.9713591 C0,15.6514521 15.6757349,0 35,
                      0 C54.3242651,0 70,15.6514521 70,34.9713591 C70,41.0901481 68.7396649,46.8315799 66.2522013,
                      51.2160249 C64.8219188,53.7370647 62.9957782,55.7988247 60.7834969,57.2728583 C58.3590445,
                      58.8882606 55.5610489,59.7333333 52.5239192,59.7333333 L16.6298553,59.7333333 Z M52.5239192,
                      50.4132388 C54.8887788,50.4132388 56.7140245,49.1970853 58.1856242,46.6032189 C59.8000886,
                      43.757537 60.7166778,39.582033 60.7166778,34.9713591 C60.7166778,20.8103156 49.2086301,
                      9.32009456 35,9.32009456 C20.7913699,9.32009456 9.28332217,20.8103156 9.28332217,34.9713591
                      C9.28332217,39.1360386 10.2134887,43.3762602 11.7993205,46.399071 C12.546263,47.822845
                      13.3936048,48.8921919 14.2650875,49.561259 C15.0226972,50.1429018 15.7778921,50.4132388
                      16.6298553,50.4132388 L52.5239192,50.4132388 Z"
                      id="Oval"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
