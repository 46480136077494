import * as React from 'react';
import {ITemplateColors} from '../../../@types/templateColors';
import {IconVideoNotFound} from '../../assets/Icons/IconVideoNotFound';

interface INFProps {
  templateColors: ITemplateColors;
}

function notFond(props: INFProps) {
  return (
    <div className="video-not-found-overlay">
      <IconVideoNotFound
        iconColor={props.templateColors.highlight}
        iconStyle={{
          height: '90px',
          marginBottom: '6px'
        }}
        textColor={props.templateColors.main}
        text={'Video Not Found'}
      />
    </div>
  );
}

export default React.memo(notFond);
