import * as React from 'react';
import {TimelineRow} from '../TimelineRow';
import {Tt5} from '../../../../components/Typography/Tt5';
import {TmpElement} from '../TmpElement';
import {IAudioChannelConfiguration, IAudioChannel} from '../../../../../@types/audioChannelConfiguration';
import {IEnum} from '../../../../../@types/enum';
import {IVideoAudioConfiguration} from '../../../../state/IVideoState';
import {ITemplateColors} from '../../../../../@types/templateColors';
import {AudioAssetsList} from '../../../../components/OnePlayerControlBar/AudioAndSubs/AudioAssetsList';
import {AudioLayerLabel} from './components/AudioLayerLabel';
import {AudioLayerContent} from './components/AudioLayerContent';
import {deepCopy} from '../../../Tabs/utils/helpers';

interface IAudioLayerProps {
  assetId: string;
  configurations: Array<IAudioChannelConfiguration>;
  audioLanguageEnums: Array<IEnum>;
  audioChannelMapEnums: Array<IEnum>;
  audioChannelConfigTypeEnums: Array<IEnum>;
  audioConfiguration: IVideoAudioConfiguration;
  templateColors: ITemplateColors;
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) => void;
  loadingPlaylist: boolean;
  loadingPlaylistError: string;
  loadingAudioConfig: boolean;
  checkPlaybackProxy: () => void;
  userEmail: string;
  userIp: string;
  contentWidth: number;
  scrollLeft: number;
  duration: number;
  curationModeEnabled: boolean;
}

interface IAudioLayerState {
  openedConfiguration: {};
}

export class AudioLayer extends React.PureComponent<IAudioLayerProps, IAudioLayerState> {
  constructor(props) {
    super(props);
    this.state = {
      openedConfiguration: {}
    };
  }

  placeholderField = (content: string = '') => (
    <TimelineRow
      labelElement={<Tt5 content="Audio" className="audiolayer-container_label aligned" />}
      contentElement={<TmpElement text={content} />}
    />
  );

  onOpenedConfiguration = (conf: string) => {
    const openedConfiguration = deepCopy({...this.state.openedConfiguration});
    openedConfiguration[conf] = openedConfiguration[conf] === conf ? '' : conf;
    this.setState({openedConfiguration});
  };

  getAudioConfigurationsList = () => {
    const {openedConfiguration} = this.state;
    return this.props.configurations.reduce(
      (acc: Array<IAudioChannelConfiguration>, config: IAudioChannelConfiguration, index: number) => {
        const AudioConfiguration = (
          <AudioAssetsList
            componentIdentifier={`${this.props.assetId}_timeline`}
            mergeLists
            audioLanguageEnums={this.props.audioLanguageEnums}
            audioChannelMapEnums={this.props.audioChannelMapEnums}
            embeddedAudioChannels={[config]}
            externalAudioChannels={[]}
            templateColors={this.props.templateColors}
            audioChannelConfigTypeEnums={this.props.audioChannelConfigTypeEnums}
            audioConfiguration={this.props.audioConfiguration}
            updateAudioConfig={this.props.updateAudioConfig}
            loadingPlaylist={this.props.loadingPlaylist}
            loadingPlaylistError={this.props.loadingPlaylistError}
            loadingAudioConfig={this.props.loadingAudioConfig}
            checkPlaybackProxy={this.props.checkPlaybackProxy}
            proxyConfiguration={null}
            userEmail={this.props.userEmail}
            userIp={this.props.userIp}
            onOpenedConfiguration={this.onOpenedConfiguration}
            useVisibilityCheckbox={true}
            curationModeEnabled={this.props.curationModeEnabled}
          />
        );
        const isOpenedConfig = openedConfiguration && openedConfiguration[config.id] === config.id;
        const Content = isOpenedConfig ? this.getWaveformsContent(config) : null;

        const Row = (
          <TimelineRow
            key={index}
            labelElement={<AudioLayerLabel audioAndSubsElement={AudioConfiguration} />}
            contentElement={<div className="audiolayer-content-container">{Content}</div>}
          />
        );
        return [...acc, Row];
      },
      []
    );
  };

  getWaveformsContent = (config: IAudioChannelConfiguration) => {
    return config.trackDetail.reduce((acc: IAudioChannel[], track: IAudioChannel, index: number) => {
      const Content = (
        <AudioLayerContent
          key={index}
          width={this.props.contentWidth}
          scrollLeft={this.props.scrollLeft}
          duration={this.props.duration}
          url={track.streamInFile}
        />
      );
      return [...acc, Content];
    }, []);
  };

  getContent = () => {
    const isEmpty = !this.props.configurations.length;
    if (isEmpty) {
      return this.placeholderField('No audio configurations to list for this asset');
    } else {
      return (
        <>
          {this.placeholderField()}
          {this.getAudioConfigurationsList()}
        </>
      );
    }
  };

  render() {
    return <div className="audiolayer-container">{this.getContent()}</div>;
  }
}
