import * as React from 'react';
import {IOnePlayerControlBarProps, SeekType} from './onePlayerControlBarProps';
import {Icon, Icons} from 'tt-components/src/Icon';
import {SpeedRate} from './SpeedRate';
import {GoToLocation} from './GoToLocation';
import {LoopControlBar} from './LoopControlBar';
import {VolumeControl} from './VolumeControl';
import {AudioAndSubs} from './AudioAndSubs';
import {GeneralSettings} from './GeneralSettings';
import {SeekBar} from 'tt-components/src/VideoPlayer/ControlBarSeekBar';

import {FrameAdvanceIcon} from '../../assets/Icons/FrameAdvance';
import {FrameRewindIcon} from '../../assets/Icons/FrameRewind';
import {QuickBackIcon} from '../../assets/Icons/Quick-back';
import {QuickFwdIcon} from '../../assets/Icons/Quick-fwd';
import {FullScreenIcon} from '../../assets/Icons/FullScreen';
import {EmbeddedScreenIcon} from '../../assets/Icons/EmbeddedScreen';
import {PlayIcon} from '../../assets/Icons/Play';
import {RestartIcon} from '../../assets/Icons/Restart';
import {FastRewindIcon} from '../../assets/Icons/FastRewind';
import {FastFwdIcon} from '../../assets/Icons/FastFwd';
import {EndIcon} from '../../assets/Icons/End';
import {title} from '../../utils/utils';
import {MediaDisplayPopup} from './MediaDisplayPopup';

const buttonLabels = {
  goToBeginning: 'Go to Beginning',
  fastRewind: 'Fast Rewind',
  backward: 'Skip Back 10s',
  frameRewind: 'Frame Rewind',
  pause: 'Pause',
  play: 'Play',
  frameAdvance: 'Frame Advance',
  forward: 'Skip Forward 10s',
  fastForward: 'Fast Forward',
  goToEnd: 'Go to End',
  goToLoc: 'Go To Location',
  loopControl: 'Loop Controls',
  playControl: 'Playback Speed Controls',
  settings: 'Settings',
  subs: 'Subs & Audio'
};
export class OnePlayerControlBar extends React.PureComponent<IOnePlayerControlBarProps> {
  readonly handle = {
    onSeekBar: time => this.props.enabled && this.props.onSeek(time, SeekType.toTime, true),
    onGoTo: time => this.props.enabled && this.props.onGoTo(time),
    onPrevTenSecond: () => this.onButtonSeek(-10, SeekType.timeLeap),
    onNextTenSecond: () => this.onButtonSeek(10, SeekType.timeLeap),
    onStart: () => this.onButtonToStart(),
    onEnd: () => this.onButtonToEnd(),
    onPrevFrame: () => {
      const {playing, onSwitchPlaying} = this.props;
      if (playing) {
        onSwitchPlaying();
      }
      this.onButtonSeek(-1, SeekType.frameLeap);
    },
    onNextFrame: () => {
      const {playing, onSwitchPlaying} = this.props;
      if (playing) {
        onSwitchPlaying();
      }
      this.onButtonSeek(1, SeekType.frameLeap);
    },
    onFullScreen: () => this.onFullScreen(),
    onFastBackward: () => this.onFastBackward(),
    onFastForward: () => this.onFastForward()
  };

  onButtonSeek(offset: number, type: SeekType) {
    if (this.props.enabled) {
      this.props.onSeek(offset, type);
    }
  }

  onButtonToStart() {
    this.props.onStart();
  }

  onButtonToEnd() {
    this.props.onEnd();
  }

  onFastBackward() {
    this.props.onFastBackward();
  }

  onFastForward() {
    this.props.onFastForward();
  }

  onGoTo = time => {
    this.handle.onGoTo(time);
  };

  onFullScreen() {
    const {isFullScreen} = this.props;
    this.props.onFullScreen(!isFullScreen);
  }

  onSeekBarMovement(timePosition: number, cX: number = null, width: number = null, left: number = null) {
    if (this.props.onSeekBarMovement) {
      this.props.onSeekBarMovement(timePosition, cX, width, left);
    }
  }

  onSeekBarMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => {
    this.onSeekBarMovement(null);
  };

  onSeekBarMouseMove = (e: React.MouseEvent<HTMLInputElement>) => {
    const rangeElement = e.target as HTMLInputElement;
    const {left, width} = rangeElement.getBoundingClientRect();
    const relativePosition = e.clientX - left;
    const timePosition = (relativePosition / width) * this.props.duration;
    this.onSeekBarMovement(timePosition, relativePosition, width, left);
  };

  onVideoClick = e => {
    //ignore click on subtitle
    if (
      e.target.closest('.video-subtitle-grid td') //CCRenderer
    ) {
      return;
    }

    const {onSwitchPlaying} = this.props;
    onSwitchPlaying();
  };

  render() {
    const {
      enabled,
      videoClock,
      duration,
      frameRate,
      playing,
      isFullScreen,
      loop,
      getShortcuts,
      setDefaultShortcuts,
      changeShortcuts,
      keyboardShortcuts,
      keyboardShortcutsType,
      volume,
      mute,
      switchLoop,
      onSwitchPlaying,
      onMuteChanged,
      onVolumeChanged,
      onPlaybackRateChange,
      onForwardAndRewindRateChange,
      playbackRate,
      forwardAndRewindRate,
      templateColors,
      changeMediaTimeFormat,
      displayMediaTimeFormat,
      watermarkPosition,
      changeWatermarkPosition,
      isControlBarShown,
      controlBarControls,
      showingDropdownTimeout,
      videoPlayerContainer,
      closestBodyElement,
      audioChannelConfigurations,
      audioLanguageEnums,
      audioChannelMapEnums,
      audioChannelConfigTypeEnums,
      languageDialectEnums,
      externalAudioChannelConfigurations,
      checkPlaybackProxy,
      audioConfiguration,
      updateAudioConfig,
      loadingPlaylist,
      loadingPlaylistError,
      loadingAsset,
      loadingAudioConfig,
      embeddedSubtitlesMetadata,
      externalSubtitlesMetadata,
      proxyConfiguration,
      videoConfiguration,
      isSettingsShow,
      onShowSettingsChange,
      isAudioSubsShow,
      onShowAudioSubsChange,
      isPlayerSpeedShow,
      onShowPlayerSpeedChange,
      isLoopControlShow,
      onShowLoopControlChange,
      isVolumeControlShow,
      onShowVolumeControlChange,
      isGoToShow,
      onShowGoToChange,
      curationModeEnabled,
      videoStartTime
    } = this.props;
    const isDropDownOpen =
      isSettingsShow || isAudioSubsShow || isPlayerSpeedShow || isLoopControlShow || isVolumeControlShow || isGoToShow;
    return (
      <div className={'op-control-bar ' + (isControlBarShown || isDropDownOpen ? '' : 'op_controls-bar_hidden')}>
        <div className="op_control-bar_bg" onClick={this.onVideoClick} />
        <div className="op-control-bar-left-time">
          <MediaDisplayPopup
            videoClock={videoClock}
            enabled={enabled}
            disabled={loadingPlaylist || loadingAsset}
            templateColors={templateColors}
            isControlBarShown={isControlBarShown}
            showingDropdownTimeout={showingDropdownTimeout}
            isFullScreen={isFullScreen}
            videoPlayerContainer={videoPlayerContainer}
            closestBodyElement={closestBodyElement}
            displayMediaTimeFormat={displayMediaTimeFormat}
            changeMediaTimeFormat={changeMediaTimeFormat}
            frameRate={frameRate}
          />
        </div>
        <div className={`op_seek-bar${loadingPlaylist || loadingAsset ? ` disabled` : ``}`}>
          <SeekBar
            duration={duration}
            clock={videoClock}
            onChange={this.handle.onSeekBar}
            onMouseLeave={this.onSeekBarMouseLeave}
            onMouseMove={this.onSeekBarMouseMove}
            color={templateColors.highlight}
          />
        </div>
        <div className="op-control-bar-button-bar">
          <div className="op-control-bar-left-button-bar">
            {controlBarControls.includes('GoToBeginning') ? (
              <div {...title(buttonLabels['goToBeginning'], enabled)} onClick={this.handle.onStart}>
                <Icon icon={RestartIcon} color={templateColors.main} size="16px" />
              </div>
            ) : null}

            {controlBarControls.includes('FastRewind') ? (
              <div {...title(buttonLabels['fastRewind'], enabled)} onClick={this.handle.onFastBackward}>
                <Icon icon={FastRewindIcon} color={templateColors.main} size="16px" />
              </div>
            ) : null}

            {controlBarControls.includes('QuickBack') ? (
              <div {...title(buttonLabels['backward'], enabled)} onClick={this.handle.onPrevTenSecond}>
                <Icon icon={QuickBackIcon} color={templateColors.main} size="25px" />
              </div>
            ) : null}

            {controlBarControls.includes('FrameRewind') ? (
              <div {...title(buttonLabels['frameRewind'], enabled)} onClick={this.handle.onPrevFrame}>
                <Icon icon={FrameRewindIcon} color={templateColors.main} size="17px" />
              </div>
            ) : null}

            {controlBarControls.includes('Play') ? (
              <div
                {...title(`${playing ? buttonLabels['pause'] : buttonLabels['play']}`, enabled)}
                onMouseDown={e => e.preventDefault()}
                onClick={
                  enabled
                    ? e => {
                        onSwitchPlaying();
                      }
                    : null
                }
              >
                {playing ? (
                  <Icon icon={Icons.FaPause} color={templateColors.main} size="16px" />
                ) : (
                  <Icon icon={PlayIcon} color={templateColors.main} size="16px" />
                )}
              </div>
            ) : null}

            {controlBarControls.includes('FrameAdvance') ? (
              <div {...title(buttonLabels['frameAdvance'], enabled)} onClick={this.handle.onNextFrame}>
                <Icon icon={FrameAdvanceIcon} color={templateColors.main} size="17px" />
              </div>
            ) : null}

            {controlBarControls.includes('QuickFwd') ? (
              <div {...title(buttonLabels['forward'], enabled)} onClick={this.handle.onNextTenSecond}>
                <Icon icon={QuickFwdIcon} color={templateColors.main} size="25px" />
              </div>
            ) : null}

            {controlBarControls.includes('FastFwd') ? (
              <div {...title(buttonLabels['fastForward'], enabled)} onClick={this.handle.onFastForward}>
                <Icon icon={FastFwdIcon} color={templateColors.main} size="16px" />
              </div>
            ) : null}

            {controlBarControls.includes('GoToEnd') ? (
              <div {...title(buttonLabels['goToEnd'], enabled)} onClick={this.handle.onEnd}>
                <Icon icon={EndIcon} color={templateColors.main} size="16px" />
              </div>
            ) : null}

            {controlBarControls.includes('GoToLocation') ? (
              <GoToLocation
                disabled={loadingPlaylist || loadingAsset}
                onGoTo={this.onGoTo}
                isOpen={isGoToShow}
                onShowGoToChange={onShowGoToChange}
                templateColors={templateColors}
                frameRate={frameRate}
                maxTime={duration}
                isControlBarShown={isControlBarShown}
                showingDropdownTimeout={showingDropdownTimeout}
                isFullScreen={isFullScreen}
                videoPlayerContainer={videoPlayerContainer}
                closestBodyElement={closestBodyElement}
                displayMediaTimeFormat={displayMediaTimeFormat}
                videoStartTime={videoStartTime}
              />
            ) : null}

            {controlBarControls.includes('Loop') ? (
              <LoopControlBar
                disabled={loadingPlaylist || loadingAsset}
                isOpen={isLoopControlShow}
                onShowLoopControlChange={onShowLoopControlChange}
                templateColors={templateColors}
                frameRate={frameRate}
                loop={loop}
                maxTime={duration}
                onSwitchLoop={switchLoop}
                isControlBarShown={isControlBarShown}
                showingDropdownTimeout={showingDropdownTimeout}
                isFullScreen={isFullScreen}
                videoPlayerContainer={videoPlayerContainer}
                closestBodyElement={closestBodyElement}
                displayMediaTimeFormat={displayMediaTimeFormat}
                videoStartTime={videoStartTime}
              />
            ) : null}

            {controlBarControls.includes('Loop') ? (
              <SpeedRate
                disabled={loadingPlaylist || loadingAsset}
                isOpen={isPlayerSpeedShow}
                onShowPlayerSpeedChange={onShowPlayerSpeedChange}
                playbackRate={playbackRate}
                forwardAndRewindRate={forwardAndRewindRate}
                onPlaybackRateChange={onPlaybackRateChange}
                onForwardAndRewindRateChange={onForwardAndRewindRateChange}
                templateColors={templateColors}
                isControlBarShown={isControlBarShown}
                showingDropdownTimeout={showingDropdownTimeout}
                isFullScreen={isFullScreen}
                videoPlayerContainer={videoPlayerContainer}
                closestBodyElement={closestBodyElement}
              />
            ) : null}
          </div>
          <div className="op-control-bar-right-button-bar">
            {controlBarControls.includes('Volume') ? (
              <VolumeControl
                disabled={loadingPlaylist || loadingAsset}
                enabled={enabled}
                isOpen={isVolumeControlShow}
                onShowVolumeControlChange={onShowVolumeControlChange}
                mute={mute}
                volume={volume}
                showingDropdownTimeout={showingDropdownTimeout}
                templateColors={templateColors}
                isControlBarShown={isControlBarShown}
                isFullScreen={isFullScreen}
                videoPlayerContainer={videoPlayerContainer}
                closestBodyElement={closestBodyElement}
                onVolumeChanged={onVolumeChanged}
                onMuteChanged={onMuteChanged}
              />
            ) : null}

            {controlBarControls.includes('GeneralSettings') ? (
              <GeneralSettings
                disabled={loadingPlaylist || loadingAsset}
                isOpen={isSettingsShow}
                onShowSettingsChange={onShowSettingsChange}
                getShortcuts={getShortcuts}
                setDefaultShortcuts={setDefaultShortcuts}
                changeShortcuts={changeShortcuts}
                keyboardShortcuts={keyboardShortcuts}
                templateColors={templateColors}
                watermarkPosition={watermarkPosition}
                changeWatermarkPosition={changeWatermarkPosition}
                isControlBarShown={isControlBarShown}
                showingDropdownTimeout={showingDropdownTimeout}
                isFullScreen={isFullScreen}
                videoPlayerContainer={videoPlayerContainer}
                closestBodyElement={closestBodyElement}
                keyboardShortcutsType={keyboardShortcutsType}
              />
            ) : null}

            {controlBarControls.includes('AudioAndSubs') ? (
              <AudioAndSubs
                disabled={loadingPlaylist}
                isOpen={isAudioSubsShow}
                onShowAudioSubsChange={onShowAudioSubsChange}
                onSubSelected={this.props.onChangeVisibleSubtitle}
                selectedSub={this.props.selectedSub}
                embeddedSubtitlesMetadata={embeddedSubtitlesMetadata}
                externalSubtitlesMetadata={externalSubtitlesMetadata}
                templateColors={templateColors}
                isControlBarShown={isControlBarShown}
                showingDropdownTimeout={showingDropdownTimeout}
                isFullScreen={isFullScreen}
                videoPlayerContainer={videoPlayerContainer}
                closestBodyElement={closestBodyElement}
                audioLanguageEnums={audioLanguageEnums}
                audioChannelMapEnums={audioChannelMapEnums}
                audioChannelConfigTypeEnums={audioChannelConfigTypeEnums}
                languageDialectEnums={languageDialectEnums}
                audioChannelConfigurations={audioChannelConfigurations}
                externalAudioChannelConfigurations={externalAudioChannelConfigurations}
                checkPlaybackProxy={checkPlaybackProxy}
                audioConfiguration={audioConfiguration}
                updateAudioConfig={updateAudioConfig}
                loadingPlaylist={loadingPlaylist}
                loadingPlaylistError={loadingPlaylistError}
                loadingAudioConfig={loadingAudioConfig}
                proxyConfiguration={proxyConfiguration}
                videoConfiguration={videoConfiguration}
                userEmail={this.props.userEmail}
                userIp={this.props.userIp}
                curationModeEnabled={curationModeEnabled}
              />
            ) : null}

            {controlBarControls.includes('FullScreenMode') ? (
              <div
                {...title(`${isFullScreen ? 'Exit Full Screen' : 'Full Screen'}`, enabled)}
                onClick={this.handle.onFullScreen}
              >
                {isFullScreen ? (
                  <Icon icon={EmbeddedScreenIcon} color={templateColors.main} size="19px" />
                ) : (
                  <Icon icon={FullScreenIcon} color={templateColors.main} size="19px" />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
