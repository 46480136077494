import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const EditIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '20px'}
      height={props.size || '20px'}
      viewBox="0 0 51 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>ic-edit-line</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="One-Player" transform="translate(-4728.000000, -549.000000)" fill={props.color || '#FFFFFF'}>
          <g id="Info-Panel" transform="translate(0.000000, 325.000000)">
            <g id="Metadata-Tab-Selected" transform="translate(0.000000, 1.000000)">
              <g id="Video-Tab-Selected" transform="translate(0.000000, 119.000000)">
                <g id="ic-edit-line" transform="translate(4728.000000, 104.000000)">
                  <g id="edit-regular">
                    <path
                      d="M35.5807783,30.5925708 L38.4109669,27.7623821 C38.8531839,
                                      27.3201651 39.6226415,27.629717 39.6226415,28.2665094 L39.6226415,
                                      41.1261792 C39.6226415, 43.4699292 37.7211085,45.3714623 35.3773585,
                                      45.3714623 L4.24528302,45.3714623 C1.90153302,45.3714623 0,43.4699292 0,
                                      41.1261792 L0,9.99410377 C0,7.65035377 1.90153302,5.74882075 4.24528302,
                                      5.74882075 L28.4345519,5.74882075 C29.0625,5.74882075 29.3808962,
                                      6.50943396 28.9386792,6.96049528 L26.1084905,9.79068396 C25.9758254,
                                      9.92334906 25.7989387,9.99410377 25.6043632,9.99410377 L4.24528302,
                                      9.99410377 L4.24528302,41.1261792 L35.3773585,41.1261792 L35.3773585,
                                      31.0878538 C35.3773585,30.9021226 35.4481132,30.7252358 35.5807783,
                                      30.5925708 Z M49.4310141,12.7446934 L26.2057783,35.9699292 L18.2104953,
                                      36.8543632 C15.8932783,37.1108491 13.9209906,35.15625 14.1774764,
                                      32.8213443 L15.0619104,24.8260613 L38.2871462,1.60082547 C40.3125,
                                      -0.424528302 43.5849056,-0.424528302 45.6014151,1.60082547 L49.4221698,
                                      5.42158019 C51.4475235,7.44693396 51.4475235,10.728184 49.4310141,
                                      12.7446934 Z M40.6928066,15.4775943 L35.5542453,10.339033 L19.1214622,
                                      26.7806604 L18.4758255,32.5560142 L24.2511792,31.9103774 L40.6928066,
                                      15.4775943 Z M46.4239386,8.42865566 L42.6031839,4.60790094 C42.240566,
                                      4.24528302 41.6479952,4.24528302 41.2942217,4.60790094 L38.5613207,
                                      7.34080189 L43.699882,12.4793632 L46.432783,9.74646226 C46.7865566,
                                      9.375 46.7865566,8.79127358 46.4239386,8.42865566 Z"
                      id="Shape"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
