import * as React from 'react';
import {Icon} from 'tt-components/src/Icon';
import {Dropdown} from 'tt-components/src/Dropdown';
import {SubsAssetsList} from './SubsAssetsList';
import {AudioAssetsList} from './AudioAssetsList';
import {defaultMenuControlBarStyles, DropdownContent} from '../../DropdownContent';
import {ITemplateColors} from '../../../../@types/templateColors';
import {PopoverTailIcon} from '../../../assets/Icons/PopoverTail';
import {IAudioChannelConfiguration} from '../../../../@types/audioChannelConfiguration';
import {IVideoAudioConfiguration} from '../../../state/IVideoState';
import {IEnum} from '../../../../@types/enum';
import {calculateDropdownStyles} from '../../../utils/utils';

import {SubtitlesIcon} from '../../../assets/Icons/Subtitles';
import {ISubtitlesMetadata} from '../../../../@types/subtitlesMetadata';

interface IAudioAndSubsProps {
  selectedSub?: string;
  onSubSelected: (trackId: string) => void;
  templateColors: ITemplateColors;
  isControlBarShown: boolean;
  showingDropdownTimeout: number;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  audioLanguageEnums: Array<IEnum>;
  audioChannelMapEnums: Array<IEnum>;
  audioChannelConfigTypeEnums: Array<IEnum>;
  languageDialectEnums: Array<IEnum>;
  audioChannelConfigurations: Array<IAudioChannelConfiguration>;
  externalAudioChannelConfigurations: Array<IAudioChannelConfiguration>;
  audioConfiguration: IVideoAudioConfiguration;
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) => void;
  loadingPlaylist: boolean;
  loadingPlaylistError: string;
  loadingAudioConfig: boolean;
  embeddedSubtitlesMetadata: ISubtitlesMetadata[];
  externalSubtitlesMetadata: ISubtitlesMetadata[];
  disabled?: boolean;
  isOpen: boolean; // TODO: Implement approach to manually update popup visibility state
  onShowAudioSubsChange: (isOpen: boolean) => void;
  checkPlaybackProxy: () => void;
  proxyConfiguration: IAudioChannelConfiguration;
  videoConfiguration: IAudioChannelConfiguration;
  userEmail: string;
  userIp: string;
  curationModeEnabled: boolean;
}

export class AudioAndSubs extends React.Component<IAudioAndSubsProps> {
  getContent = () => {
    const {tooltipBackground} = this.props.templateColors;
    const content = (
      <div className="audio-subtitles-dropdown_content">
        <AudioAssetsList
          title="Audio"
          audioLanguageEnums={this.props.audioLanguageEnums}
          audioChannelMapEnums={this.props.audioChannelMapEnums}
          embeddedAudioChannels={this.props.audioChannelConfigurations}
          externalAudioChannels={this.props.externalAudioChannelConfigurations}
          templateColors={this.props.templateColors}
          audioChannelConfigTypeEnums={this.props.audioChannelConfigTypeEnums}
          audioConfiguration={this.props.audioConfiguration}
          updateAudioConfig={this.props.updateAudioConfig}
          loadingPlaylist={this.props.loadingPlaylist}
          loadingPlaylistError={this.props.loadingPlaylistError}
          loadingAudioConfig={this.props.loadingAudioConfig}
          checkPlaybackProxy={this.props.checkPlaybackProxy}
          proxyConfiguration={this.props.proxyConfiguration}
          videoConfiguration={this.props.videoConfiguration}
          userEmail={this.props.userEmail}
          userIp={this.props.userIp}
          curationModeEnabled={this.props.curationModeEnabled}
          showConfigsAssetHrId
        />
        <div className="audio-subtitles-dropdown_delimiter" />
        <SubsAssetsList
          languageDialectEnums={this.props.languageDialectEnums}
          onSubSelected={this.props.onSubSelected}
          selectedSub={this.props.selectedSub}
          templateColors={this.props.templateColors}
          embeddedSubtitlesMetadata={this.props.embeddedSubtitlesMetadata}
          externalSubtitlesMetadata={this.props.externalSubtitlesMetadata}
        />
      </div>
    );

    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent color={tooltipBackground} content={content} />
        <Icon icon={PopoverTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  onAudioSubsOpen = (styles: React.CSSProperties) => {
    return calculateDropdownStyles(styles, this.props.isFullScreen, null, 65);
  };

  render() {
    const {
      isControlBarShown,
      isOpen,
      showingDropdownTimeout,
      videoPlayerContainer,
      isFullScreen,
      onShowAudioSubsChange
    } = this.props;
    const {main} = this.props.templateColors;
    const hideAudioSubtitlesDropdown = isControlBarShown ? '' : ' op_controls-bar_hidden';
    const closestBodyElement = (videoPlayerContainer && videoPlayerContainer.closest('.container')) as HTMLElement;

    return (
      <Dropdown
        disabled={this.props.disabled}
        isOpen={isOpen}
        elemDiff={true}
        onCloseHotKey={onShowAudioSubsChange}
        title={`Audio & Subs`}
        elemId="audioSubId"
        buttonComponent={() => (
          <div className="audio-subtitles-icon" id="audioSubId">
            <Icon icon={SubtitlesIcon} color={main} size="25px" />
          </div>
        )}
        content={this.getContent()}
        className={`ttc-control-bar-dropdown audio-subtitles-dropdown${hideAudioSubtitlesDropdown}`}
        openAbove={true}
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={isFullScreen ? videoPlayerContainer : closestBodyElement}
        onOpen={this.onAudioSubsOpen}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  minWidth: 170,
  padding: 5,
  width: 429,
  borderWidth: 1,
  marginLeft: 0,
  boxSizing: 'content-box'
};
