import * as React from 'react';
import {Dropdown} from 'tt-components/src/Dropdown';
import {Thumbnail} from '../../../Thumbnail';
import {IParsedVTT} from '../../../../../../../@types/parsedVtt';

import {TriangleIcon} from '../../../../../../assets/Icons/Triangle';

interface IThumbnailDropdownProps {
  jsonVTT: Array<IParsedVTT>;
  time: number;
  closestBody?: HTMLElement;
  onClick: (time: number) => void;
}

export class ThumbnailDropdown extends React.PureComponent<IThumbnailDropdownProps> {
  render() {
    return (
      <div className="markup-timecode-player-icon">
        <div onClick={() => this.props.onClick(this.props.time)}>
          <Dropdown
            className="program-timing-thumbnail"
            buttonComponent={() => <TriangleIcon style={{color: '#8194B5'}} />}
            content={<Thumbnail time={this.props.time} jsonVTT={this.props.jsonVTT} />}
            openAbove={true}
            portalNode={this.props.closestBody}
          />
        </div>
      </div>
    );
  }
}
