import * as React from 'react';

interface IInputProps {
  value: string;
  className?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  getRef?: (ref: HTMLElement) => void;
}

export class Input extends React.PureComponent<IInputProps> {
  inputRef: HTMLElement;

  componentDidMount() {
    if (this.props.getRef) {
      this.props.getRef(this.inputRef);
    }
  }

  render() {
    return (
      <input
        onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : (e: React.KeyboardEvent<HTMLInputElement>) => {}}
        ref={node => (this.inputRef = node)}
        onBlur={e => (this.props.onBlur ? this.props.onBlur() : null)}
        className={`input-container${this.props.className ? ` ${this.props.className}` : ``}`}
        type="text"
        value={this.props.value}
        onChange={e => this.props.onChange(e.target.value)}
      />
    );
  }
}
