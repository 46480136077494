import {PlaylistAsset} from '../PlaylistAsset';
import {IAudioChannelConfiguration} from '../../../../@types/audioChannelConfiguration';
import {ISubtitlesMetadata, ISubtitlesMetadataItem} from '../../../../@types/subtitlesMetadata';
import {IVideoSubs} from '../../../state/IVideoSubs';
import {IPlaylistAtlas} from '../../../state/IAppState';
import {parsing} from './parsing';

const getAllSubtitles = (assets: Array<PlaylistAsset>): Array<ISubtitlesMetadata> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets
    .filter((asset: PlaylistAsset) => asset.isSubtitlesAsset())
    .reduce((acc: Array<ISubtitlesMetadata>, asset: PlaylistAsset) => [...acc, ...(asset.subtitles || [])], [])
    .filter(subtitle => subtitle);
};

const getAssetSubtitlesMetadata = (asset: PlaylistAsset): Array<ISubtitlesMetadataItem> => {
  const assetCopy = parsing.parseSinglePlainObjectToInstance(asset);
  return assetCopy.isSubtitlesAsset()
    ? (assetCopy.subtitles || []).map((subtitle: ISubtitlesMetadata) => subtitle.metadata)
    : [];
};

const getEmbeddedAudioConfigurations = (assets: Array<PlaylistAsset>): Array<IAudioChannelConfiguration> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets
    .filter((asset: PlaylistAsset) => asset.isEmbeddedAudio())
    .reduce((acc: Array<IAudioChannelConfiguration>, asset: PlaylistAsset) => [...acc, ...asset.audio], []);
};

const getExternalAudioConfigurations = (assets: Array<PlaylistAsset>): Array<IAudioChannelConfiguration> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets
    .filter((asset: PlaylistAsset) => asset.getAssetType() === 'Audio')
    .reduce((acc: Array<IAudioChannelConfiguration>, asset: PlaylistAsset) => [...acc, ...asset.audio], []);
};

const getEmbeddedSubtitles = (assets: Array<PlaylistAsset>): Array<ISubtitlesMetadata> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets
    .filter((asset: PlaylistAsset) => asset.isEmbeddedSubtitle())
    .reduce((acc: Array<ISubtitlesMetadata>, asset: PlaylistAsset) => [...acc, ...(asset.subtitles || [])], [])
    .filter(subtitle => subtitle);
};

const getExternalSubtitles = (assets: Array<PlaylistAsset>): Array<ISubtitlesMetadata> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets
    .filter((asset: PlaylistAsset) => asset.getAssetType() === 'Subtitles')
    .reduce((acc: Array<ISubtitlesMetadata>, asset: PlaylistAsset) => [...acc, ...(asset.subtitles || [])], [])
    .filter(subtitle => subtitle);
};

const getAllSubs = (assets: Array<PlaylistAsset>): Array<IVideoSubs> => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets
    .filter((asset: PlaylistAsset) => asset.isSubtitlesAsset())
    .reduce(
      (acc: Array<IVideoSubs>, asset: PlaylistAsset) => [
        ...acc,
        ...[...(asset.subtitles || []).map((subtitle: ISubtitlesMetadata) => subtitle.videoSubFormat)]
      ],
      []
    );
};

const findConfiguration = (
  assets: Array<PlaylistAsset>,
  configId: string,
  proxy: IAudioChannelConfiguration,
  video: IAudioChannelConfiguration
): IAudioChannelConfiguration => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  const reduceAcc = [proxy, video].filter(conf => conf);
  return (
    clonedAssets
      .filter((asset: PlaylistAsset) => asset.isAudioAsset())
      .reduce((acc: Array<IAudioChannelConfiguration>, asset: PlaylistAsset) => [...acc, ...asset.audio], reduceAcc)
      .find((config: IAudioChannelConfiguration) => config.id === configId) || null
  );
};

export const getPlaylistAsset = (assets: Array<PlaylistAsset>, assetId: string): PlaylistAsset => {
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  return clonedAssets.find((asset: PlaylistAsset) => asset.assetId === assetId);
};

export const getVideoPlaylistAsset = (assets: Array<PlaylistAsset>, atlas: Array<IPlaylistAtlas>): PlaylistAsset => {
  const inputAssetIds = atlas.reduce((ids: Array<string>, asset: IPlaylistAtlas) => [...ids, asset.assetId], []);
  const clonedAssets = parsing.parsePlainObjectToInstances(assets);
  // Get list of video assets parsed from Playlist content
  const videoAssets = clonedAssets.filter((asset: PlaylistAsset) => asset.isVideoAsset());
  // Retrieve video asset based on the assets provided at Player initialisation
  const primaryVideoAsset = videoAssets.find((asset: PlaylistAsset) => inputAssetIds.indexOf(asset.assetId) !== -1);
  // Return video asset if found
  return primaryVideoAsset || videoAssets[0] || null;
};

export const filter = {
  getAssetSubtitlesMetadata,
  getAllSubtitles,
  getEmbeddedAudioConfigurations,
  getExternalAudioConfigurations,
  getEmbeddedSubtitles,
  getExternalSubtitles,
  getAllSubs,
  findConfiguration,
  getPlaylistAsset,
  getVideoPlaylistAsset
};
