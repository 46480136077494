import * as React from 'react';
import {Tt4a, Tt2b} from '../Typography';
import {escapeNonNumeric} from '../../utils/utils';

interface ITextFieldProps {
  label: string;
  text: string | number;
  editMode?: boolean;
  onChange?: (value: string | number) => void;
  onlyNumbers?: boolean;
}

const parseValue = (props: ITextFieldProps, value: string) => {
  if (props.onlyNumbers) {
    const parsed = parseInt(escapeNonNumeric(value));
    return isNaN(parsed) ? 0 : parsed;
  }
  return value;
};

const getContent = (props: ITextFieldProps) => {
  const readOnlyText = <Tt2b content={props.text} className="text-field-container_content_text" />;
  const inputField = (
    <input
      type="text"
      className="text-field-container_content_input"
      value={props.text}
      onChange={e => (props.onChange ? props.onChange(parseValue(props, e.target.value)) : null)}
    />
  );

  return (
    <div className={`text-field-container_content${props.editMode ? ` edit` : ``}`}>
      {props.editMode ? inputField : readOnlyText}
    </div>
  );
};

export const TextField = (props: ITextFieldProps) => (
  <div className="text-field-container">
    {!!props.label && <Tt4a content={props.label} className="text-field-container_label" />}
    {getContent(props)}
  </div>
);
