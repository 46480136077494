import * as TagManager from 'react-gtm-module';
import {Store} from 'redux';
import {Action} from 'redux-actions';
import {
  CHANGE_VISIBLE_SUBTITLE,
  FAST_FORWARD,
  FAST_REWIND,
  FRAME_ADVANCE,
  FRAME_BACKWARD,
  GO_TO_LOCATION,
  PLAYBACK_RATE_CHANGE,
  SKIP_BACKWARD_10,
  SKIP_FORWARD_10,
  SKIP_TO_BEGINNING,
  SWITCH_LOOP,
  SWITCH_PLAYING,
  UPDATE_SCRUB_BAR,
  UPDATE_SHORTCUTS,
  CHANGE_MEDIA_TIME_FORMAT
} from '../../actions/video';
import {SHOW_DIAGNOSTICS_BOX, CHANGE_WATERMARK_POSITION} from '../../actions/overlays';
import {IAppPlaylist, IAppState} from '../../state';
import {IAppConfig} from '../../../@types/appConfig';
import {LoopMode, PlaybackEvents} from './Events/PlaybackEvents';
import {IVideoSubs} from '../../state/IVideoSubs';
import {IShortcutType} from '../../constants/shortcuts';

interface IDataLayer {
  [property: string]: any;
}

interface IManagerOptions {
  gtmId?: string;
  dataLayer?: IDataLayer;
  auth?: string;
  preview?: string;
}

export class GoogleTagService {
  public PlaybackEvents: PlaybackEvents;

  constructor() {
    this.PlaybackEvents = new PlaybackEvents();
  }

  public init(configuration: IAppConfig, playlist: IAppPlaylist) {
    const {gtmId, auth, preview} = configuration.gtmOptions;

    const sessionData = {
      playerUser: configuration.userEmail,
      hostingApplication: 'Deluxe',
      assetTitle: playlist.title,
      atlasAssetId: playlist.selectedAssetId || null
    };

    const args: IManagerOptions = {
      gtmId,
      auth,
      preview,
      dataLayer: sessionData
    };

    TagManager.initialize(args);
  }

  public push(dataLayer: IDataLayer = {}) {
    TagManager.dataLayer({dataLayer});
  }

  public firePlaybackPlay(label) {
    this.push(this.PlaybackEvents.playEventDatalayer(label));
  }

  public firePlaybackPause(label) {
    this.push(this.PlaybackEvents.pauseEventDatalayer(label));
  }

  public firePlaybackSpeed(label, rate) {
    this.push(this.PlaybackEvents.playbackRateSpeedDatalayer(label, rate));
  }

  public firePlaybackFrameForward(label) {
    this.push(this.PlaybackEvents.frameForwardDatalayer(label));
  }

  public firePlaybackFrameBackward(label) {
    this.push(this.PlaybackEvents.frameBackwardDatalayer(label));
  }

  public fireSkipForward10(label) {
    this.push(this.PlaybackEvents.skipForward10Datalayer(label));
  }

  public fireSkipBackward10(label) {
    this.push(this.PlaybackEvents.skipBackward10Datalayer(label));
  }

  public fireFastForward(label) {
    this.push(this.PlaybackEvents.fastForwardDatalayer(label));
  }

  public fireFastRewind(label) {
    this.push(this.PlaybackEvents.fastRewindDatalayer(label));
  }

  public fireScrubBarUpdate(label) {
    this.push(this.PlaybackEvents.scrubBarUpdateDatalayer(label));
  }

  public fireGoToLocation(label) {
    this.push(this.PlaybackEvents.goToLocationDatalayer(label));
  }

  public fireSwitchLoop(label, mode: LoopMode) {
    this.push(this.PlaybackEvents.loopDatalayer(label, mode));
  }

  public fireSkipToBeginning(label) {
    this.push(this.PlaybackEvents.skipToBeginningDatalayer(label));
  }

  public fireFullScreen(label) {
    this.push(this.PlaybackEvents.fullScreenDatalayer(label));
  }

  public fireExternalSubtitle(label, externalSubtitles: IVideoSubs[] = [], selectedSubtitle: string) {
    const subtitle = externalSubtitles.find(subtitle => subtitle.id === selectedSubtitle);
    this.push(this.PlaybackEvents.externalSubtitle(label, subtitle ? subtitle.label : 'None'));
  }

  public fireDiagnosticPopupOpen(label) {
    this.push(this.PlaybackEvents.diagnosticPopupDatalayer(label));
  }

  public fireKeyboardShortcut(label, shortcut) {
    this.push(this.PlaybackEvents.keyboardShortcutDatalayer(label, shortcut));
  }

  public fireVideoBuffering(label, bufferTime: number) {
    this.push(this.PlaybackEvents.videoBufferingDatalayer(label, bufferTime));
  }

  public fireWatermarkLocation(label, location: CHANGE_WATERMARK_POSITION) {
    this.push(this.PlaybackEvents.watermarkLocationDatalayer(label, location));
  }

  public fireKeyboardShortcutsType(label, shortcutsType: IShortcutType) {
    this.push(this.PlaybackEvents.keyboardShortcutsTypeDatalayer(label, shortcutsType));
  }

  public fireTimecodeDisplay(label, timecodeDisplay: CHANGE_MEDIA_TIME_FORMAT) {
    this.push(this.PlaybackEvents.timecodeDisplayDatalayer(label, timecodeDisplay));
  }

  public nextAction(action: Action<any>, store: Store<IAppState>) {
    const playlist = store.getState().video.playlist;
    const eventlabel = playlist.title;

    switch (action.type) {
      case SWITCH_PLAYING:
        action.payload ? this.firePlaybackPlay(eventlabel) : this.firePlaybackPause(eventlabel);
        break;
      case PLAYBACK_RATE_CHANGE:
        this.firePlaybackSpeed(eventlabel, action.payload);
        break;
      case FRAME_ADVANCE:
        this.firePlaybackFrameForward(eventlabel);
        break;
      case FRAME_BACKWARD:
        this.firePlaybackFrameBackward(eventlabel);
        break;
      case SKIP_FORWARD_10:
        this.fireSkipForward10(eventlabel);
        break;
      case SKIP_BACKWARD_10:
        this.fireSkipBackward10(eventlabel);
        break;
      case FAST_FORWARD:
        this.fireFastForward(eventlabel);
        break;
      case FAST_REWIND:
        this.fireFastRewind(eventlabel);
        break;
      case UPDATE_SCRUB_BAR:
        this.fireScrubBarUpdate(eventlabel);
        break;
      case GO_TO_LOCATION:
        this.fireGoToLocation(eventlabel);
        break;
      case SWITCH_LOOP:
        this.fireSwitchLoop(eventlabel, action.payload.loop);
        break;
      case SKIP_TO_BEGINNING:
        this.fireSkipToBeginning(eventlabel);
        break;
      case CHANGE_VISIBLE_SUBTITLE:
        this.fireExternalSubtitle(eventlabel, playlist.externalSubs, action.payload);
        break;
      case SHOW_DIAGNOSTICS_BOX:
        if (action.payload) {
          this.fireDiagnosticPopupOpen(eventlabel);
        }
        break;
      case CHANGE_WATERMARK_POSITION:
        this.fireWatermarkLocation(eventlabel, action.payload);
        break;
      case UPDATE_SHORTCUTS:
        this.fireKeyboardShortcutsType(eventlabel, action.payload.type || 'default');
        break;
      case CHANGE_MEDIA_TIME_FORMAT:
        this.fireTimecodeDisplay(eventlabel, action.payload);
        break;
      default:
      // Do some default actions
    }
  }
}
