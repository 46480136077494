import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const VolumeUpIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 50 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2397.000000, -1764.000000)" id="Player">
          <g transform="translate(333.000000, 459.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 282.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1012.000000)">
                <g id="ic-volume-up" transform="translate(1967.000000, 2.000000)">
                  <g>
                    <polygon id="Bounds" points="0 0 66 0 66 65.0781318 0 65.0781318" />
                    <path
                      d="M8.25,23.5219509 L8.25,39.5224893 L19.25,39.5224893 L33,52.8562713 
                  L33,10.1881689 L19.25,23.5219509 L8.25,23.5219509 L8.25,23.5219509 Z M45.375,31.5222201 
                  C45.375,26.8020613 42.57,22.7485915 38.5,20.7751918 L38.5,42.2425808 C42.57,40.2958487 
                  45.375,36.2423789 45.375,31.5222201 L45.375,31.5222201 Z M38.5,8.13476648 
                  L38.5,13.6282847 C46.4475,15.9216952 52.25,23.0686023 52.25,31.5222201 
                  C52.25,39.9758379 46.4475,47.122745 38.5,49.4161555 L38.5,54.9096737 
                  C49.5275,52.4829254 57.75,42.9359375 57.75,31.5222201 C57.75,20.1085027 
                  49.5275,10.5615148 38.5,8.13476648 L38.5,8.13476648 Z"
                      id="Shape"
                      fill={props.color}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
