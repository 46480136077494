import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const TimelineRightArrowIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width="8px" height="14px" viewBox="0 0 8 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-734.000000, -960.000000)" fill="#FFFFFF">
          <g transform="translate(140.000000, 948.000000)">
            <g transform="translate(586.000000, 7.000000)">
              <path
                d="M6.35355339,8.64644661 C6.15829124,8.45118446 5.84170876,8.45118446 5.64644661,
                8.64644661 C5.45118446,8.84170876 5.45118446,9.15829124 5.64644661,9.35355339 L12,
                15.7071068 L18.3535534,9.35355339 C18.5488155,9.15829124 18.5488155,8.84170876 18.3535534,
                8.64644661 C18.1582912,8.45118446 17.8417088,8.45118446 17.6464466,8.64644661 L12,
                14.2928932 L6.35355339,8.64644661 Z"
                transform="translate(12.000000, 12.103553) scale(-1, 1) rotate(90.000000) 
                translate(-12.000000, -12.103553)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
