import * as React from 'react';
import {AudioConfig} from './components/AudioConfiguration';
import {AudioConfigEditable} from './components/AudioConfigurationEditable';
import {Collapsible} from '../../../Collapsible';
import {defineAudioConfigurationTitle} from '../../../../../../utils/utils';
import {IAudioChannelConfiguration} from '../../../../../../../@types/audioChannelConfiguration';
import {IAssetDetails, IAudioData, IErrorLog} from '../../../../../../../@types/assetDetails';
import {Tt2b} from '../../../../../../components/Typography';
import {IEnums} from 'state/IEnums';

interface IAudioConfigurationsProps {
  audioMetadata: Array<IAudioChannelConfiguration>;
  assetDetails: Partial<IAssetDetails>;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  loading: boolean;
  error: string;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

export class AudioConfigurations extends React.PureComponent<IAudioConfigurationsProps> {
  renderTableContent(config: IAudioChannelConfiguration) {
    const audio = (this.props.assetDetails.audio || []).find((audio: IAudioData) => audio.id === config.id);
    return this.props.tabsDataInEditMode ? (
      <AudioConfigEditable
        enums={this.props.enums}
        audio={audio}
        assetDetails={this.props.assetDetails}
        updatePartialAssetDetails={this.props.updatePartialAssetDetails}
        updatedAssetDetails={this.props.updatedAssetDetails}
        closestBody={this.props.closestBody}
        errorLogs={this.props.errorLogs}
      />
    ) : (
      <AudioConfig data={config} enums={this.props.enums} />
    );
  }

  getAudioConfigs() {
    return this.props.audioMetadata.map((config: IAudioChannelConfiguration, index) => {
      const title = defineAudioConfigurationTitle(
        config,
        this.props.enums.language,
        this.props.enums.channelConfigType
      );
      const content = this.createContent(config);
      return <Collapsible title={title} content={content} key={index} />;
    });
  }

  createContent(config: IAudioChannelConfiguration) {
    if (!config) {
      return (
        <div className="metadata-audio-container_configurations_data-tables_empty-configuration">
          No Audio Channels are defined
        </div>
      );
    } else {
      return this.renderTableContent(config);
    }
  }

  renderComponentContent() {
    if (this.props.loading || this.props.error) {
      return (
        <Tt2b
          className="metadata-audio-container_configurations_data-tables_empty"
          content={this.props.loading ? 'Loading audio configurations from playlist...' : this.props.error}
        />
      );
    } else {
      if (this.props.audioMetadata.length) {
        const audioConfigs = this.getAudioConfigs();
        return audioConfigs;
      } else {
        return (
          <Tt2b
            className="metadata-audio-container_configurations_data-tables_empty"
            content="No Audio Channel configurations are defined for this asset"
          />
        );
      }
    }
  }

  render() {
    return (
      <div className="metadata-audio-container_configurations">
        <div className="metadata-audio-container_configurations_data-tables">{this.renderComponentContent()}</div>
      </div>
    );
  }
}
