import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const RestartIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 43 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-422.000000, -1768.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 459.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 282.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1012.000000)">
                <g id="ic-restart" transform="translate(0.000000, 14.000000)">
                  <g
                    transform="translate(21.500000, 21.000000) scale(-1, 1) 
                  translate(-21.500000, -21.000000) "
                  >
                    <path
                      d="M35.1851852,17.7991472 L20.2962963,0.455091831 
                    C19.7530864,-0.151697277 19.2098765,-0.151697277 
                    18.7160494,0.455091831 C18.2222222,1.06188094 
                    18,2.17432764 18,3.28677433 L18,38.0760165 C18,39.1884632 
                    18.2962963,40.3009099 18.7160494,40.907699 
                    C18.962963,41.1605278 19.2592593,41.4133566 
                    19.5555556,41.4133566 C19.8518519,41.4133566 
                    20.0987654,41.1605278 20.2716049,40.907699 
                    L35.1604938,23.5636437 C35.7037037,22.9568546 
                    36,21.8444079 36,20.7319612 C36,19.6195145 
                    35.7283951,18.5576335 35.1851852,17.7991472 Z"
                      id="Shape"
                      fillRule="nonzero"
                    />
                    <path
                      d="M17.1851852,17.7991472 L2.2962963,0.455091831 
                    C1.75308642,-0.151697277 1.20987654,-0.151697277 
                    0.716049383,0.455091831 C0.222222222,1.06188094 
                    0,2.17432764 0,3.28677433 L0,38.0760165 C0,39.1884632 
                    0.296296296,40.3009099 0.716049383,40.907699 
                    C0.962962963,41.1605278 1.25925926,41.4133566 
                    1.55555556,41.4133566 C1.85185185,41.4133566 
                    2.09876543,41.1605278 2.27160494,40.907699 
                    L17.1604938,23.5636437 C17.7037037,22.9568546 
                    18,21.8444079 18,20.7319612 C18,19.6195145 
                    17.7283951,18.5576335 17.1851852,17.7991472 Z"
                      id="Shape-Copy"
                      fillRule="nonzero"
                    />
                    <rect id="Rectangle-5" x="36" y="0" width="7" height="41.4133566" rx="3" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
