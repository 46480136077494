import {IErrorPayload} from './IErrorPayload';
import {IAssetPutError} from '../../../@types/assetPutError';
import {IMetadataError} from '../../../@types/metadataErrors';
import {IAssetUnregisteredPatchError} from '../../../@types/assetUnregisteredPatchError';

export class ErrorPayload extends Error implements IErrorPayload {
  errorPayload;

  constructor(errorPayload, ...params) {
    // Pass through all params used from standard Error class
    super(...params);

    this.errorPayload = errorPayload;
    this.name = 'PayloadError';
  }

  getAssetPutErrorPayload = (): IAssetPutError => {
    if (!this.errorPayload) {
      return null;
    }

    return {
      error: this.errorPayload.error || 'Something wrong happened while updating asset content',
      errorDetails: (this.errorPayload.errorDetails || []).map(
        (errorEntry: any) =>
          ({fieldName: errorEntry.fieldName || '', message: errorEntry.message || ''} as IMetadataError)
      )
    };
  };

  getAssetUnregisteredPatchErrorPayload = (): IAssetUnregisteredPatchError => {
    if (!this.errorPayload) {
      return null;
    }

    return {
      reasons: (this.errorPayload.reasons || []).map(
        (errorEntry: any) =>
          ({fieldName: errorEntry.fieldName || '', message: errorEntry.message || ''} as IMetadataError)
      )
    };
  };
}
