import * as React from 'react';
import {Playback} from '../Playback';
import {ITemplateColors} from '../../../../@types/templateColors';
import {PlayerError} from '../../../models/PlayerError/PlayerError';

interface IPlayerSetupErrorProps {
  templateColors: ITemplateColors;
  error: PlayerError;
}

const description = (color: string, error: PlayerError) => (
  <>
    <div style={{color, width: '455px'}}>{error.message}</div>
    <div style={{color}}>{`CODE: ${error.code}`}</div>
  </>
);

export const PlayerSetupError = (props: IPlayerSetupErrorProps) => (
  <Playback
    templateColors={props.templateColors}
    mainTitle="Player Setup Error"
    descriptionContent={description(props.templateColors.main, props.error)}
  />
);
