import {connect} from 'react-redux';
import {Actions} from '../../modules/Tabs/components/Actions';
import {IAppState} from 'state';
import {saveTabsContent, cancelTabsContent, setEditMode, startAssetEdit} from '../../modules/Tabs/actions/tabs';

export interface IActionOwnProps {
  closestBodyElement?: HTMLElement;
  disableEdit?: boolean;
}

export interface IActionsStateProps {
  tabsDataInEditMode: boolean;
  isProcessing?: boolean;
  containerId: string;
}

export interface IActionsDispatchProps {
  cancelTabsContent: () => void;
  saveTabsContent: (minimumRequirementsMet?: boolean) => void;
  setTabsEditMode: (inEditMode: boolean) => void;
  startAssetEdit: () => void;
}

const mapStateToProps = (state: IAppState): IActionsStateProps => ({
  tabsDataInEditMode: state.tabs.inEditMode,
  isProcessing: state.tabs.savingTabsContent,
  containerId: state.configuration.externalControlsElementId
});

const mapDispatchToProps = (dispatch: (action: any) => void): IActionsDispatchProps => ({
  saveTabsContent: (minimumRequirementsMet?: boolean) => dispatch(saveTabsContent(minimumRequirementsMet)),
  cancelTabsContent: () => dispatch(cancelTabsContent()),
  setTabsEditMode: (inEditMode: boolean) => dispatch(setEditMode(inEditMode)),
  startAssetEdit: () => dispatch(startAssetEdit())
});

export default connect<IActionsStateProps, IActionsDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Actions);
