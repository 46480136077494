import {IStorageBase} from './IStorageBase';

export class BaseLS<T> implements IStorageBase {
  public storageKey: string;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  get() {
    const data = localStorage.getItem(this.storageKey);
    return data ? (JSON.parse(data) as Array<T>) : [];
  }

  set(data: Array<T>) {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  remove() {
    localStorage.removeItem(this.storageKey);
  }
}
