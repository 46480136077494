import * as React from 'react';
import {ButtonComponent} from './components/ButtonComponent';

export interface IOption {
  value: string | number;
  text: string;
}

interface ICustomSelectDropdownState {
  isOpen: boolean;
  selected: number | string;
}

interface ICustomSelectDropdownProps {
  selected: number | string;
  options: IOption[];
  open?: boolean;
  placeholder?: string;
  onItemSelected: (value: string | number) => void;
  onClosed?: () => void;
}

export class CustomSelectDropdown extends React.Component<ICustomSelectDropdownProps, ICustomSelectDropdownState> {
  static getDerivedStateFromProps(nextProps: ICustomSelectDropdownProps, prevState: ICustomSelectDropdownState) {
    if (nextProps.selected !== prevState.selected) {
      return {selected: nextProps.selected};
    } else {
      return null;
    }
  }

  dropdownRefElement: HTMLDivElement;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.open || false,
      selected: null
    };
  }

  componentDidMount() {
    this.dropdownRefElement.addEventListener('blur', this.onDropdownBlur);
    this.setState({selected: this.props.selected}, () => {
      this.openOnInit();
    });
  }

  componentWillUnmount() {
    this.dropdownRefElement.removeEventListener('blur', this.onDropdownBlur);
  }

  openOnInit() {
    if (this.props.open && this.dropdownRefElement) {
      this.dropdownRefElement.focus();
    }
  }

  onDropdownBlur = e => {
    if (this.state.isOpen) {
      this.onToggle();
    }
  };

  renderContent() {
    const isActive = value => (this.state.selected === value ? ' active' : '');
    return (
      <div className="custom-select-dropdown-container_content">
        {this.props.options.map((option: IOption, index) => (
          <div
            className={`custom-select-dropdown-container_content_item${isActive(option.value)}`}
            title={option.text}
            key={index}
            onClick={e => this.onItem(option.value)}
          >
            {option.text}
          </div>
        ))}
      </div>
    );
  }

  onItem = value => {
    this.props.onItemSelected(value);
    this.onToggle();
  };

  renderSelected() {
    const selectedOption = this.props.options.find((option: IOption) => option.value === this.props.selected);
    return selectedOption ? selectedOption.text : this.props.placeholder || null;
  }

  onToggle = () => {
    this.setState({isOpen: !this.state.isOpen}, () => {
      if (this.state.isOpen) {
        this.dropdownRefElement.focus();
      } else {
        if (this.props.onClosed) {
          this.props.onClosed();
        }
      }
    });
  };

  render() {
    return (
      <div className="custom-select-dropdown-container" tabIndex={-1} ref={node => (this.dropdownRefElement = node)}>
        <ButtonComponent open={this.state.isOpen} label={this.renderSelected()} onClick={this.onToggle} />
        {this.state.isOpen && this.renderContent()}
      </div>
    );
  }
}
