import {IFrameRate} from 'tt-components/src/frameRate';
import {IDisplayMediaTimeFormat} from '../../@types/displayMediaTimeFormat';
import {secondsToHHmmss, secondsToMillisecondsTimecode} from './utils';
import {Smpte} from '../models/Smpte/Smpte';

export const formatting = (
  currentTime: number,
  displayMediaTimeFormat: IDisplayMediaTimeFormat,
  frameRate: IFrameRate
) => {
  switch (displayMediaTimeFormat) {
    case 'FrameBasedTimecode':
      let frameDelimiter: string = frameRate.dropFrame ? ';' : ':';
      return Smpte.fromTimeWithAdjustments(currentTime, {
        frameRate: frameRate.frameRate,
        dropFrame: frameRate.dropFrame
      }).toString(frameDelimiter);
    case 'MillisecondBasedTimecode':
      return secondsToMillisecondsTimecode(currentTime, '.');
    case 'FrameOnly':
      const frameCount = Smpte.fromTimeWithAdjustments(currentTime, {
        frameRate: frameRate.frameRate,
        dropFrame: frameRate.dropFrame
      }).toFrames();
      return String(frameCount);
    case 'Standard':
    default:
      return secondsToHHmmss(currentTime);
  }
};
