import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const FullScreenIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>ic-media-fullscreen</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-2779.000000, -1764.000000)" id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-media-fullscreen" transform="translate(2349.000000, 1.000000)">
                  <g transform="translate(0.000000, 0.897861)">
                    <rect id="Rectangle-11" fill="#D8D8D8" opacity="0" x="0" y="0" width="66" height="65.0781318" />
                    <path
                      d="M8.25,8.13476648 L8.25,9.92044684 L8.25,24.2058897
                      L11.871951,24.2058897 L11.871951,11.7061272 L24.5487797,11.7061272 L24.5487797,8.13476648
                      L10.0609755,8.13476648 L8.25,8.13476648 L8.25,8.13476648 Z M41.4512179,8.13476648
                      L41.4512179,11.7061272 L54.128049,11.7061272 L54.128049,24.2058897 L57.75,24.2058897
                      L57.75,9.92044684 L57.75,8.13476648 L55.9390245,8.13476648 L41.4512179,8.13476648
                       L41.4512179,8.13476648 Z M8.25,40.8722576 L8.25,55.1577005 L8.25,56.9433808
                       L10.0609755,56.9433808 L24.5487797,56.9433808 L24.5487797,53.3720201 L11.871951,53.3720201
                       L11.871951,40.8722576 L8.25,40.8722576 Z M54.128049,40.8722576 L54.128049,53.3720201
                        L41.4512179,53.3720201 L41.4512179,56.9433808 L55.9390245,56.9433808 L57.75,56.9433808
                        L57.75,55.1577005 L57.75,40.8722576 L54.128049,40.8722576 Z"
                      id="Shape"
                      fill={props.color}
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
