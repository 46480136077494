import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const FilterIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '20px'}
      height={props.size || '20px'}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="20" height="20" />
        <path
          d="M19.23,5.307 C18.834,5.039 18.281,4.803 17.587,4.605 C16.221,4.215 14.415,4 12.5,
          4 C10.585,4 8.778,4.215 7.413,4.605 C6.719,4.803 6.167,5.039 5.77,5.307 C5.133,5.737 5,
          6.193 5,6.5 L5,7 C5,7.428 5.321,8.133 5.639,8.609 L10.53,15.945 C10.781,16.321 11.001,
          17.048 11.001,17.5 L11.001,20.5 C11.001,20.673 11.091,20.834 11.238,20.925 C11.318,20.975 
          11.409,21 11.501,21 C11.577,21 11.654,20.982 11.725,20.947 L13.725,19.947 C13.894,19.862 
          14.001,19.689 14.001,19.5 L14.001,17.5 C14.001,17.048 14.221,16.321 14.472,15.945 
          L19.363,8.609 C19.68,8.133 20.002,7.427 20.002,7 L20.002,6.5 C20.002,6.193 19.868,5.737 
          19.232,5.307 L19.23,5.307 Z M7.688,5.567 C8.967,5.202 10.676,5 12.5,5 C14.324,5 16.034,5.201 
          17.312,5.567 C18.69,5.961 19,6.383 19,6.5 C19,6.617 18.69,7.04 17.312,7.433 C16.033,7.798 
          14.324,8 12.5,8 C10.676,8 8.966,7.799 7.688,7.433 C6.31,7.039 6,6.617 6,6.5 C6,6.383 
          6.31,5.96 7.688,5.567 Z M13.639,15.391 C13.281,15.928 13,16.855 13,17.5 L13,19.191 L12,19.691 
          L12,17.5 C12,16.854 11.719,15.928 11.361,15.391 L6.481,8.071 C6.755,8.188 7.066,8.297 
          7.413,8.395 C8.779,8.785 10.585,9 12.5,9 C14.415,9 16.222,8.785 17.587,8.395 C17.933,8.296 
          18.245,8.188 18.519,8.07 L13.639,15.39 L13.639,15.391 Z"
          fill={props.color || '#FFFFFF'}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
