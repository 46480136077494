import LocalStorageService from '../services/LocalStorageService';

interface IAuthResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  refresh_expires_in: number;
}

interface IAuth extends IAuthResponse {
  expires_in_date: number;
  refresh_expires_in_date: number;
}
const LS_AUTH_KEY = 'authData';

/**
 * Get token from auth API, only for local development
 */
export async function token(): Promise<string> {
  const ls = new LocalStorageService();
  let authData: IAuth = JSON.parse(ls.get(LS_AUTH_KEY));

  const now = Date.now().valueOf() / 1000;

  if (authData && now < authData.expires_in_date) {
    return authData.access_token;
  } else if (authData && now < authData.refresh_expires_in_date) {
    //refresh
    authData = await tokenRefresh(authData.refresh_token);
    ls.set(LS_AUTH_KEY, JSON.stringify(authData));
  } else {
    //get new
    authData = await tokenGet();
    ls.set(LS_AUTH_KEY, JSON.stringify(authData));
  }

  return authData.access_token;
}

/**
 * Get new token
 */
async function tokenGet(): Promise<IAuth> {
  let body = new FormData();

  body.append('grant_type', 'client_credentials');
  body.append('client_id', appConfig.auth.clientId);
  body.append('client_secret', appConfig.auth.clientSecret);

  return generateResponse(body);
}

/**
 * Refresh token
 * @param refreshToken
 */
async function tokenRefresh(refreshToken): Promise<IAuth> {
  let body = new FormData();

  body.append('grant_type', 'refresh_token');
  body.append('client_id', appConfig.auth.clientId);
  body.append('client_secret', appConfig.auth.clientSecret);
  body.append('refresh_token', refreshToken);

  return generateResponse(body);
}

async function generateResponse(body: FormData): Promise<IAuth> {
  const fetchResult = await fetch(appConfig.auth.tokenUrl, {
    method: 'post',
    body
  });

  if (!fetchResult.ok) {
    throw new Error(`Bad response from server.  ${fetchResult.statusText} (${fetchResult.status})`);
  }

  const authResponse: IAuthResponse = await fetchResult.json();

  const now = Date.now().valueOf() / 1000;
  return {
    ...authResponse,
    expires_in_date: now + parseInt(authResponse.expires_in.toString()),
    refresh_expires_in_date: now + parseInt(authResponse.refresh_expires_in.toString())
  };
}
