import * as React from 'react';
import {ITemplateColors} from '../../../../@types/templateColors';
import {Icon, Icons} from 'tt-components/src/Icon';
import {Dropdown} from 'tt-components/src/Dropdown';
import {SlideBar} from 'tt-components/src/SlideBar';
import {defaultMenuControlBarStyles, DropdownContent} from '../../DropdownContent';
import {VolumeUpIcon} from '../../../assets/Icons/VolumeUp';
import {VolumeBarTailIcon} from '../../../assets/Icons/VolumeBarTail';

interface IVolumeControlProps {
  disabled: boolean;
  enabled: boolean;
  mute: boolean;
  volume: number;
  showingDropdownTimeout: number;
  templateColors: ITemplateColors;
  isControlBarShown: boolean;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  isOpen: boolean;
  onShowVolumeControlChange: (isOpen: boolean) => void;
  onVolumeChanged: (volume: number) => void;
  onMuteChanged: (mute: boolean) => void;
}

const titleProp = (enabled: boolean, title: string) => (enabled ? {title} : {});

export class VolumeControl extends React.Component<IVolumeControlProps> {
  volumeButtonRef: HTMLDivElement;

  volumeSlider = () => {
    const {mute, volume, enabled, onVolumeChanged} = this.props;
    const {highlight, tooltipBackground} = this.props.templateColors;

    const content = (
      <SlideBar
        className="op-control-bar-volume-range"
        min={0}
        max={1}
        step={0.01}
        value={mute ? 0 : volume}
        extended={true}
        color={highlight}
        onChange={e => enabled && onVolumeChanged(parseFloat(e.target.value))}
      />
    );

    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent content={content} color={tooltipBackground} />
        <Icon icon={VolumeBarTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  volumeSliderOnOpen = (styles: React.CSSProperties) => {
    if (this.volumeButtonRef) {
      const volumeButtonWidth = this.volumeButtonRef.getBoundingClientRect().width;
      // In order for this calculation to work properly the dropdown-content should have `box-sizing: content-box`
      const calcTotalContentWidth =
        ((volumeSliderStyles.width as number) +
          (volumeSliderStyles.borderWidth as number) * 2 +
          (volumeSliderStyles.padding as number) * 2) /
        2;
      const updatedStyles = {...styles, marginLeft: `-${(volumeButtonWidth - calcTotalContentWidth) / 2}px`};
      // Small position update for Full Screen mode
      if (this.props.isFullScreen && typeof updatedStyles.top !== 'undefined') {
        const top = (styles.top as number) - 8;
        return {...updatedStyles, top};
      }
      return updatedStyles;
    }
    return {...styles};
  };

  render() {
    return (
      <Dropdown
        disabled={this.props.disabled}
        {...titleProp(this.props.enabled, 'Volume')}
        buttonComponent={() => (
          <div
            {...titleProp(this.props.enabled, this.props.mute ? 'Unmute' : 'Mute')}
            onClick={() => this.props.enabled && this.props.onMuteChanged(!this.props.mute)}
            ref={node => (this.volumeButtonRef = node)}
            className="op-control-bar-volumeDropdown-button"
          >
            {this.props.mute || !this.props.volume ? (
              <Icon icon={Icons.FaVolumeOff} color={this.props.templateColors.main} size="20px" />
            ) : (
              <Icon icon={VolumeUpIcon} color={this.props.templateColors.main} size="20px" />
            )}
          </div>
        )}
        content={this.volumeSlider()}
        openAbove={true}
        isOpen={this.props.isOpen}
        elemDiff={true}
        onCloseHotKey={this.props.onShowVolumeControlChange}
        className={
          'ttc-control-bar-dropdown op-control-bar-volumeDropdown ' +
          (this.props.isControlBarShown ? '' : 'op_controls-bar_hidden')
        }
        style={volumeSliderStyles}
        showingDropdownTimeout={this.props.showingDropdownTimeout}
        portalNode={this.props.isFullScreen ? this.props.videoPlayerContainer : this.props.closestBodyElement}
        onOpen={this.volumeSliderOnOpen}
      />
    );
  }
}

const volumeSliderStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  padding: 5,
  width: 50,
  height: 130,
  borderWidth: 1,
  marginLeft: 0,
  boxSizing: 'content-box'
};
