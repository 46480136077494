import * as React from 'react';

interface ITmpElement {
  text?: string;
}

export const TmpElement = (props: ITmpElement) => {
  return <div style={style}>{props.text || ''}</div>;
};

const style: React.CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12
};
