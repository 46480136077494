import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const SmallPlayerIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '24px'}
      height={props.size || '24px'}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons/display/small-player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" stroke={props.color || '#8D919A'} x="2.5" y="2.5" width="19" height="19" rx="1" />
        <path d="M2.5,14 L12,14" id="Line-28" stroke={props.color || '#8D919A'} strokeLinecap="square" />
        <path
          d="M12.0156013,2.85979843 L12.0156013,21"
          id="Line"
          stroke={props.color || '#8D919A'}
          strokeLinecap="square"
        />
        <polygon
          id="Triangle"
          fill={props.color || '#8D919A'}
          transform="translate(8.000000, 8.500000) scale(1, -1) rotate(90.000000) translate(-8.000000, -8.500000) "
          points="8 6 11 11 5 11"
        />
      </g>
    </svg>
  );
};
