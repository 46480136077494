import * as React from 'react';

export const IconVideoNotFound = ({
  iconColor = '#378fef',
  iconStyle = {},
  height = '50px',
  text = 'Video Not Found',
  textColor = '#ffffff'
}) => {
  return (
    <div>
      <svg
        viewBox="0 0 307 246"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="video-not-found-icon"
        style={iconStyle}
        height={height}
      >
        <g id="Symbols" stroke="none" fill={iconColor} strokeWidth="1">
          <g id="ic-video-slash">
            <g id="Shape">
              <path
                d="M303.551638,220.102734 L277.209984,199.682813 C284.585647,199.010156 291.195008,193.100391
              291.195008,184.740234 L291.195008,61.2597656 C291.195008,49.0078125 277.257878,41.8488281 267.056474,
              48.8636719 L214.564743,85.1871094 L214.564743,151.107422 L199.23869,139.239844 L199.23869,53.7164063
              C199.23869,41.0320313 188.989392,30.75 176.345398,30.75 L59.3405616,30.75 L21.7917317,
              1.63359375 C18.4391576,-0.9609375 13.649766,-0.384375 11.0156006,2.97890625 L1.62839314,
              15.0867187 C-0.957878315,18.45 -0.383151326,23.2546875 2.96942278,25.8492188 L20.450702,
              39.3984375 L199.23869,178.061719 L284.729329,244.366406 C288.081903,246.960938 292.871295,
              246.384375 295.50546,243.021094 L304.892668,230.865234 C307.526833,227.55 306.904212,
              222.697266 303.551638,220.102734 Z M15.326053,192.283594 C15.326053,204.967969 25.575351,
              215.25 38.2193448,215.25 L176.345398,215.25 C181.709516,215.25 186.594696,213.328125 190.521997,
              210.205078 L15.326053,74.3285156 L15.326053,192.283594 Z"
              />
            </g>
          </g>
        </g>
      </svg>
      <div className="video-not-found-text" style={{color: textColor}}>
        {text}
      </div>
    </div>
  );
};
