import * as React from 'react';
import {Container, Row, Column} from '../../../../../Grid';
import {TrackDetails} from './AudioTrackEditableDetails';
import {Dropdown} from '../../../../../../../../components/Dropdown';
import {Toggle} from '../../../../../../../../components/Toggle';
import {deepCopy, getErrorLogByField} from '../../../../../../utils/helpers';
import {
  IAudioData,
  IAssetDetails,
  IAudioDataTrackDetail,
  IErrorLog
} from '../../../../../../../../../@types/assetDetails';
import {LanguageDropdown} from '../../../../../LanguageDropdown';

interface IAudioConfigEditableProps {
  audio: IAudioData;
  assetDetails: IAssetDetails;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: any;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: IAssetDetails) => void;
}

export class AudioConfigEditable extends React.Component<IAudioConfigEditableProps> {
  handleClick = (name, value) => {
    const data = value !== '' ? value : null;
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const audio = (this.props.assetDetails.audio || []).reduce((acc: Array<IAudioData>, audio: IAudioData) => {
      if (audio.id === this.props.audio.id) {
        audio = {...audio, [name]: data};
      }
      return [...acc, audio];
    }, []);

    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  convertEnums = enums => {
    let options = [];
    enums.map(item => {
      options.push({label: item.name, value: item.value});
    });
    return options;
  };

  onLanguageDubbedToggle = (toggleState: boolean) => {
    this.handleClick('audioChannel', toggleState);
  };

  onLanguageSelected = (language: string, country: string) => {
    setTimeout(() => this.handleClick('language', language), 200);
    setTimeout(() => this.handleClick('country', country), 200);
  };

  getContent = () => {
    const audioConfigDataEntry = this.props.audio;
    return (
      <div className="audio-config-container">
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.channelConfigType)}
              onSelected={value => this.handleClick('type', value)}
              selected={audioConfigDataEntry.type}
              label="Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.type', this.props.errorLogs)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <Toggle
              label="Audio Language Dubbed"
              toggled={!!audioConfigDataEntry.audioChannel}
              onToggle={this.onLanguageDubbedToggle}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              options={this.convertEnums(this.props.enums.channelConfig)}
              onSelected={value => this.handleClick('channelConfig', value)}
              selected={audioConfigDataEntry.channelConfig}
              label="Channel Config"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.channelConfig', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <LanguageDropdown
              search
              languageDialect={this.props.enums.languageDialect}
              onLanguageSelected={this.onLanguageSelected}
              language={audioConfigDataEntry.language}
              country={audioConfigDataEntry.country}
              label="Language"
              portalNode={this.props.closestBody}
              toLowerCaseCompare
              errorLogs={this.props.errorLogs}
            />
          </Column>
          <Column />
          <Column className="audio-details-container_row_column" />
        </Row>
        <div className="audio-config-container-tracks" />
        {audioConfigDataEntry.trackDetail.map((track: IAudioDataTrackDetail, index) => {
          return (
            <TrackDetails
              key={index}
              audioConfigId={this.props.audio.id}
              assetDetails={this.props.assetDetails}
              updatePartialAssetDetails={this.props.updatePartialAssetDetails}
              updatedAssetDetails={this.props.updatedAssetDetails}
              track={track}
              enums={this.props.enums}
              closestBody={this.props.closestBody}
              errorLogs={this.props.errorLogs}
            />
          );
        })}
      </div>
    );
  };

  render() {
    return <Container className="audio-details-container">{this.getContent()}</Container>;
  }
}
