import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const FastFwdIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1170.000000, -1768.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 459.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 282.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1012.000000)">
                <g id="ic-fast-fwd" transform="translate(748.000000, 14.000000)">
                  <g>
                    <path
                      d="M41.0493827,17.7991472 L23.6790123,0.455091831 C23.0452675,-0.151697277
                      22.4115226,-0.151697277 21.8353909,0.455091831 C21.2592593,1.06188094
                      21,2.17432764 21,3.28677433 L21,38.0760165 C21,39.1884632 21.345679,40.3009099
                      21.8353909,40.907699 C22.1234568,41.1605278 22.4691358,41.4133566 22.8148148,41.4133566
                      C23.1604938,41.4133566 23.4485597,41.1605278 23.6502058,40.907699 L41.0205761,23.5636437
                      C41.654321,22.9568546 42,21.8444079 42,20.7319612 C42,19.6195145 41.6831276,18.5576335
                      41.0493827,17.7991472 Z"
                      id="Shape"
                      fillRule="nonzero"
                    />
                    <path
                      d="M20.0493827,17.7991472 L2.67901235,0.455091831 C2.04526749,-0.151697277
                      1.41152263,-0.151697277 0.835390947,0.455091831 C0.259259259,1.06188094 0,2.17432764
                      0,3.28677433 L0,38.0760165 C0,39.1884632 0.345679012,40.3009099 0.835390947,40.907699
                      C1.12345679,41.1605278 1.4691358,41.4133566 1.81481481,41.4133566 C2.16049383,41.4133566
                      2.44855967,41.1605278 2.65020576,40.907699 L20.0205761,23.5636437 C20.654321,22.9568546
                      21,21.8444079 21,20.7319612 C21,19.6195145 20.6831276,18.5576335 20.0493827,17.7991472 Z"
                      id="Shape-Copy"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
