import * as React from 'react';
import {Dropdown, Icon} from 'tt-components';
import {DropdownContent, defaultMenuControlBarStyles} from '../DropdownContent';
import {ReplayIcon} from '../../assets/Icons/Replay';
import {IFrameRate} from 'tt-components/src/frameRate';
import {throttle, calculateDropdownStyles} from '../../utils/utils';
import {ITemplateColors} from '../../../@types/templateColors';
import {CustomRadioButton} from '../CustomRadio';
import {PopoverTailIcon} from '../../assets/Icons/PopoverTail';
import {IDisplayMediaTimeFormat} from '../../../@types/displayMediaTimeFormat';
import {PlayerTimePicker} from '../PlayerTimePicker';

export type LoopState = 'off' | 'entireVideo' | 'segmentVideo';

interface ILoopControlBarState {
  loop?: LoopState;
  timeIn?: number;
  timeOut?: number;
}
export interface ILoopControlBarProps {
  loop?: {
    loop;
    timeIn;
    timeOut;
  };
  maxTime?: number;
  frameRate?: IFrameRate;
  onSwitchLoop?: (loop: object) => void;
  templateColors: ITemplateColors;
  isControlBarShown: boolean;
  showingDropdownTimeout: number;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  disabled?: boolean;
  displayMediaTimeFormat: IDisplayMediaTimeFormat;
  isOpen: boolean; // TODO: Implement approach to manually update popup visibility state
  videoStartTime?: number;
  onShowLoopControlChange: (isOpen: boolean) => void;
}

export class LoopControlBar extends React.PureComponent<ILoopControlBarProps, ILoopControlBarState> {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.loop.loop !== prevState.loop) {
      return {loop: nextProps.loop.loop, timeIn: nextProps.loop.timeIn, timeOut: nextProps.loop.timeOut};
    } else {
      return null;
    }
  }

  loopDropdownButton: HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = {
      loop: this.props.loop.loop,
      timeIn: this.props.loop.timeIn,
      timeOut: this.props.loop.timeOut
    };
  }

  onSwitchLoop = params => {
    const {onSwitchLoop} = this.props;
    const {timeIn, timeOut} = this.state;
    const loop = params.target.value;
    const loopObj = {loop, timeIn, timeOut};
    this.setState({
      loop
    });
    onSwitchLoop(loopObj);
  };

  timeInChange = value => {
    const {onSwitchLoop} = this.props;
    const {loop, timeOut} = this.state;
    const timeIn = value;
    const loopObj = {loop, timeIn, timeOut};
    this.setState({timeIn});
    if (timeIn < timeOut) {
      onSwitchLoop(loopObj);
    }
  };

  timeOutChange = value => {
    const {onSwitchLoop} = this.props;
    const {loop, timeIn} = this.state;
    const timeOut = value;
    const loopObj = {loop, timeIn, timeOut};
    this.setState({timeOut});
    if (timeOut > timeIn) {
      onSwitchLoop(loopObj);
    }
  };

  onTimeInChange = () => throttle(this.timeInChange, 1000);

  onTimeOutChange = () => throttle(this.timeOutChange, 1000);

  getTimePicker = (time: number, type: 'timeIn' | 'timeOut') => {
    const disabled = this.state.loop === 'off' || this.state.loop === 'entireVideo';

    if (this.props.displayMediaTimeFormat === 'FrameOnly') {
      return <div>Here should be frame-only component</div>;
    } else {
      const props: any =
        this.props.displayMediaTimeFormat === 'FrameBasedTimecode'
          ? {frameRate: this.props.frameRate}
          : this.props.displayMediaTimeFormat === 'MillisecondBasedTimecode'
          ? {milliseconds: true}
          : {};
      return (
        <PlayerTimePicker
          disabled={disabled}
          time={time}
          min={0}
          max={this.props.maxTime}
          {...props}
          onChangeTime={type === 'timeIn' ? this.onTimeInChange() : this.onTimeOutChange()}
        />
      );
    }
  };

  getLoopControlContent = () => {
    const {loop, timeIn, timeOut} = this.state;
    const {main, tooltipBackground} = this.props.templateColors;
    const content = (
      <div className="op-loop-control-bar">
        <div className="title" style={{color: main}}>
          Loop
        </div>
        <ul>
          <li>
            <CustomRadioButton
              index={'off'}
              id={`loopOff`}
              value={'off'}
              text={'Off'}
              selectedValue={loop}
              onChange={this.onSwitchLoop}
              templateColors={this.props.templateColors}
            />
          </li>
          <li>
            <CustomRadioButton
              index={'entireVideo'}
              id={`loopEntireVideo`}
              value={'entireVideo'}
              text={'Entire Video'}
              selectedValue={loop}
              onChange={this.onSwitchLoop}
              templateColors={this.props.templateColors}
            />
          </li>
          <li>
            <CustomRadioButton
              index={'segmentVideo'}
              id={`loopSegmentVideo`}
              value={'segmentVideo'}
              text={'Video Segment'}
              selectedValue={loop}
              onChange={this.onSwitchLoop}
              templateColors={this.props.templateColors}
            />
          </li>
          <li>
            <div className="time-picker_title">Time in</div>
            {this.getTimePicker(timeIn, 'timeIn')}
          </li>
          <li>
            <div className="time-picker_title">Time out</div>
            {this.getTimePicker(timeOut, 'timeOut')}
          </li>
        </ul>
      </div>
    );
    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent content={content} color={tooltipBackground} />
        <Icon icon={PopoverTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  onLoopOpen = (styles: React.CSSProperties) => {
    return calculateDropdownStyles(styles, this.props.isFullScreen, this.loopDropdownButton);
  };

  render() {
    const {
      isControlBarShown,
      isOpen,
      showingDropdownTimeout,
      videoPlayerContainer,
      isFullScreen,
      onShowLoopControlChange,
      closestBodyElement
    } = this.props;
    const {main} = this.props.templateColors;
    return (
      <Dropdown
        disabled={this.props.disabled}
        isOpen={isOpen}
        elemDiff={true}
        onCloseHotKey={onShowLoopControlChange}
        title={'Loop Controls'}
        elemId="loopControlId"
        buttonComponent={() => (
          <div className="loop-control-dropdown-icon" ref={node => (this.loopDropdownButton = node)} id="loopControlId">
            <Icon icon={ReplayIcon} color={main} size="22px" />
          </div>
        )}
        content={this.getLoopControlContent()}
        openAbove={true}
        className={
          'ttc-control-bar-dropdown loop-control-bar-dropdown ' + (isControlBarShown ? '' : 'op_controls-bar_hidden')
        }
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={isFullScreen ? videoPlayerContainer : closestBodyElement}
        onOpen={this.onLoopOpen}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  width: 175,
  padding: 5,
  borderWidth: 1,
  marginLeft: 0,
  boxSizing: 'content-box'
};
