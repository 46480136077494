import * as React from 'react';
import {ITemplateColors} from '../../../@types/templateColors';
import {ISubtitlesMetadata} from '../../../@types/subtitlesMetadata';
import {CustomRadioButton} from '../CustomRadio';
import {mapSubtitleLanguageCountry, getFullLanguageName} from '../../utils/utils';
import {IEnum} from '../../../@types/enum';

interface ISubtitleRadioProps {
  templateColors: ITemplateColors;
  selectedSubId: string;
  subtitle: ISubtitlesMetadata;
  updateSelectedSubtitle?: (subId: string) => void;
  identifier?: string;
  reverse?: boolean;
  languageDialect?: Array<IEnum>;
}

export class SubtitleRadio extends React.PureComponent<ISubtitleRadioProps> {
  static defaultProps = {
    languageDialect: []
  };
  getRadioClassName = () => {
    const isNotAvailable =
      this.props.subtitle.assetStatus.toLowerCase() !== 'staged' || this.props.subtitle.isTTManError;
    const hasBeenTransformed = this.props.subtitle.videoSubFormat ? !!this.props.subtitle.videoSubFormat.url : false;
    return `subtitle-radio${isNotAvailable ? ` not-available` : hasBeenTransformed ? `` : ` not-transformed`}`;
  };

  getRadioText = () => {
    const {language, country, format, coding} = this.props.subtitle.metadata;
    const text = `${mapSubtitleLanguageCountry(
      language,
      country,
      this.props.languageDialect,
      getFullLanguageName(language)
    )}${format ? ` ${format}` : ``}${coding ? ` ${coding}` : ``}`;
    return text || 'Track';
  };

  getRadioId = () => {
    return [this.props.identifier, this.props.subtitle.id].filter(token => token).join('-');
  };

  onChange = e => {
    if (this.props.updateSelectedSubtitle) {
      this.props.updateSelectedSubtitle(e.target.value);
    }
  };

  render() {
    const isNotAvailable =
      this.props.subtitle.assetStatus.toLowerCase() !== 'staged' || this.props.subtitle.isTTManError;
    return (
      <CustomRadioButton
        className={this.getRadioClassName()}
        disabled={isNotAvailable}
        id={this.getRadioId()}
        value={this.props.subtitle.id}
        text={this.getRadioText()}
        selectedValue={this.props.selectedSubId}
        onChange={this.onChange}
        templateColors={this.props.templateColors}
        reverse={this.props.reverse}
      />
    );
  }
}
