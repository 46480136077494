import * as React from 'react';
import {ITooltipSegmentData, ITooltipComment} from '../../interfaces/ITimelineTooltipData';

interface ITimelineSegmentProps {
  containerClassName?: string;
  style?: React.CSSProperties;
  start?: number; // in seconds
  end?: number; // in seconds
  content?: string;
  title?: string;
  comment?: ITooltipComment;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

export class TimelineSegment extends React.PureComponent<ITimelineSegmentProps> {
  onMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    const {left, bottom, height, width} = (e.target as HTMLElement).getBoundingClientRect();
    this.props.onElementIn({
      leftPosition: left,
      bottomPosition: bottom,
      start: this.props.start,
      end: this.props.end,
      content: this.props.content || '',
      title: this.props.title || '',
      width,
      height,
      comment: this.props.comment || null
    });
  };

  onMouseOut = (e: React.MouseEvent<HTMLElement>) => {
    this.props.onElementOut();
  };

  render() {
    return (
      <div
        className={this.props.containerClassName || ''}
        style={this.props.style || {}}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onClick={() => this.props.onSegmentClicked(this.props.start)}
      >
        {this.props.children}
      </div>
    );
  }
}
