import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const DeleteThinIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '14px'}
      height={props.size || '14px'}
      viewBox="0 0 39 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill={props.color || '#ffffff'} fillRule="evenodd">
        <g id="One-Player" transform="translate(-4731.000000, -447.000000)" fillRule="nonzero">
          <g id="Info-Panel" transform="translate(0.000000, 325.000000)">
            <g id="Comments-Tab-Selected" transform="translate(3014.000000, 0.000000)">
              <g id="Comment-Panels-Main" transform="translate(1.000000, 96.000000)">
                <g id="Comment-Panel-1">
                  <g id="cancel" transform="translate(1716.000000, 26.000000)">
                    <path
                      d="M22.4029316,21.0000154 L38.398834,3.77360433 C39.2003887,2.91030682 
                      39.2003887,1.51068861 38.398834,0.64747313 C37.5972031,-0.215824377 
                      36.2974856,-0.215824377 35.4959309,0.64747313 L19.4999524,17.8738842 
                      L3.50405004,0.64747313 C2.70241918,-0.215824377 1.40277783,-0.215824377 
                      0.601223142,0.64747313 C-0.200331542,1.51077064 -0.200407714,2.91038885 
                      0.601223142,3.77360433 L16.5971255,21.0000154 L0.601223142,38.2264264 
                      C-0.200407714,39.0897239 -0.200407714,40.4893422 0.601223142,41.3525576 
                      C1.402854,42.2158551 2.70249536,42.2157731 3.50405004,41.3525576 
                      L19.4999524,24.1261466 L35.4957786,41.3525576 C36.2974094,42.2158551 
                      37.597127,42.2157731 38.3986816,41.3525576 C39.2002363,40.4892601 
                      39.2002363,39.0896419 38.3986816,38.2264264 L22.4029316,21.0000154 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
