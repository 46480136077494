import {IAudioChannelConfiguration, IAudioChannel} from '../../../../@types/audioChannelConfiguration';
import {IAssetStatus} from '../../../../@types/assetStatus';
import {
  IAssetFrameRate,
  IAudioData,
  IAudioDataTrackDetail,
  IAssetImages,
  IAssetNonMedia
} from '../../../../@types/assetDetails';
import {ISubtitlesMetadata, ISubtitlesMetadataItem, ISubtitlesFormat} from '../../../../@types/subtitlesMetadata';
import {getFullLanguageName, normalizeTypes} from '../../../utils/utils';
import {IVideoSubs} from '../../../state/IVideoSubs';
import {ISearchTitle} from '../../../../@types/searchTitle';
import {PlaylistAsset} from '../PlaylistAsset';
import {deepCopy} from '../../../modules/Tabs/utils/helpers';
import {IEventGroup, IMarkupEvent} from '../../../../@types/markupEvent';
import {IAssetCredentials, IFeatureCredentials, ISeriesCredentials, ISubtitlesUrlData} from '../../../state/IAppState';
import {IMarkupsTypes} from '../../../state/IVideoState';

// Default types for Program Timings events
const defaultPTTypes = Object.freeze([
  {id: 'default1', type: 'start timecode', timeIn: null, timeOut: null},
  {id: 'default2', type: 'program start', timeIn: null, timeOut: null},
  {id: 'default3', type: 'program end', timeIn: null, timeOut: null}
]);

const createAudioChannelConfiguration = (
  audio: any,
  id: string,
  assetId: string,
  assetStatus: IAssetStatus = 'Initial',
  isRegistered: boolean,
  hrId: string
): IAudioChannelConfiguration => {
  return {
    id,
    hrId,
    assetId,
    assetStatus,
    type: audio.type || null,
    audioLanguage: audio.audioLanguage || null,
    channelConfig: audio.channelConfig || null,
    country: audio.country || null,
    language: audio.language || null,
    isRegistered,
    trackDetail: (audio.trackDetail || []).reduce((acc: Array<IAudioChannel>, audioChannel: any) => {
      const channelMap =
        audioChannel.channelMap && Array.isArray(audioChannel.channelMap) ? [...audioChannel.channelMap] : [];
      // We need to create a separate entry for each existing channel mapping from audio track data
      const channels = channelMap.reduce((parsedChannels: Array<IAudioChannel>, mapping: string, index: number) => {
        const channel = parseAudioChannel(audioChannel);
        channel.channelMap = [mapping];
        // Generate unique id for the track based on audio configuration, track number and index
        channel.id = `${id}_${audioChannel.track}_${index}`;
        // In case the track mapping contains more than 1 entry we need to track the channel order
        if (index > 0) {
          channel.channel = index;
        }
        return [...parsedChannels, channel];
      }, []);

      // In case the audio track doesn't have any valid channel mapping we need to add it separate
      // from the logic that iterates between all possible options
      if (!channels.length) {
        channels.push({...parseAudioChannel(audioChannel), channelMap} as IAudioChannel);
      }

      return [...acc, ...channels];
    }, [])
  };
};

const parseAudioChannel = (audioChannel: any): IAudioChannel => {
  return {
    averageBitRate: audioChannel.averageBitRate || null,
    bitDepth: audioChannel.bitDepth || null,
    bitRateMode: audioChannel.bitRateMode || null,
    sampleRate: audioChannel.sampleRate || null,
    channelMap: audioChannel.channelMap || null,
    dbLevel: audioChannel.dbLevel || null,
    elementType: audioChannel.elementType || null,
    audioCodec: audioChannel.audioCodec || null,
    peakBitRate: audioChannel.peakBitRate || null,
    track: audioChannel.track || null,
    subType: audioChannel.subType || null,
    watermark: audioChannel.watermark || null,
    streamInFile: audioChannel.streamInFile || null,
    actualLength: audioChannel.actualLength || null
  };
};

const createSubtitlesMetadata = (
  id: string,
  assetId: string,
  hrId: string,
  assetName: string,
  assetFrameRate: IAssetFrameRate,
  subtitles: any,
  assetStatus: IAssetStatus = 'Initial'
): Array<ISubtitlesMetadata> => {
  const providedSubs = subtitles || [];
  return providedSubs.reduce((acc: Array<ISubtitlesMetadata>, subtitle: any, index: number) => {
    const metadata = parseSubtitlesItemMetadata(subtitle);
    const subtitleTrackId = `${id}-${index}`;
    const data: ISubtitlesMetadata = {
      id: subtitleTrackId,
      assetId,
      hrId,
      name: assetName,
      frameRate: {
        dropFrame: assetFrameRate ? assetFrameRate.isDrop || false : false,
        frameRate: assetFrameRate ? assetFrameRate.value || '' : ''
      },
      isTTManError: false,
      metadata,
      assetStatus,
      videoSubFormat: parseSubData(subtitleTrackId, metadata.language, metadata.format, null)
    };
    return [...acc, data];
  }, []);
};

const parseSubtitlesItemMetadata = (subtitle: any): ISubtitlesMetadataItem => {
  return {
    description: subtitle.description || null,
    embedType: subtitle.embedType || null,
    subtitleFormat: subtitle.subtitleFormat || null,
    format: subtitle.format || null,
    coding: subtitle.coding || null,
    language: subtitle.language || null,
    country: subtitle.country || null,
    cardsetList: subtitle.cardsetList || null
  };
};

const parseSubData = (id: string, lang: string, kind: ISubtitlesFormat = 'subtitle', url: string): IVideoSubs => {
  return {
    id,
    label: getFullLanguageName(lang),
    kind: ['caption', 'subtitle'].find(token => token === (kind || '').toLowerCase()) || 'subtitle',
    url,
    lang
  };
};

const parseSearchTitle = (data: any): ISearchTitle => ({
  id: data.id || '',
  hrId: data.hrId || '',
  name: data.name || '',
  type: data.type || '',
  sequence: data.sequence || '',
  ancestors: (data.ancestors || []).map(parseSearchTitle)
});

const parseSinglePlainObjectToInstance = plainAsset => {
  // Create new PlaylisAsset instance from shallow copy
  const asset = new PlaylistAsset(
    plainAsset._assetStatus,
    plainAsset._assetDetails,
    plainAsset._isRegistered,
    plainAsset._parsedEvents,
    plainAsset._assetType
  );
  // Update hidden state
  asset.isHidden = plainAsset.isHidden;
  // Update subtitles related data
  if (asset.subtitles.length && plainAsset._parsedSubtitles.length) {
    const subtitles = deepCopy([...asset.subtitles]).reduce(
      (acc: Array<ISubtitlesMetadata>, subtitle: ISubtitlesMetadata) => {
        const prevSubtitle = plainAsset._parsedSubtitles.find((sub: ISubtitlesMetadata) => sub.id === subtitle.id);
        if (prevSubtitle) {
          // Update subtitle file URL if exists
          subtitle.url = prevSubtitle.url;
          // Update track src if provided previously
          subtitle.videoSubFormat.url = prevSubtitle.videoSubFormat.url;
        }
        return [...acc, subtitle];
      },
      []
    );
    asset.updateAssetSubtitles(subtitles);
  }
  // Update waveform file URL if exists
  asset.updateWaveformsUrl(plainAsset._parsedWaveforms);
  return asset;
};

const parsePlainObjectToInstances = (assets: Array<PlaylistAsset>) => {
  return deepCopy([...assets]).map(parseSinglePlainObjectToInstance);
};

export const parseEventsAfterAPIRequest = (
  events: Array<IEventGroup>,
  enumTypes: Array<IMarkupsTypes> = []
): Array<IEventGroup> => {
  let eventsCopy = deepCopy([...events]);
  const programTimings = eventsCopy.find((eventGroup: any) => eventGroup.name === 'Program Timings');
  const programTimingsEvents = programTimings && programTimings.events ? deepCopy([...programTimings.events]) : [];

  const matchIndexes = [];
  const defaultEvents = [...defaultPTTypes].map((defaultOption: any) => {
    const matchIndex = programTimingsEvents.findIndex(
      (event: any) => (event.type || '').toLowerCase() === defaultOption.type
    );
    if (matchIndex !== -1) {
      matchIndexes.push(matchIndex);
      return {...programTimingsEvents[matchIndex]};
    }
    return defaultOption;
  });
  eventsCopy = [...eventsCopy].map((event: any) => {
    if (event.name === 'Program Timings') {
      return {
        ...event,
        events: [...defaultEvents, ...programTimingsEvents.filter((event, index) => matchIndexes.indexOf(index) === -1)]
      };
    }
    return event;
  });

  const hasDefinedProgramTimings = eventsCopy.find((group: IEventGroup) => group.name === 'Program Timings');
  if (!hasDefinedProgramTimings) {
    eventsCopy = [...eventsCopy, {name: 'Program Timings', events: defaultEvents}];
  }

  eventsCopy = eventsCopy.reduce((acc: Array<IEventGroup>, event: IEventGroup) => {
    return [...acc, {...event, events: normalizeTypes(event.events, event.name, enumTypes) || []}];
  }, []);

  return eventsCopy;
};

const parseCredentialsFromTitles = (titles: Array<ISearchTitle>): IAssetCredentials => {
  let credentials = {};
  let conformanceGroupId;
  const isEpisodeTitle = titles.find((title: ISearchTitle) => title.type === 'Episode');
  if (isEpisodeTitle) {
    const series = titles.find((title: ISearchTitle) => title.type === 'Series');
    const season = titles.find((title: ISearchTitle) => title.type === 'Season');
    const episodeVersion = titles.find((title: ISearchTitle) => title.type === 'EpisodeVersion');
    const episodeConformance = titles.find((title: ISearchTitle) => title.type === 'EpisodeConformance');

    credentials = {
      episodeId: isEpisodeTitle.id,
      seriesId: series ? series.id : '',
      seasonId: season ? season.id : '',
      episodeVersionId: episodeVersion ? episodeVersion.id : ''
    } as ISeriesCredentials;
    conformanceGroupId = episodeConformance ? episodeConformance.id : null;
  } else {
    const featureTitle = titles.find((title: ISearchTitle) => title.type === 'Feature');
    const featureVersion = titles.find((title: ISearchTitle) => title.type === 'FeatureVersion');
    const featureConformance = titles.find((title: ISearchTitle) => title.type === 'FeatureConformance');
    credentials = {
      featureId: featureTitle ? featureTitle.id : '',
      featureVersionId: featureVersion ? featureVersion.id : ''
    } as IFeatureCredentials;
    conformanceGroupId = featureConformance ? featureConformance.id : null;
  }
  return {data: credentials, type: isEpisodeTitle ? 'Series' : 'Feature', conformanceGroupId} as IAssetCredentials;
};

const provideIdsForAudioRecords = (audio: Array<IAudioData>, assetId: string): Array<IAudioData> => {
  return [
    ...audio.map((audio: IAudioData, index: number) => ({
      ...audio,
      id: `${assetId}-audio-${index}`,
      trackDetail: (audio.trackDetail || []).map((track: IAudioDataTrackDetail, trackOrder: number) => ({
        ...track,
        id: `${assetId}-audio-${index}-${trackOrder}`
      }))
    }))
  ];
};

const provideIdsForImageRecords = (images: Array<IAssetImages>, assetId: string): Array<IAssetImages> => {
  return [
    ...images.reduce((acc: Array<IAssetImages>, image: IAssetImages, index: number) => {
      const updatedImage = {
        ...image,
        id: `${assetId}-image-${index}`,
        assetId
      };
      return [...acc, updatedImage];
    }, [])
  ];
};

const provideIdsForNonMediaRecords = (images: Array<IAssetNonMedia>, assetId: string): Array<IAssetNonMedia> => {
  return [
    ...images.reduce((acc: Array<IAssetNonMedia>, nonMedia: IAssetNonMedia, index: number) => {
      const updatedNonMedia = {
        ...nonMedia,
        id: `${assetId}-nonmedia-${index}`,
        assetId
      };
      return [...acc, updatedNonMedia];
    }, [])
  ];
};

const isDefaultType = (event: IMarkupEvent): boolean => {
  return defaultPTTypes.map(defaultType => defaultType.type).indexOf((event.type || '').toLowerCase()) !== -1;
};

const isDefaultEvent = (event: IMarkupEvent): boolean => {
  return defaultPTTypes.map(defaultType => defaultType.id).indexOf(event.id) !== -1;
};

const parseSubtitlesStageData = (data: any): ISubtitlesUrlData => {
  return {
    assetId: data.assetId || null,
    url: data.url || null,
    parentAssetId: data.parentAssetId || null,
    filename: data.filename || null
  };
};

export const parsing = {
  createAudioChannelConfiguration,
  parseAudioChannel,
  createSubtitlesMetadata,
  parseSubtitlesItemMetadata,
  parseSubData,
  parseSearchTitle,
  parseSinglePlainObjectToInstance,
  parsePlainObjectToInstances,
  parseEventsAfterAPIRequest,
  defaultPTTypes,
  parseCredentialsFromTitles,
  provideIdsForAudioRecords,
  provideIdsForImageRecords,
  provideIdsForNonMediaRecords,
  isDefaultType,
  isDefaultEvent,
  parseSubtitlesStageData
};
