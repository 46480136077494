import * as React from 'react';

export interface IGuidelinesProps {
  file: string;
}

export class Guidelines extends React.Component<IGuidelinesProps> {
  render() {
    return (
      <div className="guidelines-container">
        <embed src={this.props.file} width="100%" height="600px" />
      </div>
    );
  }
}
