import * as React from 'react';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ArrowButtons} from 'tt-components/src/Buttons/ArrowButtons';
import {
  secondsToHHmmss,
  getTimeComponents,
  fromSMPTETokensToSeconds,
  secondsToMillisecondsTimecode,
  roundTwoDecimal
} from '../../utils/utils';
import {IFrameRate} from 'tt-components';
import {ShortcutHandler} from 'tt-components/src/ShortcutHandler';
import {Smpte} from '../../models/Smpte/Smpte';

interface IPlayerTimePickerProps {
  time: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  frameRate?: IFrameRate;
  milliseconds?: boolean;
  onChangeTime: (time: number) => void;
}

interface IPlayerTimePickerState {
  hours: string;
  minutes: string;
  seconds: string;
  frames: string;
  milliseconds: string;
  focused: boolean;
}

type componentsType = 'hours' | 'minutes' | 'seconds' | 'frames' | 'milliseconds';

interface IInputUpdate {
  value: string;
  type: componentsType;
}

const shortcuts = ['alt+j', 'alt+k'];

type IArrowPositions = 'up' | 'down';

export class PlayerTimePicker extends React.Component<IPlayerTimePickerProps, IPlayerTimePickerState> {
  onTimeComponentUpdated$: Subject<IInputUpdate>;
  $onTimeComponentUpdated: Subscription;

  onArrowTimeComponentUpdated$: Subject<IArrowPositions>;
  $onArrowTimeComponentUpdated: Subscription;

  hoursTextInput;
  minutesTextInput;
  secondsTextInput;
  frameTextInput;
  millisecondsTextInput;

  constructor(props) {
    super(props);

    this.state = {
      hours: '00',
      minutes: '00',
      seconds: '00',
      frames: '00',
      milliseconds: '000',
      focused: false
    };

    this.hoursTextInput = React.createRef();
    this.minutesTextInput = React.createRef();
    this.secondsTextInput = React.createRef();
    this.frameTextInput = React.createRef();
    this.millisecondsTextInput = React.createRef();
  }

  componentDidMount() {
    this.onTimeComponentUpdated$ = new Subject();
    this.$onTimeComponentUpdated = this.onTimeComponentUpdated$.pipe(debounceTime(450)).subscribe(this.onTimeUpdated);
    this.onArrowTimeComponentUpdated$ = new Subject();
    this.$onArrowTimeComponentUpdated = this.onArrowTimeComponentUpdated$
      .pipe(debounceTime(700))
      .subscribe(this.onArrowsClick);
    this.onTimeComponentsUpdate(this.props.time);
  }

  componentDidUpdate(prevProps: IPlayerTimePickerProps) {
    if (prevProps.time !== this.props.time) {
      this.onTimeComponentsUpdate(this.props.time);
    }
  }

  componentWillUnmount() {
    if (this.$onTimeComponentUpdated) {
      this.$onTimeComponentUpdated.unsubscribe();
    }
    if (this.$onArrowTimeComponentUpdated) {
      this.$onArrowTimeComponentUpdated.unsubscribe();
    }
  }

  roundMilliseconds = (milliseconds: number) => {
    return roundTwoDecimal(milliseconds / 1000) * 1000;
  };

  onTimeUpdated = (data: IInputUpdate) => {
    // Don't allow limits for minutes and seconds to be reached
    let numericValue = !isNaN(parseInt(data.value)) ? parseInt(data.value) : null;
    if (
      (data.type === 'minutes' || data.type === 'seconds' || data.type === 'frames' || data.type === 'milliseconds') &&
      numericValue
    ) {
      let minLimit = 59;
      if (data.type === 'milliseconds') {
        minLimit = 999;
        // NOTE: Workaround added because of the behavior of Bitmovin player
        // that rounds its current time with 2 numbers after the floating point
        numericValue = this.roundMilliseconds(numericValue);
      } else if (data.type === 'frames') {
        minLimit = this.getFrameRateValue() ? Math.round(this.getFrameRateValue()) - 1 : 23;
      }
      data.value = Smpte.padNum(Math.min(minLimit, numericValue), data.type === 'milliseconds');
    }
    const updateStateObject: any = {};
    updateStateObject[`${data.type}`] = data.value;
    this.onTimeComponentsUpdate(this.getSecondsFromComponents(updateStateObject), () =>
      this.props.onChangeTime(this.getSecondsFromComponents())
    );
  };

  onComponentUpdated = (value: string, type: componentsType) => {
    console.log(value, type);
    let pattern = /^[0-9]{2}$/;
    let defaultValue = '00';
    let digitLimit = 2;

    if (type === 'milliseconds') {
      pattern = /^[0-9]{3}$/;
      defaultValue = '000';
      digitLimit = 3;
    }
    // Update inserted value to match the provided format
    if (!pattern.test(value)) {
      // Remove every non-numeric value from the provided value
      value = value.replace(/\D/g, (match: string) => '');
      value = !value
        ? defaultValue
        : value.length > digitLimit
        ? value.substring(value.length - digitLimit, value.length)
        : Smpte.padNum(Number(value), type === 'milliseconds');
    }
    const updateStateObject: any = {};
    updateStateObject[`${type}`] = value;
    this.setState(updateStateObject, () => this.onTimeComponentUpdated$.next({value, type}));
  };

  getFrameRateValue = (): number => {
    return this.props.frameRate ? this.props.frameRate.frameRate || 24 : null;
  };

  getSecondsFromComponents = (comps?: {[x: string]: string}): number => {
    const components = {
      ...{
        hours: this.state.hours,
        minutes: this.state.minutes,
        seconds: this.state.seconds,
        frames: this.state.frames,
        milliseconds: this.state.milliseconds
      },
      ...(comps || {})
    };
    if (this.props.milliseconds) {
      // Calculate seconds if we have milliseconds timecode
      return [components.hours, components.minutes, components.seconds, components.milliseconds]
        .map(component => (!isNaN(parseInt(component)) ? parseInt(component) : 0))
        .reduce((sum, component, index) => {
          return sum + component * (index === 0 ? 3600 : index === 1 ? 60 : index === 3 ? 0.001 : 1);
        }, 0);
    }
    if (this.props.frameRate) {
      // Calculate seconds if we have SMPTE timecode
      return fromSMPTETokensToSeconds(
        components.hours,
        components.minutes,
        components.seconds,
        components.frames,
        this.props.frameRate.frameRate,
        this.props.frameRate.dropFrame
      );
    }
    // Calculate seconds if we have standard timecode
    return [components.hours, components.minutes, components.seconds]
      .map(component => (!isNaN(parseInt(component)) ? parseInt(component) : 0))
      .reduce((sum, component, index) => {
        return sum + component * (index === 0 ? 3600 : index === 1 ? 60 : 1);
      }, 0);
  };

  secondsToTimestamp = (time: number): string => {
    if (this.props.milliseconds) {
      return secondsToMillisecondsTimecode(time);
    } else if (this.props.frameRate) {
      return Smpte.fromTimeWithAdjustments(time, {
        frameRate: this.props.frameRate.frameRate,
        dropFrame: this.props.frameRate.dropFrame
      }).toString();
    } else {
      return secondsToHHmmss(time);
    }
  };

  onTimeComponentsUpdate = (time: number, callback?: () => void): void => {
    const min = typeof this.props.min !== 'undefined' ? this.props.min : null;
    if (min !== null && time < min) {
      time = min;
    }

    const max = typeof this.props.max !== 'undefined' ? this.props.max : null;
    if (max !== null && time > max) {
      time = max;
    }
    const components = getTimeComponents(
      this.secondsToTimestamp(time),
      this.props.milliseconds ? null : this.getFrameRateValue()
    );
    const partialState: any = {
      hours: components[0],
      minutes: components[1],
      seconds: components[2]
    };
    if (this.props.milliseconds) {
      partialState.milliseconds = Smpte.padNum(this.roundMilliseconds(+components[3]), true);
    } else {
      partialState.frames = components[3];
    }
    this.setState({...partialState}, callback || null);
  };

  onArrowsClick = (type: IArrowPositions) => {
    let duration = this.getSecondsFromComponents();
    const scaleUnit = this.props.milliseconds ? 0.01 : this.props.frameRate ? 1 / this.getFrameRateValue() : 1;

    if (type === 'up') {
      duration += scaleUnit;
    } else {
      duration -= scaleUnit;
    }

    const min = typeof this.props.min !== 'undefined' ? this.props.min : null;
    if (min !== null && duration < min) {
      duration = min;
    }

    const max = typeof this.props.max !== 'undefined' ? this.props.max : null;
    if (max !== null && duration > max) {
      duration = max;
    }

    console.log('Arrows', min, max, duration);

    this.onTimeComponentsUpdate(duration, () => this.props.onChangeTime(this.getSecondsFromComponents()));
  };

  onInputFocus = e => {
    e.target.select();
    this.setState({focused: true});
  };

  onInputBlur = () => {
    this.setState({focused: false});
  };

  handleInputBackwardFocus = () => {
    const elementId = +document.activeElement.id;
    const inputsArr = [
      this.hoursTextInput,
      this.minutesTextInput,
      this.secondsTextInput,
      this.frameTextInput,
      this.millisecondsTextInput
    ];
    if (elementId !== 0) {
      const elem = inputsArr[elementId - 1];
      elem.current.focus();
    } else {
      this.frameTextInput.current.focus();
    }
  };

  handleInputForwardFocus = () => {
    const elementId = +document.activeElement.id;
    const inputsArr = [
      this.hoursTextInput,
      this.minutesTextInput,
      this.secondsTextInput,
      this.frameTextInput,
      this.millisecondsTextInput
    ];
    if (elementId !== 3) {
      const elem = inputsArr[elementId + 1];
      elem.current.focus();
    } else {
      this.hoursTextInput.current.focus();
    }
  };

  handleShortcutPressed = (shortcut: any) => {
    switch (shortcut) {
      case 'tab':
        this.handleInputForwardFocus();
        break;
      case 'shift+tab':
        this.handleInputBackwardFocus();
        break;
    }
  };

  getFramesMillisecondsInputInput = () => {
    const delimiter = this.props.milliseconds ? '.' : ':';
    const value = this.props.milliseconds ? this.state.milliseconds : this.state.frames;
    const field = this.props.milliseconds ? 'milliseconds' : 'frames';
    const props = this.props.milliseconds ? {className: 'milliseconds-input'} : {};

    const input = (
      <>
        <span className="player-time-picker-container_fields_delimiter"> {delimiter} </span>
        <input
          {...props}
          disabled={this.props.disabled}
          type="text"
          id="3"
          value={value}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          onChange={e => this.onComponentUpdated(e.target.value, field)}
          ref={this.frameTextInput}
        />
      </>
    );

    return input;
  };

  render() {
    const {hours, minutes, seconds, focused} = this.state;
    return (
      <div className={`player-time-picker-container${focused ? ` focused` : ``}`}>
        <ShortcutHandler scope={'player'} shortcuts={shortcuts} onShortcutPressed={this.handleShortcutPressed}>
          <div className="player-time-picker-container_fields">
            <input
              disabled={this.props.disabled}
              type="text"
              id="0"
              value={hours}
              onFocus={this.onInputFocus}
              onBlur={this.onInputBlur}
              onChange={e => this.onComponentUpdated(e.target.value, 'hours')}
              ref={this.hoursTextInput}
            />
            <span className="player-time-picker-container_fields_delimiter"> : </span>
            <input
              disabled={this.props.disabled}
              type="text"
              id="1"
              value={minutes}
              onFocus={this.onInputFocus}
              onBlur={this.onInputBlur}
              onChange={e => this.onComponentUpdated(e.target.value, 'minutes')}
              ref={this.minutesTextInput}
            />
            <span className="player-time-picker-container_fields_delimiter"> : </span>
            <input
              disabled={this.props.disabled}
              id="2"
              type="text"
              value={seconds}
              onFocus={this.onInputFocus}
              onBlur={this.onInputBlur}
              onChange={e => this.onComponentUpdated(e.target.value, 'seconds')}
              ref={this.secondsTextInput}
            />
            {!!(this.props.frameRate || this.props.milliseconds) ? this.getFramesMillisecondsInputInput() : null}
          </div>
        </ShortcutHandler>
        <div className="player-time-picker-container_arrows">
          <ArrowButtons
            handleUp={() => this.onArrowTimeComponentUpdated$.next('up')}
            handleDown={() => this.onArrowTimeComponentUpdated$.next('down')}
            hidden={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}
