import {IBaseGAEvent, TriggerEventType, IEventActions, IPushParams} from './IBaseGAEvent';

export class BaseGAEvent implements IBaseGAEvent {
  public gaTriggerEvent: TriggerEventType;
  public eventActions: IEventActions;

  constructor(gaTriggerEvent: TriggerEventType, eventActions: IEventActions) {
    this.gaTriggerEvent = gaTriggerEvent;
    this.eventActions = eventActions;
  }

  createDatalayer(dataLayer: IPushParams) {
    // TODO: find more natural approach to push events directly from the Events classes
    // and not from the wrapper GoogleTabManager class
    return {event: this.gaTriggerEvent, ...dataLayer};
  }
}
