import * as React from 'react';
import {ScrollContainer} from '../ScrollContainer';
import {TimelineSegment} from '../TimelineSegment';
import {IFrameRate} from 'tt-components';
import {ITooltipSegmentData} from '../../interfaces/ITimelineTooltipData';
import {ISegmentModel} from '../../utils/SegmentModel';

interface ITimelineEventsProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  events: Array<ISegmentModel>;
  frameRate: IFrameRate;
  segmentClassName?: string;
  contentClassName?: string;
  emptySegmentsMessage?: string;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
}

interface ITimelineEventsState {
  segmentHeight: number;
  topOffset: number;
}

export class TimelineEvents extends React.Component<ITimelineEventsProps, ITimelineEventsState> {
  constructor(props) {
    super(props);

    this.state = {
      segmentHeight: 10,
      topOffset: 5
    };
  }

  addSegment = (program: ISegmentModel, index: number, top: number) => {
    const intervalDuration = program.timeOut - program.timeIn === 0 ? 1 : program.timeOut - program.timeIn;
    const style: React.CSSProperties = {
      height: this.state.segmentHeight,
      width: intervalDuration * this.props.secondUnits,
      left: program.timeIn * this.props.secondUnits,
      top
    };

    const segment = (
      <TimelineSegment
        key={index}
        containerClassName={this.props.segmentClassName || ''}
        start={program.timeIn}
        end={program.timeOut}
        style={style}
        title={program.title}
        content={program.content}
        comment={program.comment}
        onElementIn={this.props.onElementIn}
        onElementOut={this.props.onElementOut}
        onSegmentClicked={this.props.onSegmentClicked}
      />
    );

    return {segment, start: program.timeIn, end: program.timeOut};
  };

  calcSegmentTopPosition = (overlapIndex: number) => {
    return (overlapIndex + 1) * this.state.topOffset + overlapIndex * this.state.segmentHeight;
  };

  getSegments = () => {
    const segments = [];
    const records = this.props.events || [];
    let maxOverlap = 0;

    records.sort((a, b) => {
      return a.timeIn - b.timeIn;
    });

    let index = 0;
    const length = records.length;
    while (index < length) {
      let overlapTimes = 0;
      const program = records[index];
      const segment = this.addSegment(program, index, this.calcSegmentTopPosition(overlapTimes));
      segments.push(segment.segment);

      for (let innerIndex = index + 1; innerIndex < length; innerIndex++) {
        const innerProgram = records[innerIndex];
        if (innerProgram.timeIn >= segment.start && innerProgram.timeIn <= segment.end) {
          overlapTimes++;
          const innerSegment = this.addSegment(innerProgram, innerIndex, this.calcSegmentTopPosition(overlapTimes));
          segments.push(innerSegment.segment);
        }
      }

      index = index + overlapTimes;
      maxOverlap = Math.max(maxOverlap, overlapTimes);
      index++;
    }
    return {segments, maxOverlap};
  };

  render() {
    const {contentWidth} = this.props;
    const {segmentHeight, topOffset} = this.state;
    const {segments, maxOverlap} = this.getSegments();
    const contentStyle: React.CSSProperties =
      segments.length > 0
        ? {width: contentWidth, height: this.calcSegmentTopPosition(maxOverlap) + segmentHeight + topOffset}
        : emptyContentStyle;
    return (
      <ScrollContainer
        contentClassName={this.props.contentClassName || ''}
        scrollLeft={this.props.scrollLeft}
        contentStyle={contentStyle}
      >
        {segments.length ? segments : this.props.emptySegmentsMessage || `No events are defined`}
      </ScrollContainer>
    );
  }
}

const emptyContentStyle: React.CSSProperties = {
  height: 25,
  justifyContent: 'center',
  fontSize: 12,
  cursor: 'default',
  alignItems: 'center',
  display: 'flex'
};
