import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const ReplayIcon = (props: Partial<IIconProps>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.size}
      height={props.size}
      viewBox="0 0 62 61"
    >
      <g id="Compliance-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1535.000000, -1758.000000)" fill={props.color} id="Player">
          <g transform="translate(333.000000, 458.899171)">
            <g id="Player-Controls" transform="translate(0.000000, 279.734614)">
              <g id="Nav-Controls" transform="translate(89.000000, 1016.000000)">
                <g id="ic-loop" transform="translate(1113.000000, 4.000000)">
                  <g id="Shape">
                    <path
                      d="M40.6889062,11.8139062 L29.4389062,0.56390625 C28.9139062,0.00140625 
                    28.1264062,-0.14859375 27.4139062,0.15140625 C26.7014062,0.45140625 
                    26.2514062,1.12640625 26.2514062,1.87640625 L26.2514062,7.50140625 
                    L24.3764062,7.50140625 C9.90140625,7.50140625 -1.72359375,20.2514062 
                    0.22640625,35.1014062 C0.97265625,40.9064062 3.81140625,45.9126562 
                    7.93265625,49.6214062 C8.66015625,50.2776562 9.80015625,50.2026562 
                    10.4939062,49.5089062 L15.7064062,44.2964062 C16.4526562,43.5501562 
                    16.4601562,42.3164062 15.6651562,41.6226562 C13.6101562,39.8376563 
                    12.1289062,37.4339062 11.5514062,34.6139063 C9.78890625,26.1764062 
                    16.2389062,18.7514063 24.3764062,18.7514063 L26.2514062,18.7514063 
                    L26.2514062,24.3764062 C26.2514062,25.1264062 26.7014062,25.8014062 
                    27.4139062,26.1014063 C28.1264062,26.4014063 28.9139062,26.2514062 
                    29.4389062,25.6889062 L40.6889062,14.4389063 C41.4389062,13.7264062 
                    41.4389062,12.5264062 40.6889062,11.8139062 Z"
                      fillRule="nonzero"
                    />
                    <path
                      d="M59.7764063,24.9014062 C59.0301562,19.0964062 56.1914062,14.0864062 
                    52.0701562,10.3814062 C51.3426562,9.72515625 50.2026563,9.80015625 
                    49.5089063,10.4939062 L44.2964063,15.7064062 C43.5501563,16.4526562 
                    43.5426563,17.6864062 44.3376562,18.3801562 C46.3889063,20.1689062 
                    47.8739062,22.5726562 48.4514062,25.3889062 C50.2139062,33.8264062 
                    43.7639062,41.2514062 35.6264062,41.2514062 L33.7514062,41.2514062 
                    L33.7514062,35.6264062 C33.7514062,34.8764062 33.3014062,34.2014062 
                    32.5889062,33.9014062 C31.8764062,33.6014062 31.0889062,33.7514062 
                    30.5639062,34.3139062 L19.3139062,45.5639062 C18.5639062,46.2764062 
                    18.5639062,47.4764062 19.3139062,48.1889062 L30.5639062,59.4389062 
                    C31.0889062,60.0014062 31.8764062,60.1514062 32.5889062,59.8514062 
                    C33.3014062,59.5514062 33.7514062,58.8764062 33.7514062,58.1264062 
                    L33.7514062,52.5014062 L35.6264062,52.5014062 C50.1014062,52.5014062 
                    61.7264062,39.7514062 59.7764063,24.9014062 Z"
                      fillRule="nonzero"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
