import * as React from 'react';
import {IAudioChannelConfiguration, IAudioChannel} from '../../../@types/audioChannelConfiguration';
import {IEnum} from '../../../@types/enum';
import {defineAudioConfigurationTitle, defineChannelCheckboxLabel} from '../../utils/utils';
import {ITemplateColors} from '../../../@types/templateColors';
import {CustomRadioButton} from '../CustomRadio';
import {IVideoAudioCheckedChannels} from '../../state/IVideoState';
import {Icon} from 'tt-components/src/Icon/Icon';
import {CustomCheckbox, ITickers} from '../CustomCheckbox';

import {ChevronDownIcon} from '../../assets/Icons/ChevronDownIcon';
import {ChevronLeftIcon} from '../../assets/Icons/ChevronLeft';
import {VisibleIcon} from '../../assets/Icons/Visible';
import {HiddenIcon} from '../../assets/Icons/Hidden';

interface IConfigurationRadioProps {
  config: IAudioChannelConfiguration;
  title?: string;
  audioLanguageEnums?: Array<IEnum>;
  audioChannelConfigTypeEnums?: Array<IEnum>;
  audioChannelMapEnums?: Array<IEnum>;
  componentIdentifier?: string;
  selectedConfig: string;
  checkedChannels?: IVideoAudioCheckedChannels;
  templateColors: ITemplateColors;
  curationModeEnabled?: boolean;
  disabled?: boolean;
  collapsedConfiguration?: string;
  showConfigsAssetHrId?: boolean;
  useVisibilityCheckbox?: boolean;
  onConfigChange: (configId: string) => void;
  onChannelChecked?: (channelId: string, configId: string) => void;
  collapseConfig?: (configId: string) => void;
}

export class ConfigurationRadio extends React.PureComponent<IConfigurationRadioProps> {
  static defaultProps = {
    audioLanguageEnums: [],
    audioChannelConfigTypeEnums: [],
    audioChannelMapEnums: [],
    checkedChannels: {}
  };

  getConfigTitle = () => {
    const title =
      this.props.title ||
      defineAudioConfigurationTitle(
        this.props.config,
        this.props.audioLanguageEnums,
        this.props.audioChannelConfigTypeEnums
      );
    return `${title}${this.props.showConfigsAssetHrId && this.props.config.hrId ? ` (${this.props.config.hrId})` : ``}`;
  };

  getRadioElement = () => {
    const isStaged = this.props.config.assetStatus.toLowerCase() === 'staged';
    const identifier = `${this.props.config.id}_${this.props.config.assetId}${
      this.props.componentIdentifier ? `_${this.props.componentIdentifier}` : ``
    }`;
    const title = this.getConfigTitle();
    const classes = [
      isStaged ? `` : `not-staged`,
      !this.props.config.isRegistered && this.props.curationModeEnabled ? 'not-registered' : ''
    ]
      .filter(token => token)
      .join(' ');
    return (
      <CustomRadioButton
        className={classes}
        disabled={this.props.disabled}
        key={identifier}
        index={identifier}
        id={identifier}
        value={this.props.config.id}
        text={title}
        selectedValue={this.props.selectedConfig}
        onChange={e => this.props.onConfigChange(e.target.value)}
        templateColors={this.props.templateColors}
      />
    );
  };

  getChannels = (): Array<JSX.Element> => {
    const isStaged = this.props.config.assetStatus.toLowerCase() === 'staged';
    const classes = [
      isStaged ? `` : `not-staged`,
      !this.props.config.isRegistered && this.props.curationModeEnabled ? 'not-registered' : ''
    ]
      .filter(token => token)
      .join(' ');
    return (this.props.config.trackDetail || []).map((channel: IAudioChannel) => {
      const identifier = `${channel.id}_${this.props.componentIdentifier}`;
      const customTickers = this.props.useVisibilityCheckbox
        ? ({checked: VisibleIcon, notChecked: HiddenIcon} as ITickers)
        : null;
      return (
        <CustomCheckbox
          className={classes}
          disabled={this.props.disabled || !channel.channelMap.length}
          key={identifier}
          index={identifier}
          id={identifier}
          value={channel.id}
          text={defineChannelCheckboxLabel(channel.channelMap, this.props.audioChannelMapEnums)}
          checked={
            !!this.props.checkedChannels[this.props.config.id] &&
            this.props.checkedChannels[this.props.config.id].includes(channel.id)
          }
          onChange={e =>
            this.props.onChannelChecked ? this.props.onChannelChecked(channel.id, this.props.config.id) : null
          }
          templateColors={this.props.templateColors}
          customTickers={customTickers}
        />
      );
    });
  };

  getConfigComponent = () => {
    const radio = this.getRadioElement();
    const channels = this.getChannels();
    const isCollapsed = this.props.collapsedConfiguration === this.props.config.id;
    const isStaged = this.props.config.assetStatus.toLowerCase() === 'staged';
    return (
      <div className="configuration-radio-container">
        {!!channels.length && (
          <div
            className="configuration-radio-container_arrow"
            onClick={e => (this.props.collapseConfig ? this.props.collapseConfig(this.props.config.id) : null)}
          >
            <Icon
              icon={isCollapsed ? ChevronDownIcon : ChevronLeftIcon}
              size="12px"
              color={`${isStaged ? '#1f72f2' : 'red'}`}
            />
          </div>
        )}
        {radio}
        {isCollapsed && <div className="configuration-radio-container_audio-channels">{channels}</div>}
      </div>
    );
  };

  render() {
    return this.getConfigComponent();
  }
}
