import {IVersionsProps} from '../../Versions';
import {ITemplateColors} from '../../../../../../@types/templateColors';

export const VersionsProps = (
  selectedVersion: string,
  templateColors: ITemplateColors,
  onVersionChange
): IVersionsProps => ({
  versions: [
    {
      id: '1',
      name: 'Lorem ipsum dolor',
      createdAt: '2019-02-19 8:05 AM',
      updatedAt: '2019-02-19 6:19 PM'
    },
    {
      id: '2',
      name: '',
      createdAt: '2019-02-21 10:22 AM',
      updatedAt: '2019-02-22 3:15 PM'
    },
    {
      id: '3',
      name: 'Lorem ipsum dolor sit am',
      createdAt: '2019-02-21 2:16 PM',
      updatedAt: '2019-02-21 5:52 PM'
    },
    {
      id: '4',
      name: '',
      createdAt: '2019-02-27 10:36 PM',
      updatedAt: '2019-02-28 10:33 PM'
    }
  ],
  selectedVersion,
  templateColors,
  onVersionChange
});
